import ApiKey from 'components/ApiKey';
import { useFormContext } from 'react-hook-form';

interface ApiKeyFormProps extends React.ComponentProps<typeof ApiKey> {
    name: string;
    setApiKeyErrors?: (errorsExist: boolean) => void;
    setErrorField?: string;
}

/**
 * Shows a button to generate an API key that must be clicked before form can
 * be submitted.
 */
export default function ApiKeyForm({ name, setErrorField, setApiKeyErrors, ...props }: ApiKeyFormProps) {
    const { setValue, getValues } = useFormContext();
    const currentValue = getValues(name);

    const clickHandler = (apiKey: { id: string; lastFour: string }) => {
        setValue(name, apiKey, { shouldValidate: true });
    };

    return (
        <ApiKey
            currentValue={currentValue}
            {...props}
            postSubmit={clickHandler}
            setErrorField={setErrorField}
            setApiKeyErrors={setApiKeyErrors}
        />
    );
}
