import { useEffect, useRef, useState } from 'react';

/**
 * Checks if the element is overflowing, (it's scroll width is greater than it's size)
 *
 * Returns { ref, isOverflowing: boolean }, add the ref to the elements props you want to check
 *
 * Note: are you here because you are unit testing something that uses this component
 * and got an error about window.ResizeObserver  being undefined? If so, add this line
 * to your unit tests:
 *  window.ResizeObserver = class { observe(){}; unobserve(){}; disconnect(){}; };
 */
const useOverflowing = <E extends HTMLElement>() => {
    const ref = useRef<E>(null);
    const [isOverflowing, setIsOverflowing] = useState(
        !ref.current || (
            ref.current?.scrollWidth > ref.current?.clientWidth));

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const resizeObserver = new ResizeObserver((elements) => {
            for (const element of elements) {
                setIsOverflowing(element.target.scrollWidth > element.target.clientWidth);
            }
        });

        resizeObserver.observe(ref.current);

        return () => resizeObserver.disconnect();
    }, []);

    return {
        ref,
        isOverflowing
    };
};

export default useOverflowing;