import clsx from 'clsx';
import LoadingSpinner from 'components/LoadingSpinner';
import { GraphData } from 'dashboard-engine/types/data/GraphData';
import stringify from 'fast-json-stable-stringify';
import hash from 'object-hash';
import { useQuery } from 'react-query';
import { GRAPH_DATA, NetworkFlow } from './Network';
import { generateObjectNetworkLayout } from './utils/generateObjectNetwork';

const ObjectNetwork: React.FC<{
    data?: any;
    selectedNodeId?: string;
    config?: any;
    onClick?: () => void;
    dataLoader?: () => Promise<GraphData>;
}> = ({ data, dataLoader, onClick, selectedNodeId, config }) => {
    // Create a hash from the data so we can use it as the react component key
    const dataHash = hash(stringify(data));

    const { data: graphData, isLoading } = useQuery(
        [GRAPH_DATA, dataHash],
        async () => {
            let rawGraphData = data;

            // Use dataLoader if it's been passed
            if (dataLoader) {
                rawGraphData = await dataLoader();
            }

            const result = await generateObjectNetworkLayout(rawGraphData!, config || {});

            // TODO: Uncomment the below selectedNodeId functionality used by search and drilldown
            // once SAAS-4172 has been addressed.
            // if (selectedNodeId && Array.isArray(result.nodes)) {
            //     for (const node of result.nodes) {
            //         node.style = { opacity: node.id === selectedNodeId ? 1 : 0.5 };
            //     }
            // }

            return result;
        },
        { enabled: Boolean(data || dataLoader) }
    );

    if (isLoading) {
        return (
            <div className='flex items-center justify-center w-full h-full'>
                <LoadingSpinner />
            </div>
        );
    }

    // Return early loading or there is no data (should never happen!)
    if (!isLoading && !graphData) {
        return null;
    }

    return <NetworkFlow key={dataHash} nodes={graphData!.nodes} edges={graphData!.edges} onClick={onClick} />;
};

export default ObjectNetwork;
