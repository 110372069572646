import { type ClientDataStreamRequest } from '@squaredup/data-streams';
import stringify from 'fast-json-stable-stringify';
import type { QueryKey } from 'react-query';
import type { SetOptional } from 'type-fest';

export const DATA_STREAM_DATA = 'DataStreamData';

export const streamDataKeys = {
    all: [DATA_STREAM_DATA],
    forDataStream: (dataStreamId: string) => [DATA_STREAM_DATA, dataStreamId],
    pluginDataStreams: (pluginId: string) => [DATA_STREAM_DATA, pluginId],
    forRequest: (request: SetOptional<ClientDataStreamRequest, 'options'>, extraKeys: QueryKey = []): QueryKey => {
        // Unpack purpose from options so as not to use it for caching
        const { purpose, ...relevantOptions } = request.options ?? {};
        return [
            DATA_STREAM_DATA,
            request.dataStreamId,
            request.dataStreamName,
            request.pluginConfigId,
            // We don't want the query key changing as time passes
            typeof request.timeframe === 'string' ? request.timeframe : request.timeframe.enum,
            stringify(request.dataSourceConfig ?? {}),
            stringify(relevantOptions),
            stringify(request.scope),
            stringify(request.context ?? {}),
            ...(typeof extraKeys === 'string' ? [extraKeys] : extraKeys)
        ];
    }
};