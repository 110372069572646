import { DataStreamBaseTileConfig, DataStreamWarningType, type RequestResult } from '@squaredup/data-streams';
import { ConfigId } from '@squaredup/ids';
import { getTimeframeLabel, type TimeframeEnumValue } from '@squaredup/timeframes';
import { MarkdownBase } from 'components/markdown/MarkdownBase';
import { Pill } from 'components/pill/Pill';
import DashboardContext from 'contexts/DashboardContext';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import { groupBy, orderBy } from 'lodash';
import pluralize from 'pluralize';
import { useDatasourceConfigs } from 'queries/hooks/useDatasourceConfigs';
import { useContext, type FC } from 'react';
import { useTileTimeframes } from 'ui/editor/dataStream/TileEditor/hooks/useTileTimeframes';
import { TileWarning, TileWarningPopover, type TileWarningGroup } from './TileWarningPopover';

// Duplicated in https://github.com/squaredup/plugin-common/index.js
export const DATASTREAM_MESSAGES = {
    AUTHENTICATION_FAILED:
        'Authentication failures occurred, please verify the settings are correct in your data source.',
    NOT_FOUND: 'A resource was not found, please perform an import on your data source.',
    RATE_LIMITTED: 'The data source has throttled the request, please try again later.',
    INTERNAL_ERROR: 'The data source has encountered an error, please try again later.',
    UNKNOWN_ERROR: 'The data source has encountered an error.',
    SCOPE_NOT_FOUND: 'The selected collection was not found, it may have been removed.',
    DATASTREAM_NOT_FOUND: 'The specified data stream was not found, the data source may have been removed.'
};

export const warningMessages = [
    {
        heading: 'Authentication failed',
        message: DATASTREAM_MESSAGES.AUTHENTICATION_FAILED
    },
    {
        heading: 'Resource not found',
        message: DATASTREAM_MESSAGES.NOT_FOUND
    },
    {
        heading: 'Rate limit reached',
        message: DATASTREAM_MESSAGES.RATE_LIMITTED
    },
    {
        heading: 'Data source error',
        message: DATASTREAM_MESSAGES.INTERNAL_ERROR
    },
    {
        heading: 'Collection not found',
        message: DATASTREAM_MESSAGES.SCOPE_NOT_FOUND
    },
    {
        heading: 'Data stream not found',
        message: DATASTREAM_MESSAGES.DATASTREAM_NOT_FOUND
    }
];

interface TileWarningsProps {
    config: DataStreamBaseTileConfig;
}

/**
 * A summary of the warnings and errors for a single request.
 */
const RequestSummary: FC<{ index: number; request: RequestResult; pluginConfigDisplayName: string }> = ({
    index,
    request,
    pluginConfigDisplayName
}) => {
    const errors = request.succeeded ? undefined : (
        <div className='mt-2 space-y-2'>
            {request.errors.map((e) => (
                <pre className='whitespace-normal border-2 px-sm py-xs bg-tagBackground rounded-input leading-input border-tileOutline'>
                    {e}
                </pre>
            ))}
        </div>
    );

    const warnings =
        request.warnings.length === 0 ? undefined : (
            <div className='mt-2 space-y-2'>
                {request.warnings.map((w) =>
                    typeof w === 'string' ? (
                        <pre className='whitespace-normal border-2 px-sm py-xs bg-tagBackground rounded-input leading-input border-tileOutline'>
                            <MarkdownBase content={w} />
                        </pre>
                    ) : (
                        <div>
                            {w.type === DataStreamWarningType.ObjectLimit && (
                                <>
                                    <h5 className='font-semibold'>Data stream limit ({w.payload.objectLimit})</h5>
                                    <p>
                                        This data stream is limited to the first{' '}
                                        {pluralize('object', w.payload.objectLimit, true)}.{' '}
                                        <a
                                            className='text-textLink'
                                            href='https://docs.squaredup.com/first-steps/objects#data-stream-limits'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Learn more.
                                        </a>
                                    </p>
                                </>
                            )}
                            {w.type === DataStreamWarningType.Misc && <p>{w.message}</p>}
                        </div>
                    )
                )}
            </div>
        );

    return (
        <div className='mt-4'>
            <h4 className='inline font-semibold'>
                {`Query ${index + 1}`}
                <span className='font-normal'> ({pluginConfigDisplayName})</span>
            </h4>
            <Pill.Status
                className='inline ml-2'
                status={request.succeeded ? (request.warnings.length > 0 ? 'warnings' : 'succeeded') : 'failed'}
            >
                {request.succeeded ? (request.warnings.length > 0 ? 'Warning' : 'Success') : 'Error'}
            </Pill.Status>

            {errors}
            {warnings}
        </div>
    );
};

/**
 * A message summarising the warnings and errors for a set of requests.
 */
const RequestMessage: FC<{
    headerClassName: string;
    requests: RequestResult[];
}> = ({ headerClassName, requests }) => {
    const failed = requests.filter((r) => !r.succeeded).length;
    const succeededWithWarnings = requests.filter((r) => r.succeeded && r.warnings.length > 0).length;

    if (failed === 0 && succeededWithWarnings === 0) {
        return <></>;
    }

    return (
        <>
            {failed === 1 && (
                <>
                    <p className={headerClassName}>1 data source query failed.</p>
                    <p>The data source encountered an error. The tile may not display all available data.</p>
                </>
            )}

            {failed > 1 && (
                <>
                    <p className={headerClassName}>{`${failed} of ${requests.length} data source queries failed.`}</p>
                    <p>The data source encountered an error. The tile may not display all available data.</p>
                </>
            )}

            {failed === 0 && succeededWithWarnings === 1 && (
                <p className={headerClassName}>1 data source query succeeded with warnings.</p>
            )}

            {failed === 0 && succeededWithWarnings > 1 && (
                <p
                    className={headerClassName}
                >{`${succeededWithWarnings} of ${requests.length} data source queries succeeded with warnings.`}</p>
            )}
        </>
    );
};

const getTimeframeWarning = (
    tableConfig: Partial<DataStreamBaseTileConfig> | undefined,
    timeframe: TimeframeEnumValue,
    defaultTimeframe: TimeframeEnumValue
): TileWarning | undefined => {
    const tileTimeframe = tableConfig?.timeframe || defaultTimeframe;

    if (timeframe && tileTimeframe && timeframe !== tileTimeframe) {
        const previousTimeframe = getTimeframeLabel(tileTimeframe, 'nonrelative');
        const currentTimeframe = getTimeframeLabel(timeframe, 'nonrelative');

        return {
            heading: 'Timeframe not supported',
            message: `The selected page timeframe (${previousTimeframe}) is not supported by this data stream, auto-selected next available timeframe (${currentTimeframe}).`
        };
    }

    return undefined;
};

export const TileWarnings: FC<TileWarningsProps> = ({ config }) => {
    const dashboardContext = useContext(DashboardContext);
    const {
        data: {
            metadata: { requestStats }
        }
    } = useDataStreamConfig(config, {}, 'dashboard');

    const isSqlTile = config.dataStream?.id === 'datastream-sql';
    const configs = isSqlTile ? config.dataStream?.dataSourceConfig?.tables : [{ config }];

    const analyticsTimeframes = useTileTimeframes(configs?.map((c) => c?.config));
    const { data: pluginConfigDisplayNames = new Map<ConfigId['value'], string>() } = useDatasourceConfigs({
        select: (pluginConfigs) => new Map(pluginConfigs.map((c) => [c.id, c.displayName!]))
    });

    const requestsByTable = isSqlTile
        ? Object.entries(groupBy(requestStats.requests ?? [], (r) => r.source?.tableName))
        : ([[undefined, requestStats.requests ?? []]] as const);
    const allSucceeded = requestStats.requests?.every((r) => r.succeeded && r.warnings.length === 0);

    const warningsByTable = requestsByTable.flatMap(
        ([tableName, requests]): {
            warningCount: number;
            errorCount: number;
            warnings: TileWarningGroup | TileWarning[];
        }[] => {
            const tableConfigIndex = isSqlTile ? 0 : configs?.findIndex((c) => c?.tableName === tableName) ?? 0;

            const timeframeWarning =
                configs == null || analyticsTimeframes == null
                    ? undefined
                    : getTimeframeWarning(
                          configs[tableConfigIndex]?.config,
                          analyticsTimeframes[tableConfigIndex],
                          dashboardContext.timeframe
                      );

            const requestWarnings = allSucceeded
                ? []
                : orderBy(requests, ['succeeded', 'configId']).map(
                      (r, i): TileWarning => ({
                          message: (
                              <RequestSummary
                                  index={i}
                                  request={r}
                                  pluginConfigDisplayName={
                                      pluginConfigDisplayNames.get(r.configId) ?? 'Unknown Data Source'
                                  }
                              />
                          )
                      })
                  );

            if (requestWarnings.length === 0 && !isSqlTile && timeframeWarning != null) {
                return [{ errorCount: 0, warningCount: 1, warnings: [timeframeWarning] }];
            }

            return [
                {
                    errorCount: requests.reduce((acc, cur) => acc + (cur.succeeded ? 0 : cur.errors.length), 0),
                    warningCount:
                        requests.reduce((acc, cur) => acc + cur.warnings.length, 0) +
                        (timeframeWarning != null ? 1 : 0),
                    warnings: {
                        heading: tableName,
                        description: (
                            <RequestMessage headerClassName={isSqlTile ? '' : 'font-semibold'} requests={requests} />
                        ),
                        warnings: requestWarnings.concat(timeframeWarning ?? [])
                    }
                }
            ];
        }
    );

    const [warningCount, failedCount] = warningsByTable.reduce(
        ([wc, fc], r): [number, number] => [wc + r.warningCount, fc + r.errorCount],
        [0, 0] as [number, number]
    );

    return warningCount + failedCount > 0 ? (
        <TileWarningPopover
            warnings={warningsByTable.map((w) => w.warnings).flat()}
            summary={
                <>
                    {failedCount > 0 && <p>{`${failedCount} data source ${pluralize('query', failedCount)} failed`}</p>}
                    {warningCount > 0 && <p>{`${warningCount} ${pluralize('warning', warningCount)}`}</p>}
                </>
            }
        />
    ) : null;
};
