import { cn } from '@/lib/cn';
import * as Accordion from '@radix-ui/react-accordion';
import React, { useState, type ComponentProps, type HTMLAttributes } from 'react';

type AccordionRootProps = ComponentProps<typeof Accordion.Root>;

/**
 * @example
 * 
 * <Accordion.Root className='AccordionRoot' type='single' defaultValue='item-1' collapsible>
    <Accordion.Item className='AccordionItem' value='item-1'>
        <AccordionTrigger>Is it accessible?</AccordionTrigger>
        <AccordionContent>Yes. It adheres to the WAI-ARIA design pattern.</AccordionContent>
    </Accordion.Item>

    <Accordion.Item className='AccordionItem' value='item-2'>
        <AccordionTrigger>Is it unstyled?</AccordionTrigger>
        <AccordionContent>Yes. It's unstyled by default, giving you freedom over the look and feel.</AccordionContent>
    </Accordion.Item>

    <Accordion.Item className='AccordionItem' value='item-3'>
        <AccordionTrigger>Can it be animated?</AccordionTrigger>
        <Accordion.Content className='AccordionContent'>
            <div className='AccordionContentText'>Yes! You can animate the Accordion with CSS or JavaScript.</div>
        </Accordion.Content>
    </Accordion.Item>
</Accordion.Root>;
 */
export const AccordionTrigger = React.forwardRef<HTMLButtonElement, HTMLAttributes<HTMLButtonElement>>(
    ({ children, className, ...props }, forwardedRef) => (
        <Accordion.Header>
            <Accordion.Trigger className={cn('flex gap-2', className)} {...props} ref={forwardedRef}>
                {children}
            </Accordion.Trigger>
        </Accordion.Header>
    )
);

export const AccordionContent = React.forwardRef<HTMLDivElement, ComponentProps<typeof Accordion.Content>>(
    ({ children, className, ...props }, forwardedRef) => (
        <Accordion.Content className={className} {...props} ref={forwardedRef}>
            <div>{children}</div>
        </Accordion.Content>
    )
);

/**
 * Produce props used to programatically monitor and/or control an accordion component.
 * @param type The type of the accordion (single or multiple).
 * @param initialPanelState The list of panels and whether they are expanded to start with or not.
 */
export function useAccordionControls<T extends string>(options: {
    type: 'single';
    canCollapse?: (panelName: T) => boolean;
    initialPanelState: { name: T; isOpen: boolean }[];
}): Accordion.AccordionSingleProps;

export function useAccordionControls<T extends string>(options: {
    type: 'multiple';
    canCollapse?: (panelName: T) => boolean;
    initialPanelState: { name: T; isOpen: boolean }[];
}): Accordion.AccordionMultipleProps;


export function useAccordionControls<T extends string>({
    type,
    canCollapse = () => true,
    initialPanelState
}: {
    type: AccordionRootProps['type'];
    canCollapse?: (panelName: T) => boolean;
    initialPanelState: { name: T; isOpen: boolean }[];
}) {
    const [openPanels, setOpenAccordionPanels] = useState(() =>
        initialPanelState.filter((s) => s.isOpen).map((s) => s.name)
    );

    const accordionControls: AccordionRootProps =
        type === 'single'
            ? {
                  type,
                  onValueChange: (newOpenPanels: string) => {
                      if (newOpenPanels !== openPanels[0] && canCollapse(openPanels[0])) {
                          setOpenAccordionPanels([newOpenPanels] as T[]);
                      }
                  },
                  value: openPanels[0]
              }
            : {
                  type,
                  onValueChange: (newOpenPanels: string[]) => {
                      const collapsed = openPanels.filter((p) => !newOpenPanels.includes(p));
                      const failedCollapse = collapsed.filter((p) => !canCollapse(p));

                      setOpenAccordionPanels([...newOpenPanels, ...failedCollapse] as T[]);
                  },
                  value: openPanels
              };

    return accordionControls;
}
