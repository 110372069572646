import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { FC } from 'react';

export interface DrilldownPanelProps {
    title?: string;
    description?: string;
    actions?: JSX.Element;
    childrenStyles?: string;
}

export const DrilldownPanel: FC<DrilldownPanelProps> = ({ title, actions, description, children, childrenStyles }) => {
    return (
        <div className='flex flex-col h-full rounded-lg bg-tileBackground' data-testid={title}>
            <div className='flex items-center px-4 pt-4 mb-2'>
                {title && <Text.H3>{title}</Text.H3>}
                {actions && <span className='ml-auto'>{actions}</span>}
            </div>

            {description && <Text.Body className='px-4 text-textSecondary'>{description}</Text.Body>}

            <div className={cn('px-4 pb-4 tile-scroll-overflow', childrenStyles)}>{children}</div>
        </div>
    );
};
