import { Toggle } from '@/components/forms/Toggle';
import { FC, useState } from 'react';
import { DrilldownPanel } from './DrilldownPanel';
import { GraphNodePropertyTable, systemObjectHiddenPropertyMap } from './GraphNodePropertyTable';
import { NodeWithCanonical } from './common';

export const GraphNodePropertyPanel: FC<{ node: NodeWithCanonical }> = ({ node }) => {
    const [showInternalNodeProperties, setShowInternalNodeProperties] = useState(false);

    // to display, node must be found, have no type, or have at least one non system type
    const showInternalPropsToggle =
        node &&
        (!Array.isArray(node.sourceType) ||
            node.sourceType.length === 0 ||
            node.sourceType.some((t) => !systemObjectHiddenPropertyMap.has(t)));

    const actions = (
        <div className='flex items-center h-12 space-x-4'>
            {showInternalPropsToggle && (
                <>
                    <label htmlFor='internal-props-toggle' className='select-none whitespace-nowrap'>
                        Show internal properties
                    </label>
                    <Toggle
                        name='internal-props-toggle'
                        checked={showInternalNodeProperties}
                        onCheckedChange={setShowInternalNodeProperties}
                        data-testid='internal-props-toggle'
                    />
                </>
            )}
        </div>
    );

    return (
        <DrilldownPanel title='Properties' actions={actions}>
            {node && <GraphNodePropertyTable node={node} showInternalProperties={showInternalNodeProperties} />}
        </DrilldownPanel>
    );
};
