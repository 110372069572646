export const getNewFilterValue = <T>(currentFilter: T[], filterValue: T ) => {
    const indexInCurrentFilter = currentFilter.indexOf(filterValue);

    // Add the value to the array if it isn't in the array
    if (indexInCurrentFilter === -1) {
        return [...currentFilter, filterValue];
    } 

    // Remove the value from the array if the value already exists in the array
    const newFilterValue = [...currentFilter];
    newFilterValue.splice(indexInCurrentFilter, 1);

    return newFilterValue;
};
