import { DataStreamScope } from '@squaredup/data-streams';
import { ConfigId, PluginId, Serialised } from '@squaredup/ids';
import { UIConfig } from '@squaredup/utilities';
import LoadingSpinner from 'components/LoadingSpinner';
import { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';
import DisplayJsonUi, { getDefaultValues } from 'components/forms/jsonForms/DisplayJsonUi';
import { resolveFormFields } from 'components/forms/jsonForms/resolveAutocompleteOptions';
import type { DataStreamDefinitionEntity, ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import stringify from 'fast-json-stable-stringify';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface DataStreamTemplateFormProps {
    scope: DataStreamScope | undefined;
    pluginConfigId: ConfigId['value'] | undefined;
    pluginId: PluginId['value'];
    streamDefinitions: Serialised<ProjectedDataStreamDefinitionEntity>[];
    defaultDataSourceConfig?: Record<string, any>;
    formFields?: Serialised<UIConfig[]>;
    datasource?: Serialised<DataStreamDefinitionEntity>;
    isUpdating: boolean;
    onSubmit: (data: Record<string, any> & { displayName?: string }) => void;
    onDeleteClick: () => void;
    onClose: (id?: any, deleted?: boolean) => void;
}

export const DataStreamTemplateForm = ({
    defaultDataSourceConfig,
    scope,
    pluginConfigId,
    pluginId,
    streamDefinitions,
    formFields,
    isUpdating,
    onClose,
    onDeleteClick,
    onSubmit
}: DataStreamTemplateFormProps) => {
    const formDefaults = useMemo(() => getDefaultValues(formFields), [formFields]);

    const defaultValues = {
        ...formDefaults,
        ...(isUpdating ? defaultDataSourceConfig : {})
    };

    const methods = useForm({ mode: 'all', defaultValues });
    const {
        formState: { isSubmitting, isValid }
    } = methods;
    const formData = methods.watch();

    const resolvedFormFields = useMemo(
        () =>
            streamDefinitions.length === 0
                ? []
                : resolveFormFields(formFields, formData, streamDefinitions, { scope, pluginConfigId }, pluginId),
        // Stringify formData as react-hook-form re-uses the same object instance
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formFields, streamDefinitions.length, scope, pluginConfigId, pluginId, stringify(formData)]
    );

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className='flex flex-col flex-1 w-full min-h-0 mt-10'
                data-testid='form'
            >
                <div className='flex-1 min-h-0 px-8 overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                    {resolvedFormFields && <DisplayJsonUi formFields={resolvedFormFields} />}
                </div>

                <ModalButtons>
                    {isUpdating && (
                        <Button type='button' onClick={onDeleteClick} variant='destructive' className='mr-auto'>
                            Delete
                        </Button>
                    )}
                    <Button type='button' onClick={() => onClose()} variant='secondary'>
                        Cancel
                    </Button>
                    <Button type='submit' disabled={isSubmitting || !isValid}>
                        {isSubmitting ? <LoadingSpinner size={18} /> : 'Save'}
                    </Button>
                </ModalButtons>
            </form>
        </FormProvider>
    );
};
