import useFitText, { TOptions } from './useFitText';

/**
 * Wraps the useFitText hook with a 15% to allow for overflow/scroll issues
 * @param options Min/max font size etc.
 * @param buffer Percentage buffer, defaults to 15%
 * @returns Adjusted font size, with buffer applied
 */
export const useFitTextWithBuffer = (options: TOptions, buffer = 0.15) => {
    // Use max font size 2000 for a maximum of 20x (2000%) scaling
    const fit = useFitText(options);

    // Sometimes the text triggers an overflow, so we add a slight buffer to the calculated font size
    const fontSize = parseFloat(fit.fontSize) * (1-buffer);

    return {
        ...fit,
        fontSize
    };

};