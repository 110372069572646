import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Serialised } from '@squaredup/ids';
import { AlertingRuleV2, AlertingRules, sanitiseAlertingRules } from '@squaredup/monitoring';
import { isFeatureEnabled, Tier } from '@squaredup/tenants';
import LoadingSpinner from 'components/LoadingSpinner';
import { TruncatedText } from 'components/TruncatedText';
import Button from 'components/button';
import { useNotificationChannels, useNotificationChannelTypes } from 'components/hooks/useNotificationChannels';
import type { ProjectedChannel } from 'dynamo-wrapper';
import { NotificationDestinationIcon } from 'pages/monitoring/NotificationDestinationIcon';

export interface NotificationRuleSummaryForMonitorProps {
    dashboardId: string;
    alertingRules: AlertingRules | undefined;
    tier: Tier | undefined;
    tileId?: string;
    workspaceId?: string;
}

export const NotificationRuleSummaryForMonitor: React.FC<NotificationRuleSummaryForMonitorProps> = ({
    tileId,
    dashboardId,
    tier,
    alertingRules,
    workspaceId
}) => {
    const alertingRulesPresent = alertingRules !== undefined && alertingRules.length > 0;
    const { isLoadingChannels, channels } = useNotificationChannels(alertingRulesPresent);
    const { isLoadingChannelTypes, channelTypes } = useNotificationChannelTypes(alertingRulesPresent);

    if (alertingRulesPresent && (isLoadingChannels || isLoadingChannelTypes)) {
        return <LoadingSpinner size={16} />;
    }

    const channelsById = new Map(channels?.map((c) => [`${c.id}`, c]));
    const channelAvailableByTypeId = new Map(channelTypes?.map((ct) => 
        [ct.id, !ct.requiresFeature || (tier !== undefined && isFeatureEnabled(tier, ct.requiresFeature))])
    );

    const isTileExcluded = (rule: AlertingRuleV2) =>
        tileId && rule.conditions.monitors.dashboards?.[dashboardId]?.tiles?.[tileId]?.include === false;

    const isTileIncluded = (rule: AlertingRuleV2) =>
        rule.conditions.monitors.includeAllTiles ||
        rule.conditions.monitors.dashboards?.[dashboardId]?.includeAllTiles ||
        (tileId && rule.conditions.monitors.dashboards?.[dashboardId]?.tiles?.[tileId]?.include);

    const matchingDestinationIds = sanitiseAlertingRules(alertingRules ?? [])
        .filter((rule) => !isTileExcluded(rule) && isTileIncluded(rule))
        .flatMap((rule) => rule.channels.map((c) => c.id));

    // Remove any destinations that no longer exist, or are disabled
    const destinations = [...new Set(matchingDestinationIds)]
        .map((id) => channelsById.get(id))
        .filter((channel): channel is Serialised<ProjectedChannel> => 
            channel !== undefined && channel.enabled && channelAvailableByTypeId.get(channel.channelTypeId) === true)
        .sort(
            (a, b) =>
                (a.displayName ?? a.name)?.localeCompare(b.displayName ?? b.name, undefined, { sensitivity: 'base' })
        );

    const manageMessage = (
        <>
            Manage your notification rules on the{' '}
            <Button variant='link' href={`/monitoring/${workspaceId}`}>
                <span className='font-normal'>
                    Monitors <FontAwesomeIcon icon={'arrow-up-right-from-square'} fixedWidth />
                </span>
            </Button>{' '}
            page.
        </>
    );

    const headerText =
        destinations.length > 0
            ? `${destinations.length} notification rule${
                  destinations.length === 1 ? ' is' : 's are'
              } active for this monitor.`
            : 'There are no notification rules active for this monitor.';

    return (
        <div data-testid='notification-rule-summary-for-monitor'>
            <p className='text-sm text-textSecondary'>
                {headerText}{workspaceId && (<>{' '}{manageMessage}</>)}
            </p>
            {Boolean(destinations.length) && (
                <div className='flex flex-col justify-between gap-3 mt-4'>
                    {destinations.map((d) => (
                        <div key={d.id} data-testid='notification-destination' className='flex flex-row items-center'>
                            <NotificationDestinationIcon
                                channelTypeId={d.channelTypeId}
                                className='w-5 h-5'
                            />
                            {/** Be careful modifying the classes/structure, as it is easy to break 
                                 the overflow or hover on this element */}
                            <TruncatedText className='min-w-0 pl-2 text-sm font-semibold' title={d.displayName ?? d.name} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
