import type { TileConfig } from '@squaredup/dashboards';
import type { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import { formatDistance } from 'date-fns';
import stringify from 'fast-json-stable-stringify';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

/**
 * Get the date that the data stream data for the given tile config was last
 * read from the plugin. This will load the data if it isn't loaded already.
 */
export const useLastRefreshed = (tileConfig: TileConfig) => {
    const config = useMemo((): DataStreamBaseTileConfig => {
        return tileConfig._type === 'tile/data-stream'
            ? (tileConfig as DataStreamBaseTileConfig)
            : { _type: 'tile/data-stream' };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringify(tileConfig)]);

    const { data, queryKey: dataQueryKey } = useDataStreamConfig(config, {}, 'dashboard');

    const { data: lastRefreshed } = useQuery(
        [...(dataQueryKey ?? []), 'lastRefreshed', data.metadata.requestStats.completedAt],
        async () => data.metadata.requestStats.completedAt,
        {
            enabled: data != null && dataQueryKey != null && data.metadata.requestStats.completedAt > 0,
            refetchInterval: 30_000,
            staleTime: 0,
            cacheTime: 0
        }
    );

    return lastRefreshed == null || lastRefreshed === 0
        ? {}
        : {
              lastRefreshedDate: new Date(lastRefreshed),
              /**
               * A string describing how recently the data was loaded in a user-friendly way.
               * The string is updated every 30 seconds.
               *
               * @example 'Last refreshed: 5 minutes ago'
               */
              lastRefreshedString: `Last refreshed: ${formatDistance(
                  lastRefreshed,
                  /**
                   * Max in case the client clock is slow compared to the backend,
                   * we don't want to say we refreshed 'in less than a minute'
                   */
                  Math.max(Date.now(), lastRefreshed),
                  { addSuffix: true }
              )}`
          };
};
