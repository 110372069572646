import { KPI_NODE, KPI_NODE_SIZE } from 'components/map/components/nodes/KPINode';

export const kpiToNode = (workspace: any) => {

    return {    
        id: workspace.id,
        type: KPI_NODE,
        position: {
            x: 0,
            y: 0
        },
        width: KPI_NODE_SIZE,
        height: KPI_NODE_SIZE,
        data: {
            label: workspace.name[0],
            workspaceId: workspace.sourceId[0],
            ...workspace.kpis && {
                kpis: workspace.kpis,
                kpiCount: JSON.parse(workspace.kpis).length
            }
        }
    };
};
