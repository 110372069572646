import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import Tooltip from 'components/tooltip/Tooltip';
import config from 'config';
import useOverflowing from 'lib/useOverflowing';
import { useState } from 'react';
import { useQuery } from 'react-query';
import Auth from 'services/Auth';
import { setSignInPrompt } from 'services/Auth/signInIdpPrompt';
import { TENANT_DATA, Tenant } from 'services/TenantService';
import { ThemeChooser } from './ThemeChooser';
import { NavTitle } from './ui/NavLinks';

const { Release, Branch, Stage, Environment } = config;

const regionDisplayNames: Record<string, string> = {
    Production: 'US',
    ProductionEU: 'Europe',
    ProductionUK: 'UK',
    Development: 'Development',
    'Pre-Prod': 'Pre-production',
    Local: 'Local'
};

export const AccountMenu: React.FC = () => {
    const { data: tenant } = useQuery(TENANT_DATA, Tenant);
    const [showAccountInfo, setShowAccountInfo] = useState(false);

    const { ref: organisationOverflowRef, isOverflowing: organisationIsOverflowing } = useOverflowing<HTMLHeadingElement>();
    const { ref: emailOverflowRef, isOverflowing: emailIsOverflowing } = useOverflowing<HTMLHeadingElement>();

    return (
        <>
            <NavTitle
                title='Account'
                icon={faUser}
                iconClassName='mr-3 ml-[2px]'
                className={cn('mt-3', showAccountInfo && 'text-textPrimary')}
                onClick={() => setShowAccountInfo(!showAccountInfo)}
                data-testid='account-button'
            />

            {showAccountInfo && (
                <>
                    <div className='absolute inset-0 w-screen bg-opacity-0' onClick={() => setShowAccountInfo(false)} />
                    <div className='relative w-full ml-sm group'>
                        <div
                            className='absolute z-50 block w-[310px] border shadow-none bottom-[calc(100%+22px)] -left-1 rounded-b-input bg-backgroundSecondary text-textPrimary border-modalOutline p-sm group'
                            data-testid='profile-menu'
                        >
                            <div className='mb-sm'>
                                <Tooltip title={tenant?.displayName} disabled={!organisationIsOverflowing}>
                                    <Text.H4
                                        className='truncate mb-xxs'
                                        data-testid='current-tenant'
                                        ref={organisationOverflowRef}
                                    >
                                        {tenant?.displayName}
                                    </Text.H4>
                                </Tooltip>

                                <Tooltip title={Auth.user?.email} disabled={!emailIsOverflowing}>
                                    <Text.H5
                                        className='truncate text-textSecondary mt-xxxs mb-xxs'
                                        data-testid='current-user'
                                        ref={emailOverflowRef}
                                    >
                                        {Auth.user?.email}
                                    </Text.H5>
                                </Tooltip>

                                <Text.Label
                                    className='block text-textSecondary'
                                    title={`${Branch}-${Stage}`}
                                    data-testid='current-version'
                                >
                                    Release {Release} / Region: {regionDisplayNames[Environment] ?? 'Unknown'}
                                </Text.Label>
                            </div>

                            <ThemeChooser />

                            <hr className='border-outlinePrimary my-md' />

                            <div className='space-y-1.5 flex flex-col text-base font-semibold items-start'>
                                <button
                                    className='items-start cursor-pointer leading-input focus:ring-0 focus:outline-none text-textSecondary hover:text-textPrimary'
                                    onClick={() => Auth.selectTenant()}
                                >
                                    Change organization
                                </button>
                                {Auth.user?.isLocal && (
                                    <button
                                        className='items-start cursor-pointer leading-input focus:ring-0 focus:outline-none text-textSecondary hover:text-textPrimary'
                                        onClick={() => Auth.resetPassword()}
                                    >
                                        Change password
                                    </button>
                                )}
                                <button
                                    className='cursor-pointer text-statusErrorPrimary leading-input focus:ring-0 focus:outline-none'
                                    onClick={() => {
                                        // User explicitly signing out, so may want to switch account on next sign-in.
                                        // (We should probably remove this if we ever add an explicit 'Switch user'
                                        // option in the UI.)
                                        setSignInPrompt('select_account');
                                        Auth.logout();
                                    }}
                                >
                                    Sign out
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
