import { AppContext } from 'contexts/AppContext';
import { useContext, useEffect } from 'react';

export function withoutWorkspace<P extends Record<string, any> = Record<string, never>>(
    Component: React.ComponentType<P>
) {
    const WithoutWorkspace = (props: P) => {
        const { setCurrentWorkspaceID } = useContext(AppContext);

        useEffect(() => {
            setCurrentWorkspaceID();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return <Component {...(props as P)} />;
    };

    WithoutWorkspace.displayName = `withoutWorkspace-${Component.displayName}`;

    return WithoutWorkspace;
}
export default withoutWorkspace;
