import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Auth from 'services/Auth';

/**
 * LoginPage
 * Provides two login methods to authorise user, either using Microsoft
 * Account or username & password combination.
 *
 * @example
 * `<LoginPage />`
 */
function LoginPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // This should only happen if the user manually navigates to /login
    // If user is already logged in, redirect them back to home page
    if (Auth.user) {
      navigate('/');
    }
  }, [navigate]);


  if (!Auth.user) {
    // Redirect to auth login if there is no user
    Auth.login();
  }

  return null;
}

export default LoginPage;
