import { Node } from '@squaredup/graph';
import type { Config } from 'dynamo-wrapper';
import { BuiltInTypes } from 'lib/types';
import { PluginSourceConfig } from 'services/SourceConfigService';

type BuiltInTypeKeys = keyof typeof BuiltInTypes;

export const getNamesForSource = (
    datasources: Config<object>[] | PluginSourceConfig[] | undefined,
    node: Node | Record<string, unknown>,
    configId: string
) => {
    const typedNode = node as Node;     
    let sourceName = '';
    let sourceInstanceName = ''; 

    if (`${typedNode.sourceName}` === 'Workspace' && typedNode.sourceType?.[0]?.startsWith('squaredup/')) {
        const builtInTypeKey: BuiltInTypeKeys = typedNode.type?.[0] as BuiltInTypeKeys;
        sourceName = BuiltInTypes[builtInTypeKey]?.singular || `${typedNode.sourceName}`;
    } else {
        const datasource = datasources?.find((d) => String(d.id) === configId);

        if (!datasource) {
            // Could not find datasource
            sourceName =  `${typedNode.sourceName}`;
            sourceInstanceName = `${typedNode.sourceName}`;
        } else {
            sourceName = (datasource?.plugin as PluginSourceConfig).displayName?.toString() || `${typedNode.sourceName}`;
            sourceInstanceName = datasource.displayName?.toString() || `${typedNode.sourceName}`;
        }
    }

    return { sourceConfigName: sourceName, sourceInstanceName: sourceInstanceName };
};
