import { cn } from '@/lib/cn';
import { TileError } from './TileError';

interface TileGenericErrorFallBackProps {
    className?: string;
    reset?: () => void;
}

export const TileGenericErrorFallBack: React.FC<TileGenericErrorFallBackProps> = ({ 
    className, 
    reset
}) => {
    return (
        <div className={cn('h-full w-full', className)}>
            <div className='flex flex-col items-center justify-center w-full h-full max-h-full p-4'>
                <TileError
                    heading='Something went wrong with this tile'
                    showDetail={false}
                    reset={reset}
                /> 
            </div>
        </div>
    );
};
