import LoadingSpinner from 'components/LoadingSpinner';
import { TruncatedText } from 'components/TruncatedText';
import type { Plugin } from 'dynamo-wrapper';
import PluginIcon from 'pages/scope/PluginIcon';
import { useDatasourceDocumentationLink } from 'queries/hooks/useDatasourceDocumentationLink';
import { useDatasourceMarketingLink } from 'queries/hooks/useDatasourceMarketingLink';
import { forwardRef } from 'react';
import { PluginDocs } from './PluginDocs';
import { PluginPill } from './PluginPill';
import { usePlugin } from './usePlugin';
import { useDocsLink } from '../useDocsLink';
interface PluginDetailPanelProps {
    pluginId: Plugin['id']['value'];
}

export const PluginDetailPanel = forwardRef<
    HTMLIFrameElement,
    PluginDetailPanelProps
>(({ pluginId }, ref) => {
    const { data: plugin, isLoading: isLoadingPlugin } = usePlugin(pluginId);
    const { data: docsLink } = useDatasourceDocumentationLink(plugin?.id);
    const { data: marketingLink } = useDatasourceMarketingLink(plugin?.id);

    const documentationPath = useDocsLink(docsLink);

    return (
        <div className='flex flex-col h-full min-h-0 col-start-2 col-end-3 row-start-1 row-end-3 px-8 py-8 pb-4 border-l bg-tileBackground border-dividerPrimary text-textSecondary'>
            {!isLoadingPlugin && 
                <>
                    <div className='flex mb-3 text-textPrimary'>
                        <div className='w-20 h-20 mr-3 shrink-0'>
                            <PluginIcon pluginName={plugin?.displayName} />
                        </div>
                        <div className='flex flex-col truncate'>
                            <h3 className='block w-full mb-1 text-xl font-bold leading-tight truncate'>
                                <TruncatedText element='span' title={plugin?.displayName ?? ''}>
                                    {plugin?.displayName}
                                </TruncatedText>
                            </h3>
                            
                            <p>By {plugin?.author}</p>
                            <div className='mt-auto'>
                                <PluginPill onPrem={plugin?.onPrem} />
                            </div>
                        </div>
                    </div>

                    <div className='mt-4 mb-6 mr-3 space-y-2'>
                        <p>
                            {plugin?.description}
                            {marketingLink && 
                                <>
                                    {' '}
                                    <a 
                                        href={marketingLink} 
                                        target='_blank' 
                                        rel='noopener noreferrer'
                                        className='text-textLink'
                                    >
                                        {'Learn more'}
                                    </a>
                                </>
                            }
                        </p>
                    </div>
                </>
            }

            {documentationPath &&
                <PluginDocs 
                    documentationPath={documentationPath}
                    pluginDisplayName={plugin?.displayName}
                    ref={ref}
                />
            }
        </div>
    );
});
