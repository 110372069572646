/* eslint-disable camelcase */
import API, { axiosNoAuth } from './API';
import { handleError, handleResponse } from './util';

export interface OpenAccessIdToken {
    id_token: string;
    openAccessId: string;
    openAccessTenantId: string;
    openAccessTargetId: string;
    openAccessWorkspaceId: string;
    refreshContext?: RefreshTokenContext;
}

export interface RefreshTokenContext {
    expires: number;
    sessionId: string;
    sessionExpires: number;
    userEmail: string;
    refreshToken: string;
}

export interface ShareRestrictions {
    restrictedToUsers?: boolean;
    allowExtendedSession?: boolean;
    hidden?: boolean;
}

/**
 * Get an id_token (JWT) to use with open access
 */
export const GetIdToken = async (openAccessId: string): Promise<OpenAccessIdToken> =>
    // The endpoint to retrieve the id_token_hint is unauthenticated - the whole point of it
    // is to enable us to login (for open access) so by definition we haven't logged in yet and
    // don't have a JWT to use to perform an authenticated API call.
    axiosNoAuth
        .get<OpenAccessIdToken>(`/openaccess/idtoken/${openAccessId}`)
        .then(handleResponse)
        .catch((err) => handleError(err, true));

/**
 * Get an id_token (JWT) to use with open access shares that require authentication.
 * This version is for authenticated Open Access, where only certain users are allowed
 * to access the dashboard.
 */
export const GetIdTokenAuthenticated = async (openAccessId: string): Promise<OpenAccessIdToken> =>
    API.get<OpenAccessIdToken>(`/openaccess/idtoken/${openAccessId}/authenticated`)
        .then(handleResponse)
        .catch((err) => handleError(err, true));

/**
 * Find out if the Open Access share is fully open to anonymous users or restricted to specific
 * authenticated users.
 */
export const GetShareRestrictions = async (openAccessId: string) =>
    axiosNoAuth
        .get<ShareRestrictions>(`/openaccess/shares/${openAccessId}/restrictions`)
        .then(handleResponse)
        .catch((err) => handleError(err, true));

/**
 * Get a new token (JWT) to use with open access shares that require authentication, using
 * a refresh token acquired from a call to GetIdTokenAuthenticated.
 *
 * This is used to keep an authenticated Open Access session going (until the max session duration is reached)
 * without requiring a new login.
 */
export const RefreshOpenAccessToken = async (
    openAccessId: string,
    context: RefreshTokenContext
): Promise<OpenAccessIdToken> =>
    axiosNoAuth
        .post<OpenAccessIdToken>(`/openaccess/idtoken/${openAccessId}/refresh`, context, {
            headers: {
                [['3', '_', 'p', 'u', 'q', 's', '_', 'x'].reverse().join('')]: getSqup3(openAccessId, context)
            }
        })
        .then(handleResponse)
        .catch((err) => handleError(err, true));

function getSqup3(shareId: string, context: RefreshTokenContext): string {
    const str = `${shareId}-${context.expires}-${context.sessionId}-${context.refreshToken}`;
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = (hash << 7) - hash + str.charCodeAt(i);
        hash |= 0;
    }
    return Math.abs(hash).toString();
}
