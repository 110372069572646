import { faBullseye, faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { DataStreamDefinitionPartial } from '@squaredup/data-streams';
import { PluginId, Serialised } from '@squaredup/ids';
import clsx from 'clsx';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons, ifNotOutside } from 'components/Modal';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import Form from 'components/forms/form/Form';
import type { Plugin } from 'dynamo-wrapper';
import _ from 'lodash';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { OptionProps, components } from 'react-select';
import { CUSTOM_DATA_STREAM_DEFINITIONS, Create, Update } from 'services/DataStreamDefinitionService';
import { Get, PLUGINS } from 'services/PluginService';
import { CodeEditor } from 'ui/editor/components/CodeEditor';
import { CustomDataStreamProjectedRow } from './DataStreamDefinitions';

interface DataSourceEntry {
    displayName: string;
    value: string;
    label: string;
    type: string;
    description: string;
}

type CustomDataStreamFormData = {
    displayName: string;
    pluginId: { label: string; value: PluginId | `plugin-${string}` };
    dataSourceName: DataSourceEntry;
};

type DataStreamDefinitionAddEditModalProps = {
    onClose: () => void;
    onSave: () => void;
    stream: CustomDataStreamProjectedRow | undefined;
    dataSources: {
        label: string | undefined;
        value: string | undefined;
    }[];
};

type Defaults = {
    displayName: null | string;
    pluginId: { label: string | undefined; value: string | undefined };
    dataSourceName: null | string;
};

const asOption = (v: string | undefined) => ({ label: v, value: v });

export const DataStreamDefinitionAddEditModal: React.FC<DataStreamDefinitionAddEditModalProps> = ({
    onClose,
    onSave,
    stream,
    dataSources
}) => {
    const queryClient = useQueryClient();

    const [configJson, setConfigJson] = useState<DataStreamDefinitionPartial | undefined>(stream?.definition);
    const [pluginId, setPluginId] = useState(stream?.pluginId);
    const [codeContainsError, setCodeContainsError] = useState(true);
    const [validPluginId, setValidPluginId] = 
    useState(stream?.pluginId && dataSources.some((p) => p.value === stream?.pluginId));

    const { data: dataSourceItems, isLoading: isEntryPointsLoading } = useLookupEntryPointsForPlugin(
        pluginId,
        validPluginId
    );

    const defaults: Defaults = {
        displayName: null,
        pluginId: asOption(undefined),
        dataSourceName: ' '
    };

    if (stream) {
        defaults.displayName = stream.displayName;
        defaults.pluginId = asOption(validPluginId ? stream.pluginId : undefined);
        defaults.dataSourceName = stream.dataSourceName;
    }

    const { mutateAsync: saveCustomDataStream } = useMutation(
        async (data: CustomDataStreamFormData) => {
            const defaultedJson = configJson ?? {};

            if (stream?.id != null) {
                await Update(stream.id, {
                    definition: defaultedJson,
                    displayName: data.displayName,
                    dataSourceName: data.dataSourceName.value
                        ? data.dataSourceName.value
                        : data.dataSourceName.toString(),
                    pluginId: data.pluginId.value
                });
            } else {
                await Create({
                    definition: defaultedJson,
                    displayName: data.displayName,
                    dataSourceName: data.dataSourceName.value,
                    pluginId: data.pluginId.value,
                    parentPluginVersion: ''
                });
            }
        },
        {
            onSuccess: () => {
                onSave();
                onClose();
            },
            onSettled: async () => {
                queryClient.invalidateQueries(PLUGINS);
                queryClient.invalidateQueries([CUSTOM_DATA_STREAM_DEFINITIONS]);
            }
        }
    );

    return (
        <Modal
            title={stream ? `Edit custom data stream: ${stream.displayName}` : 'Add custom data stream'}
            close={ifNotOutside(onClose)}
            fullWidth
            maxWidth='max-w-6xl'
        >
            <Form submit={saveCustomDataStream} defaultValues={defaults} className='flex flex-col flex-1 min-h-0'>
                {(isValid, isSubmitting) => (
                    <>
                        <div className='px-8 tile-scroll-overflow'>
                            <div className='mb-4'>
                                <Field.Input
                                    name='displayName'
                                    label='Name'
                                    title='Name'
                                    placeholder='Enter a name'
                                    validation={{
                                        required: true,
                                        maxLength: 128,
                                        minLength: 5
                                    }}
                                />
                            </div>

                            {stream && !validPluginId && (
                                <div className='flex items-center mt-4 mb-8'>
                                    <span className='text-sm text-statusErrorPrimary'>
                                        The data source / entry point is no longer available. Please reconfigure this custom data stream.
                                    </span>
                                </div>
                            )}

                            <div className='flex '>
                                <div className='flex-1 mb-4'>
                                    {dataSources == null ? (
                                        <LoadingSpinner />
                                    ) : (
                                        <DataSourceDropDown
                                            dataSources={dataSources}
                                            setPluginId={setPluginId}
                                            setValidPluginId={setValidPluginId}
                                        ></DataSourceDropDown>
                                    )}
                                </div>

                                <div className='flex-1 mb-4 ml-2'>
                                    <>
                                        <Field.Input
                                            name='dataSourceName'
                                            label='Entry point *'
                                            title='Entry point'
                                            type='autocomplete'
                                            options={dataSourceItems}
                                            isMulti={false}
                                            isLoading={isEntryPointsLoading}
                                            placeholder='Select an entry point to read from'
                                            validation={{
                                                validate: (v) => {
                                                    if (v && v !== ' ') {
                                                        return true;
                                                    }
                                                    return 'Entry point is required.';
                                                }
                                            }}
                                            components={{ Option: CustomDataSourceOption }}
                                            formatOptionLabel={(d: DataSourceEntry) => d.displayName}
                                        />
                                    </>
                                </div>
                            </div>

                            <label className='flex items-end mb-2 font-medium'><span className='flex'>Code</span></label>
                            <div className='text-sm text-textSecondary'>
                                <div className='flex flex-col w-full mb-4 min-h-[12.5rem] border border-outlinePrimary'>
                                    <CodeEditor
                                        content={configJson}
                                        onValidUpdatedContent={setConfigJson}
                                        handleError={setCodeContainsError}
                                    />
                                </div>
                            </div>
                        </div>
                        <ModalButtons>
                            <Button type='button' onClick={() => onClose()} variant='tertiary'>
                                Cancel
                            </Button>
                            <Button type='submit' disabled={isSubmitting || codeContainsError || !isValid || !validPluginId}>
                                {isSubmitting ? <LoadingSpinner size={18} /> : 'Save'}
                            </Button>
                        </ModalButtons>
                    </>
                )}
            </Form>
        </Modal>
    );
};

/**
 * Custom react-select Option component for the data source / endpoint list
 */
const CustomDataSourceOption = (props: OptionProps<DataSourceEntry>) => {
    return (
        <components.Option {...props}>
            <div className='text-sm'>
                <div>
                    {props.data.displayName}{' '}
                    <span className={clsx('font-mono text-textSecondary', props.isSelected && 'text-textPrimary')}>
                        ({props.data.value})
                    </span>
                </div>
                <span className={clsx('text-textSecondary', props.isSelected && 'text-textPrimary')}>
                    {props.data.description}
                </span>
            </div>
        </components.Option>
    );
};

const toDataSourceEntryPointsList = (
    plugin?: Serialised<Plugin>
): { label: ReactElement; options: DataSourceEntry[] }[] => {
    const items = plugin?.dataSources.map((d: any) => {
        return {
            displayName: d.displayName,
            value: d.name,
            label: d.name,
            type: d.supportedScope === 'none' ? 'unscoped' : 'scoped',
            description: d.description
        };
    });

    return [
        {
            label: (
                <div className='mb-2'>
                    <FontAwesomeIcon icon={faBullseye} className='mr-1' />
                    Scoped
                </div>
            ),
            options: _.sortBy(
                items?.filter((i) => i.type === 'scoped'),
                'displayName'
            )
        },
        {
            label: (
                <div className='mb-2'>
                    <FontAwesomeIcon icon={faGlobe} className='mr-1' />
                    Global
                </div>
            ),
            options: _.sortBy(
                items?.filter((i) => i.type === 'unscoped'),
                'displayName'
            )
        }
    ];
};

const useLookupEntryPointsForPlugin = (pluginId: any, validPluginId: boolean | undefined) =>
    useQuery(pluginId ?? 'NoPlugin', () => (pluginId != null ? Get(pluginId) : undefined), {
        select: toDataSourceEntryPointsList,
        enabled: validPluginId,
        staleTime: Number.POSITIVE_INFINITY
    });

const DataSourceDropDown = (props: {
    dataSources: { label: string | undefined; value: string | undefined }[];
    setPluginId: Dispatch<SetStateAction<`plugin-${string}` | undefined>>;
    setValidPluginId: Dispatch<SetStateAction<boolean | undefined>>
}) => {
    const ctx = useFormContext();
    return (
        <Field.Input
            name='pluginId'
            label='Data source'
            title='Data source'
            type='autocomplete'
            options={props.dataSources}
            isMulti={false}
            onSelect={(p: { value: PluginId['value'] }) => {
                ctx.resetField('dataSourceName', { defaultValue: ' ' });
                if (props.setPluginId && p !== null) {
                    props.setPluginId(p.value);
                    props.setValidPluginId(true);
                }
            }}
            placeholder='Select a data source'
            validation={{ required: true }}
        />
    );
};
