import { useRefreshContext } from 'contexts/RefreshContext';
import { useEffect, useRef } from 'react';

/**
 * Register a handler to respond whenever a RefreshContext triggers
 * a data refresh.
 */
export const useRefreshHandler = (forceRefetch: () => void) => {
    const { refreshCount } = useRefreshContext();
    const isMounted = useRef(false);

    useEffect(() => {
        if (refreshCount > 0 && isMounted.current) {
            forceRefetch();
        }

        isMounted.current = true;
    }, [forceRefetch, refreshCount]);
};
