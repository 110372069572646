import type { TileConfig } from '@squaredup/dashboards';
import type { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { useRefreshContext } from 'contexts/RefreshContext';
import stringify from 'fast-json-stable-stringify';
import { useMemo } from 'react';
import { useIsFetching } from 'react-query';
import { useDataStreamConfig } from './useDataStreamConfig';

/**
 * Trigger and monitor force refreshes of all `useDataStream` hooks within
 * the current RefreshContext.
 */
export const useForceRefresh = (tileConfig: TileConfig) => {
    const config = useMemo((): DataStreamBaseTileConfig => {
        return tileConfig._type === 'tile/data-stream'
            ? (tileConfig as DataStreamBaseTileConfig)
            : { _type: 'tile/data-stream' };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringify(tileConfig)]);

    const { forceRefresh } = useRefreshContext();
    const { queryKey } = useDataStreamConfig(config, {}, 'dashboard');

    const isRefreshing = useIsFetching({ queryKey }) > 0 && queryKey != null;

    return {
        forceRefresh: () => {
            if (!isRefreshing) {
                forceRefresh();
            }
        },
        isRefreshing
    };
};
