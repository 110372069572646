import { Serialised } from '@squaredup/ids';
import { ChannelTypeIds } from '@squaredup/monitoring';
import { ConfirmationPrompt } from 'components/ConfirmationPrompt';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import type { ProjectedChannel } from 'dynamo-wrapper';
import { useWorkspacesForAdministration } from 'queries/hooks/useWorkspaceForAdministration';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CHANNELS, Delete, List, Update } from 'services/NotificationChannelService';
import { SettingsTemplate } from '../SettingsTemplate';
import { NotificationsChannelAddEditModal } from './components/NotificationsChannelAddEditModal';
import { NotificationsTable } from './components/NotificationsTable';
import { NotificationsWorkspacesModal } from './components/NotificationsWorkspacesModal';
import { useTier } from 'queries/hooks/useTier';
import { useNotificationChannelTypes } from 'components/hooks/useNotificationChannels';

const queryClientSettings = { refetchOnWindowFocus: false };

export default function Notifications() {
    const queryClient = useQueryClient();

    const [creating, setCreating] = useState(false);
    const [editing, setEditing] = useState<Serialised<ProjectedChannel> | undefined>();
    const [deleting, setDeleting] = useState<Serialised<ProjectedChannel> | undefined>();
    const [inspecting, setInspecting] = useState<Serialised<ProjectedChannel> | undefined>();

    const { data: channels, isLoading: isLoadingChannels } = useQuery(CHANNELS, List, queryClientSettings);
    const { data: workspaces, isLoading: isLoadingWorkspaces } = useWorkspacesForAdministration({ 
        refetchInterval: 60_000 
    });
    const { data: tier, isLoading: isLoadingTier } = useTier();
    const { channelTypes, isLoadingChannelTypes } = useNotificationChannelTypes();

    const handlePlayPauseChannel = async (id: string, enabled: boolean) => {
        await Update(id, {
            enabled
        });

        queryClient.invalidateQueries(CHANNELS);
    };

    const { mutateAsync: handleDeletion } = useMutation(Delete, {
        onSettled: () => queryClient.invalidateQueries(CHANNELS)
    });

    const isLoading = isLoadingChannels || isLoadingWorkspaces || isLoadingTier || isLoadingChannelTypes;

    return (
        <SettingsTemplate
            title='Notifications'
            description='Manage destination channels to receive state changes from your monitors.'
            learnMoreLink='https://squaredup.com/cloud/Health-notifications'
            flex
        >
            {isLoading && (
                <span className='flex justify-center'>
                    <LoadingSpinner />
                </span>
            )}
            {!isLoading && (
                <div className='flex flex-col flex-1 min-h-0'>
                    <div>
                        <Button onClick={() => setCreating(true)} data-testid='addDestination'>
                            Add destination
                        </Button>
                    </div>

                    <div className='flex flex-col min-h-0 mt-4 mb-8'>
                        <NotificationsTable
                            channels={channels ?? []}
                            setDeleting={(id) => setDeleting(channels?.find((c) => c.id === id))}
                            setEditing={(id) => setEditing(channels?.find((c) => c.id === id))}
                            setEnabledState={handlePlayPauseChannel}
                            workspaces={workspaces ?? []}
                            setInspectingWorkspaceCount={setInspecting}
                            tier={tier}
                            channelTypes={channelTypes ?? []}
                        />
                    </div>
                </div>
            )}
        
            {/* Add/Edit/Delete Modals */}
            {(editing || creating) && (
                <NotificationsChannelAddEditModal
                    existingChannelNames={channels?.map((c) => c.displayName ?? '') ?? []}
                    initialChannel={editing}
                    close={async () => {
                        setCreating(false);
                        setEditing(undefined);
                        await queryClient.invalidateQueries(CHANNELS);
                    }}
                    tier={tier}
                />
            )}

            {deleting && (
                <ConfirmationPrompt
                    title={`Delete Destination: ${deleting.displayName}`}
                    prompt={
                        deleting.channelTypeId !== ChannelTypeIds.Zapier
                            ? 'Are you sure you want to permanently delete this destination? All workspace notification rules using this destination will be disabled.'
                            : 'Are you sure you want to permanently delete this Zapier destination? All workspace notification rules using this destination will be disabled, and any Zapier applications using this destination will no longer work.'
                    }
                    confirmButtonText='Delete'
                    confirmButtonVariant='destructive'
                    onConfirm={async () => {
                        await handleDeletion(deleting.id);
                    }}
                    onClose={() => setDeleting(undefined)}
                />
            )}

            {inspecting && (
                <NotificationsWorkspacesModal
                    channel={inspecting}
                    workspaces={workspaces ?? []}
                    close={async () => {
                        setInspecting(undefined);
                    }}
                />
            )}
        </SettingsTemplate>
    );
}
