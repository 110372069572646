import graphNodes from '../graphNodes/graphNodes';
import { DatasourceFunction } from '../../../types/Datasource';
import { GraphData } from '../../../types/data/GraphData';
import { ConnectionsConfig } from './Config';

const connections: DatasourceFunction<GraphData, ConnectionsConfig> = async(config, context) => {
    const id = config.id || context?.id;
    const type = config.type;
    const sourceTypeContains = config.sourceTypeContains;
    const configId = config.configId || context?.configId;


    const { data } = await graphNodes( { related: id, type, sourceTypeContains, configId } );
    return {
        data: {
            nodes: data.filter(n => n.id !== id)
        }
    };
};

connections.config = 'ConnectionsConfig';

export default connections;
