import { Serialised } from '@squaredup/ids';
import type { AccessControlEntryModel, Config, Plugin, ProjectedPlugin } from 'dynamo-wrapper';
import trackEvent from 'lib/analytics';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { useMutation, useQueryClient } from 'react-query';
import { AccessControlQueryKeys } from 'services/AccessControlService';
import { Create, Update } from 'services/SourceConfigService';
import { AddPluginLinksToWorkspace } from 'services/WorkspaceService';
import { invalidateAfterWorkspaceLinksChange } from 'services/WorkspaceUtil';
import { PluginConfigFormData } from '../types';
import { getEditMode } from './getEditMode';
import { usePlugin } from './usePlugin';
import { customTypesQueryKeys } from 'queries/queryKeys/customTypesKeys';

interface MutatePluginProps {
    config: (Serialised<Config<Omit<PluginConfigFormData, 'displayName'>>> & { json?: string }) | undefined;
    selectedPlugin: Serialised<ProjectedPlugin>;
    acl?: AccessControlEntryModel[];
    workspaceToLinkTo?: string;
    installSampleDashboards?: boolean;
    onSubmit: (details: PluginMutationDetails) => void;
}

export const RECENT_NAME = '_squp-recent-name_';

export interface PluginMutationDetails {
    type: 'new' | 'existing';
    newId: string;
    installSampleDashboards: boolean;
}

// Get the subset of plugin properties which are saved in the plugin property of a plugin config record
type PluginProperties = {
    pluginId: string;
    name: string | undefined;
    displayName: string | undefined;
    lambdaName: string;
    version: string;
    onPrem: boolean;
    importNotSupported: boolean;
    agentGroupId?: string;
};

export function getRequiredPluginProperties(thePlugin: Serialised<Plugin>): PluginProperties {
    return {
        pluginId: thePlugin.id,
        name: thePlugin.pluginName ?? thePlugin.displayName,
        displayName: thePlugin.displayName,
        lambdaName: thePlugin.lambdaName,
        version: thePlugin.version,
        onPrem: thePlugin.onPrem,
        importNotSupported: thePlugin.importNotSupported
    };
}

export const useMutatePlugin = ({
    config,
    selectedPlugin,
    workspaceToLinkTo = '',
    installSampleDashboards,
    acl,
    onSubmit
}: MutatePluginProps) => {
    const { id: pluginId } = selectedPlugin ?? { id: '' };
    const { data: plugin } = usePlugin(pluginId);

    const queryClient = useQueryClient();

    const editMode = getEditMode(config);

    const {
        error,
        mutate: submit,
        isLoading: isSubmitting
    } = useMutation(
        async ({ data }: { data: PluginConfigFormData; wait?: number }) => {
            // Permissions filtered out so they aren't saved as part of the config
            const { displayName: dataDisplayName, agentGroupId, permissions, ...dataConfig } = data;

            let pluginProperties;

            // If creating a config for a Plugin, pull out necessary properties and add them to the config
            if (plugin) {
                dataConfig.pluginId = pluginId;
                dataConfig.lambdaName = plugin.lambdaName;
                dataConfig.version = plugin.version;

                pluginProperties = getRequiredPluginProperties(plugin);
            }

            if (editMode === 'existing') {
                const configId = config?.id as string;
                await Update(configId, dataDisplayName, dataConfig, agentGroupId?.value, acl);
                queryClient.invalidateQueries(AccessControlQueryKeys.EntityACL(configId));
                queryClient.invalidateQueries(AccessControlQueryKeys.CallerPermissionsOnEntity(configId));

                trackEvent(`${pluginProperties ? 'Plugin Updated' : 'Data Source Updated'}`, {
                    type: plugin?.displayName
                });
                return;
            }

            const names = JSON.parse(sessionStorage.getItem(RECENT_NAME) ?? '[]');
            sessionStorage.setItem(RECENT_NAME, JSON.stringify([...names, dataDisplayName]));

            const configId = await Create(
                dataDisplayName,
                dataConfig,
                pluginProperties ?? {},
                agentGroupId ? agentGroupId.value : '',
                false,
                acl
            );

            if (workspaceToLinkTo) {
                await AddPluginLinksToWorkspace(workspaceToLinkTo, [configId]);
            }

            invalidateAfterWorkspaceLinksChange(queryClient);
            queryClient.invalidateQueries(dashboardQueryKeys.all);

            // Plugin may have associated custom types - make sure we load them.
            queryClient.invalidateQueries(customTypesQueryKeys.list);

            return configId;
        },
        {
            onSuccess(newId) {
                const details: PluginMutationDetails = {
                    type: newId ? 'new' : 'existing',
                    newId: newId ?? '',
                    installSampleDashboards: installSampleDashboards ?? false
                };
                onSubmit(details);
            }
        }
    );

    return {
        error,
        isSubmitting,
        submit
    };
};
