import { useState } from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { ConfirmationPrompt } from 'components/ConfirmationPrompt';
import { useWorkspaceCanWrite } from 'services/AccessControlService';
import { LinkImportModal } from './LinkImportModal';
import { useNavigate } from 'react-router-dom';
import { useWorkspaceLinking } from './useWorkspaceLinking';
import { useDefaultDashboardCreation } from './useDefaultDashboardCreation';

type ModalState = 'add' | 'remove' | 'off';

export const LinkingButton = ({
    datasourceId,
    datasourceName,
    workspaceId,
    pluginId
}: {
    datasourceId: string;
    datasourceName: string;
    workspaceId: string;
    pluginId: string;
}) => {
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [modalState, setModalState] = useState<ModalState>('off');
    const navigate = useNavigate();

    const { mutate: createDashboards } = useDefaultDashboardCreation();

    const { isLoading, hasWorkspaceData, isLinked, unlinkConfigFromWorkspace, linkConfigToWorkspace } =
        useWorkspaceLinking(workspaceId);

    const { data: canWriteToWorkspace, isLoading: isLoadingWorkspacePermissions } = useWorkspaceCanWrite(workspaceId);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (isLoadingWorkspacePermissions || !canWriteToWorkspace) {
        return <></>;
    }

    if (!hasWorkspaceData) {
        return <div>Could not fetch workspace!</div>;
    }

    const linkWorkspace = async (importDashboards: boolean) => {
        setButtonEnabled(false);
        setModalState('off');
        await linkConfigToWorkspace(datasourceId);
        if (importDashboards) {
            await createDashboards({ configId: datasourceId, pluginId, workspaceId });
        }
        setButtonEnabled(true);
    };

    const unlinkWorkspace = async () => {
        setButtonEnabled(false);
        setModalState('off');
        await unlinkConfigFromWorkspace(datasourceId);
        setButtonEnabled(true);
        navigate(`/datasources/${workspaceId}`);
    };

    return (
        <div>
            {modalState === 'off' && (
                <>
                    {isLinked(datasourceId) ? (
                        <Button
                            className='whitespace-nowrap'
                            onClick={() => setModalState('remove')}
                            disabled={!buttonEnabled}
                            variant='destructive'
                        >
                            <span>Remove</span>
                        </Button>
                    ) : (
                        <Button
                            onClick={() => setModalState('add')}
                            className='whitespace-nowrap'
                            disabled={!buttonEnabled}
                        >
                            <span>Add</span>
                        </Button>
                    )}
                </>
            )}
            {modalState === 'remove' && (
                <ConfirmationPrompt
                    title={`Remove data source: ${datasourceName}`}
                    prompt='Are you sure you want to remove this data source from this workspace? Dashboard tiles using this data source will stop working.'
                    onConfirm={unlinkWorkspace}
                    confirmButtonVariant='destructive'
                    onClose={() => setModalState('off')}
                    confirmButtonText='Remove'
                />
            )}
            {modalState === 'add' && (
                <LinkImportModal
                    dataSourceName={datasourceName}
                    onClose={() => linkWorkspace(false)}
                    onSubmit={() => linkWorkspace(true)}
                />
            )}
        </div>
    );
};
