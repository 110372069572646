import type { UserGroupModel } from 'dynamo-wrapper';
import { UseQueryOptions, useQuery } from 'react-query';
import { AccessControlQueryKeys } from 'services/AccessControlService';
import { GetGroups } from 'services/UserService';

type Options = UseQueryOptions<Array<UserGroupModel>, unknown, Array<UserGroupModel>, string>;

/**
 * useMutation to invite a user to a dashboard
 * @param options The useMutation options object
 * @returns uesMutation instance
 */
export const useGroups = (options?: Options) => {
    return useQuery({
        ...options,
        queryKey: AccessControlQueryKeys.UserGroups,
        queryFn: GetGroups
    });
};
