const theme = {
    textColor: 'var(--textSecondary)',
    fontFamily: 'Inter',
    crosshair: { line: { stroke: 'var(--dividerPrimary)' } },
    grid: { line: { stroke: 'var(--dividerTertiary)', strokeWidth: 1 } },
    tooltip: { container: { padding: 0, background: 'none' } },
    axis: { 
        ticks: { 
            text: { fill: 'var(--textSecondary)' },
            line: { stroke: 'var(--dividerTertiary)' }
        },
        legends: {
            text: { fill: 'var(--textSecondary)' }
        }
    }
};

export default theme;

