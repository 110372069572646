import type { DashboardVariable } from '@squaredup/dashboards';
import { Node } from '@squaredup/graph';
import { useDashboardContext } from 'contexts/DashboardContext';
import pluralize from 'pluralize';
import { variableObjectQueryKeys } from 'queries/queryKeys/variableObjectKeys';
import { useInfiniteQuery } from 'react-query';
import { ListVariableObjects } from 'services/VariableService';
import { useCurrentWorkspaceId } from 'services/WorkspaceUtil';

const OBJECTS_PER_PAGE = 25;

export const getDropdownLabel = (variable: DashboardVariable, disabled?: boolean) => {
    if (disabled) {
        return 'Unavailable';
    }

    if (variable.selectedAll) {
        return 'All objects';
    }

    if (!(variable.allowMultipleSelection || variable.default === 'all')) {
        return variable.selectedObjects[0]?.name ?? 'Select object';
    }

    if (variable.selectedObjects.length === 0) {
        return 'Select objects';
    }

    if (variable.selectedObjects.length === 1) {
        return variable.selectedObjects[0].name;
    }
    return pluralize('objects', variable.selectedObjects.length, true);
};

export const useSetCurrentVariable = (currentVariableId: string) => {
    const { setVariables } = useDashboardContext();
    return (newVariable: DashboardVariable) => {
        setVariables?.((currentVariables) =>
            currentVariables.map((currentVariable) => {
                if (currentVariable.id !== currentVariableId) {
                    return currentVariable;
                }

                return newVariable;
            })
        );
    };
};

export const useVariableObjects = (scopeId: string, searchTerm: string) => {
    const workspaceId = useCurrentWorkspaceId();

    const {
        data: pagedData,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        isLoading,
        fetchNextPage
    } = useInfiniteQuery(variableObjectQueryKeys.scope(scopeId, searchTerm), {
        queryFn: async ({ pageParam = 0 }: { pageParam?: number }) => {
            const { objects, count } = await ListVariableObjects({
                workspaceId,
                scopeId,
                searchTerm,
                page: pageParam,
                perPage: OBJECTS_PER_PAGE
            });

            return {
                objects: objects as Node[],
                count: count as number,
                nextPage: pageParam + 1
            };
        },
        getNextPageParam: (lastPage) => (lastPage.objects.length >= OBJECTS_PER_PAGE ? lastPage.nextPage : undefined),
        enabled: Boolean(scopeId),
        keepPreviousData: true,
        cacheTime: 120_000,
        staleTime: 120_000
    });

    const flatResults = pagedData?.pages.flatMap((v) => v.objects);

    return {
        objects: flatResults || [],
        isFetchingObjects: isFetching,
        isFetchingNextObjectsPage: isFetchingNextPage,
        isLoadingObjects: isLoading,
        hasNextObjectsPage: hasNextPage,
        count: pagedData?.pages[0]?.count,
        fetchNextObjectsPage: fetchNextPage
    };
};

export const dashboardHasNoVariableObjectsSelected = (variables?: DashboardVariable[]) =>
    variables && variables.length > 0 && !variables.some((v) => v.selectedAll || v.selectedObjects.length > 0);
