import Modal, { CloseHandler, ifNotOutside } from 'components/Modal';
import Button from 'components/button/Button';
import { createContext, useContext, type ComponentPropsWithoutRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Tabs } from 'ui/Tabs';
import ErrorFallback from 'ui/errorHandling/ErrorFallback';
import { CreateWorkspaceForm } from './CreateWorkspaceForm';
import { WorkspaceModalAccessControlTab } from './WorkspaceModalAccessControlTab';
import { WorkspaceModalGeneralTab } from './WorkspaceModalGeneralTab';
import { usePrefetchCreateWorkspaceModalQueries } from './usePrefetchWorkspaceModalQueries';

type CreateWorkspaceModalProps = {
    close: CloseHandler;
};

export const CreateWorkspaceModal = (props: CreateWorkspaceModalProps) => {
    const { close } = props;

    usePrefetchCreateWorkspaceModalQueries();

    return (
        <Modal
            title='Add workspace'
            close={ifNotOutside(close)}
            modalClassName='w-[700px] h-[700px] flex flex-col'
            description={<ModalDescription />}
        >
            <CreateWorkspaceModalContext.Provider value={{ close }}>
                <CreateWorkspaceForm>
                    <Tabs tabs={createWorkspaceTabs} tabsID='visualizationTabs' className='mt-4' />
                </CreateWorkspaceForm>
            </CreateWorkspaceModalContext.Provider>
        </Modal>
    );
};

const ContentContainer = (props: ComponentPropsWithoutRef<'div'>) => {
    return <div {...props} className='p-6 tile-scroll-overflow' />;
};

const GeneralTab = () => {
    return (
        <ContentContainer>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <WorkspaceModalGeneralTab modalType='create-workspace' />
            </ErrorBoundary>
        </ContentContainer>
    );
};

const AccessControlTab = () => {
    return (
        <ContentContainer>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <WorkspaceModalAccessControlTab modalType='create-workspace' />
            </ErrorBoundary>
        </ContentContainer>
    );
};

const createWorkspaceTabs = [
    {
        label: 'General',
        component: <GeneralTab />,
        key: 'visualization',
        className: 'px-8 py-4'
    },
    {
        label: 'Access Control',
        component: <AccessControlTab />,
        key: 'monitoring',
        className: 'px-8 py-4'
    }
];

export const CreateWorkspaceModalContext = createContext<CreateWorkspaceModalProps | null>(null);

export const useCreateWorkspaceModalContext = () => {
    const context = useContext(CreateWorkspaceModalContext);

    if (!context) {
        throw new Error('useCreateWorkspaceModalContext needs to be used in a CreateWorkspaceModalContext.Provider');
    }

    return context;
};

const ModalDescription = () => {
    return (
        <>
            Workspaces are used to logically organize your dashboards and data, and create maps of your services and
            applications.{' '}
            <Button variant='link' href='https://squaredup.com/cloud/workspaces'>
                Learn more
            </Button>
        </>
    );
};
