import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';
import { AppContext } from 'contexts/AppContext';
import { getDashboardContents } from 'dashboard-engine/util/dashboard';
import useMutationWithSuccess from 'lib/useMutationWithSuccess';
import { useDashboardsForWorkspace } from 'queries/hooks/useDashboardsForWorkspace';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { perspectiveQueryKeys } from 'queries/queryKeys/perspectiveKeys';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { flattenedDashboardsSortedByWorkspaceOrder } from 'queries/utils/dashboardSorted';
import React, { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Delete } from 'services/DashboardService';

type DashboardDeleteModalProps = {
    dashboard: any;
    workspace: string;
    close: () => void;
};

export const DashboardDeleteModal: React.FC<DashboardDeleteModalProps> = ({ dashboard, close }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const tilesWithMonitoring = getDashboardContents(dashboard).filter((tile: any) =>
        Boolean(tile.config?.monitor)
    ).length;

    const { currentWorkspaceID } = useContext(AppContext);
    const { data: workspace } = useWorkspace(currentWorkspaceID);
    const { data: dashboards } = useDashboardsForWorkspace(currentWorkspaceID, {
        select: flattenedDashboardsSortedByWorkspaceOrder(workspace?.data?.properties?.dashboardIdOrder)
    });

    const handleDelete = useMutationWithSuccess(
        async () => {
            setIsDeleting(true);
            // Ensure the deleted dashboard is removed from query cache to
            // ensure we don't try to re-fetch the deleted dashboard
            queryClient.removeQueries(dashboardQueryKeys.detail(dashboard.id));
            await Delete(dashboard.id);
            queryClient.invalidateQueries(perspectiveQueryKeys.all);
            if (tilesWithMonitoring > 0) {
                // We want to invalidate health queries as this will change as monitors are removed
                queryClient.invalidateQueries(dashboardQueryKeys.states);
                queryClient.invalidateQueries(workspaceQueryKeys.states);
            }
        },
        dashboardQueryKeys.list,
        async () => {
            close();

            // Navigate to next available dashboard or go to home page
            const targetDashboard = dashboards?.find((d) => d.id !== dashboard.id);
            navigate(targetDashboard ? `/dashboard/${targetDashboard.id}` : `/workspace/${currentWorkspaceID}`);
        }
    );

    let description = 'Are you sure you want to permanently delete this dashboard?';

    if (tilesWithMonitoring > 0) {
        description += ` ${tilesWithMonitoring} monitor${
            tilesWithMonitoring === 1 ? '' : 's'
        } in this dashboard will be permanently deleted which may impact the health of this workspace.`;
    }

    return (
        <Modal
            title={`Delete Dashboard: ${dashboard.displayName}`}
            description={description}
            close={close}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <ModalButtons>
                <Button type='button' variant='tertiary' onClick={() => close()}>
                    Cancel
                </Button>
                <Button
                    variant='destructive'
                    onClick={() => handleDelete()}
                    data-testid='confirmDeleteDashboard'
                    disabled={isDeleting}
                >
                    {isDeleting ? <LoadingSpinner size={18} /> : 'Delete'}
                </Button>
            </ModalButtons>
        </Modal>
    );
};
