import { InfoTip } from '@/components/InfoTip';
import { cn } from '@/lib/cn';
import { faCircleCheck, faExclamationCircle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import pluralize from 'pluralize';
import { useTileEditorObjectsFilterContext } from '../../contexts/TileEditorObjectsFilterContext';

export const DataStreamLimitMessage = () => {
    const { isDynamic, count = 0, objectLimit, selectedObjectsCount } = useTileEditorObjectsFilterContext();

    if (objectLimit == null) {
        return null;
    }

    const learnMoreLink = (
        <a
            className='text-textLink'
            href='https://docs.squaredup.com/first-steps/objects#data-stream-limits'
            target='_blank'
            rel='noopener noreferrer'
        >
            Learn more.
        </a>
    );

    let icon = faInfoCircle;
    let textColor = 'text-textSecondary';
    let iconColor = textColor;
    let message = (
        <>
            This data stream is limited to {pluralize('object', objectLimit, true)}. {learnMoreLink}
        </>
    );

    if (selectedObjectsCount === objectLimit || (isDynamic && count === objectLimit)) {
        icon = faCircleCheck;
        iconColor = 'text-statusHealthyPrimary';
        message = (
            <>
                You have selected the maximum number of objects ({objectLimit}). {learnMoreLink}
            </>
        );
    }

    if (selectedObjectsCount > objectLimit || (isDynamic && count > objectLimit)) {
        icon = faExclamationCircle;
        textColor = 'text-statusErrorPrimary';
        iconColor = textColor;
        message = (
            <>
                This data stream is limited to the first {pluralize('object', objectLimit, true)}. {learnMoreLink}
            </>
        );
    }

    return (
        <InfoTip
            icon={icon}
            iconClassName={iconColor}
            className={cn(textColor, 'bg-transparent inline-block items-baseline p-0 m-0 mr-xs')}
        >
            {message}
        </InfoTip>
    );
};
