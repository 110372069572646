import { useSearchParams } from 'react-router-dom';
import { useDataSourceConfig } from './useDataSourceConfig';

export const useOAuthRedirectParam = () => {
    const [params, setSearchParams] = useSearchParams();

    // Check if we are in the middle of an edit session (e.g. for plugin using oauth2) and restart if required...
    const configId = params.get('oauthReturnConfigId');
    const { data: pluginInstanceBeingEdited, isLoading: isLoadingPluginInstanceBeingEdited } = useDataSourceConfig(
        configId ?? ''
    );

    const clearSearch = () => {
        params.delete('oauthReturnConfigId');
        setSearchParams(params);
    };

    return {
        hasOAuthRehydration:
            configId !== null && !isLoadingPluginInstanceBeingEdited && Boolean(pluginInstanceBeingEdited),
        isLoadingPluginInstanceBeingEdited,
        pluginInstanceBeingEdited,
        clearSearch
    };
};
