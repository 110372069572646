import { Skeleton } from '@/components/Skeleton';
import { cn } from '@/lib/cn';

const GaugeSkeleton = ({ className }: { className?: string }) => {
    return (
        <Skeleton
            className={cn(
                'size-full max-h-[65%] max-w-[72%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-none',
                className
            )}
            style={{
                maskPosition: 'center',
                maskRepeat: 'no-repeat',
                maskSize: 'contain',
                maskImage:
                    'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 289 145"><path fill="%23000" fill-opacity=".6" fill-rule="evenodd" d="M209.2 41.8a121.4 121.4 0 0 1 56.7 102.7H289a144.5 144.5 0 0 0-67.5-122.3l-12.3 19.6Z" clip-rule="evenodd"/><path fill="%23000"  d="M0 144.5A144.5 144.5 0 0 1 221.5 22.2l-12.3 19.6a121.4 121.4 0 0 0-186 102.7H0Z"/></svg>\')'
            }}
        />
    );
};

export default GaugeSkeleton;
