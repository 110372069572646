import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { Action } from 'pages/components/ApplicationTable/types';
import { FC } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import TruncateWithTooltip from '../TruncateWithTooltip';
import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { ProjectedScript } from './Scripts';

interface ScriptsTableProps {
    scripts: ProjectedScript[];
    onEdit: (customType: ProjectedScript) => void;
    onDelete: (workspace: ProjectedScript) => void;
}

const ScriptsApplicationTable = ApplicationTable<ProjectedScript, string>();

const ScriptsTable: FC<ScriptsTableProps> = ({ scripts, onEdit, onDelete }) => { 
    const columns: ColumnDef<ProjectedScript>[] = [
        {
            id: 'name',
            header: 'Name',
            cell: ({ row }) => {
                return (
                    <TruncateWithTooltip title={row.original.displayName||''}>
                        <div className='truncate'>{row.original.displayName}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 300,
            accessorKey: 'displayName'
        },
        {
            id: 'type',
            header: 'Type',
            cell: ({ row }) => row.original.scriptTypeName,
            size: 300,
            accessorKey: 'scriptTypeName'
        },
        {
            id: 'id',
            header: 'Id',
            cell: ({ row }) => row.original.id,
            size: 300,
            accessorKey: 'id'
        }        
                             
    ];

    const actions: Action[] = [
        {
            action: onEdit,
            icon: faPencil,
            dataTestId: 'editScript',
            tooltip: 'Edit script'
        },
        {
            action: onDelete,
            icon: faTrash,
            dataTestId: 'deleteScript',
            tooltip: 'Delete script'
        }
    ];

    return (
        <div className='flex flex-col flex-1 w-full h-full min-h-0'>
            <ScriptsApplicationTable
                config={{
                    actions,
                    noDataMessage: 'There are no scripts configured.'
                }}
                columns={columns}
                data={scripts}
            />
        </div>
    );
};

export default ScriptsTable;