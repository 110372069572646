import { Divider } from '@/components/Divider';
import { findValueColumn } from '@squaredup/data-streams';
import Field from 'components/forms/field/Field';
import { defaultWrapperClassName } from 'components/forms/input/Input';
import Slider from 'components/forms/slider/Slider';
import { OptionDropdown as FormDropdown } from 'components/visualisationOptions/OptionDropdown';
import { OptionToggle } from 'components/visualisationOptions/OptionToggle';
import { dataMatchCriteria } from 'dashboard-engine/dataStreams/dataMatchCriteria';
import { VisualisationOption } from 'dashboard-engine/types/Visualisation';
import { OptionDropdown } from '../sharedComponents/OptionDropdown';
import { VisualisationConfigAccordion } from '../VisualisationConfigAccordion';
import { DataStreamScalarConfig } from './Config';

const labelPositionOptions = [
    { label: 'Bottom', value: 'bottom' },
    { label: 'Top', value: 'top' },
    { label: 'Left', value: 'left' },
    { label: 'Right', value: 'right' }
];

const DataStreamScalarOptions: VisualisationOption<DataStreamScalarConfig> = {
    initialPanels: (config) => {
        const panels = [{ name: 'mapping', isOpen: true }];

        if (config?.label) {
            panels.push({ name: 'label', isOpen: true });
        }

        if (config?.formatted === false || config?.manualSize) {
            panels.push({ name: 'options', isOpen: true });
        }

        return panels;
    },
    dataMappingComponent: ({ columns, config, onChange, tileData }) => {
        const autoColumn = findValueColumn(columns, tileData.rows.length ?? 0);
        const autoName = autoColumn.column ? `Auto - ${autoColumn.displayName}` : 'Auto - Count (rows)';
        const builtInOptions = [
            {
                label: autoName,
                value: 'none'
            }
        ];

        if (autoColumn.column) {
            builtInOptions.push({
                label: 'Count (rows)',
                value: 'count'
            });
        }

        const optionGroups = [
            {
                label: 'Built-in',
                options: builtInOptions
            },
            {
                label: 'Columns',
                options: columns.map((column) => ({
                    label: column.displayName,
                    value: column.name
                }))
            }
        ];

        const defaultValue = optionGroups
            .flatMap((group) => group.options)
            .find((option) => option.value === (typeof config.value === 'string' ? config.value : config.value?.type)) ?? {
            label: autoName,
            value: 'none'
        };

        return (
            <Field label='Value' htmlFor='scalar' help='Select the column to use for the value shown in the tile'>
                <OptionDropdown name='scalar' options={optionGroups} defaultValue={defaultValue} onChange={onChange} />
            </Field>
        );
    },
    configurationComponent: ({ config, accordionControls, onChange }) => {
        const labelChange = (label: string) => {
            onChange({ action: 'label-text', data: label });
        };

        return (
            <>
                <VisualisationConfigAccordion
                    value='label'
                    label='Label'
                    accordionControls={accordionControls}
                >
                    <Field label='Text' htmlFor='scalar-label'>
                        <input
                            name='scalar-label'
                            type='text'
                            className={defaultWrapperClassName}
                            onChange={(e) => labelChange(e.target.value)}
                            maxLength={250}
                            defaultValue={config?.label}
                        />
                    </Field>

                    <Field 
                        label='Position' 
                        htmlFor='scalar-label-position'
                    >
                        <FormDropdown
                            options={labelPositionOptions}
                            defaultValue={
                                labelPositionOptions.find((option) => option.value === config.labelPosition) ??
                                labelPositionOptions[0]
                            }
                            onChange={onChange}
                            action='scalar-label-position'
                            isDisabled={!config?.label}
                        />
                    </Field>
                </VisualisationConfigAccordion>

                <Divider />

                <VisualisationConfigAccordion
                    value='options'
                    label='Options'
                    accordionControls={accordionControls}
                >
                    <OptionToggle
                        name='showFormattedValue'
                        label='Format value'
                        checked={config.formatted ?? true}
                        onChange={(data) => onChange({ action: 'scalar-formatted-toggle', data })}
                        data-testid='scalar-formatted-toggle'
                    />

                    <OptionToggle
                        name='manualSize'
                        label='Manual size'
                        wrapperClassName='mt-3'
                        checked={config.manualSize !== undefined}
                        onChange={() => onChange({ action: 'scalar-autoSize-toggle' })}
                        data-testid='scalar-autoSize-toggle'
                    />

                    <Field className='mt-3 px-md'>
                        {config.manualSize !== undefined && (
                            <div className='flex-1 min-w-0'>
                                <Slider
                                    min={25}
                                    max={310}
                                    defaultValue={config.manualSize ?? 180}
                                    onChange={(e) => onChange({ action: 'scalar-manual-size', data: e.target.value })}
                                    markers={[
                                        { value: 25, label: 'Min' },
                                        { value: 310, label: 'Max' }
                                    ]}
                                />
                            </div>
                        )}
                    </Field>
                </VisualisationConfigAccordion>
            </>
        );
    },
    handlers: {
        'scalar-select': (config, data) => {
            if (data && data !== 'none') {
                if (data === 'count') {
                    return { ...config, value: { type: 'count' } };
                }
                return { ...config, value: data };
            } else {
                const { value: _, ...rest } = config;
                return rest;
            }
        },
        'label-text': (config, data) => {
            return { ...config, label: data };
        },
        'scalar-formatted-toggle': (config, data) => {
            return { ...config, formatted: data };
        },
        'scalar-autoSize-toggle': (config) => {
            if (config.manualSize !== undefined) {
                const { manualSize, ...configWithoutManualSize } = config;
                return configWithoutManualSize;
            }

            // Just add the manualSize key to the config so we know we can set it
            return {
                ...config,
                manualSize: 180
            };
        },
        'scalar-manual-size': (config, data) => {
            return { ...config, manualSize: data };
        },
        'scalar-label-position': (config, data) => {
            return {
                ...config,
                labelPosition: data
            };
        }
    },
    validate: (_, config) => config,
    matchesData: () => dataMatchCriteria()
};

export default DataStreamScalarOptions;
