import { FormToggle } from 'components/forms/toggle/FormToggle';
import { TruncatedTextWithPrefix } from 'components/TruncatedTextWithPrefix';

interface FormToggleTableProps<T> {
    items: T[]
    disabled?: boolean
    getName: (item: T) => string
    getLabel: (item: T) => string
    getPrefix?: (item: T) => string
}

export const FormToggleTable = <T,>({ 
    items, 
    disabled, 
    getName,
    getLabel,
    getPrefix 
}: FormToggleTableProps<T>) => {
    return (
        <div className='w-full'>
            {items.map((item) => (
                <div 
                    key={getName(item)} 
                    className='w-full border-b last:border-b-0 border-b-dividerPrimary'
                >
                    <FormToggle
                        labelTextClassName='block overflow-hidden'
                        wrapperClassName='truncate py-2 px-4 [&>*]:overflow-hidden [&>*>*]:overflow-hidden'
                        name={getName(item)}
                        label={
                            <TruncatedTextWithPrefix
                                prefix={getPrefix?.(item)}
                                text={getLabel(item)}
                                prefixClasses='truncate text-textSecondary basis-0'
                                textClasses='truncate'
                            />
                        }
                        disabled={disabled}
                    />
                </div>
            ))}
        </div>
    );
};

