import stringify from 'fast-json-stable-stringify';
import { DETAIL, LIST } from './common';

const DATASOURCES = 'datasources';
const DOCS = 'docs';
const LATEST = 'latest';

export const datasourceQueryKeys = {
    all: [DATASOURCES],
    list: [DATASOURCES, LIST],
    latest: [DATASOURCES, LATEST],
    docs: [DATASOURCES, DOCS],
    detail: (datasourceId?: string) => [DATASOURCES, DETAIL, stringify(datasourceId)]
};
