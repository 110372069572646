import { MonitorFrequencyPresets, getAllowedMonitorFrequency } from '@squaredup/monitoring';
import { TimeframeEnumValue } from '@squaredup/timeframes';

/**
 * Ensure the given frequency is allowed based on the selected tiemframe
 * @param timeframe Current timeframe
 * @param frequency Current frequency
 * @returns Frequency
 */
export const sanitiseFrequency = (timeframe: TimeframeEnumValue, tierLimit: number, frequency?: number): number => {
    const allowedMonitorFrequency = getAllowedMonitorFrequency(timeframe);

    // Set the default if not currently set
    if (!frequency) {
        frequency = allowedMonitorFrequency.defaultMinutes;
    }

    // Ensure our minimums are being honoured.
    const clamped = Math.max(frequency, allowedMonitorFrequency.minimumMinutes, tierLimit);

    // If frequency doesn't match one of our presets, pick the nearest (larger) preset.
    const possibleValues = Object.values(MonitorFrequencyPresets) as number[];
    if (!possibleValues.includes(clamped)) {
        return possibleValues.find((f) => f > clamped) || MonitorFrequencyPresets.TwentyFourHours;
    }

    return clamped;
};
