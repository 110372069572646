import { ColumnDef } from '@tanstack/react-table';
import Modal, { ModalButtons, ifNotOutside } from 'components/Modal';
import { TruncatedText } from 'components/TruncatedText';
import Button from 'components/button/Button';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Workspace } from 'services/WorkspaceService';
import { PluginProjectedRow } from './Plugins';

interface PluginsWorkspacesModalProps {
    plugin: PluginProjectedRow;
    associatedWorkspaces: Workspace[];
    close: () => void;
}

const PluginsWorkspacesApplicationTable = ApplicationTable<Workspace, string>();

const PluginsWorkspacesModal: FC<PluginsWorkspacesModalProps> = ({ plugin, associatedWorkspaces, close }) => {
    const columns: ColumnDef<Workspace>[] = [
        {
            header: 'Workspace',
            cell: ({ row }) => {
                return (
                    <TruncatedText title={`Go to workspace: ${row.original.displayName}`}>
                        <Button variant='link'>
                            <Link to={`/workspace/${row.original.id}`}>{row.original.displayName}</Link>
                        </Button>
                    </TruncatedText>
                );
            },
            accessorKey: 'displayName',
            size: 400
        }
    ];

    return (
        <Modal
            title={`Workspaces using ${plugin.displayName}`}
            close={ifNotOutside(close)}
            fullWidth
            maxWidth='max-w-3xl'
            maxHeight='h-fit'
        >
            <div className='flex flex-col h-full min-h-0 px-6 pt-5'>
                <PluginsWorkspacesApplicationTable
                    config={{
                        noDataMessage: 'There are no workspaces using this data source.'
                    }}
                    data={Array.from(associatedWorkspaces)}
                    columns={columns}
                />
            </div>
            <ModalButtons>
                <Button type='button' variant='tertiary' onClick={() => close()}>
                    Close
                </Button>
            </ModalButtons>
        </Modal>
    );
};

export default PluginsWorkspacesModal;
