import { faRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'components/LoadingSpinner';
import { isUnhealthy } from 'constants/state';
import { WorkspaceNetworkContext } from 'contexts/WorkspaceNetworkContext';
import stringify from 'fast-json-stable-stringify';
import { uniqueId } from 'lodash';
import objectHash from 'object-hash';
import {
    FilterCriteria,
    WorkspaceWithDependencies,
    defaultFilterCriteria,
    getAllWorkspacesWithDependencies
} from 'pages/monitoring/common';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { ControlButton } from 'reactflow';
import { WORKSPACES } from 'services/WorkspaceService';
import { GRAPH_DATA, NetworkFlow } from './Network';
import { generateWorkspaceNetworkData, getInitialNodes } from './utils/generateWorkspaceNetwork';
import { getFilteredNodes } from './utils/getFilteredNodes';
import { cytoscapeWebWorker } from './utils/networkWorker';
import { WorkspaceFilterPanel } from './workspaceNetworkComponents/WorkspaceFilterPanel';

export const GRAPH_LAYOUT = 'WORKSPACE_GRAPH_LAYOUT';

const WorkspaceNetwork: React.FC<{
    data: string[];
    config: any;
    editingEnabled?: boolean;
}> = ({ data, config, editingEnabled = false }) => {
    const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>(defaultFilterCriteria);
    const [expandedNodeIds, setExpandedNodeIds] = useState<string[]>([]);
    const filterPanelId = useMemo(() => uniqueId(), []);

    const { data: workspaceNetworkData, isLoading: isLoadingData } = useQuery(
        [WORKSPACES, GRAPH_DATA, stringify(data), stringify(config)],
        async () => {
            // Query to get the graph nodes of all workspaces and connections for the current tenant
            const workspacesWithDependencies: WorkspaceWithDependencies[] = await getAllWorkspacesWithDependencies();

            return generateWorkspaceNetworkData(data, workspacesWithDependencies, config);
        },
        {
            enabled: data?.length > 0,
            keepPreviousData: true,
            refetchInterval: 60_000
        }
    );

    const {
        networkData,
        workspacesWithDependencies = [],
        workspacesWithDependenciesByNodeId = new Map()
    } = workspaceNetworkData || {};

    const { data: workspaceNetworkLayout, isLoading: isLoadingLayout } = useQuery(
        [
            GRAPH_LAYOUT,
            objectHash(workspaceNetworkData || {}),
            stringify(filterCriteria),
            stringify(expandedNodeIds),
            stringify(config)
        ],
        async () => {
            const targetWorkspaces =
                networkData?.nodes.filter(({ data: { workspaceID } }) => data.includes(workspaceID)) || [];

            const initialNodeIds: string[] = [];
            getInitialNodes(
                initialNodeIds,
                targetWorkspaces.map(({ data: { id } }) => id),
                workspacesWithDependenciesByNodeId!
            );

            let nodes =
                networkData?.nodes.filter(({ data: { id } }) => [...initialNodeIds, ...expandedNodeIds].includes(id)) ||
                [];

            // Apply filter criteria
            const { showIssuesOnly, showHealthyMonitors } = filterCriteria;

            if (showIssuesOnly) {
                // Filter nodes based on health - initialise with all nodes that are passed to the Workspace Network
                const filteredNodes = [...targetWorkspaces];

                getFilteredNodes(
                    filteredNodes,
                    nodes,
                    filteredNodes,
                    filterCriteria,
                    workspacesWithDependenciesByNodeId!
                );

                nodes = filteredNodes;
            }

            const edges = networkData?.edges.filter(
                (edge: any) =>
                    nodes?.some(({ data: { id } }) => id === edge.data.to) &&
                    nodes?.some(({ data: { id } }) => id === edge.data.from)
            );

            // Hide healthy monitors if not requested
            if (!showHealthyMonitors) {
                nodes = nodes?.map((node) => ({
                    ...node,
                    data: {
                        ...node.data,
                        tiles: node.data.tiles?.filter((tileMonitor) =>
                            isUnhealthy(tileMonitor.state)
                        )
                    }
                }));
            }

            const networkConfig = {
                ...config,
                nodeType: 'kpi',
                layoutType: config.layoutType
            };

            return cytoscapeWebWorker(nodes || [], edges || [], networkConfig);
        },
        {
            enabled: Boolean(workspaceNetworkData),
            keepPreviousData: true
        }
    );

    const isLoading = isLoadingData || isLoadingLayout;

    if (isLoading) {
        return (
            <div className='flex items-center justify-center w-full h-full'>
                <LoadingSpinner />
            </div>
        );
    }

    // Return early loading or there is no data (should never happen!)
    if (!isLoading && !workspaceNetworkData) {
        return null;
    }

    const { nodes, edges } = workspaceNetworkLayout || {};

    return (
        <div className='flex flex-col h-full'>
            <WorkspaceNetworkContext.Provider
                value={{
                    workspacesWithDependencies,
                    workspacesWithDependenciesByNodeId,
                    editingEnabled,
                    filterCriteria,
                    setExpandedNodeIds
                }}
            >
                <NetworkFlow
                    key={stringify(data)}
                    nodes={nodes}
                    edges={edges}
                    onClick={undefined}
                    nodeType='kpi'
                    extraControls={
                        <>
                            <ControlButton className='relative' title='Filter nodes'>
                                <WorkspaceFilterPanel
                                    id={filterPanelId}
                                    filterCriteria={filterCriteria}
                                    setFilterCriteria={setFilterCriteria}
                                />
                            </ControlButton>
                            {expandedNodeIds?.length > 0 && (
                                <ControlButton
                                    onClick={() => {
                                        setExpandedNodeIds([]);
                                        setFilterCriteria(defaultFilterCriteria);
                                    }}
                                    title='Reset nodes'
                                >
                                    <FontAwesomeIcon icon={faRotateLeft} />
                                </ControlButton>
                            )}
                        </>
                    }
                />
            </WorkspaceNetworkContext.Provider>
        </div>
    );
};

export default WorkspaceNetwork;
