import { cn } from '@/lib/cn';
import { HTMLAttributes, ReactNode, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Tooltip from './tooltip/Tooltip';

interface TruncateListProps extends HTMLAttributes<HTMLDivElement> {
    items: { label: string; component: ReactNode }[];
    canExpand: boolean;
    itemMargin: number;
    className?: string;
}

const estimateXMoreWidth = 60;

/**
 * Given a list of items, this component will render as many of them as possible in a single line up to
 * the width of the containing element. Any left over items will be displayed as an "+X more" call to action
 * at the end of the list. Depending on the `canExpand` prop, this "+X more" will either allow the user to
 * expand the list to show all the items (canExpand = true), or the additional items will be displayed by
 * their label in a tooltip (canExpand = false).
 *
 * @param className Optional class name to be applied to the container element
 * @param items Array of items to display in the list
 * @param canExpand Should the truncated list be allowed to expand to show remaining items, or
 * remaining items shown in a tooltip
 * @param itemMargin The width in px of the margin between each of the items
 * @returns A component that displays a truncated list of items
 */
export const TruncateList: React.FC<TruncateListProps> = ({ className, items, canExpand, itemMargin, ...rest }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [widths, setWidths] = useState<number[] | undefined>(undefined);
    const [hiddenCount, setHiddenCount] = useState(0);
    const [showAll, setShowAll] = useState(false);

    const itemsToShow = useMemo(() => {
        if (showAll && hiddenCount > 0) {
            return [
                ...items.map(({ component }) => component),
                <button onClick={() => setShowAll(false)}>Show less</button>
            ];
        } else if (!showAll && hiddenCount > 0) {
            const tooltipTitle = (
                <dl>
                    {items.slice(items.length - hiddenCount).map((t) => (
                        <dd key={`tag-${t.label}`}>{t.label}</dd>
                    ))}
                </dl>
            );
            return [
                ...items.map(({ component }) => component).slice(0, items.length - hiddenCount),
                <Tooltip title={tooltipTitle} disabled={canExpand} className='inline-block'>
                    <button onClick={() => setShowAll(true)} disabled={!canExpand}>
                        +{hiddenCount} more
                    </button>
                </Tooltip>
            ];
        }
        return items.map(({ component }) => component);
    }, [hiddenCount, showAll, canExpand, items]);

    const truncateItems = useCallback(() => {
        const container = containerRef.current;
        if (!container || !widths) {
            return;
        }
        let itemCount = 0;
        let currentWidthTotal = 0;

        // Add item widths until total width is greater than wrapper width
        while (currentWidthTotal + widths[itemCount] + itemMargin < container.offsetWidth - estimateXMoreWidth) {
            currentWidthTotal += widths[itemCount] + itemMargin;
            itemCount++;
        }

        itemCount = Math.max(itemCount, 0);

        const remaining = items.length - itemCount;

        if (remaining !== hiddenCount) {
            setHiddenCount(remaining);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerRef.current, hiddenCount, itemMargin]);

    // When the list of items changes, calculate the widths of all the items
    useLayoutEffect(() => {
        const container = containerRef.current;
        if (!container) {
            return;
        }
        const childItems = Array.from(container.children);
        const elementsAreInDom = childItems.every((c) => Boolean(c.clientWidth));

        if (elementsAreInDom) {
            setWidths(childItems.map((c) => c.clientWidth + 2));
        }
    }, []);

    useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach(() => truncateItems());
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, [truncateItems]);

    return (
        <div ref={containerRef} className={cn('w-full', { 'overflow-hidden': !showAll }, className)} {...rest}>
            {itemsToShow}
        </div>
    );
};
