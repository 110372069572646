import { useQuery } from 'react-query';
import { AccessControlQueryKeys } from 'services/AccessControlService';
import { GetGroups } from 'services/UserService';

export const useGroupsForACLEditor = () => {
    return useQuery({
        // Groups change very rarely, so always use old cached data to keep the UI snappy ...
        staleTime: Number.POSITIVE_INFINITY,
        cacheTime: Number.POSITIVE_INFINITY,
        // ... but refetch when someone looks at it, to asynchronously pull in any updates
        refetchOnMount: 'always',
        queryKey: AccessControlQueryKeys.UserGroupsWithLazyUpdate,
        queryFn: GetGroups
    });
};