import {
    date,
    findColumn,
    findColumns,
    FormattedStreamData,
    FormattedStreamValue,
    FoundColumn,
    number,
    preferred,
    required,
    state,
    StreamDataColumn,
    string
} from '@squaredup/data-streams';
import { Result } from '@squaredup/utilities';
import { hasUniqueValues } from 'dashboard-engine/dataStreams/dataMatchingUtils';
import { LegacyDataStreamBarChartConfig } from './Config';

export const getLegacyBarChartColumns = (
    columns: StreamDataColumn[],
    rows: FormattedStreamValue[][],
    config: LegacyDataStreamBarChartConfig = {}
): Result<{
    valueColumn: FoundColumn;
    labelColumn: FoundColumn;
    unitLabelColumnResult: Result<FoundColumn>;
    valueColumnCandidates: FoundColumn[];
    labelColumnCandidates: FoundColumn[];
}> => {
    if (rows.length === 0) {
        return Result.fail('No rows of data');
    }

    const valueColumnCandidates = findColumns(
        columns,
        required('valueShapeName', number.name),
        preferred('role', 'value')
    );

    if (valueColumnCandidates.failed) {
        return Result.fail(`Couldn't find any value columns: ${valueColumnCandidates.reason}`);
    }

    const valueColumn = findColumn(columns, required('name', config?.yAxisColumn ?? '')).getValue(
        valueColumnCandidates.value[0]
    );

    const labelColumnCandidates = findColumns(
        columns,
        required('valueShapeName', string.name),
        required.custom('has a unique value for each row', (c) => {
            const dataIndex = columns.indexOf(c);

            return hasUniqueValues(rows, dataIndex);
        }),
        preferred('role', 'label'),
        preferred.not('role', 'value'),
        preferred.not('shapeName', state.name),
        preferred.not('shapeName', date.name)
    );

    if (labelColumnCandidates.failed) {
        return Result.fail(`Couldn't find a label column: ${labelColumnCandidates.reason}`);
    }

    const labelColumn = findColumn(columns, required('name', config?.xAxisColumn ?? '')).getValue(
        labelColumnCandidates.value[0]
    );

    const unitLabelColumnResult = findColumn(
        columns,
        required('valueShapeName', string.name),
        required('role', 'unitLabel')
    );

    return Result.success({
        labelColumn,
        valueColumn,
        unitLabelColumnResult,
        labelColumnCandidates: labelColumnCandidates.value,
        valueColumnCandidates: valueColumnCandidates.value
    });
};

/**
 * Migrates a given tile config from the legacy bar chart column selection logic
 * by setting the previously auto detected columns in the returned config
 * @param config Tile config
 * @param data Stream data
 * @returns Migrated configuration
 */
export const performLegacyBarChartColumnMigration = (
    config: LegacyDataStreamBarChartConfig,
    data: FormattedStreamData
) => {
    if (!config?.requiresLegacyColumnMigration) {
        // No migration needed
        return config;
    }

    const {
        metadata: { columns },
        rows
    } = data;

    const result = getLegacyBarChartColumns(columns, rows, config);

    if (result.failed) {
        // If this fails, e.g. no data, just return config as-is (will later re-run when data becomes available)
        return config;
    }

    const updated = {
        ...config,
        xAxisData: config.xAxisColumn || result.value.labelColumn.column.name,
        xAxisGroup: 'none',
        yAxisData: config.yAxisColumn || result.value.valueColumn.column.name,
        requiresLegacyColumnMigration: false
    };

    return updated;
};
