import { Serialised } from '@squaredup/ids';
import { AutocompleteOptionWithStringLabel } from 'components/forms/jsonForms/autocompleteOptions';
import { useQuery } from 'react-query';
import { AGENT_GROUPS, AgentGroupResponse, List as ListAgentGroups } from 'services/AgentGroupService';
import { AGENTS, List as ListAgents } from 'services/AgentService';

export type AgentGroupWithPlatform = Serialised<AgentGroupResponse> & {
    platforms: string[];
    platformSummary: string;
    agentOptions: (AutocompleteOptionWithStringLabel & { platform: string | undefined })[];
};
export type AgentGroupOptions = ReturnType<typeof formatAgentGroups>;

type options = {
    refetchInterval: number;
};

const formatAgentGroups = (agentGroups: AgentGroupWithPlatform[] | undefined) =>
    agentGroups?.map((group) => ({
        key: group.id,
        label: group.displayName ?? group.name,
        value: group.id,
        platforms: group.platforms
    })) ?? [];

export const summarisePlatform = (platforms: (string | undefined)[]) => {
    if (platforms.length === 0) {
        return 'Unknown';
    }

    const first = platforms[0];
    return platforms.every((platform) => platform === first) ? first ?? 'Unknown' : 'Mixed';
};

export const useAgentGroups = (options?: options) => {
    const { isLoading: isLoadingRawAgentGroups, data: rawAgentGroups } = useQuery(
        [AGENT_GROUPS],
        ListAgentGroups,
        options
    );
    const { isLoading: isLoadingAgents, data: agents } = useQuery([AGENTS], ListAgents, options);

    const isLoadingAgentGroups = isLoadingRawAgentGroups || isLoadingAgents;

    const agentGroups = !isLoadingAgentGroups
        ? rawAgentGroups?.map<AgentGroupWithPlatform>((group) => {
              const linkedAgents = agents?.filter((a) => a.agentGroups.includes(group.id)) ?? [];

              const platforms = linkedAgents.filter((a) => a.platform).map((a) => `${a.platform?.toLowerCase()}`);
              const platformSummary = summarisePlatform(platforms);

              const agentOptions = linkedAgents.map((agent) => ({
                  label: agent.displayName ?? agent.name,
                  value: agent.id,
                  platform: agent.platform
              }));

              return {
                  ...group,
                  agentOptions,
                  platforms,
                  platformSummary
              };
          })
        : undefined;

    return {
        agentGroups,
        agentGroupOptions: formatAgentGroups(agentGroups),
        isLoadingAgentGroups
    };
};
