import { useCallback } from 'react';
import { useGetGraphEdges, useGetGraphNodes, useSetGraphEdges, useSetGraphNodes } from '../context/NetworkMapStoreContext';
import { fetchMapData } from '../data/fetchMapData';
import { useRevealExpandedNodes } from './useRevealExpandedNodes';

export const useFetchExpandedNodes = () => {
    const setGraphNodes = useSetGraphNodes();
    const setGraphEdges = useSetGraphEdges();
    const getGraphNodes = useGetGraphNodes();
    const getGraphEdges = useGetGraphEdges();
    const revealExpandedNodes = useRevealExpandedNodes();

    return useCallback(async (
        ids: string[],
        expandedNodeIds: string[], 
        pinnedNodeIds: string[],
        pinnedGroupsWithMemberNodeIds: Map<string, string[]>,
        updatedUngroupedNodeIds: string[],
        visibleNodeIds: string[]
    ) => {
        const existingGraphNodes = getGraphNodes();
        const existingGraphEdges = getGraphEdges();

        // We want to fetch nodes that are connected to the current node but not currently visible
        const graphResults = await fetchMapData(ids, visibleNodeIds);
   
        const updatedGraphNodes = existingGraphNodes;
        const updatedGraphEdges = existingGraphEdges;

        updatedGraphNodes.push(...
            graphResults?.nodes
                ?.filter((newNode) => !existingGraphNodes
                    .some(existingNode => newNode.id === existingNode.id)) ?? []
        );
    
        updatedGraphEdges.push(...
            graphResults?.edges
                ?.filter((newEdge) => !existingGraphEdges
                    .some(existingEdge => newEdge.id === existingEdge.id)) ?? []
        );
    
        // Store new flow data
        setGraphNodes(updatedGraphNodes);
        setGraphEdges(updatedGraphEdges);

        revealExpandedNodes(
            ids,
            updatedGraphNodes,
            updatedGraphEdges,
            expandedNodeIds,
            pinnedNodeIds,
            pinnedGroupsWithMemberNodeIds,
            updatedUngroupedNodeIds
        );
    }, [
        getGraphEdges, 
        getGraphNodes, 
        setGraphEdges, 
        setGraphNodes,
        revealExpandedNodes
    ]);
};
