import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { TruncatedText } from 'components/TruncatedText';
import { ReactNode, forwardRef } from 'react';

interface FilterOptionProps {
    title: string;
    icon?: ReactNode;
    children?: ReactNode;
    className?: string;
    isSelected?: boolean;
    disabled?: boolean;
    onClick: () => void;
}

export const FilterOption = forwardRef<HTMLButtonElement, FilterOptionProps>(
    ({ title, icon, className, isSelected, disabled, children, onClick }, ref) => {
        return (
            <div className='flex overflow-hidden'>
                <button
                    className={cn(
                        (isSelected || disabled) && 'text-textPrimary font-bold',
                        (!isSelected || disabled) && 'text-textSecondary hover:text-textPrimary',
                        disabled && 'hover:text-textSecondary',
                        'flex flex-1 w-full overflow-hidden items-center gap-x-2',
                        className
                    )}
                    onClick={onClick}
                    disabled={disabled}
                    ref={ref}
                >
                    {icon && <span className='flex-shrink-0 w-5 h-5'>{icon}</span>}
                    <Text.SmallBody className='flex-1 text-left overflow-hidden'>
                        <TruncatedText title={title} className='min-w-0 truncate' />
                    </Text.SmallBody>
                </button>
                {children}
            </div>
        );
    }
);
