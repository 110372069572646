const repeatedDonutGraphPalette = [
    '#7A65FA',
    '#622CE8',
    '#844DCE',
    '#7423B2',
    '#994ABB',
    '#842497',
    '#A64E88',
    '#DB96B7',
    '#3ABACD',
    '#1C98C6'
];

const repeatedBarGraphPalette = [
    '#7A65FA',
    '#622CE8',
    '#844DCE',
    '#7423B2',
    '#994ABB',
    '#842497',
    '#C95DD2',
    '#DB96B7'
];

export const visualizationPalettes = {
    singleBar: {
        dark: ['#3EB5FF'],
        light: ['#0075FF']
    },
    bar: {
        dark: ['#3EB5FF', '#0075FF', '#3ABACD', '#007FAE', ...repeatedBarGraphPalette],
        light: ['#0075FF', '#3EB5FF', '#007FAE', '#3ABACD', ...repeatedBarGraphPalette]
    },
    donut: {
        dark: ['#3EB5FF', '#0075FF', ...repeatedDonutGraphPalette],
        light: ['#0075FF', '#3EB5FF', ...repeatedDonutGraphPalette]
    },
    line: {
        dark: ['#3EB5FF', '#9E58FF', '#0075FF', '#C95DD2', '#A891FA', '#DB96B7'],
        light: ['#0075FF', '#C95DD2', '#3EB5FF', '#842497', '#A891FA', '#DB96B7']
    }
};

export const healthStateColors = {
    success: 'var(--statusHealthyPrimary)',
    warning: 'var(--statusWarningPrimary)',
    error: 'var(--statusErrorPrimary)',
    unknown: 'var(--statusUnknownPrimary)',
    unmonitored: 'var(--statusUnmonitoredPrimary)'
};
