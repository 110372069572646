import base62 from 'base-x';

const BASE62 = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

export function base62Decode(strToDecode) {
    return base62(BASE62).decode(strToDecode).toString();
}

export function base62Encode(strToEncode) {
    return base62(BASE62).encode(Buffer.from(strToEncode));
}