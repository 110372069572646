import { toMaxLength } from 'dashboard-engine/util/tickFormatter';
import { GridLineExtension } from '../components/GridLineExtension';
import { maxTickCharacterLength } from './Config';

interface BarChartBottomAxisTicksProps {
    tick: number | string | null;
    xPosition: number;
    rotateTicks: boolean;
}

/**
 * @returns a custom bottom axis for the bar chart variants, with rendered ticks
 */
export const BarChartBottomAxisTicks = ({ tick, xPosition, rotateTicks }: BarChartBottomAxisTicksProps) => {
    const truncatedTick = toMaxLength(String(tick), maxTickCharacterLength);

    return (
        <g key={tick} transform={`translate(${xPosition ?? 0}, 1)`}>
            <GridLineExtension />
            <text
                y={0}
                x={0}
                className='fill-textSecondary'
                style={{
                    fontSize: '11px',
                    fontFamily: 'Inter',
                    letterSpacing: '0px'
                }}
                textAnchor={`${rotateTicks ? 'end' : 'middle'}`}
                transform={`rotate(${rotateTicks ? '-45' : '0'}), translate(${rotateTicks ? '7, -5' : '0'})`}
            >
                {truncatedTick}
            </text>
        </g>
    );
};
