import { ClientDataStreamRequest, DataStreamOptions } from '@squaredup/data-streams';
import { Result } from '@squaredup/utilities';
import stringify from 'fast-json-stable-stringify';
import { omit, omitBy, uniq } from 'lodash';

type ClientSideCheck =
    | [
          failureMessage: string,
          check: (old: ClientDataStreamRequest, current: ClientDataStreamRequest) => boolean | string[]
      ]
    | ((old: ClientDataStreamRequest, current: ClientDataStreamRequest) => string[]);

/**
 * Checks that compare the old and new request. If any of them fail then we can't
 * handle this request client-side and have to go to the backend because we might
 * get different data (e.g. different columns if grouping change, different data
 * if the timeframe changes etc.)
 */
const clientSideChecks: ClientSideCheck[] = [
    ['Selected data stream Id changed', (old, current) => old.dataStreamId !== current.dataStreamId],
    ['Selected data stream name changed', (old, current) => old.dataStreamName !== current.dataStreamName],
    ['Selected plugin instance changed', (old, current) => old.pluginConfigId !== current.pluginConfigId],
    [
        'Data stream configuration changed',
        (old, current) => {
            return stringify(old.dataSourceConfig) !== stringify(current.dataSourceConfig);
        }
    ],
    ['Scope changed', (old, current) => stringify(old.scope) !== stringify(current.scope)],
    ['Timeframe changed', (old, current) => stringify(old.timeframe) !== stringify(current.timeframe)],
    [
        'Filtering changed',
        (old, current) => {
            // It's fine if we're adding a filter because we're starting with all the data
            return old.options.filter != null && stringify(old.options.filter) !== stringify(current.options.filter);
        }
    ],
    [
        'Sorting changed',
        (old, current) => stringify(omit(old.options.sort, 'top')) !== stringify(omit(current.options.sort, 'top'))
    ],
    [
        'Top changed, and base top was smaller',
        (old, current) =>
            current.options.sort?.top != null &&
            (old.options.sort?.top ?? current.options.sort.top) < current.options.sort.top
    ],
    [
        'Base data was grouped',
        (old) =>
            old.options.group != null &&
            Object.keys(omitBy(old.options.group, (v) => v == null || (Array.isArray(v) && v.length === 0))).length > 0
    ],
    [
        'Grouping changed',
        (old, current) =>
            stringify(omitBy(old.options.group, (v) => v == null || (Array.isArray(v) && v.length === 0))) !==
            stringify(omitBy(current.options.group, (v) => v == null || (Array.isArray(v) && v.length === 0)))
    ],
    (old, current) => {
        const keys = uniq(Object.keys(old.options).concat(Object.keys(current.options))) as (keyof DataStreamOptions)[];

        return keys
            .filter((k) => !['filter', 'group', 'sort', 'format', 'metadata'].includes(k))
            .flatMap((k) => (old.options[k] !== current.options[k] ? [`${k} changed`] : []));
    }
];

/**
 * Determine if a data stream request can be run client-side by reprocessing the data from a previous request.
 */
export const canRunClientSide = (
    oldRequest: ClientDataStreamRequest,
    newRequest: ClientDataStreamRequest
): Result<void, { details: string[] }> => {
    const failureMessages = clientSideChecks.flatMap((check) => {
        if (typeof check === 'function') {
            return check(oldRequest, newRequest);
        }

        const [failureMessage, checkFn] = check;

        if (checkFn(oldRequest, newRequest)) {
            return [failureMessage];
        }

        return [];
    });

    if (failureMessages.length === 0) {
        return Result.success(undefined);
    }

    return Result.fail({ reason: 'Cannot process this change client-side', details: failureMessages });
};
