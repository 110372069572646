import { resolveScopeToTargetNodes } from '@squaredup/data-streams';
import { useAppContext } from 'contexts/AppContext';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import stringify from 'fast-json-stable-stringify';
import { useQuery } from 'react-query';
import { Query } from 'services/GraphService';

/**
 * Gets a gremlin query to filter the objects to those intersecting with the saved scope
 */
export const useObjectFilterScopeNodes = (filterScope: string, workspaceId?: string) => {
    const { currentWorkspaceID } = useAppContext();
    const { isGlobal } = useDataStreamWorkspaceContext();

    return useQuery(
        ['scopeNodes', filterScope, stringify(isGlobal)],
        async () => {
            const scopeNodes = await resolveScopeToTargetNodes((query) => Query(
                query, 
                isGlobal ? 'directOrAnyWorkspaceLinks' : undefined)
            )({
                workspace: workspaceId ?? currentWorkspaceID!,
                scope: filterScope,
                excludeCanonicalLinkedNodes: true
            });

            if (scopeNodes.length) {
                return `.has("id", within(${scopeNodes.map(({ id }) => `"${id}"`).join(',')}))`;
            }

            // If there are no scope nodes we want to ensure we filter the list of objects to nothing
            return '.not(has("id"))';
        },
        {
            enabled: Boolean(filterScope),
            keepPreviousData: true,
            placeholderData: '',
            cacheTime: 120_000,
            staleTime: 120_000
        }
    );
};
