import { cn } from '@/lib/cn';
import { HealthState, stateStrings, unmonitoredLabel } from '@squaredup/monitoring';
import Tooltip from 'components/tooltip/Tooltip';
import { healthStateTextColors, healthStatesEnum } from 'constants/state';
import { capitalize } from 'lodash';
import { FC, ReactNode } from 'react';

interface StateIndicatorProps {
    state?: HealthState | string;
    className?: string;
    hideTooltip?: boolean;
    disablePing?: boolean;
    titleOverride?: ReactNode | ((state: string) => ReactNode);
    showUnmonitored?: boolean;
}

const StateIcon = ({ state = stateStrings.unmonitored, disablePing = false }: StateIndicatorProps) => (
    <>
        {['error', 'warning'].includes(state) && (
            <span
                className={cn(
                    'inline-block absolute opacity-75 origin-center bg-current rounded-full w-full h-full',
                    !disablePing && 'animate-ping'
                )}
            />
        )}
        <span
            data-testid='statusIcon'
            data-teststate={state}
            className={'inline-block absolute align-baseline bg-current rounded-full w-full h-full'}
        />
        <span className='sr-only'>Current status is {state}</span>
    </>
);

export const StateIndicator: FC<StateIndicatorProps> = ({
    state = stateStrings.unmonitored,
    className,
    hideTooltip,
    titleOverride,
    disablePing
}) => {
    // If the state isn't one of the recognised health states then it's set as unknown
    const healthState = healthStatesEnum.parse(state);

    const title = typeof titleOverride === 'function' ? 
        titleOverride(state) : 
        titleOverride || capitalize(state === stateStrings.unmonitored ? unmonitoredLabel : state);

    return (
        <Tooltip
            disabled={Boolean(hideTooltip)}
            title={
                <div className='flex items-center justify-center space-x-[10px]'>
                    <div
                        className={cn(
                            'inline-flex shrink-0 items-center text-center justify-center overflow-visible relative w-[10px] h-[10px]',
                            healthStateTextColors[healthState]
                        )}
                    >
                        <StateIcon 
                            disablePing={disablePing} 
                            state={healthState} 
                        />
                    </div>
                    
                    <div className='font-normal text-[14px] leading-[16px]'>{title}</div>
                </div>
            }
            className={cn(
                'inline-flex shrink-0 items-center text-center justify-center overflow-visible relative w-2 h-2',
                healthStateTextColors[healthState],
                className
            )}
        >
            <StateIcon 
                disablePing={disablePing} 
                state={healthState} 
            />
        </Tooltip>
    );
};
