import { fiveMinutes } from 'queries/constants';
import { datasourceConfigQueryKeys } from 'queries/queryKeys/datasourceConfigKeys';
import { UseQueryOptions, useQuery, type UseQueryResult } from 'react-query';
import { ListPluginSourceConfigsForAllWorkspaces } from 'services/SourceConfigService';

type QueryReturnType = Awaited<ReturnType<typeof ListPluginSourceConfigsForAllWorkspaces>>;

/**
 * useQuery returning the data source configs within the tenant
 * @param options The react-query options e.g. refreshInterval
 * @returns All data source configs within the tenant
 */
export const useDatasourceConfigs = <T = QueryReturnType>(
    options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>
): UseQueryResult<T> =>
    useQuery(datasourceConfigQueryKeys.list, ListPluginSourceConfigsForAllWorkspaces, {
        staleTime: fiveMinutes,
        cacheTime: fiveMinutes,
        keepPreviousData: true,
        ...options
    });
