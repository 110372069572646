import LoadingSpinner from 'components/LoadingSpinner';
import { AppContext } from 'contexts/AppContext';
import { Suspense, useContext } from 'react';
import { NavTitleLink } from 'ui/nav/ui/NavLinks';
import { NavSection } from '../NavSection';
import { AddDashboardAndFolderButtons } from '../dashboards/AddDashboardAndFolderButton';
import { DashboardTree } from '../dashboards/DashboardTree';
import { DatasourceSelect } from './DatasourceSelect';

export default function WorkspaceDetail() {
    const { currentWorkspaceID } = useContext(AppContext);

    return (
        // Used size={17.5} to stop awkward, layout shift (though it may still surface as a result of pixel rounding!)
        <Suspense fallback={<LoadingSpinner size={17.5} className='ml-sm' />}>
            <div className='space-y-5 text-textSecondary'>
                <NavSection
                    title='Dashboards'
                    data-testid='navbar-menu-dashboards'
                    contentClassNames='px-0'
                    action={<AddDashboardAndFolderButtons />}
                >
                    <DashboardTree />
                </NavSection>

                <DatasourceSelect />

                <NavTitleLink to={`/scopes/${currentWorkspaceID}`} className='ml-[40.5px]' testId='scopesLink'>
                    Objects
                </NavTitleLink>

                <NavTitleLink to={`/monitoring/${currentWorkspaceID}`} className='ml-[40px]'>
                    Monitors
                </NavTitleLink>

                <NavTitleLink to={'/explore'} className='ml-[40px]'>
                    Data
                </NavTitleLink>

                <NavTitleLink to={`/explorer/${currentWorkspaceID}`} className='ml-[40px]'>
                    Map
                </NavTitleLink>
            </div>
        </Suspense>
    );
}
