import { FC } from 'react';

export interface PseudoTileProps {
    title: string;
    description: string;
}

export const NewDonutHolder: FC = ({ children }) => (
    <div className='h-full px-4 py-2 rounded-lg'>
        <div className='relative h-[50vh]'>{children}</div>
    </div>
);

export const OverviewGroup: FC<PseudoTileProps> = ({ title, description, children }) => (
    <div className='h-full'>
        <TitleBlock title={title} description={description} />
        {children}
    </div>
);

const TitleBlock: FC<PseudoTileProps> = ({ title, description }) => (
    <>
        <div className='space-x-3 text-base font-bold'>{title}</div>
        <div className='my-1 text-sm text-textSecondary'>{description}</div>
    </>
);

export const DonutHolder: FC<PseudoTileProps> = ({ title, description, children }) => (
    <div className='h-full px-4 py-2 border rounded-lg bg-tileBackground border-tileOutline'>
        <TitleBlock title={title} description={description} />
        <div className='relative h-[50vh]'>{children}</div>
    </div>
);

export const BlocksHolder: FC<PseudoTileProps> = ({ title, description, children }) => (
    <div className='px-4 py-2 overflow-auto rounded-lg max-h-[80vh] min-h-fit bg-tileBackground border border-tileOutline scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
        <TitleBlock title={title} description={description} />
        {/* Below div is necessary for the scrollbar to render correctly (for some reason) */}
        <div className='relative'>{children}</div>
    </div>
);

export const OverviewColumn: FC = ({ children }) => <div className='w-full p-4 overflow-hidden rounded-lg bg-tileBackground min-w-1/2'>{children}</div>;
