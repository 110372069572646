import DataStreamBaseTile from 'dashboard-engine/basetiles/DataStreamBaseTile';
import SimpleBaseTile from 'dashboard-engine/basetiles/SimpleBaseTile';
import GenericRepo from './GenericRepo';

const baseTileRepo = new GenericRepo<React.ComponentType<any>>({
    'simple-base-tile': SimpleBaseTile,
    'data-stream-base-tile': DataStreamBaseTile
});

export default baseTileRepo;
