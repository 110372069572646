import { PropsForInputType } from 'components/forms/input/buildInput';
import Input from 'components/forms/input/Input';
import PropTypes from 'prop-types';
import { SetOptional } from 'type-fest';
import Field, { FormFieldProps } from '../Field';

type FieldCheckboxProps = FormFieldProps &
    SetOptional<Omit<PropsForInputType<'checkbox'>, 'type'>, | 'validation'> & {
        name: string;
    };

/**
 * Field Checkbox
 * Helper for creating a Checkbox field. Unlike the other checkbox field, the  checkbox only
 * has a single input and label
 *
 * @example
 * `<Field.Checkbox name='test' validation={{ required: true }}/>`
 */
function FieldCheckbox({
    name,
    label,
    description,
    help,
    validation,
    checked,
    helpAlign,
    descriptionAbove,
    ...rest
}: FieldCheckboxProps) {
    return (
        <Field
            description={description}
            help={help}
            htmlFor={name}
            helpAlign={helpAlign}
            descriptionAbove={descriptionAbove}
        >
            <Input
                type='checkbox'
                name={name}
                label={label}
                help={help}
                validation={validation}
                {...rest}
            />
        </Field>
    );
}

FieldCheckbox.propTypes = {
    /**
     * Name of the input, used in output data.
     */
    name: PropTypes.string.isRequired,
    /**
     * Text to be displayed as checkbox label
     */
    label: PropTypes.string.isRequired,
    /**
     * Text to be displayed underneath input
     */
    description: PropTypes.string,
    /**
     * Text to display as the tooltip
     */
    title: PropTypes.string,
    /**
     * Text to displayed as help icon tooltip
     */
    help: PropTypes.string,
    /**
     * Validation settings - see React Hook Form
     */
    validation: PropTypes.object,
    /**
     * Boolean deciding if the input is checked
     */
    checked: PropTypes.bool,
    value: PropTypes.string,
    classNames: PropTypes.string,
    onClick: PropTypes.func
};

export default FieldCheckbox;
