import { useTenant } from './useTenant';
import { Tier, validateTier } from '@squaredup/tenants';

type UseTierOptions = Omit<Parameters<typeof useTenant>[0], 'select'>;

/**
 * useQuery returning the user's current tier.
 * Makes use of useTenant under the hood so remains fast and responsive to tenant changes.
 * @param options The react-query options e.g. refreshInterval
 * @returns Valid Tier object or undefined if the tenant tier is missing or malformed
 */
export const useTier = (options?: UseTierOptions) => {
    return useTenant({
        ...options,
        select: (tenant): Tier | undefined => {
            const result = validateTier(tenant.tier);
            return result.success ? result.data : undefined;
        }
    });
};
