import LoadingSpinner from 'components/LoadingSpinner';
import { useAppContext } from 'contexts/AppContext';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router-dom';
import { GetScope } from 'services/WorkspaceService';

/**
 * This page is used when drilling down to a scope
 * It fetching the scope and sets the current workspace to the scope's workspace,
 * then redirects to the /scopes list page to view that specific scope
 */
const Scope = () => {
    const { id = 'no_scope' } = useParams();
    const { setCurrentWorkspaceID, currentWorkspaceID } = useAppContext();

    const { data: { workspaceId } = {}, isLoading } = useQuery(scopeQueryKeys.detail(id), () =>
        GetScope(currentWorkspaceID, id)
    );

    if (workspaceId && workspaceId !== currentWorkspaceID) {
        setCurrentWorkspaceID({ id: workspaceId });
    }

    if (isLoading) {
        return (
            <div className='flex flex-col flex-1 w-full h-full min-h-0 items-center justify-center'>
                <LoadingSpinner size={20} />
            </div>
        );
    }

    return <Navigate to={`/scopes/${workspaceId}?viewScope=${id}`} replace={true} />;
};

export default Scope;
