import { cn } from '@/lib/cn';
import { ResponsiveLine } from '@nivo/line';
import { getShape, nullShapeFormatter } from '@squaredup/data-streams';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDrilldownContext } from 'ui/editor/dataStream/contexts/DrilldownContext';
import NoDataPlaceholder from '../../../components/NoDataPlaceholder';
import { DataStreamVisualisation } from '../../types/Visualisation';
import nivoTheme from '../../util/nivoTheme';
import { formatTickAsColumn, tickCount } from '../../util/tickFormatter';
import { LegendWrapper } from '../components/LegendWrapper';
import { LegendPosition } from '../components/Legends';
import { getColor } from '../getColor';
import { NUM_Y_TICKS, getGridYValues } from '../getGridYValues';
import { useVisualizationTheme } from '../hooks/useVisualizationTheme';
import { DataStreamLineGraphConfig } from './Config';
import { CustomBottomAxis } from './LineGraphCustomBottomAxis';
import { LineGraphLinesLayer } from './LineGraphLinesLayer';
import { getYScaleOptions } from './LineGraphScaleOptions';
import { formatSeries, toSeries } from './LineGraphToSeries';
import { getTrendLines } from './LineGraphTrendLines';
import { LineSeries } from './lineGraphTypes';
import { getAreaOpacity } from './styles';

const calculateMargins = (legendPosition: LegendPosition | undefined, xAxisLabelVisible: boolean) => {
    let margins = { top: 6, right: 25, bottom: 65, left: 30 };
    if (legendPosition === 'bottom') {
        margins.bottom = 60;
        if (!xAxisLabelVisible) {
            margins.bottom = 35;
        }
    }

    return margins;
};

/**
 * Get the minimum value of all series
 * @param series Line chart series
 * @returns Min value
 */
const getMinYValue = (series: LineSeries[]) => Math.min(...series.flatMap((s) => s.data.map((d) => d.y)));

const DataStreamLineGraph: DataStreamVisualisation<DataStreamLineGraphConfig> = ({ data, config, onClick }) => {
    const graphRef = useRef<HTMLDivElement>(null);
    const legendContainerRef = useRef<HTMLDivElement>(null);
    const { isDrilldownEnabled } = useDrilldownContext();

    const {
        series: seriesData,
        valueColumn,
        unitLabel
    } = useMemo(() => toSeries(data, config, { isDrilldownEnabled }), [data, config, isDrilldownEnabled]);

    const lineGraphTheme = useVisualizationTheme('line');

    const [hoveredLineId, setHoveredLineId] = useState<string | number | undefined>(undefined);

    const shape = valueColumn?.shapeName && getShape(valueColumn?.shapeName);

    const formatTick = useMemo(
        () => (valueColumn == null ? nullShapeFormatter : formatTickAsColumn(valueColumn, { data, valueColumn })),
        [data, valueColumn]
    );

    const series = formatSeries(seriesData, config.cumulative, formatTick);

    const trendLines = config.showTrendLine ? getTrendLines(series) : null;

    const isLegendVertical = config.legendPosition === 'top' || config.legendPosition === 'bottom';

    const lineGraphLayer = useCallback(
        (props) => (
            <LineGraphLinesLayer
                {...props}
                trendLines={trendLines}
                legendContainerRef={legendContainerRef}
                graphRef={graphRef}
                hoveredLineId={hoveredLineId}
                setHoveredLineId={setHoveredLineId}
            />
        ),
        [graphRef, legendContainerRef, trendLines, hoveredLineId]
    );

    const yScaleOptions = getYScaleOptions(series, config);

    const getLineGraphColor = (datum: LineSeries) => getColor(config, lineGraphTheme, datum.id, datum.index);

    const legend = series.map((s) => ({
        id: s.id,
        label: s.id,
        color: getLineGraphColor(s),
        isFocused: hoveredLineId !== undefined ? hoveredLineId === s.id : true
    }));

    if (!data || data.rows.length === 0) {
        return <NoDataPlaceholder />;
    }

    const showGrid = config.showGrid ?? true;

    const margins = calculateMargins(config.showLegend ? config.legendPosition : undefined, Boolean(config.xAxisLabel));

    const lineGraph = (
        <div
            ref={graphRef}
            className={cn('relative w-full h-full font-inter', onClick && 'cursor-pointer')}
            data-visualization='data-stream-line-graph'
            onClick={onClick}
        >
            <ResponsiveLine
                data={series}
                margin={margins}
                colors={getLineGraphColor}
                xScale={{
                    type: 'time',
                    format: '%Q',
                    precision: 'millisecond',
                    useUTC: true
                }}
                yScale={{
                    type: 'linear',
                    min: yScaleOptions.min,
                    max: yScaleOptions.max,
                    stacked: false,
                    reverse: false
                }}
                layers={['grid', 'markers', 'crosshair', lineGraphLayer, 'axes', 'legends', CustomBottomAxis]}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    legend: config?.xAxisLabel ?? '',
                    legendOffset: 50,
                    legendPosition: 'middle',
                    renderTick: () => null
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 0,
                    tickPadding: 0,
                    tickValues: NUM_Y_TICKS,
                    legend:
                        (config?.yAxisLabel ? config.yAxisLabel : null) ?? unitLabel ?? shape?.displayName ?? 'Value',
                    legendOffset: -25,
                    legendPosition: 'middle',
                    renderTick: ({ x, y, textBaseline, textX, textY, value }: any) => {
                        const formattedValue = formatTick(value);

                        return (
                            <g transform={`translate(${x + 2},${y})`}>
                                <text
                                    alignmentBaseline={textBaseline}
                                    textAnchor='right'
                                    transform={`translate(${textX},${textY})`}
                                    className='fill-textSecondary'
                                    style={{
                                        opacity: 1,
                                        stroke: 'var(--tileBackground)',
                                        strokeWidth: '3px',
                                        fontWeight: 500,
                                        fontSize: '11px',
                                        fontFamily: 'Inter',
                                        paintOrder: 'stroke',
                                        letterSpacing: '1px'
                                    }}
                                >
                                    {formattedValue}
                                </text>
                            </g>
                        );
                    }
                }}
                theme={nivoTheme}
                areaBaselineValue={yScaleOptions.areaBaseline} // Ensure the area doesn't go past the axis when in edit mode
                enablePoints={config.dataPoints ?? false}
                gridYValues={getGridYValues(showGrid, yScaleOptions.min, getMinYValue(series))}
                gridXValues={showGrid ? tickCount((graphRef.current?.clientWidth || 0) - margins.left) : [0]} // Match grid lines to ticks
                enableSlices='x'
                enableArea={config.shading ?? true}
                areaOpacity={getAreaOpacity(series)}
                defs={[
                    {
                        id: 'areaGradient',
                        type: 'linearGradient',
                        colors: [
                            { offset: 0, color: 'inherit' },
                            { offset: 25, color: 'inherit', opacity: 1 },
                            { offset: 100, color: 'inherit', opacity: 0 }
                        ]
                    }
                ]}
                fill={[{ match: '*', id: 'areaGradient' }]}
            />
        </div>
    );

    if (config.showLegend && config.legendPosition) {
        return (
            <LegendWrapper
                legend={legend}
                position={config.legendPosition}
                vizRef={graphRef}
                legendContainerRef={legendContainerRef}
                containerSizePercentage={isLegendVertical ? 0.3 : 0.25}
                onHover={(attributes) => setHoveredLineId(attributes.id)}
                onLeave={() => setHoveredLineId(undefined)}
                vizContainerClassname='flex-1'
                legendContainerClassname={cn('p-5', { 'pt-0': config.legendPosition === 'top' })}
            >
                {lineGraph}
            </LegendWrapper>
        );
    }

    return lineGraph;
};

DataStreamLineGraph.config = 'DataStreamLineGraphConfig';

export default DataStreamLineGraph;
