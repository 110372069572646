import LoadingSpinner from 'components/LoadingSpinner';
import { useQuery } from 'react-query';
import { Navigate } from 'react-router-dom';
import { Tenant, TENANT_DATA } from 'services/TenantService';
import { IsTenantAdmin } from '../services/AccessControlService';

// Wrapper for rendering children only if the user has admin (AD) permissions to the tenant
function RequireTenantAdmin({ children, redirectTo }) {
    const { data: tenant, isLoading } = useQuery(TENANT_DATA, Tenant);

    return isLoading ? (
        <LoadingSpinner className='mt-md' />
    ) : IsTenantAdmin(tenant) ? (
        children
    ) : (
        <Navigate to={redirectTo} replace={true} />
    );
}

export default RequireTenantAdmin;
