import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faCheckCircle, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'components/LoadingSpinner';
import { useAppContext } from 'contexts/AppContext';
import { ErrorDetails } from 'pages/datasources/components/ImportInfo';
import { useDefaultDashboardCreation } from 'pages/datasources/components/useDefaultDashboardCreation';
import PluginIcon from 'pages/scope/PluginIcon';
import { flattenDashboardIdOrder } from 'queries/utils/dashboardSorted';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { PLUGIN_DETAIL } from 'services/PluginService';
import { Get } from 'services/SourceConfigService';
import { FooterButtons } from './components/FooterButtons';
import { PluginConfig, usePluginContext } from './components/PluginConfigContext';
import { useDelay } from './components/useDelay';

type LoadingAnimationProps = Omit<LoadingModalProps, 'onClose'> & {
    status: LoadStatus;
};

export function LoadingAnimation({ pluginName, status }: LoadingAnimationProps) {
    const circles = [];

    const lerp = (start: number, end: number, t: number) => start + (end - start) * t;

    for (let i = 0; i <= 11; i++) {
        circles.push(
            <circle cx={`${lerp(27, 63, i / 11)}`} cy={7 - Math.sin(Math.PI * (i / 11))} r='0.5'>
                <animate
                    attributeName='fill'
                    values='#fff;#111'
                    dur='1s'
                    repeatCount='indefinite'
                    calcMode='linear'
                    begin={`${i * 0.1}s`}
                />
            </circle>
        );
    }

    const linePath = 'M20 7 Q45 4 70 7';

    return (
        <svg strokeWidth='1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 90 15'>
            {status === 'loading' && circles}
            {status === 'success' && (
                <>
                    <path className='stroke-statusHealthySecondary' fill='none' d={linePath} />
                    <circle cx='45' cy='6' r='3' fill='currentColor' className='text-backgroundSecondary' />
                    <FontAwesomeIcon
                        x='42'
                        width='6'
                        y='-2'
                        className='text-statusHealthyPrimary'
                        icon={faCheckCircle}
                    />
                </>
            )}
            {status === 'error' && (
                <>
                    <path className='stroke-statusErrorSecondary' fill='none' d={linePath} />
                    <circle cx='45' cy='6' r='3' fill='currentColor' className='text-backgroundSecondary' />
                    <FontAwesomeIcon x='42' width='6' y='-2' className='text-statusErrorPrimary' icon={faXmarkCircle} />
                </>
            )}
            <PluginIcon pluginName={pluginName} x='13' width='12' />
            <PluginIcon pluginName='SquaredUp' x='65' width='12' />
        </svg>
    );
}

interface ProgressSpinnerProps {
    state: LoadStatus;
}
function ProgessSpinner({ state }: ProgressSpinnerProps) {
    if (state === 'loading') {
        return <LoadingSpinner size={32} className='w-5 h-5' />;
    }

    if (state === 'success' || state === 'warning') {
        return (
            <FontAwesomeIcon
                className={cn(
                    'w-5 h-5',
                    state === 'success' ? 'text-statusHealthyPrimary' : 'text-statusWarningPrimary'
                )}
                icon={faCheckCircle}
            />
        );
    }

    return <FontAwesomeIcon className='w-5 h-5 text-statusErrorPrimary' icon={faXmarkCircle} />;
}

interface LoadingStepProps {
    loadingText: string;
    errorText: string;
    successText: string;
    state: LoadStatus;
}

const LoadingStep: React.FC<LoadingStepProps> = ({ loadingText, errorText, successText, state, children }) => {
    const message = state === 'loading' ? loadingText : state === 'error' ? errorText : successText;

    return (
        <div className='rounded bg-componentBackgroundSecondary'>
            <div className='flex items-center h-12 px-4 py-2 space-x-4 font-semibold rounded bg-componentBackgroundSecondary'>
                <ProgessSpinner state={state} />
                <h3>{message}</h3>
            </div>

            {children}
        </div>
    );
};

type LoadingModalProps = {
    pluginName: string;
    onClose?: () => void;
};

type LoadStatus = 'loading' | 'success' | 'error' | 'warning';

const combineStatus = (...states: LoadStatus[]): LoadStatus => {
    let current: LoadStatus = 'success';
    for (let state of states) {
        if (state === 'loading') {
            current = 'loading';
        }
        if (state === 'error') {
            return 'error';
        }
    }
    return current;
};

function PluginLoadingModal({ pluginName, onClose }: LoadingModalProps) {
    const { newId: configId, installSampleDashboards, setConfig, setInstallSampleDashboards } = usePluginContext();

    // If users go back to the form, we don't want to install the sample dashboards again,
    // so installSampleDashboards is set to false when the component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const hadSampleDashboards = useMemo(() => installSampleDashboards, []);

    const { data: pluginData, isLoading } = useQuery(
        [PLUGIN_DETAIL, configId],
        () => {
            return Get(configId);
        },
        {
            onSuccess: (data) => {
                setConfig(data as PluginConfig);
            }
        }
    );

    const {
        mutate,
        isLoading: isInstallingDashboards,
        isSuccess: isDashboardInstallationSuccessfull,
        isError: isDashboardInstallationUnsuccessfull,
        data: sampleDashboards
    } = useDefaultDashboardCreation();

    const firstDashboard = useMemo(() => {
        if (!sampleDashboards?.length) {
            return;
        }

        const folder = sampleDashboards[0].rootFolder;

        return (
            flattenDashboardIdOrder(folder?.dashboardIdOrder || []).find(({ id }) =>
                sampleDashboards.some((dash) => dash.id === id)
            ) || sampleDashboards[0]
        );
    }, [sampleDashboards]);

    const { currentWorkspaceID: workspaceId } = useAppContext();

    useEffect(() => {
        if (!isLoading && workspaceId && installSampleDashboards) {
            mutate({ configId: configId, pluginId: (pluginData?.plugin as any).pluginId, workspaceId });
            setInstallSampleDashboards(false);
        }
    }, [
        configId,
        mutate,
        pluginData?.plugin,
        workspaceId,
        installSampleDashboards,
        setInstallSampleDashboards,
        isLoading
    ]);

    const completed =
        !isInstallingDashboards &&
        (pluginData?.importStatus?.status === 'failed' || pluginData?.importStatus?.status === 'succeeded');

    const isConfiguring = useDelay(5_000, completed);

    const sampleDashStepState =
        isInstallingDashboards || isConfiguring ? 'loading' : isDashboardInstallationSuccessfull ? 'success' : 'error';

    const indexingStep = completed
        ? pluginData?.importStatus?.status === 'failed'
            ? 'error'
            : (pluginData?.importStatus?.warnings?.length ?? 0) > 0
            ? 'warning'
            : 'success'
        : 'loading';

    return (
        <div className='flex flex-col w-[90vw] max-w-xl relative pt-12'>
            <div className='pb-5'>
                <LoadingAnimation
                    pluginName={pluginName}
                    status={combineStatus(
                        hadSampleDashboards ? sampleDashStepState : 'success',
                        indexingStep,
                        isConfiguring ? 'loading' : 'success'
                    )}
                />
            </div>

            <div className='pb-8 text-center'>
                <DetailText
                    configureState={isConfiguring ? 'loading' : 'success'}
                    indexingState={indexingStep}
                    warningCount={pluginData?.importStatus?.warnings?.length ?? 0}
                />
            </div>

            <ul className='flex flex-col px-8 space-y-4'>
                <li>
                    <LoadingStep
                        loadingText='Configuring data streams'
                        successText='Data streams configured'
                        errorText='' // UNREACHABLE
                        state={isConfiguring ? 'loading' : 'success'}
                    />
                </li>

                {hadSampleDashboards && (
                    <li>
                        <LoadingStep
                            loadingText='Installing sample dashboards'
                            successText='Sample dashboards installed'
                            errorText='Sample dashboard installation failed'
                            state={sampleDashStepState}
                        />
                    </li>
                )}

                <li>
                    <LoadingStep
                        loadingText='Establishing connection and indexing objects'
                        successText='Connection and object indexing completed'
                        errorText='Connection or object indexing failed'
                        state={indexingStep}
                    >
                        {(indexingStep === 'error' || indexingStep === 'warning') && (
                            <ErrorDetails
                                message={pluginData?.importStatus?.message}
                                correlationId={pluginData?.importStatus?.correlationId}
                                pluginId={(pluginData?.plugin as any).pluginId}
                                originalError={pluginData?.importStatus?.originalError}
                                warnings={pluginData?.importStatus?.warnings}
                                className='pr-4 pl-[46px] pb-4 bg-tileBackgroundPrimary text-textSecondary'
                            />
                        )}
                    </LoadingStep>
                </li>
            </ul>

            <FooterButtons
                completed={completed}
                importStatus={pluginData?.importStatus}
                dashboardId={firstDashboard?.id}
                isInstallingDashboards={
                    isInstallingDashboards ||
                    isDashboardInstallationUnsuccessfull ||
                    isDashboardInstallationSuccessfull ||
                    !hadSampleDashboards
                }
                configId={configId}
                pluginName={pluginName}
                onClose={onClose}
            />
        </div>
    );
}

type DetailState = {
    configureState: LoadStatus;
    indexingState: LoadStatus;
    warningCount: number;
};

function DetailText({ configureState, indexingState, warningCount }: DetailState) {
    if (configureState === 'loading') {
        return <Text.H3>Setting up data source</Text.H3>;
    }

    if (indexingState === 'loading') {
        return (
            <>
                <Text.H3>Object indexing in progress</Text.H3>
                <Text.Body className='text-textSecondary'>This may take a few minutes</Text.Body>
            </>
        );
    }

    if (indexingState === 'error') {
        return <Text.H3>Data source setup failed</Text.H3>;
    }

    return (
        <Text.H3>
            Data source setup complete {warningCount > 0 && `(${warningCount} warning${warningCount > 1 ? 's' : ''})`}
        </Text.H3>
    );
}

export default PluginLoadingModal;
