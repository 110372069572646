import { cn } from '@/lib/cn';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNetworkBackground } from 'components/map/context/NetworkMapStoreContext';
import { PinnableNodeData } from 'components/map/data/types';
import { TruncatedText } from 'components/TruncatedText';
import { mapBackgroundColors, mapFillColors } from 'dashboard-engine/visualisations/Network/utils/mapColors';
import { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { ORGANISATION_NODE_SIZE } from '../OrganisationNode';

interface OrganisationNodeInternalProps extends PinnableNodeData {
    id: string;
}

export const OrganisationNodeInternal: React.FC<OrganisationNodeInternalProps> = memo(({ label }) => {
    const background = useNetworkBackground();

    return (
        <div
            className='relative inline-flex items-center justify-center rounded-full'
            style={{ width: ORGANISATION_NODE_SIZE, height: ORGANISATION_NODE_SIZE }}
        >
            <svg viewBox='0 0 100 100' className={cn('absolute inset-0 w-full', mapFillColors[background])}>
                <circle
                    cx='50%'
                    cy='50%'
                    r='40'
                    vectorEffect='non-scaling-stroke'
                    strokeWidth={5}
                    className={cn('stroke-filterActive', mapFillColors[background])}
                />
            </svg>

            <FontAwesomeIcon icon={faGlobe} size='4x' className='relative z-10' />

            <Handle
                type='target'
                position={Position.Top}
                className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
            />
            <Handle
                type='source'
                position={Position.Bottom}
                className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
            />

            {label && (
                <>
                    <span className='sr-only'>{label}</span>
                    <div
                        className={cn(
                            'text-primary rounded-sm text-base font-bold px-1 py-0.5 leading-tight absolute top-[calc(100%+4px)] max-w-[160px] left-1/2 -translate-x-1/2 text-center',
                            mapBackgroundColors[background]
                        )}
                    >
                        <TruncatedText title={label}>{label}</TruncatedText>
                    </div>
                </>
            )}
        </div>
    );
});
