import { HealthState } from '@squaredup/monitoring';
import { oneMinute } from 'queries/constants';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { TileStates } from 'queries/types/types';
import { UseQueryOptions, useQuery } from 'react-query';
import { GetDashboardHealthById } from 'services/HealthService';

export const getDashboardHealthWithTileStates = async (dashboardId: string) => {
    const { dashboardState, tileStates } = await GetDashboardHealthById(dashboardId!);

    return {
        state: dashboardState.state,
        tileStates: tileStates.reduce((acc, h) => {
            acc[h.tileId.split('/')[1]] = {
                state: h.state,
                stateReason: h.stateReason?.text
            };
            return acc;
        }, {} as TileStates)
    };
};

type QueryReturnType = {
    state: HealthState;
    tileStates: TileStates;
};

/**
 * useQuery returning a dashboards health state rollup (current state and individual tile states)
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of all dashboards in a tenant
 */
export const useDashboardHealthStateRollup = <T = QueryReturnType>(
    dashboardId?: string,
    options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>
) => useQuery(
    dashboardQueryKeys.stateRollup(dashboardId), 
    async () => getDashboardHealthWithTileStates(dashboardId!), 
    {
        enabled: Boolean(dashboardId),
        staleTime: oneMinute,
        cacheTime: oneMinute,
        refetchInterval: oneMinute,
        keepPreviousData: true,
        ...options
    }
);
