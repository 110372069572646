import * as React from 'react';

export default function useTimeout<T>(fn: (...args: T[]) => void) {
    const timeout = React.useRef<ReturnType<typeof setTimeout>>();
    const callback = React.useRef(fn);

    const stop = React.useCallback(() => {
        timeout.current && clearTimeout(timeout.current);
        timeout.current = undefined;
    }, []);

    const start = React.useCallback(
        (ms: number, ...args: T[]) => {
            stop();

            timeout.current = setTimeout(() => {
                callback.current(...args);
            }, ms);
        },
        [stop]
    );

    const isActive = React.useCallback(() => {
        return timeout.current;
    }, []);

    React.useEffect(() => {
        callback.current = fn;
    }, [fn]);

    // if we are demounting this hook then we should cancel any running functions
    React.useEffect(() => () => stop(), [stop]);

    return { stop, start, isActive };
}
