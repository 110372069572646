import { isProd } from 'lib/environment';

// Product codes, used for certain lookups (or if you want to dynamically fetch prices)
export const STARTER_PADDLE_ID = isProd ? 'pro_01jbxw3053bqe6wqn0wcszd4zw' : 'pro_01ja8dv9n3k0d7ckv0d9fmbcsr';
export const PRO_PADDLE_ID = isProd ? 'pro_01jbxw3a2gde98qe5nr68rw6v5' : 'pro_01ja8ee71jcdgefq6wfjxj3y8r';

// Prices, which is what users actually need to choose between.
export const STARTER_MONTHLY_PADDLE_ID = isProd ? 'pri_01jbxw7bm8k21msvcwqj477nrd' : 'pri_01ja8dynesc8y5e77d37k2h851';
export const STARTER_ANNUALLY_PADDLE_ID = isProd ? 'pri_01jbxw5w0jk3ak8vna48c9hdad' : 'pri_01ja8e0stmj3t1c6536yg4jxhb';
export const PRO_MONTHLY_PADDLE_ID = isProd ? 'pri_01jbxwervp9d0t59fssb9qq4n5' : 'pri_01ja8ej1zgtzz0zedkh7b6k9r8';
export const PRO_ANNUALLY_PADDLE_ID = isProd ? 'pri_01jbxwe91knqg0ycwhhwd35vc8' : 'pri_01ja8ek2rnx5yxnhq50qxz25zh';
export const ENTERPRISE_FAKE_ID = '-1';

export const planOptions = [
    { label: 'Starter (Monthly)', value: STARTER_MONTHLY_PADDLE_ID },
    { label: 'Starter (Annually)', value: STARTER_ANNUALLY_PADDLE_ID },
    { label: 'Pro (Monthly)', value: PRO_MONTHLY_PADDLE_ID },
    { label: 'Pro (Annually)', value: PRO_ANNUALLY_PADDLE_ID },
    { label: 'Enterprise', value: ENTERPRISE_FAKE_ID }
];

export const planRanges: { [key: string]: { min: number; max: number } } = {
    [STARTER_MONTHLY_PADDLE_ID]: { min: 1, max: 1000 },
    [STARTER_ANNUALLY_PADDLE_ID]: { min: 1, max: 1000 },
    [PRO_MONTHLY_PADDLE_ID]: { min: 5, max: 1000 },
    [PRO_ANNUALLY_PADDLE_ID]: { min: 5, max: 1000 },
    [ENTERPRISE_FAKE_ID]: { min: 10, max: 100 }
};
