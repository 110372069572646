import { faPencil, faRotate, faTrash } from '@fortawesome/pro-solid-svg-icons';
import Button from 'components/button/Button';
import { Pill, pillStatusText, type PillState } from 'components/pill/Pill';
import Tooltip from 'components/tooltip/Tooltip';
import { formatDuration, intervalToDuration } from 'date-fns';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { DetailsPopover, getSupportingText } from 'pages/datasources/components/ImportInfo';
import PluginIcon from 'pages/scope/PluginIcon';
import { PluginProjectedRow } from './Plugins';
import TruncateWithTooltip from 'pages/settings/TruncateWithTooltip';
import { FC } from 'react';
import { Action } from 'pages/components/ApplicationTable/types';
import { ColumnDef, Row, SortDirection } from '@tanstack/react-table';
import { sortImportStatus } from 'pages/components/ApplicationTable/ApplicationTableSort';
import type { ImportStatus } from 'dynamo-wrapper';
import { PluginPill } from 'pages/settings/plugins/components/PluginPill';

interface PluginsTableProps {
    plugins: PluginProjectedRow[];
    objectCountsMap: Map<string, number>;
    setPluginWorkspaceModal: (plugin: PluginProjectedRow) => void;
    onIndex: (plugin: PluginProjectedRow) => void;
    onEdit: (plugin: PluginProjectedRow) => void;
    onDelete: (plugin: PluginProjectedRow) => void;
    canIndex: (plugin: PluginProjectedRow) => boolean;
    isIndexing: (plugin: PluginProjectedRow) => boolean;
    canEdit: (plugin: PluginProjectedRow) => boolean;
    canDelete: (plugin: PluginProjectedRow) => boolean;
}

const PluginsApplicationTable = ApplicationTable<PluginProjectedRow, string>();

const PluginsTable: FC<PluginsTableProps> = ({
    plugins,
    objectCountsMap,
    setPluginWorkspaceModal,
    onIndex,
    onEdit,
    onDelete,
    canIndex,
    isIndexing,
    canEdit,
    canDelete
}) => {
    const getObjectCount = (row: Row<PluginProjectedRow>) => objectCountsMap.get(row.original.id) ?? 0;

    let getIsSortedStatus: (() => SortDirection | false);

    const columns: ColumnDef<PluginProjectedRow, string>[] = [
        {
            id: 'name',
            header: 'Name',
            cell: ({ row, getValue }) => {
                return (
                    <TruncateWithTooltip title={getValue()}>
                        <PluginIcon className='h-6 mr-2' pluginName={row.original.plugin?.displayName || ''} />
                        <div className='truncate'>{getValue()}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 380,
            accessorKey:'displayName'
        },
        {
            id: 'plugin',
            header: 'Plugin',
            cell: ({ row, getValue }) => {
                return (
                    <TruncateWithTooltip title={getValue()}>
                        <div className='truncate'>{getValue()}</div>
                        <div className='self-center pl-3'><PluginPill onPrem={row.original.onPrem} /></div>
                    </TruncateWithTooltip>
                );
            },
            size: 280,
            accessorKey: 'nameAndVersion'
        },
        {
            id: 'status',
            header: 'Status',
            cell: ({ row, column }) => {
                getIsSortedStatus = column.getIsSorted;
                const rowStatusValue = row.original.importStatus;
                const status = (
                    rowStatusValue?.status
                        ? (rowStatusValue.status === 'succeeded' && (rowStatusValue.totalWarningCount ? 'warnings' : 'succeeded')) ||
                        rowStatusValue.status
                        : 'notRun'
                ) as PillState;

                return <Pill.Status status={status}>{pillStatusText[status]}</Pill.Status>;
            },
            size: 125, // Minimum width required for widest status ("Connected")
            accessorKey: 'importStatus',
            // eslint-disable-next-line max-len
            sortingFn: (rowA, rowB, columnId) => sortImportStatus<PluginProjectedRow>(rowA, rowB, columnId, getIsSortedStatus),
            sortDescFirst: false
        },
        {
            id: 'objectIndexing',
            header: 'Object Indexing',
            cell: ({ row }) => {
                const rowStatusValue = row.original.importStatus;
                const text = getSupportingText(rowStatusValue as ImportStatus, true);
                const totalWarningCount = rowStatusValue?.totalWarningCount || 0;
                const hasWarnings = totalWarningCount > 0;
                const warningCountText = hasWarnings
                    ? ` (${totalWarningCount} warning${totalWarningCount === 1 ? '' : 's'})`
                    : '';

                let tooltipText = '';

                switch (rowStatusValue?.status) {
                    case 'notRun': {
                        tooltipText = 'Indexing has never been run';
                        break;
                    }
                    case 'waitingForData':
                    case 'started':
                    case 'running': {
                        const dataLastReceived: number | undefined =
                            'dataLastReceived' in rowStatusValue ? rowStatusValue?.dataLastReceived : undefined;
                        tooltipText = `Indexing started at ${new Date(rowStatusValue?.started).toLocaleString()}${
                            dataLastReceived != null
                                ? `, data last received at ${new Date(dataLastReceived).toLocaleString()}`
                                : ''
                        }`;
                        break;
                    }
                    case 'succeeded': {
                        const duration = intervalToDuration({
                            start: new Date(rowStatusValue?.started),
                            end: new Date(rowStatusValue?.ended)
                        });
                        let durationString = formatDuration(duration);
                        if (!durationString) {
                            durationString = '0 seconds';
                        }
                        const endedString = new Date(rowStatusValue?.ended).toLocaleString();
                        tooltipText = `Indexing finished at ${endedString} after ${durationString}`;
                        break;
                    }
                    case 'failed': {
                        tooltipText = `Indexing failed at ${new Date(rowStatusValue?.ended).toLocaleString()}`;
                        break;
                    }
                    default: {
                        tooltipText = 'Could not determine import state';
                    }
                }

                return (
                    <div className='flex'>
                        <Tooltip title={tooltipText}>
                            <div>{`${text}${warningCountText ? ` ${warningCountText}` : ''}`}</div>
                        </Tooltip>
                        {(hasWarnings || rowStatusValue?.status === 'failed') && (
                            <DetailsPopover
                                triggerClassOverride='underline ml-4'
                                warnings={rowStatusValue?.warnings}
                                message={rowStatusValue?.message}
                                correlationId={rowStatusValue?.correlationId}
                                originalError={rowStatusValue?.originalError}
                                pluginId={row.original.plugin?.pluginId}
                                pluginName={row.original.plugin?.name}
                            />
                        )}
                    </div>
                );
            },
            size: 440,
            accessorKey: 'importStatus',
            enableSorting: false
        },
        {
            id: 'objectCount',
            header: 'Objects',
            cell: ({ row }) => {
                const val = objectCountsMap.size ? getObjectCount(row) : '';
                return !val && row.original.plugin?.importNotSupported === true
                    ? (
                        <Tooltip title={'This data source does not support object indexing'}>
                            <div>{'-'}</div>
                        </Tooltip>
                    )
                    : val;
            },
            size: 140,
            accessorKey: 'objectCount',
            sortingFn: (rowA, rowB) => {
                let valA = getObjectCount(rowA);
                if (valA === 0 && rowA.original.plugin?.importNotSupported === true) {
                    valA = -1;
                }
                let valB = getObjectCount(rowB);
                if (valB === 0 && rowB.original.plugin?.importNotSupported === true) {
                    valB = -1;
                }
                return valA - valB;
            }
        },
        {
            id: 'workspaceCount',
            header: 'Workspaces',
            cell: ({ row, getValue }) => {
                return (
                    <Button type='button' variant='tertiary' onClick={() => setPluginWorkspaceModal(row.original)}>
                        <span className='cursor-pointer select-none text-textLink hover:underline'>{getValue()}</span>
                    </Button>
                );
            },
            size: 120,
            accessorKey: 'workspaceCount'
        }
    ];

    const actions: Action[] = [
        {
            visible: canIndex,
            loading: isIndexing,
            loadingTooltip: 'Refreshing',
            action: onIndex,
            icon: faRotate,
            dataTestId: 'indexPluginButton',
            tooltip: 'Refresh index'
        },
        {
            visible: canEdit,
            action: onEdit,
            icon: faPencil,
            dataTestId: 'editPluginButton',
            tooltip: 'Edit data source'
        },
        {
            visible: canDelete,
            action: onDelete,
            icon: faTrash,
            dataTestId: 'deletePluginButton',
            tooltip: 'Delete data source'
        }
    ];

    return (
        <PluginsApplicationTable
            config={{
                actions,
                noDataMessage: 'There are no data sources configured.'
            }}
            columns={columns}
            data={plugins}
        />
    );
};

export default PluginsTable;
