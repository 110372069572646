import { Switch } from '@/components/Switch';
import Field from 'components/forms/field/Field';
import { FormToggle } from 'components/forms/toggle/FormToggle';
import { useController, useFormContext } from 'react-hook-form';

const ALL = 'all';
const NONE = 'none';

const defaultOptions = [
    {
        label: 'All objects',
        value: ALL
    },
    {
        label: 'None',
        value: NONE
    }
];

export const DashboardVariableDefaultSelection: React.FC = () => {
    const { field } = useController({
        name: 'default'
    });

    const { setValue } = useFormContext();

    return (
        <div>
            <Field label='Default value' help='Default object selection for the dashboard'>
                <div className='flex items-stretch space-x-2'>
                    <Switch
                        value={field.value}
                        options={defaultOptions}
                        onValueChange={(value) => {
                            if (value) {
                                field.onChange(value);
                            }
                        }}
                    />
                </div>
            </Field>

            {field.value === NONE ? (
                <Field label='Options'>
                    <FormToggle
                        wrapperClassName='!mt-2 rounded-md'
                        name='allowMultipleSelection'
                        label='Allow multiple selection'
                        onCheckedChange={(checked) => {
                            setValue('allowMultipleSelection', Boolean(checked));
                        }}
                    />
                </Field>
            ) : (
                // Placeholder element so the modal doesn't move around when 'default' field value changes
                <div className='h-[87px]'></div>
            )}
        </div>
    );
};
