import Button, { buttonVariants } from 'components/button/Button';
import { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FallbackProps } from 'react-error-boundary';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const errorLocation = useRef(location.pathname);

    useEffect(() => {
        if (location.pathname !== errorLocation.current) {
            resetErrorBoundary();
        }
    }, [location.pathname, resetErrorBoundary]);

    return (
        <div className='flex w-full h-1/2' role='alert'>
            <div className='self-center w-1/2 max-w-3xl mx-auto border bg-componentBackgroundPrimary border-statusErrorPrimary px-xl py-md rounded-input'>
                <Text.H1 className='mb-4 text-2xl font-bold'>It looks like something went wrong</Text.H1>
                <pre className='mb-6 text-statusErrorPrimary'>Error: {error.message}</pre>
                <div className='mb-8 space-x-4'>
                    <Button onClick={resetErrorBoundary}>Try again</Button>
                    <Button
                        onClick={() => {
                            resetErrorBoundary();
                            navigate('/status');
                        }}
                        variant='secondary'
                    >
                        Go to Organization home
                    </Button>
                </div>
                <Text.H4 className='mb-xxs'>
                    Keep seeing this error?
                </Text.H4>
                <a
                    href='https://cloudsupport.squaredup.com/hc/en-us/requests/new'
                    target='_blank'
                    rel='noreferrer'
                    className={cn(buttonVariants({ variant: 'link' }))}
                >
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='mr-2' />
                    Contact Support
                </a>
            </div>
        </div>
    );
}

export default ErrorFallback;
