import { Button } from '@/components/Button';
import { Serialised } from '@squaredup/ids';
import { buttonVariants } from 'components/button';
import LoadingSpinner from 'components/LoadingSpinner';
import { ModalButtons } from 'components/Modal';
import { ResultMessage } from 'components/ui/state/ResultMessage';
import { useAppContext } from 'contexts/AppContext';
import type { Plugin, PluginDashboard, PluginFolder } from 'dynamo-wrapper';
import { flattenDashboardIdOrder } from 'queries/utils/dashboardSorted';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ReactNode } from 'react-markdown';
import { useNavigate } from 'react-router';
import { useSetFolderOpen } from 'ui/nav/components/dashboards/useFoldersOpen';
import InstallSampleDashboardTable from './InstallSampleDashboardsTable';
import { useDefaultDashboardCreation } from './useDefaultDashboardCreation';

export const ContactSupportLink = () => {
    return (
        <a
            className={buttonVariants({ variant: 'link' })}
            href='https://docs.squaredup.com/submit-ticket'
            target='_blank'
            rel='noreferrer'
        >
            Contact support
        </a>
    );
};

interface InstallSampleDashboardsFormProps {
    pluginData: Serialised<Plugin>;
    configID: string;
    displayName: string;
    onClose: () => void;
}

interface FormValues {
    selectedDashboards: Record<string, boolean>;
}

interface ButtonState {
    text?: string;
    icon?: ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    type?: 'submit';
}

export const InstallSampleDashboardsForm = ({
    pluginData,
    configID,
    displayName,
    onClose
}: InstallSampleDashboardsFormProps) => {
    const [dashboardRedirectLink, setDashboardRedirectLink] = useState<string>('');
    const navigate = useNavigate();
    const appCtx = useAppContext();
    const setFoldersOpen = useSetFolderOpen();
    const createDashboards = useDefaultDashboardCreation(false);

    const dashboards = useMemo(
        () => pluginData?.defaultContent?.dashboards || [],
        [pluginData?.defaultContent?.dashboards]
    );

    const allDashboards = useMemo(() => {
        const getAllDashboards = (
            items: (PluginDashboard | PluginFolder)[],
            parentPath: string[] = []
        ): PluginDashboard[] => {
            return items.reduce<PluginDashboard[]>((acc, item) => {
                if ('isFolder' in item) {
                    const folderPath = [...parentPath, item.name];
                    return [...acc, ...getAllDashboards(item.dashboards, folderPath)];
                }
                return [...acc, { ...item, folderPath: parentPath }];
            }, []);
        };

        return getAllDashboards(dashboards);
    }, [dashboards]);

    const initialFormState = Object.fromEntries(
        allDashboards.map((dash) => [[...(dash.folderPath || []), dash.name].join('/'), true])
    );

    const methods = useForm<FormValues>({
        defaultValues: {
            selectedDashboards: initialFormState
        }
    });

    const {
        getValues,
        handleSubmit: onSubmit,
        formState: { errors, isSubmitting, isSubmitSuccessful },
        reset
    } = methods;

    const handleSubmit = onSubmit(async (data) => {
        try {
            const selectedPaths = Object.entries(data.selectedDashboards)
                .filter(([_, selected]) => selected)
                .map(([path]) => path);

            const workspaceId = appCtx.currentWorkspaceID;
            if (!workspaceId) {
                throw new Error('WorkspaceId is missing.');
            }

            const dashboardsResponse = await createDashboards.mutateAsync({
                pluginId: pluginData.id,
                selectedSampleDashboards: selectedPaths,
                configId: configID,
                workspaceId: workspaceId
            });

            const folder = dashboardsResponse[0]?.rootFolder;
            if (dashboardsResponse.length) {
                const ids = flattenDashboardIdOrder(folder?.dashboardIdOrder || []);
                const firstDashboard =
                    ids.find(({ id }) => dashboardsResponse.some((dash) => dash.id === id)) || dashboardsResponse[0];
                setDashboardRedirectLink(`/dashboard/${firstDashboard.id}?force_open_folder=true`);
            }

            if (folder) {
                setFoldersOpen([{ itemId: folder.id, open: true }]);
            }
        } catch (error) {
            methods.setError('selectedDashboards', {
                type: 'custom',
                message: error instanceof Error ? error.message : 'Failed to install dashboards'
            });
        }
    });

    const handleSelectAll = useCallback(() => {
        const currentValues = getValues().selectedDashboards;
        const dashboardPaths = allDashboards.map((d) => [...(d.folderPath || []), d.name].join('/'));
        const allSelected = dashboardPaths.every((path) => currentValues[path]);
        const newValues = Object.fromEntries(dashboardPaths.map((path) => [path, !allSelected]));
        reset({ selectedDashboards: newValues });
    }, [getValues, allDashboards, reset]);

    const isAllSelected = useCallback(() => {
        const selectedValues = getValues().selectedDashboards;
        const dashboardPaths = allDashboards.map((d) => [...(d.folderPath || []), d.name].join('/'));
        return dashboardPaths.length > 0 && dashboardPaths.every((path) => selectedValues[path]);
    }, [allDashboards, getValues]);

    const navigateToDashboards = () => {
        navigate(dashboardRedirectLink);
    };

    const BUTTON_STATES: Record<string, ButtonState> = {
        default: {
            text: 'Install'
        },
        loading: {
            icon: <LoadingSpinner size={14} />,
            type: 'submit',
            disabled: true
        },
        success: {
            text: 'Go to dashboards',
            onClick: navigateToDashboards
        },
        error: {
            text: 'Close',
            onClick: onClose
        }
    };

    const isError = Object.keys(errors).length > 0;

    const getButtonState = () => {
        if (isSubmitting) {
            return 'loading';
        }
        if (isSubmitSuccessful) {
            return 'success';
        }
        if (isError) {
            return 'error';
        }
        return 'default';
    };

    const currentState = getButtonState();
    const { text, icon, disabled, onClick } = BUTTON_STATES[currentState];

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit}>
                <div className='px-8 pt-5'>
                    <header className='flex items-center justify-between w-full mb-3'>
                        <p>Select the sample dashboards to install:</p>
                        <Button
                            variant='link'
                            onClick={handleSelectAll}
                            type='button'
                            disabled={isSubmitSuccessful || isSubmitting || isError}
                        >
                            <div className='pr-2'>{isAllSelected() ? 'Deselect all' : 'Select all'}</div>
                        </Button>
                    </header>

                    <div className='flex flex-col items-start justify-center w-full'>
                        <InstallSampleDashboardTable
                            dashboards={allDashboards}
                            disabled={isSubmitting || isSubmitSuccessful || isError}
                        />
                        <aside className='px-2 py-4 text-textSecondary'>
                            The selected sample dashboards will be installed in a new folder called "
                            <span className='font-bold'>{displayName}</span>" in the current workspace.
                        </aside>
                    </div>

                    {isSubmitSuccessful && (
                        <ResultMessage
                            state='success'
                            title='Successfully installed the selected sample dashboard(s)'
                        />
                    )}

                    {isError && (
                        <ResultMessage
                            title='The selected sample dashboard(s) failed to install.'
                            body={<ContactSupportLink />}
                            state='error'
                            wrapperClassName='flex-row justify-start items-start gap-x-2'
                        />
                    )}
                </div>

                {/* Footer buttons */}
                <ModalButtons>
                    {!isError && (
                        <Button variant='tertiary' onClick={onClose} disabled={isSubmitting}>
                            {isSubmitSuccessful ? 'Close' : 'Cancel'}
                        </Button>
                    )}
                    <Button
                        variant='primary'
                        onClick={onClick}
                        disabled={!Object.values(methods.watch('selectedDashboards')).some(Boolean) || disabled}
                        {...(currentState === 'default' || currentState === 'loading'
                            ? { 'data-testid': 'installSampleDashboardsSubmitButton' }
                            : {})}
                    >
                        <div className='flex items-center gap-x-1'>
                            {icon}
                            <span>{text}</span>
                        </div>
                    </Button>
                </ModalButtons>
            </form>
        </FormProvider>
    );
};
