import { Serialised } from '@squaredup/ids';
import type { AgentDownload, ProjectedAgent } from 'dynamo-wrapper';
import API from './API';
import { handleError, handleResponse } from './util';

/**
 * Key for react-query cache
 */
export const AGENTS = 'agents';
export const LATEST_AGENT = 'latest_agent';

/**
 * List all agents
 */
export const List = async () => {
    return API.get<Serialised<ProjectedAgent>[]>('/agents').then(handleResponse).catch(handleError);
};

/**
 * Get the latest version for download
 */
export const GetLatestAgentDownload = async (): Promise<AgentDownload | undefined> => {
    return API.get('/agents/latest')
        .then(handleResponse)
        .catch((error) => {
            // Rather than display and log errors for this state, treat
            // it as agent downloads being intentionally unavailable at this time.
            if (error.response && error.response.status === 404) {
                return undefined;
            } else {
                handleError(error);
            }
        });
};

/**
 * Create a new agent
 * @param name
 * @param description
 * @param rawId
 * @param apiKeyId
 */
export const Create = async (
    displayName: string, description: string, rawId?: string, apiKeyId?: string, agentGroups?: string[]
) => {
    return API.post('agents', {
        displayName,
        description,
        rawId,
        apiKeyId,
        agentGroups
    })
        .then(handleResponse)
        .catch(handleError);
};

/**
 * Get an Agent
 * @param id
 */
export const GetAgent = async (id: string) => {
    return API.get(`/agents/${id}`).then(handleResponse).catch(handleError);
};

/**
 * Adds an agent to agent groups
 * @param id
 * @param groups
 */
export const AddToGroups = async (id: string, groups: string[]) => {
    return API.post(`/agents/${id}/addtogroups`, groups).then(handleResponse).catch(handleError);
};

/**
 * Removes an agent to agent groups
 * @param id
 * @param groups
 */
export const RemoveFromGroups = async (id: string, groups: string[]) => {
    return API.post(`/agents/${id}/removefromgroups`, groups).then(handleResponse).catch(handleError);
};

/**
 * Updates an agent
 * @param displayName
 * @param description
 * @param rawId
 * @param apiKeyId
 */
export const Update = async (
    displayName: string, description: string, id: string, apiKeyId?: string, meta?: object
) => {
    return API.put(`agents/${id}`, {
        displayName,
        description,
        apiKeyId,
        meta
    })
        .then(handleResponse)
        .catch(handleError);
};

/**
 * Deletes an agent
 * @param id
 */
export const Delete = async (id: string) => {
    return API.delete(`/agents/${id}`).then(handleResponse).catch(handleError);
};
