import { Button, buttonVariants } from '@/components/Button';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faDocker, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { faDownload, faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal, { ModalButtons, ifNotOutside } from 'components/Modal';
import type { AgentDownload } from 'dynamo-wrapper';
import { FC, ReactNode } from 'react';

type AgentDownloadPlatform = {
    enabled: boolean;
    platform: string;
    isExternal: boolean;
    displayName: string;
    key: string;
    icon: ReactNode;
    url: string;
};

type AgentDownloadChoiceProps = {
    agentPlatform: string | undefined;
    latestAgent?: AgentDownload;
};

type AgentDownloadsModalProps = AgentDownloadChoiceProps & {
    onClose: () => void;
};

const externalLinkProps = {
    target: '_blank',
    rel: 'noopener noreferrer'
};

export const AgentDownloadChoice: FC<AgentDownloadChoiceProps> = ({ agentPlatform, latestAgent }) => {
    const versionString = latestAgent?.version ? ` (v${latestAgent.version})` : undefined;
    const platforms: AgentDownloadPlatform[] = [
        {
            platform: 'windows',
            displayName: `Download Windows agent ${versionString ?? ''}`,
            icon: <FontAwesomeIcon icon={faMicrosoft} className='w-5 h-5 text-[--windowsLogo]' fixedWidth />,
            isExternal: false,
            key: 'windows',
            url: latestAgent?.url ?? ''
        },
        {
            platform: 'linux',
            displayName: 'Pull image from Docker Hub',
            icon: <FontAwesomeIcon icon={faDocker} className='w-5 h-5 text-[--dockerLogo]' fixedWidth />,
            isExternal: true,
            key: 'dockerLinux',
            url: 'https://hub.docker.com/r/squaredup/relay-agent'
        }
    ].map((download) => ({
        ...download,
        enabled:
            agentPlatform === undefined ||
            agentPlatform.localeCompare(download.platform, undefined, { sensitivity: 'base' }) === 0
    }));

    return (
        <>
            <Text.Body>Select an installation option:</Text.Body>
            <div className='flex flex-col justify-between gap-2 mt-3 mb-4'>
                {platforms.map((platform) => (
                    <a
                        key={platform.key}
                        download
                        href={platform.enabled ? platform.url : undefined}
                        className={cn(
                            buttonVariants({ variant: 'choice' }),
                            'focus:ring-1', // override button style for clicked hyperlinks
                            !platform.enabled && 'text-textDisabled pointer-events-none'
                        )}
                        data-testid={`link-${platform.key}`}
                        {...(platform.isExternal ? externalLinkProps : {})}
                    >
                        <span className='inline-flex items-center mr-3'>{platform.icon}</span>
                        <span className='font-normal'>{platform.displayName}</span>
                        <FontAwesomeIcon
                            icon={platform.isExternal ? faExternalLink : faDownload}
                            fixedWidth
                            className='ml-auto '
                        />
                    </a>
                ))}
            </div>
            <div className='flex flex-row'>
                <a
                    href={'https://squaredup.com/cloud/relay'}
                    target='_blank'
                    className={buttonVariants({ variant: 'link' })}
                    rel='noopener noreferrer'
                >
                    Installation Instructions
                </a>
                <Text.Body className='ml-auto'>
                    Can't see your platform?{' '}
                    <a
                        className={buttonVariants({ variant: 'link' })}
                        href='https://docs.squaredup.com/submit-ticket'
                        {...externalLinkProps}
                    >
                        Contact us
                    </a>
                </Text.Body>
            </div>
        </>
    );
};

export const AgentDownloadsModal: FC<AgentDownloadsModalProps> = ({ onClose, ...rest }) => {
    return (
        <Modal title='Agent installation' close={ifNotOutside(onClose)} fullWidth maxWidth='max-w-3xl'>
            <hr className='border-dividerPrimary' />
            <div className='flex flex-col overflow-hidden'>
                <div className='px-8 pt-5'>
                    <AgentDownloadChoice {...rest} />
                </div>
                <ModalButtons>
                    <Button type='button' onClick={() => onClose()} data-testid='downloadModal-done'>
                        Done
                    </Button>
                </ModalButtons>
            </div>
        </Modal>
    );
};
