import { Button } from '@/components/Button';
import { Skeleton } from '@/components/Skeleton';
import Template from '@/components/Template';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'components/LoadingSpinner';
import { useModal } from 'components/Modal';
import { Presence } from 'components/Presence';
import { WorkspaceHomeStateIndicator } from 'components/ui/state/WorkspaceHomeStateIndicator';
import { EditWorkspaceModal, startingTabSchema } from 'components/workspaceModals/EditWorkspaceModal';
import { AppContext } from 'contexts/AppContext';
import { usePageTitle } from 'lib/usePageTitle';
import { useDashboardsForWorkspace } from 'queries/hooks/useDashboardsForWorkspace';
import { useDatasourceConfigsForWorkspace } from 'queries/hooks/useDatasourceConfigsForWorkspace';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { ComponentPropsWithoutRef, PropsWithChildren, Suspense, useContext, useLayoutEffect } from 'react';
import { Link, useMatch, useSearchParams } from 'react-router-dom';
import { useWorkspaceCanWrite } from 'services/AccessControlService';
import { WorkspaceDataSourcesOverviewPanel } from './WorkspaceDataSourcesOverviewPanel';
import { NoDashboardsInWorkspaceMessage, NoDataSourcesAndDashboardsInWorkspace } from './WorkspaceEmptyMessages';
import { WorkspaceHealthOverviewPanel } from './WorkspaceHealthOverviewPanel';
import { WorkspaceKpiOverviewPanel } from './WorkspaceKpiOverviewPanel';
import { WorkspaceMapOverviewPanel } from './WorkspaceMapOverviewPanel';

export const WorkspaceHome = () => {
    useSetGlobalWorkspaceId();

    return (
        <TemplateWrapper>
            <EmptyTilesGuard>
                <GridLayout>
                    <WorkspaceHealthOverviewPanel />
                    <WorkspaceDataSourcesOverviewPanel />
                    <WorkspaceMapOverviewPanel />
                    <WorkspaceKpiOverviewPanel />
                </GridLayout>
            </EmptyTilesGuard>
        </TemplateWrapper>
    );
};

const TemplateWrapper: React.FC = ({ children }) => {
    const workspaceId = useWorkspaceId();

    const { data: workspace, isLoading } = useWorkspace(workspaceId);

    usePageTitle(workspace?.displayName);

    if (isLoading) {
        return <LoadingSpinner className='mt-8' />;
    }

    if (!workspace) {
        return <WorkspaceNotAccessible />;
    }

    return (
        <Template
            title={workspace.displayName}
            description={workspace.data?.properties?.description}
            type={workspace.data?.properties?.type}
            tags={workspace.data?.properties?.tags}
            actions={<EditWorkspaceHomeModal />}
            flex
            equalPadding
            truncateDescription
            icon={<WorkspaceHomeStateIndicator />}
        >
            <Suspense fallback={<Skeleton className='grow mb-4' />}>{children}</Suspense>
        </Template>
    );
};

const EditWorkspaceHomeModal = () => {
    const workspaceId = useWorkspaceId();
    const workspaceCanWrite = useWorkspaceCanWrite(workspaceId);
    const workspace = useWorkspace(workspaceId);
    const modal = useModal();
    const [searchParams, setSearchParams] = useSearchParams();

    useLayoutEffect(() => {
        if (searchParams.get('workspace-settings-tab')) {
            modal.open();
        }
    }, [modal, searchParams]);

    const allowedToWrite = workspaceCanWrite.data;

    if (!allowedToWrite || !workspace.data) {
        return null;
    }

    return (
        <>
            <Button
                icon={<FontAwesomeIcon icon={faCog} />}
                onClick={modal.open}
                variant='secondary'
                className='whitespace-nowrap'
                data-testid='workspacehome-settings'
            >
                Workspace settings
            </Button>

            <Presence isPresent={modal.isOpen}>
                <EditWorkspaceModal
                    close={() => {
                        searchParams.delete('workspace-settings-tab');
                        setSearchParams(searchParams);

                        modal.close();
                    }}
                    workspace={workspace.data}
                    startingTab={startingTabSchema.parse(searchParams.get('workspace-settings-tab'))}
                />
            </Presence>
        </>
    );
};

const WorkspaceNotAccessible = () => {
    return (
        <main className='pt-48 text-center'>
            <Text.H1 className='mb-1 text-2xl font-bold'>Workspace not accessible</Text.H1>
            <Text.Body>The workspace does not exist or you do not have permission to access it.</Text.Body>
            <Text.Body>
                Please select a different workspace or go to the&nbsp;
                <Link to='/status' className='cursor-pointer text-textLink hover:underline'>
                    Organization home
                </Link>
                .
            </Text.Body>
        </main>
    );
};

const EmptyTilesGuard = (props: PropsWithChildren<object>) => {
    const currentWorkspaceID = useWorkspaceId();

    const dataSourcesInWorkspace = useDatasourceConfigsForWorkspace(currentWorkspaceID, {
        suspense: true
    });
    const noDatasourcesInWorkspace = dataSourcesInWorkspace.data?.length === 0;

    const dashboardsInWorkspace = useDashboardsForWorkspace(currentWorkspaceID, {
        suspense: true
    });
    const noDashboardsInWorkspace = dashboardsInWorkspace?.data?.length === 0;

    if (noDatasourcesInWorkspace && noDashboardsInWorkspace) {
        return (
            <Panel className='items-center justify-center mb-4 gap-7'>
                <NoDataSourcesAndDashboardsInWorkspace />
            </Panel>
        );
    }

    if (noDashboardsInWorkspace && !noDatasourcesInWorkspace) {
        return (
            <Panel className='items-center justify-center mb-4 gap-7'>
                <NoDashboardsInWorkspaceMessage />
            </Panel>
        );
    }

    return <>{props.children}</>;
};

const GridLayout = (props: ComponentPropsWithoutRef<'main'>) => {
    return <main {...props} className='h-full grid grid-cols-2 grid-rows-[40%_1fr_40%] gap-2.5 overflow-hidden pb-4' />;
};

export const Panel = ({ className, ...props }: ComponentPropsWithoutRef<'section'>) => {
    return (
        <section
            {...props}
            className={cn(
                'bg-tileBackground h-full rounded-lg flex overflow-x-auto overflow-y-hidden flex-col border-[16px] border-tileBackground scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary',
                className
            )}
        />
    );
};

export const useWorkspaceId = () => {
    const match = useMatch('/workspace/:id/overview');
    const match2 = useMatch('/workspace/:id');

    const workspaceId = match?.params.id || match2?.params.id;

    if (!workspaceId) {
        throw new Error(`invalid workspace id: ${workspaceId} in /workspace/:id`);
    }

    return workspaceId;
};

const useSetGlobalWorkspaceId = () => {
    const workspaceId = useWorkspaceId();
    const { setCurrentWorkspaceID } = useContext(AppContext);

    setCurrentWorkspaceID({ id: workspaceId });
};
