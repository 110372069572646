export type FilterQueryParameters = {
    booleanQuery?: string;
    properties?: Record<string, any>;
    types?: {
        value: string;
    }[];
    plugins?: {
        value: string;
    }[];
};

export const getObjectFilters = (
    filterSources: string[],
    filterTypes: string[],
    filterProperties: Record<string, any>,
    filterQuery: string
): FilterQueryParameters => {
    // We only want to pass filter properties if there is at least one value present
    const filterPropertiesWithValues = Object.entries(filterProperties || {}).reduce(
        (properties, [property, values]) => {
            if (values.length) {
                properties[property] = values;
            }
            return properties;
        },
        {} as typeof filterProperties
    );

    return {
        ...(filterSources.length && {
            plugins: filterSources.map((source) => ({ value: source }))
        }),
        ...(filterTypes.length && {
            types: filterTypes.map((type) => ({ value: type }))
        }),
        ...(Object.keys(filterPropertiesWithValues).length && {
            properties: filterPropertiesWithValues
        }),
        ...(filterQuery && { booleanQuery: filterQuery })
    };
};
