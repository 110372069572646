import { isRouteAllowedForOpenAccess } from '@squaredup/open-access';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { isOpenAccess } from 'lib/openAccessUtil';
import Auth from 'services/Auth';
import Config from '../config';

const axiosObj = axios.create({
    baseURL: `${Config.Endpoint}/`
});

axiosObj.interceptors.request.use(function (config) {
    const token = Auth?.user?.getToken();
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }
    const tenantId = Auth?.user?.tenant;
    if (tenantId) {
        config.headers['squp-tenant'] = tenantId;
    }

    // Do not remove, this is needed for proper app operation.
    config.headers['x_squp_1'] = Config.x_squp_1;

    // Check the current location, if open access restrict requests based on their routes
    const { method, url } = config;
    const [urlPath] = url.split('?');
    
    if (isOpenAccess() && !isRouteAllowedForOpenAccess(method, urlPath)) {
        throw new axios.Cancel(`This request is not allowed in open access (${method} ${urlPath})`);
    }

    return config;
});

// Auto-retry for 401 errors to work around API requests failing with 
// "OIDC discovery endpoint communication error" from API Gateway.
// (Can also present with a "JWKS communication error")
//
// See https://community.auth0.com/t/intermittent-oidc-discovery-endpoint-communication-error-when-using-jwt-authorizer-with-aws-http-api/89695
//
const configureAxiosRetry = (_axiosObj) => axiosRetry(_axiosObj, {
    retries: 3,
    retryDelay: (count) => {
        return count * 250;
    },
    retryCondition: (error) => error?.response?.status === 401
});

// For use with unauthenticated endpoints on the API.
export const axiosNoAuth = axios.create({
    baseURL: `${Config.Endpoint}/`,
    // eslint-disable-next-line camelcase
    headers: { x_squp_1: Config.x_squp_1 }
});

configureAxiosRetry(axiosNoAuth);
configureAxiosRetry(axiosObj);

// Setup an Axios service with the configured endpoint
export default axiosObj;
