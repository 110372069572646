import Text from '@/components/Text';
import LoadingSpinner from 'components/LoadingSpinner';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { FC, ReactNode } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useEnsureCorrectCurrentWorkspaceId } from 'ui/hooks/useEnsureCorrectCurrentWorkspaceId';

interface WorkspaceRequiredProps {
    children?: ReactNode;
}

export interface InjectedWorkspaceProps {
    workspaceID: string;
}

export const WorkspaceNotFound = () => (
    <div className='flex flex-col items-center h-screen mt-48'>
        <div className='mx-auto text-center'>
            <Text.H1 className='mb-1 text-2xl font-bold'>Workspace not accessible</Text.H1>
            <Text.Body>The workspace does not exist or you do not have permission to access it.</Text.Body>
            <Text.Body>
                Please select a different workspace or go to the&nbsp;
                <Link to='/status' className='cursor-pointer text-textLink hover:underline'>
                    Organization home
                </Link>
                .
            </Text.Body>
        </div>
    </div>
);

const WorkspaceRequired: FC<WorkspaceRequiredProps> = ({ children }) => {
    const { id } = useParams();
    useEnsureCorrectCurrentWorkspaceId(id);
    
    return id ? <>{children}</> : <WorkspaceNotFound />;
};

export function withWorkspaceID<P extends InjectedWorkspaceProps = InjectedWorkspaceProps>(
    Component: React.ComponentType<P>
) {
    const WithID = (props: Omit<P, keyof InjectedWorkspaceProps>) => {
        const queryClient = useQueryClient();

        const { id } = useParams();
        const { data: workspace, isLoading } = useWorkspace(id);

        useEnsureCorrectCurrentWorkspaceId(id);

        if (isLoading) {
            return (
                <div className='pt-[20px]'>
                    <LoadingSpinner />
                </div>
            );
        }

        if (workspace) {
            return <Component {...(props as P)} workspaceID={id} />;
        }

        // We know if we've fetched, or failed to get data, for a specific workspace that the workspaces data has been 
        // populated, therefore we can use that data to check whether there are any other workspaces available
        if (queryClient.getQueriesData(workspaceQueryKeys.list).length === 0) {
            return (
                <div className='flex flex-col items-center h-screen mt-48'>
                    <div className='mx-auto text-center'>
                        <h1 className='mb-1 text-2xl font-bold'>No workspaces</h1>
                        <p>Create a workspace to get started.</p>
                    </div>
                </div>
            );
        }

        return <WorkspaceNotFound />;
    };

    WithID.displayName = `withWorkspaceID${Component.displayName}`;

    return WithID;
}
export default WorkspaceRequired;
