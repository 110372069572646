import { cn } from '@/lib/cn';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { forwardRef } from 'react';

export const EyeCheckbox = forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, disabled, ...props }, ref) => (
    <CheckboxPrimitive.Root
        ref={ref}
        role='checkbox'
        className={cn(
            'relative shrink-0 h-checkbox w-checkbox overflow-hidden align-middle text-componentActive rounded-checkbox focus:ring-0 focus:outline-none focus-visible:outline-none focus-visible:ring-0',
            className
        )}
        disabled={disabled}
        {...props}
        id={props.id ?? props.name}
    >
        <span className={cn('flex items-center justify-center text-current align-middle h-full')}>
            <FontAwesomeIcon
                className={cn(disabled ? 'text-textDisabled' : 'text-textPrimary')}
                icon={props.checked ? faEye : faEyeSlash}
            />
        </span>
    </CheckboxPrimitive.Root>
));
