import { FormattedStreamData } from '@squaredup/data-streams';
import { VisualisationOption, VisualisationOptionAction } from 'dashboard-engine/types/Visualisation';
import { BarChartConfigurationForm } from './BarChartConfigurationForm';
import { BarDataMappingForm } from './BarChartDataMappingForm';
import { DataStreamBarChartConfig, LegacyDataStreamBarChartConfig } from './Config';
import { matchesData } from './dataUtils';
import { performLegacyBarChartColumnMigration } from './performLegacyBarChartColumnMigration';

/**
 * An object that maps the passed data to the bar chart whilst providing configuration options
 */
export const DataStreamBarChartOptions: VisualisationOption<DataStreamBarChartConfig> = {
    initialPanels: (config?: DataStreamBarChartConfig) => {
        const panels = [{ name: 'mapping', isOpen: true }];

        if (
            config?.grouping || 
            (config?.displayMode && config.displayMode !== 'actual') || 
            config?.horizontalLayout === 'horizontal'
        ) {
            panels.push({ name: 'type', isOpen: true });
        }

        if (config?.xAxisLabel && config.xAxisLabel !== 'auto') {
            panels.push({ name: 'xAxis', isOpen: true });
        }

        if (
            (config?.yAxisLabel && config.yAxisLabel !== 'auto') || 
            (config?.range?.type && config.range.type !== 'auto')
        ) {
            panels.push({ name: 'yAxis', isOpen: true });
        }

        if (config?.showLegend) {
            panels.push({ name: 'legend', isOpen: true });
        }

        if (config?.showGrid === false || config?.showValue === true) {
            panels.push({ name: 'options', isOpen: true });
        }

        return panels;
    },
    // Maps data to the visualization
    dataMappingComponent: ({ columns, config: rawConfig, onChange, tileData }) => {
        // Perform migration from legacy bar chart configuration
        const config = performAndPersistLegacyMigration(
            rawConfig as LegacyDataStreamBarChartConfig,
            tileData,
            onChange
        );

        return <BarDataMappingForm columns={columns} config={config} onChange={onChange} />;
    },
    // Configuration for the visualization
    configurationComponent: ({ config: rawConfig, accordionControls, columns, tileData, onChange }) => {
        const formSubmit = (data: any) => {
            onChange({ action: 'form-change', data });
        };

        // Perform migration from legacy bar chart configuration
        const config = performAndPersistLegacyMigration(
            rawConfig as LegacyDataStreamBarChartConfig,
            tileData,
            onChange
        );

        return (
            <BarChartConfigurationForm 
                config={config} 
                onChange={formSubmit} 
                columns={columns} 
                accordionControls={accordionControls}
            />
        );
    },
    // Triggers to update the configuration/visualization
    handlers: {
        'form-change': (config, data) => ({ ...config, ...data }),
        'xAxisData-select': (config, data) => ({ ...config, xAxisData: data }),
        'xAxisGroup-select': (config, data) => ({ ...config, xAxisGroup: data }),
        'yAxisData-select': (config, data) => ({ ...config, yAxisData: data }),
        cleanupLegacyProps: (config, data) => {
            // Remove legacy prop prior to saving
            const updated = { ...config, ...data };
            delete updated.requiresLegacyColumnMigration;
            return updated;
        }
    },
    validate: (_, config) => config,
    matchesData
};

/**
 * Perform and immediately save the legacy migration of columns for the (old) bar chart
 * @param config Current config
 * @param tileData Data
 * @param onChange Save handler
 * @returns Updated config
 */
const performAndPersistLegacyMigration = (
    config: LegacyDataStreamBarChartConfig,
    tileData: FormattedStreamData,
    onChange: (action: VisualisationOptionAction) => void
): DataStreamBarChartConfig => {
    const updated = performLegacyBarChartColumnMigration(config as LegacyDataStreamBarChartConfig, tileData);
    if (config.requiresLegacyColumnMigration === false) {
        onChange({ action: 'cleanupLegacyProps', data: updated });
    }
    return updated;
};
