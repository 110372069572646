import { tenantQueryKeys } from 'queries/queryKeys/tenantKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { Tenant } from 'services/TenantService';

type QueryReturnType = Awaited<ReturnType<typeof Tenant>>;

/**
 * useQuery returning the current Tenant
 * @param options The react-query options e.g. refreshInterval
 * @returns The current tenant
 */
export const useTenant = <T = QueryReturnType>(options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>) => 
    useQuery(
        tenantQueryKeys.tenant, 
        Tenant,
        {
            staleTime: Number.POSITIVE_INFINITY,
            cacheTime: Number.POSITIVE_INFINITY,
            ...options
        });
