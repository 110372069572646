import { cn } from '@/lib/cn';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { forwardRef } from 'react';

export const Checkbox = forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
    <CheckboxPrimitive.Root
        ref={ref}
        role='checkbox'
        className={cn(
            'relative shrink-0 h-checkbox w-checkbox overflow-hidden align-middle text-componentActive rounded-checkbox border border-outlineSecondary hover:border-outlineSecondaryHover focus:ring-0 focus:outline-none focus-visible:outline-none focus-visible:ring-0 disabled:border-outlinePrimary data-[state=checked]:bg-primaryButtonBackground data-[state=checked]:border-primaryButtonBackground data-[state=checked]:hover:bg-primaryButtonBackgroundHover data-[state=checked]:hover:border-primaryButtonBackgroundHover data-[state=checked]:focus-visible:border-primaryButtonBackgroundHover data-[state=checked]:focus-visible:bg-primaryButtonBackgroundHover',
            className
        )}
        {...props}
        id={props.id ?? props.name}
    >
        <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current align-middle h-full')}>
            <FontAwesomeIcon icon={faCheck} size='xs' />
        </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
));
