import {
    FormattedStreamData,
    FoundColumn,
    StreamDataColumn,
    findColumn,
    preferred,
    required,
    url
} from '@squaredup/data-streams';
import { dataMatchCriteria } from 'dashboard-engine/dataStreams/dataMatchCriteria';
import { DataStreamIframeConfig } from './Config';

export const findIframeLinkColumn = (columns: StreamDataColumn[]) =>
    findColumn(columns, required('shapeName', url.name), preferred('role', 'link'));

export const getIframeColumns = (columns: StreamDataColumn[], config?: DataStreamIframeConfig) => {
    const iframeCritera = dataMatchCriteria<{
        linkColumn: FoundColumn;
    }>();

    let linkColumn = findIframeLinkColumn(columns);

    if (config?.linkColumn) {
        const column = findColumn(columns, required('name', config.linkColumn));
        if (column.succeeded) {
            linkColumn = column;
        }
    }

    if (linkColumn.failed) {
        iframeCritera.fail('Missing URL column', linkColumn.reason);
    } else {
        iframeCritera.pass('Automatically selected URL', {
            linkColumn: linkColumn.value
        });
    }

    return iframeCritera;
};

export const matchesData = (data?: FormattedStreamData, config?: DataStreamIframeConfig) => {
    const columns = data?.metadata?.columns ?? [];
    const rows = data?.rows ?? [];

    const iframeCritera = getIframeColumns(columns, config);

    if (rows.length === 0) {
        iframeCritera.fail('Exactly 1 row of data is required, 0 detected');
    } else if (rows.length > 1) {
        iframeCritera.fail('Exactly 1 row of data is required, multiple detected');
    }

    return iframeCritera;
};
