import { isOpenAccess } from 'lib/openAccessUtil';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ThemeOptions } from './useTheme';

export const useForcedTheme = () => {
    const [params] = useSearchParams();

    const { pathname } = useLocation();

    const URLTheme = params.get('theme') || '';
    const forcedTheme = ['dark', 'light'].includes(URLTheme) ? params.get('theme') : undefined;

    // For OA we want to force dark theme unless a theme query param overrides it
    if (isOpenAccess() && pathname.length > 22) {
        return forcedTheme || ThemeOptions.dark;
    }

    return forcedTheme;
};
