import { Serialised } from '@squaredup/ids';
import type { ProjectedChannel } from 'dynamo-wrapper';
import { ChannelType } from 'services/NotificationChannelService';
import { AddEditNotificationRulePanelProps } from '../AddEditNotificationRuleModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusLarge, faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { useMemo, useState } from 'react';
import { NotificationDestinationIcon } from '../NotificationDestinationIcon';
import Input from 'components/forms/input/Input';
import Form from 'components/forms/form/Form';
import { FullWidthModalButton } from 'components/Modal';
import { ModalOptionsWithOriginal } from 'components/ModalOptions';

export interface NotificationRuleDestinationsProps extends AddEditNotificationRulePanelProps {
    selectedChannelType?: Serialised<ChannelType>;
}

const ChannelsModalOptions = ModalOptionsWithOriginal<Serialised<ProjectedChannel>>();

export const NotificationRuleDestinations: React.FC<NotificationRuleDestinationsProps> = ({
    rule,
    channels,
    setActivePanel,
    setRule,
    selectedChannelType
}) => {
    const [search, setSearch] = useState<string | undefined>(undefined);

    const filterSearch = (displayName?: string, term?: string) =>
        !term ? true : `${displayName}`.toLocaleLowerCase().includes(term.toLocaleLowerCase());

    const typeChannels = useMemo(
        () => channels.filter((c) => c.channelTypeId === selectedChannelType?.id),
        [channels, selectedChannelType]
    );

    const matchingChannels = useMemo(
        () =>
            typeChannels
                .filter((c) => filterSearch(c.displayName, search))
                .sort((a, b) =>
                    `${a.displayName}`.localeCompare(`${b.displayName}`, undefined, { sensitivity: 'base' })
                ),
        [typeChannels, search]
    );

    if (!selectedChannelType) {
        return <></>;
    }

    return (
        <div className='px-6 pb-8' data-testid='destinations-panel'>
            <div>
                <FullWidthModalButton
                    onClick={() => setActivePanel('addEditForm')}
                    icon={<FontAwesomeIcon icon={faPlusLarge} fixedWidth />}
                    title='Add destination'
                >
                    Add destination
                </FullWidthModalButton>
            </div>
            <hr className='my-6 border-dividerPrimary' />
            {typeChannels.length > 0 ? (
                <>
                    <div className='mb-2'>
                        <Form>
                            <Input
                                onChange={(e: { target: { value: string } }) => setSearch(e.target.value)}
                                value={search}
                                name='destinationSearch'
                                placeholder={'Search for destination...'}
                                prepend={<FontAwesomeIcon icon={faMagnifyingGlass} fixedWidth className='h-4' />}
                            />
                        </Form>
                    </div>
                    <div className='flex flex-col justify-between gap-2 max-h-48 table-scroll-overflow'>
                        {matchingChannels.length > 0 ? (
                            <ChannelsModalOptions
                                objects={matchingChannels}
                                onClick={(c) => {
                                    setRule({ ...rule, channels: [{ id: c.id }] });
                                    setActivePanel('summary');
                                }}
                                optionMapper={(c) => ({
                                    key: c.id,
                                    label: c.displayName ?? c.name,
                                    icon: (
                                        <NotificationDestinationIcon
                                            channelTypeId={c.channelTypeId}
                                            className='w-5 h-5'
                                        />
                                    )
                                })}
                                includeRightArrow={true}
                            />
                        ) : (
                            <p className='text-textIncomplete'>No search results were found.</p>
                        )}
                    </div>
                </>
            ) : (
                <p className='text-textIncomplete'>There are no destinations. Add a destination using the button above.</p>
            )}
        </div>
    );
};
