import { ReactNode } from 'react';

function NoDataPlaceholder({ message = 'No data to show.', icon }: { message?: string; icon?: ReactNode }) {
    return (
        <div className='flex items-center justify-center w-full h-full text-center text-textIncomplete'>
            {icon}
            <p className='text-2xl'>{message}</p>
        </div>
    );
}

export default NoDataPlaceholder;
