import Logo from 'images/squaredup-logo-full.svg?react';
import { oaLog } from 'lib/openAccessUtil';
import { useEffect } from 'react';

export function OpenAccessLoadError({ err }: { err: any }) {
    useEffect(() => {
        oaLog('Failed to load Open Access dashboard', err);

        const timeout = setTimeout(() => {
            oaLog('Reloading app to see if share has become accessible.');
            window.location.reload();
        }, 60000);
        
        return () => clearTimeout(timeout);
    }, [err]);

    return (
        <div className='flex flex-col items-center justify-center h-screen min-h-screen text-textPrimary bg-backgroundPrimary'>
            <a href='http://squaredup.com' className='pb-6 scale-150'>
                <Logo />
            </a>
            <h1 className='pb-16 text-2xl font-bold text-textPrimary'>Sorry, this dashboard is not available.</h1>
        </div>
    );
}
