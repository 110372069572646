import type { DashboardTileContent } from '@squaredup/dashboards';
import type { OOBInfo } from 'dynamo-wrapper';
import trackEvent from 'lib/analytics';
import { mean } from 'lodash';
import React, { createContext, useCallback, useContext, useRef } from 'react';

type MetricsTileState = Record<
    string,
    {
        startTime?: number;
        endTime?: number;
        success?: boolean;
    }
>;

type DashboardMetricsValue = {
    reportTileStart: (tileId: string, startTime: number) => void;
    reportTileEnd: (tileId: string, endTime: number, success: boolean) => void;
};

const DashboardMetricsContext = createContext<DashboardMetricsValue>({
    reportTileStart: () => undefined,
    reportTileEnd: () => undefined
});

export const useDashboardMetricsContext = () => useContext(DashboardMetricsContext);

interface DashboardMetricsProviderProps {
    dashboardContents: DashboardTileContent[];
    oobInfo?: OOBInfo;
}

export const DashboardMetricsProvider: React.FC<DashboardMetricsProviderProps> = ({
    dashboardContents,
    oobInfo,
    children
}) => {
    const hasSentEvent = useRef(false);
    const allTilesHaveReported = useRef(false);
    const tilesRef = useRef<MetricsTileState>({});

    const dataStreamTileIdsString = dashboardContents
        .filter((c) => c.config._type === 'tile/data-stream')
        .map((c) => c.i)
        .sort()
        .join();
    
    const reportTileStart = useCallback((tileId: string, startTime: number) => {
        const currentTiles = tilesRef.current;

        if (currentTiles[tileId]?.startTime != null) {
            return currentTiles;
        }
            
        tilesRef.current = {
            ...currentTiles,
            [tileId]: {
                ...(currentTiles[tileId] ?? {}),
                startTime
            }
        };
    }, []);

    const reportTileEnd = useCallback((tileId: string, endTime: number, success: boolean) => {
        const currentTiles = tilesRef.current;

        if (currentTiles[tileId]?.endTime != null) {
            return;
        }
            
        tilesRef.current = {
            ...currentTiles,
            [tileId]: {
                ...(currentTiles[tileId] ?? {}),
                endTime,
                success
            }
        };

        const tiles = tilesRef.current;

        const dataStreamTileIds = dataStreamTileIdsString.split(',');
        const haveAllTilesReported =
            dataStreamTileIds.length > 0 &&
            dataStreamTileIds.every((id) => tiles[id]?.startTime != null && tiles[id]?.endTime != null);

        if (haveAllTilesReported) {
            allTilesHaveReported.current = true;
        }

        if (haveAllTilesReported && !hasSentEvent.current) {
            const tilesWithSuccess = dataStreamTileIds.filter((id) => tiles[id]?.success);
            const tilesWithError = dataStreamTileIds.filter((id) => !tiles[id]?.success);
            const loadTimes = dataStreamTileIds.map((id) => (tiles[id]?.endTime ?? 0) - (tiles[id]?.startTime ?? 0));
            const averageLoadTime = Math.round(mean(loadTimes));
            const minLoadTime = Math.round(Math.min(...loadTimes));
            const maxLoadTime = Math.round(Math.max(...loadTimes));

            // Pendo property values must be strings or booleans
            trackEvent('Dashboard Loaded', {
                success: String(tilesWithSuccess.length),
                error: String(tilesWithError.length),
                averageLoadTime: String(averageLoadTime),
                minLoadTime: String(minLoadTime),
                maxLoadTime: String(maxLoadTime),
                oobName: oobInfo?.dashboardName,
                oobPluginId: oobInfo?.pluginId,
                oobPluginVersion: oobInfo?.pluginVersion
            });
            hasSentEvent.current = true;
        }
    }, [dataStreamTileIdsString, oobInfo?.dashboardName, oobInfo?.pluginId, oobInfo?.pluginVersion]);

    return (
        <DashboardMetricsContext.Provider value={{ reportTileStart, reportTileEnd }}>
            {children}
        </DashboardMetricsContext.Provider>
    );
};
