import { TooltipButton } from '@/components/Button';
import { cn } from '@/lib/cn';
import {
    faCircleNotch,
    faMagnifyingGlassChart,
    faPencil,
    faRefresh,
    faUpRightAndDownLeftFromCenter
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { useWorkspacePermissions } from 'components/hooks/useWorkspacePermissions';
import { useAppContext } from 'contexts/AppContext';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { useFullscreenContext } from 'contexts/FullscreenContext';
import { useTileContext } from 'contexts/TileContext';
import { TileTypes } from 'dashboard-engine/constants';
import { useDataStreamFromTileConfig } from 'dashboard-engine/hooks/useDataStreamFromTileConfig';
import { useForceRefresh } from 'dashboard-engine/hooks/useForceRefresh';
import { useNavigateToExplore } from 'dashboard-engine/hooks/useNavigateToExplore';
import { isOpenAccess } from 'lib/openAccessUtil';
import { useLocation } from 'react-router';
import { TileDropdownMenuActions } from './actions/TileDropdownMenuActions';
import { useLastRefreshed } from './hooks/useLastRefreshed';

export const TileHeaderActions: React.FC = () => {
    const { pathname } = useLocation();
    const { currentWorkspaceID, searchOpen, isNarrowLayout } = useAppContext();
    const { editing } = useDashboardContext();
    const { config, tileId, health, onEdit } = useTileContext();
    const { isConfigured } = useDataStreamFromTileConfig(config as DataStreamBaseTileConfig);

    const { canWrite } = useWorkspacePermissions();
    const { setFullScreenConfig } = useFullscreenContext();
    const { isGlobal } = useDataStreamWorkspaceContext();
    
    const canWriteToWorkspace = canWrite({ id: currentWorkspaceID || '' });
    const isDashboard = pathname.startsWith('/dashboard/');
    
    const isSearchDialog = searchOpen;
    const isEditor = isDashboard && canWriteToWorkspace;
    const isEditing = Boolean(editing);
    const isDataStreamTile = config._type === TileTypes.dataStream;
    
    const isOA = isOpenAccess();
    const { isRefreshing, forceRefresh } = useForceRefresh(config);
    const { lastRefreshedString } = useLastRefreshed(config);
    const navigateToExplore = useNavigateToExplore(config, isGlobal);

    return (
        <div className='flex items-center gap-xxxs min-h-[21px]'>
            {isEditing && (
                <TooltipButton
                    variant='tertiary'
                    icon={<FontAwesomeIcon icon={faPencil} fixedWidth />}
                    title='Edit'
                    onClick={() => onEdit()}
                    className={cn(!isEditing && 'hidden group-hover/tile:inline-flex')}
                    data-testid='tileEdit'
                />
            )}

            {!isEditing && isDataStreamTile && !isSearchDialog && (
                <>
                    <TooltipButton
                        variant='tertiary'
                        icon={<FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} fixedWidth />}
                        title='Full screen'
                        className={cn(!isEditing && 'hidden group-hover/tile:inline-flex')}
                        onClick={() =>
                            setFullScreenConfig({
                                ...(config as DataStreamBaseTileConfig),
                                health,
                                tileId
                            })
                        }
                    />
                    {!isOA && (
                        <TooltipButton
                            variant='tertiary'
                            icon={
                                <FontAwesomeIcon
                                    icon={isRefreshing ? faCircleNotch : faRefresh}
                                    spin={isRefreshing}
                                    fixedWidth
                                />
                            }
                            title={lastRefreshedString}
                            className={cn(!isEditing && 'hidden group-hover/tile:inline-flex')}
                            onClick={() => forceRefresh()}
                        />
                    )}
                </>
            )}

            {isDataStreamTile && isSearchDialog && (
                <TooltipButton
                    variant='tertiary'
                    icon={<FontAwesomeIcon icon={faMagnifyingGlassChart} fixedWidth />}
                    title='Explore data'
                    onClick={() => navigateToExplore()}
                    className={cn(!isEditing && 'hidden group-hover/tile:inline-flex')}
                />
            )}

            {/* Don't show menu if this is the search dialog, or an unconfigured datastream tile in OA */}
            {!isSearchDialog && !(isOpenAccess() && isDataStreamTile && !isConfigured) && (
                <TileDropdownMenuActions
                    isEditor={isEditor ?? false}
                    isMobile={isNarrowLayout ?? false}
                    isOA={isOA}
                    // We don't support refreshing non-data stream tiles
                    isForceRefreshEnabled={config._type === 'tile/data-stream'}
                />
            )}
        </div>
    );
};
