import { BarExtendedDatum, Value } from '@nivo/bar';
import { Theme } from '@nivo/core';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { estimateTickSize } from './BarChartCustomBottomAxis';
import { BarChartBars } from './Config';

type Formatter = (category: string | number | Date) => string;

interface BarChartTotalsProps {
    bars: BarChartBars[];
    hasSeriesColumn?: boolean;
    theme: Theme;
    layout: string;
    data: BarExtendedDatum[];
    labelSkipHeight?: number;
    labelSkipWidth?: number;
    setTotalsLabelRightMargin: Dispatch<SetStateAction<number>>;
    indexBy: string;
    format: Formatter;
}

/**
 * Get the total values for each bar group in the bar chart, along with the label positions
 */
function getBarTotals(
    bars: BarChartBars[],
    isHorizontal: boolean,
    format: Formatter,
    labelSkipHeight?: number,
    labelSkipWidth?: number
) {
    const labelMargin = isHorizontal ? 5 : 10;
    let groups: {
        id: Value;
        totalValue: number;
        lastBar: BarChartBars;
    }[] = [];

    // build an array of groups with the total value of each group
    bars.forEach((bar) => {
        if (!bar.data.value) {
            return;
        }

        const group = groups.find((g) => g.id === bar.data.indexValue);

        if (group) {
            group.totalValue += bar.data.value;
            // store last bar in the group to help position the label
            group.lastBar = bar;
        } else {
            groups.push({
                id: bar.data.indexValue,
                totalValue: bar.data.value,
                lastBar: bar
            });
        }
    });

    let barTotals: {
        id: Value;
        value: string;
        y: number;
        x: number;
    }[] = [];

    groups.forEach(({ id, totalValue, lastBar }) => {
        // Don't show total labels for small bars
        if (labelSkipHeight && isHorizontal && lastBar.height < labelSkipHeight) {
            return;
        }
        if (labelSkipWidth && !isHorizontal && lastBar.width < labelSkipWidth) {
            return;
        }

        // Position the label at the end of the last bar in the group
        const x = isHorizontal ? lastBar.x + lastBar.width + labelMargin : lastBar.x + lastBar.width / 2;
        const y = isHorizontal ? (lastBar.y ?? 0) + lastBar.height / 2 : (lastBar.y ?? 0) - labelMargin;

        barTotals.push({
            x,
            y,
            value: format(totalValue),
            id
        });
    });

    return barTotals;
}

const calculateMargin = (labels: string[]) => {
    // Get the longest label, and estimate it's pixel size
    const longestLabelSize = Math.max(...labels.map(estimateTickSize));

    // Add some additional space so it's not right against the label
    const margin = longestLabelSize + 10;

    // Apply a minimum margin in the case of small labels
    return Math.max(40, margin);
};

/**
 * Shows total values for each bar in a bar chart
 */
export function BarChartTotals(props: BarChartTotalsProps) {
    const { bars, theme, layout, labelSkipHeight, labelSkipWidth, format, setTotalsLabelRightMargin } = props;

    const isHorizontal = layout === 'horizontal';

    const totals = useMemo(
        () => getBarTotals(bars, isHorizontal, format, labelSkipHeight, labelSkipWidth),
        [bars, isHorizontal, labelSkipHeight, labelSkipWidth, format]
    );

    // Calculate dynamic margin based on these values
    const rightMargin = calculateMargin(totals.map((t) => t.value));

    // Ideally we wouldn't do this but this is the only place we have access to
    // the current state of the bars via Nivo; useEffect ensures render safety as a result
    useEffect(() => {
        setTotalsLabelRightMargin(rightMargin);
    }, [rightMargin, setTotalsLabelRightMargin]);

    return (
        <g className='bar-totals'>
            {totals.map(({ value, x, y, id }) => (
                <text
                    x={x}
                    y={y}
                    key={id}
                    textAnchor={isHorizontal ? 'start' : 'middle'}
                    alignmentBaseline='central'
                    style={{
                        ...theme.labels?.text,
                        pointerEvents: 'none',
                        fill: 'currentColor'
                    }}
                >
                    {value}
                </text>
            ))}
        </g>
    );
}
