import type { PluginDashboard } from 'dynamo-wrapper';
import React from 'react';
import { FormToggleTable } from 'components/toggle-table/FormToggleTable';

interface InstallSampleDashboardsScrollAreaProps {
    children: React.ReactNode;
}

const InstallSampleDashboardsScrollArea: React.FC<InstallSampleDashboardsScrollAreaProps> = ({ children }) => {
    return (
        <div className='w-full relative'>
            <div
                className='w-full max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-statusUnknownPrimary scrollbar-track-transparent'
                style={{ scrollbarGutter: 'stable' }}
            >
                <div className='flex flex-col w-full'>{children}</div>
            </div>
        </div>
    );
};

interface InstallSampleDashboardTableProps {
    dashboards: PluginDashboard[];
    disabled: boolean;
}


const InstallSampleDashboardTable: React.FC<InstallSampleDashboardTableProps> = ({ dashboards, disabled }) => {
    return (
        <InstallSampleDashboardsScrollArea>
            <FormToggleTable 
                items={dashboards}
                disabled={disabled}
                getName={(dashboard : PluginDashboard) => `selectedDashboards.${[...(dashboard.folderPath || []), dashboard.name].join('/')}`}
                getLabel={(dashboard : PluginDashboard) => dashboard.name}
                getPrefix={(dashboard : PluginDashboard) => (dashboard.folderPath || []).join(' / ')}
            />
        </InstallSampleDashboardsScrollArea>
    );
};

export default InstallSampleDashboardTable;
