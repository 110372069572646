import { StreamDataColumn } from '@squaredup/data-streams';
import Button from 'components/button/Button';
import { VisualisationOptionAction } from 'dashboard-engine/types/Visualisation';

interface HideAllColumnsParams {
    columns: StreamDataColumn[];
    onChange: (action: VisualisationOptionAction) => void;
}

export default function HideAllColumns({ columns, onChange }: HideAllColumnsParams) {
    if (!columns.length) {
        return null;
    }
    
    return (
        <Button 
            variant='tertiary'
            onClick={() => onChange({ action: 'hideAll', data: columns.map(col => col.name) })}
            className='mb-2'
        >
            Hide all
        </Button>
    );
}
