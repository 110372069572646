import ApiKeyForm from 'components/forms/api-key/ApiKeyForm';
import Autocomplete from 'components/forms/autocomplete/Autocomplete';
import Checkbox from 'components/forms/checkbox/Checkbox';
import Json from 'components/forms/json/Json';
import KeyValue from 'components/forms/key-value/KeyValue';
import Number from 'components/forms/number/Number';
import OAuth2 from 'components/forms/oauth2/OAuth2';
import PayloadViewer from 'components/forms/payloadViewer/PayloadViewer';
import Radio from 'components/forms/radio/Radio';
import ShowConfigValue from 'components/forms/showConfigValue/ShowConfigValue';
import Slider from 'components/forms/slider/Slider';
import TextArea from 'components/forms/textarea/TextArea';
import { HTMLInputTypeAttribute, InputHTMLAttributes } from 'react';
import { RegisterOptions, UseFormRegister } from 'react-hook-form';
import { SetRequired } from 'type-fest';
import { Code } from '../code/Code';
import { FieldType } from '../field/getFormFieldComponent';
import AwsCreateRole from '../awsCreateRole/awsCreateRole';

/**
 * Map type strings to the component used to display that type.
 * Types not included here are passed to the default input.
 */
const typeToComponent = {
    apiKey: ApiKeyForm,
    autocomplete: Autocomplete,
    oAuth2: OAuth2,
    awsCreateRole: AwsCreateRole,
    payloadViewer: PayloadViewer,
    showConfigValue: ShowConfigValue,
    checkbox: Checkbox,
    radio: Radio,
    json: Json,
    'key-value': KeyValue,
    slider: Slider,
    textarea: TextArea,
    number: Number
} as const;

type InferProps<T> = T extends React.FC<infer U> ? U : never;

export type PropsForInputType<T extends FieldType> = T extends keyof typeof typeToComponent
    ? Omit<React.ComponentProps<(typeof typeToComponent)[T]>, 'type'> & { type: T }
    : T extends HTMLInputTypeAttribute
      ? SetRequired<Omit<InferProps<typeof DefaultInput>, 'type'> & { type: T }, 'name'>
      : never;

const DefaultInput = ({
    name,
    type,
    register,
    validation,
    ...props
}: {
    type: FieldType;
    name: string;
    register?: UseFormRegister<Record<string, any>>;
    validation: RegisterOptions;
} & InputHTMLAttributes<HTMLInputElement>) => {
    return (
        <input
            id={name}
            type={type}
            className='w-full p-2 px-5 font-medium bg-transparent border-0 placeholder-textIncomplete focus:ring-0'
            {...register?.(name, validation)}
            {...props}
        />
    );
};

/**
 * Create an input based on a dynamic type
 * @param type Input type, e.g. checkbox
 * @param name ID/name of the input
 * @param label Label to display for some inputs, e.g. checkboxes
 * @param register Register function - see React Hook Form
 * @param validation Validation rules - see React Hook Form
 * @param props Other properties to pass to the input
 */
const buildInput = (
    type: FieldType,
    name: string,
    label: string,
    register: UseFormRegister<Record<string, any>>,
    validation: RegisterOptions | undefined,
    props: Record<string, unknown>
) => {
    switch (type) {
        case 'apiKey':
            return <ApiKeyForm name={name} {...props} />;
        case 'autocomplete':
            return <Autocomplete name={name} validation={validation} {...props} />;
        case 'oAuth2':
            return <OAuth2 name={name} validation={validation} {...props} />;
        case 'awsCreateRole':
            return <AwsCreateRole name={name} validation={validation} {...props} />;
        case 'payloadViewer':
            return <PayloadViewer name={name} {...props} />;
        case 'showConfigValue':
            return <ShowConfigValue name={name} configFieldName={props.configFieldName as string} {...props} />;
        case 'checkbox':
            return <Checkbox name={name} label={label} validation={validation} {...props} />;
        case 'radio':
            return <Radio name={name} label={label} register={register} validation={validation} {...props} />;
        case 'json':
            return <Json name={name} validation={validation} {...props} />;
        case 'code':
            return <Code name={name} validation={validation} {...props} />;
        case 'key-value':
            // @ts-expect-error
            return <KeyValue name={name} {...props} />;
        case 'slider':
            return <Slider name={name} register={register} validation={validation} {...props} />;
        case 'textarea':
            return <TextArea name={name} register={register} validation={validation} {...props} />;
        case 'number':
            return <Number name={name} validation={validation} {...props} />;
        default:
            //standard
            return (
                <input
                    id={name}
                    type={type}
                    className='w-full bg-transparent border-0 leading-input text-textPrimary py-input autofill:text-textPrimary px-md placeholder-textIncomplete focus:ring-0 disabled:text-textDisabled disabled:pointer-events-none disabled:placeholder-textDisabled'
                    {...register(name, validation)}
                    {...props}
                />
            );
    }
};

export default buildInput;
