import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faTriangleExclamation, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { FC } from 'react';
import { cn } from '@/lib/cn';

const colors = {
    success: {
        icon: faCircleCheck,
        className: 'text-statusHealthyPrimary'
    },
    warning: {
        icon: faTriangleExclamation,
        className: 'text-statusWarningPrimary'
    },
    error: {
        icon: faXmarkCircle,
        className: 'text-statusErrorPrimary'
    }
};

export interface ValidationMessagesProps {
    messages?: {
        message: string;
        status: 'success' | 'warning' | 'error';
        link?: string;
    }[];
}

const ValidationMessages: FC<ValidationMessagesProps> = ({ messages }) => (
    <>
        {messages?.map(({ message, status, link }, index) => {
            const { icon, className } = colors[status];
            return (
                <div className='mt-2' key={index}>
                    {icon && (
                        <span className={cn(className, 'mr-2')}>
                            <FontAwesomeIcon 
                                icon={icon} 
                                className='fill-current'
                            />
                        </span>
                    )}
                    <span className='mr-2'>{message}</span>
                    {link && (
                        <a className='text-textLink' href={link} target='_blank' rel='noreferrer noopener'>
                            Learn more.
                        </a>
                    )}
                </div>
            );
        })}
    </>
);

export default ValidationMessages;
