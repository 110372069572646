import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import Tooltip from 'components/tooltip/Tooltip';
import { useTileContext } from 'contexts/TileContext';
import { isOpenAccess } from 'lib/openAccessUtil';
import pluralize from 'pluralize';
import { useWorkspaceCanWrite } from 'services/AccessControlService';
import { NO_ACTIVE_WORKSPACE, useCurrentWorkspaceId } from 'services/WorkspaceUtil';
import { useCheckScopeIsOobAndHasLimit } from './hooks/useCheckScopeIsOobAndHasLimit';

interface TileScopeLimitInfoProps {
    config: DataStreamBaseTileConfig;
}

export const TileScopeLimitInfo: React.FC<TileScopeLimitInfoProps> = ({ config }) => {
    const { onEdit } = useTileContext();
    const workspaceId = useCurrentWorkspaceId();
    const { data: canWriteToWorkspace } = useWorkspaceCanWrite(
        workspaceId !== NO_ACTIVE_WORKSPACE ? workspaceId : undefined
    );
    const isOA = isOpenAccess();

    const { isLoading: isLoadingLimit, limit, objectType } = useCheckScopeIsOobAndHasLimit(config);

    if (!canWriteToWorkspace || isOA) {
        return null;
    }

    if (!isLoadingLimit && limit != null && limit !== false) {
        return (
            <Tooltip
                title={`For demo purposes, ${limit > 1 ? 'up to ' : ''}${pluralize(
                    objectType ?? 'object',
                    limit,
                    true
                )} ${limit === 1 ? 'has' : 'have'} been automatically selected. Edit this tile to select your own.`}
            >
                <button onClick={() => onEdit()}>
                    <FontAwesomeIcon icon={faInfoCircle} className='z-10 cursor-pointer text-textLink' />
                </button>
            </Tooltip>
        );
    }

    return null;
};
