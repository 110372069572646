import { Toggle } from '@/components/forms/Toggle';
import { StreamDataColumn, type DataStreamSortingConfig } from '@squaredup/data-streams';
import Field from 'components/forms/field/Field';
import stringify from 'fast-json-stable-stringify';
import { useCallback, useMemo } from 'react';
import { Controller, DeepPartial, FormProvider, useForm } from 'react-hook-form';
import type { PartialDeep } from 'type-fest';
import EditorSelect from 'ui/editor/components/EditorSelect';
import { useFormChange } from 'ui/editor/dataStream/hooks/useFormChange';
import {
    SortingValues,
    directionOptions,
    getSortingColumns,
    getValidSort
} from 'ui/editor/dataStream/utilities/sorting';

interface SortingFormProps {
    defaultValues: PartialDeep<SortingValues>;
    columns: StreamDataColumn[];
    onChange?: (
        event:
            | { isValid: true; formData: DataStreamSortingConfig['sort'] }
            | { isValid: false; formData: PartialDeep<SortingValues> }
    ) => void;
}

export const SortingForm: React.FC<SortingFormProps> = ({ defaultValues, columns, onChange }) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columnOptions = useMemo(() => getSortingColumns(columns), [stringify(columns)]);

    const formProps = useForm<SortingValues>({ defaultValues });

    const { watch, setValue } = formProps;

    const fields = watch();

    const handleChange = useCallback(
        (values: DeepPartial<SortingValues>) => {
            const validSort = getValidSort(values);

            if (validSort == null) {
                onChange?.({ isValid: false, formData: values });
                return;
            }

            onChange?.({ isValid: true, formData: validSort });
        },
        [onChange]
    );

    useFormChange(watch, handleChange);

    const selectedColumnIsValid = columnOptions.some((o) => o.value === fields.column);

    if (!selectedColumnIsValid) {
        if (fields.enableTop) {
            setValue('enableTop', false);
        } else if (fields.column != null) {
            setValue('column', null);
        }
    }

    return (
        <FormProvider {...formProps}>
            <form>
                <div className='grid grid-cols-[minmax(0,1fr)_minmax(0,1fr)] gap-2'>
                    <div>
                        <EditorSelect
                            key={stringify(columnOptions)}
                            name='column'
                            placeholder='Select column'
                            options={columnOptions}
                        />
                    </div>
                    <div>
                        <EditorSelect
                            key={stringify(directionOptions)}
                            name='direction'
                            placeholder='Ascending'
                            options={directionOptions}
                            isClearable={false}
                            isDisabled={!selectedColumnIsValid}
                        />
                    </div>
                    <Field.Label className='mt-2 text-textPrimary' spacing='none' label='Top'>
                        <div className='flex flex-row items-center col-start-1 mb-2 space-x-2 min-h-[2.92rem]'>
                            <Controller
                                name='enableTop'
                                render={({ field: { value, onChange: onValueChange } }) => (
                                    <Toggle
                                        checked={value}
                                        disabled={!selectedColumnIsValid}
                                        aria-label='Enable top'
                                        data-testid='sorting-top'
                                        onCheckedChange={() => {
                                            onValueChange(!value);
                                            if (!fields.top) {
                                                setValue('top', '10');
                                            }
                                        }}
                                    />
                                )}
                            />
                            {fields.enableTop && (
                                <div>
                                    <Field.Input type='number' name='top' min={1} placeholder='Number of results' />
                                </div>
                            )}
                        </div>
                    </Field.Label>
                </div>
            </form>
        </FormProvider>
    );
};
