import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { VisualisationOptionAction } from 'dashboard-engine/types/Visualisation';
import { useEffect, useState, type ComponentProps } from 'react';
import Select, { SingleValue, type MultiValue } from 'react-select';

type DropdownParams = {
    action: string;
    onChange: (action: VisualisationOptionAction) => void;
} & Omit<ComponentProps<typeof Select<AutocompleteOption, boolean>>, 'onChange' | 'className'>;

const isMultiValue = (
    x: SingleValue<AutocompleteOption> | MultiValue<AutocompleteOption>
): x is MultiValue<AutocompleteOption> => Array.isArray(x);

export const OptionDropdown = ({ name, action, onChange, ...selectProps }: DropdownParams) => {
    const [value, setValue] = useState(selectProps.defaultValue);

    useEffect(() => {
        setValue(selectProps.defaultValue);
    }, [selectProps.defaultValue]);

    const handleChange = (choice: SingleValue<AutocompleteOption> | MultiValue<AutocompleteOption>) => {
        setValue(choice ?? selectProps.defaultValue);

        if (isMultiValue(choice)) {
            onChange({ action, data: choice.map((c) => c.value) });
        } else {
            onChange({ action, data: choice?.value });
        }
    };

    const portalTarget = useDOMElement('visualizationConfigPanel');

    return (
        <Select<AutocompleteOption, boolean>
            name={name}
            classNamePrefix='autocomplete'
            className='ring-1 ring-inset ring-outlinePrimary focus-within:ring-outlineSecondary rounded-input bg-componentBackgroundPrimary'
            blurInputOnSelect={true}
            value={value}
            onChange={handleChange}
            menuPortalTarget={portalTarget}
            {...selectProps}
        />
    );
};
