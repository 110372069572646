import { useQuery } from 'react-query';
import { Get as getPlugin } from 'services/PluginService';
import { useDataStreamDefinition } from './useDataStreamDefinition';
import { TimeframeEnumValue } from '@squaredup/timeframes';

export const useDataStreamSupportedTimeframes = (dataStreamId?: string): boolean | TimeframeEnumValue[] => {
    // Load tile data stream as well as the sourceTemplate data stream if it is a configured data stream
    const { data: dataStream } = useDataStreamDefinition(dataStreamId);

    const { pluginId } = dataStream || {};

    const { data: plugin } = useQuery(['plugin', pluginId], () => getPlugin(pluginId!), {
        enabled: Boolean(pluginId)
    });

    const dataStreamTimeframe = dataStream?.definition.timeframes;

    const pluginDataSource = plugin?.dataSources.find((d) => d.name === dataStream?.dataSourceName);

    const dataSourceTimeframe = pluginDataSource?.timeframes ?? true;

    return dataStreamTimeframe ?? dataSourceTimeframe;
};
