type UseCopyToClipboard = ({
    onNotSupported,
    onSuccess,
    onError
}: {
    /**
     * Invoked when copy is not available, e.g. on HTTP connections
     * @param value String for copying
     */
    onNotSupported?: (value: string) => void;
    /**
     * Invoked when copy to clipboard suceeds
     */
    onSuccess?: () => void;
    /**
     * Invoked when copy to clipboard fails
     */
    onError?: () => void;
}) => {
    /**
     * Copy to clipboard function
     * @param value String to be copied to clipboard
     */
    copy: (value: string) => Promise<void>;
    /**
     * Is copy to clipboard supported on this browser/connection
     */
    supportsClipboard: boolean;
};

/**
 * Hook for performing a copy to clipboard action, with fallback functions
 * for when it's not available (e.g. HTTP connections)
 * @returns Function to trigger copy of the given string
 */
const useCopyToClipboard: UseCopyToClipboard = ({ onNotSupported, onSuccess, onError }) => {
    const supportsClipboard = Boolean(navigator.clipboard); // check support

    return {
        supportsClipboard,
        copy: async (value: string) => {
            if (!supportsClipboard) {
                onNotSupported?.(value);
            } else {
                try {
                    await navigator.clipboard.writeText(value ?? '');
                    onSuccess?.();
                } catch {
                    onError?.();
                }
            }
        }
    };
};

export default useCopyToClipboard;
