import * as Constants from '@squaredup/constants';
import { SqUpPluginConfigId, SqUpPluginDisplayName, SqUpPluginId } from '@squaredup/ids';
import { ListPluginSourceConfigs, PluginSourceConfig } from 'services/SourceConfigService';

export const SqUpPluginConfig: PluginSourceConfig = {
    id: SqUpPluginConfigId.value,
    displayName: SqUpPluginDisplayName,
    plugin: {
        name: SqUpPluginDisplayName,
        displayName: SqUpPluginDisplayName,
        pluginId: SqUpPluginId.value
    },
    source: 'PLUGIN',
    description: '',
    importStatus: undefined,
    type: 'config',
    subType: 'source.plugin',
    tenant: `ten-${Constants.GlobalTenantId}` as `ten-${string}`
};

export const injectSqUpPluginSource = (pluginSources: Awaited<ReturnType<typeof ListPluginSourceConfigs>>) => {
    if (!pluginSources.find((p) => p.id === SqUpPluginConfigId.value)) {
        pluginSources.push(SqUpPluginConfig as any);
    }
    return pluginSources.sort((a, b) => a.displayName?.localeCompare(b.displayName ?? '') ?? -1);
};
