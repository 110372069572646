import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import TileContext from 'contexts/TileContext';
import trackEvent from 'lib/analytics';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useRecentDataStreamStore } from './useRecentDataStreamStore';

/**
 * @returns useMutation mutateAsync function that allows the user to save
 */
export const useTileEditorSave = () => {
    const { onChange, onClose } = useContext(TileContext);
    const { saveRecentDataStream } = useRecentDataStreamStore();

    const { mutateAsync: handleSave } = useMutation({
        onSuccess: onClose,
        mutationFn: async (configToSave: DataStreamBaseTileConfig) => {
            trackEvent('Tile Saved', { title: configToSave.title });
            saveRecentDataStream(configToSave.dataStream?.id ?? '');
            return onChange(configToSave);
        }
    });

    return handleSave;
};
