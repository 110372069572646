import { FeatureKey, isUnderLimit } from '@squaredup/tenants';
import { useTier } from 'queries/hooks/useTier';
import React from 'react';

export interface BelowFeatureLimitProps {
    featureKey: FeatureKey;
    currentUsage: number | undefined;
    fallback?: React.ReactNode;
}

export const BelowFeatureLimit: React.FC<BelowFeatureLimitProps> = ({
    currentUsage,
    children,
    fallback,
    featureKey
}) => {
    const { data: tier } = useTier();

    if (!tier || currentUsage === undefined) {
        return <>{fallback}</>;
    }

    return isUnderLimit(tier, featureKey, currentUsage) ? <>{children}</> : <>{fallback}</>;
};
