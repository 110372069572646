import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { StreamDataColumn, type DataStreamFilterConfig } from '@squaredup/data-streams';
import Button from 'components/button/Button';
import { Fragment, useCallback, useMemo } from 'react';
import { DeepPartial, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useFormChange } from 'ui/editor/dataStream/hooks/useFormChange';
import {
    Filter,
    FilterFormData,
    FilteringFormValidationSchema,
    getEmptyFilter,
    getFilterColumns,
    getFilterValueType,
    getValidFilters
} from 'ui/editor/dataStream/utilities/filters';
import { FilterOption } from './FilterOption';

interface FilteringFormProps {
    defaultValues: Partial<FilterFormData>;
    columns: StreamDataColumn[];
    onChange?: (
        event:
            | {
                  formData: Exclude<DataStreamFilterConfig, undefined>['filter'];
                  isValid: true;
              }
            | { formData: Record<string, unknown>; isValid: false }
    ) => void;
}

export const FilteringForm: React.FC<FilteringFormProps> = ({ defaultValues, columns, onChange }) => {
    const columnOptions = useMemo(() => getFilterColumns(columns), [columns]);

    const formProps = useForm<FilterFormData>({
        defaultValues,
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: zodResolver(FilteringFormValidationSchema)
    });

    const { watch, control, trigger, setValue } = formProps;

    const {
        fields: filters,
        append,
        remove
    } = useFieldArray({
        control,
        name: 'filters'
    });

    const fields = watch();

    const handleChange = useCallback(
        (values: DeepPartial<FilterFormData>) => {
            // Changing operator can cause the value field to become invalid,
            // but RHF by default only re-validates the changed field
            trigger();

            const result = getValidFilters(values, columns);

            if (result.failed) {
                onChange?.({ formData: result.data, isValid: false });
                return;
            }

            const validFilters = result.value;

            onChange?.({ formData: validFilters, isValid: true });
        },
        [trigger, columns, onChange]
    );

    useFormChange(watch, handleChange);

    const handleAddFilter = () => {
        fields.filters.forEach(({ value, column }, i) => {
            if (getFilterValueType(columns.find((c) => c?.name === column)?.shapeName) !== 'text') {
                return;
            }

            setValue(`filters.${i}.value`, value, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true
            });
        });

        append(getEmptyFilter());
    };

    return (
        <FormProvider {...formProps}>
            {/* aria-label is required for this element to get the default 'form' role for testing */}
            <form aria-label='filtering form'>
                <div className='flex flex-col gap-y-4'>
                    {filters.map((field, index) => (
                        <Fragment key={field.id}>
                            <FilterOption
                                index={index}
                                filter={fields.filters?.[index]}
                                columnOptions={columnOptions}
                                columns={columns}
                                // We use setValue here to ensure fields are updated when values change during render
                                update={(value: Filter) => setValue(`filters.${index}`, value)}
                                remove={() => remove(index)}
                            />
                        </Fragment>
                    ))}
                    <Button
                        onClick={handleAddFilter}
                        variant='secondary'
                        className='place-self-start'
                        data-testid='filtering-addFilter'
                    >
                        <FontAwesomeIcon icon={faPlus} className='mr-3' /> <span>Add filter</span>
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};
