import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Serialised } from '@squaredup/ids';
import type { CustomType } from 'dynamo-wrapper';
import API from './API';
import { handleError, handleResponse } from './util';

export type ClientSideCustomType = Serialised<CustomType> & {
    config: {
        name: string;
        icon: IconProp;
        singular: string;
        plural: string;
        type: string;
    };
};

export const Get = async (id: string) => {
    return API.get(`/types/${id}`).then(handleResponse).catch(handleError);
};

export const ListForAdministration = async () => {
    return API.get<ClientSideCustomType[]>('/types?includeDisabled=true').then(handleResponse).catch(handleError);
};

export const ListIncludingPluginCustomTypes = async () => {
    return API.get<ClientSideCustomType[]>('/types?includePluginCustomTypes=true')
        .then(handleResponse)
        .catch(handleError);
};

export const Create = async (
    displayName: string,
    config: any,
    pluginConfigId?: string,
    description?: string,
    json?: object
) => {
    const created = await API.post('/types', { displayName, config, description, pluginConfigId, json })
        .then(handleResponse)
        .catch(handleError);

    return created.id;
};

export const Update = async (id: string, displayName: string, config: any, json?: object) => {
    await API.put(`/types/${id}`, { displayName, config, json }).then(handleResponse).catch(handleError);
    return id;
};

export const Delete = async (id: string) => {
    await API.delete(`/types/${id}`).then(handleResponse).catch(handleError);
    return true;
};
