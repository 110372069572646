import { Serialised } from '@squaredup/ids';
import { LINKED_PLUGINS } from 'components/hooks/useLinkedPluginConfigs';
import type { ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { QueryClient } from 'react-query';
import { DATA_STREAM_DEFINITIONS_FOR_WORKSPACE, INCLUDE_TEMPLATES } from 'services/DataStreamDefinitionService';
import { PLUGIN_SOURCES, PluginSourceConfig } from 'services/SourceConfigService';
import { SOURCE_TYPE_MAP } from '../../hooks/useSourceTypesToTypesMap';

export const invalidateDataSourceAfterAddingDataSourceToWorkspace = (
    selectedDataSource: PluginSourceConfig,
    dataStreams: Serialised<ProjectedDataStreamDefinitionEntity>[] | undefined,
    workspaceId: string,
    queryClient: QueryClient
) => {
    const parentPluginId = selectedDataSource.plugin?.pluginId;

    const matchedDataStreamsToDataSource = dataStreams?.filter((stream) => stream.parentPluginId === parentPluginId);

    if (matchedDataStreamsToDataSource == null) {
        return;
    }

    queryClient.setQueryData([PLUGIN_SOURCES, workspaceId], (previousDataSources: PluginSourceConfig[] = []) => {
        return [...previousDataSources, selectedDataSource];
    });

    queryClient.invalidateQueries(workspaceQueryKeys.all);
    queryClient.invalidateQueries([SOURCE_TYPE_MAP, workspaceId]);
    queryClient.invalidateQueries([LINKED_PLUGINS, workspaceId]);

    queryClient.setQueryData(
        [DATA_STREAM_DEFINITIONS_FOR_WORKSPACE, workspaceId, INCLUDE_TEMPLATES],
        (previousDataStreams: Serialised<ProjectedDataStreamDefinitionEntity>[] = []) => {
            return previousDataStreams.concat(matchedDataStreamsToDataSource);
        }
    );
};
