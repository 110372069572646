import { DataStreamBaseTileConfig } from '@squaredup/data-streams';

export const getInitialObjectsSelection = (config: DataStreamBaseTileConfig) => {
    // For tiles with no scope we want to initialise the editor in fixed scope mode. We therefore
    // return an empty array (`isDynamic` checks the return of this function is undefined)
    if (!config.scope) {
        return [];
    }

    if (!Array.isArray(config.scope) && 
        'queryDetail' in config.scope && 
        ('ids' in config.scope.queryDetail || 'list' in config.scope.queryDetail)
    ) {
        return config.scope.queryDetail.ids || config.scope.queryDetail.list || [];
    }
};
