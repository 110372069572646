import { cn } from '@/lib/cn';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { DebouncedInput } from './DebouncedInput';

interface ApplicationTableGlobalFilterProps {
    globalFilter?: string;
    setGlobalFilter?: Dispatch<SetStateAction<string>>; 
}

export const ApplicationTableGlobalFilter: React.FC<ApplicationTableGlobalFilterProps> = ({
    globalFilter,
    setGlobalFilter
}) => {
    const [searchOpen, setSearchOpen] = useState(Boolean(globalFilter));
    const [currentValue, setCurrentValue] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    return (
        <div className='sticky top-0 right-0 flex justify-end pr-4'>
            <div
                className={cn(
                    'flex items-center border-0 bg-componentBackgroundPrimary font-normal pointer-events-auto ring-inset ring-1 ring-outlinePrimary focus-within:ring-inset focus-within:ring-1 rounded-input focus-within:ring-outlineSecondary leading-input py-[6px] pl-[8px] text-tertiaryButton placeholder-textIncomplete disabled:text-tertiaryButtonDisabled',
                    !searchOpen && 'hover:text-tertiaryButtonHover'
                )}
                onClick={() => {
                    setSearchOpen(true);
                    inputRef?.current?.focus();                               
                }}
                onBlur={() => {
                    !currentValue && setSearchOpen(false);
                    inputRef?.current?.blur();                  
                }}
                >
                <FontAwesomeIcon icon={faSearch} className='pointer-events-none mr-[8px]' />
                <DebouncedInput
                    autoFocus={searchOpen}
                    value={globalFilter ?? ''}
                    onChange={(value) => {
                        if (setGlobalFilter !== undefined) {
                            setGlobalFilter(value);
                        }
                        setCurrentValue(value);
                    }}
                    className={cn(
                        'flex-grow placeholder-textIncomplete bg-transparent leading-input border-0 ring-0 p-0 focus:outline-none focus:ring-0 transition-[width,opacity,padding]',
                        searchOpen ? 'w-48 opacity-100 pr-xs' : 'w-px opacity-0 pr-0'
                    )}
                    placeholder='Search...'
                    type='text'
                    ref={inputRef}
                />
            </div>
        </div>
    );
};
