import DropdownMenu from '@/components/DropdownMenu';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { useFullscreenContext } from 'contexts/FullscreenContext';
import { useTileContext } from 'contexts/TileContext';
import { TileTypes } from 'dashboard-engine/constants';

export const Fullscreen: React.FC = () => {
    const { setFullScreenConfig } = useFullscreenContext();
    const { config, tileId, health } = useTileContext();

    if (config._type !== TileTypes.dataStream) {
        return null;
    }

    return (
        <DropdownMenu.Item
            icon={<FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} fixedWidth />}
            onSelect={() => setFullScreenConfig({
                ...(config as DataStreamBaseTileConfig),
                health,
                tileId
            })}
            key='fullscreen'
        >
            Full screen
        </DropdownMenu.Item>
    );
};
