import { useNavigate } from 'react-router-dom';
import { ConfirmationPrompt } from 'components/ConfirmationPrompt';

interface NavigateToDataSourcesPagePromptProps {
    workspaceId: string;
    onCancelNavigation: () => void;
}

export const NavigateToDataSourcesPagePrompt = ({
    workspaceId,
    onCancelNavigation
}: NavigateToDataSourcesPagePromptProps) => {
    const navigate = useNavigate();

    return (
        <ConfirmationPrompt
            title={'Discard changes'}
            confirmButtonText='Discard'
            confirmButtonVariant='destructive'
            prompt="You are being redirected to the data source page, any changes you've made will be lost."
            onConfirm={() => navigate(`/datasources/${workspaceId}`)}
            onClose={onCancelNavigation}
        />
    );
};
