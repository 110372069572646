import { faFileImage, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { checkFileSize, checkFileTypeIsImage, maxSizeMegaBytes, readFileAsDataURL } from 'lib/blobs';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useController, useFormContext } from 'react-hook-form';

const validateFile = (files: unknown) => {
    if (Array.isArray(files) && files.length > 1) {
        return 'Too many files uploaded';
    }

    if (Array.isArray(files) && files[0] instanceof File) {
        if (!checkFileTypeIsImage(files[0])) {
            return 'The file uploaded was not compatible (PNG, JPG, SVG, GIF, WEBP).';
        }
        const { ok } = checkFileSize(files[0]);
        if (!ok) {
            return `The image uploaded exceeded the maximum filesize (${maxSizeMegaBytes}MB).`;
        }
    }
    return true;
};

interface ImageDropzoneProps {
    fileName: string;
    onFileChange: (dataUrl: string, fileName: string) => void;
}

export const ImageDropzone: React.FC<ImageDropzoneProps> = ({ fileName, onFileChange }) => {
    const { trigger, setValue, watch, setError } = useFormContext();
    const fields = watch();
    const { field, fieldState } = useController({
        name: 'files',
        rules: {
            required: Boolean(!fields.uploaded),
            validate: validateFile
        }
    });

    const onDrop = useCallback(
        async (acceptedFiles) => {
            field.onChange(acceptedFiles);
            if (validateFile(acceptedFiles) !== true) {
                return;
            }
            const file = acceptedFiles[0];
            if (file && file instanceof File) {
                const dataURL = await readFileAsDataURL(file);
                onFileChange(dataURL, file.name);
                trigger();
            }
        },
        [onFileChange, field, trigger]
    );

    const handleClearFile = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        setValue('uploaded', 0);
        onFileChange('', '');
        field.onChange(null);
        setError('files', { message: 'Field required' });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop
    });

    return (
        <div
            {...getRootProps({
                className: clsx(
                    'flex flex-col flex-1 w-full items-center justify-center p-4 cursor-pointer h-28 bg-componentBackgroundPrimary border-[1px] rounded-md',
                    {
                        'border-outlinePrimary': !isDragActive,
                        'border-outlineSecondary': isDragActive,
                        'border-none': !fileName && !isDragActive
                    }
                ),
                style: {
                    backgroundImage:
                        !fileName && !isDragActive
                            ? "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23333' stroke-width='2' stroke-dasharray='10%2c10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e\")"
                            : undefined
                }
            })}
        >
            <input type='file' id='files' data-testid='files' placeholder='Upload an image' {...getInputProps()} />
            {fileName ? (
                <div className='flex items-center space-x-2.5'>
                    <FontAwesomeIcon icon={faFileImage} style={{ width: 19, height: 'auto' }} />
                    <p className='text-textPrimary'>{fileName}</p>
                    <FontAwesomeIcon icon={faXmarkCircle} onClick={handleClearFile} className='text-textSecondary' />
                </div>
            ) : (
                <>
                    {isDragActive ? (
                        <p className='text-textPrimary'>Drop files here</p>
                    ) : (
                        <p className='text-textSecondary'>
                            Drag and drop, or <span className='underline'>browse</span> for an image
                        </p>
                    )}
                    {fieldState.error && <p className='px-4 text-center text-statusErrorPrimary'>{fieldState.error.message}</p>}
                </>
            )}
        </div>
    );
};
