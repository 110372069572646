import { Toggle, ToggleProps } from '@/components/forms/Toggle';
import { cn } from '@/lib/cn';
import { ToggleStyleWrapper } from 'components/ToggleStyleWrapper';

interface ToggleParams extends Omit<ToggleProps, 'onChange'> {
    name: string;
    label: string;
    wrapperClassName?: string;
    help?: string;
    onChange: (choice: boolean) => void;
}

export const OptionToggle = ({ name, label, help, wrapperClassName, checked, onChange, ...props }: ToggleParams) => {
    return (
        <ToggleStyleWrapper
            name={name}
            label={label}
            help={help}
            disabled={props.disabled}
            wrapperClassName={cn('bg-tagBackground', wrapperClassName)}
        >
            <Toggle
                name={name}
                checked={checked}
                disabled={props.disabled}
                onCheckedChange={(value) => onChange(value)}
                {...props}
            />
        </ToggleStyleWrapper>
    );
};
