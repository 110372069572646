import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { Serialised } from '@squaredup/ids';
import { isDefaultValid } from 'components/forms/jsonForms/DisplayJsonUi';
import type { DataStreamDefinitionEntity } from 'dynamo-wrapper';
import { getDataStreamType, type DataStreamType } from './getDataStreamType';

const isDataStreamConfigured = (
    dataStreamType: DataStreamType | undefined,
    hasScope: boolean,
    hasConfiguration: boolean,
    hasTable: boolean,
    hasPluginInstanceSpecified: boolean
) => {
    // If no data stream is found, return true to allow inner error to show
    if (!dataStreamType) {
        return true;
    }

    if (dataStreamType.supportsDatasets) {
        return hasTable;
    }

    const isConfiguredIfRequired = hasConfiguration || !dataStreamType.isConfigurable;

    const hasScopeIfRequired =
        hasScope || !dataStreamType.supportsScope || (!dataStreamType.requiresScope && hasPluginInstanceSpecified);

    return isConfiguredIfRequired && hasScopeIfRequired;
};

export const getIsDataStreamConfigured = (dataStreamDefinition: any, config: DataStreamBaseTileConfig) => {
    const dataStreamConfig = config.dataStream || {};

    if (Object.keys(dataStreamConfig).length === 0) {
        return false;
    }

    const dataStreamType = dataStreamDefinition
        ? getDataStreamType(dataStreamDefinition as Serialised<DataStreamDefinitionEntity>, false)
        : undefined;

    const hasScope = Object.keys(config.scope || {}).length > 0;

    const hasConfiguration =
        (Boolean(dataStreamConfig.dataSourceConfig) &&
            Object.keys(dataStreamConfig.dataSourceConfig || {}).length > 0) ||
        (Boolean(dataStreamDefinition) && isDefaultValid(dataStreamDefinition.template));

    const hasPluginInstanceSpecified = config?.dataStream?.pluginConfigId != null;

    return isDataStreamConfigured(
        dataStreamType,
        hasScope,
        hasConfiguration,
        hasTable(dataStreamConfig),
        hasPluginInstanceSpecified
    );
};

export const hasTable = (dataStreamConfig: any) => {
    if (!dataStreamConfig) {
        return false;
    }
    const sql = dataStreamConfig.dataSourceConfig?.sql;
    const sqlLowerCase = typeof sql === 'string' ? sql.toLowerCase() : '';
    return (
        Boolean(sqlLowerCase) &&
        Boolean(
            // Allow inline variables / tables (see examples in datasource-service/src/dataStreams/builtIn/sql/Sql.ts)
            sqlLowerCase.includes('@[') ||
                ['create', 'table', 'insert'].every((keyword) => sqlLowerCase.includes(keyword)) ||
                (dataStreamConfig.dataSourceConfig?.tables &&
                    dataStreamConfig.dataSourceConfig?.tables.some(
                        (table: any) => table && table.tableName && table.config?.dataStream
                    ))
        )
    );
};
