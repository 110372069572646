import { RefObject, useEffect } from 'react';

export function useClickOutside(refs: RefObject<HTMLElement>[], callback?: () => void) {
    useEffect(() => {
        function isClickOutsideRefs(event: MouseEvent) {
            return refs.reduce((isOutsideOtherRefs, ref) => {
                const outsideCurrentRef = ref.current && event.target && !ref.current.contains(event.target as Node);
                return Boolean(outsideCurrentRef) && isOutsideOtherRefs;
            }, true);
        }

        function handleClickOutside(event: MouseEvent) {
            if (isClickOutsideRefs(event)) {
                callback?.();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [callback, refs]);
}
