import Text from '@/components/Text';
import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { Action } from 'pages/components/ApplicationTable/types';
import { FC } from 'react';
import { ProjectedCustomCorrelation } from 'services/CustomCorrelationsService';
import TruncateWithTooltip from '../TruncateWithTooltip';

interface CustomCorrelationsTableProps {
    customCorrelations: ProjectedCustomCorrelation[];
    isFeatureAvailable: boolean;
    onEdit: (customType: ProjectedCustomCorrelation) => void;
    onDelete: (workspace: ProjectedCustomCorrelation) => void;
}

const CustomTypesApplicationTable = ApplicationTable<ProjectedCustomCorrelation, string>();

const CustomCorrelationsTable: FC<CustomCorrelationsTableProps> = ({
    customCorrelations,
    onEdit,
    onDelete,
    isFeatureAvailable
}) => {
    const columns: ColumnDef<ProjectedCustomCorrelation>[] = [
        {
            id: 'name',
            header: 'Name',
            cell: ({ row }) => {
                return (
                    <div className='flex items-center gap-2'>
                        <TruncateWithTooltip title={row.original.displayName || ''}>
                            <div className='truncate'>{row.original.displayName}</div>
                        </TruncateWithTooltip>
                        {!isFeatureAvailable && (
                            <Text.SmallBody className='text-textSecondary'>(disabled)</Text.SmallBody>
                        )}
                    </div>
                );
            },
            size: 300,
            accessorKey: 'displayName'
        },
        {
            id: 'from',
            header: 'From',
            cell: ({ row }) => {
                return (
                    <TruncateWithTooltip
                        title={`${row.original.config.fromType} / ${row.original.config.fromProp}` || ''}
                    >
                        <div className='truncate'>{`${row.original.config.fromType} / ${row.original.config.fromProp}`}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 300,
            accessorKey: 'config.fromProp'
        },
        {
            id: 'to',
            header: 'To',
            cell: ({ row }) => {
                return (
                    <TruncateWithTooltip title={`${row.original.config.toType} / ${row.original.config.toProp}` || ''}>
                        <div className='truncate'>{`${row.original.config.toType} / ${row.original.config.toProp}`}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 300,
            accessorKey: 'config.toProp'
        },
        {
            id: 'associationLabel',
            header: 'Association Label',
            cell: ({ row }) => {
                return (
                    <TruncateWithTooltip title={row.original.config.verb || ''}>
                        <div className='truncate'>{row.original.config.verb}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 300,
            accessorKey: 'config.verb'
        }
    ];

    const actions: Action[] = [
        {
            action: onEdit,
            visible: (_) => isFeatureAvailable,
            icon: faPencil,
            dataTestId: 'edit',
            tooltip: 'Edit correlation rule'
        },
        {
            action: onDelete,
            icon: faTrash,
            dataTestId: 'delete',
            tooltip: 'Delete correlation rule'
        }
    ];

    return (
        <div className='flex flex-col flex-1 w-full h-full min-h-0'>
            <CustomTypesApplicationTable
                config={{
                    actions,
                    noDataMessage: 'There are no correlation rules configured.'
                }}
                columns={columns}
                data={customCorrelations}
            />
        </div>
    );
};

export default CustomCorrelationsTable;
