import { useDatasourceConfigsForAdministration } from 'queries/hooks/useDatasourceConfigsForAdministration';
import { useWorkspace } from 'queries/hooks/useWorkspace';

export const useUnlinkedDataSources = (workspaceId: string) => {
    const { data: datasourceConfigs = [] } = useDatasourceConfigsForAdministration();
    const { data: workspace, isLoading } = useWorkspace(workspaceId);

    const unlinkedDataSources = datasourceConfigs.filter(
        (plugin) => !workspace?.data?.links?.plugins?.find((datasourceConfigId) => datasourceConfigId === plugin.id)
    );

    return { unlinkedDataSources, datasourceConfigs, isLoading };
};
