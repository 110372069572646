import { faCheckCircle, faExclamationCircle, faQuestionCircle, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import DOMPurify from 'dompurify';
import parse, { Element, HTMLReactParserOptions, attributesToProps, domToReact } from 'html-react-parser';
import { omit, uniq } from 'lodash';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { HealthStateConfig } from './Config';
import { HealthIconDefinition } from './HealthIconDefinition';
import { HealthStateFilter } from './HealthStateFilter';
import { HealthStateIcon } from './HealthStateIcon';

type ParseInlineSVGParams = {
    src: string;
    tileId: string;
    className: string;
    healthConfig: HealthStateConfig;
    healthStates: {
        id: string;
        state: string;
    }[];
    isPreview?: boolean;
};

export const decodeSVG = (src: string) => {
    const svgDataUrl = src.match(/^data:image\/svg[^,]*?(;base64)?,(.*)/u);
    if (svgDataUrl) {
        // src data url is either base64 encoded or URI encoded, appropriately decode it
        return svgDataUrl[1] ? window.atob(svgDataUrl[2]) : decodeURIComponent(svgDataUrl[2]);
    }
    return src;
};

export const extractIds = (src: string) => {
    // Sanitize user uploaded image to prevent XSS
    const svgString = DOMPurify.sanitize(decodeSVG(src));

    // Keep track of the dashboard/workspace ids and node ids we find when traversing the SVG
    const sourceIds: string[] = [];
    const ids: string[] = [];

    const htmlParserOptions: HTMLReactParserOptions = {
        trim: true,
        transform(reactNode, domNode) {
            if (domNode instanceof Element && domNode.attribs && domNode.name === 'a') {
                const nodeHref = domNode.attribs.href || domNode.attribs['xlink:href'];
                const url = getObjectHealthUrl(nodeHref);

                const anchor = getAnchorHealthId(url.pathname, url.searchParams);
                anchor?.sourceId && sourceIds.push(anchor.sourceId);
                anchor?.id && ids.push(anchor.id);
            }
            return reactNode;
        }
    };

    try {
        parse(svgString, htmlParserOptions);
    } catch (e) {
        return { sourceIds: [], ids: [] };
    }

    return {
        // ids/sourceIds could be included in the SVG multiple times, but we want a list of unique values
        sourceIds: uniq(sourceIds),
        ids: uniq(ids)
    };
};

export const parseInlineSVG = ({
    src,
    tileId,
    className,
    healthConfig,
    healthStates,
    isPreview
}: ParseInlineSVGParams) => {
    // Sanitize user uploaded image to prevent XSS
    const svgString = DOMPurify.sanitize(decodeSVG(src));

    // Keep track of any health icons we encounter, we have to put these after other elements
    // to simulate z-index
    const healthIcons = new Map<Element, JSX.Element>();

    const htmlParserOptions: HTMLReactParserOptions = {
        trim: true,
        transform(reactNode, domNode, index) {
            if (domNode instanceof Element && domNode.attribs) {
                if (domNode.name === 'svg' && index === 0) {
                    // Add our classname to the root SVG element so it fills the space correctly
                    return (
                        <svg {...attributesToProps(domNode.attribs)} className={className}>
                            <defs>
                                {healthStates.map(({ id, state }) => (
                                    <Fragment key={id}>
                                        <HealthStateFilter id={`${tileId}-${id}`} state={state} />
                                        <HealthIconDefinition id={`${tileId}-${id}`} state={state} />
                                    </Fragment>
                                ))}
                            </defs>
                            {/* This will recursively process all child SVG elements, 
                                gathering any health icons in the process */}
                            {domToReact(domNode.children, htmlParserOptions)}
                            {/* Put the health icons last so they appear above the rest of the elements */}
                            {[...healthIcons.values()]}
                        </svg>
                    );
                } else if (domNode.name === 'a') {
                    const nodeHref = domNode.attribs.href || domNode.attribs['xlink:href'];
                    const url = getObjectHealthUrl(nodeHref);

                    const anchor = getAnchorHealthId(url.pathname, url.searchParams);

                    // Replace anchor tags with react-router links if they are internal links
                    if (url.origin === window.location.origin) {
                        const props = omit(attributesToProps(domNode.attribs), 'href', 'xlink:href', 'target');
                        // Pick the first child of the anchor's children to apply the health filter to
                        const [firstChild, ...rest] = domNode.children.filter((child) =>
                            // Ignore any text nodes that just contain whitespace
                            child.type === 'text' ? child.data.trim() !== '' : true
                        );

                        const { mode, iconSize, iconPosition } = healthConfig;

                        const healthId = `${tileId}-${anchor?.sourceId || anchor?.id || ''}`;
                        const glowFilter = mode?.includes('glow') ? `url(#health-filter-glow-${healthId})` : undefined;
                        const fillFilter = mode?.includes('fill') ? `url(#health-filter-fill-${healthId})` : undefined;

                        const createLink = (children: JSX.Element) => (
                            <Link
                                key={index}
                                to={`${url.pathname}${url.search}`}
                                // Open internal links in a new tab when in preview
                                // so the user doesn't lose their progress
                                target={isPreview ? '_blank' : undefined}
                                {...props}
                            >
                                {children}
                            </Link>
                        );

                        if (mode?.includes('icon')) {
                            // We use the domNode reference to ensure we don't create duplicate icons
                            // index is not unique as it resets to 0 when traversing nested children
                            // see https://github.com/remarkablemark/html-react-parser/issues/1259
                            healthIcons.set(
                                domNode,
                                // Include link with health icon so it is clickable
                                createLink(
                                    <HealthStateIcon
                                        healthId={healthId}
                                        size={iconSize}
                                        verticalPosition={iconPosition?.vertical || 'top'}
                                        horizontalPosition={iconPosition?.horizontal || 'left'}
                                    >
                                        {/* Hide content, we only need it to calculate the position of the icon */}
                                        <g opacity={0}>{domToReact(domNode.children, htmlParserOptions)}</g>
                                    </HealthStateIcon>
                                )
                            );
                        }

                        return createLink(
                            <>
                                {/* This filter matches up with a corresponding <HealthStateFilter />
                                    component which sets the correct filter glow for the given id/sourceId */}
                                <g filter={glowFilter}>
                                    <g filter={fillFilter}>{domToReact([firstChild], htmlParserOptions)}</g>
                                </g>
                                {domToReact(rest, htmlParserOptions)}
                            </>
                        );
                    }
                    // Ensure other links open in a new tab
                    return (
                        <a key={index} {...attributesToProps(domNode.attribs)} target='_blank'>
                            {domToReact(domNode.children, htmlParserOptions)}
                        </a>
                    );
                }
            }
            return reactNode;
        }
    };

    return parse(svgString, htmlParserOptions);
};

export const getObjectHealthUrl = (href?: string | null) => {
    try {
        href = !href ? '/' : href.toString();

        if (href.startsWith('/')) {
            return new URL(href, window.location.origin); // relative
        }

        try {
            return new URL(href);
        } catch {
            // Probably missing the protocol (e.g. www.bbc.co.uk) so assume
            // https to at least give the chance of generating a valid link
            return new URL('https://' + href);
        }
    } catch {
        return new URL('about:blank'); // let's not allow an invalid link to blow everything up!
    }
};

/**
 * Returns sourceId or id for health object href, if any
 */
export const getAnchorHealthId = (pathname: string, searchParams: URLSearchParams) => {
    if (pathname.includes('/dash-') || pathname.includes('/space-') || pathname.includes('/drilldown/node')) {
        // Users often copy the workspace home URL which ends in /overview, lets remove this if so
        const path = pathname.endsWith('/overview') ? pathname.substring(0, pathname.length - 9) : pathname;

        const [sourceId] = path.split('/').slice(-1);

        if (sourceId.startsWith('dash-') || sourceId.startsWith('space-')) {
            return { sourceId };
        } else if (sourceId.startsWith('node-') || sourceId.startsWith('con-')) {
            return { id: sourceId };
        } else {
            // legacy drilldown url, need to extract from search parameters
            const id = searchParams.get('id');
            if (id) {
                return { id };
            }
        }
    }
};

export const getStateInfo = (state: string) => {
    switch (state) {
        case 'error':
            return { state, colour: 'var(--statusErrorPrimary)', opacity: 0.95, icon: faXmarkCircle };
        case 'warning':
            return { state, colour: 'var(--statusWarningPrimary)', opacity: 0.95, icon: faExclamationCircle };
        case 'success':
            return { state, colour: 'var(--statusHealthyPrimary)', opacity: 0.95, icon: faCheckCircle };
        default:
            return { state: 'unknown', colour: 'var(--statusUnknownPrimary)', opacity: 1, icon: faQuestionCircle };
    }
};
