import { ChoiceChips, ChoiceChipsProps } from 'components/forms/choiceChips/ChoiceChips';
import Field, { FormFieldProps } from '../Field';

type FieldChoiceChipsProps = FormFieldProps & ChoiceChipsProps;

export const FieldChoiceChips: React.FC<FieldChoiceChipsProps> = ({
    name,
    label,
    options,
    isMulti,
    disabled,
    ...props
}) => {
    return (
        <Field label={label} htmlFor={name} {...props}>
            <ChoiceChips name={name} options={options} isMulti={isMulti} disabled={disabled} />
        </Field>
    );
};
