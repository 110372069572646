import Tooltip from 'components/tooltip/Tooltip';
import useOverflowing from 'lib/useOverflowing';

interface TruncatedTextProps {
    title: string;
    element?: ElementType;
    className?: string;
    tooltipClassName?: string;
    tooltipAsChild?: boolean;
    placement?: React.ComponentProps<typeof Tooltip>['placement'];
    disabled?: boolean;
}

type ElementType = 'div' | 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

/**
 * @param title Text for the tooltip
 * @param element Element tag
 * @param className Optional className
 * @returns A component that displays a tooltip if there isn't enough horizontal space (truncating the content)
 */
export const TruncatedText: React.FC<TruncatedTextProps> = ({
    title,
    className = '',
    element = 'p',
    tooltipClassName = '',
    tooltipAsChild = false,
    children,
    disabled,
    placement
}) => {
    const { ref, isOverflowing } = useOverflowing<HTMLDivElement>();
    const Element = element;

    return (
        <Tooltip
            title={title}
            disabled={!disabled ? !isOverflowing : true}
            className={className}
            tooltipClassName={tooltipClassName}
            asChild={tooltipAsChild}
            placement={placement}
        >
            <Element ref={ref} className='block max-h-full truncate' aria-label={title}>
                {children || title}
            </Element>
        </Tooltip>
    );
};
