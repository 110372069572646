import { FIRST_DASHBOARD } from '@squaredup/constants';
import LoadingSpinner from 'components/LoadingSpinner';
import { useDashboardsForWorkspace } from 'queries/hooks/useDashboardsForWorkspace';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { flattenedDashboardsSortedByWorkspaceOrder } from 'queries/utils/dashboardSorted';
import { Navigate, useLocation, useParams } from 'react-router';
import { useEnsureCorrectCurrentWorkspaceId } from 'ui/hooks/useEnsureCorrectCurrentWorkspaceId';

export const RedirectToWorkspaceDefaultPage = () => {
    const { id: workspaceId } = useParams();
    const { search } = useLocation();

    useEnsureCorrectCurrentWorkspaceId(workspaceId);

    const { data: workspace, isLoading: isLoadingWorkspace } = useWorkspace(workspaceId);
    const { data: dashboards, isLoading: isLoadingDashboards } = useDashboardsForWorkspace(workspaceId, {
        select: flattenedDashboardsSortedByWorkspaceOrder(workspace?.data?.properties?.dashboardIdOrder)
    });

    const isLoading = isLoadingWorkspace || isLoadingDashboards;

    const isUserPreferenceGoToFirstItem = workspace?.data?.properties?.landingPage === FIRST_DASHBOARD;

    const [firstDashboard] = dashboards ?? [];
    const firstDashboardLink = firstDashboard?.id && `/dashboard/${firstDashboard?.id}`;

    if (isLoading) {
        return <LoadingSpinner className='mt-8' />;
    }

    if (isUserPreferenceGoToFirstItem && firstDashboardLink) {
        return <Navigate to={{ pathname: firstDashboardLink, search }} replace={true} />;
    }

    return <Navigate to={{ pathname: `/workspace/${workspaceId}/overview`, search }} replace={true} />;
};
