import { InputHTMLAttributes, forwardRef } from 'react';

export const Radio = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => (
    <input
        type='radio'
        ref={ref}
        className='box-content align-middle w-disabledRadio h-disabledRadio border-outlineTertiary text-componentActive bg-componentActive focus:ring-0 focus:ring-offset-0 checked:focus:bg-componentActive checked:w-radioBullet checked:h-radioBullet checked:border-radioBorder checked:bg-none checked:bg-componentActive checked:border-primaryButtonBackground disabled:bg-transparent checked:disabled:bg-none checked:disabled:bg-componentActive checked:disabled:border-primaryButtonBackground checked:hover:border-primaryButtonBackgroundHover checked:focus:border-primaryButtonBackgroundHover disabled:border-textDisabled disabled:pointer-events-none disabled:checked:border-primaryButtonBackgroundDisabled disabled:checked:bg-componentDisabled'
        {...props}
    />
));
