import Text from '@/components/Text';
import { isPresetOf } from '@squaredup/data-streams';
import LoadingSpinner from 'components/LoadingSpinner';
import { ResizablePanelResizeHandle } from 'components/ResizablePanelResizeHandle';
import { ScrollArea } from 'components/scrollArea';
import { useMemo, useRef, type ComponentRef, type FC } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { useTileEditorState } from 'ui/editor/dataStream/TileEditor/state/TileEditorStateProvider';
import { useDataStreamDefinition } from '../../hooks/useDataStreamDefinition';
import { useDataStreamTemplate } from '../../hooks/useDataStreamTemplate';
import { DataStreamUserConfiguredForm } from '../DataStreamUserConfiguredForm';
import { ConfigurationPresetList } from './ConfigurationPresetList';
import { DataStreamConfigureForm } from './DataStreamConfigureForm';

export const DataStreamConfigurationStep: FC = () => {
    const {
        dataStream,
        parentDataStream,
        pluginConfig,
        plugin,
        formTemplate,
        formDefaultValues,
        streamDefinitions,
        isLoading: isLoadingDataStream
    } = useDataStreamTemplate();
    const { dispatch } = useTileEditorState();

    // We want to show the read-only form data if the dataStream is a legacy configured stream
    const { data: sourceDataStream, isLoading: isLoadingSourceDataStream } = useDataStreamDefinition(
        dataStream?.sourceTemplate
    );

    const panelGroupRef = useRef<ComponentRef<typeof PanelGroup>>(null);

    const resetPanelLayout = () => {
        if (!panelGroupRef.current) {
            return;
        }
        panelGroupRef.current.setLayout([{ sizePercentage: 33 }, { sizePercentage: 67 }]);
    };

    const hasOrIsPreset = useMemo(
        () =>
            dataStream != null &&
            (dataStream.definition.presetOf != null ||
                streamDefinitions.some((preset) => isPresetOf(dataStream, preset))),
        [dataStream, streamDefinitions]
    );

    const isLoading = isLoadingDataStream || isLoadingSourceDataStream;

    if (isLoading || dataStream == null) {
        return (
            <div className='flex items-center justify-center w-full h-full'>
                <LoadingSpinner />
            </div>
        );
    }

    const manualConfigApply = dataStream.definition.manualConfigApply || parentDataStream?.definition.manualConfigApply;

    return (
        <div className='flex flex-col h-full pl-6 pr-5 text-sm py-7'>
            <Text.H3 className='text-textPrimary'>Parameters</Text.H3>
            {sourceDataStream ? (
                <DataStreamUserConfiguredForm
                    dataStream={dataStream!}
                    sourceDataStream={sourceDataStream}
                    formTemplate={sourceDataStream.template}
                    defaultValues={dataStream?.definition.dataSourceConfig as Record<string, any>}
                    streamDefinitions={streamDefinitions}
                    plugin={plugin}
                    pluginConfig={pluginConfig}
                />
            ) : hasOrIsPreset ? (
                <PanelGroup ref={panelGroupRef} direction='horizontal' className='grid h-full gap-2'>
                    <Panel collapsible={true} minSizePixels={200} defaultSizePercentage={33}>
                        <ScrollArea className='h-full'>
                            <div className='flex flex-col gap-4 pt-4'>
                                <span className='text-textSecondary'>
                                    Select from a preset below, or configure manually.
                                </span>
                                <ConfigurationPresetList
                                    dataStreams={streamDefinitions}
                                    selectedDataStream={dataStream}
                                    onChange={(ds) => {
                                        dispatch({ type: 'selectDataStream', dataStreamId: ds.id });
                                    }}
                                />
                            </div>
                        </ScrollArea>
                    </Panel>
                    <ResizablePanelResizeHandle direction='horizontal' onResetLayout={resetPanelLayout} />
                    <Panel collapsible={true} minSizePixels={200}>
                        <div className='h-full pl-8 border-l-2 border-dividerTertiary'>
                            <DataStreamConfigureForm
                                key={dataStream.id} // Reset the form state when the selected data stream changes
                                formTemplate={formTemplate}
                                formDefaultValues={formDefaultValues}
                                streamDefinitions={streamDefinitions}
                                plugin={plugin}
                                pluginConfig={pluginConfig}
                                manualConfigApply={manualConfigApply}
                            />
                        </div>
                    </Panel>
                </PanelGroup>
            ) : (
                <DataStreamConfigureForm
                    key={dataStream.id} // Reset the form state when the selected data stream changes
                    formTemplate={formTemplate}
                    formDefaultValues={formDefaultValues}
                    streamDefinitions={streamDefinitions}
                    plugin={plugin}
                    pluginConfig={pluginConfig}
                    manualConfigApply={manualConfigApply}
                />
            )}
        </div>
    );
};
