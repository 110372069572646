import { Button } from '@/components/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import type { DashboardVariable } from '@squaredup/dashboards';
import LoadingSpinner from 'components/LoadingSpinner';
import { ModalButtons } from 'components/Modal';
import { useDashboardContext } from 'contexts/DashboardContext';
import { cloneDeep } from 'lodash';
import { useHandleSave } from 'pages/dashboard/hooks/useHandleSave';
import { CreateEditScope } from 'pages/scope/CreateEditScope';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { perspectiveQueryKeys } from 'queries/queryKeys/perspectiveKeys';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { AddVariable, UpdateVariable } from 'services/VariableService';
import { useCurrentWorkspaceId } from 'services/WorkspaceUtil';
import { DashboardVariableDefaultSelection } from './DashboardVariableDefaultSelection';
import { DashboardVariableObjectVariableFields } from './DashboardVariableObjectVariableFields';
import { VariableFormData, variableFormSchema } from './utils/getVariableFromFormData';

interface DashboardVariableFormProps {
    existingVariable?: DashboardVariable;
    onClose: () => void;
}
export const DashboardVariableForm: React.FC<DashboardVariableFormProps> = ({ existingVariable, onClose }) => {
    const { dashboard, setVariables } = useDashboardContext();
    const queryClient = useQueryClient();

    const [isScopeModalOpen, setIsScopeModalOpen] = useState(false);

    const formProps = useForm<VariableFormData>({
        defaultValues: {
            name: existingVariable?.name || 'Objects',
            scope: existingVariable?.scopeId || undefined,
            type: 'object',
            default: existingVariable?.default || 'all',
            allowMultipleSelection: existingVariable?.allowMultipleSelection || false
        },
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: zodResolver(variableFormSchema)
    });

    const {
        handleSubmit,
        setValue,
        formState: { isValid, isSubmitting }
    } = formProps;

    const { mutate: updatedDashboard } = useHandleSave();

    const workspaceId = useCurrentWorkspaceId();

    const onSubmit = async (data: VariableFormData) => {
        let id = existingVariable?.id || '';

        if (existingVariable) {
            await UpdateVariable(existingVariable.id, {
                ...data,
                dashboardId: dashboard.id
            });
        } else {
            const newVariable = await AddVariable(workspaceId, {
                ...data,
                dashboardId: dashboard.id
            });
            id = newVariable.id;
        }

        const variables = [
            {
                id,
                name: data.name,
                scopeId: data.scope,
                selectedAll: data.default === 'all',
                selectedObjects: [],
                default: data.default,
                allowMultipleSelection: data.default === 'all' ? false : data.allowMultipleSelection
            }
        ];

        queryClient.setQueryData(dashboardQueryKeys.variables(dashboard.id), variables);
        queryClient.invalidateQueries(perspectiveQueryKeys.all);

        setVariables?.(variables);

        const newDashboard = cloneDeep(dashboard);
        if (existingVariable?.scopeId !== data.scope) {
            // If the scope has changed, update any tiles using the variable to its new scope
            newDashboard.content.contents.forEach((value) => {
                if (
                    'variables' in value.config &&
                    (value.config.variables?.length ?? 0) > 0 &&
                    value.config.scope &&
                    'scope' in value.config.scope
                ) {
                    value.config.scope.scope = data.scope;
                }
            });
        }

        updatedDashboard({
            ...newDashboard,
            // Currently only one variable per dashboard
            variables: variables.map((v) => v.id)
        });

        onClose();
    };

    return (
        <>
            <FormProvider {...formProps}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-col px-8 space-y-4 mt-7'>
                        <DashboardVariableObjectVariableFields setIsScopeModalOpen={setIsScopeModalOpen} />
                        <DashboardVariableDefaultSelection />
                    </div>

                    <ModalButtons>
                        <div className='flex flex-col flex-1 space-y-2'>
                            <a
                                className='self-start text-textLink'
                                href='https://docs.squaredup.com/first-steps/dashboards/dashboard-variables'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                What are dashboard variables?
                            </a>
                        </div>

                        <Button variant='tertiary' type='button' disabled={isSubmitting} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type='submit' disabled={isSubmitting || !isValid}>
                            {isSubmitting && <LoadingSpinner size={18} className='mr-3' />}
                            {existingVariable ? 'Update' : 'Add'}
                        </Button>
                    </ModalButtons>
                </form>
            </FormProvider>
            {isScopeModalOpen && (
                <CreateEditScope
                    onClose={(modifiedScope) => {
                        if (modifiedScope) {
                            setValue('scope', modifiedScope.id, { shouldValidate: true });
                            queryClient.invalidateQueries(scopeQueryKeys.workspace(workspaceId));
                        }

                        setIsScopeModalOpen(false);
                    }}
                />
            )}
        </>
    );
};
