import { BuiltInGroups } from '@squaredup/constants';
import { AxiosError } from 'axios';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import Form from 'components/forms/form/Form';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ifNotOutside, ModalButtons } from 'components/Modal';
import type { UserGroupModel } from 'dynamo-wrapper';
import { difference } from 'lodash';
import { useState } from 'react';
import { MutationFunction, useMutation, useQuery, useQueryClient } from 'react-query';
import { ACL_USER_GROUPS_QUERY_KEY } from 'services/AccessControlService';
import {
    AddUser,
    AddUserToGroup,
    EDITABLE_USER_GROUPS,
    GetEditableGroups,
    GetGroupsForUser,
    RemoveUserFromGroup,
    TENANT_USER_GROUPS_QUERY_KEY,
    TENANT_USERS_QUERY_KEY,
    USER_GROUPS_QUERY_KEY
} from 'services/UserService';
import { ValidationValue } from 'types/ValidationValue';

type CreateEditUserModalProperties = {
    user: any;
    onClose: () => void;
};

function CreateEditUserModal({ user, onClose }: CreateEditUserModalProperties) {
    const queryClient = useQueryClient();
    const isEditing = Boolean(user);
    const [error, setError] = useState<string | undefined>();

    const { data: userGroups, isLoading: isLoadingUserGroups } = useQuery(EDITABLE_USER_GROUPS, GetEditableGroups);
    const {
        data: groupsUserBelongsTo,
        isLoading: isLoadingGroupsUserBelongsTo,
        isIdle
    } = useQuery(
        [USER_GROUPS_QUERY_KEY, user],
        async () => {
            const allGroupsForUser = await GetGroupsForUser(user);
            return allGroupsForUser.filter((group) => group.name !== BuiltInGroups.Everyone.name);
        },
        { enabled: isEditing }
    );

    const { mutateAsync: saveUser, isLoading } = useMutation(
        (isEditing
            ? (data: any) => updateUser(user, groupsUserBelongsTo!, data.groupIds)
            : AddUser) as unknown as MutationFunction<any, string>,
        { 
            onSuccess: () => reloadList(),
            onError: (axiosError: AxiosError<any>) => {
                setError(axiosError.response?.data.error || axiosError.message);
            }
        }
    );

    function reloadList() {
        queryClient.removeQueries(USER_GROUPS_QUERY_KEY);
        queryClient.invalidateQueries(TENANT_USERS_QUERY_KEY);
        queryClient.invalidateQueries(TENANT_USER_GROUPS_QUERY_KEY);
        queryClient.invalidateQueries(ACL_USER_GROUPS_QUERY_KEY);
    }

    const handleSubmit = async (data: any) => {
        
        const { groupIds, ...formData } = data;

        const processedData = {
            ...formData,
            ...(groupIds && { groupIds: groupIds.map((group: any) => group.value) })
        };

        await saveUser(processedData);
        onClose();
    };

    const formDefaults = isEditing
        ? {
              groupIds: groupsUserBelongsTo?.map((group) => ({
                  label: group.displayName,
                  value: group.id
              }))
          }
        : {};

    return (
        <Modal
            title={isEditing ? 'Edit user' : 'Add user'}
            close={ifNotOutside(onClose)}
            fullWidth
            maxWidth='max-w-3xl'
        >
            {!isLoadingGroupsUserBelongsTo || isIdle ? (
                <Form submit={handleSubmit} defaultValues={formDefaults} resetOnSubmit={true}>
                    {(isValid, isSubmitting) => (
                        <>
                            <div className='px-8'>
                                {!isEditing && (
                                    <Field.Input
                                        name='email'
                                        label='Email'
                                        type='email'
                                        placeholder='user@company.com'
                                        validation={{
                                            required: true,
                                            maxLength: { value: 256 } as ValidationValue
                                        }}
                                    />
                                )}

                                <Field.Input
                                    name='groupIds'
                                    label='User groups'
                                    type='autocomplete'
                                    placeholder='Select one or more user groups'
                                    options={( userGroups ? userGroups : []).map(
                                        ({ displayName, id }) => ({ label: displayName, value: id })
                                    )}
                                    isLoading={isLoadingUserGroups}
                                />
                            </div>
                            
                            {error && <p className='mt-2 px-7 text-statusErrorPrimary'>{error}</p>}
                            <ModalButtons>
                                <Button type='button' variant='tertiary' onClick={() => onClose()}>
                                    Cancel
                                </Button>

                                <Button type='submit' disabled={isSubmitting || !isValid}>
                                    {(isSubmitting || isLoading) ? <LoadingSpinner /> : 'Save'}
                                </Button>
                            </ModalButtons>
                        </>
                    )}
                </Form>
            ) : (
                <div className='px-8 mb-8'>
                    <LoadingSpinner />
                </div>
            )}
        </Modal>
    );
}

async function updateUser(user: string, original: UserGroupModel[], updatedGroups: string[]) {
    const originalGroups = original.map((originalGroup) => originalGroup.id);
    const groupsToAdd = difference(updatedGroups, originalGroups);
    const groupsToRemove = difference(originalGroups, updatedGroups);

    const groupUpdates: Promise<void>[] = [];
    groupsToAdd?.forEach((groupToAdd) => groupUpdates.push(AddUserToGroup(groupToAdd!, user)));
    groupsToRemove?.forEach((groupToRemove) => groupUpdates.push(RemoveUserFromGroup(groupToRemove!, user)));
    return Promise.all(groupUpdates);
}

export default CreateEditUserModal;
