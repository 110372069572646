import clsx from 'clsx';
import { SearchMenuComponent } from 'components/Search';
import { GlobalSearchResult } from './GlobalSearch';

export const MenuComponent: SearchMenuComponent<GlobalSearchResult> = ({ children, visible = true }) => {
    return (
        <div
            className={clsx(
                'left-0 right-0 z-50 top-full bg-tileBackground rounded-b-input outline-outlinePrimary outline-1 outline',
                visible ? 'pointer-events-auto' : 'invisible pointer-events-none'
            )}
        >
            <div className='flex overflow-hidden' style={{ maxHeight: '750px', minHeight: '60vh' }}>
                <div
                    className='w-full mb-4 overflow-auto scrollbar-thin scrollbar-thumb-statusUnknownPrimary scrollbar-track-transparent'
                    style={{ maxHeight: '80vh', minHeight: '60vh', flexShrink: 0.5 }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};
