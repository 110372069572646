import { MutationFunction, useMutation, useQueryClient } from 'react-query';

/**
 *
 * @param func The function to invoke to perform mutation
 * @param queryKey react-query data key
 * @param postSuccessFunc optional function rto be invoked onSuccess
 * @returns {function(): void}
 */
export default function useMutationWithSuccess(
    func: MutationFunction<unknown, void>,
    queryKey: (string | null)[],
    postSuccessFunc?: () => void
) {
    const queryClient = useQueryClient();
    const mutation = useMutation(func, {
        onSuccess: () => {
            if (queryKey) {
                queryClient.invalidateQueries(queryKey);
                postSuccessFunc?.(); // Invoke func if provided
            }
        }
    });

    return () => mutation.mutate();
}
