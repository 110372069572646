import Text from '@/components/Text';
import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { Action } from 'pages/components/ApplicationTable/types';
import { FC } from 'react';
import { ClientSideCustomType } from 'services/CustomTypesService';
import TruncateWithTooltip from '../TruncateWithTooltip';

interface CustomTypesTableProps {
    isFeatureAvailable: boolean;
    customTypes: ClientSideCustomType[];
    onEdit: (customType: ClientSideCustomType) => void;
    onDelete: (workspace: ClientSideCustomType) => void;
}

const CustomTypesApplicationTable = ApplicationTable<ClientSideCustomType, string>();

const CustomTypesTable: FC<CustomTypesTableProps> = ({ customTypes, onEdit, onDelete, isFeatureAvailable }) => {
    const columns: ColumnDef<ClientSideCustomType>[] = [
        {
            id: 'name',
            header: 'Name',
            cell: ({ row }) => {
                return (
                    <div className='flex items-center gap-2'>
                        <TruncateWithTooltip title={row.original.displayName || ''}>
                            <div className='truncate'>{row.original.displayName}</div>
                        </TruncateWithTooltip>
                        {!isFeatureAvailable && (
                            <Text.SmallBody className='text-textSecondary'>(disabled)</Text.SmallBody>
                        )}
                    </div>
                );
            },
            size: 300,
            accessorKey: 'displayName'
        },
        {
            id: 'type',
            header: 'Type',
            cell: ({ row }) => row.original.config.type,
            size: 300,
            accessorKey: 'config.type'
        },
        {
            id: 'icon',
            header: 'Icon',
            cell: ({ row }) => {
                return (
                    <TruncateWithTooltip title={row.original.config.icon.toString() || ''}>
                        <FontAwesomeIcon icon={row.original.config.icon} className='h-6 mr-2 text-textPrimary' />
                        <div className='truncate'>{row.original.config.icon.toString()}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 300,
            accessorKey: 'config.icon'
        },
        {
            id: 'singular',
            header: 'Singular Name',
            cell: ({ row }) => {
                return (
                    <TruncateWithTooltip title={row.original.config.singular || ''}>
                        <div className='truncate'>{row.original.config.singular}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 300,
            accessorKey: 'config.singular'
        },
        {
            id: 'plural',
            header: 'Plural Name',
            cell: ({ row }) => {
                return (
                    <TruncateWithTooltip title={row.original.config.plural || ''}>
                        <div className='truncate'>{row.original.config.plural}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 300,
            accessorKey: 'config.plural'
        }
    ];

    const actions: Action[] = [
        {
            action: onEdit,
            visible: (_) => isFeatureAvailable,
            icon: faPencil,
            dataTestId: 'editCustomType',
            tooltip: 'Edit custom type'
        },
        {
            action: onDelete,
            icon: faTrash,
            dataTestId: 'deleteCustomType',
            tooltip: 'Delete custom type'
        }
    ];

    return (
        <div className='flex flex-col flex-1 w-full h-full min-h-0'>
            <CustomTypesApplicationTable
                config={{
                    actions,
                    noDataMessage: 'There are no custom types configured.'
                }}
                columns={columns}
                data={customTypes}
            />
        </div>
    );
};

export default CustomTypesTable;
