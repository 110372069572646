import { Checkbox } from '@/components/forms/Checkbox';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Popover from '@radix-ui/react-popover';
import { FilterCriteria } from 'pages/monitoring/common';

export const WorkspaceFilterPanel: React.FC<{
    id: string;
    filterCriteria: FilterCriteria;
    setFilterCriteria: React.Dispatch<React.SetStateAction<FilterCriteria>>;
}> = ({ id, filterCriteria, setFilterCriteria }) => {
    return (
        <Popover.Root>
            <Popover.Trigger asChild aria-label='Filter workspace network'>
                <span className='absolute inset-0 flex items-center justify-center text-textPrimary'>
                    <FontAwesomeIcon icon={faFilter} />
                </span>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    side='bottom'
                    align='start'
                    alignOffset={45}
                    sideOffset={-27}
                    avoidCollisions={true}
                    className='ml-2 border text-textPrimary border-dividerPrimary PopoverContent bg-componentBackgroundPrimary w-72'
                >
                    <p className='border-b py-autocomplete border-dividerPrimary mb-xxxs px-xs'>Filter options</p>
                    <div className='flex items-center space-x-4 select-none px-xs py-autocomplete'>
                        <Checkbox
                            name={`showIssuesOnly-${id}`}
                            checked={filterCriteria.showIssuesOnly}
                            onCheckedChange={() => {
                                setFilterCriteria((currentCriteria) => ({
                                    ...currentCriteria,
                                    showIssuesOnly: !currentCriteria.showIssuesOnly
                                }));
                            }}
                        />
                        <label htmlFor={`showIssuesOnly-${id}`} className='cursor-pointer leading-input'>
                            Show issues only
                        </label>
                    </div>
                    <div className='flex items-center space-x-4 select-none px-xs py-autocomplete'>
                        <Checkbox
                            name={`showHealthyMonitors-${id}`}
                            checked={filterCriteria.showHealthyMonitors}
                            onCheckedChange={() => {
                                setFilterCriteria((currentCriteria) => ({
                                    ...currentCriteria,
                                    showHealthyMonitors: !currentCriteria.showHealthyMonitors
                                }));
                            }}
                        />
                        <label htmlFor={`showHealthyMonitors-${id}`} className='cursor-pointer leading-input'>
                            Show healthy monitors
                        </label>
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};
