import { faCaretDown, faCaretRight, faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useState } from 'react';
import { DataMatchCriterion } from './dataMatchCriteria';

interface DataCriteriaListProps {
    criteria: DataMatchCriterion[];
}

interface DataCriteriaListItemProps {
    criterion: DataMatchCriterion;
}

export const DataCriteriaListItem: React.FC<DataCriteriaListItemProps> = ({ criterion }) => {
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded(!expanded);
    return (
        <div className='mb-2 text-sm'>
            <button 
                disabled={!criterion.additionalInformation} onClick={toggleExpanded}
                className={clsx('leading-none flex items-center', criterion.success ? 'text-statusHealthyPrimary' : 'text-statusErrorPrimary pl-0.5')}
            >
                <span className='mr-2'>
                    <FontAwesomeIcon icon={criterion.success ? faCheck : faXmark} />
                </span>
                {criterion.message}
                {criterion.additionalInformation && (
                    <span className='pl-2'>
                        <FontAwesomeIcon icon={expanded ? faCaretDown : faCaretRight} />
                    </span>
                )}
            </button>
            {expanded && <pre className='my-2 ml-5 whitespace-pre'>{criterion.additionalInformation}</pre>}
        </div>
    );
};

export const DataCriteriaList: React.FC<DataCriteriaListProps> = ({ criteria }) => (
    <div className='text-left w-[32rem] mx-auto'>
        {criteria.map((criterion) => (
            <DataCriteriaListItem key={criterion.message} criterion={criterion} />
        ))}
    </div>
);
