import { inferDisplayNames } from '@squaredup/data-streams';

type config = { excludeProperties?: string[] };

export default function graphNodeToTableData(node: any, config?: config) {
    const excludeProperties = config && Array.isArray(config.excludeProperties) ? config.excludeProperties : [];
    const isCanonical = node['__canonicalType'];

    // Exclude all properties early so that we can generate nice display names without lots of
    // extra context due to clashes
    const keys = Object.keys(node).filter(
        (key) =>
            !(
                key.startsWith('__') ||
                (isCanonical && key.includes('_')) ||
                // Exclude the property from the table data if specified by config
                excludeProperties.includes(key)
            )
    );

    return inferDisplayNames(keys)
        .map((displayName, index) => {
            const data = node[keys[index]];
            // Unwrap array if there is only a single value for the property
            // to improve sorting experience
            const value = Array.isArray(data) && data.length === 1 ? data[0] : data;
            return {
                prop: displayName,
                rawProp: keys[index],
                value
            };
        })
        .sort((a, b) => a.prop.localeCompare(b.prop, undefined, { sensitivity: 'base' }));
}
