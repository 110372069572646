import { useCallback, useEffect, useRef } from 'react';

// Returns a function to wrap your async calls in to avoid updating after the component has been unmounted;
const useSafeStateChange = () => {
    const isMounted = useRef<boolean>(false);

    useEffect(() => {
        isMounted.current = true;
  
      return () => {
        isMounted.current = false;
      };
    }, []);

    return useCallback(
        <T>(promise: Promise<T>) =>
            new Promise<T>((resolve, reject) => {
                const onValue = (value: any) => {
                    isMounted.current && resolve(value);
                };
                const onError = (error: any) => {
                    isMounted.current && reject(error);
                };
                promise.then(onValue, onError);
            }),
        []
    );
};

export default useSafeStateChange;
