import TileContext from 'contexts/TileContext';
import { TileType, tileTypes, TileTypes } from 'dashboard-engine/constants';
import { useContext } from 'react';
import { TileTypeButtons } from './TileTypeButtons';
import { TileTypeDropdown } from './TileTypeDropdown';

export interface TileTypeSelectorComponentProps {
    tileTypes: TileType[];
    onTileTypeSelected: (tileType: TileTypes) => void;
}

export const TileTypeSelector: React.FC<{
    setTileType: (type: TileTypes) => void;
}> = ({ setTileType }) => {
    const { onEdit } = useContext(TileContext);

    const setTileTypeAndEdit = (tileType: TileTypes) => {
        setTileType(tileType);
        onEdit();
    };

    const tiles = tileTypes();

    return (
        <div className='flex items-center justify-center h-full tileTypeControls'>
            <div className='tileTypeControlsInline'>
                <TileTypeButtons tileTypes={tiles} onTileTypeSelected={setTileTypeAndEdit} />
            </div>
            <div className='tileTypeControlsDropdown'>
                <TileTypeDropdown tileTypes={tiles} onTileTypeSelected={setTileTypeAndEdit} />
            </div>
        </div>
    );
};
