import Text from '@/components/Text';
import { BuiltInGroups, isBuiltInGroup } from '@squaredup/constants';
import { ConfirmationPrompt } from 'components/ConfirmationPrompt';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import type { UserGroupModel, UserModel } from 'dynamo-wrapper';
import { sortBy } from 'lodash';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ACL_USER_GROUPS_QUERY_KEY } from 'services/AccessControlService';
import Auth from 'services/Auth';
import { USER_COUNT_KEY } from 'queries/queryKeys/usageQueryKeys';
import {
    DeleteGroup,
    DeleteUser,
    GetGroupsWithMembers,
    GetUsers,
    TENANT_USERS_QUERY_KEY,
    TENANT_USER_GROUPS_QUERY_KEY,
    USER_GROUPS_QUERY_KEY
} from 'services/UserService';
import { SettingsTemplate } from '../SettingsTemplate';
import CreateEditUserGroupModal from './CreateEditUserGroupModal';
import CreateEditUserModal from './CreateEditUserModal';
import UserGroupsTable from './UserGroupsTable';
import UsersTable from './UsersTable';
import { useTier } from 'queries/hooks/useTier';
import { isFeatureEnabled, isUnderLimit } from '@squaredup/tenants';
import { LimitReachedBanner } from 'components/plans/LimitReachedBanner';
import { FeatureUnavailablePill } from 'components/plans/FeatureUnavailablePill';

export interface UserGroup extends UserGroupModel {
    userCount: number;
}

export interface User extends UserModel {
    lastLoginDate: Date | null;
}

const filterSquaredUpUsers = (usersToFilter: Awaited<ReturnType<typeof GetUsers>>) =>
    usersToFilter.filter((user) => !user.name.endsWith('@squaredup.com'));

function Users() {
    const queryClient = useQueryClient();

    const [userBeingEdited, setUserBeingEdited] = useState<any>();
    const [userBeingDeleted, setUserBeingDeleted] = useState<any>();
    const [userModalOpen, setUserModalOpen] = useState(false);

    const { data: tier, isLoading: isLoadingTier } = useTier();

    const {
        data: users,
        isLoading: isLoadingUsers,
        isSuccess: isUsersSuccess
    } = useQuery(TENANT_USERS_QUERY_KEY, GetUsers);

    const reloadUsersList = () => {
        queryClient.removeQueries([USER_GROUPS_QUERY_KEY]);
        queryClient.invalidateQueries([TENANT_USERS_QUERY_KEY]);
        queryClient.invalidateQueries([USER_COUNT_KEY]);
        queryClient.invalidateQueries([TENANT_USER_GROUPS_QUERY_KEY]);
    };

    const usersMutationOptions = { onSuccess: reloadUsersList };
    const deleteUserMutation = useMutation(DeleteUser, usersMutationOptions);
    const currentUser = Auth.user?.name.toLowerCase().trim();

    const sortedUsers: User[] = Array.isArray(users)
        ? users
              .map((user) => ({
                  ...user,
                  lastLoginDate: user.lastLogin ? new Date(user.lastLogin) : null
              }))
              .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
        : [];

    const userCount = (isUsersSuccess ? filterSquaredUpUsers(users) : []).length;
    const atUserLimit = tier === undefined || !isUsersSuccess || !isUnderLimit(tier, 'users', userCount);

    const handleOnUserEdit = (user: any) => {
        setUserBeingEdited(user);
        setUserModalOpen(true);
    };
    const handleOnUserDelete = (user: any) => setUserBeingDeleted(user);
    const canDeleteUser = (user: any) => currentUser !== user.id && !deleteUserMutation.isLoading;

    const [userGroupBeingEdited, setUserGroupBeingEdited] = useState<any>();
    const [userGroupBeingDeleted, setUserGroupBeingDeleted] = useState<any>();
    const [userGroupModalOpen, setUserGroupModalOpen] = useState(false);

    const reloadUserGroupsList = () => {
        queryClient.removeQueries(USER_GROUPS_QUERY_KEY);
        queryClient.invalidateQueries(TENANT_USERS_QUERY_KEY);
        queryClient.invalidateQueries(TENANT_USER_GROUPS_QUERY_KEY);
        queryClient.invalidateQueries(ACL_USER_GROUPS_QUERY_KEY);
    };

    const userGroupsMutationOptions = { onSuccess: reloadUserGroupsList };
    const deleteUserGroupMutation = useMutation(DeleteGroup, userGroupsMutationOptions);
    const { data: userGroups, isLoading: isLoadingUserGroups } = useQuery(TENANT_USER_GROUPS_QUERY_KEY, async () => {
        const groups: UserGroup[] = (await GetGroupsWithMembers()).map((group) => ({
            ...group,
            userCount: Array.isArray(group.users) ? group.users.length : 0
        }));
        return sortBy(groups, [
            (userGroup) => userGroup.name !== BuiltInGroups.Everyone.name,
            (userGroup) => !isBuiltInGroup(userGroup.name!),
            'displayName'
        ]);
    });

    const handleOnUserGroupEdit = (userGroup: any) => {
        setUserGroupBeingEdited(userGroup);
        setUserGroupModalOpen(true);
    };
    const handleOnUserGroupDelete = (userGroup: any) => setUserGroupBeingDeleted(userGroup);
    const canEditUserGroup = (userGroup: any) => userGroup.name !== BuiltInGroups.Everyone.name;
    const canDeleteUserGroup = (userGroup: any) => !isBuiltInGroup(userGroup.name);

    const accessControlEnabled = tier && isFeatureEnabled(tier, 'accessControl');

    return (
        <SettingsTemplate
            title='Users & Groups'
            description='Manage users and control their access by assigning them to groups.'
            learnMoreLink='https://squaredup.com/cloud/users'
            flex
        >
            <div className='flex flex-col flex-1 min-h-0'>
                <Text.H2>Users</Text.H2>
                {atUserLimit ? (
                    <LimitReachedBanner currentUsage={userCount} featureKey='users' container='page' content='subtle' className='mt-4 mb-7' />
                ) : (
                    <>
                        {users && (
                            <Text.Body className='mb-sm text-textSecondary'>
                                {`${userCount} User${userCount !== 1 ? 's' : ''}`}
                            </Text.Body>
                        )}
                    </>
                )}
                <div>
                    <Button onClick={() => setUserModalOpen(true)} variant='primary' data-testid='settings-users-addUser' disabled={atUserLimit}>
                        Add user
                    </Button>
                </div>
                <div className='flex flex-col min-h-0 mt-7 mb-xs'>
                    {isLoadingTier || isLoadingUsers ? (
                        <LoadingSpinner />
                    ) : (
                        <UsersTable
                            userGroups={userGroups ?? []}
                            users={sortedUsers ?? []}
                            onEdit={handleOnUserEdit}
                            onDelete={handleOnUserDelete}
                            canDelete={canDeleteUser}
                        />
                    )}
                </div>
            </div>
            <div className='flex flex-col flex-1 min-h-0' data-testid='groups-table-section'>
                <div className='flex items-center gap-3 mb-sm'>
                    <Text.H2>Groups</Text.H2>
                    <FeatureUnavailablePill featureKey='accessControl' />    
                </div>
                <div>
                    <Button onClick={() => setUserGroupModalOpen(true)} variant='primary' data-testid='settings-users-addGroup' disabled={!accessControlEnabled}>
                        Add user group
                    </Button>
                </div>
                <div className='flex flex-col min-h-0 mb-8 mt-7'>
                    {isLoadingUserGroups ? (
                        <LoadingSpinner />
                    ) : (
                        <UserGroupsTable
                            userGroups={userGroups ?? []}
                            onEdit={handleOnUserGroupEdit}
                            onDelete={handleOnUserGroupDelete}
                            canEdit={canEditUserGroup}
                            canDelete={canDeleteUserGroup}
                        />
                    )}
                </div>
            </div>

            {userModalOpen && (
                <CreateEditUserModal
                    user={userBeingEdited?.id}
                    onClose={() => {
                        setUserBeingEdited(undefined);
                        setUserModalOpen(false);
                    }}
                />
            )}

            {userBeingDeleted && (
                <ConfirmationPrompt
                    title={`Delete User: ${userBeingDeleted.id}`}
                    prompt='Are you sure you want to permanently delete this user?'
                    confirmButtonText='Delete'
                    confirmButtonVariant='destructive'
                    onConfirm={async () => {
                        await deleteUserMutation.mutateAsync(userBeingDeleted.id);
                        return reloadUsersList();
                    }}
                    onClose={() => setUserBeingDeleted(undefined)}
                />
            )}

            {userGroupModalOpen && (
                <CreateEditUserGroupModal
                    userGroup={userGroupBeingEdited}
                    onClose={() => {
                        setUserGroupBeingEdited(undefined);
                        setUserGroupModalOpen(false);
                    }}
                />
            )}

            {userGroupBeingDeleted && (
                <ConfirmationPrompt
                    title={`Delete User Group: ${userGroupBeingDeleted.displayName}`}
                    prompt='Are you sure you want to permanently delete this user group?'
                    confirmButtonText='Delete'
                    confirmButtonVariant='destructive'
                    onConfirm={async () => {
                        await deleteUserGroupMutation.mutateAsync(userGroupBeingDeleted.id!);
                        return reloadUserGroupsList();
                    }}
                    onClose={() => setUserGroupBeingDeleted(undefined)}
                />
            )}
        </SettingsTemplate>
    );
}

export default Users;
