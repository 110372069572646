import Template from '@/components/Template';
import { isFixedScope } from '@squaredup/data-streams';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import stableStringify from 'fast-json-stable-stringify';
import { usePageTitle } from 'lib/usePageTitle';
import { sortBy } from 'lodash';
import { useImportStatus } from 'pages/settings/plugins/useImportStatus';
import WorkspaceRequired, { withWorkspaceID } from 'pages/workspace/WorkspaceRequired';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { variableObjectQueryKeys } from 'queries/queryKeys/variableObjectKeys';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { useWorkspaceCanWrite } from 'services/AccessControlService';
import { Query } from 'services/GraphService';
import { ListPluginSourceConfigForWorkspace, PluginSourceConfig } from 'services/SourceConfigService';
import { ListScopes, SCOPES } from 'services/WorkspaceService';
import { getParsedScopeBindings, getParsedScopeQueryDetail } from 'utilities/getParsedScopeUtilities';
import { CreateEditScope } from './CreateEditScope';
import DeleteScopeModal from './DeleteScopeModal';
import ScopesTable from './ScopesTable';
import { ViewScopeModal } from './ViewScopeModal';

export type Scope = {
    id: string;
    displayName: string;
    workspaceId: string;
    data: {
        quickScope?: boolean;
        query: string;
        queryDetail: string;
        bindings: string;
        version?: number;
        scope?: string;
        isDependency?: boolean;
    };
};

const getObjectCount = async (query: string, bindings?: Record<string, unknown>) => {
    if (query) {
        return (
            await Query({
                gremlinQuery: `${query}.dedup().valueMap(true).count()`,
                ...(bindings && { bindings })
            })
        ).gremlinQueryResults[0];
    }

    return 0;
};

interface ScopePageProps {
    workspaceID: string;
}

const ScopePage: React.FC<ScopePageProps> = ({ workspaceID: currentWorkspaceID }) => {
    usePageTitle('Objects');
    const { id } = useParams();
    const queryClient = useQueryClient();
    const [scopeBeingDeleted, setScopeBeingDeleted] = useState<Scope>();
    const [scopeBeingEdited, setScopeBeingEdited] = useState<Scope>();
    const [scopeBeingViewed, setScopeBeingViewed] = useState<Scope>();
    const [scopeModalOpen, setScopeModalOpen] = useState(false);
    const { data: canWriteToWorkspace } = useWorkspaceCanWrite(currentWorkspaceID);
    const { acknowledgeFailedImports } = useImportStatus();
    const [searchParams, setSearchParams] = useSearchParams();

    const getQuickScopeDisplayName = (queryDetail: any) => {
        const types = queryDetail.types.length > 0 && queryDetail.types.map((type: any) => type.displayName).join(', ');
        return `${queryDetail.plugin?.displayName}${types ? ' - ' : ''}${types || ''}`;
    };

    const noneNames = ['', 'None'];
    const multipleNames = ['', 'Multiple'];
    const squaredUpNames = ['SquaredUp', 'SquaredUp'];

    const getDataSourceNames = async (
        linkedPlugins: PluginSourceConfig[],
        query: string,
        bindings?: Record<string, unknown>
    ) => {
        if (query) {
            const nodes = (
                await Query({
                    gremlinQuery: `${query}.dedup().valueMap(true)`,
                    ...(bindings && { bindings })
                })
            ).gremlinQueryResults;

            if (nodes.length > 0) {
                const configIds = nodes.map((node) => node.__configId[0]);
                const uniqueConfigIds = Array.from(new Set(configIds));

                if (uniqueConfigIds.length > 1) {
                    const plugins = uniqueConfigIds.map((configId) =>
                        linkedPlugins.find((plugin) => plugin.id === configId)
                    );
                    return plugins.every((p) => !p) ? squaredUpNames : multipleNames;
                } else {
                    const plugin = linkedPlugins.find(({ id: pluginId }) => pluginId === uniqueConfigIds[0]);
                    return plugin ? [plugin.plugin?.name, plugin.displayName] : squaredUpNames;
                }
            }
        }

        return noneNames;
    };

    const { data: scopes, isLoading } = useQuery([SCOPES, currentWorkspaceID], async () => {
        const unsortedScopes: Scope[] = await ListScopes(id || '');
        const linkedPlugins = await ListPluginSourceConfigForWorkspace(currentWorkspaceID);

        const promises = unsortedScopes.map(async (scope) => {
            const isQuickScope = scope.data.quickScope ?? false;
            const isDependency = scope.data.isDependency ?? false;

            const query = scope.data.query || '';
            const queryDetail = getParsedScopeQueryDetail(scope);
            const bindings = getParsedScopeBindings(scope);

            const fixedScope = isFixedScope(scope.data);
            const scopeType = fixedScope ? 'Fixed' : 'Dynamic';

            const displayName = isQuickScope ? getQuickScopeDisplayName(queryDetail) : scope.displayName;

            // [name, displayName]
            const names = isQuickScope
                ? [queryDetail.plugin.plugin.name, queryDetail.plugin.displayName]
                : isDependency
                ? squaredUpNames
                : await getDataSourceNames(linkedPlugins, query, bindings);

            const dataSourceName = names[0];
            const dataSourceDisplayName = names[1];

            let objectCount = await getObjectCount(query, bindings);

            // If the scope was created with objects that are no longer available
            // (i.e. the plugin has been removed), reset the scope's id list
            if (fixedScope && objectCount === 0) {
                scope.data.queryDetail = JSON.stringify({ ...queryDetail, ids: [] });
            }

            return {
                ...scope,
                displayName,
                dataSourceName,
                dataSourceDisplayName,
                scopeType,
                objectCount,
                canEdit,
                canDelete
            };
        });

        const mappedScopes = await Promise.all(promises);

        return sortBy(mappedScopes, (scope) => scope.displayName?.toLowerCase());
    });

    const safeScopes = stableStringify(scopes);

    const canEdit = (scope: { canEdit: boolean }) => Boolean(canWriteToWorkspace && scope.canEdit);
    const canDelete = (scope: { canDelete: boolean }) => Boolean(canWriteToWorkspace && scope.canDelete);

    useEffect(() => {
        acknowledgeFailedImports();
    }, [acknowledgeFailedImports, safeScopes]);

    const editId = searchParams.get('editScope');
    const viewId = searchParams.get('viewScope');
    useEffect(() => {
        if ((editId || viewId) && scopes && scopes.length > 0) {
            const editScope = scopes.find((s) => s.id === editId);

            if (editScope) {
                setScopeBeingEdited(editScope);
                setScopeModalOpen(true);
            }

            const viewScope = scopes.find((s) => s.id === viewId);

            if (viewScope) {
                setScopeBeingViewed(viewScope);
            }

            setSearchParams({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editId, viewId, safeScopes]);

    const handleOnDelete = (scope: Scope) => {
        setScopeBeingDeleted(scope);
    };

    const handleAddEdit = (scope: Scope) => {
        setScopeBeingEdited(scope);
        setScopeModalOpen(true);
    };

    const handleView = (scope: Scope) => {
        setScopeBeingViewed(scope);
    };

    return (
        <WorkspaceRequired>
            <Template
                title='Objects'
                description='Objects indexed by data sources can be added to collections which can be used as a filter when configuring dashboard tiles.'
                learnMoreLink='https://squaredup.com/cloud/scopes'
                flex
            >
                {isLoading && (
                    <span className='flex justify-center'>
                        <LoadingSpinner />
                    </span>
                )}

                {!isLoading && (
                    <div className='flex flex-col flex-1 min-h-0'>
                        {canWriteToWorkspace && (
                            <div>
                                <Button onClick={() => setScopeModalOpen(true)} data-testid='create-scopes'>
                                    Add collection
                                </Button>
                            </div>
                        )}

                        <div className='flex flex-col min-h-0 mt-4 mb-8'>
                            <ScopesTable
                                scopes={scopes}
                                onEdit={handleAddEdit}
                                onView={handleView}
                                onDelete={handleOnDelete}
                                canEdit={canEdit}
                                canDelete={canDelete}
                            />
                        </div>
                    </div>
                )}

                {scopeModalOpen && (
                    <CreateEditScope
                        onClose={(modifiedScope) => {
                            if (modifiedScope) {
                                queryClient.removeQueries([SCOPES, currentWorkspaceID]);
                                queryClient.removeQueries(scopeQueryKeys.workspace(currentWorkspaceID));
                                queryClient.removeQueries(variableObjectQueryKeys.all);
                            }
                            setScopeBeingEdited(undefined);
                            setScopeModalOpen(false);
                        }}
                        scope={scopeBeingEdited}
                        key='scope-add-edit'
                    />
                )}

                {scopeBeingViewed && (
                    <ViewScopeModal
                        scopeId={scopeBeingViewed.id}
                        scopeName={scopeBeingViewed.displayName}
                        canWriteToWorkspace={canWriteToWorkspace}
                        onEdit={() => {
                            setScopeBeingViewed(undefined);
                            setScopeBeingEdited(scopeBeingViewed);
                            setScopeModalOpen(true);
                        }}
                        onClose={() => {
                            setScopeBeingViewed(undefined);
                        }}
                    />
                )}

                {scopeBeingDeleted && (
                    <DeleteScopeModal
                        workspaceId={currentWorkspaceID}
                        scope={scopeBeingDeleted}
                        onClose={() => {
                            setScopeBeingDeleted(undefined);
                        }}
                        key='scope-delete'
                    />
                )}
            </Template>
        </WorkspaceRequired>
    );
};

export default withWorkspaceID(ScopePage);
