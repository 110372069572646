import Button from 'components/button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import { Delete, SCRIPTS } from 'services/ScriptService';
import { ProjectedScript } from './Scripts';
import { useMutation, useQueryClient } from 'react-query';

type ScriptsDeleteModalProps = {
    script: ProjectedScript;
    onClose: () => void;
};

export const ScriptsDeleteModal: React.FC<ScriptsDeleteModalProps> = ({ script, onClose }) => {
    const queryClient = useQueryClient();

    const { mutate: handleDelete, isLoading, isError } = useMutation(
        async () => {
            return Delete(script.id);
        },
        {
            onSettled: async () => {
                await queryClient.invalidateQueries([SCRIPTS]);
            },
            onSuccess: async () => {
                onClose();
            }                 
        }
    );    

    const description = 'Are you sure you want to permanently delete this script?';

    return (
        <Modal
            title={`Delete Script: ${script.displayName}`}
            description={description}
            close={onClose}
            fullWidth
            maxWidth='max-w-3xl'
        >
            {isError && <p className='pl-8 text-statusErrorPrimary'>An error occurred, please try again.</p>}
            
            <ModalButtons>
                <Button type='button' variant='tertiary' onClick={onClose}>
                    Cancel
                </Button>

                <Button
                    type='button'
                    variant='destructive'
                    data-testid='confirmDeleteScript'
                    onClick={() => handleDelete()}
                    disabled={isLoading}
                >
                    {isLoading ? <LoadingSpinner size={18} /> : 'Delete'}
                </Button>
            </ModalButtons>
        </Modal>
    );
};
