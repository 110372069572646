import { Button } from '@/components/Button';
import { faCircleXmark, faRefresh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';

interface TileErrorProps {
    heading: ReactNode;
    detail?: ReactNode;
    showDetail: boolean;
    reset?: () => void;
    /** Used to link to the documentation */
    articleAnchor?: string;
}

export const TileError: React.FC<TileErrorProps> = ({ heading, detail, showDetail, reset, articleAnchor }) => {
    return (
        <div className='flex flex-col items-center max-h-full p-4'>
            <div className='relative max-w-lg max-h-full p-4 text-left border-2 rounded border-statusErrorSecondary'>
                <div className='absolute top-0 left-0 w-full h-full bg-statusErrorSecondary opacity-10'></div>
                <div className='relative flex flex-col h-full'>
                    <div className='flex items-start'>
                        <div className='mr-3'>
                            <FontAwesomeIcon icon={faCircleXmark} className='text-base text-statusErrorSecondary' />
                        </div>
                        <p className='font-semibold text-statusErrorPrimary'>{heading}</p>
                        {showDetail && (
                            <a
                                href={`https://squaredup.com/cloud/TroubleshootingTiles#${articleAnchor}`}
                                target='_blank'
                                rel='noreferrer'
                                className='flex justify-end flex-1 ml-8 text-sm text-textLink'
                            >
                                Learn more
                            </a>
                        )}
                    </div>
                    {showDetail && detail && (
                        <div className='flex-1 mt-4 overflow-y-scroll pl-7 text-textPrimary scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                            <pre className='pr-2 whitespace-normal'>{detail}</pre>
                        </div>
                    )}
                    {reset && (
                        <Button
                            onClick={reset}
                            className='w-auto mt-2 font-semibold'
                            variant='tertiary'
                            icon={<FontAwesomeIcon icon={faRefresh} />}
                        >
                            Try again
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
