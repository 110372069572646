import { Datum } from '@nivo/line';
import GraphTooltip from 'components/GraphTooltip';
import { RefObject, useState } from 'react';
import { LegendItem } from './LegendItem';

export type Legend = {
    id: string | number;
    label: string;
    value?: number | undefined;
    percentage?: number | undefined;
    formattedPercentage?: string | undefined;
    color: string;
    isFocused?: boolean;
    composition?: { label: string; value?: string }[];
    formattedValue?: string | undefined;
};

export type LegendPosition = 'auto' | 'top' | 'left' | 'right' | 'bottom';

interface LegendsProps {
    vizRef: RefObject<HTMLElement>;
    legend: Legend[];
    isVertical: boolean;
    showTooltip?: boolean;
    showValuesAsPercentage?: boolean;
    containerSize: {
        width: number;
        height: number;
    };
    onHover: (attributes: Datum) => void;
    onLeave: (attributes: Datum) => void;
    formatValue?: (v: unknown) => string;
    formatPercentage?: (v: unknown) => string;
}

const maxColumnWidth = 200;
const itemHeightWithValue = 36;
const itemHeightWithoutValue = 18;
const blockWidth = 20;
const charWidth = 9;
const margin = 18;
const containerPadding = 18;

const fitIntoSpace = (totalSize: number, itemSize: number, gap: number) => {
    let count = 1;
    let currentSize = itemSize;
    while (currentSize + gap + itemSize < totalSize) {
        currentSize += gap + itemSize;
        count += 1;
    }
    return count;
};

export const Legends: React.FC<LegendsProps> = ({
    legend,
    vizRef,
    containerSize,
    isVertical,
    showTooltip,
    showValuesAsPercentage,
    onHover,
    onLeave,
    formatValue,
    formatPercentage
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [hoveredLegend, setHoveredLegend] = useState<Legend | null>(null);

    const containerWidth = containerSize.width - containerPadding * 2;
    const containerHeight = containerSize.height - containerPadding * 2;
    // Get a rough width in px for each label
    const legendLabelWidths = legend.map((a) => a.label.length * charWidth + blockWidth);
    const totalWidth = legendLabelWidths.reduce((acc, cur) => acc + cur, 0) + (margin * legend.length - 1);
    const maxLabelWidth = Math.max(...legendLabelWidths) + 2;
    // Calculate the most appropriate column width, if there's enough space use the biggest label,
    // otherwise clamp with a max width
    let colWidth = totalWidth < containerWidth ? maxLabelWidth : Math.min(maxLabelWidth, maxColumnWidth);
    colWidth = Math.max(Math.min(containerWidth, colWidth), 50);

    const itemsHaveValues = legend.every((v) => v.formattedValue != null);
    const itemHeight = itemsHaveValues ? itemHeightWithValue : itemHeightWithoutValue;

    const count = legend.length;

    let numberOfColumns = 1;
    let numberOfRows = 1;

    let style = {};

    if (isVertical) {
        // Take up as many columns as we can before breaking into a new row
        const cols = fitIntoSpace(containerWidth, colWidth, margin);
        numberOfColumns = Math.min(Math.max(cols, 1), count);

        const rows = fitIntoSpace(containerHeight, itemHeight, margin);
        numberOfRows = Math.min(Math.max(rows, 1), Math.ceil(count / numberOfColumns));

        style = {
            gridTemplateRows: `repeat(${
                numberOfRows > count ? count : Math.min(Math.ceil(count / numberOfColumns), numberOfRows)
            }, auto)`,
            gridTemplateColumns: `repeat(${numberOfColumns}, auto)`
        };
    } else {
        // Take up as many rows as we can before breaking into a new column
        const rows = fitIntoSpace(containerHeight, itemHeight, margin);
        const cols = fitIntoSpace(containerWidth, colWidth, margin);
        numberOfRows = Math.min(Math.max(rows, 1), count);
        numberOfColumns = Math.min(Math.max(cols, 1), count);

        style = {
            gridTemplateRows: `repeat(${numberOfRows}, auto)`,
            gridTemplateColumns: `repeat(${
                numberOfColumns > count ? count : Math.min(Math.ceil(count / numberOfRows), numberOfColumns)
            }, auto)`
        };
    }

    const displayExcessLegends = (hiddenLegends: number) => {
        const legendsList = legend.slice(count - hiddenLegends);
        const totalValue = legendsList.reduce((total, { value }) => total + (value ?? 0), 0);
        const totalPercentage = legendsList.reduce((total, { percentage }) => total + (percentage ?? 0), 0);

        const currentLegend: Legend = {
            id: legend[hiddenLegends - 1].id,
            label: hiddenLegends + ' more',
            value: undefined,
            formattedValue: formatValue ? formatValue(totalValue) : undefined,
            color: 'var(--outlineSecondary)',
            composition: legendsList.map((c) => {
                return {
                    label: c.label,
                    value: showValuesAsPercentage ? c.formattedPercentage : c.formattedValue,
                    color: c.color
                };
            }),
            ...showValuesAsPercentage && formatPercentage && { 
                formattedPercentage: formatPercentage(Math.round(totalPercentage)) 
            }
        };

        setHoveredLegend(currentLegend);
    };

    return (
        <>
            <div className='flex items-center justify-center w-full h-full'>
                <div className='grid gap-5 overflow-hidden' style={style} data-testid='legendBlocks'>
                    {legend.slice(0, numberOfColumns * numberOfRows).map((item, i: number) => {
                        const currentIndex = i + 1;
                        const isLastLegendItem = currentIndex === numberOfColumns * numberOfRows;
                        const hasMoreItemsToDisplay = count > currentIndex;

                        if (isLastLegendItem && hasMoreItemsToDisplay) {
                            const legendsLeft = count - currentIndex + 1;
                            const rest = legend.slice(currentIndex, count);

                            const isFocused = rest.some((l) => l.isFocused);

                            return (
                                <LegendItem
                                    key={item.id}
                                    label={`${legendsLeft} more`}
                                    color='var(--outlineSecondary)'
                                    showTooltip={false}
                                    maxWidth={colWidth}
                                    onMouseEnter={() => {
                                        setIsHovered(true);
                                        displayExcessLegends(legendsLeft);
                                    }}
                                    onMouseLeave={() => {
                                        setIsHovered(false);
                                        setHoveredLegend(null);
                                    }}
                                    isDimmed={!isFocused}
                                />
                            );
                        }

                        return (
                            <LegendItem
                                key={item.id}
                                label={item.label}
                                value={showValuesAsPercentage ? item.formattedPercentage : item.formattedValue}
                                color={item.color}
                                showTooltip={showTooltip}
                                maxWidth={colWidth}
                                onMouseEnter={() => {
                                    if (showTooltip) {
                                        setIsHovered(true);
                                        setHoveredLegend(item);
                                    }
                                    onHover(item);
                                }}
                                onMouseLeave={() => {
                                    if (showTooltip) {
                                        setIsHovered(false);
                                    }
                                    onLeave(item);
                                }}
                                isDimmed={!item.isFocused}
                            />
                        );
                    })}
                </div>
            </div>

            {isHovered && hoveredLegend && (
                <GraphTooltip
                    points={[
                        {
                            label: hoveredLegend.label,
                            value: showValuesAsPercentage
                                ? `${hoveredLegend.formattedPercentage} (${hoveredLegend.formattedValue})`
                                : hoveredLegend.formattedValue,
                            color: hoveredLegend.color,
                            highlight: true,
                            composition: hoveredLegend.composition
                        }
                    ]}
                    graphRef={vizRef}
                />
            )}
        </>
    );
};
