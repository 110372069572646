import React from 'react';

interface BlocksGridProps {
    columns: number;
    hasSublabels: boolean;
    blockHeight?: number;
    limitHeight?: boolean;
}

export const BlocksGrid: React.FC<BlocksGridProps> = ({
    hasSublabels,
    columns,
    children,
    blockHeight,
    limitHeight = true
}) => {
    const gridRowHeight = blockHeight
        ? `${blockHeight}px`
        : `minmax(${hasSublabels ? 50 : 30}px, ${limitHeight ? '310px' : '1fr'})`;

    return (
        <div
            className='grid h-full gap-2 text-primaryButtonText'
            style={{
                gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
                gridAutoRows: gridRowHeight
            }}
        >
            {children}
        </div>
    );
};
