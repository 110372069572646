import { FormattedStreamValue, isNone, state, StreamDataColumn } from '@squaredup/data-streams';
import { HealthState, stateValues } from '@squaredup/monitoring';
import { Row } from '@tanstack/react-table';

export const sortDataStreamValue =
    (columns: StreamDataColumn[]) =>
    (rowA: Row<FormattedStreamValue[]>, rowB: Row<FormattedStreamValue[]>, columnId: string): number => {
        const valueA: FormattedStreamValue['value'] = (rowA.getValue(columnId) as FormattedStreamValue).value;
        const valueB: FormattedStreamValue['value'] = (rowB.getValue(columnId) as FormattedStreamValue).value;
        const column = columns.find((c) => c.name === columnId);

        const isNoneA = isNone(valueA);
        const isNoneB = isNone(valueB);
        const neitherIsNone = !isNoneA && !isNoneB;

        if (valueA === valueB || (isNoneA && isNoneB)) {
            return 0;
        }

        // Apply health state mapping to state columns
        const mappedA = !isNoneA && column?.shapeName === state.name ? stateValues[valueA as HealthState] : valueA;
        const mappedB = !isNoneB && column?.shapeName === state.name ? stateValues[valueB as HealthState] : valueB;

        // Ensure it's a case insensitive sort for strings
        const lowerA = typeof mappedA === 'string' ? mappedA.toLowerCase() : mappedA;
        const lowerB = typeof mappedB === 'string' ? mappedB.toLowerCase() : mappedB;

        const isHigher = (!isNoneA && isNoneB) || (neitherIsNone && lowerA > lowerB);
        const asNumber = isHigher ? 1 : -1;

        return asNumber;
    };
