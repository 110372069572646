import { FormattedStreamData, StreamData } from '@squaredup/data-streams';
import { formatDate, standardDatePatterns } from '@squaredup/utilities';
import { DataStreamLineGraphConfig } from './Config';
import { getLinegraphColumns } from './LineGraphColumns';
import { toSeries } from './LineGraphToSeries';

export const dateFormatterFullDate = (dateValue: string | number | Date) => {
    return formatDate(dateValue, { pattern: standardDatePatterns.long, appendUtcOffset: true });
};

const hasASeriesWithMultiplePoints = (data?: StreamData, config?: DataStreamLineGraphConfig) => {
    let isMultipleSeries = false;
    if (data) {
        const series = toSeries(data, config);
        // check if the time series data have more than 1 point for at least one label
        isMultipleSeries = series.series.filter((row) => row.points.length > 1).length > 0;
    }
    return isMultipleSeries;
};

export const matchesLineGraphData = (data?: FormattedStreamData, config?: DataStreamLineGraphConfig) => {
    const columns = data?.metadata?.columns ?? [];
    const rows = data?.rows ?? [];
    const criteria = getLinegraphColumns(columns, rows, config);

    if (data == null) {
        criteria.fail('At least 1 row of data is required');
    }
    if (!hasASeriesWithMultiplePoints(data, config)) {
        criteria.fail('At least 1 row of data, per series, is required');
    }

    return criteria;
};
