import { RefreshTokenContext } from 'services/OpenAccessLoginService';

/**
 * Returns true if the app is currently in Open Access mode, i.e displaying an open access
 * dashboard through an anonymous login (or attempting to).
 */
export function isOpenAccess() {
    return window.location.pathname.startsWith('/openaccess/');
}

export function getRequestedOpenAccessId() {
    const rawOpenAccessId = window.location.pathname.substring('/openaccess/'.length);
    const parts = rawOpenAccessId.split('/'); // Might be a /tile/xxx
    return `openacc-${parts[0]}`;
}

export function getRefreshTokenFromLocalStorage(openAccessId: string): RefreshTokenContext | undefined {
    const refreshContextJson = localStorage.getItem(getStorageKey(openAccessId));

    if (refreshContextJson) {
        const refreshContext = JSON.parse(refreshContextJson) as RefreshTokenContext;
        
        oaLog('Refresh token from local storage', { refreshContext });
        if (refreshContext.expires > Date.now() && refreshContext.sessionExpires > Date.now()) {
            oaLog('Refresh token is valid');
            return refreshContext;
        }

        // Refresh token has expired. Let's be good citizens and clean up after ourselves.
        deleteRefreshTokenFromLocalStorage(openAccessId);
    }
}

export function storeRefreshTokenInLocalStorage(openAccessId: string, refreshContext: RefreshTokenContext) {
    oaLog('Storing refresh token in local storage', { openAccessId, refreshContext });
    localStorage.setItem(getStorageKey(openAccessId), JSON.stringify(refreshContext));
}

export function deleteRefreshTokenFromLocalStorage(openAccessId: string) {
    oaLog('Deleting referesh token from local storage');
    localStorage.removeItem(getStorageKey(openAccessId));
}

/**
 * Turn on for extra diagnostics when developing.
 * (You can add the local storage entry in dev tools - make sure it's for the app URL, not a B2C URL)
 */
export function oaLog(message?: any, ...optionalParams: any[]) {    
    if (localStorage.getItem('SQUP_OPEN_ACCESS_LOGGING_ENABLED')) {
        // eslint-disable-next-line no-console
        console.log(`[${new Date().toISOString()}] Open Access: ` + message, ...optionalParams);
    }
}

function getStorageKey(openAccessId: string) {
    return `squp.${openAccessId.replace('openacc-', '')}.refresh`;
}