import { HTMLAttributes, forwardRef } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps extends HTMLAttributes<HTMLDivElement> {
    container?: HTMLElement | null;
}

export const Portal = forwardRef<HTMLDivElement, PortalProps>(
    ({ container = globalThis?.document?.body, ...portalProps }, forwardedRef) => 
        container ? createPortal(<div {...portalProps} ref={forwardedRef} />, container) : null
);
