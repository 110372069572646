import { PinnableNodeData } from 'components/map/data/types';
import { NodeProps } from 'reactflow';
import { OrganisationNodeInternal } from './internals/OrganisationNodeInternal';

export const ORGANISATION_NODE = 'OrganisationNode';
export const ORGANISATION_NODE_SIZE = 100;

export const OrganisationNode = ({ id, data }: NodeProps<PinnableNodeData>) => {

    return (
        <OrganisationNodeInternal 
            id={id}
            pinned={data.pinned}
            expanded={data.expanded}
            label={data.label}
            workspaceId={data.workspaceId}
            hiddenConnections={data.hiddenConnections}
            fixedPosition={data.fixedPosition}
        />
    );
};
