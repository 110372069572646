import Text from '@/components/Text';
import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFeatureEnabled } from '@squaredup/tenants';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { FeatureUnavailableBanner } from 'components/plans/FeatureUnavailableBanner';
import { useTier } from 'queries/hooks/useTier';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { KPI_TYPES, ListTypes } from 'services/KPIService';
import { SettingsTemplate } from '../SettingsTemplate';
import KPITypeDeleteModal from './KPITypeDeleteModal';
import KPITypeModal from './KPITypeModal';

function KPITypes() {
    const [typeBeingDeleted, setTypeBeingDeleted] = useState(null);
    const [typeAddEditModalOpen, setTypeAddEditModalOpen] = useState(false);
    const [typeBeingEdited, setTypeBeingEdited] = useState(false);
    const { data: tier, isLoading: isTierLoading } = useTier();
    const isFeatureAvailable = tier !== undefined && isFeatureEnabled(tier, 'kpiTypes');

    const { isLoading: isKPITypesLoadind, data: types, refetch } = useQuery(KPI_TYPES, ListTypes);

    const isLoading = isKPITypesLoadind || isTierLoading;

    const handleClose = async (callback) => {
        refetch();
        callback();
    };

    return (
        <SettingsTemplate
            title='KPI'
            description={
                isFeatureAvailable
                    ? 'KPIs can publish key metrics both within a workspace and also to aggregate at a higher level for complete oversight.'
                    : undefined
            }
            learnMoreLink='https://docs.squaredup.com/first-steps/kpis#adding-kpi-types'
        >
            {isLoading && (
                <span className='flex justify-center'>
                    <LoadingSpinner />
                </span>
            )}
            {!isLoading && (
                <div>
                    <FeatureUnavailableBanner featureKey='kpiTypes' className='mb-9' container='page' title='KPIs' />
                    <Button
                        onClick={() => setTypeAddEditModalOpen(true)}
                        data-testid='addKPIType'
                        disabled={!isFeatureAvailable}
                    >
                        Add KPI type
                    </Button>

                    <div className='max-w-4xl'>
                        {types.map((type) => (
                            <KPIType
                                isFeatureAvailable={isFeatureAvailable}
                                key={type.id}
                                type={type}
                                onEdit={() => {
                                    setTypeBeingEdited(type);
                                    setTypeAddEditModalOpen(true);
                                }}
                                onDelete={() => setTypeBeingDeleted(type)}
                            />
                        ))}
                    </div>
                </div>
            )}

            {typeBeingDeleted && (
                <KPITypeDeleteModal
                    type={typeBeingDeleted}
                    close={() => handleClose(() => setTypeBeingDeleted(null))}
                />
            )}

            {typeAddEditModalOpen && (
                <KPITypeModal
                    type={typeBeingEdited}
                    close={() =>
                        handleClose(() => {
                            setTypeBeingEdited(null);
                            setTypeAddEditModalOpen(false);
                        })
                    }
                />
            )}
        </SettingsTemplate>
    );
}

function KPIType({ type, onEdit, onDelete, isFeatureAvailable }) {
    return (
        <>
            <div
                className='p-5 pb-4 my-4 border rounded-input bg-componentBackgroundSecondary border-outlinePrimary'
                data-testid='kpiType'
            >
                <div className='flex items-center'>
                    <span className='mr-3 text-base font-semibold truncate'>{type.displayName}</span>
                    {!isFeatureAvailable && (
                        <Text.SmallBody className='mr-3 text-textSecondary'>(disabled)</Text.SmallBody>
                    )}

                    <span className='flex items-center ml-auto text-xl text-textSecondary'>
                        {isFeatureAvailable && onEdit && (
                            <FontAwesomeIcon
                                icon={faPencil}
                                title='Edit'
                                className='mx-2 cursor-pointer hover:text-textPrimary'
                                onClick={onEdit}
                                data-testid='editKPIType'
                            />
                        )}
                        {onDelete && (
                            <FontAwesomeIcon
                                icon={faTrash}
                                title='Delete'
                                className='ml-2 cursor-pointer hover:text-textPrimary'
                                onClick={onDelete}
                                data-testid='deleteKPIType'
                            />
                        )}
                    </span>
                </div>
            </div>
        </>
    );
}

export default KPITypes;
