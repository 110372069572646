import { PinnableNodeData } from 'components/map/data/types';
import { NodeProps } from 'reactflow';
import { ObjectNodeInternal } from './internals/ObjectNodeInternal';

export const OBJECT_NODE = 'objectNode';
export const OBJECT_NODE_SIZE = 28;

export const ObjectNode = ({ id, data }: NodeProps<PinnableNodeData>) => {

    return (
        <ObjectNodeInternal
            id={id}
            pinned={data.pinned}
            expanded={data.expanded}
            label={data.label}
            fixedPosition={data.fixedPosition}
            type={data.type}
            sourceType={data.sourceType}
            initialState={data.initialState}
            isCanonical={data.isCanonical}
            hiddenConnections={data.hiddenConnections}
        />
    );
};
