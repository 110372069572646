import type { Node } from '@squaredup/graph';
import { Query } from '../../../../services/GraphService';
import { NodeData } from '../../../types/data/GraphData';
import { DatasourceFunction } from '../../../types/Datasource';
import { GraphNodesConfig } from './Config';

const graphNodes: DatasourceFunction<NodeData, GraphNodesConfig> = async (config) => {
    let query = 'g.V()';
    let bindings: Record<string,any> = {};

    // If related is specified then get adjacent (other) nodes related to ID
    // (traversing any/all canonical relations)
    if (config.related) {
        query += `.hasId(id).optional(out('is')).optional(__.in('is')).bothE()
                  .not(has('label', 'is')).otherV().optional(out('is'))
                  .not(hasId(id)).dedup()`;
        bindings.id = config.related;
    }

    // Filter by type / sourceType
    if (config.type || config.sourceTypeContains || config.configId) {
        if (config.type) {
            query += '.has("type", within(type))';
            bindings.type = config.type;
        }
        if (config.sourceTypeContains) {
            query += '.has("sourceType", containing(sourceTypeContains))';
            bindings.sourceTypeContains = config.sourceTypeContains;
        }
        if (config.configId) {
            query += '.has("__configId", configId)';
            bindings.configId = config.configId;
        }
        // Traverse to canonical node if there is one and dedup
        query += '.hasNot("__canonicalType").optional(out("is")).dedup()';
    }

    // Limit and map results to nicer format
    query += `.limit(${config.nodeLimit || 200}).valueMap(true)`;

    const { gremlinQueryResults }: { gremlinQueryResults: Node[] } = await Query({'gremlinQuery': query, bindings});

    return {
        data: gremlinQueryResults
    };
};

graphNodes.config = 'GraphNodesConfig';

export default graphNodes;
