/*
 * This is a modified version of the original BarItem component from the nivo v0.68 library.
 * Allowing us to customize the label position rendering.
 * https://github.com/plouc/nivo/blob/v0.68.0/packages/bar/src/BarItem.js
 *
 * This will need to be refactored if Nivo is updated, as the original component has changed in later versions.
 */
import { BarExtendedDatum, BarItemProps, GraphicsContainer } from '@nivo/bar';
import { BasicTooltip } from '@nivo/tooltip';
import React from 'react';

// below this height a bar's label will be rendered above the bar
export const LABEL_ALIGN_TOP_MIN_HEIGHT = 20;
// below this width a bar's label will be rendered to the right of the bar
export const LABEL_ALIGN_RIGHT_MIN_WIDTH = 40;
// offsets of the label from the bar
const LABEL_VERTICAL_OFFSET = 10;
const LABEL_HORIZONTAL_OFFSET = 5;

const DataStreamBarItem = (
    props: BarItemProps & {
        getTooltipLabel?: (data: BarExtendedDatum) => string;
        labelSkipHeight?: number;
        labelSkipWidth?: number;
        data: BarExtendedDatum & {
            fill?: string;
        };
    }
) => {
    const {
        data,
        x,
        y,
        width,
        height,
        borderRadius,
        color,
        borderWidth,
        borderColor,
        labelColor,
        showTooltip,
        hideTooltip,
        onClick,
        onMouseEnter,
        onMouseLeave,
        labelSkipHeight,
        labelSkipWidth,
        label,
        theme,
        tooltip,
        shouldRenderLabel,
        tooltipFormat,
        getTooltipLabel
    } = props;

    const nivoTooltip = (
        <BasicTooltip
            id={getTooltipLabel?.(data)}
            value={data.value}
            enableChip={true}
            color={color}
            format={tooltipFormat as any}
            renderContent={(typeof tooltip === 'function' ? tooltip.bind(null, { color, ...data }) : null) as any}
        />
    );

    const handleTooltip = (e: React.MouseEvent<GraphicsContainer>) => showTooltip(nivoTooltip, e);

    const handleMouseEnter = (e: React.MouseEvent<GraphicsContainer>) => {
        onMouseEnter(data, e);
        showTooltip(nivoTooltip, e);
    };

    const handleMouseLeave = (e: React.MouseEvent<GraphicsContainer>) => {
        onMouseLeave(data, e);
        hideTooltip();
    };

    const handleMouseClick = (e: React.MouseEvent<GraphicsContainer>) => {
        onClick(data, e);
    };

    // if labelSkipHeight is 0, the bar label is rendered above the bar if it's too short
    const shouldLabelAlignTop = height < LABEL_ALIGN_TOP_MIN_HEIGHT && !labelSkipHeight;
    const shouldLabelAlignRight = width < LABEL_ALIGN_RIGHT_MIN_WIDTH && !labelSkipWidth;
    // assuming vertical layout if a labelSkipHeight is provided and is greater than 0
    const labelY = shouldLabelAlignTop ? -LABEL_VERTICAL_OFFSET : height / 2;
    const labelX = shouldLabelAlignRight ? width + LABEL_HORIZONTAL_OFFSET : width / 2;
    const labelFill = shouldLabelAlignTop || shouldLabelAlignRight ? 'currentColor' : labelColor;

    return (
        <g transform={`translate(${x}, ${y})`}>
            <rect
                width={width}
                height={height}
                rx={borderRadius}
                ry={borderRadius}
                fill={data.fill ? data.fill : color}
                strokeWidth={borderWidth}
                stroke={borderColor}
                onMouseEnter={handleMouseEnter}
                onMouseMove={handleTooltip}
                onMouseLeave={handleMouseLeave}
                onClick={handleMouseClick}
            />
            {shouldRenderLabel && (
                <text
                    x={labelX}
                    y={labelY}
                    textAnchor={shouldLabelAlignRight ? 'start' : 'middle'}
                    dominantBaseline='central'
                    style={{
                        ...theme.labels?.text,
                        pointerEvents: 'none',
                        fill: labelFill
                    }}
                >
                    {label}
                </text>
            )}
        </g>
    );
};

export default DataStreamBarItem;
