import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { Action } from 'pages/components/ApplicationTable/types';
import TruncateWithTooltip from 'pages/settings/TruncateWithTooltip';
import { FC } from 'react';
import PluginIcon from './PluginIcon';

interface ScopesTableProps {
    scopes: any;
    onEdit: (scope: any) => void;
    onDelete: (scope: any) => void;
    onView: (scope: any) => void;
    canEdit: (scope: any) => boolean;
    canDelete: (scope: any) => boolean;
}

export interface ScopeProjectedRow {
    id: string;
    type: string;
    displayName: string;
    tenant: string;
    configId: string;
    data: Record<string, unknown>;
    workspaceId?: string;
    link: string;
    dataSourceName: string;
    dataSourceDisplayName: string;
    scopeType: string;
    objectCount: number;
    canEdit: boolean;
    canDelete: boolean;
}

const ScopesApplicationTable = ApplicationTable<ScopeProjectedRow, string>();

const ScopesTable: FC<ScopesTableProps> = ({ scopes, onEdit, onView, onDelete, canEdit, canDelete }) => {
    const columns: ColumnDef<ScopeProjectedRow>[] = [
        {
            id: 'name',
            header: 'Name',
            cell: ({ row }) => {
                return (
                    <TruncateWithTooltip title={row.original.displayName}>
                        <button
                            className='truncate cursor-pointer text-textLink hover:underline'
                            onClick={() => onView(row.original)}
                        >
                            {row.original.displayName}
                        </button>
                    </TruncateWithTooltip>
                );
            },
            size: 400,
            accessorKey: 'displayName'
        },
        {
            id: 'objectCount',
            header: 'Objects',
            cell: ({ row }) => row.original.objectCount,
            size: 120,
            accessorKey: 'objectCount'
        },
        {
            id: 'scopeType',
            header: 'Selection',
            cell: ({ row }) => row.original.scopeType,
            size: 130,
            accessorKey: 'scopeType'
        },
        {
            id: 'dataSource',
            header: 'Data Source',
            cell: ({ row }) => {
                return (
                    <TruncateWithTooltip title={row.original.dataSourceDisplayName}>
                        {!['Multiple', 'None'].includes(row.original.dataSourceDisplayName) && (
                            <div>
                                <PluginIcon className='h-6 mr-2' pluginName={row.original.dataSourceName} />
                            </div>
                        )}
                        <div className='truncate'>{row.original.dataSourceDisplayName}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 200,
            accessorKey: 'dataSourceDisplayName'
        }
    ];

    const actions: Action[] = [
        {
            visible: canEdit,
            action: onEdit,
            icon: faPencil,
            dataTestId: 'editScopeButton',
            tooltip: 'Edit scope'
        },
        {
            visible: canDelete,
            action: onDelete,
            icon: faTrash,
            dataTestId: 'deleteScopeButton',
            tooltip: 'Delete scope'
        }
    ];

    return (
        <ScopesApplicationTable
            config={{
                actions,
                noDataMessage: 'There are no collections configured.'
            }}
            data={scopes}
            columns={columns}
        />
    );
};

export default ScopesTable;
