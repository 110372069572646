import { Options } from 'html-to-image/lib/types';
import * as htmlToImage from 'html-to-image';

/**
 * For Firefox only!
 *
 * Convert an HTML element (e.g. dashboard) to a PNG file (as a data URI).
 *
 * Normally we use html-to-image toPng() for this, but it has a problem on Firefox.
 * That's because html-to-image uses data URIs rather than object URIs internally, and Firefox
 * has a 32 MByte limit for those, which we can easily breach with a complex
 * dashboard. (The final image URL is not that big, but the intermediate SVG URL can be.)
 *
 * Refs:
 *    Firefox introduction of 32 MB limit: https://bugzilla.mozilla.org/show_bug.cgi?id=1721448
 *    html-to-image discussion of issue: https://github.com/bubkoo/html-to-image/issues/255
 *
 * Chrome (and apparently Safari) have much higher limits - 512 MB for Chrome, 2 GB for Safari,
 * so don't have the issue.
 *
 * So the workaround for Firefox is to get html-to-image to do the hard work of converting the html to SVG, then
 * replace the SVG-to-PNG conversion with our own code that uses an object URL rather than a data URI
 * to handle the massive SVG. Object URLs have much higher size limits.
 *
 * IMPORTANT: This approach does NOT work on Chrome.  It will complain about the canvas being tainted.
 * So we should ONLY use this code for Firefox, and stick with the standard html-to-image toPng() function
 * for other browsers.
 */
export async function firefoxHtmlToPng<T extends HTMLElement>(
    element: T,
    options?: Options
): Promise<string | undefined> {
    const width = element.scrollWidth;
    const height = element.scrollHeight;

    // This is the URI that can be massive. There's no problem creating it on Firefox - we just
    // cannot attach it to an IMG element to convert it to PNG.
    //
    const svgDataUri = await htmlToImage.toSvg(element, options);

    // To avoid the Firefox data URL limit, we extract the raw SVG string from SVG data URI and then wrap it in a Blob,
    // then create an *object* URL (not a data URL) from that blob.  Object URLs can be massive, so we
    // don't hit a limit.
    //
    const rawSvg = decodeURIComponent(svgDataUri.split('data:image/svg+xml;charset=utf-8,')[1]);

    const pngDataUri = await svgToPngUri(
        rawSvg,
        width,
        height,
        options?.pixelRatio ?? 1,
        options?.style?.background
    );

    return pngDataUri;
}

async function svgToPngUri(
    svg: string,
    width: number,
    height: number,
    ratio: number,
    backgroundColor: string | undefined
) {
    let svgBlob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
    let svgObjectUrl = URL.createObjectURL(svgBlob);
    try {
        const canvas = await svgToCanvas(svgObjectUrl, width, height, ratio, backgroundColor);
        const pngBlob = await canvasToPngBlob(canvas);

        // It's ok to use a data uri here rather than an object URI, because it should never be near
        // the 32 MB limit. And it's better to return a data URI to the caller as it means we can free
        // the object URL without requiring the caller to do so.
        const pngUri = await blobToDataUri(pngBlob);

        return pngUri;
    } finally {
        URL.revokeObjectURL(svgObjectUrl);
    }
}

function svgToCanvas(
    svgObjectUrl: string,
    width: number,
    height: number,
    ratio: number,
    backgroundColor: string | undefined
): Promise<HTMLCanvasElement> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = async () => {
            let canvas = document.createElement('canvas');
            canvas.width = width * ratio;
            canvas.height = height * ratio;
            canvas.style.width = `${width}`;
            canvas.style.height = `${height}`;
            let ctx = canvas.getContext('2d');
            if (ctx && backgroundColor) {
                ctx.fillStyle = backgroundColor;
                ctx.fillRect(0, 0, canvas.width, canvas.height);
            }
            ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
            resolve(canvas);
        };
        img.onerror = () => reject(new Error('Failed to convert SVG to canvas'));
        img.src = svgObjectUrl;
    });
}

async function canvasToPngBlob(canvas: HTMLCanvasElement): Promise<Blob> {
    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (blob) {
                resolve(blob);
            } else {
                reject(new Error('Blob creation failed'));
            }
        }, 'image/png');
    });
}

async function blobToDataUri(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function () {
            resolve(reader.result as string);
        };
        reader.onerror = function () {
            reject(new Error('Failed to convert blob to data URI'));
        };
        reader.readAsDataURL(blob);
    });
}
