/* eslint-disable no-redeclare */
import type { KPI, KPIType, KPIValue } from 'dynamo-wrapper';
import API from './API';
import { handleError, handleResponse } from './util';

export const KPI_TYPES = 'kpiTypes';
export const KPI_DATA = 'kpiData';

export const GetType = async (id: string) => {
    return API.get<KPIType>(`/kpitypes/${id}`).then(handleResponse).catch(handleError);
};

export const ListTypes = async () => {
    const kpiTypes = await API.get<KPIType[]>('/kpitypes').then(handleResponse).catch(handleError);
    return kpiTypes.sort((a, b) => a.displayName.localeCompare(b.displayName));
};

export const CreateType = async (displayName: string) => {
    const created = await API.post<KPIType>('/kpitypes', { displayName }).then(handleResponse).catch(handleError);

    return created.id;
};

export const UpdateType = async (id: string, displayName: string) => {
    await API.put<KPIType>(`/kpitypes/${id}`, { displayName }).then(handleResponse).catch(handleError);
    return id;
};

export const DeleteType = async (id: string) => {
    await API.delete(`/kpitypes/${id}`).then(handleResponse).catch(handleError);
    return true;
};

export async function List(
    workspaceId?: string,
    kpiTypeId?: string,
    options?: { includeValues: true }
): Promise<KPIValue[]>;
export async function List(
    workspaceId?: string,
    kpiTypeId?: string,
    options?: { includeValues: false }
): Promise<KPI[]>;
export async function List(
    workspaceId?: string[],
    kpiTypeId?: string,
    options?: { includeValues: boolean }
): Promise<KPI[]>;
export async function List(
    workspaceId?: string[] | string,
    kpiTypeId?: string,
    options?: { includeValues: true | false }
): Promise<KPIValue[] | KPI[]> {
    let workspaceQuery = '';
    if (typeof workspaceId === 'string') {
        workspaceQuery = `workspaceId=${workspaceId}&`;
    } else if (Array.isArray(workspaceId)) {
        workspaceQuery = `workspaceId=${workspaceId.join('&workspaceId=')}&`;
    }

    const typeQuery = kpiTypeId == null ? '' : `type=${kpiTypeId}&`;

    return API.get(`/kpis?${workspaceQuery}${typeQuery}includeValues=${options?.includeValues ?? false}`)
        .then(handleResponse)
        .catch(handleError);
}
