import { SqUpPluginDisplayName } from '@squaredup/ids';

export function isScope(node: any) {
    return `${node?.sourceType}` === 'squaredup/scope';
}

export function getNodeTitle(node: any) {
    const displayName = node?.name?.[0];

    if (isScope(node)) {
        return getScopeTitle({
            displayName,
            data: {
                quickScope: node.quickScope?.[0],
                queryDetail: node.queryDetail?.[0]
            }
        });
    }

    return displayName;
}

export function graphDataToNetworkData(data: any, addSortName = false) {
    const nodes = data.nodes?.map((node: any) => {
        const label = getNodeTitle(node);
        return {
            ...node,
            label,
            name: [label],
            group: 'nodes',
            ...(addSortName && { sortName: label?.toLocaleLowerCase() })
        };
    });

    const edges = data.edges?.map((edge: any) => ({
        ...edge,
        from: edge.outV,
        to: edge.inV,
        group: 'edges'
    }));

    return { nodes, edges };
}

interface ScopeTitleParams {
    displayName?: string;
    data?: {
        quickScope?: boolean;
        queryDetail?: string | { plugin: any, types: any[], objects: any[] };
    };
    isTitleNodeName?: boolean;
}

export function getScopeTitle(params: ScopeTitleParams, prefix = '') {
    if (!params.data?.quickScope || !params.data?.queryDetail) {
        return prefix + params.displayName;
    }

    let plugin, types, objects;
    if (typeof params.data.queryDetail === 'string') {
        ({ plugin, types, objects } = JSON.parse(params.data.queryDetail || '{}'));
    } else {
        ({ plugin, types, objects } = params.data.queryDetail);
    }

    let typesString = '';
    if (types) {
        typesString = types.length > 0
            ? types.map((type: any) => type.displayName).join(', ')
            : 'All types';
    }

    let objectsString = '';
    if (objects) {
        objectsString = objects.length > 0
            ? objects.map((object: any) => object.properties.name[0].value).join(', ')
            : 'All objects';
    }

    let title = prefix + (plugin?.displayName || SqUpPluginDisplayName);

    const joinChars = ' ❯ ';

    if (typesString) {
        title += joinChars + typesString;
    }

    if (objectsString) {
        title += joinChars + objectsString;
    }

    return title;
}
