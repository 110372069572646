import { faFilter, faSearch, faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TabularData } from 'dashboard-engine/types/data/TabularData';
import { Visualisation } from 'dashboard-engine/types/Visualisation';
import { useMemo, useState } from 'react';
import {
    FilterProps,
    useAsyncDebounce,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    useTable
} from 'react-table';
import { TableConfig } from './Config';
import clsx from 'clsx';

// UI for global filtering
const GlobalFilter = ({
    globalFilter,
    setGlobalFilter
}: {
    globalFilter: string;
    setGlobalFilter: (v: unknown) => void;
}) => {
    const setGlobalFilterDebounced = useAsyncDebounce((v) => {
        setGlobalFilter(v || undefined);
    }, 200);

    return (
        <div
            className={
                'self-end mb-3 flex items-center flex-shrink-0 pl-2 py-1 text-sm leading-input ring-1 ring-outlinePrimary rounded-input bg-componentBackgroundPrimary'
            }
        >
            <FontAwesomeIcon icon={faSearch} fixedWidth className={'self-center my-2 cursor-pointer mr-2'} />
            <input
                className={
                    'font-sans self-center flex-grow p-0 placeholder-textIncomplete bg-transparent border-0 focus:outline-none focus:ring-0'
                }
                placeholder='search...'
                defaultValue={globalFilter || ''}
                onChange={(e) => {
                    setGlobalFilterDebounced(e.target.value || undefined);
                }}
            />
        </div>
    );
};

/**
 *
 * @param data - data should be in the form of an array of objects, with any number of properties:
 *
 * `[
 *      {
 *          col1: 'hello',
 *          col2: 'world'
 *      },
 *      {
 *          col1: 'whatever',
 *          col2: 'you want'
 *      }
 * ]`
 *
 * @param config - config should include a `columns` array property describing how to display the passed in data:
 *
 * `[
 *      {
 *          Header: 'Column 1 Title',
 *          accessor: 'col1'
 *      },
 *      {
 *          Header: 'External Link',
 *          accessor: 'col2',
 *          externalLinkLabel: 'col1'  // will use value at accessor as label
 *      }
 * ]`
 *
 */
const Table: Visualisation<TabularData, TableConfig> = ({ data, config }) => {
    const [isFiltering, setIsFiltering] = useState(false);

    const defaultColumn = {
        minWidth: 50,
        Filter: ({ column: { filterValue, setFilter } }: FilterProps<object>) => (
            <div className='flex items-center px-5 py-2 shrink-0 bg-tileBackground'>
                <input
                    className='w-full p-1 px-1 bg-transparent border-0 placeholder-textIncomplete focus:outline-none'
                    placeholder='filter...'
                    defaultValue={filterValue}
                    onChange={
                        (e) => setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                    }
                />
            </div>
        )
    };

    const columns = useMemo(
        () =>
            config.columns?.map((col, i) => {
                const linkLabel = col.externalLinkLabel;

                if (linkLabel && col.Cell == null) {
                    const url = col.accessor;
                    // use the link label as the cell value so sorting and filtering works as expected
                    col.accessor = linkLabel;

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    col.Cell = ({ cell, row }: any) => {
                        return (
                            <a
                                className='underline text-textLink'
                                target='_blank'
                                rel='noopener noreferrer'
                                href={row.original[url]}
                            >
                                {cell.value}
                            </a>
                        );
                    };
                }

                // turn off filtering as we don't have any way of doing type or column-specific
                // filtering UI yet, e.g. date pickers or dropdowns
                col.disableFilters = true;

                // last column can't be resized directly
                if (i === (config.columns?.length || 0) - 1) {
                    col.disableResizing = true;
                }

                return col;
            }) || [],
        [config.columns]
    );

    const tableData = useMemo(() => data, [data]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable(
        {
            columns,
            data: tableData,
            defaultColumn,
            disableSortBy: config.disableSortBy,
            autoResetSortBy: config.autoResetSortBy,
            autoResetGlobalFilter: config.autoResetGlobalFilter
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useGlobalFilter,
        useSortBy
    );

    return tableData.length > 0 ? (
        <div className='flex flex-col h-full'>
            {!config.hideSearch && <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />}
            <div className='overflow-hidden'>
                <div className='tile-scroll-overflow'>
                    <div {...getTableProps()} className='overflow-hidden leading-normal' data-testid='table'>
                        <div>
                            {headerGroups.map((headerGroup) => (
                                <div {...headerGroup.getHeaderGroupProps()} className='max-w-full min-w-full'>
                                    {headerGroup.headers.map((column) => (
                                        <div
                                            {...column.getHeaderProps()}
                                            className={clsx(
                                                'px-4 font-semibold text-left break-word last:flex-grow group',
                                                column.canGrow && 'flex-grow',
                                                !column.hideHeader && 'pb-2'
                                            )}
                                        >
                                            <div className='flex justify-between h-full'>
                                                <div className='flex flex-col justify-between w-full h-full'>
                                                    <div className='flex items-center'>
                                                        <span {...column.getSortByToggleProps()}>
                                                            {!column.hideHeader && column.render('Header')}
                                                            {column.canSort && column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <FontAwesomeIcon
                                                                        icon={faSortDown}
                                                                        className='ml-2'
                                                                    />
                                                                ) : (
                                                                    <FontAwesomeIcon icon={faSortUp} className='ml-2' />
                                                                )
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </span>

                                                        {column.canFilter && (
                                                            <FontAwesomeIcon
                                                                icon={faFilter}
                                                                fixedWidth
                                                                size='1x'
                                                                className={`${
                                                                    isFiltering
                                                                        ? 'visible'
                                                                        : 'invisible group-hover:visible'
                                                                } ml-2 cursor-pointer`}
                                                                onClick={() => setIsFiltering(!isFiltering)}
                                                            />
                                                        )}
                                                    </div>

                                                    {column.canFilter && isFiltering && (
                                                        <div className='mt-3'>{column.render('Filter')}</div>
                                                    )}
                                                </div>

                                                {column.canResize && (
                                                    <div
                                                        {...column.getResizerProps()}
                                                        className='absolute top-0 right-0 z-0 w-1 h-full transform-gpu translate-x-2/4 hover:bg-textSecondary'
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className='relative group' {...getTableBodyProps()}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <div
                                        {...row.getRowProps()}
                                        className={`max-w-full min-w-full ${
                                            config.striped !== false ? 'even:bg-dividerTertiary' : ''
                                        }`}
                                        data-testid='tableRow'
                                    >
                                        {row.cells.map((cell) => {
                                            return (
                                                <div
                                                    {...cell.getCellProps()}
                                                    className={clsx(
                                                        'self-center px-4 py-1 break-words last:flex-grow',
                                                        cell.column.canGrow && 'flex-grow'
                                                    )}
                                                >
                                                    {Array.isArray(cell.value)
                                                        ? cell.value.map((value, ValueIndex) => {
                                                              if (typeof value === 'object') {
                                                                  return (
                                                                      <div
                                                                          key={ValueIndex}
                                                                          className={`${
                                                                              ValueIndex < cell.value.length - 1
                                                                                  ? 'border-b border-dividerTertiary pb-2 mb-2'
                                                                                  : ''
                                                                          }`}
                                                                      >
                                                                          {Object.entries(value as object).map(
                                                                              ([k, v], index) => {
                                                                                  return (
                                                                                      <div key={index} className='flex'>
                                                                                          <em className='mr-1'>
                                                                                              {k}:{' '}
                                                                                          </em>{' '}
                                                                                          {`${v}`}
                                                                                      </div>
                                                                                  );
                                                                              }
                                                                          )}
                                                                      </div>
                                                                  );
                                                              } else {
                                                                  return (
                                                                      <div
                                                                          key={ValueIndex}
                                                                          className={`${
                                                                              ValueIndex < cell.value.length - 1
                                                                                  ? 'border-b border-dividerTertiary pb-2 mb-2'
                                                                                  : ''
                                                                          }`}
                                                                      >
                                                                          {`${value}`}
                                                                      </div>
                                                                  );
                                                              }
                                                          })
                                                        : cell.render('Cell')}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className='px-3 py-2 text-sm text-center break-words text-textIncomplete bg-dividerTertiary'>
            {config.noDataMessage || 'No data to display'}
        </div>
    );
};

Table.config = 'TableConfig';

export default Table;
