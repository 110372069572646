type PaletteOption = string[] | Record<string, string>;

export type CustomPalette = { palette?: PaletteOption };

/**
 * Get a color for the given datum based on config and theme
 * @param config Tile config
 * @param defaultPalette Default theme
 * @param id Datum ID
 * @param index Datum Index
 * @returns Color
 */
export const getColor = function <T extends CustomPalette>(
    config: T,
    defaultPalette: PaletteOption,
    id: string,
    index: number
) {
    const palette = config.palette ?? defaultPalette;

    if (Array.isArray(palette)) {
        return palette[index % palette.length];
    }

    return palette[id];
};
