import type { HealthState } from '@squaredup/monitoring';
import { WorkspacesDependenciesMap } from 'dashboard-engine/visualisations/Network/utils/generateWorkspaceNetwork';
import type { KPI } from 'dynamo-wrapper';
import { defaultFilterCriteria, FilterCriteria, WorkspaceWithDependencies } from 'pages/monitoring/common';
import { createContext } from 'react';
import { TileState } from 'services/HealthService';

interface WorkspaceNetworkContextInterface {
    workspacesWithDependencies: WorkspaceWithDependencies[];
    workspacesWithDependenciesByNodeId: WorkspacesDependenciesMap;
    editingEnabled: boolean;
    filterCriteria: FilterCriteria;
    setFilterCriteria?: React.Dispatch<React.SetStateAction<FilterCriteria>>;
    setExpandedNodeIds: React.Dispatch<React.SetStateAction<string[]>>;
    healthData?: Map<string, { state: HealthState; tileStates: TileState[] }>;
    pluginDetails?: Map<string, { id: string; name?: string; displayName?: string }[]>;
    kpiDetails?: { kpiMap: Map<string, KPI[]>; kpiData: KPI[] };
}

export const WorkspaceNetworkContext = createContext<WorkspaceNetworkContextInterface>({
    workspacesWithDependencies: [],
    workspacesWithDependenciesByNodeId: new Map(),
    editingEnabled: false,
    filterCriteria: defaultFilterCriteria,
    setFilterCriteria: undefined,
    setExpandedNodeIds: () => []
});
