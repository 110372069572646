import { Serialised } from '@squaredup/ids';
import { AgentGroupOptions } from 'components/hooks/useAgentGroups';
import { CloseHandler } from 'components/Modal';
import type { ProjectedPlugin } from 'dynamo-wrapper';
import { useCallback, useState } from 'react';
import { useZendeskLabels } from 'ui/zendesk/ZendeskContext';
import PluginConfigModal from './PluginConfigModal';
import { SelectPluginModal } from './SelectPluginModal';
import { useSearchParams } from 'react-router-dom';

interface PluginModalProps {
    agentGroups: AgentGroupOptions;
    workspaceToLinkTo?: string;
    close: CloseHandler;
}

const PluginModal: React.FC<PluginModalProps> = ({ 
    agentGroups, 
    workspaceToLinkTo = '', 
    close 
}) => {
    useZendeskLabels('add plugin');
    const [, setSearchParams] = useSearchParams();

    const [selectedPlugin, setSelectedPlugin] = useState<Serialised<ProjectedPlugin> | undefined>(undefined);

    const handlePluginModalClose = useCallback((reason, details) => {
        if (reason === 'back') {
            setSelectedPlugin(undefined);
            return;
        }

        close(reason, details);

        // Remove any search params that were added
        setSearchParams({});
    }, [close, setSearchParams]);

    return !selectedPlugin ? (
        <SelectPluginModal 
            setSelectedPlugin={setSelectedPlugin}
            close={handlePluginModalClose}
        />
    ) : (
        <PluginConfigModal
            agentGroups={agentGroups}
            selectedPlugin={selectedPlugin}
            workspaceToLinkTo={workspaceToLinkTo}
            showBackButton={true}
            close={handlePluginModalClose}
        />
    );
};

export default PluginModal;
