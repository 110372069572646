import { Button, buttonVariants } from '@/components/Button';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { FeatureDisplay, FeatureKey } from '@squaredup/tenants';
import { Pill } from 'components/pill/Pill';
import trackEvent from 'lib/analytics';
import { PlanUpgradeModal } from 'pages/usage/PlanUpgradeModal';
import { useState } from 'react';
import { ProjectedTenantResponse } from 'services/TenantService';

type HeadingProps = React.HTMLAttributes<HTMLDivElement> & {
    feature: FeatureDisplay;
    title?: string;
    hidePill?: boolean;
};

export const Heading = ({ title, feature, hidePill, ...props }: HeadingProps) => (
    <div className='flex mb-2 max-w-fit' {...props}>
        <Text.H3 className={cn(['text-textPrimary', hidePill && 'mr-16'])}>
            {title ?? feature.singular ?? feature.displayName}
        </Text.H3>
        {!hidePill && (
            <Pill className='flex items-center ml-4 bg-upgrade text-primaryButtonText h-min' variant='rounded'>
                <Text.H3>{feature.availableInTier}</Text.H3>
            </Pill>
        )}
    </div>
);

type SummaryProps = {
    summary?: string;
    canUpgrade: boolean;
    feature: FeatureDisplay;
    className?: string;
};

export const Summary = ({ summary, canUpgrade, feature, className }: SummaryProps) => (
    <Text.Body className={cn('w-0 min-w-full text-textPrimary', className)}>
        {summary ?? feature.upgradeMessage} {!canUpgrade && 'Please contact your administrator to upgrade.'}
    </Text.Body>
);

type LearnMoreProps = React.HTMLAttributes<HTMLAnchorElement> & { learnMoreUrl: string };

export const LearnMore = ({ learnMoreUrl, ...props }: LearnMoreProps) => (
    <Text.Body>
        <a
            className={buttonVariants({ variant: 'link' })}
            href={learnMoreUrl}
            target='_blank'
            rel='noopener noreferrer'
            {...props}
        >
            Learn more
        </a>
    </Text.Body>
);

type UpgradeButtonProps = Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'onClick'> & {
    canUpgrade: boolean;
    onClick: () => void;
    isUpgradePending: boolean;
    featureKey: FeatureKey;
};

export const UpgradeButton = ({ canUpgrade, onClick, isUpgradePending, featureKey, ...props }: UpgradeButtonProps) => {
    if (!canUpgrade) {
        return <></>;
    }

    return (
        <div {...props}>
            <Button
                type='button'
                variant='upgrade'
                onClick={onClick}
                className='text-[14px]'
                disabled={isUpgradePending}
                data-testid={`upgrade-button-${featureKey}`}
            >
                {isUpgradePending ? 'Upgrading...' : 'Compare plans'}
            </Button>
        </div>
    );
};

export const useShowUpgradeModal = (tenant?: ProjectedTenantResponse, featureKey?: FeatureKey) => {
    const [isUpgradeModalOpen, setUpgradeModalOpen] = useState(false);

    const onUpgradeModalOpen = () => {
        if (!tenant) {
            return undefined;
        }

        setUpgradeModalOpen(true);
        trackEvent('Upgrade Modal Opened', { tenantId: tenant.id });
    };

    const upgradeModal =
        tenant && isUpgradeModalOpen ? (
            <PlanUpgradeModal onClose={() => setUpgradeModalOpen(false)} tenant={tenant} featureKey={featureKey} />
        ) : null;

    return {
        openUpgradeModal: onUpgradeModalOpen,
        upgradeModal
    };
};

type FeatureUnavailableContentProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
    feature: FeatureDisplay;
    title?: string;
    canUpgrade: boolean;
    summary?: string;
    onUpgradeClicked: () => void;
    isUpgradePending: boolean;
    hidePill?: boolean;
};

export const FeatureUnavailableContent = ({
    feature,
    title,
    canUpgrade,
    summary,
    onUpgradeClicked,
    isUpgradePending,
    hidePill
}: FeatureUnavailableContentProps) => (
    <>
        <div className='flex mb-2 leading-6 gap-9'>
            <div className='flex-grow'>
                <Heading title={title} feature={feature} hidePill={hidePill} />
                <Summary canUpgrade={canUpgrade} feature={feature} summary={summary} />
            </div>
            <UpgradeButton
                canUpgrade={canUpgrade}
                onClick={onUpgradeClicked}
                isUpgradePending={isUpgradePending}
                featureKey={feature.key}
            />
        </div>
        <LearnMore learnMoreUrl={feature.kbLink} />
    </>
);

export const NarrowFeatureUnavailableContent = ({
    feature,
    title,
    canUpgrade,
    summary,
    children,
    onUpgradeClicked,
    isUpgradePending,
    hidePill
}: FeatureUnavailableContentProps) => (
    <div className='flex flex-col leading-6'>
        <div className='flex items-start mb-7 gap-9'>
            <div className='flex-grow'>
                <Heading title={title} feature={feature} hidePill={hidePill} />
                <Summary canUpgrade={canUpgrade} feature={feature} summary={summary} />
            </div>
            {children}
        </div>
        <div className={cn('flex items-center gap-6', canUpgrade && 'ml-auto')}>
            <LearnMore learnMoreUrl={feature.kbLink} />
            <UpgradeButton
                canUpgrade={canUpgrade}
                onClick={onUpgradeClicked}
                isUpgradePending={isUpgradePending}
                featureKey={feature.key}
            />
        </div>
    </div>
);
