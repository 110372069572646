import { useState, useEffect } from 'react';

// This hook is used to detect when a request takes longer than a given duration
export function useSlowLoading(loading: boolean, duration: number): boolean {
    const [slow, setSlow] = useState(false);
    useEffect(() => {
        let timeout: number | undefined;
        if (loading) {
            timeout = window.setInterval(() => setSlow(true), duration);
        } else {
            setSlow(false);
        }
        return () => {
            timeout && window.clearInterval(timeout);
        };
    }, [loading, duration]);

    return slow;
}

export default useSlowLoading;
