import { Node } from '@squaredup/graph';

/**
 * Get a node's drilldown link
 * @param node
 * @returns drilldown link
 */
export const getDrilldownLink = (node: Node) => {
    const sourceType = node.sourceType?.[0] ?? '';
    switch (sourceType) {
        case 'squaredup/space':
            return `/workspace/${node.sourceId}`;
        case 'squaredup/dash':
            return `/dashboard/${node.sourceId}`;
        case 'squaredup/scope':
            return `/scope/${node.sourceId}`;
        case 'squaredup/monitor':
            return `/dashboard/${node.dashId}`;
        case 'squaredup/tile':
            return `/dashboard/${node.dashId}`;
        case 'squaredup/kpi':
            return `/dashboard/${node.dashId}`;
        case 'squaredup/data-source':
            return `/datasource/${node.sourceId}`;
        default:
            return `/drilldown/node/${node.id}`;
    }
};
