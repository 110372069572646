import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getStateInfo } from './svgUtils';

interface HealthIconDefinitionProps {
    id: string;
    state: string;
}

export const HealthIconDefinition: React.FC<HealthIconDefinitionProps> = ({ id, state }) => {
    const { icon, colour } = getStateInfo(state);
    return (
        <svg id={`health-icon-${id}`} width={24} height={24} viewBox='0 0 24 24'>
            <filter
                x='-40%'
                y='-40%'
                width='180%'
                height='180%'
                id={`health-icon-${id}-shadow`}
                colorInterpolationFilters='sRGB'
            >
                <feDropShadow dx='0.5' dy='0.5' stdDeviation='3' floodOpacity='0.75' />
            </filter>
            <g transform='translate(2 2)'>
                <circle r={12} fill='white' cx={10} cy={10} />
                <FontAwesomeIcon icon={icon} style={{ color: colour }} width={20} height={20} />
            </g>
        </svg>
    );
};
