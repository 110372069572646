import { isNoData, isNone } from '@squaredup/data-streams';
import NoDataPlaceholder from 'components/NoDataPlaceholder';
import { useMemo } from 'react';
import { DataStreamVisualisation } from '../../types/Visualisation';
import IFrame from '../IFrame/IFrame';
import { DataStreamIframeConfig } from './Config';
import { getIframeColumns } from './dataUtils';

/**
 * Renders an iframe based on data stream data
 * e.g. using a url shape or link role
 * @param data Data stream data
 * @param config Configuration object
 * @returns
 */
const DataStreamIframe: DataStreamVisualisation<DataStreamIframeConfig> = ({ data, config }) => {
    const { linkColumn } = getIframeColumns(data.metadata.columns, config).throwIfFailed();

    const value = useMemo(() => {
        if (isNoData(data) || isNone(data.rows[0]?.[linkColumn.dataIndex].value)) {
            return null;
        }
        return data.rows[0]?.[linkColumn.dataIndex];
    }, [linkColumn, data]);

    return value !== null ? (
        <div className='w-full h-full leading-loose'>
            <IFrame
                config={{
                    src: value.raw as string,
                    scale: config.scale || 1
                }}
                data={{}}
            />
        </div>
    ) : (
        <NoDataPlaceholder />
    );
};

DataStreamIframe.config = 'DataStreamIframeConfig';

export default DataStreamIframe;
