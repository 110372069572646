import { cn } from '@/lib/cn';
import { TruncateList } from 'components/TruncateList';
import Text from 'components/primitives/components/Text';
import stringify from 'fast-json-stable-stringify';

interface DataStreamTagsFilterProps {
    tags: string[];
    selectedTags: string[];
    toggleTag: (tag: string) => void;
}

export const DataStreamTagsFilter: React.FC<DataStreamTagsFilterProps> = ({ tags, selectedTags, toggleTag }) => {
    return (
        <div className='pb-3 mb-4 mt-1 border-b border-dividerPrimary'>
            <div className='pl-6 pr-5'>
                <TruncateList
                    // Force list to remount and recalculate if tags change
                    key={stringify(tags)}
                    canExpand={true}
                    itemMargin={8}
                    className='max-h-[112px] overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'
                    items={tags.map((tag) => ({
                        label: tag,
                        component: (
                            <button
                                key={tag}
                                className={cn(
                                    'bg-tileBackground border border-outlinePrimary rounded-full px-3 py-1 mr-2 mb-2',
                                    {
                                        'bg-filterActive': selectedTags.includes(tag)
                                    }
                                )}
                                data-testid='datastreamTag'
                                onClick={() => toggleTag(tag)}
                            >
                                <Text.H4 className='text-textSecondary'>{tag}</Text.H4>
                            </button>
                        )
                    }))}
                />
            </div>
        </div>
    );
};
