import DropdownMenu from '@/components/DropdownMenu';
import { faCircleNotch, faRefresh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTileContext } from 'contexts/TileContext';
import { useForceRefresh } from 'dashboard-engine/hooks/useForceRefresh';
import type { FC } from 'react';

export const ForceRefresh: FC = () => {
    const { config } = useTileContext();
    const { forceRefresh, isRefreshing } = useForceRefresh(config);

    return (
        <DropdownMenu.Item
            icon={<FontAwesomeIcon icon={isRefreshing ? faCircleNotch : faRefresh} spin={isRefreshing} fixedWidth />}
            onSelect={() => forceRefresh()}
        >
            Force refresh
        </DropdownMenu.Item>
    );
};
