import { edge } from 'components/map/layout/edges/edge';
import { entityToNode } from 'components/map/layout/nodes/entityToNode';
import { Edge } from 'reactflow';
import { NodesAndEdges, PinnableNode } from '../types';

type GraphNodes = NodesAndEdges['nodes']
type GraphEdges = NodesAndEdges['edges']

export const graphEntitiesToFlowNodes = (
    graphNodes?: GraphNodes, 
    graphEdges?: GraphEdges
) => {
    return {
        nodes: graphNodes?.map(entityToNode) ?? [] as PinnableNode[],
        edges: graphEdges?.map((e: any) => edge(e.outV, e.inV, e.label)) ?? [] as Edge[]
    };
};