import { fiveMinutes } from 'queries/constants';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { ListForLinking } from 'services/WorkspaceService';

type QueryReturnType = Awaited<ReturnType<typeof ListForLinking>>;
type QuerySelectReturnType = QueryReturnType;

/**
 * useQuery returning the data source configs for linking for a tenant
 * @param options The react-query options e.g. refreshInterval
 * @returns Data source configs for linking in a workspace
 */
export const useWorkspacesForLinking = (
    workspaceId?: string | null,
    options?: Omit<UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>, 'enabled'>
) => 
    useQuery(
        workspaceQueryKeys.forLinking(workspaceId), 
        async () => ListForLinking(workspaceId), 
        {
            staleTime: fiveMinutes,
            cacheTime: fiveMinutes,
            keepPreviousData: true,
            ...options
        }
    );
