import { Button } from '@/components/Button';
import { StackedInitialAvatars, StackedInitialAvatarsSkeleton } from '@/components/InitialAvatar';
import { Skeleton } from '@/components/Skeleton';
import Text from '@/components/Text';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFeatureLimit } from '@squaredup/tenants';
import LoadingSpinner from 'components/LoadingSpinner';
import { Presence } from 'components/Presence';
import { useShowUpgradeModal } from 'components/plans/common';
import AgentStatusIcon from 'pages/settings/agents/AgentStatusIcon';
import pluralize from 'pluralize';
import { useInviteUserToDashboard } from 'queries/hooks/useInviteUserToDashboard';
import { useTenant } from 'queries/hooks/useTenant';
import { useTenantUsers } from 'queries/hooks/useTenantUsers';
import { useTier } from 'queries/hooks/useTier';
import { ComponentPropsWithoutRef, Suspense, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IsTenantAdmin } from 'services/AccessControlService';
import { useDashboardId } from 'ui/hooks/useDashboardId';
import { z } from 'zod';
import CopyDashboardLink from '../components/CopyDashboardLink';

export const DashboardUsers = () => {
    const tenant = useTenant();
    const isTenantAdmin = Boolean(IsTenantAdmin(tenant.data));

    return (
        <>
            <Text.H4 className='font-semibold'>Users</Text.H4>

            <SubHeaderContainer>
                <Suspense fallback={<NumberOfTenantUsersSkeleton />}>
                    <NumberOfTenantUsers />
                </Suspense>

                <CopyDashboardLink />
            </SubHeaderContainer>

            <Suspense fallback={<StackedInitialAvatarsSkeleton />}>
                <TenantUsersInitials />
            </Suspense>

            <Presence isPresent={isTenantAdmin}>
                <InviteUserForm />
            </Presence>
        </>
    );
};

const SubHeaderContainer = (props: ComponentPropsWithoutRef<'div'>) => {
    return <div className='flex justify-between' {...props} />;
};

const NumberOfTenantUsers = () => {
    const tenantUsers = useTenantUsers({ suspense: true });
    const numberOfTenantUsers = tenantUsers.data?.length ?? 0;

    return (
        <Text.Body className='text-textSecondary'>
            {numberOfTenantUsers} {pluralize('user', numberOfTenantUsers)} can view this dashboard.
        </Text.Body>
    );
};

const NumberOfTenantUsersSkeleton = () => {
    return <Skeleton className='w-56 h-2 my-2' />;
};

const TenantUsersInitials = () => {
    const tenantUsers = useTenantUsers({ suspense: true });

    const tenantUsersEmails = tenantUsers.data?.map((tenant) => tenant.id);

    if (!tenantUsersEmails?.length) {
        return null;
    }

    return <StackedInitialAvatars texts={tenantUsersEmails} />;
};

type InviteUserFormData = {
    email: string;
    dashboardId: string;
};

const InviteUserForm = () => {
    const dashboardId = useDashboardId();
    const form = useForm<InviteUserFormData>({ defaultValues: { email: '', dashboardId } });

    const inviteUserToDashboard = useInviteUserToDashboard();

    const onSubmit = (props: InviteUserFormData) => {
        inviteUserToDashboard.mutate(props, { onSuccess: () => form.reset() });
    };

    useEffect(() => document.getElementById('invite-user-email-input')?.focus(), []);

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <input
                    type='email'
                    id='invite-user-email-input'
                    placeholder='Enter an email address...'
                    disabled={inviteUserToDashboard.isLoading}
                    className='flex-1 mr-5 border-0 leading-input text-textPrimary py-input autofill:text-textPrimary px-md placeholder-textIncomplete disabled:text-textDisabled disabled:pointer-events-none ring-1 rounded-input bg-componentBackgroundPrimary ring-primaryButtonBackground focus:ring-primaryButtonBackgroundHover'
                    {...form.register('email', {
                        validate: (unsafeEmail) => z.string().trim().max(255).email().safeParse(unsafeEmail).success
                    })}
                />

                <Button disabled={inviteUserToDashboard.isLoading || !form.formState.isValid}>
                    <Presence isPresent={inviteUserToDashboard.isLoading}>
                        <LoadingSpinner size={18} className='mr-3' />
                    </Presence>
                    <span>Invite user</span>
                </Button>
            </Form>

            <Presence
                isPresent={/User limit reached: \d/iu.test(inviteUserToDashboard.error?.response?.data.error ?? '')}
            >
                <FormStatusContainer>
                    <UpgradeIcon />
                    <UserLimitReachedText />
                </FormStatusContainer>
            </Presence>

            <Presence isPresent={/user already joined/iu.test(inviteUserToDashboard.error?.message ?? '')}>
                <FormStatusContainer>
                    <AgentStatusIcon state={'error'} title={''} />
                    <UserAlreadyAcceptedInvite email={inviteUserToDashboard.variables?.email ?? ''} />
                </FormStatusContainer>
            </Presence>

            <Presence isPresent={inviteUserToDashboard.status === 'success'}>
                <FormStatusContainer>
                    <AgentStatusIcon state={'success'} title={''} />
                    <InvitationConfimationText />
                </FormStatusContainer>
            </Presence>
        </FormProvider>
    );
};

const UpgradeIcon = () => {
    return (
        <span className='text-upgrade'>
            <FontAwesomeIcon icon={faCircle} />
        </span>
    );
};

const UserLimitReachedText = () => {
    const { data: tenant } = useTenant();
    const { data: tier } = useTier();
    const { openUpgradeModal, upgradeModal } = useShowUpgradeModal(tenant, 'users');
    const userLimit = tier ? getFeatureLimit(tier, 'users') : { value: 0 };
    // Ignoring isUnlimited as this will never be hit in that scenario
    const maxNumberOfUsers = 'value' in userLimit ? userLimit.value : 0;
    return (
        <>
            <Text.Body>
                User limit reached ({maxNumberOfUsers}). Upgrade to increase your limit.{' '}
                <Button
                    type='button'
                    variant='link'
                    onClick={openUpgradeModal}
                    data-testid='sharing-users-compare-plans'
                >
                    Compare plans.
                </Button>
            </Text.Body>
            {upgradeModal}
        </>
    );
};

const UserAlreadyAcceptedInvite = (props: Pick<InviteUserFormData, 'email'>) => {
    return <Text.Body>{props.email} already has access to this dashboard.</Text.Body>;
};

const InvitationConfimationText = () => {
    return <Text.Body>User added and invitation sent successfully.</Text.Body>;
};

const FormStatusContainer = (props: ComponentPropsWithoutRef<'div'>) => {
    return <div {...props} className='flex items-center gap-2 mt-2 ml-4 animate-in fade-in' />;
};

const Form = (props: ComponentPropsWithoutRef<'form'>) => {
    return <form {...props} className='flex mt-2' />;
};
