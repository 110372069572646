import {
    faChevronDown,
    faChevronUp,
    faCircleCheck,
    faCircleExclamation,
    faXmark
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import Button from 'components/button/Button';
import Copy from 'components/Copy';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

export const toastIds = {
    ERROR: '1',
    SUCCESS: '2'
};

/**
 * Creates a toast in the top right corner with a clipboard component
 * Intended for displaying error messages
 */
function ClipboardToast(message: string, isError = true, id = toastIds.ERROR) {
    const updateDuration = (duration: number, fold: boolean) => {
        toast.custom(
            (toastProperties) => (
                <ToastContent
                    toastProperties={toastProperties}
                    fold={fold}
                    isError={isError}
                    message={message}
                    updateDuration={updateDuration}
                />
            ),
            {
                duration,
                position: 'bottom-right',
                id
            }
        );
    };

    toast.custom(
        (toastProperties) => (
            <ToastContent
                toastProperties={toastProperties}
                message={message}
                isError={isError}
                updateDuration={updateDuration}
            />
        ),
        {
            position: 'bottom-right',
            id
        }
    );
}

interface ToastContentProps {
    fold?: boolean;
    updateDuration: (duration: number, fold: boolean) => void;
    toastProperties: {
        id: string;
        visible: boolean;
    };
    isError: boolean;
    message: string;
}

const ToastContent = ({ fold, updateDuration, toastProperties, isError, message }: ToastContentProps) => {
    const [folded, setFolded] = useState(fold ?? false);

    const toggleDisplay = () => {
        setFolded(!folded);
        updateDuration(folded ? 4000 : Infinity, folded);
    };

    const closeToast = () => {
        toast.dismiss(toastProperties.id);
    };

    return (
        <div
            className={clsx(
                toastProperties.visible ? 'animate-entry' : 'animate-leave',
                isError ? 'border-statusErrorPrimary' : 'border-statusHealthyPrimary',
                'text-sm m-4 mr-12 text-textPrimary bg-tooltipBackground rounded border-l-4 relative'
            )}
            data-theme='dark'
        >
            <div className='flex items-center px-4 py-3 right-1 bg-tooltipBackground'>
                <FontAwesomeIcon icon={isError ? faCircleExclamation : faCircleCheck} fixedWidth />

                {isError ? (
                    <div>
                        <span className='px-2 mr-4'>Something went wrong, please try again.</span>
                        <span className='space-x-2'>
                            <Button variant='tertiary' onClick={toggleDisplay}>
                                <FontAwesomeIcon icon={folded ? faChevronUp : faChevronDown} />
                            </Button>
                            <Button variant='tertiary' onClick={closeToast}>
                                <FontAwesomeIcon icon={faXmark} />
                            </Button>
                        </span>
                    </div>
                ) : (
                    <>
                        <span className='px-2 mr-4'>{message}</span>
                    </>
                )}
            </div>

            {folded && (
                <div className='relative flex items-baseline px-4 mt-1 border-t py-xs border-dividerPrimary'>
                    <pre className='flex-grow pr-6 overflow-x-auto'>{message}</pre>
                    <Copy value={message} />
                </div>
            )}
        </div>
    );
};

export default ClipboardToast;
