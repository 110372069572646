import { ConfirmationPrompt } from 'components/ConfirmationPrompt';
import Button from 'components/button/Button';
import type { AccessControlEntryModel, AcePermission, UserGroupModel } from 'dynamo-wrapper';
import { isEqual, sortBy } from 'lodash';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { GetEntityACL, AccessControlQueryKeys } from 'services/AccessControlService';
import { DeleteAllWorkspaceLinks, GetWorkspaceLinks, PLUGIN_WORKSPACE_LINKS } from 'services/SourceConfigService';
import { invalidateAfterWorkspaceLinksChange } from 'services/WorkspaceUtil';
import CustomACLEditor, { PermissionOption } from './CustomACLEditor';
import { useFormContext } from 'react-hook-form';
import ToggleField from './ToggleField';
import Auth from 'services/Auth';
import { useGroupsForACLEditor } from 'queries/hooks/useGroupsForACLEditor';
import { FeatureUnavailablePill } from 'components/plans/FeatureUnavailablePill';
import { useTier } from 'queries/hooks/useTier';
import { isFeatureEnabled } from '@squaredup/tenants';

export const standardACLPresetNames = {
    custom: 'custom'
};

export const aclTemplateParams = {
    userExternalId: '{{userExternalId}}',
    everyoneGroupId: '{{everyoneGroupId}}'
};

/**
 * When enabling access control, start with an ACL that gives the current user 'Full control'
 * and everyone else 'read only' (which for workspaces means 'Viewer' and plugins means 'Can link to workspace').
 */
const defaultPermissionsWhenAccessControlEnabled: AccessControlEntryModel[] = [
    {
        subjectId: aclTemplateParams.userExternalId,
        permissions: ['AD']
    },
    {
        subjectId: aclTemplateParams.everyoneGroupId,
        permissions: ['RO']
    }
];

type AccessControlArgs = {
    entityId?: string;
    entityName?: string;
    permissionOptions: PermissionOption[];
    defaultPermissionsForNewACE: AcePermission[];
    defaultPermissions?: AccessControlEntryModel[];
    customACLEditorTitle: string;
    onACLChange: (acl: AccessControlEntryModel[]) => void;
    toggleTitle?: string;
    allowUnlinkAll?: boolean;
    helpMessage?: string;
    description?: string;
};

const querySettings = {
    refetchOnMount: 'always' as const // Just in case another user/browser has modified the permissions or workspace links
};

/**
 * Small control for injecting into config pages for workspaces, plugins etc
 *
 * Allows user to choose from access control 'presets' like 'Everyone can view',
 * and also offers a 'Custom' option with pop-up modal editor for the ACL.
 */
export const AccessControl: React.FC<AccessControlArgs> = ({
    entityId,
    entityName,
    permissionOptions,
    defaultPermissionsForNewACE,
    onACLChange,
    toggleTitle = 'Access Control',
    allowUnlinkAll = false,
    defaultPermissions = defaultPermissionsWhenAccessControlEnabled,
    helpMessage = 'Enable this to manage user and group access to this workspace',
    description = 'Everyone in your organization currently has access to this workspace'
}: AccessControlArgs) => {
    const queryClient = useQueryClient();
    const [isValid, setIsValid] = useState(true);
    const [accessControlEnabled, setAccessControlEnabled] = useState(false);
    const [promptForUnlinkAll, setPromptForUnlinkAll] = useState(false);
    const [updatedACL, setUpdatedACL] = useState<AccessControlEntryModel[]>();
    const { setError, clearErrors } = useFormContext();
    const { data: tier } = useTier();
    const isACLFeatureEnabled = tier && isFeatureEnabled(tier, 'accessControl');

    const currentUser = Auth.user?.name.toLowerCase().trim();

    const { data: groups } = useGroupsForACLEditor();

    const { data: existingACL } = useQuery(
        AccessControlQueryKeys.EntityACL(entityId),
        async () => {
            if (entityId) {
                return getEntityACLWithoutObjectId(entityId);
            }
            return undefined;
        },
        {
            ...querySettings,
            enabled: Boolean(groups),
            onSuccess: (acls) => {
                setAccessControlEnabled(acls != null && !isAccessControlDisabledACL(acls));
            }
        }
    );

    const { data: workspaceLinks } = useQuery(
        [PLUGIN_WORKSPACE_LINKS, entityId],
        () => (allowUnlinkAll && entityId ? GetWorkspaceLinks(entityId) : undefined),
        querySettings
    );

    function handleACLChange(updatedCustomACL: AccessControlEntryModel[]) {
        setUpdatedACL(updatedCustomACL);
        onACLChange(updatedCustomACL);
        const isAclValid = isACLValid(updatedCustomACL);
        setIsValid(isAclValid);
        if (isAclValid) {
            clearErrors('noFullControl');
        } else {
            setError('noFullControl', {
                type: 'customError',
                message: 'At least one user or group must have full control.'
            });
        }
    }

    function onToggleAccessControlEnabled(newAccessControlEnabled: boolean) {
        setAccessControlEnabled(newAccessControlEnabled);
        if (!newAccessControlEnabled) {
            // Access control is being disabled, so use the appropriate ACL for that.
            groups && handleACLChange(getAccessControlDisabledACL(groups));
        } else if (defaultPermissions) {
            const parsedDefaultPermissions = defaultPermissions.map((permission) => {
                if (permission.subjectId === aclTemplateParams.everyoneGroupId && groups) {
                    permission.subjectId = findTheEveryoneGroup(groups).id!;
                }
                if (permission.subjectId === aclTemplateParams.userExternalId && currentUser) {
                    permission.subjectId = currentUser;
                }
                return permission;
            });
            groups && handleACLChange(parsedDefaultPermissions);
        }
    }

    function isAccessControlDisabledACL(acl?: AccessControlEntryModel[]) {
        return !isACLFeatureEnabled || (acl && groups && areACLsEqual(acl, getAccessControlDisabledACL(groups)));
    }

    async function handleUnlinkAll() {
        if (entityId) {
            await DeleteAllWorkspaceLinks(entityId);
            queryClient.invalidateQueries([PLUGIN_WORKSPACE_LINKS, entityId]);
            invalidateAfterWorkspaceLinksChange(queryClient);
        }
    }

    return (
        <>
            {(
                <ToggleField
                    onToggle={onToggleAccessControlEnabled}
                    toggleDefaultValue={accessControlEnabled}
                    label={toggleTitle}
                    help={helpMessage}
                    description={accessControlEnabled ? undefined : description}
                    upgradePill={<FeatureUnavailablePill featureKey='accessControl' className='ml-3' />}
                    toggleDisabled={!isACLFeatureEnabled}
                >
                    <div className='flex flex-col items-start w-full justify-items-end'>
                        {accessControlEnabled && (
                            <div className='flex flex-col w-full'>
                                <div className='flex items-center w-full'>
                                    <CustomACLEditor
                                        acl={updatedACL ?? existingACL ?? []}
                                        entityId={entityId}
                                        permissionOptions={permissionOptions}
                                        defaultPermissionsForNewACE={defaultPermissionsForNewACE}
                                        onChange={(acl) => handleACLChange(acl)}
                                    />
                                </div>
                                <div className='px-2 pt-2'>
                                    {!isValid && (
                                        <p className='mt-2 text-statusErrorPrimary'>
                                            At least one user or group must have full control.
                                        </p>
                                    )}
                                </div>
                                {allowUnlinkAll && workspaceLinks && workspaceLinks?.length > 0 && entityId && (
                                    <div className='mx-2 mt-3'>
                                        <span>
                                            Added to {workspaceLinks?.length} workspace
                                            {workspaceLinks?.length === 1 ? '' : 's'}.&nbsp;&nbsp;
                                            <Button
                                                type='button'
                                                variant='link'
                                                onClick={() => setPromptForUnlinkAll(true)}
                                            >
                                                Remove from all workspaces
                                            </Button>
                                        </span>
                                        {promptForUnlinkAll && (
                                            <ConfirmationPrompt
                                                title={`Remove ${entityName}`}
                                                prompt={<UnlinkAllPrompt entityName={entityName ?? ''} />}
                                                confirmButtonText='Remove'
                                                onConfirm={handleUnlinkAll}
                                                onClose={() => setPromptForUnlinkAll(false)}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </ToggleField>
            )}
        </>
    );
};

function UnlinkAllPrompt({ entityName }: { entityName: string }) {
    return (
        <div>
            <p className='mt-8'>
                Are you sure you want to remove <span>{entityName}</span> from all workspaces?
            </p>
            <p className='mt-4'>This may cause errors in workspaces that depend on it.</p>
        </div>
    );
}

export interface AccessControlPreset {
    label: string;
    value: string;
    aclTemplate?: AccessControlEntryModel[];
}

/**
 * If access control is disabled, we use an ACL of [Everyone:Full Control]
 * (So access control is logically disabled from the user point-of-view, but under the hood
 * it's just a very permissive ACL)
 */
function getAccessControlDisabledACL(groups: UserGroupModel[]): AccessControlEntryModel[] {
    const everyoneGroup = findTheEveryoneGroup(groups);

    return (
        everyoneGroup && [
            {
                subjectId: everyoneGroup.id!,
                permissions: ['AD']
            }
        ]
    );
}

function findTheEveryoneGroup(groups: UserGroupModel[]): UserGroupModel {
    const everyoneGroup = groups.find((group) => group.name === 'everyone');
    if (!everyoneGroup) {
        throw new Error('Everyone group not found');
    }
    return everyoneGroup;
}

async function getEntityACLWithoutObjectId(entityId: string): Promise<AccessControlEntryModel[]> {
    const acl = await GetEntityACL(entityId);
    return acl.map((ace) => ({
        subjectId: ace.subjectId,
        permissions: ace.permissions
    }));
}

function isACLValid(acl: AccessControlEntryModel[]) {
    return acl && acl.length > 0 && acl.find((ace) => ace.permissions.includes('AD')) !== undefined;
}

function areACLsEqual(acl1?: AccessControlEntryModel[], acl2?: AccessControlEntryModel[]): boolean {
    if (!acl1 && !acl2) {
        return true;
    }
    if (!acl1 || !acl2) {
        return false;
    }
    const acl1Essentials = acl1.map((ace) => ({ subjectId: ace.subjectId, permissions: ace.permissions }));
    const acl2Essentials = acl2.map((ace) => ({ subjectId: ace.subjectId, permissions: ace.permissions }));
    return isEqual(sortBy(acl1Essentials, 'subjectId'), sortBy(acl2Essentials, 'subjectId'));
}
