import { isEmpty } from 'lodash';
import { usePlugin } from 'pages/settings/plugins/components/usePlugin';

export const useHasDefaultContent = (pluginId?: string) => {
    const { data: plugin, isLoading } = usePlugin(pluginId);

    return {
        isLoading,
        hasDefaultContent: plugin ? !isEmpty(plugin.defaultContent) : undefined
    };
};
