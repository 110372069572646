import { AccessControlType } from '@squaredup/constants';
import { Query } from 'services/GraphService';

export interface WorkspaceWithDependencies {
    id: string;
    name: string;
    workspaceID: string;
    dependencies: string[];
}

export interface WorkspaceDependencyFormData {
    editDownstream: {
        label: string;
        value: string;
        isNew: boolean;
    }[];
}

export interface FilterCriteria {
    showIssuesOnly: boolean;
    showHealthyMonitors: boolean;
}

export const defaultFilterCriteria: FilterCriteria = {
    showIssuesOnly: false,
    showHealthyMonitors: false
};

export const monitoringRefetchSettings = {
    refetchOnMount: true,
    refetchInterval: 30_000
};

export const monitoringTitleClassName = 'mt-6 mb-3 text-xs font-bold tracking-widest uppercase text-textSecondary';

export const dashboardJson = `{
    "_type": "layout/grid",
    "contents": [
        {
            "x": 0,
            "h": 2,
            "i": "1",
            "y": 0,
            "config": {
                "_type": "tile/data-stream",
                "title": "Health",
                "description": "",
                "visualisation": {
                    "type": "data-stream-blocks"
                },
                "scope": {
                    "workspace": "{{workspaceId}}",
                    "scope": "{{scopeId}}"
                },
                "dataStream": {
                    "id": "datastream-health"
                },
                "monitor": {
                    "tileRollsUp": true,
                    "frequency": "1",
                    "condition": {
                        "columns": [
                            "data.state"
                        ]
                    }
                },
                "timeframe": "last1hour"
            },
            "w": 4
        }
    ],
    "columns": 4
}`;

export const getAllWorkspacesWithDependencies = async (accessType: AccessControlType = 'directOrWorkspaceLinks') => {    
    // Query to get the graph nodes of all workspaces and connections for the current tenant
    const { gremlinQueryResults: workspacesWithDependencies } = await Query(
        {
            gremlinQuery: `g.V().has("sourceType","squaredup/space")
                .project("id", "workspaceID", "name", "dependencies")
                .by("id")
                .by("sourceId")
                .by("name")
                .by(
                    coalesce(
                        outE("contains").inV().has("sourceType", "squaredup/dash")
                            .outE("contains").inV().has("sourceType", "squaredup/monitor")
                            .outE("monitors").inV().has("sourceType","squaredup/space")
                            .id()
                            .fold(),
                        constant([])
                    )
                )
            `
        },
        accessType
    );

    return workspacesWithDependencies.map(({ id, workspaceID, name, dependencies }) => {
        return {
            id,
            name,
            workspaceID,
            dependencies: dependencies.filter((targetNodeId: string) => targetNodeId !== id)
        };
    });
};

export const getUpstreamWorkspaces = (id: string, workspaces: WorkspaceWithDependencies[] = []) => {
    return workspaces.filter(
        (workspaceWithDependencies) =>
            workspaceWithDependencies.dependencies?.some((workspaceNodeID) => workspaceNodeID === id)
    );
};

/**
 * Fetches all workspaces directly upstream and recursively upstream of the current workspace
 * @param {string} currentWorkspaceNodeId the workspace id that you are finding all upstream
 * @param {Array} workspaces List of all the workspaces
 * @param {Set} existingUpstreamDependencies current filled upstream dependencies, defaults to new empty Set
 * @returns All workspaces upstream of the currentWorkspaceNodeId workspace
 */
export const calculateUpstreamDependencyIds = (
    currentWorkspaceNodeId: string,
    workspaces: WorkspaceWithDependencies[],
    existingUpstreamDependencies?: Set<string>
) => {
    const upstreamDependencies = existingUpstreamDependencies || new Set();
    upstreamDependencies.add(currentWorkspaceNodeId);

    getUpstreamWorkspaces(currentWorkspaceNodeId, workspaces).forEach(({ id }) => {
        if (!upstreamDependencies.has(id)) {
            const parents = calculateUpstreamDependencyIds(id, workspaces, upstreamDependencies);
            parents.map((parent) => upstreamDependencies.add(parent));
        }
    });

    return Array.from(upstreamDependencies);
};