import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Serialised } from '@squaredup/ids';
import { ChannelTypeIds } from '@squaredup/monitoring';
import { isFeatureEnabled, sortFeaturesByStartingTier, Tier } from '@squaredup/tenants';
import { AxiosError } from 'axios';
import Button from 'components/button/Button';
import { useNotificationChannelTypes } from 'components/hooks/useNotificationChannels';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ifNotOutside, ModalButtons } from 'components/Modal';
import { ModalOptionsWithOriginal } from 'components/ModalOptions';
import { FeatureUnavailablePill } from 'components/plans/FeatureUnavailablePill';
import type { ProjectedChannel } from 'dynamo-wrapper';
import trackEvent from 'lib/analytics';
import { NotificationRuleAddChannelPanel } from 'pages/monitoring/modal-panels/NotificationRuleAddChannelPanel';
import { NotificationDestinationIcon } from 'pages/monitoring/NotificationDestinationIcon';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    CHANNEL,
    ChannelCreate,
    CHANNELS,
    ChannelType,
    Create,
    Get,
    Update
} from 'services/NotificationChannelService';

type NotificationsChannelAddEditModalArgs = {
    initialChannel?: Serialised<ProjectedChannel>;
    close: () => void;
    existingChannelNames: string[];
    tier: Tier | undefined;
};

// Fix the type of the ModalOptions generic component
const ModalOptionsChannelType = ModalOptionsWithOriginal<Serialised<ChannelType>>();

export const NotificationsChannelAddEditModal: React.FC<NotificationsChannelAddEditModalArgs> = ({
    initialChannel,
    close,
    existingChannelNames,
    tier
}) => {
    const queryClient = useQueryClient();
    const editing = Boolean(initialChannel);
    const [selectedChannelTypeId, setSelectedChannelTypeId] = useState(
        initialChannel?.channelTypeId ? initialChannel.channelTypeId : ''
    );
    const [selectedChannelType, setSelectedChannelType] = useState<ChannelType | undefined>(undefined);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const {
        data: channel,
        isLoading: isLoadingChannel,
        isFetching: isFetchingChannel
    } = useQuery([CHANNEL, initialChannel?.id], async () => initialChannel && Get(initialChannel.id), {
        enabled: Boolean(initialChannel),
        refetchOnWindowFocus: false
    });

    const { channelTypes, isLoadingChannelTypes } = useNotificationChannelTypes();

    // Ensure email channel is first, custom is last, and the rest are alphabetical case-insensitive.
    const sortedChannelTypes = useMemo(
        () =>
            [...(channelTypes ?? [])].sort((a, b) => {
                const featureSortOrder = sortFeaturesByStartingTier(a.requiresFeature, b.requiresFeature);
                if (featureSortOrder !== 0) {
                    return featureSortOrder;
                }

                if (a.id === ChannelTypeIds.Email || b.id === ChannelTypeIds.Custom) {
                    return -1;
                }

                if (a.id === ChannelTypeIds.Custom || b.id === ChannelTypeIds.Email) {
                    return 1;
                }

                return a.displayName.localeCompare(b.displayName, undefined, { sensitivity: 'base' });
            }),
        [channelTypes]
    );

    // Set the selected channel type instance
    useEffect(() => {
        setSelectedChannelType(channelTypes?.find((ch) => ch.id === selectedChannelTypeId));
    }, [selectedChannelTypeId, channelTypes]);

    const { mutateAsync: persistChannel } = useMutation(
        ({ ...config }: ChannelCreate) => (channel ? Update(channel.id, config) : Create(config)),
        {
            onSuccess: async () => {
                trackEvent(`Notification Channel ${editing ? 'Updated' : 'Created'}`, {
                    type: selectedChannelType?.displayName
                });
                // Invalidate list before modal closes
                await queryClient.invalidateQueries(CHANNELS);
                close();
                // Ensure when the modal re-opens stale values are not displayed
                await queryClient.invalidateQueries([CHANNEL, initialChannel?.id]);
            },
            onError: (axiosError: AxiosError<any>) => {
                setError(axiosError.response?.data.error || axiosError.message);
                setIsSaving(false);
            }
        }
    );

    // Set the preheader above the modal title
    const modalPreHeader =
        !editing && selectedChannelType ? (
            <div className='mb-2 -ml-1 text-textSecondary'>
                <Button type='button' variant='tertiary' onClick={() => setSelectedChannelTypeId('')}>
                    <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
                    <span className='ml-1 font-normal' data-testid='backButton'>
                        Back
                    </span>
                </Button>
            </div>
        ) : undefined;

    // set the description below the modal title
    const url = selectedChannelType?.externalLinks.find((link) => link.isKbArticle)?.url;
    const modalDescription = url ? (
        <div className='mb-4 -mt-2 text-textSecondary'>
            For help adding this destination, visit our{' '}
            <Button type='button' variant='link' href={url}>
                help article
            </Button>
            .
        </div>
    ) : undefined;

    const isLoading = isLoadingChannel || isFetchingChannel || isLoadingChannelTypes || (editing && !channel);

    return (
        <Modal
            title={channel ? `Edit ${selectedChannelType?.displayName} destination` : 'Add destination'}
            close={ifNotOutside(close)}
            headerContent={<></>} // remove close X
            preHeader={modalPreHeader} // Add < back text if required
            fullWidth
            maxWidth='max-w-3xl'
            description={modalDescription}
        >
            <hr className='border-dividerPrimary' />
            <div className='flex flex-col pt-5 overflow-hidden'>
                {isLoading ? (
                    <>
                        <div className='flex justify-center mt-7'>
                            <LoadingSpinner />
                        </div>
                        <ModalButtons>
                            <Button type='button' variant={'tertiary'} onClick={close}>
                                Cancel
                            </Button>
                        </ModalButtons>
                    </>
                ) : !selectedChannelType ? (
                    /* Display if a type is unchosen.. */
                    <>
                        <div className='flex flex-col px-8 overflow-hidden'>
                            <p className='mb-2 text-textSecondary'>Select an integration</p>
                            <div className='pb-12 table-scroll-overflow'>
                                <ModalOptionsChannelType
                                    objects={sortedChannelTypes}
                                    optionMapper={(ct) => ({
                                        original: ct,
                                        key: ct.id,
                                        label: ct.displayName,
                                        icon: <NotificationDestinationIcon channelTypeId={ct.id} className='w-5 h-5' />,
                                        subLabel: ct.requiresFeature
                                            ? (<FeatureUnavailablePill featureKey={ct.requiresFeature} className='ml-3' />)
                                            : undefined,
                                        disabled: ct.requiresFeature &&
                                            (!tier || !isFeatureEnabled(tier, ct.requiresFeature))
                                    })}
                                    onClick={(channelType) => setSelectedChannelTypeId(channelType.id)}
                                    includeRightArrow={true}
                                />
                            </div>
                        </div>
                        <ModalButtons hideTopMargin>
                            <Button type='button' variant={'tertiary'} onClick={close}>
                                Cancel
                            </Button>
                            <Button type='button' disabled={true}>
                                Save
                            </Button>
                        </ModalButtons>
                    </>
                ) : (
                    <NotificationRuleAddChannelPanel
                        existingChannelNames={existingChannelNames}
                        initialValue={editing && channel ? channel : undefined}
                        channelType={selectedChannelType}
                        saveNewChannel={(config) => {
                            setIsSaving(true);
                            persistChannel(config).catch(() => {
                                /* Do nothing, handled by mutation */
                            });
                        }}
                        setIsValid={setIsSaveEnabled}
                    >
                        {error && <p className='mt-2 px-7 text-statusErrorPrimary'>{error}</p>}
                        <ModalButtons hideTopMargin>
                            {selectedChannelType.id === ChannelTypeIds.Zapier ? (
                                <>
                                    <Button type='submit' onClick={close}>
                                        Close
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button type='button' variant={'tertiary'} onClick={close}>
                                        Cancel
                                    </Button>
                                    <Button type='submit' disabled={!isSaveEnabled || isSaving}>
                                        {isSaving ? <LoadingSpinner size={18} /> : 'Save'}
                                    </Button>
                                </>
                            )}
                        </ModalButtons>
                    </NotificationRuleAddChannelPanel>
                )}
            </div>
        </Modal>
    );
};
