import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import Button from './button';

interface ModalOption {
    label: string;
    key?: string;
    subLabel?: React.ReactNode;
    icon?: React.ReactNode;
    disabled?: boolean;
}

interface ModalOptionWithClick extends ModalOption {
    onClick: () => void;
}

interface ModalOptionsProps {
    options: ModalOption[];
    onClick: (option: ModalOption) => void;
    includeRightArrow?: boolean;
    labelClasses?: string;
}

interface ModalOptionsWithOriginalProps<T> {
    objects: T[];
    optionMapper: (original: T) => ModalOption;
    onClick: (option: T) => void;
    includeRightArrow?: boolean;
    labelClasses?: string;
}

export const ModalOptionsWithOriginal =
    <T,>(): FC<ModalOptionsWithOriginalProps<T>> =>
    ({ onClick, objects, optionMapper, includeRightArrow, labelClasses }) =>
        ModalOptionsInternal({
            options: objects.map((o) => ({ ...optionMapper(o), onClick: () => onClick(o) })),
            includeRightArrow,
            labelClasses
        });

export const ModalOptions: FC<ModalOptionsProps> = ({ onClick, options, includeRightArrow, labelClasses }) =>
    ModalOptionsInternal({
        options: options.map((o) => ({ ...o, onClick: () => onClick(o) })),
        labelClasses,
        includeRightArrow
    });

const ModalOptionsInternal = ({
    options,
    includeRightArrow = true,
    labelClasses
}: Omit<ModalOptionsProps, 'onClick' | 'options'> & { options: ModalOptionWithClick[] }) => (
    <div className='flex flex-col justify-between gap-2'>
        {options.map((option) => (
            <Button
                key={option.key ?? option.label}
                type='button'
                variant='choice'
                aria-label={option.label}
                data-testid={`${option.label}`}
                icon={option.icon}
                /* Simulate button being disabled, so that children can still receive click events from the browser */
                aria-disabled={option.disabled}
                onClick={option.disabled ? undefined: option.onClick}
                tabIndex={option.disabled ? -1 : undefined}
                className={option.disabled ? 'hover:bg-componentBackgroundPrimary hover:ring-tileOutline' : undefined}
            >
                <span className={labelClasses || 'font-normal'}>{option.label}</span>
                {option.subLabel && option.subLabel}
                {includeRightArrow && <FontAwesomeIcon icon={faChevronRight} fixedWidth className='ml-auto' />}
            </Button>
        ))}
    </div>
);
