import Text from '@/components/Text';
import clsx from 'clsx';
import { useTileEditorStepsContext } from '../../contexts/TileEditorStepsContext';

/**
 * @returns Steps such as: Data Stream, Objects, Configure, Shaping (step names subject to change)
 */
export const DataStreamTileEditorStepSelector = () => {
    const { currentEditorStep, editorSteps, setCurrentEditorStep } = useTileEditorStepsContext();

    return (
        <div className='flex flex-col justify-start items-center w-64 relative border-r-[1px] border-dividerPrimary overflow-auto tile-scroll-overflow text-base scrollbar-thumb-statusUnknownPrimary'>
            {editorSteps.map(({ name, sublabel, condition }) => (
                <button
                    key={name}
                    onClick={() => setCurrentEditorStep(name)}
                    className={clsx(
                        'font-semibold w-full text-left py-4 px-[23px] shrink-0 h-[79px] text-textPrimary border-b-[1px] border-dividerPrimary disabled:text-textDisabled',
                        name === currentEditorStep && 'bg-tagBackground'
                    )}
                    disabled={!condition}
                    data-testid={`step-${name}`}
                >
                    <Text.H4>{name}</Text.H4>
                    <Text.SmallBody className={clsx(
                        'text-sm font-semibold', 
                        condition ? 'text-textSecondary' : 'text-textDisabled'
                    )}>
                        {sublabel}
                    </Text.SmallBody>
                </button>
            ))}
        </div>
    );
};
