const getNodeType = ({ data }: any) => (data['type'] ? data['type'][0] : 'unknown');

export const getNodeWithState = (
    node: any, 
    nodeType: string, 
    hoveredNode?: string, 
    hoveredUpStreamNodes?: string[]
) => {
    const { id, data, ...rest } = node;
    const nodeDataType = getNodeType(node);
    const radius = nodeDataType === 'space' ? 80 : nodeDataType === 'app' ? 60 : 40;
    const isHoveredUpstreamNode = hoveredUpStreamNodes?.includes(id);

    return {
        id,
        type: nodeType || 'circle',
        data: {
            ...data,
            radius,
            isHovered: hoveredNode === id,
            showLabel: isHoveredUpstreamNode
        },
        zIndex: isHoveredUpstreamNode ? 4 : 1,
        style: {
            opacity: nodeType === 'kpi' || !hoveredUpStreamNodes || isHoveredUpstreamNode ? 1 : 0.15,
            transition: 'opacity 0.3s ease-out'
        },
        ...rest
    };
};
