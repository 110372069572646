import GridSkeleton from '@/components/GridSkeleton';
import { HealthState, stateStrings } from '@squaredup/monitoring';
import { isFeatureEnabled } from '@squaredup/tenants';
import type { OrganisationHomeConfig } from 'dynamo-wrapper';
import { groupBy } from 'lodash';
import { oneMinute } from 'queries/constants';
import { useDashboardsWithHealth } from 'queries/hooks/useDashboardsWithHealthStates';
import { useMonitorsWithHealth } from 'queries/hooks/useMonitorsForDashboards';
import { useTier } from 'queries/hooks/useTier';
import { useWorkspacesWithHealthRollup } from 'queries/hooks/useWorkspacesWithHealthRollup';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { List } from 'services/KPIService';
import { useDataCount } from '../utils/useDataCount';
import { GlobalViewEmptyMessage } from './GlobalViewEmptyMessage';
import { StatusBlockDataItem } from './blocks/StatusBlock';
import { StatusBlocks } from './blocks/StatusBlocks';

export type SortValue = 'state' | 'name';

export type StatusRequestType = 'space' | 'dash' | 'monitor';

export interface StatusOverviewProps {
    sort: {
        value: SortValue;
        direction: 'asc' | 'desc';
    };
    stateFilters: HealthState[];
    type: StatusRequestType;
    tags?: string[];
    kpiTypeIds?: string[];
    openCreateWorkspace?: () => void;
    workspaceId?: string;
    maxColumns?: number;
    workspaceTypes?: string[];
    viewSettings?: OrganisationHomeConfig['viewSettings'];
}

export default function StatusOverview({
    openCreateWorkspace,
    maxColumns = 3,
    workspaceId: currentWorkspaceId,
    tags,
    workspaceTypes,
    kpiTypeIds,
    viewSettings,
    ...requestParams
}: StatusOverviewProps) {
    const { data: tier } = useTier();

    const { data: workspacesData, isLoading: workspacesLoading } = useWorkspacesWithHealthRollup({
        select: (data): (StatusBlockDataItem & { workspaceId: string; type: string; tags: string[] })[] =>
            data.map(({ state, displayName: name, id, data: { properties } }) => ({
                state: state ?? stateStrings.unmonitored,
                name,
                id,
                link: `/workspace/${id}`,
                workspaceId: id,
                type: properties?.type ?? '',
                tags: properties?.tags ?? []
            }))
    });

    const { data: groupedKPIs, isLoading: kpisLoading } = useQuery({
        queryFn: () => List(undefined, undefined, { includeValues: false }),
        queryKey: workspaceQueryKeys.kpis(null, { includeValues: false }),
        enabled: requestParams.type === 'space',
        select: (data) =>
            groupBy(
                data.filter((kpiType) => !kpiTypeIds?.length || kpiTypeIds.includes(kpiType.type)),
                'workspaceId'
            ),
        refetchInterval: oneMinute
    });

    const { data: dashboardsData, isLoading: loadingDashboards } = useDashboardsWithHealth({
        enabled: requestParams.type === 'dash',
        refetchInterval: oneMinute,
        select: (data): (StatusBlockDataItem & { workspaceId: string })[] =>
            data.map(({ state, displayName: name, id, workspaceId }) => ({
                state: state ?? stateStrings.unmonitored,
                name,
                id,
                link: `/dashboard/${id}`,
                workspaceId
            }))
    });

    const { data: monitorsData, isLoading: loadingMonitors } = useMonitorsWithHealth({
        enabled: requestParams.type === 'monitor',
        refetchInterval: oneMinute,
        select: (data): (StatusBlockDataItem & { workspaceId: string })[] =>
            data.map(({ state, tileId, workspaceId, dashId, tileName, dashName }) => ({
                state,
                name: `Tile '${tileName}' of dashboard '${dashName}'`,
                id: `${dashId}/${tileId}`,
                link: `/dashboard/${dashId}#${tileId}`,
                workspaceId
            }))
    });

    const isLoading = workspacesLoading || loadingDashboards || loadingMonitors || kpisLoading;

    const data = useMemo(() => {
        let items: (StatusBlockDataItem & { type?: string; tags?: string[]; workspaceId: string })[] = [];

        if (requestParams.type === 'space') {
            items = workspacesData ?? [];
        } else if (requestParams.type === 'dash') {
            items = dashboardsData ?? [];
        } else {
            items = monitorsData ?? [];
        }

        // filter state
        if (requestParams.stateFilters.length) {
            items = items.filter(({ state }) => requestParams.stateFilters.includes(state));
        }

        let filteredWorkspaces = workspacesData;
        // filter tags
        if (tags?.length) {
            filteredWorkspaces = filteredWorkspaces?.filter((space) => tags.some((tag) => space.tags.includes(tag)));
        }

        // filter type
        if (workspaceTypes?.length) {
            filteredWorkspaces = filteredWorkspaces?.filter(
                (space) =>
                    (space.type === '' && workspaceTypes.includes('[Not set]')) || workspaceTypes.includes(space.type)
            );
        }

        items = items.filter(({ workspaceId }) => filteredWorkspaces?.some(({ id }) => workspaceId === id));

        // Sort the data
        if (requestParams.sort.value === 'state') {
            const valueMap = {
                unmonitored: requestParams.sort.direction === 'asc' ? 6 : 0,
                unknown: requestParams.sort.direction === 'asc' ? 5 : 1,
                error: 4,
                warning: 3,
                success: 2
            } as Record<string, number>;

            // Fallback to alphabetical when the states are equal
            items = items.sort((a, b) =>
                requestParams.sort.direction === 'asc'
                    ? valueMap[a.state] - valueMap[b.state] || a.name.localeCompare(b.name)
                    : valueMap[b.state] - valueMap[a.state] || a.name.localeCompare(b.name)
            );
        } else {
            items = items.sort((a, b) =>
                requestParams.sort.direction === 'asc'
                    ? a.name.localeCompare(b.name, undefined, { numeric: true })
                    : b.name.localeCompare(a.name, undefined, { numeric: true })
            );
        }

        return items;
    }, [
        dashboardsData,
        monitorsData,
        requestParams.sort.direction,
        requestParams.sort.value,
        requestParams.stateFilters,
        requestParams.type,
        tags,
        workspaceTypes,
        workspacesData
    ]);

    const { workspaceCount, dashboardCount, monitorCount } = useDataCount();

    const showAddWorkspaceButton =
        !isLoading && data.length < 3 && workspaceCount < 3 && requestParams.type === 'space';

    const isWorkspacesAvailable = (tier && isFeatureEnabled(tier, 'workspaces')) || workspaceCount === 0;

    return (
        <div className='flex space-x-3 grow'>
            <div className='w-full grow'>
                {!isLoading && (
                    <StatusBlocks
                        data={data}
                        type={requestParams.type}
                        columns={maxColumns}
                        shaping={{ ...requestParams, tags, workspaceTypes }}
                        viewSettings={viewSettings}
                        kpiTypeIds={kpiTypeIds}
                        showAddWorkspace={Boolean(showAddWorkspaceButton)}
                        onAddWorkspaceClick={openCreateWorkspace}
                        isWorkspaceFeatureEnabled={isWorkspacesAvailable}
                        groupedKPIs={groupedKPIs}
                        noBlocksMessage={
                            /** No data found & we aren't showing an add workspace button */
                            !isLoading &&
                            data.length === 0 &&
                            (workspaceCount > 2 || requestParams.type !== 'space') && (
                                <div className='flex flex-col items-center justify-center h-full gap-4 m-auto text-center text-textSecondary'>
                                    <GlobalViewEmptyMessage
                                        type={requestParams.type}
                                        dashCount={dashboardCount}
                                        monitorCount={monitorCount}
                                    />
                                </div>
                            )
                        }
                    />
                )}
                {isLoading && (
                    <GridSkeleton
                        cols={maxColumns}
                        gap={'1.5rem'}
                        height={requestParams.type === 'space' ? '218px' : '152px'}
                        radius='0.25rem'
                    />
                )}
            </div>
        </div>
    );
}
