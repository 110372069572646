import DropdownMenu from 'components/dropdownMenu';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { KPI_TYPES, ListTypes } from 'services/KPIService';

export interface KPIFilterMenuProps {
    selectedKPITypeIds?: string[];
    setSelectedKPITypeIds: (types: string[]) => void;
}

export default function KPIFilterMenu({ selectedKPITypeIds = [], setSelectedKPITypeIds }: KPIFilterMenuProps) {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    const { data: KPITypes = [] } = useQuery([KPI_TYPES], ListTypes, {
        suspense: true
    });

    const selectedKPITypes = useMemo(
        () => KPITypes.filter((kpiType) => selectedKPITypeIds.includes(kpiType.id)),
        [KPITypes, selectedKPITypeIds]
    );

    return (
        <DropdownMenu onOpenChange={setIsDropdownOpened}>
            <DropdownMenu.Button
                active={selectedKPITypeIds.length > 0}
                className='whitespace-nowrap'
                dropdownOpen={isDropdownOpened}
                showChevron
                useFilterStyle
            >
                KPI Type
                {selectedKPITypes.length > 0 && (
                    <span className='px-2 ml-2 rounded-full bg-textDisabled' aria-label='numFiltersApplied'>
                        {selectedKPITypes.length}
                    </span>
                )}
            </DropdownMenu.Button>
            <DropdownMenu.Menu align='start'>
                <DropdownMenu.Group>
                    {KPITypes.sort((a, b) => a.displayName.localeCompare(b.displayName)).map((type) => (
                        <DropdownMenu.CheckboxItem
                            key={type.id}
                            checked={selectedKPITypeIds.includes(type.id)}
                            onCheckedChange={(checked) =>
                                setSelectedKPITypeIds(
                                    selectedKPITypeIds.filter((id) => id !== type.id).concat(checked ? [type.id] : [])
                                )
                            }
                        >
                            {type.displayName}
                        </DropdownMenu.CheckboxItem>
                    ))}
                </DropdownMenu.Group>
                <DropdownMenu.Separator />
                <DropdownMenu.SecondaryItem
                    disabled={selectedKPITypeIds.length === 0}
                    onSelect={() => setSelectedKPITypeIds([])}
                >
                    Clear filter
                </DropdownMenu.SecondaryItem>
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
}
