import { useState, type ReactNode } from 'react';

export const EDITOR_MODE = 'editorMode';

export interface StepWithCondition {
    name: string;
    condition?: boolean;
    sublabel?: ReactNode;
}

export interface TileEditorStepsValue {
    currentEditorStep: string;
    editorSteps: StepWithCondition[];
    canMoveToNextStep: boolean;
    setCurrentEditorStep: (step: string) => void;
    nextStep: () => void;
}

/**
 * Handles state management for a set of steps
 */
export const useTileEditorSteps = (steps: StepWithCondition[], initialStep: string): TileEditorStepsValue => {
    const [currentStepName, setCurrentStepName] = useState(function findInitialStep(): string {
        const foundStepIndex = steps.findIndex((s) => s.name === initialStep);
        const foundStep = foundStepIndex === -1 ? undefined : steps[foundStepIndex];

        if (foundStep != null && foundStep.condition !== false) {
            return foundStep.name;
        }

        const closestAvailableStep = steps.reduce(
            ({ availableStep, index }, step, i) => {
                if (availableStep == null && step.condition !== false) {
                    return { availableStep: step.name, index: i };
                }

                if (step.condition !== false && i < foundStepIndex) {
                    return { availableStep: step.name, index: i };
                }

                return { availableStep, index };
            },
            { availableStep: undefined, index: -1 } as { availableStep?: string; index: number }
        )?.availableStep;

        // If we have no available step, pick the found one even if it's disabled
        // to match previous behaviour
        return closestAvailableStep ?? foundStep?.name ?? '';
    });

    const availableSteps = steps.filter((step) => step.condition !== false);
    const stepInFlow = availableSteps.findIndex((step) => step.name === currentStepName);

    const canMoveToNextStep = stepInFlow < availableSteps.length - 1;

    const nextStep = () => {
        if (canMoveToNextStep) {
            setCurrentStepName(availableSteps[stepInFlow + 1].name);
        }
    };

    return {
        currentEditorStep: currentStepName,
        canMoveToNextStep,
        setCurrentEditorStep: (stepName) => {
            const newCurrentStep = steps.find((s) => s.name === stepName);

            if (newCurrentStep == null || newCurrentStep.condition === false) {
                return;
            }

            return setCurrentStepName(newCurrentStep.name);
        },
        nextStep,
        editorSteps: steps
    };
};
