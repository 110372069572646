import { isFeatureEnabled } from '@squaredup/tenants';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { FeatureUnavailableBanner } from 'components/plans/FeatureUnavailableBanner';
import { useTier } from 'queries/hooks/useTier';
import { customTypesQueryKeys } from 'queries/queryKeys/customTypesKeys';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { ClientSideCustomType, ListForAdministration } from 'services/CustomTypesService';
import { SettingsTemplate } from '../SettingsTemplate';
import { CustomTypesAddEditModal } from './CustomTypesAddEditModal';
import CustomTypesDeleteModal from './CustomTypesDeleteModal';
import CustomTypesTable from './CustomTypesTable';

/**
 * Renders the Custom Types settings page
 */
function CustomTypes() {
    const [typeBeingDeleted, setTypeBeingDeleted] = useState<ClientSideCustomType>();
    const [typeBeingEdited, setTypeBeingEdited] = useState<ClientSideCustomType>();
    const [typeAddEditOpen, setTypeAddEditOpen] = useState(false);

    const { data: tier, isLoading: isTierLoading } = useTier();
    const isFeatureAvailable = tier !== undefined && isFeatureEnabled(tier, 'customTypes');

    const { data: customTypes, isLoading: isCustomTypesLoading } = useQuery(
        customTypesQueryKeys.forAdministration,
        ListForAdministration,
        {
            select: (data) =>
                data.sort((a, b) => {
                    if (a.displayName === undefined) {
                        a.displayName = '';
                    }
                    if (b.displayName === undefined) {
                        b.displayName = '';
                    }
                    return a.displayName.localeCompare(b.displayName);
                })
        }
    );

    const isLoading = isCustomTypesLoading || isTierLoading;

    return (
        <SettingsTemplate
            title='Custom Types'
            description='Custom types are used to define a name and icon for objects that are indexed from your custom data sources.'
            learnMoreLink='https://squaredup.com/cloud/custom-types'
        >
            {isLoading && (
                <span className='flex justify-center'>
                    <LoadingSpinner />
                </span>
            )}
            {!isLoading && (
                <div className='flex flex-col flex-1 min-h-0'>
                    <FeatureUnavailableBanner
                        featureKey='customTypes'
                        container='page'
                        title='Categorize your objects'
                        className='mb-9'
                    />

                    <div>
                        <Button
                            onClick={() => setTypeAddEditOpen(true)}
                            data-testid='addType'
                            disabled={!isFeatureAvailable}
                        >
                            Add custom type
                        </Button>
                    </div>

                    <div className='flex flex-col min-h-0 mt-4 mb-8'>
                        <CustomTypesTable
                            isFeatureAvailable={isFeatureAvailable}
                            customTypes={customTypes || []}
                            onEdit={(customType) => {
                                setTypeBeingEdited(customType);
                                setTypeAddEditOpen(true);
                            }}
                            onDelete={(customType) => setTypeBeingDeleted(customType)}
                        />
                    </div>
                </div>
            )}

            {typeBeingDeleted && (
                <CustomTypesDeleteModal
                    customType={typeBeingDeleted}
                    onClose={() => setTypeBeingDeleted(undefined)}
                    key='custom-types-delete'
                />
            )}

            {typeAddEditOpen && (
                <CustomTypesAddEditModal
                    onSave={() => setTypeBeingEdited(undefined)}
                    onClose={() => {
                        setTypeBeingEdited(undefined);
                        setTypeAddEditOpen(false);
                    }}
                    customType={typeBeingEdited}
                    key='custom-types-add-edit'
                />
            )}
        </SettingsTemplate>
    );
}

export default CustomTypes;
