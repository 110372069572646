import { Skeleton } from '@/components/Skeleton';
import { cn } from '@/lib/cn';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSize from '@react-hook/size';
import { HealthState, stateStrings } from '@squaredup/monitoring';
import clsx from 'clsx';
import { Presence } from 'components/Presence';
import { TruncatedText } from 'components/TruncatedText';
import Tooltip from 'components/tooltip/Tooltip';
import { StateIndicator } from 'components/ui/state/StateIndicator';
import { WorkspaceStateIndicator } from 'components/ui/state/WorkspaceStateIndicator';
import type { BlockData } from 'dashboard-engine/visualisations/DataStreamBlocks/types';
import type { KPI, KPIValue, OrganisationHomeConfig } from 'dynamo-wrapper';
import { workspacePredefinedIconKey } from 'lib/fontawesome/fontawesome';
import { useBlockReason } from 'pages/status/utils/useBlockReason';
import { defaultGlobalViewConfig, STATUS_CONFIG } from 'pages/status/utils/useStatusConfig';
import { fiveMinutes } from 'queries/constants';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { useWorkspaceAvatar } from 'queries/hooks/useWorkspaceAvatar';
import { useWorkspaceKpis } from 'queries/hooks/useWorkspaceKpis';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import React, { memo, ReactNode, Suspense, useLayoutEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';
import { List } from 'services/KPIService';
import { StatusRequestType } from '../StatusOverview';
import { Footer } from './StatusBlockFooter';

interface BlockLinkWrapperProps {
    link?: string;
    className?: string;
    children?: ReactNode;
}

interface BlockSublabelProps {
    sublabel: string;
    state?: HealthState;
    lines: number;
}

export type StatusBlockDataItem = BlockData & { id: string };

export interface StatusBlockPlugin {
    id: string;
    workspaceId: string;
    name: string;
    displayName: string;
}

export interface StatusBlockData {
    type?: string;
    tags?: string[];
    plugins?: StatusBlockPlugin[];
}

interface StatusBlockProps {
    blockData: StatusBlockDataItem & { workspaceId: string };
    extraData?: StatusBlockData;
    showFooter?: boolean;
    viewSettings?: OrganisationHomeConfig['viewSettings'];
    type: StatusRequestType;
    kpiTypeIds?: string[];
}

export const blockBackground = {
    success: 'bg-statusHealthyPrimary',
    error: 'bg-statusErrorPrimary',
    warning: 'bg-statusWarningPrimary',
    unknown: 'bg-statusUnknownPrimary',
    unmonitored: 'bg-statusUnmonitoredPrimary border-y border-l border-cardOutline rounded-l-lg'
};

const BlockLinkWrapper: React.FC<BlockLinkWrapperProps> = ({ link, className, children }) => {
    if (link) {
        return (
            <NavLink to={link} className={className}>
                {children}
            </NavLink>
        );
    }

    return <>{children}</>;
};

const BlockSublabel: React.FC<BlockSublabelProps> = ({ sublabel, state, lines }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const textRef = useRef<HTMLDivElement>(null);
    const [overflowing, setOverflowing] = useState(false);
    const [labelWidth, labelHeight] = useSize(containerRef);

    useLayoutEffect(() => {
        const scrollHeight = textRef.current?.scrollHeight || 0;

        setOverflowing(Math.round(labelHeight) < scrollHeight);
    }, [textRef, labelHeight, labelWidth]);

    return (
        <div ref={containerRef} className='mt-4 overflow-hidden shrink-0'>
            <Tooltip title={sublabel} disabled={!overflowing}>
                <p
                    ref={textRef}
                    className='overflow-hidden text-base font-light text-ellipsis text-textPrimary line-clamp-1'
                    aria-label={sublabel}
                    style={{
                        WebkitLineClamp: lines
                    }}
                >
                    {state && <StateIndicator disablePing className='mr-2 mb-0.5' state={state} />}
                    {sublabel}
                </p>
            </Tooltip>
        </div>
    );
};

export const StatusBlock: React.FC<StatusBlockProps> = memo(
    ({ blockData, extraData, viewSettings, type, kpiTypeIds }) => {
        const containerRef = useRef<HTMLDivElement>(null);

        const { id, name, state, link, workspaceId } = blockData;
        const reason = useBlockReason(type, id, state !== stateStrings.unmonitored ? state : undefined);

        const showType = Boolean(viewSettings?.type);
        const showTags = Boolean(viewSettings?.tags);
        const showDataSources = Boolean(viewSettings?.dataSources);
        const showSublabel = Boolean(viewSettings?.sublabel) || type !== 'space';
        const showAvatar = Boolean(viewSettings?.avatar);
        const showState = Boolean(viewSettings?.state) || type !== 'space';
        const showKPIs = Boolean(viewSettings?.kpis);

        const queryClient = useQueryClient();
        const config = queryClient.getQueryData<OrganisationHomeConfig>([STATUS_CONFIG]) ?? defaultGlobalViewConfig;

        return (
            <BlockLinkWrapper link={link}>
                <div
                    ref={containerRef}
                    className={cn(
                        'relative w-full h-full text-textPrimary  shadow-[0px_0px_10px_-1px_rgba(0,0,0,0.15)] overflow-hidden font-semibold bg-cardBackground rounded-lg',
                        Boolean(link) && 'status-block-hover',
                        state !== 'unknown' && 'text-primaryButtonText'
                    )}
                    data-testid='block'
                    data-teststate={state}
                    role='listitem'
                    data-tags={extraData?.tags?.toString()}
                >

                    {showState && (
                        <div
                            role='status'
                            aria-label={state}
                            className={clsx(
                                'shrink-0 flex justify-center w-4 absolute top-0 h-full z-10 left-0',
                                blockBackground[state]
                            )}
                        />
                    )}
                    
                    <div
                        className={cn(
                            'relative flex h-full border-cardOutline overflow-hidden border w-full rounded-lg',
                            Boolean(link) && 'status-block-hover',
                            state !== 'unknown' && 'text-primaryButtonText',
                            showState && 'pl-[13px]'
                        )}
                    >
                        <div className='flex flex-col flex-1 w-full min-w-0 overflow-hidden'>
                            <div className='flex gap-2 px-5 pt-5 overflow-hidden grow'>
                                <div className='flex flex-col flex-1 h-full min-w-0 min-h-0 overflow-hidden'>
                                    <span
                                        className={clsx(
                                            'leading-normal shrink-0 flex grow-0 space-x-4 text-md items-center',
                                            type === 'space' && 'h-12'
                                        )}
                                    >
                                        <Presence isPresent={showAvatar && config?.type === 'space'}>
                                            <Suspense fallback={<StatusBlockAvatarSkeleton />}>
                                                <StatusBlockAvatar id={id} />
                                            </Suspense>
                                        </Presence>
                                        <TruncatedText
                                            title={name}
                                            element='h6'
                                            className='overflow-hidden text-2xl font-semibold truncate text-textPrimary'
                                        />
                                    </span>
                                    {reason && showSublabel && (
                                        <BlockSublabel
                                            lines={type === 'space' && showKPIs ? 1 : 3}
                                            state={
                                                state !== stateStrings.unmonitored ? 
                                                    state : 
                                                    undefined
                                            }
                                            sublabel={reason}
                                        />
                                    )}
                                    {type === 'space' && showKPIs && (
                                        <KPIList kpiTypeIds={kpiTypeIds} workspaceId={workspaceId} />
                                    )}
                                </div>
                            </div>

                            {(showTags || showType || showDataSources) && type === 'space' && (
                                <Footer
                                    plugins={extraData?.plugins}
                                    showPlugins={showDataSources}
                                    showTypesAndTags={showTags || showType}
                                    typeAndTags={[
                                        ...(extraData?.type && showType
                                            ? [{ text: extraData.type, type: 'type' }]
                                            : []),
                                        ...(showTags
                                            ? extraData?.tags?.map((text) => ({ text, type: 'tag' })) ?? []
                                            : [])
                                    ]}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </BlockLinkWrapper>
        );
    }
);

const StatusBlockAvatar = (props: Pick<Parameters<typeof useWorkspaceAvatar>[0], 'id'>) => {
    const workspace = useWorkspace(props.id);
    const workspaceAvatar = useWorkspaceAvatar({
        suspense: true,
        id: props.id,
        enabled: !workspace.data?.data.properties?.avatarIconName
    });

    if (!workspaceAvatar.data && !workspace.data?.data?.properties?.avatarIconName) {
        return (
            <div className='p-1 rounded-md h-min bg-cardIconBackground'>
                <WorkspaceStateIndicator state={'unknown'} id={props.id} className='w-10 h-10' />
            </div>
        );
    }

    if (workspace.data?.data?.properties?.avatarIconName) {
        return (
            <div className='flex items-center justify-center rounded-md aspect-square h-min bg-cardIconBackground'>
                <FontAwesomeIcon
                    className='w-8 h-8 m-2 text-textPrimary'
                    icon={
                        workspace.data?.data?.properties?.avatarIconName.replace(
                            workspacePredefinedIconKey,
                            ''
                        ) as IconProp
                    }
                />
            </div>
        );
    }

    return (
        <div className='flex items-center justify-center rounded-md h-min shrink-0 bg-cardIconBackground'>
            <Presence isPresent={!workspaceAvatar.data?.startsWith(workspacePredefinedIconKey)}>
                <img
                    src={workspaceAvatar.data}
                    alt='workspace avatar'
                    className='object-contain shrink-0 aspect-square w-12 rounded-[4px] bg-white'
                />
            </Presence>
            <Presence isPresent={Boolean(workspaceAvatar.data?.startsWith(workspacePredefinedIconKey))}>
                <FontAwesomeIcon
                    className='w-8 h-8 m-2 text-textPrimary'
                    icon={workspaceAvatar.data?.replace(workspacePredefinedIconKey, '') as IconProp}
                />
            </Presence>
        </div>
    );
};

const StatusBlockAvatarSkeleton = () => {
    return (
        <Skeleton className='border-4 rounded-md outline outline-1 h-7 w-7 outline-cardIconBackground aspect-square' />
    );
};

function KPIList({ workspaceId, kpiTypeIds }: { workspaceId: string; kpiTypeIds?: string[] }) {
    const { data: kpiMeta, isLoading: kpiMetaLoading } = useQuery({
        queryFn: () => List(undefined, undefined, { includeValues: false }),
        queryKey: workspaceQueryKeys.kpis(null, { includeValues: false }),
        select: (data) => data.filter((kpi) => kpi.workspaceId === workspaceId),
        refetchOnMount: false,
        refetchInterval: fiveMinutes,
        staleTime: fiveMinutes
    });

    const { data: kpis, isLoading: kpisLoading } = useWorkspaceKpis(workspaceId, {
        enabled: kpiMeta?.length !== 0,
        refetchOnMount: false,
        refetchInterval: fiveMinutes,
        staleTime: fiveMinutes
    });

    if (kpiMetaLoading || !kpiMeta?.length) {
        return null;
    }

    return (
        <dl className='grid grid-cols-1 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary mt-4 auto-rows-[54px] mb-4 gap-2 grow overflow-auto'>
            {(kpis ?? kpiMeta)
                ?.filter((kpi) => !kpiTypeIds?.length || kpiTypeIds.includes(kpi.type))
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((kpi: KPI | KPIValue) =>
                    !kpisLoading ? (
                        <NavLink
                            to={`/dashboard/${kpi.dashboardId}#${kpi.tileId}`}
                            key={kpi.tileId}
                            className='flex flex-col justify-center w-full px-3 bg-componentTileBlock hover:bg-componentTileBlockHover shrink-0'
                        >
                            <dt className='pl-px text-sm font-normal text-textSecondary'>
                                <TruncatedText title={kpi.name}>{kpi.name}</TruncatedText>
                            </dt>
                            <dd
                                data-status={'value' in kpi ? kpi.status : 'loading'}
                                className={clsx(
                                    'text-textPrimary pl-px font-semibold rounded h-[24px] text-lg truncate data-[status=error]:text-statusErrorPrimary data-[status=success]:text-statusHealthyPrimary data-[status=warning]:text-statusWarningPrimary data-[status=unknown]:text-textPrimary',
                                    kpisLoading && 'skeleton-shimmer w-3/4'
                                )}
                            >
                                <TruncatedText
                                    className={clsx(kpisLoading && 'sr-only')}
                                    title={'formattedValue' in kpi ? kpi.formattedValue : 'Loading...'}
                                >
                                    {'formattedValue' in kpi ? kpi.formattedValue : 'Loading...'}
                                </TruncatedText>
                            </dd>
                        </NavLink>
                    ) : (
                        <div key={kpi.tileId} className='w-full h-full skeleton-shimmer' />
                    )
                )}
        </dl>
    );
}
