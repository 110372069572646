import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import Form from 'components/forms/form/Form';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ifNotOutside, ModalButtons } from 'components/Modal';
import trackEvent from 'lib/analytics';
import { iconNameValid } from 'lib/fontawesome/fontawesome';
import { customTypesQueryKeys } from 'queries/queryKeys/customTypesKeys';
import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ClientSideCustomType, Create, Update } from 'services/CustomTypesService';

type CustomTypeFormData = {
    displayName: string;
    icon: IconProp;
    singular: string;
    plural: string;
    type: string;
};

type CustomTypesAddModalProps = {
    onClose: () => void;
    onSave: () => void;
    customType: ClientSideCustomType | undefined;
};

export const CustomTypesAddEditModal: FC<CustomTypesAddModalProps> = ({ onClose, onSave, customType }) => {
    const queryClient = useQueryClient();
    const defaults = customType && { displayName: customType.displayName, ...customType.config };

    const { mutateAsync: saveCustomType } = useMutation(
        async (data: CustomTypeFormData) => {
            const { displayName, ...config } = data;

            if (customType?.id) {
                // editing existing custom type
                await Update(customType.id, data.displayName ?? '', config);
            } else {
                // add new custom type
                await Create(data.displayName ?? '', config);
                trackEvent('Type Created', { name: data.displayName, type: config.type });
            }
        },
        {
            onSuccess: () => {
                onSave();
                onClose();
            },
            onSettled: async () => {
                queryClient.invalidateQueries(customTypesQueryKeys.all);
            }
        }
    );

    return (
        <Modal
            title={customType ? `Edit custom type: ${customType.displayName}` : 'Add custom type'}
            close={ifNotOutside(onClose)}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <div className='pr-1 tile-scroll-overflow'>
                <Form submit={saveCustomType} defaultValues={defaults}>
                    {(isValid, isSubmitting, data) => (
                        <>
                            <div className='flex-1 min-h-0 px-8 tile-scroll-overflow'>
                                <Field.Input
                                    name='displayName'
                                    label='Name'
                                    title='Name'
                                    placeholder='Enter a name'
                                    help='This name is only used to identify your custom type and is not displayed in the application.'
                                    validation={{
                                        required: true,
                                        maxLength: 128,
                                        minLength: 1
                                    }}
                                />
                                <Field.Input
                                    name='type'
                                    label='Type'
                                    title='Type'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const start = e.target.selectionStart;
                                        const end = e.target.selectionEnd;
                                        const textVal = e.target.value;
                                        e.target.value = textVal ? textVal.toLowerCase() : '';
                                        e.target.setSelectionRange(start, end);
                                    }}
                                    placeholder='Enter a type (will be automatically lower-cased)'
                                    help='This should match the type property used by your custom indexed object.'
                                    validation={{
                                        required: true,
                                        maxLength: 128,
                                        minLength: 1
                                    }}
                                />
                                <div className='relative mt-8'>
                                    <Field.Input
                                        name='icon'
                                        label='Icon'
                                        title='Icon'
                                        placeholder='Enter a Font Awesome icon name'
                                        prepend={
                                            data.icon && (
                                                <FontAwesomeIcon icon={data.icon} className='text-textPrimary' />
                                            )
                                        }
                                        validation={{
                                            required: true,
                                            maxLength: 128,
                                            minLength: 1,
                                            validate: (iconName: string) =>
                                                iconNameValid(iconName?.toLowerCase()) || 'Unrecognised icon name.'
                                        }}
                                    />
                                </div>
                                <p className='mt-2 text-textSecondary'>
                                    Click{' '}
                                    <a
                                        href='https://fontawesome.com/v6/icons'
                                        target='_blank'
                                        rel='noreferrer noopener'
                                        className='text-textLink'
                                    >
                                        here
                                    </a>{' '}
                                    to see a full list of Font Awesome icon names.
                                </p>
                                <Field.Input
                                    name='singular'
                                    label='Singular name'
                                    title='Singular name'
                                    placeholder='Enter a singular name, e.g. server'
                                    help='This name is shown when a single object of this type is shown in the application.'
                                    validation={{
                                        required: true,
                                        maxLength: 128,
                                        minLength: 1
                                    }}
                                />
                                <Field.Input
                                    name='plural'
                                    label='Plural name'
                                    title='Plural name'
                                    placeholder='Enter a plural name, e.g. servers'
                                    help='This name is shown when a collection of these objects are shown in the application.'
                                    validation={{
                                        required: true,
                                        maxLength: 128,
                                        minLength: 1
                                    }}
                                />
                            </div>

                            <ModalButtons>
                                <Button type='button' variant='tertiary' onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button
                                    type='submit'
                                    disabled={isSubmitting || !isValid}
                                    data-testid='submit-custom-types'
                                >
                                    {isSubmitting ? <LoadingSpinner size={18} /> : 'Save'}
                                </Button>
                            </ModalButtons>
                        </>
                    )}
                </Form>
            </div>
        </Modal>
    );
};
