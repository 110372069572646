import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { Serialised } from '@squaredup/ids';
import { APIKEY } from 'components/ApiKey';
import Modal, { CloseHandler } from 'components/Modal';
import { AgentGroupOptions } from 'components/hooks/useAgentGroups';
import type { ProjectedPlugin } from 'dynamo-wrapper';
import { datasourceConfigQueryKeys } from 'queries/queryKeys/datasourceConfigKeys';
import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Delete } from 'services/ApiKeyService';
import { PLUGIN_DETAIL } from 'services/PluginService';
import { NavTitle } from 'ui/nav/ui/NavLinks';
import { PluginConfigForm } from './PluginConfigForm';
import PluginLoadingModal from './PluginLoadingModal';
import { PluginConfig, PluginContextProvider, PluginModalStep } from './components/PluginConfigContext';
import { getEditMode } from './components/getEditMode';

interface PluginConfigModalProps {
    config?: PluginConfig;
    selectedPlugin: Serialised<ProjectedPlugin>;
    agentGroups: AgentGroupOptions;
    workspaceToLinkTo?: string;
    permissiveLinking?: boolean;
    showBackButton?: boolean;
    close: CloseHandler;
}

function PluginConfigModal({
    config: initialConfig,
    selectedPlugin,
    agentGroups = [],
    workspaceToLinkTo = '',
    showBackButton = false, // Used for settings plugin modal
    close
}: PluginConfigModalProps) {
    const queryClient = useQueryClient();
    const [step, setStep] = useState<PluginModalStep>('form');
    const [newId, setNewId] = useState<string>('');
    const [config, setConfig] = useState<PluginConfig>(initialConfig);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isAdd = useMemo(() => ['new', 'newWithDraft'].includes(getEditMode(config)), []);

    const doClose: CloseHandler = (reason, details) => {
        queryClient.invalidateQueries(datasourceConfigQueryKeys.all);
        close(reason, details);
    };

    const deleteAndClose: CloseHandler = async (reason) => {
        // Delete key if generated
        const keyId: string | undefined = queryClient.getQueryData(APIKEY);

        if (keyId) {
            await Delete(keyId);
            queryClient.removeQueries(APIKEY);
        }

        doClose(reason);
    };

    const clearAndClose: CloseHandler = async (reason, details) => {
        queryClient.removeQueries(APIKEY);
    
        doClose(reason, details);
    };

    const backButton = (
        <NavTitle
            title='Back'
            onClick={() => {
                deleteAndClose('back');
            }}
            icon={faChevronLeft}
            className='mb-2 text-sm text-textSecondary hover:text-textPrimary'
        />
    );

    return (
        <Modal
            key={selectedPlugin.id}
            disableClickOutside
            maxWidth='max-w-4xl'
            preHeader={showBackButton && step !== 'loading' ? backButton : undefined}
            {...step === 'form' && {
                title: initialConfig ? 'Edit data source' : 'Add data source',
                maxWidth: 'max-w-7xl',
                modalClassName: 'grid grid-cols-[4fr_minMax(200px,3fr)] grid-rows-[min-content,1fr]',
                fullWidth: true,
                fullHeight: true,
                headerBorder: true
            }}
        >
            <PluginContextProvider
                config={config}
                plugin={selectedPlugin}
                agentGroups={agentGroups}
                workspaceToLinkTo={workspaceToLinkTo}
                step={step}
                setConfig={setConfig}
                setStep={setStep}
                newId={newId}
                setNewId={setNewId}
            >
                {step === 'form' && (
                    <PluginConfigForm
                        pluginSubmitHandler={(details) => {
                            queryClient.invalidateQueries([PLUGIN_DETAIL, details.newId || config?.id]);
                            setConfig({ ...config, id: details.newId || config?.id } as PluginConfig);
                            setNewId(details.newId || config?.id || '');
                            if (isAdd) {
                                // At this point we install and don't want to risk deleting the API key
                                queryClient.setQueriesData(APIKEY, null);
                                setStep('loading');
                            } else {
                                clearAndClose('submit', details);
                            }
                        }}
                        cancelHandler={() => deleteAndClose('cancel')}
                    />
                )}

                {step === 'loading' && (
                    <PluginLoadingModal 
                        pluginName={selectedPlugin.displayName ?? ''} 
                        {...!workspaceToLinkTo && { onClose: () => doClose('exit') }}
                    />
                )}
            </PluginContextProvider>
        </Modal>
    );
}

export default PluginConfigModal;
