import axios from 'axios';
import Color from 'color';
import type { DashboardType } from '@squaredup/dashboards';
import * as htmlToImage from 'html-to-image';
import { Options } from 'html-to-image/lib/types';
import { firefoxHtmlToPng } from './dashboardImageSnapshotsFirefox';
import { GetExternalImageViaPlatform } from 'services/ImageService';

const dashboardContainerElementId = 'dashDownloadAsImageContainer';

// Classes we need to add to the dashboard/tile container element to override default behaviour,
// to make snapshots work better.
const dashboardClassesToAdd = ['dashboard-image-export'];
const tileClassesToAdd = ['tile-image-export'];

// Controls resolution of snapshot. Fairly arbitrary - may need tweeking to provide support
// for various environments (browsers, display res etc)
const pixelRatioForDashboards = 2;
const pixelRatioForTiles = 2;

const hideForImageExportClass = 'hide-for-image-export';

export async function downloadTileAsImage(tileId: string, tileTitle?: string, addBackground = false) {
    const element = document.getElementById(`tile-${tileId}`);

    if (!element) {
        return;
    }

    tileClassesToAdd.forEach(c => element.classList.add(c));

    try {
        // If we're exporting a table tile we must scroll to the top to ensure virtualisation doesn't cause an issue
        const tableWrapper = element.querySelector('[data-target="tableWrapper"]');
        const currentTableScrollPosition = { top: tableWrapper?.scrollTop, left: tableWrapper?.scrollLeft };

        if (tableWrapper) {
            tableWrapper.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }

        const imageUrl = await htmlToImage.toPng(element, {
            pixelRatio: pixelRatioForTiles,
            cacheBust: true, // to avoid CORS issue with images,
            fontEmbedCSS: await getFontEmbedCss(element),
            height: element.scrollHeight,
            width: element.scrollWidth,
            filter: tileElementFilter,
            ...(addBackground && {
                style: {
                    background: Color(getComputedStyle(document.body).getPropertyValue('--tileBackground')).hex()
                }
            })
        });

        // Revert scroll position on table tiles
        if (tableWrapper) {
            tableWrapper.scrollTo({ ...currentTableScrollPosition, behavior: 'instant' });
        }

        downloadImage(imageUrl, `SquaredUp ${tileTitle || 'tile'} ${new Date().toLocaleString()}.png`);
    } finally {
        tileClassesToAdd.forEach(c => element.classList.remove(c));
    }
}

export async function downloadCurrentDashboardAsImage(dashboard: DashboardType) {
    const element = getDashboardContainerElement();
    if (!element) {
        return;
    }

    // We want to make some modifications, so we clone the dashboard and hide it
    // so the user doesn't see what we're doing.
    const clonedElement = element.cloneNode(true) as HTMLElement;
    if (!clonedElement) {
        return;
    }

    // Position the hidden dashboard in the same place as the original, so size and layout stays the same.
    const originalBoundingRect = element.getBoundingClientRect();
    clonedElement.style.position = 'absolute';
    clonedElement.style.top = '0';
    clonedElement.style.left = '0';
    clonedElement.style.width = originalBoundingRect.width + 'px';

    // Put the dashboard behind everything else and use opacity 0 to be extra sure it's not visible.
    clonedElement.style.zIndex = '-999';
    clonedElement.style.opacity = '0';

    // Add extra classes to apply any tweaks we need for export. See index.css for how these classes are used.
    dashboardClassesToAdd.forEach(c => clonedElement.classList.add(c));
    const imageUrlsToSkip = await prepareImagesForExport(clonedElement, dashboard);

    const parentElement = element.parentElement!;
    parentElement.appendChild(clonedElement);

    try {
        const options: Options = {
            pixelRatio: pixelRatioForDashboards,
            cacheBust: true, // to avoid CORS issue with images,
            height: element.scrollHeight,
            width: element.scrollWidth,
            filter: (e) => dashboardElementFilter(e, imageUrlsToSkip),
            fontEmbedCSS: await getFontEmbedCss(element),
            style: {
                background: Color(getComputedStyle(document.body).getPropertyValue('--backgroundPrimary')).hex(),
                opacity: '100' // restore visibility when htmlToImage does its rendering (on it's own internal copy of the node)
            }
        };

        const isFirefox = navigator?.userAgent?.includes('Firefox');
        const imageUrl = isFirefox
            ? await firefoxHtmlToPng(clonedElement, options)
            : await htmlToImage.toPng(clonedElement, options);

        if (imageUrl) {
            downloadImage(imageUrl, `SquaredUp ${dashboard.displayName || 'dashboard'} ${new Date().toLocaleString()}.png`);
        }
    } finally {
        parentElement.removeChild(clonedElement);
    }
}

export function downloadImage(imageUrl: string, fileName: string) {
    const fakeLink = window.document.createElement('a');
    fakeLink.download = fileName;
    fakeLink.href = imageUrl;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);
    fakeLink.remove();
};

function dashboardElementFilter(element: HTMLElement, imageUrlsToSkip: string[] = []) {
    const exclusionClasses = [hideForImageExportClass];

    if (imageUrlsToSkip.length && element.tagName?.toLowerCase() === 'img') {
        const imgSrc = (element as HTMLImageElement).src;
        if (imageUrlsToSkip.includes(imgSrc)) {
            return false;
        }
    }

    return !exclusionClasses.some((classname) => element.classList?.contains(classname));
}

function getDashboardContainerElement() {
    return document.getElementById(dashboardContainerElementId);
}

function tileElementFilter(element: HTMLElement) {
    const exclusionClasses = [hideForImageExportClass];
    return !exclusionClasses.some((classname) => element.classList?.contains(classname));
};

/* eslint-disable no-console */
async function getFontEmbedCss(element: HTMLElement) {
    // We get a bunch of ugly console errors due to failure to load 3rd party CSS rules (e.g. paddle),
    // so hide them for now.
    const error = console.error;
    console.error = () => { /* silent */ };
    try {
        return await htmlToImage.getFontEmbedCSS(element);
    } finally {
        console.error = error;
    }
}

/**
 * Internet images often can't be accessed from Javascript due to CORS.
 *
 * To workaround this we test the images and if they are not accessible, we fetch them through the platform
 * (to avoid CORS) and attach them to the dashboard element as a data URI.
 *
 * If we still cannot access the image, we return the URL so that the caller can just exclude them
 * from the image export process, to avoid them breaking the export.
 */
async function prepareImagesForExport(dashboardElement: HTMLElement, dashboard: DashboardType): Promise<string[]> {
    const imageUrlsToSkip: string[] = [];
    const dashboardImages = dashboardElement.querySelectorAll('img');

    await Promise.all([...dashboardImages].map(async (image: Element) => {
        const htmlImage = image as HTMLImageElement;

        if (htmlImage.src?.toLowerCase()?.startsWith('http')) { // Ignore data URIs
            try {
                // Use HEAD rather than GET because we just want to test access to the image
                // (particularly wrt CORS) - we don't want to download the image data.
                await axios.head(htmlImage.src);
            } catch {
                try {
                    // Can't access the image directly, probably due to CORS, so try to load the image
                    // via our platform to avoid any CORS issues.
                    const dataUri = await GetExternalImageViaPlatform(
                        dashboard.workspaceId,
                        dashboard.id,
                        htmlImage.src
                    );

                    // Note that we're modifying the passed in dashboardElement here, which is generally
                    // a temporary copy of the displayed dashboard. So we're not modifying the dashboard the user sees.
                    htmlImage.src = dataUri;
                } catch {
                    // Can't access image directly or via our platform, so just exclude it when exporting the dashboard.
                    imageUrlsToSkip.push(htmlImage.src);
                }
            }
        }
    }));

    return imageUrlsToSkip;
}
