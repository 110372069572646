import { handleError, handleResponse } from './util';

import API from './API';

// The path is the only thing that varies between most these config related client services -
// they could all just utilise some shared ConfigService.ts client-side code!
const path = '/scripts';

export const SCRIPTS = 'scripts';

export const Get = async (id: string) => {
    return API.get(`${path}/${id}`).then(handleResponse).catch(handleError);
};

export const List = async () => {
    return API.get(path).then(handleResponse).catch(handleError);
};

export const Create = async (displayName: string, scriptType: string, config: any) => {
    const created = await API.post(path, { displayName, scriptType, config }).then(handleResponse).catch(handleError);
    return created.id;
};

export const Update = async (id: string, displayName: string, config: any) => {
    await API.put(`${path}/${id}`, { displayName, config }).then(handleResponse).catch(handleError);
    return id;
};

export const Delete = async (id: string) => {
    await API.delete(`${path}/${id}`).then(handleResponse).catch(handleError);
    return true;
};
