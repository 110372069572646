import Text from '@/components/Text';
import { faChevronDown, faChevronUp, faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModal } from 'components/Modal';
import { Presence } from 'components/Presence';
import Field from 'components/forms/field/Field';
import Tooltip from 'components/tooltip/Tooltip';
import { useWorkspaceDetailedPermissions } from 'pages/settings/workspaces/useWorkspaceDetailedPermissions';
import { useWorkspaceLinks } from 'pages/settings/workspaces/useWorkspaceLinks';
import { ComponentPropsWithoutRef } from 'react';
import { useController } from 'react-hook-form';
import { Workspace } from 'services/WorkspaceService';

type Props = {
    workspace: Workspace;
};

export const WorkspaceModalAdvancedTab = (props: Props) => {
    const workspacePermissions = useWorkspaceDetailedPermissions(props.workspace);
    const inputAccordion = useModal();
    const linkWorkspacesController = useController({ name: 'links.workspaces' });

    const workspaceLinks = useWorkspaceLinks(props.workspace);
    const numberOfLinkedWorkspaces = linkWorkspacesController.field.value.length;

    if (!workspacePermissions.canEditLinks) {
        return null;
    }

    return (
        <Container>
            <Text.H4 className='flex items-center font-medium'>
                Links
                <Tooltip
                    title='Links to other workspaces to allow this workspace to read their health and KPI.'
                    className='text-textSecondary cursor-pointer shrink-0 ml-2'
                >
                    <FontAwesomeIcon className='ml-1' icon={faCircleQuestion} />
                </Tooltip>
            </Text.H4>

            <Text.H4 className='text-textSecondary font-medium'>
                This workspace can read health and KPIs from{' '}
                <span className='text-textPrimary'>{numberOfLinkedWorkspaces} other workspaces.</span>
            </Text.H4>

            <Presence isPresent={inputAccordion.isOpen}>
                <button
                    onClick={inputAccordion.close}
                    type='button'
                    className='text-textPrimary flex items-center font-medium'
                >
                    Hide workspaces <FontAwesomeIcon icon={faChevronUp} className='ml-1' />
                </button>
            </Presence>

            <Presence isPresent={!inputAccordion.isOpen}>
                <button
                    onClick={inputAccordion.open}
                    type='button'
                    className='text-textPrimary flex items-center font-medium'
                >
                    Show workspaces <FontAwesomeIcon icon={faChevronDown} className='ml-1' />
                </button>
            </Presence>

            <Presence isPresent={inputAccordion.isOpen}>
                <Field.Input
                    name='links.workspaces'
                    type='autocomplete'
                    title='Linked workspaces'
                    placeholder='Select other workspaces to access from this workspace'
                    helpAlign='Left'
                    options={workspaceLinks.workspaceOptions}
                    defaultValue={workspaceLinks.getDefaultLinkedWorkspaces}
                    isMulti={true}
                    isClearable={true}
                    isSearchable={true}
                    menuShouldBlockScroll={true}
                />
            </Presence>
        </Container>
    );
};

const Container = (props: ComponentPropsWithoutRef<'div'>) => {
    return <div {...props} className='space-y-2' />;
};
