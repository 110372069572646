import LoginWrapper from 'pages/login/LoginWrapper';
import Auth from 'services/Auth';
import { setSignInPrompt } from 'services/Auth/signInIdpPrompt';
import { useApplicationSubdomain } from 'queries/hooks/useApplicationSubdomain';
import { linkButtonClasses } from 'pages/login/loginStyles';

// NOTE: Use of text-[14px] on this page is to match B2C sign-up / sign-in pages

function goToSignIn() {
    // User intends to sign in as a different user - make sure the IdP (MS, Google) doesn't
    // sign them straight back in with the same account! This hint to the IdP should mean
    // that it asks the user which account they want to use.
    //
    // NOTE: This does not appear to work with Okta. The user will have to sign out of Okta
    // if they want to try signing in with a different user.
    //
    setSignInPrompt('select_account');

    // Log out will exit from the current user session and take us back to the sign-in page
    // so the user can try a different account (or to the 'You have signed out' page if we're using domain_hint 
    // to bypass the sign-in page).
    Auth.logout();
}

function goToSignUp() {
    // We need to log out the current user and then navigate to the sign up
    // page so the user can use a different user to sign up.
    //
    // Normally a logout redirects to the sign IN page afterwards, so we need to explicitly
    // ask to redirect to the sign UP page instead.
    Auth.logout({ signUpAfterSignOut: true });
}

export function SignInFail({ email }: { email: string }) {
    const { isLoading: isLoadingApplicationSubdomain, data: applicationSubdomain } = useApplicationSubdomain();

    if (isLoadingApplicationSubdomain) {
        return <></>;
    }

    return (
        <LoginWrapper>
            <div className='flex flex-col flex-1 min-h-0 leading-normal' data-testid='signInFail'>
                <h1 className='mt-10 text-2xl font-semibold'>There are no eligible SquaredUp organizations available.</h1>

                <p className='mt-10 text-textSecondary text-[14px]'>
                    <span className='mr-1.5'>
                        Please ask a SquaredUp administrator to invite{' '}
                        <span className='font-semibold text-textPrimary'>{email}</span> or
                    </span>
                    <button className={`${linkButtonClasses}`} onClick={() => goToSignIn()}>
                        sign in
                    </button>
                    <span className='ml-1.5'>as a different user, region or authentication method.</span>
                </p>

                {/* Don't show link to sign-up from a subdomain - it may not work as expected if 
                    subdomain has restricted sign-in settings */}
                {!applicationSubdomain?.subdomain && (
                    <p className='mt-8 text-textSecondary text-[14px]'>
                        <span className='mr-1.5'>Alternatively,</span>
                        <button className={`${linkButtonClasses}`} onClick={() => goToSignUp()}>
                            sign up
                        </button>
                        <span className='ml-1.5'>for a new organization.</span>
                    </p>
                )}
            </div>
        </LoginWrapper>
    );
}
