import { useCallback, useLayoutEffect, useRef } from 'react';

type Props<Element> = { onResizeHandler: (element: Element) => void };

export const useOnResize = <Element extends HTMLElement>({ onResizeHandler }: Props<Element>) => {
    const ref = useRef<Element | null>(null);

    const resizeHandler = useCallback(() => {
        if (ref.current) {
            onResizeHandler(ref.current);
        }
    }, [onResizeHandler]);

    useLayoutEffect(() => {
        resizeHandler();

        window.addEventListener('resize', resizeHandler);

        return () => window.removeEventListener('resize', resizeHandler);
    }, [onResizeHandler, resizeHandler]);

    return { ref };
};
