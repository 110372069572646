import { AxiosPromise } from 'axios';
import { handleError, handleErrorNoToast, handleResponse } from './util';
import API from './API';
import { startOfHour } from 'date-fns';

function getDashboardImagePath(spaceId: string, dashId: string, tileId: string) {
    return `/workspaces/${spaceId}/dashboards/${dashId}/images/${tileId}`;
}

async function handle(promise: AxiosPromise) {
    return promise.then(handleResponse).catch(handleError);
}

export async function GetDashboardImage(spaceId: string, dashId: string, tileId: string, uploaded: number) {
    // Include the uploaded timestamp as a query string param (unused) just so we can include it
    // in the CloudFront cache key and avoid hitting the origin unless a new image is uploaded.
    return handle(API.get(`${getDashboardImagePath(spaceId, dashId, tileId)}?uploaded=${uploaded || Date.now()}`)) as
        Promise<{ dataURL: string, metadata?: { [key: string]: unknown } }>;
};

export async function PutDashboardImage(
    spaceId: string, dashId: string, tileId: string, data: { dataURL: string, metadata?: { [key: string]: unknown } }
) {
    return handle(API.put(getDashboardImagePath(spaceId, dashId, tileId), data));
};

export async function CloneDashboardImage(spaceId: string, dashId: string, tileId: string, toTileId: string) {
    return handle(API.put(`${getDashboardImagePath(spaceId, dashId, tileId)}/clone?to=${toTileId}`));
};

export async function DeleteDashboardImage(spaceId: string, dashId: string, tileId: string) {
    return handle(API.delete(getDashboardImagePath(spaceId, dashId, tileId)));
};

/**
 * Retrieve an internet image via our platform, to bypass CORS restrictions.
 * Returns image as a data URI.
 */
export async function GetExternalImageViaPlatform(
    spaceId: string,
    dashId: string,
    url: string
): Promise<string> {
    // The images accessed through this endpoint are cached by CloudFront.
    // The cache interval may be too long for us, because dashboard image URLs *could*
    // reference a dynamic image that changes frequently.  Unlikely, but possible.
    // For now we'll be conservative and only cache for max one hour.
    const version = startOfHour(Date.now()).getTime();

    return API.get<string>(
        `/workspaces/${spaceId}/dashboards/${dashId}/images/external?url=${encodeURIComponent(
            url
        )}&version=${version}`
    )
        .then(handleResponse)
        .catch(handleErrorNoToast);
};
