import { StreamDataColumn, DataStreamSortingConfig } from '@squaredup/data-streams';
import { orderBy } from 'lodash';
import { DeepPartial } from 'react-hook-form';

export interface SortingValues {
    column?: string | null;
    direction: 'asc' | 'desc';
    top?: string;
    enableTop: boolean;
}

export const directionOptions = [
    {
        value: 'asc',
        label: 'Ascending'
    },
    {
        value: 'desc',
        label: 'Descending'
    }
];

export const getSortingFormDefaultValues = (
    sort: DataStreamSortingConfig['sort'] | undefined,
    columnOptions: { value: string; label: string }[]
): SortingValues => {
    const [columnName, directionString] = sort?.by?.[0] ?? [];

    const column = columnOptions.find((v) => v.value === columnName)?.value ?? undefined;
    const direction = (directionOptions.find((v) => v.value === directionString)?.value ??
        directionOptions[0].value ??
        'asc') as SortingValues['direction'];

    return sort
        ? {
              column,
              direction,
              top: sort?.top?.toString() ?? undefined,
              enableTop: Boolean(sort?.top) && column != null
          }
        : { direction: 'asc', enableTop: false };
};

export const getSortingColumns = (columns: StreamDataColumn[]) =>
    orderBy(
        columns.map((c) => ({
            value: c.name,
            label: c.displayName
        })),
        'label'
    );

export const getValidSort = (data: DeepPartial<SortingValues>): DataStreamSortingConfig['sort'] => {
    if (data.enableTop && data.top && !isNaN(parseInt(data.top))) {
        return {
            by: [[data.column ?? undefined, data.direction]],
            top: parseInt(data.top)
        };
    }

    if (data.column == null) {
        return {};
    }

    return {
        by: [[data.column, data.direction]]
    };
};
