import type { OpenAccessShare } from 'dynamo-wrapper';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { UpdateShare } from 'services/OpenAccessService';
import { useDashboardId } from 'ui/hooks/useDashboardId';

type Input = { id: OpenAccessShare['id']['value']; requireAuth: boolean; enabled: boolean };
type Option = UseMutationOptions<any, unknown, Input>;

/**
 * useMutation to update an existing sharing link for a dashboard.
 * @param options The useMutation options e.g. refreshInterval
 * @returns a mutation variable
 */
export const useUpdateDashboardSharingLink = (option?: Option) => {
    const queryClient = useQueryClient();
    const dashboardId = useDashboardId();

    return useMutation({
        ...option,
        mutationFn: async (props: Input) => {
            return UpdateShare(props.id, props.enabled, props.requireAuth, []);
        },
        onSettled: () => {
            if (dashboardId) {
                queryClient.invalidateQueries(dashboardQueryKeys.openAccessForDashboard(dashboardId));
            }
        }
    });
};
