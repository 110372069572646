import { faEnvelope, faQuestion, faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ServiceNow from 'icons/destination-icons/servicenow.svg?react';
import Slack from 'icons/destination-icons/slack.svg?react';
import Teams from 'icons/destination-icons/teams.svg?react';
import Zapier from 'icons/destination-icons/zapier.svg?react';
import { ChannelTypeIds } from '@squaredup/monitoring';

interface IconProps {
    channelTypeId?: string;
    [key: string]: unknown;
}

export const NotificationDestinationIcon: React.FC<IconProps> = ({ channelTypeId, ...props }) => {
    switch (channelTypeId) {
        case ChannelTypeIds.Email:
            return <FontAwesomeIcon icon={faEnvelope} fixedWidth {...props} />;
        case ChannelTypeIds.SlackAPI:
        case ChannelTypeIds.SlackWebHook:
            return <Slack {...props} />;
        case ChannelTypeIds.Teams:
            return <Teams {...props} />;
        case ChannelTypeIds.ServiceNow:
            return <ServiceNow {...props} />;
        case ChannelTypeIds.Custom:
            return <FontAwesomeIcon icon={faPenToSquare} fixedWidth {...props} />;
        case ChannelTypeIds.Zapier:
            return <Zapier {...props} />;
        default:
            return <FontAwesomeIcon icon={faQuestion} fixedWidth {...props} />;
    }
};
