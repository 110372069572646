import type { FormattedStreamValue } from '@squaredup/data-streams';

/**
 * Determines whether a given column has a unique value for every row
 */
export const hasUniqueValues = (rows: FormattedStreamValue[][], columnIndex: number) => {
    const seenValues = new Set<string>();

    const isUnique = rows.every((r) => {
        if (seenValues.has(r[columnIndex].formatted)) {
            return false;
        }

        seenValues.add(r[columnIndex].formatted);

        return true;
    });

    return isUnique;
};

export const validateNonEmptyData = (
    data: {
        value: number;
    }[]
) => {
    return data.length > 0 && data.some((s) => s.value !== 0);
};
