import { useCallback } from 'react';
import { GROUP_NODE } from '../components/nodes/GroupNode';
import { useGetExpandedNodeIds, useGetGraphEdges, useGetGraphNodes, useGetNetworkNodes, useGetPinnedGroupsWithMemberIds, useGetPinnedNodeIds, useGetUngroupedNodeIds, useSetExpandedNodeIds, useSetPinnedGroupsWithMemberIds, useSetPinnedNodeIds, useSetUngroupedNodeIds } from '../context/NetworkMapStoreContext';
import { useFetchExpandedNodes } from './useFetchExpandedNodes';
import { useRevealExpandedNodes } from './useRevealExpandedNodes';

interface ActionPayload {
    nodeIds: string[],
    sourceNodeIds?: string[],
    groupNodeId?: string,
}

export enum NodeAction {
    expand,
    expandGroup,
    ungroup,
    pin,
    pinAndExpand,
    pinAndExpandGroup,
}

export const addOrRemoveId = (entries: string[], id: string) => {
    if (entries.indexOf(id) < 0) {
        entries.push(id);
    } else {
        entries.splice(entries.indexOf(id), 1);
    }
};

export const useHandleNodeAction = () => {
    const getNodes = useGetNetworkNodes();
    const getGraphNodes = useGetGraphNodes();
    const getGraphEdges = useGetGraphEdges();
    const getExpandedNodeIds = useGetExpandedNodeIds();
    const setExpandedNodeIds = useSetExpandedNodeIds();
    const getPinnedNodeIds = useGetPinnedNodeIds();
    const setPinnedNodeIds = useSetPinnedNodeIds();
    const getPinnedGroupMemberWithNodeIds = useGetPinnedGroupsWithMemberIds();
    const setPinnedGroupsWithMemberNodeIds = useSetPinnedGroupsWithMemberIds();
    const getUngroupedNodeIds = useGetUngroupedNodeIds();
    const setUngroupedNodeIds = useSetUngroupedNodeIds();
    const fetchExpandedNodes = useFetchExpandedNodes();
    const revealExpandedNodes = useRevealExpandedNodes();

    return useCallback(async (action: NodeAction, payload: ActionPayload) => {  
        const { nodeIds, sourceNodeIds, groupNodeId } = payload;

        const existingGraphNodes = getGraphNodes();
        const existingGraphEdges = getGraphEdges();
        const existingExpandedNodeIds = getExpandedNodeIds();
        const existingPinnedNodeIds = getPinnedNodeIds();
        const existingUngroupedNodeIds = getUngroupedNodeIds();
        const existingpinnedGroupsWithMemberNodeIds = getPinnedGroupMemberWithNodeIds();

        const updatedExpandedNodeIds = [...existingExpandedNodeIds];
        const updatedPinnedNodeIds = [...existingPinnedNodeIds];
        const updatedPinnedGroupsWithMemberNodeIds = new Map(existingpinnedGroupsWithMemberNodeIds);
        const updatedUngroupedNodeIds = [...existingUngroupedNodeIds];

        const nodes = [...getNodes()];

        const visibleConnectedNodeIds = nodes
            .filter(({ hidden }) => !hidden)
            .map(({ id, type, data }) => [id, ...type === GROUP_NODE && data.sourceNodeIds || []])
            .flat();

        const groupNodes = nodes
            .filter(({ id, type }) => type === GROUP_NODE && nodeIds.includes(id));

        if (![NodeAction.pin, NodeAction.ungroup].includes(action)) {
            nodeIds.forEach(
                (id) => {
                    addOrRemoveId(updatedExpandedNodeIds, id);

                    // If the node being expanded/collapsed is currently ungrouped, remove it from the list
                    // of ungroupednodes as expanded nodes aren't grouped and it'll make it easier to track.
                    // A nice added side-effect is that it will collapse back into the group
                    if (existingUngroupedNodeIds.includes(id)) {
                        updatedUngroupedNodeIds.splice(updatedUngroupedNodeIds.indexOf(id), 1);
                    }
                }
            );

            setExpandedNodeIds(updatedExpandedNodeIds);
        } 
        
        if ([NodeAction.pinAndExpandGroup, NodeAction.pinAndExpand, NodeAction.pin].includes(action)) {
            nodeIds.forEach(
                (id) => addOrRemoveId(updatedPinnedNodeIds, id)
            );

            // Prevent unpinning last node we want to prevent the action
            if (!updatedPinnedNodeIds.length) {
                return;
            }

            // For group nodes being pinned/unpinned we want to add/remove the source nodes from the 
            // pinnedGroupMemberNodes so they stay around when collapsing all nodes
            if (groupNodes.length) {
                groupNodes.forEach(({ id, data }) => {
                    const pinnedGroupMemberNodeIds = updatedPinnedGroupsWithMemberNodeIds.get(id) ?? [];

                    data.sourceNodeIds?.forEach((sourceNodeId) => 
                        addOrRemoveId(pinnedGroupMemberNodeIds, sourceNodeId)
                    );
    
                    updatedPinnedGroupsWithMemberNodeIds.set(id, pinnedGroupMemberNodeIds);
                });

                setPinnedGroupsWithMemberNodeIds(updatedPinnedGroupsWithMemberNodeIds);
            }
            
            setPinnedNodeIds(updatedPinnedNodeIds);
        }

        if (action === NodeAction.ungroup) {
            nodeIds.forEach(
                (id) => addOrRemoveId(updatedUngroupedNodeIds, id)
            );   
            
            const groupMembers = updatedPinnedGroupsWithMemberNodeIds.get(groupNodeId ?? '');

            // When we ungroup a node we want to ensure that the pinnedGroupMembers reflects the removal of the node
            nodeIds?.forEach((id) => {
                if (groupMembers?.includes(id)) {
                    updatedUngroupedNodeIds.forEach(ungroupedNode => {
                        groupMembers.splice(groupMembers.indexOf(ungroupedNode), 1);
                        updatedPinnedGroupsWithMemberNodeIds.set(id, groupMembers);
                    });
                }
            });

            setUngroupedNodeIds(updatedUngroupedNodeIds);
            setPinnedGroupsWithMemberNodeIds(updatedPinnedGroupsWithMemberNodeIds);
        }

        const updatedGraphNodes = existingGraphNodes;
        const updatedGraphEdges = existingGraphEdges;

        revealExpandedNodes(
            nodeIds,
            updatedGraphNodes, 
            updatedGraphEdges, 
            updatedExpandedNodeIds ?? [],
            updatedPinnedNodeIds ?? [],
            updatedPinnedGroupsWithMemberNodeIds ?? new Map<string, string[]>(),
            updatedUngroupedNodeIds ?? []
        );

        // Fetch new nodes if we've expanded a node (if it's a group we want to use the sourceNodeIds)
        if ((existingExpandedNodeIds.length < updatedExpandedNodeIds.length) || sourceNodeIds?.length) {
            fetchExpandedNodes(
                sourceNodeIds?.length ? sourceNodeIds : nodeIds,
                updatedExpandedNodeIds ?? [],
                updatedPinnedNodeIds ?? [],
                updatedPinnedGroupsWithMemberNodeIds ?? [],
                updatedUngroupedNodeIds ?? [],
                visibleConnectedNodeIds
            );
        }
    }, [
        getGraphNodes, 
        getGraphEdges, 
        getExpandedNodeIds, 
        getPinnedNodeIds, 
        getUngroupedNodeIds, 
        getPinnedGroupMemberWithNodeIds, 
        getNodes, 
        revealExpandedNodes, 
        setExpandedNodeIds, 
        setPinnedNodeIds, 
        setPinnedGroupsWithMemberNodeIds, 
        setUngroupedNodeIds, 
        fetchExpandedNodes
    ]);
};
