import { findColumn, required } from '@squaredup/data-streams';
import { Timeframe } from '@squaredup/timeframes';
import { requestData } from 'services/DataStreamService';

const getScopeQuery = (sourceIds: string[], ids: string[]) => {
    if (sourceIds.length < 1 && ids.length < 1) {
        return '';
    }

    let scopeQuery = 'g.V().or(';

    if (sourceIds.length > 0) {
        // Note trailing comma, Gremlin 'or' steps can have trailing but not leading commas
        scopeQuery += `has('sourceId', within(${sourceIds.map((sourceId) => `'${sourceId}'`).join(',')})),`;
    }

    if (ids.length > 0) {
        scopeQuery += `has('id', within(${ids.map((id) => `'${id}'`).join(',')}))`;
    }

    scopeQuery += ')'; // close the 'or' step

    return scopeQuery;
};

export const getHealthData = async (workspaceId: string, sourceIds: string[], ids: string[]) => {
    const scopeQuery = getScopeQuery(sourceIds, ids);

    if (!workspaceId || !scopeQuery) {
        return [];
    }

    const dataStreamData = await requestData({
        dataStreamId: 'datastream-health',
        scope: {
            workspace: workspaceId,
            query: scopeQuery
        },
        timeframe: {} as Timeframe,
        options: {}
    });

    if (dataStreamData.rows.length === 0) {
        return [];
    }

    const idColumn = findColumn(dataStreamData.metadata.columns, required('name', 'data.id')).throwIfFailed();
    const sourceIdColumn = findColumn(
        dataStreamData.metadata.columns,
        required('name', 'data.sourceId')
    ).throwIfFailed();
    const stateColumn = findColumn(dataStreamData.metadata.columns, required('name', 'data.state')).throwIfFailed();

    return dataStreamData.rows.flatMap((row) => [
        {
            id: row[idColumn.dataIndex].value.toString(),
            state: row[stateColumn.dataIndex].value.toString()
        },
        {
            id: row[sourceIdColumn.dataIndex].value.toString(),
            state: row[stateColumn.dataIndex].value.toString()
        }
    ]);
};
