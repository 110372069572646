import { Serialised } from '@squaredup/ids';
import Button from 'components/button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import type { Agent } from 'dynamo-wrapper';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AGENTS, Delete } from 'services/AgentService';

type AgentDeleteModalProps = {
    agent: Serialised<Agent>;
    onClose: () => void;
};

function AgentDeleteModal({ agent, onClose }: AgentDeleteModalProps) {
    const queryClient = useQueryClient();
    const [isDeleting, setDeleting] = useState(false);

    const { mutate: handleDelete } = useMutation(
        () => {
            setDeleting(true);
            return Delete(agent.id);
        },
        {
            onSettled: async () => {
                await queryClient.invalidateQueries([AGENTS]);
                onClose();
            }
        }
    );

    const description = 'Are you sure you want to permanently delete this agent?';

    return (
        <Modal
            title={`Delete Agent: ${agent.displayName}`}
            description={description}
            close={onClose}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <ModalButtons>
                <Button type='button' variant='tertiary' onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    type='submit'
                    variant='destructive'
                    onClick={handleDelete}
                    disabled={isDeleting}
                    data-testid='deleteConfirm'
                >
                    {isDeleting ? <LoadingSpinner size={18} /> : 'Delete'}
                </Button>
            </ModalButtons>
        </Modal>
    );
}
export default AgentDeleteModal;
