import { OBJECT_NODE, OBJECT_NODE_SIZE } from '../../components/nodes/ObjectNode';

export const objectToNode = (object: any) => {

    return {
        id: object.id,
        type: OBJECT_NODE,
        position: {
            x: 0,
            y: 0
        },
        width: OBJECT_NODE_SIZE,
        height: OBJECT_NODE_SIZE,
        data: {
            label: `${object.name?.[0]}`,
            type: object.type?.[0],
            sourceType: object.sourceType?.[0],
            sourceNodeIds: object.sourceNodeIds,
            isCanonical: Boolean(object.sourceNodeIds?.length),
            initialState: Array.isArray(object.state) ? object.state?.[0] : object.state
        }
    };
};
