import { DataStreamScope, resolveScopeToTargetNodes } from '@squaredup/data-streams';
import { Node } from '@squaredup/graph';
import { fiveMinutes } from 'queries/constants';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { Query } from 'services/GraphService';
import { NO_ACTIVE_WORKSPACE } from 'services/WorkspaceUtil';

type QueryReturnType = Node[];
type QuerySelectReturnType = QueryReturnType;

/**
 * useQuery returning the graph objects for a scope config
 * @param scope The scope content you'd like to use when resolving objects
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of saved scopes
 */
export const useScopeContentToObjects = <T = QuerySelectReturnType>(
    scope?: DataStreamScope,
    workspaceId?: string | null,
    options?: Omit<UseQueryOptions<QueryReturnType, unknown, T, string[]>, 'enabled'>
) => {
    const isGlobal = workspaceId === NO_ACTIVE_WORKSPACE;

    return useQuery(
        scopeQueryKeys.scopeContentToObjects(scope, workspaceId), 
        async () => resolveScopeToTargetNodes((query) => Query(
            query, 
            isGlobal ? 'directOrAnyWorkspaceLinks' : undefined
        ))(scope), 
        {
            staleTime: fiveMinutes,
            cacheTime: fiveMinutes,
            keepPreviousData: true,
            enabled: Boolean(scope),
            ...options
        }
    );
};
