import Text from '@/components/Text';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGlobalKeybind } from 'components/hooks/useGlobalKeybind';
import { useAppContext } from 'contexts/AppContext';

export const SearchTrigger: React.FC = () => {
    const { setSearchOpen } = useAppContext();

    useGlobalKeybind((e) => {
        const windowsKeybindTriggered = e.ctrlKey && e.key === 'k';
        const appleKeybindTriggered = e.metaKey && e.key === 'k';

        if ((!windowsKeybindTriggered && !appleKeybindTriggered) || !document.getElementById('global-search-button')) {
            return;
        }

        e.preventDefault();
        setSearchOpen((prev: boolean) => !prev);
    });

    return (
        <button
            id='global-search-button'
            className='flex items-center w-full px-4 text-sm font-semibold text-left py-xs group text-textSecondary shrink-0 hover:bg-secondaryButtonBackground focus:bg-secondaryButtonBackground border-y border-dividerPrimary'
            onClick={() => setSearchOpen(true)}
        >
            <FontAwesomeIcon
                size='sm'
                className='mr-[9px] text-[14px] ml-0.5'
                icon={faMagnifyingGlass}
                fixedWidth
            />{' '}

            <Text.H4 className='leading-none'>Search</Text.H4>

            <kbd className='flex items-center ml-auto font-sans font-medium leading-none capitalize opacity-0 group-hover:opacity-100'>
                {/mac/u.test(navigator.userAgent.toLowerCase()) ? (
                    <span>⌘</span>
                ) : (
                    <span>ctrl</span>
                )}
                +K
            </kbd>
        </button>
    );
};
