import { fiveMinutes } from 'queries/constants';
import { datasourceConfigQueryKeys } from 'queries/queryKeys/datasourceConfigKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { ListPluginSourceConfigsForAdmin } from 'services/SourceConfigService';

type QueryReturnType = Awaited<ReturnType<typeof ListPluginSourceConfigsForAdmin>>;
type QuerySelectReturnType = QueryReturnType;

/**
 * useQuery returning the data source configs for linking for a tenant
 * @param options The react-query options e.g. refreshInterval
 * @returns Data source configs for linking in a workspace
 */
export const useDatasourceConfigsForLinking = (
    options?: Omit<UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>, 'enabled'>
) => 
    useQuery(
        datasourceConfigQueryKeys.forLinking, 
        ListPluginSourceConfigsForAdmin, 
        {
            staleTime: fiveMinutes,
            cacheTime: fiveMinutes,
            keepPreviousData: true,
            ...options
        }
    );
