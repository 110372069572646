import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { scopeLimitMaximum } from '@squaredup/constants';
import { DataStreamGremlinScope } from '@squaredup/data-streams';
import { TruncatedText } from 'components/TruncatedText';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { getNameForType } from 'lib/types';
import { groupBy } from 'lodash';
import { useScope } from 'queries/hooks/useScope';
import { useScopeContentToObjects } from 'queries/hooks/useScopeContentToObjects';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { useDataStreamTemplate } from '../hooks/useDataStreamTemplate';

export const DataStreamObjectsSublabel = () => {
    const { config } = useDatasetContext();
    const { variables = [] } = useDashboardContext();
    const { workspace } = useDataStreamWorkspaceContext();
    const { dataStream, pluginDataSource } = useDataStreamTemplate();

    const objectLimit = dataStream?.definition?.objectLimit ?? pluginDataSource?.objectLimit;

    const { data: scope } = useScope(
        workspace,
        config?.scope && 'scope' in config.scope ? config.scope.scope : undefined
    );

    const { data: scopeObjectsByType } = useScopeContentToObjects(
        config?.scope && 'query' in config.scope
            ? ({
                  ...config.scope,
                  excludeCanonicalLinkedNodes: true // Always filter out canonical as the tile editor only allows you to select source nodes
              } as DataStreamGremlinScope)
            : undefined,
        workspace,
        {
            select: (objects) =>
                groupBy(objects, (object) => {
                    const objectType = object.type?.[0] ?? object.sourceType?.[0];
                    return objectType ? getNameForType(objectType, 'singular', 'object') : 'object';
                })
        }
    );

    if (!config.scope) {
        return <span>Select objects</span>;
    }

    if (variables.length && config.variables?.length) {
        const tileVariables = variables.filter((v) => config.variables?.includes(v.id));

        if (tileVariables.length) {
            return (
                <div className='flex items-center w-full min-w-0 space-x-2'>
                    <TruncatedText title='Variable' className='flex-1 min-w-0' />
                    <div className='w-6 h-6'>
                        <FontAwesomeIcon icon={faBarsFilter} fixedWidth={true} className='ml-2' />
                    </div>
                </div>
            );
        }
    }

    if (scope) {
        return <TruncatedText title={scope.displayName}>{scope.displayName}</TruncatedText>;
    }

    if (scopeObjectsByType) {
        const allObjects = Object.values(scopeObjectsByType).flat();
        const objectTypes = Object.keys(scopeObjectsByType);

        if (allObjects.length === 1) {
            const objectName = allObjects[0]?.name[0];
            return <TruncatedText title={objectName}>{objectName}</TruncatedText>;
        }

        const hasMultipleTypes = objectTypes.length > 1;
        const hasMultipleObjects = allObjects.length > 1;
        const fallback = hasMultipleObjects ? 'objects' : 'object';

        // Get the friendly, pluralised type name
        // If there are multiple types we just show object/objects
        const typeName = hasMultipleTypes
            ? fallback
            : getNameForType(objectTypes[0], hasMultipleObjects ? 'plural' : 'singular', fallback);

        const objectsSummary = `${Math.min(allObjects.length, objectLimit ?? scopeLimitMaximum)} ${typeName} selected`;

        return <TruncatedText title={objectsSummary}>{objectsSummary}</TruncatedText>;
    }

    return <span>-</span>;
};
