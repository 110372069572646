import Button from 'components/button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import React, { useState } from 'react';

type ConfirmationPromptArgs = {
    title: string;
    prompt: string | React.ReactNode;
    confirmButtonText?: string;
    confirmButtonVariant?: 'primary' | 'destructive';
    cancelButtonText?: string;
    hideCancel?: boolean;
    onConfirm: (() => Promise<void>) | (() => void);
    onClose: () => void;
};

/**
 * Simple modal confirmation prompt, with configurable text and OK/cancel buttons.
 *
 * If the user confirms the action, onConfirm is called, then onClose.
 * If the user cancels the action, onClose is called.
 * If onConfirm throws an error, the error is displayed, and the user can retry or cancel.
 *
 * The caller must handle showing/hiding the component.
 */
export const ConfirmationPrompt: React.FC<ConfirmationPromptArgs> = ({
    title,
    prompt,
    confirmButtonText = 'OK',
    cancelButtonText = 'Cancel',
    confirmButtonVariant = 'primary',
    hideCancel = false,
    onConfirm,
    onClose
}) => {
    const [isConfirming, setIsConfirming] = useState(false);
    const [error, setError] = useState(false);

    const handleConfirm = async () => {
        setIsConfirming(true);
        setError(false);

        try {
            await onConfirm();
            onClose();
        } catch {
            setError(true);
            setIsConfirming(false);
        }
    };

    return (
        <Modal title={title} description={prompt} close={onClose} fullWidth maxWidth='max-w-3xl'>
            {error && <p className='px-8 mt-2 text-statusErrorPrimary'>An error occurred, please try again.</p>}
            <ModalButtons>
                {!hideCancel && (
                    <Button type='button' variant='tertiary' onClick={onClose}>
                        {cancelButtonText}
                    </Button>
                )}

                <Button type='button' onClick={handleConfirm} variant={confirmButtonVariant} disabled={isConfirming}>
                    {isConfirming ? <LoadingSpinner size={18} /> : confirmButtonText}
                </Button>
            </ModalButtons>
        </Modal>
    );
};
