/* eslint-disable no-console */
import * as Msal from 'msal';
import Config from '../../config';
import { buildAuthority, config as msalConfig } from './msalConfig';
import { endOpenAccessSession } from './OpenAccessAuth';
import { isOpenAccess } from 'lib/openAccessUtil';
import { B2COption } from '@squaredup/constants';
import { clearSignInPrompt, getSignInPrompt } from './signInIdpPrompt';
import {
    getApplicationSubdomain,
    getRequiredIdentityProvider,
    getPostLogoutRedirectUri,
    setForceSignInAfterSignOut,
    setSignUpAfterSignOut
} from './getApplicationSubdomain';
import { environment } from 'lib/environment';
import { hasProperty } from '@squaredup/utilities';

// Local storage setting to enable testing of token expiry detection (rather than waiting 12 hours)
const SQUP_ONE_MINUTE_TOKEN_EXPIRY = 'SQUP_ONE_MINUTE_TOKEN_EXPIRY'; 

let user = undefined;
let tokenExpired = false;
const appLoadUrl = new URL(window.location.href);

/**
 * Flag to indicate we have initiated a login, so we can detect multiple concurrent login attempts (e.g.
 * due to React re-rendering).
 *
 * In most cases a login will cause our app to reload (via a redirect through B2C), so the flag is
 * automatically reset back to false.
 *
 * Worst case scenario if the flag is stuck on true, a fresh navigation to the app (or browser refresh)
 * will clear it.
 */
let loginInProgress = false;

const localStorageAvailable = () => {
    try {
        localStorage.getItem('anything');
        return true;
    } catch {
        return false;
    }
};

/**
 * Trigger immediate sign out if our JWT (access token) has expired.
 * 
 * This just makes the sign out and redirect to sign-in page process a bit quicker than 
 * if we let the token be used and then trigger API call failures etc.
 */
function logoutIfTokenExpired(msalApp) {
    try {
        if (isOpenAccess() || !localStorageAvailable() || !msalApp || !msalApp.getAccount()) {
            return false;
        }
    
        let exp = msalApp.getAccount().idToken?.exp;
        if (!exp || typeof exp !== 'number') {
            return false;
        }

        const isOneMinuteExpiryEnabled = window.localStorage.getItem(SQUP_ONE_MINUTE_TOKEN_EXPIRY);
        if (isOneMinuteExpiryEnabled === 'true') {
            // Test mode enabled that simulates token expiry after one minute rather than 12 hours.
            // (by reducing the expiry time by 11h 59m. Note that exp is in seconds, not milliseconds.
            console.log('SQUP_ONE_MINUTE_TOKEN_EXPIRY enabled, so subtracting 11h 59m from token expiry');
            exp -= (12 * 60 * 60) - 60;
        }
        
        const expiryEpochMs = exp * 1000;
        const isExpired = expiryEpochMs < Date.now();

        if (isExpired) {
            console.log('User session has expired - signing out to trigger new sign-in');
            // If we have domain_hint, don't want to go to 'you have signed out' page - we can just log back in again.
            setForceSignInAfterSignOut(true); 
            msalApp.logout(); // This will asynchronously reload the app at some point in the near future.
        } 

        return isExpired;
    } catch (err) {
        console.error('Failed to check that token is still valid.', err);
    }

    return false;
}

/**
 * Config object to be passed to MSAL on creation.
 * For a full list of msal.js configuration parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_configuration_.html
 * */
const appConfig = {
    auth: {
        clientId: msalConfig.clientId,
        authority: buildAuthority(msalConfig.b2cPolicies.signUp),
        validateAuthority: false,
        redirectUri: msalConfig.redirectUri,
        postLogoutRedirectUri: () => getPostLogoutRedirectUri(appLoadUrl)
    },
    cache: {
        cacheLocation: localStorageAvailable() ? 'localStorage' : 'sessionStorage',
        storeAuthStateInCookie: false
    }
};

// Enable MSAL logging if required.
// (You can add the local storage entry in dev tools - make sure it's for the app URL, not a B2C URL)
if (localStorageAvailable() && window.localStorage.getItem('SQUP_MSAL_LOGGING_ENABLED')) {
    appConfig.system = {
        ...appConfig.system,
        logger: new Msal.Logger(
            // eslint-disable-next-line no-unused-vars
            (logLevel, message, containsPii) => {
                console.log(`MSAL: ${message}`);
            },
            {
                level: Msal.LogLevel.Verbose,
                piiLoggingEnabled: true // Only logged to client browser console, so minimal privacy issues
            }
        )
    };
}

const app = new Msal.UserAgentApplication(appConfig);

window.addEventListener('focus', function () {
    // Window/tab got focus, perhaps after an extended interval, so check we still have a token to force
    // re-login before we start working.
    // If we don't do this then the user may interact with the app for a few seconds before
    // getting kicked out to the sign-in page, which is not a very nice experience.
    logoutIfTokenExpired(app);
});

// Generate user if they are already logged in
const account = app.getAccount();

if (account) {
    /* SAAS-177 use the code below when acquireTokenSilent issue is fixed

    const tokenRequest = buildParameters({
        // Add here scopes for access token to be used at the API endpoints.
        scopes: ['https://SQUPB2CTESTAW.onmicrosoft.com/api/demo.read']
    });

    const getToken = async () => {
        return app.acquireTokenSilent(tokenRequest).then(
            response => response.accessToken
        ).catch(() => {
            // acquireTokenRedirect also doesn't work at the moment so use popup flow
            return app.acquireTokenPopup(tokenRequest).then(
                popupResponse => popupResponse.accessToken
            );
        });
    };
    */

    // With MSAL the token may have expired but the cached account is still valid. So best to do an explicit
    // check for token expiry before continuing with a cached account that isn't going to be able to
    // make API calls to the server.
    tokenExpired = logoutIfTokenExpired(app);

    if (!tokenExpired) {
        // Kick off async function to fetch subdomain info and cache it, in case we need it later (e.g. for sign-out).
        getApplicationSubdomain(appLoadUrl);
    }

    const getToken = () => {
        // Temporary workaround (see above)
        return window.localStorage.getItem('msal.idtoken');
    };

    const tenants =
        account.idTokenClaims.tenants && account.idTokenClaims.tenants.length > 0
            ? account.idTokenClaims.tenants
            : null;

    const preferredTenant = window.localStorage.getItem('preferredTenant');

    let tenant;
    if (tenants?.length === 1) {
        tenant = tenants[0];
    } else if (tenants?.includes(preferredTenant)) {
        tenant = preferredTenant;
    }

    const isLocal = account.idTokenClaims.authenticationSource === 'localAccountAuthentication';

    const passwordReset = Boolean(account.idTokenClaims.passwordReset);

    if (account.idTokenClaims.email && tenants?.length >= 1) {
        // Successfully got access to at least one tenant. So reset back to standard sign in prompt,
        // i.e. no need to trigger IdP 'select account' as user is unlikely to be switching accounts on next sign-in.
        clearSignInPrompt();
    }

    user = tokenExpired ? null : {
        // Our app expects this name property to be the email, for historical reasons.
        // Do NOT use account.name as that is often but not always the email.
        name: account.idTokenClaims.email,
        email: account.idTokenClaims.email,
        id: account.idTokenClaims.userId,
        accountIdentifier: account.accountIdentifier,
        openAccessId: '',
        openAccessTargetId: '',
        openAccessWorkspaceId: '',
        tenant,
        tenants,
        passwordReset,
        isLocal,
        getToken
    };
} else {
    user = null;
}

// Note: This callback may be executed immediately so ensure everything is 
// initialised (e.g. user object above) before registering it.
app.handleRedirectCallback(authRedirectCallBack);

async function buildParameters(options) {
    const queryParams = new URLSearchParams(window.location.search);

    if (Config.Environment === 'Development') {
        // Tell our redirect forwarder in S3 (redirect.html) where to come back to.
        options.state = `redirectURI=${window.location.origin}/`;
    }

    options.extraQueryParameters = {};

    // Tell B2C which identify provider to use for sign-in, if available. 
    // (Without a domain hint the standard sign-in page will be displayed and the user will 
    // need to choose from MS, Google etc)
    const requiredIdentityProvider = await getRequiredIdentityProvider(appLoadUrl);
    if (requiredIdentityProvider) {
        options.extraQueryParameters['domain_hint'] = requiredIdentityProvider;
    }

    // Give a hint to the IdP (MS, Google etc) to prompt for the user account, if required.
    const prompt = getSignInPrompt();
    if (prompt) {
        options.extraQueryParameters.promptOverride = prompt;
    }

    // Tell the sign-in page whether it's a standard login or for Open Access,
    // so the sign-in page can be customised accordingly.
    options.extraQueryParameters.mode = isOpenAccess() ? 'openaccess' : 'standard';

    if (!requiredIdentityProvider && queryParams.get(B2COption.signUp)?.toLowerCase() === 'true') {
        // Tell the B2C policy to go straight to sign-up (bypassing sign-in).
        options.extraQueryParameters.option = B2COption.signUp;
    } else if (isOpenAccess()) {
        // Our B2C policy passes through this value to our /claims endpoint.
        // So we use it to tell the /claims endpoint that this is an Open Access sign-in,
        // so it shouldn't automatically create a new tenant if the user is unknown.
        options.extraQueryParameters.option = B2COption.oaAuthSignIn;
    } else {
        options.extraQueryParameters.option = B2COption.none; // Ignored by our B2C policy, but useful for diagnostics etc.
    }

    // Tell the sign-up/sign-in pages how to get back to initial login page if they need to.
    options.extraQueryParameters.loginLink = window.location.origin;

    // add the Segment anonymousId to the b2clogin address to enable
    // consistent user tracking through the external login pages
    const anonymousId = queryParams.get('anonymousId') || window.analytics?.user?.().anonymousId?.();
    if (anonymousId) {
        options.extraQueryParameters.anonymousId = anonymousId;
    }

    const userId = user?.name;
    if (userId) {
        options.extraQueryParameters.userId = userId;
    }

    const preferredTenant = window.localStorage.getItem('preferredTenant');
    if (preferredTenant) {
        // eslint-disable-next-line camelcase
        options.extraQueryParameters.preferred_tenant = preferredTenant;
    }

    return options;
}

async function resetPassword() {
    app.authority = buildAuthority(msalConfig.b2cPolicies.passwordReset);
    app.loginRedirect(await buildParameters({}));
}

function authRedirectCallBack(error, response) {
    try {
        if (error) {
            // Handle forgot password flow
            if (error.errorMessage.includes('AADB2C90118')) {
                resetPassword();
            }
        } else {
            // We need to reject id tokens that were not issued with the default sign-in or password reset policies.
            // To learn more about b2c tokens, visit
            // https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
            if (
                response.tokenType === 'id_token' &&
                response.idToken.claims['acr'] !== msalConfig.b2cPolicies.signUp.toLowerCase() &&
                response.idToken.claims['acr'] !== msalConfig.b2cPolicies.passwordReset.toLowerCase()
            ) {
                app.logout();
            }
        }
    } finally {
        // Normally this isn't necessary because loginInProgress will have been reset by an app reload
        // after redirect, but just in case there's a scenario where we go straight from loginRedirect to
        // here without redirect (due to error perhaps) we'll reset the flag.
        loginInProgress = false;
    }
}

/**
 * For a full list of available authentication parameters visit:
 * https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
const login = async () => {
    // Multiple concurrent logins can cause issues with MSAL, so try to avoid.
    // Also don't try to log in if we've got an expired token as we'll be trying to log out!
    if (loginInProgress || tokenExpired) {
        return;
    }
    loginInProgress = true;

    const options = {
        scopes: ['openid', 'profile']
    };

    const authParameters = await buildParameters(options);

    app.loginRedirect(authParameters);
};

const logout = ({
    /**
     * If true, go to sign-in after sign-out even if we've got a domain_hint and will sign straight back in again.
     * 
     * Set to true when signing back in immediately is desirable, e.g. on session expiry.
     * Set to false for a 'normal' sign-out, where the user does not expect to be taken back into the app.
     */
    forceSignInAfterSignOut = false,
    /**
     * If true, go to sign UP after logout, rather than sign IN.
     * 
     * This is used, for example, when the user has signed in with the wrong account and they have indicated
     * they want to go to sign up to create a new tenant.
     */
    signUpAfterSignOut = false
} = {}) => {
    if (tokenExpired) {
        return; // We will already be in the process of logging out
    }
    if (isOpenAccess()) {
        endOpenAccessSession();
    }
    setForceSignInAfterSignOut(forceSignInAfterSignOut ?? false);
    setSignUpAfterSignOut(signUpAfterSignOut ?? false);
    app.logout();
};

const selectTenant = () => {
    window.location.href = '/organization';
};

const isSqupAdmin = () => user.tenants.includes('SquaredUp Cloud Administrators');

const getAppLoadUrl = () => appLoadUrl;

const analyticsPageWithTenant = () => {
    // We have already called page() when analytics was first initialised but we didn't have the tenantId then,
    // so we'll redo with the tenant ID now if we have it.
    //
    // This is particularly useful when we haven't signed in yet but the tenant ID has been passed as a 
    // query parameter, e.g. navigation from a notification link.
    //
    if (hasProperty(window, 'analytics') && typeof window.analytics === 'object' && window.analytics != null) {
        const queryParams = new URLSearchParams(window.location.search);
        const tenantId = user?.tenant || queryParams.get('tid');

        if (tenantId) {
            window.analytics.page({}, {
                context: {
                    groupId: tenantId,
                    environment
                }
            });
        }
    }
};

analyticsPageWithTenant();

const auth = {
    login,
    logout,
    selectTenant,
    resetPassword,
    user,
    isSqupAdmin,
    getAppLoadUrl
};

export default auth;
