import Text from '@/components/Text';
import { LinkWrapper } from 'components/LinkWrapper';
import ReactMarkdown from 'react-markdown';
import { Components } from 'react-markdown/src/ast-to-react';

/**
 * Get the indentation for a list element based on a given depth
 * @param depth List depth
 * @returns Styles
 */
const getIndentation = (depth: number) => ({ marginLeft: depth * 20, ...(depth > 0 && { marginTop: 5 }) });

/**
 * Generates styled React elements from Markdown, using base styles that match the app
 * Individual components can be overriden using the `components` property
 */
export const MarkdownBase = ({ content, components }: { content: string; components?: Components }) => (
    <ReactMarkdown
        className='space-y-4'
        components={{
            a: ({ href, children }) => (
                <LinkWrapper link={href as string} className='text-textLink text-[100%]'>
                    {children}
                </LinkWrapper>
            ),
            h1: Text.H1,
            h2: Text.H2,
            h3: Text.H3,
            h4: Text.H4,
            h5: Text.H5,
            p: Text.SmallBody,
            pre: ({ children }) => (
                <pre className='p-3 text-sm border rounded-md bg-modalOutline border-outlinePrimary'>{children}</pre>
            ),
            ol: ({ children, depth }) => (
                <ol
                    className='space-y-1 list-decimal list-inside marker:text-textSecondary marker:mr-2 text-[100%]'
                    style={getIndentation(depth)}
                >
                    {children}
                </ol>
            ),
            ul: ({ children, depth }) => (
                <ul className='space-y-1 list-disc list-inside text-[100%]' style={getIndentation(depth)}>
                    {children}
                </ul>
            ),
            hr: () => <hr className='border-dividerPrimary' />,
            ...components
        }}
    >
        {content}
    </ReactMarkdown>
);
