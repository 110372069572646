
export const getScopeQueryDetailValue = (queryDetail: any, property: 'plugins' | 'types' | 'query' | 'booleanQuery' | 'properties') => {
    const queryDetailValue = queryDetail?.[property];
    
    if (!queryDetailValue) {
        return;
    }
    
    if (typeof queryDetailValue === 'string') {
        return queryDetailValue;
    }

    if (Array.isArray(queryDetailValue)) {
        return queryDetailValue.map(({ value }: any) => value) || [];
    }

    return queryDetailValue;
};
