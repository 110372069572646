import { Checkbox } from '@/components/forms/Checkbox';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/tooltip/Tooltip';

interface UnshapedDataOptionProps {
    isShowingUnshapedData: boolean,
    setIsShowingUnshapedData: React.Dispatch<React.SetStateAction<boolean>>,
}

export const UnshapedDataOption: React.FC<UnshapedDataOptionProps> = ({
    isShowingUnshapedData,
    setIsShowingUnshapedData
}) => {
    return (
        <div className='inline-flex items-center flex-shrink-0 space-x-2 select-none'>
            <div 
                className='inline-flex items-center space-x-2 text-sm cursor-pointer'
                onClick={() => setIsShowingUnshapedData(current => !current)}
            >
                <Checkbox checked={isShowingUnshapedData} />
                <span>Show unshaped data</span>
            </div>
            <Tooltip title='Show the data before the Shaping step is applied'>
                <FontAwesomeIcon icon={faQuestionCircle} />
            </Tooltip>
        </div>
    );
};

