import { Skeleton } from '@/components/Skeleton';
import Template from '@/components/Template';
import { Serialised } from '@squaredup/ids';
import { useAgentGroups } from 'components/hooks/useAgentGroups';
import { useLinkedPluginConfigs } from 'components/hooks/useLinkedPluginConfigs';
import { usePluginPermissions } from 'components/hooks/usePluginPermissions';
import { AppContext } from 'contexts/AppContext';
import { differenceInMinutes } from 'date-fns';
import type { PluginDashboard, PluginFolder } from 'dynamo-wrapper';
import { usePageTitle } from 'lib/usePageTitle';
import { NotFoundPanel } from 'pages/components/Panels/NotFoundPanel';
import PluginIcon from 'pages/scope/PluginIcon';
import { usePlugin } from 'pages/settings/plugins/components/usePlugin';
import { FC, useContext, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useWorkspaceCanWrite } from 'services/AccessControlService';
import { PluginSourceConfig } from 'services/SourceConfigService';
import { ImportInfo } from './components/ImportInfo';
import { InstallSampleDashboardsModal } from './components/InstallSampleDashboardsModal';
import { LinkingButton } from './components/LinkingButton';
import { ManageDatasourceButton } from './components/ManageDatasourceButton';
import { OverviewData } from './components/OverviewData';
import { useDataSourceConfig } from './components/useDataSourceConfig';
import { useImportTrigger } from './components/useImportTrigger';

export const SkeletonButtons = () => {
    return (
        <div className='flex gap-x-4'>
            <InstallSampleDashboardsButtonSkeleton />
            {[...Array(2)].map((_, index) => (
                <ButtonSkeleton key={index} />
            ))}
        </div>
    );
};

export const InstallSampleDashboardsButtonSkeleton = () => {
    return <Skeleton className='h-9 w-48' />;
};

export const ButtonSkeleton = () => {
    return <Skeleton className='h-9 w-28' />;
};

interface ActionButtonsProps {
    configId: string;
    workspaceId: string;
    pluginDetails: Serialised<PluginConfig>;
    data: PluginSourceConfig;
    canEditPlugin: boolean;
    canReadPlugin: boolean;
    pluginDefaultDashboards: (PluginDashboard | PluginFolder)[];
    canWrite?: boolean;
    pluginId: string;
}

export const ActionButtons: FC<ActionButtonsProps> = ({
    configId,
    workspaceId,
    pluginDetails,
    data,
    canEditPlugin,
    canReadPlugin,
    pluginId,
    canWrite
}) => {
    const { agentGroupOptions, isLoadingAgentGroups } = useAgentGroups();
    const { data: canWriteToWorkspace, isLoading: isPermissionsLoading } = useWorkspaceCanWrite(workspaceId);
    const { data: dataSourceConfigData, isLoading: isConfigLoading } = useDataSourceConfig(configId);
    const { data: pluginData, isLoading: isPluginDataLoading } = usePlugin(pluginId);

    if (!canWrite) {
        return null;
    }

    const isInitialLoading = isLoadingAgentGroups || isPermissionsLoading || isConfigLoading || isPluginDataLoading;

    if (isInitialLoading) {
        return <SkeletonButtons />;
    }

    const displayName = data.displayName;

    return (
        <div className='flex gap-4'>
            {canReadPlugin && displayName && (
                <InstallSampleDashboardsModal pluginData={pluginData} configID={configId} displayName={displayName} />
            )}
            {canEditPlugin && (
                <ManageDatasourceButton
                    data={dataSourceConfigData}
                    canWriteToWorkspace={canWriteToWorkspace}
                    agentGroupOptions={agentGroupOptions}
                    workspaceID={workspaceId}
                />
            )}
            <LinkingButton
                datasourceId={configId}
                datasourceName={displayName ?? ''}
                workspaceId={workspaceId}
                pluginId={pluginDetails.pluginId}
            />
        </div>
    );
};

interface OverviewDashboardProps {
    configId: string;
    workspaceId: string;
}

export interface PluginConfig {
    pluginId: string;
    name: string;
}

export const PluginConfigSkeletonLoader = () => {
    return (
        <Template
            title={<Skeleton className='h-8 w-64' />}
            flex
            equalPadding
            icon={<Skeleton className='w-24 h-24 rounded-lg' />}
            status={
                <div className='flex items-center gap-2'>
                    <Skeleton className='h-6 w-32' />
                </div>
            }
            actions={
                <div className='flex gap-4'>
                    <SkeletonButtons />
                </div>
            }
        ></Template>
    );
};

export const PluginConfigOverviewDashboard: FC<OverviewDashboardProps> = ({ configId, workspaceId }) => {
    const { data: canWrite, isLoading: isLoadingPermissions } = useWorkspaceCanWrite(workspaceId);
    const { canEdit, isLoading: isLoadingPluginPermissions, canRead } = usePluginPermissions();

    const {
        data: linkedPlugins = [],
        isLoading,
        isFetching
    } = useLinkedPluginConfigs(workspaceId, {
        refetchInterval: canEdit(configId) ? 5_000 : false // Needs common refetch for updated import status
    });

    const { triggerImport } = useImportTrigger(configId, workspaceId);

    const data = linkedPlugins.find((v) => v.id === configId);

    usePageTitle(data?.displayName);
    const waitingForDataSourceToBeLinkedToWorkspace = data === undefined && isFetching;

    const pluginDetails = data?.plugin as Serialised<PluginConfig> | undefined;

    const pluginData = usePlugin(pluginDetails?.pluginId).data;

    const pluginDefaultDashboards = pluginData?.defaultContent?.dashboards ?? [];

    const canEditPlugin = canEdit(configId);

    const canReadPlugin = canRead(configId);

    const isInitialLoading =
        isLoading ||
        waitingForDataSourceToBeLinkedToWorkspace ||
        !pluginDetails ||
        isLoadingPermissions ||
        isLoadingPluginPermissions ||
        canEditPlugin === undefined ||
        canReadPlugin === undefined;
    if (isInitialLoading) {
        return <PluginConfigSkeletonLoader />;
    }

    if (data === undefined) {
        return (
            <NotFoundPanel
                title='Data source not accessible'
                body='The data source does not exist or you do not have permission to access it.'
            />
        );
    }

    const isRefreshing =
        ['waitingForData', 'started', 'running'].includes(data?.importStatus?.status ?? 'notRun') &&
        differenceInMinutes(Date.now(), data?.importStatus?.started ?? 0) < 20;

    return (
        <Template
            title={data?.displayName}
            flex
            equalPadding
            icon={<PluginIcon pluginName={pluginDetails?.name || ''} className='w-24 h-24' />}
            status={
                <>
                    {data?.importStatus && (
                        <ImportInfo
                            pluginId={pluginDetails?.pluginId}
                            isRefreshing={isRefreshing}
                            importStatus={data?.importStatus}
                            refreshImport={triggerImport}
                            canRefresh={canEdit(configId)}
                            pluginName={pluginDetails?.name}
                        />
                    )}
                </>
            }
            actions={
                <ActionButtons
                    pluginId={pluginDetails?.pluginId}
                    canWrite={canWrite}
                    configId={configId}
                    workspaceId={workspaceId}
                    pluginDetails={pluginDetails}
                    data={data}
                    canEditPlugin={canEditPlugin}
                    canReadPlugin={canReadPlugin}
                    pluginDefaultDashboards={pluginDefaultDashboards}
                />
            }
        >
            <OverviewData
                configId={configId}
                pluginId={pluginDetails?.pluginId ?? ''}
                importStatus={data?.importStatus?.status ?? ''}
            />
        </Template>
    );
};

export const DatasourceOverview: FC = () => {
    const { id } = useParams();
    const [params] = useSearchParams();
    const { currentWorkspaceID, setCurrentWorkspaceID } = useContext(AppContext);

    useEffect(() => {
        const spaceId = params.get('space');
        if (spaceId) {
            setCurrentWorkspaceID({ id: spaceId });
        }
    }, [params, setCurrentWorkspaceID]);
    if (!id) {
        return <div className='text-textIncomplete'>No id provided</div>;
    }

    if (!currentWorkspaceID) {
        setCurrentWorkspaceID({ pickBest: true });

        return <div className='text-textIncomplete'>One workspace must exist</div>;
    }

    return <PluginConfigOverviewDashboard configId={id} workspaceId={currentWorkspaceID} />;
};
