import AsyncCreatableSelect from 'react-select/async-creatable';

export interface RemoteCreatableSelectProps extends React.ComponentProps<AsyncCreatableSelect> {
    immediateFetch?: boolean;
    emptyMessage?: string;
}

export default function RemoteCreatableSelect({
    immediateFetch = true,
    emptyMessage,
    ...props
}: RemoteCreatableSelectProps) {
    const defaultOptions = [];

    if (emptyMessage) {
        defaultOptions.push({
            label: emptyMessage,
            value: emptyMessage,
            isDisabled: true
        });
    }

    return (
        <AsyncCreatableSelect
            cacheOptions
            defaultOptions={immediateFetch ? true : defaultOptions}
            isClearable={true}
            {...props}
            noOptionsMessage={() => emptyMessage}
        />
    );
}
