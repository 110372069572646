import { cn } from '@/lib/cn';
import { Controller, RegisterOptions } from 'react-hook-form';

type NumberProps = JSX.IntrinsicElements['input'] & {
    name: string;
    validation?: RegisterOptions;
};

export default function Number({ name, className, validation, ...props }: NumberProps) {
    return (
        <div className='w-full'>
            <Controller
                name={name}
                rules={validation}
                render={({ field: {onChange, value, ref} }) => (
                    <input
                        ref={ref}
                        type='number'
                        className={cn('w-full py-input leading-input px-md text-textPrimary autofill:text-textPrimary bg-transparent border-0 placeholder-textIncomplete disabled:text-textDisabled disabled:pointer-events-none focus:ring-0', className)}
                        value={value}
                        onChange={onChange}
                        {...props}
                    />
                )}
            />
        </div>
    );
}
