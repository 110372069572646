import Config from '../../config';

const clientId = Config.Auth.ClientId;
const tenantId = Config.Auth.TenantId;
const b2cTenant = Config.Auth.Tenant;
const redirectUri =
    Config.Environment === 'Development'
        ? 'https://s3.amazonaws.com/account-common-auth.dev.app.squaredup.com/redirect.html'
        : window.location.origin;

const b2cPolicies = {
    signUp: 'B2C_1A_SignUpSignInAAD',
    passwordReset: 'B2C_1A_PasswordReset'
};

export function buildAuthority(policy) {
    return `https://${b2cTenant}.squaredup.com/${tenantId}/${policy}`;
}

export const config = {
    b2cPolicies,
    clientId,
    redirectUri
};
