import Dashboard from 'pages/dashboard/Dashboard';
import { DatasourceOverview } from 'pages/datasources/Datasource';
import DatasourcesPage from 'pages/datasources/DatasourcesPage';
import Drilldown from 'pages/drilldowns/Drilldown';
import Explore from 'pages/explore/Explore';
import Explorer from 'pages/explorer/Explorer';
import Monitoring from 'pages/monitoring/Monitoring';
import NotFound from 'pages/not-found/NotFound';
import Scope from 'pages/scope/Scope';
import ScopesPage from 'pages/scope/ScopesPage';
import Settings from 'pages/settings/Settings';
import StatusPage from 'pages/status/StatusPage';
import { UsagePage } from 'pages/usage/UsagePage';
import { RedirectToWorkspaceDefaultPage } from 'pages/workspace/RedirectToWorkspaceDefaultPage';
import { WorkspaceHome } from 'pages/workspaceHome/WorkspaceHome';
import { useWorkspaces } from 'queries/hooks/useWorkspaces';
import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import IndexRedirect from './IndexRedirect';

export const lastNonSettingsPageKey = 'lastNonSettingsPage';

export default function AppRoutes({
    render
}: {
    render: (
        element: ReactNode,
        classNames?: { innerContainerClassName?: string; outerContainerClassName?: string }
    ) => ReactNode;
}) {
    const location = useLocation();

    useEffect(() => {
        // We want to keep track of the last non-settings page the user visited
        // so that we can redirect them back to it when they click the "Back to app" button
        if (!location.pathname.startsWith('/settings/')) {
            sessionStorage.setItem(lastNonSettingsPageKey, `${location.pathname}${location.search}`);
        }
    }, [location]);

    return (
        <Routes>
            <Route path='/' element={render(<IndexRedirect />)} />
            <Route
                path='status'
                element={render(
                    <RedirectIfSingleWorkspace>
                        <StatusPage />
                    </RedirectIfSingleWorkspace>
                )}
            />
            <Route path='workspace/:id' element={render(<RedirectToWorkspaceDefaultPage />)} />
            <Route path='workspace/:id/overview' element={render(<WorkspaceHome />)} />
            <Route path='drilldown/*' element={render(<Drilldown />)} />
            <Route path='dashboard/:id' element={render(<Dashboard />)} />
            <Route path='monitoring/:id' element={render(<Monitoring />)} />
            <Route path='explorer/:id' element={render(<Explorer />)} />
            <Route path='explore' element={render(<Explore />)} />
            <Route path='datasources/:id' element={render(<DatasourcesPage />)} />
            <Route path='scope/:id' element={render(<Scope />)} />
            <Route path='scopes/:id' element={render(<ScopesPage />)} />
            <Route path='datasource/:id' element={render(<DatasourceOverview />)} />
            <Route path='settings/*' element={render(<Settings />)} />
            <Route path='usage' element={render(<UsagePage />)} />
            <Route path='*' element={render(<NotFound />)} />
        </Routes>
    );
}

const RedirectIfSingleWorkspace = (props: PropsWithChildren<object>) => {
    const { data: workspaces } = useWorkspaces();
    const [searchParams] = useSearchParams();

    const singleWorkspace = workspaces?.length === 1;
    const firstWorkspace = workspaces?.at(0);
    const skipRedirect = Boolean(searchParams.get('skip-single-workspace-redirect'));

    if (singleWorkspace && firstWorkspace && !skipRedirect) {
        return <Navigate to={`/workspace/${firstWorkspace.id}`} replace={true} />;
    }

    return <>{props.children}</>;
};
