import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { construct } from 'dashboard-engine/schemas/schema';
import { useEffect, useState } from 'react';
import { CodeEditor, CodeEditorArgs } from 'ui/editor/components/CodeEditor';

type TileCodeEditorProps = Omit<CodeEditorArgs, 'content'> & {
    tileConfig: DataStreamBaseTileConfig;
    update: (config: DataStreamBaseTileConfig) => void;
};

export const TileCodeEditor: React.FC<TileCodeEditorProps> = ({ tileConfig, update, ...rest }) => {
    const [schema, setSchema] = useState({});

    useEffect(() => {
        const load = async () => {
            const result = await construct();
            setSchema(result);
        };
        load();
    }, []);

    return <CodeEditor content={tileConfig} onValidUpdatedContent={update} schema={schema} {...rest} />;
};
