import { getSharingEnvironment } from '@squaredup/open-access';
import { getRefreshTokenFromLocalStorage, getRequestedOpenAccessId, oaLog } from 'lib/openAccessUtil';
import { isEqual } from 'lodash';
import { OpenAccessLoadingSpinner } from 'pages/openAccess/OpenAccessLoadingSpinner';
import { getWorkspaceAuthenticatedDomains } from 'queries/hooks/useWorkspaceAuthenticatedDomains';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { GetShareRestrictions, ShareRestrictions } from 'services/OpenAccessLoginService';
import { TENANT_DATA, Tenant } from 'services/TenantService';
import { List } from 'services/WorkspaceService';
import { OpenAccessLoadError } from 'ui/errorHandling/OpenAccessLoadError';
import Auth from '.';
import { beginOrExtendOpenAccessSession, configureAuthFromQueryString } from './OpenAccessAuth';

export function RequireOpenAccessAuth({ children }: { children: any }) {
    const [error, setError] = useState();
    const [isOpenAccessTokenAcquired, setOpenAccessTokenAcquired] = useState(false);
    const [isAuthenticationRequired, setAuthenticationRequired] = useState<boolean | undefined>(undefined);
    const [isUserLoginInitiated, setUserLoginInitiated] = useState(false);
    const [isUserLoginDone, setUserLoginDone] = useState(false);
    const [restrictions, setRestrictions] = useState<ShareRestrictions | undefined>();

    // Disable attempting to load tenant data until after auth is completed

    const { data: tenant, isLoading: isTenantLoading } = useQuery(TENANT_DATA, Tenant, {
        enabled: isOpenAccessTokenAcquired
    });

    const { data: workspaces, isLoading: isWorkspaceLoading } = useQuery(workspaceQueryKeys.list, List, {
        enabled: isOpenAccessTokenAcquired
    });

    const isLoading = isTenantLoading || isWorkspaceLoading;

    // Always will return one workspace which is the one we want to access
    const workspace = workspaces?.at(0);

    const [authenticatedDomains, setAuthenticatedDomains] = useState<string[]>([]);

    // Check if the share requires authentication or not, as that affects what preliminaries we need to do
    // (e.g. do we need to do a user login)
    useEffect(() => {
        async function checkAuthenticationRequired() {
            try {
                const shareRestrictions = await GetShareRestrictions(getRequestedOpenAccessId());
                setRestrictions(shareRestrictions);

                const configuredAuthFromQueryString = configureAuthFromQueryString(setAuthInfo);
                if (configuredAuthFromQueryString) {
                    // Auth token was supplied to us via the query string, so no need to acquire it. We're done!
                    setAuthenticationRequired(false);
                    setOpenAccessTokenAcquired(true);
                    (window as any).analytics?.track?.('Transient Open Access Signed In');
                    return;
                }

                const authenticationRequired = shareRestrictions?.restrictedToUsers === true;
                oaLog(`authenticationRequired = ${authenticationRequired}`);
                setAuthenticationRequired(authenticationRequired);
            } catch (err: any) {
                setError(err);
            }
        }
        checkAuthenticationRequired();
    }, []);

    // Acquire an access token once we've done all of the required preliminaries.
    useEffect(() => {
        async function acquireOpenAccessToken() {
            oaLog(`Acquiring open access token (isAuthenticationRequired = ${isAuthenticationRequired ?? false})`);
            await beginOrExtendOpenAccessSession(setAuthInfo, restrictions);
        }

        if (
            restrictions &&
            isOpenAccessTokenAcquired === false &&
            (isAuthenticationRequired === false || isUserLoginDone === true)
        ) {
            acquireOpenAccessToken()
                .then(() => {
                    oaLog('Open access token acquired');
                    setOpenAccessTokenAcquired(true);
                    (window as any).analytics?.track?.('Open Access Signed In');
                })
                .catch((err) => {
                    setError(err);
                });
        }
    }, [isOpenAccessTokenAcquired, isUserLoginDone, isAuthenticationRequired, restrictions]);

    // Respond to any tenant setting changes that might render our share access invalid, so we need to log out.
    useEffect(() => {
        if (!isLoading && tenant) {
            if (restrictions?.hidden) {
                oaLog('Hidden/system share, so ignoring tenant sharing settings');
                return;
            }

            const {
                tenantSharingSettings: {
                    publicSharesEnabled,
                    authenticatedSharesEnabled,
                    authenticatedDomains: newDomains
                }
            } = getSharingEnvironment(tenant);

            const sharingEnabled = isAuthenticationRequired ? authenticatedSharesEnabled : publicSharesEnabled;

            const workspaceDomains = isUserLoginDone ? getWorkspaceAuthenticatedDomains(workspace, tenant) : [];

            const allDomains = [...new Set([...newDomains, ...workspaceDomains])];
            // logout if OA is no longer enabled
            if (!sharingEnabled) {
                oaLog('OA not enabled for tenant, so logging out');
                Auth.logout(); // will do an app reload
            }

            // Logout if the share is restricted, and authenticated domains changes
            if (isAuthenticationRequired && authenticatedDomains.some((domain) => !allDomains.includes(domain))) {
                oaLog('Allowed user domains for authenticated OA access have changed, so logging out');
                Auth.logout(); // will do an app reload
            }

            // Set authenticated domains for next diff
            if (!isEqual(allDomains, authenticatedDomains)) {
                setAuthenticatedDomains(allDomains);
            }

            oaLog('Allowed domains for authenticated OA shares', allDomains);
        }
    }, [tenant, isLoading, isAuthenticationRequired, authenticatedDomains, restrictions, isUserLoginDone, workspace]);

    // Can't do anything until checkAuthenticationRequired() has completed.
    if (isAuthenticationRequired === undefined && !error) {
        oaLog('Waiting to determine if authentication is required');
        return null;
    }

    // If the Open Access share requires authentication, ensure the user is logged in before we continue.
    if (isAuthenticationRequired === true && isUserLoginDone === false && isOpenAccessTokenAcquired === false) {
        const standardUserLoginActive = Auth.user && !Auth.user.openAccessId;

        if (standardUserLoginActive) {
            oaLog('User is logged in', Auth.user);
            setUserLoginDone(true);
            return null;
        } else {
            // If we have a valid refresh token, we should be able to use that to refresh our session,
            // rather than requiring a new login.
            oaLog('Authentication required, but no user logged in. Try to refresh existing OA session');
            const refreshContext = getRefreshTokenFromLocalStorage(getRequestedOpenAccessId());
            if (refreshContext) {
                // Looks like we have a valid refresh token, which is equivalent to a login for our purposes.
                oaLog("Got a valid refresh token, so don't need to log in");
                setUserLoginDone(true);
                return null;
            }

            // Can't refresh existing session, so will need to start new one, which requires the user to sign in.
            // Note this will trigger an app reload.
            if (isUserLoginInitiated === true) {
                oaLog('Interactive login required but already triggered, so doing nothing.');
                return null;
            }
            oaLog('No valid refresh token, so triggering interactive user login');
            setUserLoginInitiated(true);
            Auth.login();
            return null;
        }
    }

    if (isOpenAccessTokenAcquired) {
        oaLog('Got access token, so displaying dashboard.');
        return children;
    }

    if (error) {
        return <OpenAccessLoadError err={error} />;
    }

    return <OpenAccessLoadingSpinner />;
}

function setAuthInfo(authInfo: any) {
    Auth.user = authInfo;
}
