import { Button } from '@/components/Button';
import DropdownMenu from '@/components/DropdownMenu';
import { cn } from '@/lib/cn';
import { faCircleNotch, faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useTileContext } from 'contexts/TileContext';
import { Clone } from './Clone';
import { CopyData } from './CopyData';
import { CopyTo } from './CopyTo';
import { DeleteTile } from './DeleteTile';
import { Edit } from './Edit';
import { Explore } from './Explore';
import { ExportImage } from './ExportImage';
import { ForceRefresh } from './ForceRefresh';
import { Fullscreen } from './Fullscreen';

export interface TileDropdownMenuActionsProps {
    isEditor: boolean;
    isMobile: boolean;
    isOA: boolean;
    isForceRefreshEnabled: boolean;
}

export const TileDropdownMenuActions: React.FC<TileDropdownMenuActionsProps> = ({
    isEditor,
    isMobile,
    isOA,
    isForceRefreshEnabled
}) => {
    const { editing: isEditing } = useDashboardContext();
    const { isExporting } = useTileContext();
    const dropdownMenuActions = getDropdownMenuActions(
        isEditor,
        isEditing,
        isMobile,
        isOA,
        isForceRefreshEnabled
    );    

    return (
        <DropdownMenu>
            <DropdownMenu.Trigger
                className={cn(!isEditing && 'hidden group-hover/tile:inline-flex data-[state=open]:!inline-flex')}
                disabled={isExporting}
            >
                <Button
                    variant='tertiary'
                    icon={<FontAwesomeIcon
                        icon={isExporting ? faCircleNotch : faEllipsisV}
                        fixedWidth
                        {...(isExporting && { spin: true })} />} />
            </DropdownMenu.Trigger>

            <DropdownMenu.Menu menuId='tileMenu' className='divide-y divide-outlinePrimary'>
                {dropdownMenuActions.map((option, index) => (
                    <DropdownMenu.Group key={index}>
                        {option.map((NestedOption, optionIndex) => (
                            <NestedOption key={`${index}-${optionIndex}`} />
                        ))}
                    </DropdownMenu.Group>
                ))}
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
};

/**
 * Build a menu based on the provided flags
 * @param isEditor If the user has editor permissions
 * @param isMobile If the mobile layout is active
 * @param isOA If the session is currently in Open Access
 * @returns Array of menu components
 */
export const getDropdownMenuActions = (
    isEditor: boolean,
    isEditing: boolean,
    isMobile: boolean,
    isOA: boolean,
    isForceRefreshEnabled: boolean
) => {
    const forceRefresh = insertIf(ForceRefresh, isForceRefreshEnabled && !isOA);

    // Mobile / Open Access
    if (isMobile || isOA) {
        return [
            [
                Fullscreen,
                ExportImage,
                CopyData,
                ...forceRefresh
            ]
        ];
    }

    // Editors
    if (isEditor) {
        return [
            [
                Edit,
                ...insertIf(Clone, isEditing)
            ],
            [
                Fullscreen,
                Explore,
                CopyTo
            ],
            [
                ExportImage,
                CopyData,
                ...forceRefresh
            ],
            insertIf(DeleteTile, isEditing)
        ].filter(a => a.length > 0);
    }

    // All other users
    return [
        [
            Fullscreen,
            Explore,
            CopyTo
        ],
        [
            ExportImage,
            CopyData,
            ...forceRefresh
        ]
    ];
};

/**
 * Helper for optionally adding an element to an array based on a condition
 * @param element Element to add
 * @param condition Boolean condition
 * @returns 
 */
const insertIf = <T,>(element: T, condition: boolean) => condition ? [element] : [];
