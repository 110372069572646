import { fiveMinutes } from 'queries/constants';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { List } from 'services/DashboardService';

type QueryReturnType = Awaited<ReturnType<typeof List>>;

/**
 * useQuery returning all dashboards
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of all dashboards in a tenant
 */
export const useDashboards = <T = QueryReturnType>(options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>) =>
    useQuery<QueryReturnType, unknown, T, string[]>(dashboardQueryKeys.list, List, {
        staleTime: fiveMinutes,
        cacheTime: fiveMinutes,
        keepPreviousData: true,
        ...options
    });
