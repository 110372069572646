import stringify from 'fast-json-stable-stringify';
import { graphQueryKeys } from 'queries/queryKeys/graphQueryKeys';
import { useQuery, UseQueryOptions } from 'react-query';
import { fetchMapData } from './fetchMapData';
import { NodesAndEdges } from './types';

type QueryReturnType = NodesAndEdges | undefined;
type QuerySelectReturnType = QueryReturnType;

export const useObjectMapData = (
    objectNodeIds?: string[], 
    queryOptions?: UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>
) => {
    return useQuery(
        graphQueryKeys.graph(stringify((objectNodeIds ?? []).sort())),
        async () => fetchMapData(objectNodeIds),
        {
            ...queryOptions,
            enabled: Boolean(objectNodeIds?.length)
        }
    );
};
