import { FormattedStreamData } from '@squaredup/data-streams';
import { useMemo } from 'react';
import { DataStreamBarChartConfig } from './Config';
import { getAxes, getBarChartColumns, transformToBarChart } from './dataUtils';

interface UseColumnsProps {
    data: FormattedStreamData;
    config: DataStreamBarChartConfig;
    displayMode: 'actual' | 'percentage';
    isHorizontal: boolean;
    hasSorting: boolean;
}

export const useColumns = ({ data, config, displayMode, isHorizontal, hasSorting }: UseColumnsProps) => {
    return useMemo(() => {
        const columns = getBarChartColumns(data.metadata.columns, config).throwIfFailed();
        const { allData, groupedChartData, dataKeys } = transformToBarChart(columns, data, displayMode, hasSorting);
        const { valueColumn, labelColumn, groupColumn } = columns;
        const axes = getAxes(
            valueColumn?.column,
            labelColumn?.column,
            config,
            isHorizontal,
            displayMode === 'percentage'
        );

        // If the graph is horizontal, we need to reverse the groups as Nivo displays it incorrectly
        const series = isHorizontal ? groupedChartData.reverse() : groupedChartData;

        return {
            allData,
            series,
            dataKeys,
            valueColumn,
            axes,
            hasSeriesColumn: Boolean(groupColumn)
        };
    }, [data, config, displayMode, hasSorting, isHorizontal]);
};
