import get from 'lodash/get';
import { ScalarData } from '../../../types/data/ScalarData';
import { DatasourceFunction } from '../../../types/Datasource';
import { PlaceholderConfig } from './Config';

const placeholder: DatasourceFunction<ScalarData, PlaceholderConfig> = async (config, context) => {
    if(config.data) {
        return { 
            data: config.data 
        };
    } else if(config.pick) {
        return { 
            data: get(context, config.pick) 
        };
    } else {
        return { data: '' }; // Nothing to show
    }
};

placeholder.config = 'PlaceholderConfig';

export default placeholder;
