import { createContext, useContext } from 'react';

interface VisualisationContextValue {
    updateVisualisationConfig?: (content: any) => void;
}

const VisualisationContext = createContext<VisualisationContextValue>({
    updateVisualisationConfig: () => undefined
});

VisualisationContext.displayName = 'VisualisationContext';

export const useVisualisationContext = () => useContext(VisualisationContext);

export default VisualisationContext;
