import Logo from 'images/squaredup-logo-full.svg?react';
import { NavLink } from 'react-router-dom';

/**
 * LoginPage
 * Provides two login methods to authorise user, either using Microsoft
 * Account or username & password combination.
 *
 * @example
 * `<LoginPage />`
 */
function LoginWrapper({ children }) {
    return (
        <div className='flex h-full upgradePage bg-backgroundPrimary text-textPrimary' data-theme='dark'>
            <div className='relative z-50 flex flex-col w-full h-full max-w-xl py-[4.375rem] px-[3.75rem] text-sm bg-backgroundPrimary'>
                <NavLink to='/' className='inline-block w-40'>
                    <Logo />
                </NavLink>
                {children}
            </div>
            <picture className='grow login-background'>
                <source
                    type='image/avif'
                    srcset='
                      /product_covers/dark_product_1286.avif 1286w,
                        /product_covers/dark_product_1988.avif 1988w,
                        /product_covers/dark_product_2571.avif 2571w,
                        /product_covers/dark_product_3840.avif 3840w'
                />

                <source
                    type='image/png'
                    srcset='
                        /product_covers/dark_product_1286.jpg 1286w,
                        /product_covers/dark_product_1988.jpg 1988w,
                        /product_covers/dark_product_2571.jpg 2571w,
                        /product_covers/dark_product_3840.jpg 3840w'
                />

                <img src='/product_covers/dark_product.jpg' alt='Squaredup Dashboard' />
            </picture>
        </div>
    );
}

LoginWrapper.propTypes = {};

export default LoginWrapper;
