import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'components/LoadingSpinner';
import { useRef, useState } from 'react';
import Tooltip from 'components/tooltip/Tooltip';
import { Button } from 'components/button';

type ApplicationTableActionButtonProps = {
    onClick: () => void;
    icon: IconProp;
    confirmationIcon?: IconProp;
    className?: string;
    dataTestId?: string;
    tooltip?: string;
    confirmationTooltip?: string;
};

export const ApplicationTableActionButton = ({
    onClick,
    icon,
    confirmationIcon,
    tooltip,
    confirmationTooltip,
    className,
    dataTestId
}: ApplicationTableActionButtonProps) => {
    const [actionIcon, setActionIcon] = useState(icon);
    const [actionTooltip, setActionTooltip] = useState(tooltip);

    const confirmationTimerRef = useRef<number | undefined>();

    const button = (
        <Button
            variant='tertiary'
            className={className}
            onClick={() => {
                onClick();

                if (confirmationIcon || confirmationTooltip) {
                    confirmationIcon && setActionIcon(confirmationIcon);
                    confirmationTooltip && setActionTooltip(confirmationTooltip);

                    confirmationTimerRef.current && window.clearTimeout(confirmationTimerRef.current);

                    confirmationTimerRef.current = window.setTimeout(() => {
                        confirmationIcon && setActionIcon(icon);
                        confirmationTooltip && setActionTooltip(tooltip);
                    }, 1500);
                }
            }}
            data-testid={dataTestId}
        >
            <FontAwesomeIcon icon={actionIcon} fixedWidth />
        </Button>
    );

    if (tooltip) {
        return <Tooltip title={actionTooltip} className='flex place-items-center'>{button}</Tooltip>;
    } else {
        return button;
    }
};

type ApplicationTableActionSpinnerProps = {
    tooltip?: string;
};

export const ApplicationTableActionSpinner = ({ tooltip }: ApplicationTableActionSpinnerProps) => {
    const spinner = (
        <LoadingSpinner className='w-[17.5px] h-[17.5px]' />
    );

    if (tooltip) {
        return <Tooltip title={tooltip}>{spinner}</Tooltip>;
    } else {
        return spinner;
    }
};