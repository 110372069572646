import Text from '@/components/Text';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import { DashboardContextValue } from 'contexts/DashboardContext';
import { useTileContext } from 'contexts/TileContext';
import { TileTypes } from 'dashboard-engine/constants';
import { ImageTileConfig } from 'dashboard-engine/tiles/image';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { GetDashboardImage, PutDashboardImage } from 'services/ImageService';
import { Tabs } from 'ui/Tabs';
import { ImagePreview } from './ImagePreview';
import { ImageUploadForm } from './ImageUploadForm';

type ImageTileFormData = ImageTileConfig['visualisation']['config'];

const initialHealthStateIconPosition = {
    vertical: 'top' as const,
    horizontal: 'left' as const
};

export const ImageTileEditor: React.FC<{
    config: ImageTileConfig;
    context: DashboardContextValue;
    tileId: string;
    onClose: () => void;
}> = ({ config, context, tileId, onClose }) => {
    const { onChange } = useTileContext();
    const [dataUrl, setDataUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const [isLoadingFile, setIsLoadingFile] = useState(false);
    const [canShowHealthState, setCanShowHealthState] = useState(false);
    const [activeTab, setActiveTab] = useState(config.visualisation?.config?.src ? 1 : 0);

    const defaultValues = {
        src: config.visualisation?.config?.src || '',
        title: config.visualisation?.config?.title || '',
        uploaded: config.visualisation?.config?.uploaded || 0,
        files: undefined, // transitory (not persisted)
        showHealthState: config.visualisation?.config?.showHealthState || false,
        healthStateMode: config.visualisation?.config?.healthStateMode || ['fill'],
        healthStateIconPosition:
            config.visualisation?.config?.healthStateIconPosition || initialHealthStateIconPosition,
        healthStateIconSize: config.visualisation?.config?.healthStateIconSize || 3
    };

    const formProps = useForm({ mode: 'all', criteriaMode: 'all', defaultValues, shouldUnregister: true });
    const {
        formState: { isValid, isSubmitting },
        reset,
        setValue,
        watch,
        trigger,
        handleSubmit
    } = formProps;
    const fields = watch();

    const { workspaceId, id } = context.dashboard;

    useEffect(() => {
        (async () => {
            try {
                if (defaultValues.uploaded) {
                    setIsLoadingFile(true);
                    // A tile image has been uploaded so attempt to read it
                    const dashboardImage = await GetDashboardImage(workspaceId, id, tileId, defaultValues.uploaded);
                    setCanShowHealthState(dashboardImage.dataURL.startsWith('data:image/svg'));
                    setDataUrl(dashboardImage.dataURL);
                    setFileName((dashboardImage.metadata?.fileName as string) || 'Uploaded file');
                    setIsLoadingFile(false);
                }
            } catch {
                setIsLoadingFile(false);
            }
        })();
    }, [defaultValues.uploaded, workspaceId, id, setValue, tileId]);

    const { mutateAsync: addDashboardImage } = useMutation(
        async (data: ImageTileFormData) => {
            let uploaded = data.uploaded;
            if (dataUrl) {
                await PutDashboardImage(workspaceId, id, tileId, { dataURL: dataUrl, metadata: { fileName } });
                uploaded = Date.now();
            }
            return { ...data, uploaded };
        },
        {
            onSuccess(data: ImageTileFormData) {
                onChange({
                    ...config,
                    _type: TileTypes.image,
                    visualisation: {
                        ...config.visualisation,
                        config: {
                            src: data.src,
                            title: data.title,
                            uploaded: data.uploaded,
                            showHealthState: data.showHealthState,
                            healthStateMode: data.healthStateMode,
                            healthStateIconPosition: data.healthStateIconPosition,
                            healthStateIconSize: data.healthStateIconSize
                        }
                    }
                });
                onClose();
            },
            onError() {
                const message = 'Failed to upload file';
                formProps.setError('files', { message }, { shouldFocus: true });
            }
        }
    );

    const handleTabChange = (index: number) => {
        setActiveTab(index);
        setDataUrl('');
        setFileName('');
        setCanShowHealthState(false);
        reset({
            showHealthState: false,
            healthStateMode: ['fill'],
            healthStateIconPosition: initialHealthStateIconPosition,
            healthStateIconSize: 3
        });
        trigger();
    };

    const handleFileChange = (url: string, name: string) => {
        setDataUrl(url);
        setFileName(name);
        const isSvg = url.startsWith('data:image/svg');
        setValue('showHealthState', isSvg);
        setCanShowHealthState(isSvg);
    };

    return (
        <Modal title={null} fullWidth maxWidth='max-w-7xl'>
            <FormProvider {...formProps}>
                <div className='flex flex-row min-h-[600px]'>
                    <div className='flex-1 grow-[4] border-r border-solid border-dividerPrimary'>
                        <form
                            onSubmit={handleSubmit((data: ImageTileFormData) => addDashboardImage(data))}
                            className='flex flex-col h-full'
                        >
                            <div className='flex flex-col flex-1 px-8 pt-8 overflow-hidden'>
                                <Text.H2 className='text-xl font-bold mb-4 truncate !overflow-visible'>
                                    Edit image tile
                                </Text.H2>
                                <Tabs
                                    selectedIndex={activeTab}
                                    onSelect={handleTabChange}
                                    className='!h-auto'
                                    tabs={[
                                        {
                                            label: 'Upload an image',
                                            className: 'w-1/2',
                                            component: (
                                                <ImageUploadForm
                                                    isLoadingFile={isLoadingFile}
                                                    fileName={fileName}
                                                    canShowHealthState={canShowHealthState}
                                                    handleFileChange={handleFileChange}
                                                />
                                            )
                                        },
                                        {
                                            label: 'Enter an image URL',
                                            className: 'w-1/2',
                                            component: (
                                                <>
                                                    <div className='pt-4'>
                                                        <Field.Input
                                                            type='url'
                                                            name='src'
                                                            label='Image URL'
                                                            placeholder='Enter an image URL'
                                                            validation={{
                                                                // Field required if no file
                                                                // already uploaded and no file to upload
                                                                required: true
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='mt-8'>
                                                        <Field.Input
                                                            type='text'
                                                            name='title'
                                                            label='Description'
                                                            placeholder='Enter an optional image description'
                                                            help='Shown to screen readers and on hover'
                                                            validation={{ maxLength: 300 }}
                                                        />
                                                    </div>
                                                </>
                                            )
                                        }
                                    ]}
                                    tabsID='imageTileTabs'
                                />
                            </div>

                            <ModalButtons hideTopMargin={true}>
                                <Button
                                    type='button'
                                    onClick={onClose}
                                    variant='tertiary'
                                    data-testid='cancelAddButton'
                                >
                                    Cancel
                                </Button>
                                <Button type='submit' data-testid='addButton' disabled={!isValid || isSubmitting}>
                                    {isSubmitting ? <LoadingSpinner size={18} /> : 'Save'}
                                </Button>
                            </ModalButtons>
                        </form>
                    </div>
                    <div className='flex flex-col flex-1 grow-[5] min-h-0 justify-center'>
                        <div className='flex flex-1 min-h-0 m-4 h-[600px] bg-componentBackgroundSecondary justify-center overflow-hidden'>
                            <ImagePreview src={dataUrl || fields.src} context={context} tileId={tileId} />
                        </div>
                    </div>
                </div>
            </FormProvider>
        </Modal>
    );
};
