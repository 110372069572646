import type { AccessControlEntryModel, AcePermission, ObjectPermissionsModel } from 'dynamo-wrapper';
import { useQuery, UseQueryOptions } from 'react-query';
import API from './API';
import { ProjectedTenantResponse } from './TenantService';
import { handleError, handleResponse } from './util';

export const GetEntityACL = async (entityId: string): Promise<AccessControlEntryModel[]> => {
    return API.get<AccessControlEntryModel[]>(`/accesscontrol/acl/${entityId}`).then(handleResponse).catch(handleError);
};

export const GetEntityPermissions = async (entityId: string): Promise<AcePermission[]> => {
    return API.get<AcePermission[]>(`/accesscontrol/permissions/${entityId}`).then(handleResponse).catch(handleError);
};

export const GetEntityTypePermissions = async (entityType: string): Promise<ObjectPermissionsModel[]> => {
    return API.get<ObjectPermissionsModel[]>(`/accesscontrol/permissions/type/${entityType}`)
        .then(handleResponse)
        .catch(handleError);
};

export const CanWriteToEntity = async (entityId: string): Promise<boolean> => {
    return (await GetEntityPermissions(entityId)).some((callerPermissions) => ['RW', 'AD'].includes(callerPermissions));
};

export const IsTenantAdmin = (tenant: ProjectedTenantResponse | undefined) => tenant?.callerPermissions?.includes('AD');

// Note this is separate from TENANT_USER_GROUPS_QUERY_KEY because TENANT_USER_GROUPS_QUERY_KEY includes
// group members, where this is just the list of groups.
export const ACL_USER_GROUPS_QUERY_KEY = 'ACL_USER_GROUPS_QUERY_KEY';

export const AccessControlQueryKeys = {
    EntityACL: (entityId?: string) => ['ENTITY_ACL_QUERY_KEY', entityId],
    CallerPermissionsOnEntity: (entityId?: string) => ['CALLER_ENTITY_PERMISSIONS_QUERY_KEY', entityId],
    CallerCanWriteToEntity: (entityId?: string) => ['CALLER_CAN_WRITE_TO_ENTITY_QUERY_KEY', entityId],
    EntityTypePermissions: (entityType: string) => ['ENTITY_TYPE_PERMISSIONS_QUERY_KEY', entityType],
    UserGroups: ACL_USER_GROUPS_QUERY_KEY,

    // 'Lazy update' means the data is cached for a long time but is updated asynchronously
    // on component mount. So it's always available for immediate display, but may show stale date briefly.
    // Designed for use with the ACL editor.
    UserGroupsWithLazyUpdate: [ACL_USER_GROUPS_QUERY_KEY, 'LAZY_UPDATE'],
    UsersWithLazyUpdate: ['ACL_USERS_LAZY_UPDATE']
};

/**
 * Simple wrapper hook to determine if user can write to a specific workspace.
 * Returns the same object as useQuery (data, isLoading etc)
 **/
export const useWorkspaceCanWrite = (
    workspaceId: string | undefined,
    options?: UseQueryOptions<boolean, unknown, boolean, (string | undefined)[]>
) => {
    return useQuery(AccessControlQueryKeys.CallerCanWriteToEntity(workspaceId), () => CanWriteToEntity(workspaceId!), {
        enabled: Boolean(workspaceId),
        cacheTime: Number.POSITIVE_INFINITY,
        staleTime: Number.POSITIVE_INFINITY,
        ...options
    });
};
