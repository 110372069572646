/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';

/**
 * Simple hook to wrap an async action with an isLoading flag and appropriate state.
 * @template T The type of data being awaited
 * @param { () => Promise<T> } action Method to fetch data
 * @param {any} initial Initial value for data
 * @param  {...any} deps Other arguments passed as dependencies to the hook
 * @returns { [boolean, T] }
 */
export default function useAwait(action, initial = [], ...deps) {
    const [data, setData] = useState(initial);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);

        async function fetchData() {
            const response = await action();

            if (isMounted) {
                setData(response);
                setIsLoading(false);
            }
        }

        fetchData();

        return () => {
            isMounted = false;
        };
    }, deps);
    return [isLoading, data];
}
