import { faCheck, faCopy, faXmark, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/button/Button';
import { ConfirmationPrompt } from 'components/ConfirmationPrompt';
import useCopyToClipboard from 'lib/useCopyToClipboard';
import PropTypes from 'prop-types';
import { useState } from 'react';
import LoadingSpinner from './LoadingSpinner';

/**
 * Standardised prompt for when copying is not available
 */
export const CopyNotAvailablePrompt = ({ value, onClose }: { value: CopyProps['value']; onClose: () => void }) => (
    <ConfirmationPrompt
        title='Copy to clipboard not available'
        prompt={
            <>
                The copy to clipboard functionality is currently not available for this browser. You can still manually
                copy the following text: <pre className='mt-4 select-all'>{value}</pre>
            </>
        }
        onConfirm={onClose}
        onClose={onClose}
        confirmButtonText='OK'
    />
);

type CopyProps = {
    value?: string | null;
    title?: string;
    icon?: IconDefinition;
    variant?: React.ComponentProps<typeof Button>['variant'];
    children?: NonNullable<PropTypes.ReactNodeLike>;
    disabled?: boolean;
    isLoading?: boolean;
    className?: string;
};

/**
 * Copy
 * Used to copy given value to clipboard
 *
 * @param { * }
 * @example
 * `<Copy value='hello world'/>`
 */
export default function Copy({
    value,
    title,
    icon = faCopy,
    variant = 'tertiary',
    children,
    disabled = false,
    isLoading = false,
    className
}: CopyProps) {
    const [copyIcon, setCopyIcon] = useState(icon);
    const [showNotAvailableDialog, setShowNotAvailableDialog] = useState(false);
    const { copy } = useCopyToClipboard({
        onNotSupported: () => setShowNotAvailableDialog(true),
        onSuccess: () => {
            setCopyIcon(faCheck);
            setTimeout(() => setCopyIcon(icon), 1500);
        },
        onError: () => {
            setCopyIcon(faXmark);
            setTimeout(() => setCopyIcon(icon), 1500);
        }
    });

    return (
        <>
            <Button
                className={className}
                variant={variant}
                onClick={() => copy(value || '')}
                title={title || 'Copy to clipboard'}
                icon={isLoading ? <LoadingSpinner size={18} /> : <FontAwesomeIcon icon={copyIcon} fixedWidth />}
                disabled={disabled || isLoading}
            >
                {children}
            </Button>
            {showNotAvailableDialog && (
                <CopyNotAvailablePrompt value={value} onClose={() => setShowNotAvailableDialog(false)} />
            )}
        </>
    );
}
