import { Serialised } from '@squaredup/ids';
import { AgentGroupOptions } from 'components/hooks/useAgentGroups';
import type { Config, ProjectedPlugin } from 'dynamo-wrapper';
import { PluginConfigFormData } from 'pages/settings/plugins/types';
import { createContext, useContext, useState } from 'react';
import { usePlugin } from './usePlugin';

export type PluginConfig =
    | (Serialised<Config<Omit<PluginConfigFormData, 'displayName'>>> & { json?: string })
    | undefined;

export interface PluginContextValue {
    workspaceToLinkTo: string;
    plugin: Serialised<ProjectedPlugin>;
    config: PluginConfig;
    agentGroups: AgentGroupOptions;
    installSampleDashboards: boolean;
    step: PluginModalStep;
    newId: string;
    setConfig: (config: PluginConfig) => void;
    setInstallSampleDashboards: (install: boolean) => void;
    setStep: (step: PluginModalStep) => void;
    setNewId: (id: string) => void;
}

export type PluginModalStep = 'form' | 'loading';

const PluginContext = createContext<PluginContextValue>({
    workspaceToLinkTo: '',
    plugin: {} as Serialised<ProjectedPlugin>,
    config: {} as PluginConfig,
    agentGroups: [],
    installSampleDashboards: false,
    step: 'form',
    newId: '',
    setConfig: () => undefined,
    setInstallSampleDashboards: () => undefined,
    setStep: () => undefined,
    setNewId: () => undefined
});

PluginContext.displayName = 'PluginFormContext';

export default PluginContext;

export const usePluginContext = () => useContext(PluginContext);

interface PluginConfigContextProps extends PluginContextValue {
    children: React.ReactNode;
}

export function PluginContextProvider({ children, ...value }: Partial<PluginConfigContextProps>) {
    const [installSampleDashboards, setInstallSampleDashboards] = useState(false);

    const { data: plugin } = usePlugin(value.plugin?.id || '');

    if (plugin) {
        value.plugin = plugin as any;
    }
    value.setInstallSampleDashboards = setInstallSampleDashboards;
    value.installSampleDashboards = installSampleDashboards;

    return <PluginContext.Provider value={value as PluginConfigContextProps}>{children}</PluginContext.Provider>;
}
