import { Button } from '@/components/Button';
import Text from '@/components/Text';
import trackEvent from 'lib/analytics';
import { useState } from 'react';
import { ProjectedTenantResponse } from 'services/TenantService';
import { useContactSalesModal } from './ContactSalesModal';
import { PlanUpgradeModal } from './PlanUpgradeModal';

type UserPlanProps = {
    tenant?: ProjectedTenantResponse;
    tierCanBeUpgraded: boolean;
};

export const UpgradeButton = ({ tenant, tierCanBeUpgraded }: UserPlanProps) => {
    const [purchaseSucceeded, setPurchaseSucceeded] = useState(tenant?.licenceData?.upgradePending ?? false);
    const [isUpgradeModalOpen, setUpgradeModalOpen] = useState(false);
    const { openContactSalesModal, contactSalesModal } = useContactSalesModal(
        'Plan and Usage',
        tenant?.id,
        tenant?.displayName ?? tenant?.name ?? ''
    );

    const handleButtonClick = () => {
        if (tenant == null) {
            return undefined;
        }

        setUpgradeModalOpen(true);
        trackEvent('Upgrade Modal Opened', { tenantId: tenant.id });
    };

    if (purchaseSucceeded) {
        return (
            <Text.Body className='mb-4 text-statusHealthyPrimary'>
                Thank you - your purchase was successful and your new plan will be active shortly.
            </Text.Body>
        );
    }

    return (
        <>
            {tenant && isUpgradeModalOpen && (
                <PlanUpgradeModal
                    onClose={() => setUpgradeModalOpen(false)}
                    onPurchase={() => setPurchaseSucceeded(true)}
                    tenant={tenant}
                />
            )}
            {tierCanBeUpgraded ? (
                <Button variant={'upgrade'} onClick={handleButtonClick} data-testid='upgradePlanButton'>
                    Compare plans
                </Button>
            ) : (
                <>
                    <Button
                        type='button'
                        variant='upgrade'
                        onClick={openContactSalesModal}
                        data-testid='upgradePlanButton'
                    >
                        Contact sales
                    </Button>
                    {contactSalesModal}
                </>
            )}
        </>
    );
};
