import DashboardContext, { useDashboardContext } from 'contexts/DashboardContext';
import { DataStreamErrors } from 'dashboard-engine/dataStreams/DataStreamErrors';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import RenderTile from 'dashboard-engine/render/RenderTile';
import { DrilldownContext } from '../../contexts/DrilldownContext';
import { useTileEditorContext } from '../../contexts/TileEditorContext';

export const VisualisationPreview: React.FC = () => {
    const dashboardContext = useDashboardContext();
    const { tileConfig, previewHealth, setTileConfig } = useTileEditorContext();

    const { isError, error } = useDataStreamConfig(tileConfig);

    return (
        // Min height prevents preview from collapsing to a height which causes calculation errors for visualizations
        <div className='h-full min-h-[100px] p-4'>
            <DrilldownContext.Provider value={{ isDrilldownEnabled: false }}>
                <DashboardContext.Provider
                    value={{
                        ...dashboardContext,
                        updateTile: setTileConfig as () => void
                    }}
                >
                    {isError ? (
                        <div className='flex flex-col justify-center h-full text-center whitespace-normal text-statusErrorPrimary'>
                            <DataStreamErrors error={error} showDetailedErrors={dashboardContext.showDetailedErrors} />
                        </div>
                    ) : (
                        <RenderTile
                            tileId='preview'
                            preview={true}
                            previewHealth={previewHealth}
                            config={tileConfig}
                            supportsDelete={false}
                            supportsDragging={false}
                            supportsEditingTitle={false}
                            supportsClone={false}
                            supportsFullscreen={false}
                        />
                    )}
                </DashboardContext.Provider>
            </DrilldownContext.Provider>
        </div>
    );
};
