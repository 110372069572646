import GenericRepo from './GenericRepo';
import webApi from '../datasources/misc/webApi/webApi';
import graphNodes from '../datasources/graph/graphNodes/graphNodes';
import graphNode from '../datasources/graph/graphNode/graphNode';
import graphNetwork from '../datasources/graph/graphNetwork/graphNetwork';
import graphCustom from '../datasources/graph/graphCustom/graphCustom';
import placeholder from '../datasources/misc/placeholder/placeholder';
import noOp from '../datasources/misc/noOp/noOp';
import connections from '../datasources/graph/connections/connections';
import script from '../datasources/misc/script/script';

import { DatasourceFunction } from '../types/Datasource';

const dataSourceRepo = new GenericRepo<DatasourceFunction<any,any>>({
    'web-api': webApi,
    'graph-nodes': graphNodes,
    'graph-node': graphNode,
    'graph-network': graphNetwork,
    'graph-custom': graphCustom,
    'placeholder': placeholder,
    'no-op': noOp,
    'connections': connections,
    'script': script
});

export default dataSourceRepo;
