import { useRefreshContext, type RefreshContextValue } from 'contexts/RefreshContext';
import { useState } from 'react';

/**
 * Allows useDataStream hooks within the nearest RefreshContext to be forcibly
 * refreshed, bypassing and updating the backend cache.
 */
export const useRefresh = (): Omit<RefreshContextValue, 'name'> => {
    const parentContext = useRefreshContext();

    const [count, setCount] = useState(0);

    const forceRefresh = () => {
        setCount((current) => current + 1);
    };

    const refreshCount = (parentContext.name !== 'null' ? parentContext.refreshCount : 0) + count;

    return { refreshCount, forceRefresh };
};
