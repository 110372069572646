import { ConfigCard } from 'pages/datasources/ConfigCard';
import { PluginSourceConfig } from 'services/SourceConfigService';

interface NonLinkedPluginsProps {
    unlinkedDataSources: PluginSourceConfig[];
    onSelectedDataSource: (plugin: PluginSourceConfig) => void;
}

export const NonLinkedPlugins = ({ unlinkedDataSources, onSelectedDataSource }: NonLinkedPluginsProps) => {
    return (
        <div className='grid grid-cols-4 gap-4'>
            {unlinkedDataSources?.map((plugin) => {
                return <ConfigCard plugin={plugin} key={plugin.id} onClick={() => onSelectedDataSource(plugin)} />;
            })}
        </div>
    );
};
