import { StatusError } from '@squaredup/utilities';

// Set a 3MB max size limit
export const maxSizeMegaBytes = 3;
const maxSizeBytes = maxSizeMegaBytes * 1024 * 1024;
export const workspaceAvatarMaxSizeInBytes = 1024 * 200; // 200 KB

export const imageExtensions = [
    'image/svg',
    'image/svg+xml',
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'image/webp'
];

export function getFile(files: unknown) {
    if (files instanceof FileList && files.length > 0) {
        return files.item(0);
    }
    return null;
}

export function checkFileSize(file: File) {
    if (file.size > maxSizeBytes) {
        return {
            ok: false,
            message: `File cannot exceed ${maxSizeMegaBytes} MB`
        };
    }
    return {
        ok: true,
        message: ''
    };
}

export const checkFileTypeIsImage = (file: File) => {
    return imageExtensions.includes(file.type);
};

export async function readFileAsDataURL(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const { ok, message } = checkFileSize(file);
        if (!ok) {
            reject(new StatusError(message, 413));
        } else {
            const reader = new FileReader();
            reader.onload = () => {
                const dataURL = reader.result;
                if (dataURL && typeof dataURL === 'string') {
                    resolve(dataURL);
                } else {
                    reject(new Error('Failed to read file as dataURL'));
                }
            };
            reader.onerror = () => {
                reject(reader.error);
            };
            reader.readAsDataURL(file);
        }
    });
}
