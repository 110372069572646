import NoDataPlaceholder from 'components/NoDataPlaceholder';
import { clamp } from 'lodash';
import React, { useMemo } from 'react';
import { BlockData } from '../types';
import { Block } from './Block';
import { BlocksGrid } from './BlocksGrid';

export interface BlocksProps {
    columns: number;
    blocks: BlockData[];
    blockHeight?: number;
    limitHeight?: boolean;
    wrapLabels?: boolean;
}

export const Blocks: React.FC<BlocksProps> = ({ blocks, columns, blockHeight, limitHeight, wrapLabels }) => {
    const titleSize = useMemo(() => (blockHeight ? clamp(blockHeight * 0.15, 12, 31) : undefined), [blockHeight]);
    const labelSize = useMemo(() => (blockHeight ? clamp(blockHeight * 0.1, 12, 18) : undefined), [blockHeight]);
    const barWidth = useMemo(() => (blockHeight ? clamp(blockHeight * 0.4, 30, 60) : undefined), [blockHeight]);
    const paddingX = useMemo(() => (blockHeight ? clamp(blockHeight * 0.1, 16, 24) : undefined), [blockHeight]);
    const paddingY = useMemo(() => (blockHeight ? clamp(blockHeight * 0.1, 6, 24) : undefined), [blockHeight]);

    return blocks.length > 0 ? (
        <BlocksGrid
            columns={columns}
            blockHeight={blockHeight}
            hasSublabels={blocks.some((b) => Boolean(b.sublabel))}
            limitHeight={limitHeight}
        >
            {blocks.map((block, index) => (
                <Block
                    key={`${block.name}${index}`}
                    block={block}
                    fixedTitleSize={titleSize}
                    fixedLabelSize={labelSize}
                    fixedBarWidth={barWidth}
                    fixedPaddingX={paddingX}
                    fixedPaddingY={paddingY}
                    wrapLabels={wrapLabels}
                />
            ))}
        </BlocksGrid>
    ) : (
        <NoDataPlaceholder />
    );
};
