import { ModalButtons } from 'components/Modal';
import Button from 'components/button';
import { useCreateDashboardAndNavigate } from 'components/hooks/useCreateDashboardAndNavigate';
import type { ImportStatus } from 'dynamo-wrapper';
import trackEvent from 'lib/analytics';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface FooterButtonsProps {
    importStatus?: ImportStatus;
    completed: boolean;
    dashboardId?: string;
    configId: string;
    pluginName: string;
    isInstallingDashboards: boolean;
    onClose?: () => void;
}

export function FooterButtons({
    importStatus,
    completed,
    dashboardId,
    configId,
    pluginName,
    isInstallingDashboards,
    onClose
}: FooterButtonsProps) {
    const navigate = useNavigate();
    const { createDashboardAndNavigate } = useCreateDashboardAndNavigate();
    const handleClose = () => (onClose ? onClose() : navigate(`/datasource/${configId}`));
    const [havePicked, setHavePicked] = useState(false);
    const hasSampleDashboards = Boolean(dashboardId);

    const pickAndLock = (fn: () => void) => {
        return () => {
            setHavePicked(true);
            fn();
        };
    };

    if (completed && importStatus?.status === 'failed') {
        return (
            <ModalButtons isFooter>
                <Button
                    variant='primary'
                    data-testid='closeOnFailure'
                    disabled={havePicked}
                    onClick={pickAndLock(() => {
                        trackEvent('Plugin Setup Complete', {
                            state: 'failed',
                            runInBackground: false,
                            closedToDSO: true,
                            exploreDashboards: false,
                            newDashboard: false,
                            hasSampleDashboards,
                            configId,
                            pluginName
                        });
                        handleClose();
                    })}
                >
                    Close
                </Button>
            </ModalButtons>
        );
    }

    if (completed && importStatus?.status === 'succeeded') {
        if (dashboardId) {
            return (
                <ModalButtons isFooter>
                    <Button
                        variant='tertiary'
                        data-testid='closeRatherThanExplore'
                        disabled={havePicked}
                        onClick={pickAndLock(() => {
                            trackEvent('Plugin Setup Complete', {
                                state: 'succeeded',
                                runInBackground: false,
                                closedToDSO: true,
                                exploreDashboards: false,
                                newDashboard: false,
                                hasSampleDashboards,
                                configId,
                                pluginName
                            });
                            handleClose();
                        })}
                    >
                        Close
                    </Button>

                    <Button
                        variant='primary'
                        disabled={havePicked}
                        data-testid='exploreDashboards'
                        onClick={pickAndLock(() => {
                            trackEvent('Plugin Setup Complete', {
                                state: 'succeeded',
                                runInBackground: false,
                                closedToDSO: false,
                                exploreDashboards: true,
                                newDashboard: false,
                                hasSampleDashboards,
                                configId,
                                pluginName
                            });
                            navigate(`/dashboard/${dashboardId}?force_open_folder=true`);
                        })}
                    >
                        Explore dashboards
                    </Button>
                </ModalButtons>
            );
        }

        return (
            <ModalButtons isFooter>
                <Button
                    variant={onClose ? 'primary' : 'tertiary'}
                    data-testid='closeRatherThanAdd'
                    disabled={havePicked}
                    onClick={pickAndLock(() => {
                        trackEvent('Plugin Setup Complete', {
                            state: 'succeeded',
                            runInBackground: false,
                            closedToDSO: true,
                            exploreDashboards: false,
                            newDashboard: false,
                            hasSampleDashboards,
                            configId,
                            pluginName
                        });
                        handleClose();
                    })}
                >
                    Close
                </Button>

                {!onClose && (
                    <Button
                        variant='primary'
                        data-testid='addNewDashboard'
                        disabled={havePicked}
                        onClick={pickAndLock(() => {
                            trackEvent('Plugin Setup Complete', {
                                state: 'succeeded',
                                runInBackground: false,
                                closedToDSO: false,
                                exploreDashboards: false,
                                newDashboard: true,
                                hasSampleDashboards,
                                configId,
                                pluginName
                            });
                            createDashboardAndNavigate();
                        })}
                    >
                        Add dashboard
                    </Button>
                )}
            </ModalButtons>
        );
    }

    return (
        <ModalButtons isFooter>
            {isInstallingDashboards && (
                <Button
                    variant='secondary'
                    data-testid='runInBackground'
                    disabled={havePicked}
                    onClick={pickAndLock(() => {
                        trackEvent('Plugin Setup Complete', {
                            state: 'loading',
                            runInBackground: true,
                            closedToDSO: false,
                            exploreDashboards: false,
                            newDashboard: false,
                            hasSampleDashboards,
                            configId,
                            pluginName
                        });
                        handleClose();
                    })}
                >
                    Run in background
                </Button>
            )}
        </ModalButtons>
    );
}
