import { VisualisationOption } from 'dashboard-engine/types/Visualisation';
import { ColumnDropdown } from '../sharedComponents/ColumnDropdown';
import { DataStreamDonutConfig } from './Config';
import { DonutConfigurationForm } from './DonutConfigurationForm';
import { findDonutLabelColumn, findDonutValueColumn, matchesData } from './dataUtils';

const DataStreamDonutOptions: VisualisationOption<DataStreamDonutConfig> = {
    initialPanels: (config?: DataStreamDonutConfig) => {
        const panels = [{ name: 'mapping', isOpen: true }];

        if (config?.legendMode && config.legendMode !== 'inline') {
            panels.push({ name: 'legend', isOpen: true });
        }

        return panels;
    },
    dataMappingComponent: ({ columns, config, onChange }) => {
        return (
            <>
                <ColumnDropdown
                    label='Value'
                    name='valueColumn'
                    help='Select the column to use for the value of each segment'
                    columns={columns}
                    defaultColumn={findDonutValueColumn(columns)}
                    config={config}
                    onChange={onChange}
                />
                <ColumnDropdown
                    label='Label'
                    name='labelColumn'
                    help='Select the column to use for the label of each segment'
                    columns={columns}
                    defaultColumn={findDonutLabelColumn(columns)}
                    config={config}
                    onChange={onChange}
                />
            </>
        );
    },
    configurationComponent: ({ config, accordionControls, onChange }) => {
        return (
            <DonutConfigurationForm 
                config={config} 
                onChange={onChange} 
                accordionControls={accordionControls}
            />
        );
    },
    handlers: {
        'valueColumn-select': (config, data) => {
            return {
                ...config,
                valueColumn: data
            };
        },
        'labelColumn-select': (config, data) => {
            return {
                ...config,
                labelColumn: data
            };
        },
        'form-change': (config, data) => ({ ...config, ...data })
    },
    validate: (_, config) => {
        return config;
    },
    matchesData
};

export default DataStreamDonutOptions;
