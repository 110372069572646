export type DataMatchCriterion = {
    success: boolean;
    message: string;
    additionalInformation?: string;
};

export type DataMatchCriteria<TValue> = {
    readonly success: boolean;
    readonly value: TValue;
    criteria: DataMatchCriterion[];
    pass: (message: string, v: Record<string, unknown>) => void;
    fail: (message: string, additionalInformation?: string) => void;
    throwIfFailed: () => TValue;
};

export const dataMatchCriteria = <TValue>(): DataMatchCriteria<TValue> => {
    const criteria: DataMatchCriterion[] = [];
    let value: TValue = {} as TValue;

    return {
        get success() {
            return criteria.every((a) => a.success);
        },
        get value(): TValue {
            return value;
        },
        criteria,
        pass: (message: string, partialValue: Record<string, unknown>) => {
            criteria.push({ success: true, message });
            value = {
                ...value,
                ...partialValue
            };
        },
        fail: (message: string, additionalInformation?: string) =>
            criteria.push({ success: false, message, additionalInformation }),
        throwIfFailed() {
            if (!criteria.every((a) => a.success)) {
                throw new Error('Criteria has failures');
            }
            return value;
        }
    };
};
