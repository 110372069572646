import { Serialised } from '@squaredup/ids';
import type { ProjectedPlugin } from 'dynamo-wrapper';
import { PluginCard } from './PluginCard';
import { gridResponsiveClassName } from './common';

const suggestedDatasourceNames = [
    'Azure',
    'AWS',
    'Azure DevOps',
    'Cloudflare',
    'Elasticsearch',
    'GitHub',
    'Google Cloud',
    'Jenkins',
    'Jira',
    'Microsoft 365',
    'PowerShell',
    'Prometheus',
    'Sample Data',
    'Splunk Enterprise',
    'Web API',
    'Zendesk Support'
];

interface SuggestedPluginsPageProps {
    onSelect?: (plugin: Serialised<ProjectedPlugin>) => void;
    plugins: Serialised<ProjectedPlugin>[];
}

export function SuggestedPluginsPage({ onSelect, plugins }: SuggestedPluginsPageProps) {
    const suggestedPlugins = plugins
        ?.filter((plugin) => suggestedDatasourceNames.includes(plugin.displayName || '') && !plugin.onPrem)
        .sort((a, b) => (a.displayName || '').localeCompare(b.displayName || ''));

    return (
        <section className='flex-col w-full'>
            <h4 className='pb-2 text-2xl font-semibold'>Suggested</h4>
            <ol className={gridResponsiveClassName}>
                {suggestedPlugins.map((plugin) => (
                    <li className='h-36' key={plugin.id}>
                        <PluginCard plugin={plugin} onClick={onSelect} />
                    </li>
                ))}
            </ol>
        </section>
    );
}
