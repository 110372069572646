import { faArrowUpRightFromSquare, faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Serialised } from '@squaredup/ids';
import { ChannelTypeIds } from '@squaredup/monitoring';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { defaultOnCreate } from 'components/forms/autocomplete/Autocomplete';
import Field from 'components/forms/field/Field';
import { getFormFieldComponent } from 'components/forms/field/getFormFieldComponent';
import { isAutocomplete } from 'components/forms/jsonForms/autocompleteOptions';
import type { Channel } from 'dynamo-wrapper';
import { emailRegex } from 'lib/validation';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ChannelCreate, ChannelType, Test } from 'services/NotificationChannelService';

const ALERT_SUCCESS = 'Test sent successfully';
const ALERT_ERROR = 'Test failed';

export interface NotificationRuleAddChannelPanelProps {
    existingChannelNames: string[];
    setIsValid: (isValid: boolean) => void;
    channelType?: Serialised<ChannelType>;
    saveNewChannel: (c: ChannelCreate) => void;
    initialValue?: Serialised<Channel>;
}

export interface ChannelFormData {
    displayName: string;
    [key: string]: unknown;
}

const ChannelExternalLinks = ({ channelType }: { channelType: Serialised<ChannelType> }) => {
    const externalLinks = channelType?.externalLinks.filter((link) => !link.isKbArticle) ?? [];

    return externalLinks.length > 0 ? (
        <div className='mt-2'>
            {externalLinks.map(({ url, label }) => (
                <div key={url}>
                    <Button type='button' variant='link' href={url}>
                        {label}
                    </Button>
                </div>
            ))}
        </div>
    ) : null;
};

export const NotificationRuleAddChannelPanel: React.FC<NotificationRuleAddChannelPanelProps> = ({
    channelType,
    existingChannelNames,
    children,
    saveNewChannel,
    setIsValid,
    initialValue
}) => {
    const [alertTestLoading, setAlertTestLoading] = useState<boolean>(false);
    const [alertTestResult, setAlertTestResult] = useState<string | undefined>(undefined);
    const existingChannelNamesLower = existingChannelNames.map((name) => name.toLowerCase());

    const formProps = useForm<ChannelFormData>({
        mode: 'all',
        defaultValues: initialValue ? { displayName: initialValue.displayName, ...initialValue.config } : {}
    });
    const {
        handleSubmit,
        watch,
        reset,
        formState: { isValid, isDirty }
    } = formProps;

    const data = watch();

    useEffect(() => {
        setIsValid(data && isValid);
    }, [setIsValid, data, isValid]);

    const handleTest = async (formData: ChannelFormData) => {
        if (!channelType) {
            return;
        }

        const { displayName, ...rest } = formData;
        setAlertTestLoading(true);

        try {
            await Test(channelType?.id, rest, initialValue?.id);
            setAlertTestResult(ALERT_SUCCESS);
        } catch (e: any) {
            const { error } = e.response.data;
            setAlertTestResult(error);
        }

        setAlertTestLoading(false);
        // mark the form fields as untouched by the user after testing
        reset(undefined, { keepValues: true, keepIsValid: true });
    };

    const addNewChannel = ({ displayName, ...config }: ChannelFormData) => {
        if (!channelType) {
            return;
        }

        saveNewChannel({
            displayName,
            config,
            channelTypeId: channelType.id,
            enabled: initialValue?.enabled ?? true
        });
    };

    if (!channelType) {
        return <></>;
    }

    return (
        <FormProvider {...formProps}>
            {channelType.id === ChannelTypeIds.Zapier ? (
                <form
                    className='flex flex-col overflow-hidden'
                    data-testid='addEditZapierDestinationForm'
                    onSubmit={handleSubmit(() => null)}
                >
                    <div className='flex-1 min-h-0 px-8 pb-12 table-scroll-overflow'>
                        <div className='mb-4'>
                            <span>
                                Create or manage your SquaredUp Zapier integration directly in Zapier. Click the link
                                below to get started.
                            </span>
                        </div>
                        
                        <Button variant='secondary' href='https://squaredup.com/cloud/zapier-integration'>
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} fixedWidth /> Go to Zapier
                        </Button>
                    </div>
                    {/* Render buttons */}
                    {children}
                </form>
            ) : (
                <form
                    className='flex flex-col overflow-hidden'
                    data-testid='addEditDestinationForm'
                    onSubmit={handleSubmit(addNewChannel)}
                >
                    <div className='flex-1 min-h-0 px-8 pb-12 table-scroll-overflow'>
                        <Field.Input
                            type='text'
                            name='displayName'
                            label='Destination name'
                            placeholder='Enter a destination name'
                            validation={{
                                required: true,
                                validate: (value) =>
                                    initialValue?.displayName === value ||
                                    !existingChannelNamesLower.includes(value?.toLowerCase()) ||
                                    'Destination name must be unique'
                            }}
                        />
                        {/* Dynamic form */}
                        {channelType.uiConfiguration.map((fieldProps: any) => {
                            const FormField = getFormFieldComponent(fieldProps.type);

                            return (
                                <FormField
                                    key={fieldProps.name}
                                    {...fieldProps}
                                    {...(isAutocomplete(fieldProps) && {
                                        onCreate: fieldProps.allowCustomValues !== false ? defaultOnCreate : undefined
                                    })}
                                    {...(fieldProps.optionType === 'email' && {
                                        isValidNewOption: (email: string) => email.match(emailRegex)
                                    })}
                                />
                            );
                        })}

                        <div className='mt-8'>
                            <Button
                                type='button'
                                variant='secondary'
                                disabled={alertTestLoading || !isValid}
                                onClick={() => handleTest(data)}
                            >
                                {alertTestLoading ? <LoadingSpinner size={18} /> : <span>Test</span>}
                            </Button>
                            {!isDirty && alertTestResult !== undefined && (
                                <>
                                    <div className='flex items-center mt-3'>
                                        {alertTestResult === ALERT_SUCCESS ? (
                                            <FontAwesomeIcon icon={faCircleCheck} className='text-statusHealthyPrimary' />
                                        ) : (
                                            <FontAwesomeIcon icon={faCircleXmark} className='text-statusErrorPrimary' />
                                        )}
                                        <span className='ml-2 text-sm'>
                                            {alertTestResult === ALERT_SUCCESS ? alertTestResult : ALERT_ERROR}
                                        </span>
                                    </div>
                                    {alertTestResult !== ALERT_SUCCESS && (
                                        <div className='p-4 mt-2 bg-componentBackgroundSecondary rounded-input'>
                                            <p>{alertTestResult}</p>
                                            <p className='mt-2 text-sm'>
                                                You can continue to use this configuration, but may encounter issues.
                                            </p>
                                            <ChannelExternalLinks channelType={channelType} />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    {/* Render buttons */}
                    {children}
                </form>
            )}
        </FormProvider>
    );
};
