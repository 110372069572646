import { StreamDataColumn } from '@squaredup/data-streams';
import Tooltip from 'components/tooltip/Tooltip';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import EditorSelect from 'ui/editor/components/EditorSelect';
import { ColumnOption } from 'ui/editor/dataStream/utilities/filters';
import { getBucketByOptions } from 'ui/editor/dataStream/utilities/grouping';
import { DeleteRowButton } from './DeleteRowButton';

interface GroupRowProps {
    columns: StreamDataColumn[];
    columnOptions: ColumnOption[];
    fieldIndex: number;
    deleteGroup: () => void;
}

export const GroupRow: React.FC<GroupRowProps> = ({ columns, columnOptions, fieldIndex, deleteGroup }) => {
    const { setValue, watch } = useFormContext();

    // For some reason, if we watch the field directly, e.g. watch(`groups.${fieldIndex}`)
    // the component tests refuse to pass
    const group = watch().groups?.[fieldIndex] ?? {};

    const { groupBy, bucketBy } = group;

    const currentColumnShape = columns.find((c) => c.name === groupBy)?.shapeName;

    const bucketOptions = useMemo(() => getBucketByOptions(currentColumnShape), [currentColumnShape]);

    if (currentColumnShape != null && !bucketOptions.some((b) => b.value === bucketBy)) {
        // Default to the first bucketing method, if the current column being grouped supports them,
        // or clear the value if there are no options available (which means the field isn't being
        // displayed, but we need to keep the config valid)
        setValue(`groups.${fieldIndex}.bucketBy`, bucketOptions[0]?.value ?? null);
    }

    return (
        <>
            <div className='col-start-1'>
                <EditorSelect name={`groups[${fieldIndex}].groupBy`} placeholder='Group by' options={columnOptions} />
            </div>
            {bucketOptions.length > 1 ? (
                <EditorSelect
                    key='bucketBy'
                    name={`groups[${fieldIndex}].bucketBy`}
                    placeholder='Select column'
                    options={bucketOptions}
                    isClearable={false}
                />
            ) : (
                <EditorSelect
                    key='bucketByDisabled'
                    name='disabled'
                    placeholder='-'
                    isDisabled={true}
                    isClearable={false}
                    options={[]}
                />
            )}
            <Tooltip title='Remove this grouping' className='flex col-start-3 place-items-center'>
                <DeleteRowButton
                    aria-label='Remove this grouping'
                    className='block w-full h-full'
                    onClick={deleteGroup}
                />
            </Tooltip>
        </>
    );
};
