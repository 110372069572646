import { RegisterOptions, UseFormRegister } from 'react-hook-form';
import styles from './Slider.module.css';

interface SliderProps
    extends Pick<
        JSX.IntrinsicElements['input'],
        'className' | 'style' | 'name' | 'min' | 'max' | 'step' | 'onChange' | 'defaultValue'
    > {
    markers?: { label: string; value: number }[];
    reverse?: boolean;
    validation?: RegisterOptions;
    register?: UseFormRegister<Record<string, any>>;
}

function Slider({
    name,
    min,
    max,
    step,
    markers = [],
    reverse,
    validation,
    defaultValue,
    onChange,
    register
}: SliderProps) {
    const getMarkerStyles = (value: number) => {
        const minNumber = min as number;
        const maxNumber = max as number;
        const multiplier = 100 / (maxNumber - minNumber);
        const isRightAligned = value >= (minNumber + maxNumber) / 2;

        return isRightAligned
            ? {
                  [!reverse ? 'right' : 'left']: (maxNumber - value) * multiplier
              }
            : {
                  [!reverse ? 'left' : 'right']: (minNumber - value) * multiplier
              };
    };

    return (
        <div className='relative'>
            <input
                className={styles.slider}
                style={{ direction: !reverse ? 'ltr' : 'rtl' }}
                type='range'
                min={min}
                max={max}
                step={step}
                onChange={onChange}
                defaultValue={defaultValue}
                {...(register &&
                    register(name!, {
                        ...validation,
                        valueAsNumber: true
                    }))}
            />
            <div className='h-6 text-sm text-textPrimary'>
                {markers.map(({ label, value }) => {
                    return (
                        <span key={label} className='absolute' style={getMarkerStyles(value)}>
                            {label || value}
                        </span>
                    );
                })}
            </div>
        </div>
    );
}

export default Slider;
