import { HealthState, stateStrings } from '@squaredup/monitoring';
import { groupBy } from 'lodash';
import { oneMinute } from 'queries/constants';
import { getDashboardHealthWithTileStates } from 'queries/hooks/useDashboardHealthStateRollup';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { useMemo } from 'react';
import { useQueries } from 'react-query';
import { useGetGraphNodes, useGetNetworkNode } from '../context/NetworkMapStoreContext';

const getGroupHealthState = (states: HealthState[]) => {
    let groupHealthState: HealthState = stateStrings.unknown;

    const statesWithCount = states.reduce((stateCounter, state) => {
        stateCounter.set(state, (stateCounter.get(state) ?? 0) + 1);
        return stateCounter;
    }, new Map<HealthState, number>());

    const monitorStates = [...statesWithCount.keys()];

    if (monitorStates.includes(stateStrings.error)) {
        groupHealthState = stateStrings.error;
    } else if (monitorStates.includes(stateStrings.warning)) {
        groupHealthState = stateStrings.warning;
    } else if (monitorStates.includes(stateStrings.success)) {
        groupHealthState = stateStrings.success;
    }

    return groupHealthState;
};

export const useGetMonitorGroupHealthState = (id: string, sourceType?: string) => {
    const { sourceNodeIds } = useGetNetworkNode()(id)?.data ?? {};
    const getGraphNodes = useGetGraphNodes();

    const monitorTileIdsByDashId = useMemo(() => {
        if (sourceType === 'squaredup/monitor') {
            const monitorNodes = getGraphNodes()
                .filter((node => sourceNodeIds?.includes(node.id) && node.dashId?.[0] && node.tileId?.[0]))
                .map(({ id: monitorId, dashId, tileId, state }) => {
                    return {
                        id: monitorId,
                        dashId: dashId![0],
                        tileId: tileId![0],
                        initialState: state?.[0]
                    };
                });

            return groupBy(
                monitorNodes,
                ({ dashId }) => {
                    return dashId;
                }
            );
        }
        return {};

    }, [sourceType, sourceNodeIds, getGraphNodes]);

    const queries = useQueries(monitorTileIdsByDashId ? 
        Object.entries(monitorTileIdsByDashId ?? []).map(([ dashId ]) => {
            return { 
                queryKey: dashboardQueryKeys.stateRollup(dashId), 
                queryFn: async () => getDashboardHealthWithTileStates(dashId),
                staleTime: oneMinute,
                cacheTime: oneMinute,
                refetchInterval: oneMinute
            };
        }) : []
    );

    const tileHealthStates: HealthState[] = [];

    if (monitorTileIdsByDashId && queries.every(query => query.isFetched)) {
        Object.entries(monitorTileIdsByDashId ?? []).forEach(([, monitors], index) => {
            const dashboardStateData = queries[index]?.data;
            
            if (dashboardStateData) {
                monitors.forEach(({ tileId }) => {
                    tileHealthStates.push(dashboardStateData?.tileStates[tileId].state);
                });
            }
        });
    }

    if (monitorTileIdsByDashId) {
        const initialStates = Object.values(monitorTileIdsByDashId ?? {})
            .map(monitorNodes => monitorNodes.map(({ initialState }) => initialState))
            .flat()
            .filter(state => state !== undefined) as HealthState[];

        return getGroupHealthState(tileHealthStates.length ?
            tileHealthStates :
            initialStates
        );
    }

    return undefined;
};