import { Edge, Node } from '@squaredup/graph';
import { PinnableNode } from '../types';
import { graphEntitiesToFlowNodes } from './graphNodesToFlowNodes';
import { rewriteLargeNodeGroups } from './rewriteLargeNodeGroups';
import { rewriteSourceToCanonical } from './rewriteSourceToCanonical';

export const setNodesAndEdgeVisibility = (
    graphNodes: Node[], 
    graphEdges: Edge[], 
    expandedNodeIds: string[],
    pinnedNodeIds: string[],
    pinnedGroupMemberNodeIds: Map<string, string[]>,
    ungroupedNodeIds: string[]
) => {
    const results = rewriteSourceToCanonical({ nodes: graphNodes, edges: graphEdges });

    const eligibleNodes = [...results.nodes];
    const nodeIds = eligibleNodes.map(({ id }: any) => id);
    const eligibleEdges = results.edges.filter(
        ({ inV, outV }: any) => [inV, outV].every(edgeNodeId => nodeIds.includes(edgeNodeId))
    );

    let { nodes: flowNodes, edges: flowEdges } = graphEntitiesToFlowNodes(
        eligibleNodes, 
        eligibleEdges
    );

    const {
        nodes, 
        edges
    } = rewriteLargeNodeGroups(
        flowNodes,
        flowEdges,
        expandedNodeIds,
        pinnedNodeIds,
        pinnedGroupMemberNodeIds,
        ungroupedNodeIds
    );

    // Get map of rewrittenNodeId -> sourceNodeId for expanded/hidden resolution
    const rewrittenNodeIdToSourceNodeId = new Map(
        nodes.reduce((rewrittenNodeIdSourceNodeIdPairs: [string, string][], node) => {
            if (node.data.sourceNodeIds) {
                node.data.sourceNodeIds.forEach((sourceNodeId: string) => {
                    rewrittenNodeIdSourceNodeIdPairs.push([node.id, sourceNodeId]);
                });
            };

            return rewrittenNodeIdSourceNodeIdPairs;
        }, [])
    );

    const updatedNodes = nodes.map((node) => {
        // Expanded if the nodeId is in the list of expandedNodeIds OR the list of rewritten source IDs
        const expanded = Boolean(
            expandedNodeIds?.includes(node.id) || (
                node.data.sourceNodeIds &&
                node.data.sourceNodeIds?.some((sourceNodeId: string) => expandedNodeIds.includes(sourceNodeId))
            )
        );

        // Show if the nodeId is in the list of pinnedNodeIds OR was a member of a pinned node 
        // (this is needed to stop group nodes collapsing/disappearing)
        const pinned = pinnedNodeIds?.includes(node.id);

        const nodeConnections = edges
            .reduce((connections, { source, target }) => {
                if (source === node.id) {
                    connections.outgoingNodeIds.push(target);
                    connections.connectedNodeIds.push(target);
                }
                if (target === node.id) {
                    connections.incomingNodeIds.push(source);
                    connections.connectedNodeIds.push(source);
                }
                return connections;
            }, { 
                incomingNodeIds: [] as string[], 
                outgoingNodeIds: [] as string[],
                connectedNodeIds: [] as string[]
            }
        );

        const { incomingNodeIds, outgoingNodeIds, connectedNodeIds } = nodeConnections;

        const connectedToExpanded = connectedNodeIds.some(
            connectedNodeId => 
                expandedNodeIds.includes(connectedNodeId) || (
                    rewrittenNodeIdToSourceNodeId.has(connectedNodeId) && 
                    expandedNodeIds.includes(rewrittenNodeIdToSourceNodeId.get(connectedNodeId)!)
                )
        );

        return {
            ...node,
            hidden: !(expanded || connectedToExpanded || pinned),
            data: {
                ...node.data,
                incomingNodeIds,
                outgoingNodeIds,
                connectedNodeIds,
                expanded,
                pinned,
                connectedToExpanded: connectedNodeIds.some(
                    connectedNodeId => expandedNodeIds?.includes(connectedNodeId)
                )
            }
        };
    });

    const hiddenNodeIds = updatedNodes
        .filter(({ hidden }) => hidden)
        .map(({ id }) => id);

    return {
        nodes: updatedNodes.map((node) => {
            return {
                ...node,
                data: {
                    ...node.data,
                    hiddenConnections: node.data.connectedNodeIds
                        ?.filter((connectedNodeId: string) => hiddenNodeIds.includes(connectedNodeId))?.length
                }
            };
        }) as PinnableNode[],
        edges: edges.map((edgeWithoutHiddenState) => ({
            ...edgeWithoutHiddenState,
            hidden: Boolean(
                hiddenNodeIds.includes(edgeWithoutHiddenState.source) || 
                hiddenNodeIds.includes(edgeWithoutHiddenState.target)
            )
        }))
    };
};
