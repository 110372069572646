import { Donut } from './dataUtils';
import { Margins, marginsInlineLegend, marginsNoLegend, marginsTableLegend } from './DataStreamDonutMargins';

export const MAX_LABEL_LENGTH = 20;
const APPROX_CHAR_SIZE = 6;

// An example of width/height for the visualization from the designs, which we need to preserve the aspect ratio
export const DONUT_ORIGINAL_WIDTH = 450;
export const DONUT_ORIGINAL_HEIGHT = 250;

/**
 * Calculates the size and margins of the donut, and determines if labels should be shown
 * based on the width/height/scale/series specified
 * @param width Width of tile
 * @param height Height of tile
 * @param scale Scale versus the 'perfect' donut size
 * @param isLegendTable True if using table style legend
 * @param series Data
 */
export const calculateSize = (
    width: number,
    height: number,
    scale: number,
    isLegendTable: boolean,
    series: Donut[]
) => {
    // If we're showing a table legend, we can use the specified margins
    if (isLegendTable) {
        const margins = marginsTableLegend(scale);
        const size = getSize(width, height, margins);
        return {
            size,
            margins,
            showLabels: false
        };
    }

    // Otherwise, we need to check if rendering the given labels would result in either:
    // - the donut being too small
    // - the labels being cropped

    // So, let's approximate the size of the longest label
    const maxLabel =
        (Math.min(Math.max(...series.map((s) => s.label.length)), MAX_LABEL_LENGTH) + 1) * APPROX_CHAR_SIZE;

    // And calculate what size the donut would be with those labels
    const margins = marginsInlineLegend(scale, maxLabel);
    const size = getSize(width, height, margins);

    // We can then approximate space left over after rendering
    // the donut with labels either side (worst case, given max label length)
    const space = width - size;

    // If the donut isn't too small, and there's space left, we can go ahead
    if (size > 75 && space >= 0) {
        return {
            size,
            margins,
            showLabels: true
        };
    }

    // Otherwise, we choose not to show labels, and re-calculate the desired size based on not showing the labels
    const noLabelMargins = marginsNoLegend(scale);
    const noLabelSize = getSize(width, height, noLabelMargins);
    return {
        size: noLabelSize,
        margins: noLabelMargins,
        showLabels: false
    };
};

/**
 * Calculate the scale factor of the tile versus the originally designed size
 * This allows us to scale the donut and things like font size proportionally
 * @param width Tile width
 * @param height Tile height
 * @returns Scale factor
 */
export const getScale = (width: number, height: number) =>
    Math.max(0.25, Math.min(width / DONUT_ORIGINAL_WIDTH, height / DONUT_ORIGINAL_HEIGHT));

/**
 * Calculate the size of the actual donut based on given width/height/margins
 * @param width Tile width
 * @param height Tile height
 * @param margins Margins
 * @returns Donut size
 */
export const getSize = (width: number, height: number, margin: Margins) => {
    const actualWidth = width - margin.left - margin.right;
    const actualHeight = height - margin.top - margin.bottom;

    if (actualWidth <= actualHeight) {
        return actualWidth;
    } else {
        return actualHeight;
    }
};
