import { useMemo, useState } from 'react';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { DataStreamFilterOption } from './useDataStreamFilters';
import { useDataStreamSortOrder } from './useDataStreamSortOrder';
import { useRecentDataStreamStore } from './useRecentDataStreamStore';

export const useSortedDataStreams = (dataStreams?: DataStreamFilterOption[], allowSort?: boolean) => {
    const { config } = useDatasetContext();
    const [{ id: dataStreamId }] = useState(config.dataStream || {});

    const { sort, sortOrder, nextSortOrder } = useDataStreamSortOrder();

    const { recentDataStreams } = useRecentDataStreamStore();

    const topRecent = useMemo(() => {
        const streamIds = dataStreams?.map((stream) => stream.id);
        return recentDataStreams?.filter((id) => streamIds?.includes(id as `datastream-${string}`)).slice(0, 5);
    }, [dataStreams, recentDataStreams]);

    const sortedStreams = allowSort ? sort(dataStreams, dataStreamId, topRecent) : dataStreams;

    return {
        sortedStreams,
        sortOrder,
        topRecent,
        nextSortOrder
    };
};
