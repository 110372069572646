import { cn } from '@/lib/cn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ComponentProps, ReactElement } from 'react';

export interface DropdownRootProps extends DropdownMenu.DropdownMenuProps {
    onOpenChange?: (open: boolean) => void;
}

const Root: React.FC<DropdownRootProps> = ({ children, onOpenChange, ...props }) => (
    <DropdownMenu.Root onOpenChange={onOpenChange} modal={false} {...props}>
        {children}
    </DropdownMenu.Root>
);

export interface MenuProps extends DropdownMenu.DropdownMenuContentProps {
    container?: HTMLElement | null;
    menuId?: string;
}

const Menu: React.FC<MenuProps> = ({
    container,
    className,
    side = 'bottom',
    align = 'end',
    menuId,
    children,
    ...props
}) => (
    <DropdownMenu.Portal {...(container && { container })}>
        <DropdownMenu.Content
            className={cn('w-48 border z-50 bg-backgroundPrimary border-outlinePrimary text-textPrimary', className)}
            side={side}
            sideOffset={5}
            align={align}
            avoidCollisions
            data-menuid={menuId}
            {...props}
        >
            {children}
        </DropdownMenu.Content>
    </DropdownMenu.Portal>
);

export interface TriggerProps extends ComponentProps<typeof DropdownMenu.Trigger> {
    disabled?: boolean;
    className?: string;
}

const Trigger: React.FC<TriggerProps> = ({ disabled, className, children, ...props }) => {
    return (
        <DropdownMenu.Trigger disabled={disabled} className={cn(className)} asChild {...props}>
            {children}
        </DropdownMenu.Trigger>
    );
};

export interface ItemProps extends DropdownMenu.DropdownMenuItemProps {
    icon?: ReactElement<typeof FontAwesomeIcon>;
    testId?: string;
    onSelect?: (event: Event) => void;
}

const Item: React.FC<ItemProps> = ({ icon, className, disabled, children, onSelect, testId, ...props }) => (
    <DropdownMenu.Item
        className={cn(
            'flex gap-x-xs bg-transparent py-2 px-xs leading-input cursor-pointer data-[highlighted]:bg-dividerTertiary data-[highlighted]:ring-0 data-[highlighted]:outline-0 focus:outline-0 focus:ring-0',
            disabled && 'opacity-50',
            className
        )}
        disabled={disabled}
        onSelect={onSelect}
        data-testid={testId}
        {...props}
    >
        {icon && <span className='flex-shrink-0 text-textSecondary'>{icon}</span>}
        <span className='flex-1 min-w-0'>{children}</span>
    </DropdownMenu.Item>
);

interface GroupProps {
    className?: string;
}

const Group: React.FC<GroupProps> = ({ children, className }) => (
    <DropdownMenu.Group className={cn('empty:hidden empty:py-0 py-xxxs [&:not(:first-child)]:-mt-px', className)}>
        {children}
    </DropdownMenu.Group>
);

interface SeparatorProps {
    className?: string;
}

const Separator: React.FC<SeparatorProps> = ({ className }) => (
    <DropdownMenu.Separator className={cn('w-full h-px bg-dividerPrimary', className)} />
);

export default Object.assign(Root, {
    Trigger,
    Menu,
    Item,
    Group,
    Separator
});
