import { DataRequestPurpose } from '@squaredup/constants';
import {
    DataStreamBaseTileConfig,
    DataStreamOptions,
    getClientDataStreamRequest,
    getGroupingSpec,
    getSortSpec,
    parseDefinitionColumns
} from '@squaredup/data-streams';
import { getDefaultValues } from 'components/forms/jsonForms/DisplayJsonUi';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { streamDataKeys } from 'queries/queryKeys/streamDataKeys';
import type { QueryKey } from 'react-query';
import { useTileTimeframes } from 'ui/editor/dataStream/TileEditor/hooks/useTileTimeframes';
import { UseDataStreamQueryOptions, UseDataStreamResult, useDataStream } from './useDataStream';
import { useDataStreamFromTileConfig } from './useDataStreamFromTileConfig';
import { useVariableContext } from './useVariableContext';

export type UseDataStreamConfigResult = UseDataStreamResult & {
    // Whether the data stream has default values or has config object
    isConfigured: boolean;
    // Whether the data stream is configured and all required fields are valid
    isConfiguredStrict: boolean;
    queryKey: QueryKey | undefined;
};

export function useDataStreamConfig(
    config: DataStreamBaseTileConfig,
    queryOptions?: UseDataStreamQueryOptions,
    purpose?: DataRequestPurpose
): UseDataStreamConfigResult {
    const {
        isConfigured,
        isConfiguredStrict,
        errorMessage,
        dataStreamDefinition,
        streamDefinitionLoading,
        streamDefinitionError
    } = useDataStreamFromTileConfig(config);
    const dashboardContext = useDashboardContext();

    const dataStreamOptions: DataStreamOptions = {
        ...queryOptions?.dataStreamOptions,
        ...(config.dataStream?.group && { group: getGroupingSpec(config.dataStream) }),
        ...(config.dataStream?.sort && { sort: getSortSpec(config.dataStream) }),
        ...(config.dataStream?.accessControlType && { accessControlType: config.dataStream.accessControlType }),
        metadata: parseDefinitionColumns(config.dataStream?.metadata),
        purpose: purpose ?? 'other'
    };

    let requestConfig: DataStreamBaseTileConfig = {
        ...config,
        dataStream: {
            ...config.dataStream,
            dataSourceConfig:
                config.dataStream?.dataSourceConfig ??
                (dataStreamDefinition ? getDefaultValues(dataStreamDefinition.template) : undefined),
            id: dataStreamDefinition?.id,
            name: dataStreamDefinition?.definition?.name
        }
    };

    const timeframes = useTileTimeframes(
        config.dataStream?.id === 'datastream-sql'
            ? requestConfig.dataStream?.dataSourceConfig?.tables?.map((c) => c?.config)
            : [config]
    );

    const fallbackTimeframe = config.timeframe || dashboardContext.timeframe;

    if (config.dataStream?.id === 'datastream-sql' && requestConfig.dataStream?.dataSourceConfig?.tables) {
        requestConfig = {
            ...requestConfig,
            dataStream: {
                ...requestConfig.dataStream,
                dataSourceConfig: {
                    ...requestConfig.dataStream.dataSourceConfig,
                    tables: requestConfig.dataStream.dataSourceConfig.tables.map((table, i) => ({
                        ...table,
                        config: {
                            ...table?.config,
                            timeframe: timeframes?.[i] || fallbackTimeframe
                        }
                    }))
                }
            }
        };
    }

    const context = useVariableContext(config);
    const hasVariables = dashboardContext.variables?.some((v) => v.selectedObjects.length > 0 || v.selectedAll);
    const disableDueToVariables =
        (dashboardContext.variables?.length ?? 0) > 0 && (config.variables?.length ?? 0) > 0 ? !hasVariables : false;

    const dataStreamRequest =
        dataStreamDefinition?.id == null || !isConfigured || disableDueToVariables
            ? undefined
            : getClientDataStreamRequest(
                  requestConfig,
                  timeframes?.[0] || fallbackTimeframe,
                  dataStreamOptions,
                  context
              );

    const { isGlobal, workspace } = useDataStreamWorkspaceContext();
    const result = useDataStream(dataStreamRequest, isGlobal ? null : workspace, queryOptions);

    return {
        ...result,
        queryKey:
            dataStreamRequest == null
                ? undefined
                : streamDataKeys.forRequest(dataStreamRequest, queryOptions?.extraKeys),
        isConfigured,
        isConfiguredStrict,
        isLoading: streamDefinitionLoading || result.isLoading,
        error: streamDefinitionError ?? errorMessage ?? result.error
    };
}
