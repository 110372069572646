import DropdownMenu from '@/components/DropdownMenu';
import { cn } from '@/lib/cn';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal, { ModalButtons } from 'components/Modal';
import { Button } from 'components/button';
import { FormToggle } from 'components/forms/toggle/FormToggle';
import { useCreateDashboardAndNavigate } from 'components/hooks/useCreateDashboardAndNavigate';
import { DashboardFolder } from 'queries/utils/dashboardSorted';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { v4 as generateId } from 'uuid';
import { MAX_NESTING_DEPTH, folderInitEdit } from './DashboardTreeUtils';
import { useApplyDashboardInstruction } from './useApplyDashboardInstruction';

interface FolderMenuProps {
    className?: string;
    folder: DashboardFolder;
    level: number;
    onEditClick: () => void;
}

export const FolderMenu: React.FC<FolderMenuProps> = ({ className, onEditClick, folder, level, ...props }) => {
    const [deleteOpen, setDeleteOpen] = useState(false);

    const deleteForm = useForm({ defaultValues: { deleteChildren: true } });

    const applyChanges = useApplyDashboardInstruction();

    const { createDashboardAndNavigate } = useCreateDashboardAndNavigate();

    return (
        <>
            <DropdownMenu>
                <DropdownMenu.Trigger
                    onClick={(e) => e.stopPropagation()}
                    asChild={false}
                    className={cn(' cursor-pointer focus-visible:outline outline-2 outline-current ', className)}
                    {...props}
                >
                    <FontAwesomeIcon icon={faEllipsis} />
                </DropdownMenu.Trigger>
                <DropdownMenu.Menu align='start'>
                    <DropdownMenu.Group>
                        <DropdownMenu.Item
                            onClick={(e) => {
                                e.stopPropagation();
                                createDashboardAndNavigate({ folderId: folder.id });
                            }}
                        >
                            Add dashboard
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                            disabled={level >= MAX_NESTING_DEPTH - 1}
                            onClick={(e) => {
                                e.stopPropagation();

                                if (level < MAX_NESTING_DEPTH - 1) {
                                    const newFolder: DashboardFolder = {
                                        id: generateId(),
                                        name: 'New folder',
                                        children: []
                                    };

                                    applyChanges({
                                        instruction: { type: 'add-folder', newFolder },
                                        itemId: folder.id,
                                        targetId: ''
                                    });
                                    folderInitEdit(newFolder);
                                }
                            }}
                        >
                            Add subfolder
                        </DropdownMenu.Item>
                    </DropdownMenu.Group>

                    <DropdownMenu.Separator />

                    <DropdownMenu.Group>
                        <DropdownMenu.Item
                            onClick={(e) => {
                                e.stopPropagation();
                                onEditClick();
                            }}
                        >
                            Rename folder
                        </DropdownMenu.Item>
                    </DropdownMenu.Group>
                    <DropdownMenu.Separator />
                    <DropdownMenu.Group>
                        <DropdownMenu.Item
                            onClick={(e) => {
                                e.stopPropagation();
                                setDeleteOpen(true);
                            }}
                            className='text-statusErrorPrimary'
                        >
                            Delete folder
                        </DropdownMenu.Item>
                    </DropdownMenu.Group>
                </DropdownMenu.Menu>
            </DropdownMenu>

            {deleteOpen && (
                <Modal
                    title={`Delete Folder: ${folder.name}`}
                    close={() => setDeleteOpen(false)}
                    maxWidth='max-w-3xl'
                    fullWidth
                    headerBorder
                    onClick={(e) => e.stopPropagation()}
                >
                    <FormProvider {...deleteForm}>
                        <form
                            onSubmit={deleteForm.handleSubmit(({ deleteChildren }) => {
                                applyChanges({
                                    instruction: { type: 'remove-folder', deleteChildren },
                                    itemId: folder.id,
                                    targetId: ''
                                });
                                setDeleteOpen(false);
                            })}
                        >
                            <div className='flex flex-col px-8 pt-4 space-y-8'>
                                <p className='text-textSecondary'>
                                    Are you sure you want to permanently delete this folder?
                                </p>
                                <FormToggle
                                    name='deleteChildren'
                                    label='Permanently delete the dashboards in this folder'
                                />
                            </div>
                            <ModalButtons>
                                <Button type='button' onClick={() => setDeleteOpen(false)} variant='tertiary'>
                                    Cancel
                                </Button>
                                <Button type='submit' variant='destructive'>
                                    Delete
                                </Button>
                            </ModalButtons>
                        </form>
                    </FormProvider>
                </Modal>
            )}
        </>
    );
};
