import { cn } from '@/lib/cn';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/tooltip/Tooltip';
import pluralize from 'pluralize';

interface SelectedObjectsCountButtonProps {
    totalCount: number;
    interactedObjectsCount: number;
    selectedObjectsCount: number;
    dynamicObjectsCount: number;
    isDynamic: boolean;
    onClick: () => void;
}

export const SelectedObjectsCountButton: React.FC<SelectedObjectsCountButtonProps> = ({
    totalCount,
    interactedObjectsCount,
    selectedObjectsCount,
    dynamicObjectsCount,
    isDynamic,
    onClick
}) => (
    <div className='flex pr-5'>
        <button
            data-testid='tileEditorObjectsSelectedBtn'
            disabled={isDynamic || interactedObjectsCount === 0}
            className={cn('text-textSecondary', !isDynamic && interactedObjectsCount !== 0 && 'hover:text-textPrimary')}
            onClick={onClick}
        >
            {isDynamic
                ? `${dynamicObjectsCount} ${pluralize('object', selectedObjectsCount)}`
                : `${selectedObjectsCount}/${totalCount} selected`}
        </button>
        {!isDynamic && (
            <Tooltip className='ml-2' title='Hold shift to select multiple objects'>
                <FontAwesomeIcon className='text-textSecondary opacity-70' fixedWidth icon={faQuestionCircle} />
            </Tooltip>
        )}
    </div>
);
