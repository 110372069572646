import { fiveMinutes } from 'queries/constants';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { ListForAdministration } from 'services/WorkspaceService';

type QueryReturnType = Awaited<ReturnType<typeof ListForAdministration>>;
type QuerySelectReturnType = QueryReturnType;

/**
 * useQuery returning all workspaces for administration
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of all workspaces in a tenant
 */
export const useWorkspacesForAdministration = (
    options?: UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>
) => 
    useQuery(
        workspaceQueryKeys.forAdministration, 
        ListForAdministration, 
        {
            staleTime: fiveMinutes,
            cacheTime: fiveMinutes,
            keepPreviousData: true,
            ...options
        }
    );
