/* eslint-disable no-console */
import Config from '../../config';
import { useTenant } from 'queries/hooks/useTenant';
import { useEffect, useState } from 'react';
import { TenantResponse } from 'services/TenantService';
import { ErrorToastOptions } from 'services/util';

const AppVersion = Config.Stage;
const MaxReloadAttempts = 5;

/**
 * Reload the app if required due to tenant configuration that affects what is downloaded
 * from CloudFront.
 * 
 * If a tenant object is returned the caller can assume the app is good and it's safe to continue.
 *
 * If a tenant is not returned then we are still checking, or we've checked and the
 * version is wrong and a reload of the app has been kicked off. Either way, the caller should
 * suspend all activity unless/until it gets back a tenant object.
 *
 * There are currently two settings that can require a reload:
 * 
 *    - Tenant stage. This is effectively the version of the app, so if this is wrong we
 *      need to ask for the correct stage/version of the app from CloudFront.
 * 
 *    - CSP header. A tenant can have a customised content security policy. This must be 
 *      configured in the CSP header when downloading the app index.html from CloudFront. 
 * 
 * In both cases if CloudFront has not given us the flavour of the app we want, we use cookies
 * to ask for what we need and then trigger a reload to get it.
 */
export const useEnsureAppConfiguration = (): { tenant?: TenantResponse } => {
    const { data: tenant } = useTenant();
    const [isAppReloadInProgress, setAppReloadInProgress] = useState(false);
    const [isConfigChecked, setConfigChecked] = useState(false);

    useEffect(() => {
        if (tenant && !isConfigChecked) {
            const reloadForStage = isAppReloadRequiredForStage(tenant.stage);
            const reloadForCSP = isAppReloadRequiredForCSP(tenant);

            if (reloadForStage || reloadForCSP) {
                setAppReloadInProgress(true);
                const success = reloadApp(tenant, reloadForStage, reloadForCSP);
                if (!success) {
                    // Couldn't initiate reload, perhaps due to environmental issues stopping
                    // us setting a cookie. Don't just leave the user hanging - allow them to continue
                    // with this version/configuration of the app.
                    setAppReloadInProgress(false);
                }
            }

            setConfigChecked(true);
        }

    }, [tenant, isConfigChecked]);

    return {
        tenant: isConfigChecked && !isAppReloadInProgress ? tenant : undefined
    };
};

/**
 * Returns true if an app reload is required to change the version of the app to match the tenant stage.
 */
function isAppReloadRequiredForStage(tenantStage: string | undefined): boolean {
    // Blue/green deployments only apply to PP/PROD.
    const shouldCheckStage = tenantStage && !Config.Environment.match(/^(Development|Local)$/u);

    if (!shouldCheckStage) {
        return false;
    }

    const stageMismatch = AppVersion && tenantStage && AppVersion !== tenantStage;

    if (stageMismatch && getCookieValue('stage') === tenantStage && getReloadAttempts() >= MaxReloadAttempts) {
        // Client doesn't match tenant stage, but we've previously set the cookie to the right stage
        // and it didn't change the client version, perhaps because the client version is too old and
        // no longer available. To avoid getting into an infinite loop we'll just give up with switching
        // and use the current version of the app.
        //
        console.log(
            `Stage mismatch but cookie already matches tenant stage [${tenantStage}] and max attempts (${MaxReloadAttempts}) reached, so giving up on app version switch and using this app version [${AppVersion}].`
        );
        setReloadAttempts(0);
        return false;
    }

    const reloadAttempts = getReloadAttempts();

    if (stageMismatch) {
        console.log(`Stage mismatch - client version is ${AppVersion}, tenant stage is ${tenantStage}. Reload attempts = ${reloadAttempts}`);
        const success = setReloadAttempts(reloadAttempts + 1);
        if (!success) {
            console.log(`Reload attempts cookie could not be set. Risk of infinite reload loop, so giving up on app version switch and using this app version [${AppVersion}].`);
            return false;
        }
        return true;
    }

    console.log(
        `Stage match so no reload is required - client version is ${AppVersion}, tenant stage is ${tenantStage}. Reload attempts = ${reloadAttempts}`
    );
    setReloadAttempts(0);
    return false;
}

/**
 * Returns true if an app reload is required to ensure the app index.html has the required CSP header.
 */
function isAppReloadRequiredForCSP(tenant: TenantResponse): boolean {
    // Note that CSP only applies to PP/PROD (no CloudFront in DEV), so we could return false here for DEV.
    // However nothing here will cause any *problems* in DEV - the cookie we set will just be ignored -
    // so for the sake of consistency and finding issues before they hit production, we'll run this check in DEV too.

    const existingTenantCookie = getCookieValue('tenant');
    if (!existingTenantCookie && !tenant.settings?.cspFrameSrcEntries?.length) {
        // CloudFront wasn't asked for tenant CSP settings, and our tenant doesn't have any customisations,
        // so current app (with default CSP) is fine.
        return false;
    }

    if (existingTenantCookie === tenant.id) {
        // CloudFront was told the correct tenant, so we should have the correct CSP settings already.
        return false;
    }

    return true;
}

/**
 * Reload the app to get a new version from CloudFront configured to our requirements.
 * 
 * Returns false if the reload could not be completed (e.g. failure to set the required cookie)
 */
function reloadApp(tenant: TenantResponse, reloadForStage: boolean, reloadForCSP: boolean): boolean {
    if (reloadForStage) {
        const success = setStageCookie(tenant.stage);
        if (!success) {
            console.log('Failed to set stage cookie, so abandoning reload');
            return false;
        }
    }

    if (reloadForCSP) {
        const success = setCSPCookie(tenant.id);
        if (!success) {
            console.log('Failed to set CSP cookie, so abandoning reload');
            return false;
        }
    }

    ErrorToastOptions.SuppressAll = true; // Don't show errors from cancelled API requests
    window.location.reload();
    return true;
}

function setStageCookie(stage: string | undefined): boolean {
    if (!stage) {
        return false;
    }

    console.log(
        `App stage [${AppVersion}] does not match tenant stage [${stage}], so reloading app after setting cookie.`
    );

    // Set a cookie that tells CloudFront which version of the app we want, because it didn't give 
    // us the version we wanted.
    //
    // This could happen in one of two scenarios:
    //
    //     1. We didn't have a cookie set so CloudFront gave us its default version (normally GA) and that wasn't
    //        what we wanted.
    //
    //  OR 2. We had a cookie set already so CloudFront gave us that version (e.g. old RC), but that wasn't the version 
    //        we wanted (e.g. a newer RC) 
    //
    // We only set the cookie for a few hours so that we don't pointlessly download stale versions of the app
    // in future, or leave old cookies around for long periods that might trip us up later.
    //
    // Note that customers on the GA release usually just want the default CloudFront version (which is what you
    // get when no cookie is used), which is usually the GA version.  
    // So we shouldn't get here most of the time for standard customers, because CloudFront will give them what they
    // want by default.
    // If we do get here for a GA tenant then the cookie will only last a few hours, then the client will go back 
    // to not needing a cookie again as soon as the tenant stage is back in sync with CloudFront.
    //
    // By contrast RC tenants will come through here regularly.  They rely on the cookie to load a 
    // newer version than CloudFront's default.  So they will do this reload-to-switch every day (from GA to RC).
    // We could keep the cookie longer to reduce the number of switches in some cases for RC tenants, but the RC 
    // version changes so frequently (often multiple times per day) it's not worth worrying about.
    //
    const maxAgeSeconds = 60 * 60 * 12; // 12 hours
    return setCookie('stage', stage, maxAgeSeconds);
}

function setCSPCookie(tenantId: string | undefined): boolean {
    if (!tenantId) {
        return false;
    }

    console.log('CSP settings may not match tenant settings so reloading app after setting cookie.');

    // Set a cookie that tells CloudFront which tenant we are displaying, so it can set the CSP header for
    // the app index.html correctly.
    //
    // This could be required in one of these scenarios:
    //
    //     1. We didn't have a cookie set so CloudFront gave us its default CSP, but the tenant has CSP customisation
    //        that must be included in the index.html CSP header.
    //
    //  OR 2. We had a cookie set already so CloudFront gave us the CSP settings for that tenant, but we've 
    //        switched to a different tenant which may have different CSP settings.
    //
    const maxAgeSeconds = 60 * 60 * 24 * 30; // 30 days - If client switches tenant we'll update the cookie, so can be long-lived.
    return setCookie('tenant', tenantId, maxAgeSeconds);
}

function getCookieValue(name: string) {
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
}

/**
  * Set the number of reload attempts.
  * 
  * This is for client use only so arguably should be in local or session storage rather than a cookie,
  * but cookie is safer (supported in scenarios where storage isn't) and easier for diagnostics to have in
  * the same place as the stage cookie.
 */
function setReloadAttempts(reloadAttempts: number | undefined): boolean {
    const maxAgeSeconds = 120; // Should only need for a couple of minutes while we're attempting reloads.
    return setCookie('stageReloadAttempts', `${reloadAttempts || 0}`, maxAgeSeconds);
}

function getReloadAttempts(): number {
    const reloadAttemptsCookie = getCookieValue('stageReloadAttempts');
    return Number.parseInt(reloadAttemptsCookie) || 0;
}

function setCookie(name: string, value: string, maxAgeSeconds: number): boolean {
    const isHttp = window.location.protocol === 'http:';

    // Require secure connection (https) to set cookie (unless we're running in DEV over http).
    const secure = isHttp ? '' : ' Secure;';

    // Note SameSite=None rather than SameSite=Strict or Lax. This is because we want the cookie to be sent in 
    // cross-origin scenarios like redirect from B2C and link from another site / email (which requires 'Lax').
    // But also we want it to work in an OA iframe embedding scenario, which requires dialing it all the way 
    // back to 'None'. This shouldn't be a security issue as the stage is not in any way sensitive.
    // 
    // Note however that we cannot use 'SameSite:None' with Chrome in DEV, because Chrome doesn't seem to 
    // allow SameSite:None without 'Secure;'
    //
    const sameSite = isHttp ? 'Lax' : 'None';

    document.cookie = `${name}=${value}; SameSite=${sameSite};${secure} path=/; Max-Age=${maxAgeSeconds}`;
    const cookieValueAfterSet = getCookieValue(name);
    console.log(`Cookie '${name}' set to ${cookieValueAfterSet}`);

    // If we can't read the cookie after we set it, it's probably because it wasn't set for some reason.
    // Return false in that case so the caller is aware.
    return cookieValueAfterSet === value;
}