import { cn } from '@/lib/cn';
import { animate, AnimationPlaybackControls, motion } from 'framer-motion';
import useFitText from 'lib/useFitText';
import { useEffect, useRef, useState } from 'react';
import usePrefersReducedMotion from '../../../lib/usePrefersReducedMotion';
import { DonutCenterValueProps } from './Config';

const ANIMATION_DURATION = 0.3;

function DonutCenterValue({
    value,
    formatValue,
    formatPercentage,
    showValuesAsPercentage,
    showPercentage,
    totalValue,
    className,
    style
}: DonutCenterValueProps) {
    const [isFinished, setIsFinished] = useState(false);
    const animation = useRef<AnimationPlaybackControls>();
    const prevState = useRef({ value, showPercentage });
    const preferedReducedMotion = usePrefersReducedMotion();

    const {
        fontSize,
        fontSizeValue,
        ref: counterRef
    } = useFitText({
        maxFontSize: 2000,
        onFinish: () => setIsFinished(true)
    });

    const {
        fontSize: fontSizePercentage,
        fontSizeValue: fontSizePercentageValue,
        ref: percentageRef
    } = useFitText({
        maxFontSize: 2000
    });

    const [formattedValue, setFormattedValue] = useState<string>(formatValue(prevState.current));

    useEffect(() => {
        // stop previous animation overlapping this
        if (animation.current?.isAnimating()) {
            animation.current.stop();
        }

        // skip animation if value didn't change
        if (value === prevState.current.value) {
            prevState.current = { value, showPercentage };
            setFormattedValue(showPercentage ? formatPercentage(value) : formatValue(value));
            return;
        }

        animation.current = animate(prevState.current.value, value, {
            duration: preferedReducedMotion ? 0 : ANIMATION_DURATION,
            onUpdate(currentValue) {
                const valueFormatted = formatValue(currentValue);

                // Skip if not using percentage mode
                if (!showValuesAsPercentage) {
                    setFormattedValue(valueFormatted);
                    return;
                }

                const percentCompleted = 1 - Math.abs(value - currentValue) / Math.abs(value - prevState.current.value);

                /**
                 * only start showing percentage formatting when we are 80% done
                 * or previous value was a percentage, smoothing out animation.
                 */
                if (showPercentage && (percentCompleted > 0.8 || prevState.current.showPercentage)) {
                    setFormattedValue(formatPercentage(currentValue));
                } else {
                    setFormattedValue(valueFormatted);
                }
            },
            onComplete() {
                prevState.current = { value, showPercentage };
            }
        });
    }, [value, formatValue, formatPercentage, preferedReducedMotion, showValuesAsPercentage, showPercentage]);

    const textScaling = showPercentage ? Math.min(fontSizePercentageValue / fontSizeValue, 1) : 1;

    /**
     * We render the total value in an invisible div to get the correct font size
     * By using the total it's always the largest value for non-percentage mode donuts
     * If using percentage mode, we have to scale down the percentage value to fit when the total is smaller than 100
     * So use a separate font size for the percentage value
     */
    return (
        <>
            <div ref={counterRef} className={cn(className, 'invisible')} style={{ ...style, fontSize }}>
                <p className='font-semibold whitespace-nowrap'>{formatValue(totalValue)}</p>
            </div>
            <div
                ref={percentageRef}
                className={cn(className, 'invisible')}
                style={{ ...style, fontSize: fontSizePercentage }}
            >
                {showValuesAsPercentage && <p className='font-semibold whitespace-nowrap'>{formatPercentage(value)}</p>}
            </div>
            <motion.div
                className={cn(className, 'origin-center')}
                style={{
                    ...style,
                    fontSize,
                    opacity: isFinished ? 1 : 0
                }}
                transition={{ duration: ANIMATION_DURATION }}
                animate={{ scale: textScaling }}
            >
                <p className='font-semibold whitespace-nowrap'>{formattedValue}</p>
            </motion.div>
        </>
    );
}

export default DonutCenterValue;
