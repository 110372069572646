import { cn } from '@/lib/cn';
import { Node } from '@squaredup/graph';
import { useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';

interface VariableObjectListProps {
    objects: Node[];
    totalCount: number;
    resetScrollKey: string;
    hasNextObjectsPage?: boolean;
    isFetchingNextObjectsPage: boolean;
    isFetchingObjects: boolean;
    fetchNextObjectsPage: () => void;
    renderObjectRow: (object: Node, index: number) => React.ReactNode;
}

export const VariableObjectList: React.FC<VariableObjectListProps> = ({
    objects,
    totalCount,
    resetScrollKey,
    hasNextObjectsPage,
    isFetchingNextObjectsPage,
    isFetchingObjects,
    fetchNextObjectsPage,
    renderObjectRow
}) => {
    const scrollRef = useRef<HTMLDivElement>(null);

    const currentObjectsCount = objects.length;

    const rowVirtualizer = useVirtualizer({
        count: hasNextObjectsPage ? currentObjectsCount + 1 : currentObjectsCount,
        overscan: 25,
        getScrollElement: () => scrollRef.current,
        estimateSize: () => 32 // Height of each row in px
    });

    const virtualItems = rowVirtualizer.getVirtualItems();

    useEffect(() => {
        // When a filter is changed, scroll the user to the first item in the list
        if (virtualItems.length > 0) {
            rowVirtualizer.scrollToIndex(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetScrollKey]);

    useEffect(() => {
        const [lastItem] = [...virtualItems].reverse();

        if (!lastItem) {
            return;
        }

        const nextPageBuffer = 6;
        if (lastItem.index >= objects.length - nextPageBuffer && hasNextObjectsPage && !isFetchingNextObjectsPage) {
            fetchNextObjectsPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasNextObjectsPage, fetchNextObjectsPage, objects?.length, isFetchingNextObjectsPage, virtualItems]);

    return (
        <div
            className={cn({
                'h-[320px] overflow-hidden': totalCount > 10
            })}
        >
            <div
                ref={scrollRef}
                className={clsx(
                    'min-h-0 pr-4 h-full overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary',
                    isFetchingObjects && 'pointer-events-none'
                )}
            >
                <div className='relative w-full' style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                    {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                        const object = objects[virtualItem.index];
                        return (
                            <div
                                key={virtualItem.key.toString()}
                                className='absolute top-0 left-0 w-full'
                                style={{
                                    height: `${virtualItem.size}px`,
                                    transform: `translateY(${virtualItem.start}px)`
                                }}
                            >
                                {object && renderObjectRow(object, virtualItem.index)}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
