import { Checkbox } from '@/components/forms/Checkbox';
import { cn } from '@/lib/cn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Node } from '@squaredup/graph';
import { SqUpPluginDisplayName } from '@squaredup/ids';
import clsx from 'clsx';
import { TruncatedText } from 'components/TruncatedText';
import { getIconForNode, getNameForType } from 'lib/types';
import PluginIcon from 'pages/scope/PluginIcon';
import { MouseEvent } from 'react';

interface ObjectOptionProps {
    object: Node;
    isActive?: boolean;
    readOnly?: boolean;
    checkboxDisabled?: boolean;
    className?: string;
    filterPropertiesColumns: string[];
    pluginLookup?: Map<string, { pluginName: string; sourceName: string }>;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

interface ObjectOptionColumnProps {
    className?: string;
}

export const ObjectOptionColumn: React.FC<ObjectOptionColumnProps> = ({ children, className }) => (
    <span className={cn(className, 'flex items-center space-x-2')}>{children}</span>
);

export const ObjectOption: React.FC<ObjectOptionProps> = ({
    object,
    isActive,
    readOnly,
    className,
    checkboxDisabled,
    filterPropertiesColumns,
    pluginLookup,
    onClick
}) => {
    const { __configId, name, sourceType } = object;
    const { pluginName, sourceName } = pluginLookup?.get(__configId![0]) || {};

    const isSqUpObject = sourceType?.[0]?.includes('squaredup/');
    const disabled = !onClick;

    return (
        <button
            className={clsx(
                'grid w-full text-left self-stretch p-1 px-2 gap-6',
                isActive ? 'font-bold text-textPrimary' : 'text-textSecondary',
                className
            )}
            style={{
                gridTemplateColumns: `minmax(10rem, 1fr) repeat(${2 + filterPropertiesColumns.length}, minmax(0, 1fr)) 0.875rem`
            }}
            onClick={onClick}
            disabled={disabled || checkboxDisabled}
            data-testid='objectOption' 
        >
            <ObjectOptionColumn>
                {!disabled && !readOnly && <Checkbox checked={isActive} disabled={checkboxDisabled} />}

                <TruncatedText title={name[0]} className='flex-1 min-w-0' />
            </ObjectOptionColumn>

            {(sourceName || isSqUpObject) && (
                <ObjectOptionColumn>
                    <span className='flex-shrink-0 w-5 h-5'>
                        <PluginIcon pluginName={pluginName || SqUpPluginDisplayName} />
                    </span>
                    <TruncatedText title={sourceName || SqUpPluginDisplayName} className='flex-1 min-w-0' />
                </ObjectOptionColumn>
            )}

            <ObjectOptionColumn className={cn(!filterPropertiesColumns?.length && 'col-span-2')}>
                <span className='flex items-center justify-center w-5 h-5'>
                    <FontAwesomeIcon icon={getIconForNode(object)} className='flex-shrink-0' />
                </span>
                <TruncatedText
                    title={object.type?.map(type => getNameForType(type || '', 'singular'))?.join(', ') || 'Unknown'}
                    className='flex-1 min-w-0'
                />
            </ObjectOptionColumn>

            {filterPropertiesColumns?.map((property: keyof typeof object, index) => {
                const propertyValue = String(object[property]?.[0] || '');

                return (
                    <ObjectOptionColumn className={cn(index === filterPropertiesColumns?.length - 1 && 'col-span-2')}>
                        <TruncatedText title={propertyValue} className='flex-1 min-w-0' />
                    </ObjectOptionColumn>
                );
            })}
        </button>
    );
};
