import { buildQuery, Node } from '@squaredup/graph';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import stringify from 'fast-json-stable-stringify';
import { useQuery } from 'react-query';
import { Query } from 'services/GraphService';

/**
 *
 * @param interactedObjects
 * @param page
 * @returns List of interacted objects in relation to SelectedObjectsPanel.tsx.
 * An interacted object is an object that at one point has been selected when SelectedObjectsPanel is open,
 * this means it can be deselected and still be visible until the panel is closed;
 * at which point that deselected object is popped off the stack.
 * When an object is added/popped the query will update to return those new objects
 */
export const useSelectedObjects = (interactedObjects: string[]) => {
    const { isGlobal } = useDataStreamWorkspaceContext();

    const fetchObjects = async () => {
        if (interactedObjects.length > 0) {
            const objectsQuery = `
                    .order()
                        .by("__configId")
                        .by("__search")
                        .valueMap(true)
                            .fold()
            `;
            const { gremlinQueryResults } = await Query(
                buildQuery({ ids: interactedObjects }, objectsQuery, { limit: true }),
                isGlobal ? 'directOrAnyWorkspaceLinks' : undefined
            );

            return gremlinQueryResults[0] as Node[];
        } else {
            return [];
        }
    };

    const { data: objects, isLoading } = useQuery(['objects', interactedObjects.join(','), stringify(isGlobal)], fetchObjects, {
        keepPreviousData: true
    });

    return { objects, isLoading };
};
