/**
 * useQuery keys for usage data
 */
export const USER_COUNT_KEY = 'USER_COUNT';
export const NODE_COUNT_KEY = 'NODE_COUNT';
export const REQUEST_COUNT_KEY = 'REQUEST_COUNT';

const USAGE_PAGE = 'USAGE_PAGE';
const MONITORS = 'MONITOR_COUNT';
const WORKSPACES = 'WORKSPACE_COUNT';
const DASHBOARDS = 'DASHBOARD_COUNT';
const REQUESTS = 'REQUEST_DETAIL_COUNT';

export const UsageQueryKeys = {
    All: [USAGE_PAGE],
    Users: [USAGE_PAGE, USER_COUNT_KEY], 
    Nodes: [USAGE_PAGE, NODE_COUNT_KEY],
    Monitors: [USAGE_PAGE, MONITORS],
    Workspaces: [USAGE_PAGE, WORKSPACES],
    Dashboards: [USAGE_PAGE, DASHBOARDS],
    Requests: [USAGE_PAGE, REQUESTS]
};
