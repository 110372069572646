import NotFound from 'pages/not-found/NotFound';
import { Route, Routes } from 'react-router-dom';
import GraphNodeDrilldown from './graph-node/GraphNodeDrilldown';
import { SearchForGraphNode } from './graph-node/SearchForGraphNode';

/**
 * Selects the appropriate drilldown component to render
 */
function Drilldown() {
    return (
        <Routes>
            <Route path='node/:id' element={<GraphNodeDrilldown />} />
            <Route path='node' element={<SearchForGraphNode />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
}

export default Drilldown;
