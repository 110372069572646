import { UseQueryOptions, useQuery } from 'react-query';
import { ListPluginSourceConfigForWorkspace } from 'services/SourceConfigService';

export type ListPluginSourceConfigForWorkspaceQueryReturnType = Awaited<
    ReturnType<typeof ListPluginSourceConfigForWorkspace>
>;

export const LINKED_PLUGINS = 'LINKED_PLUGINS';

export const useLinkedPluginConfigs = <T = ListPluginSourceConfigForWorkspaceQueryReturnType>(
    workspaceId: string | null | undefined,
    options?: UseQueryOptions<ListPluginSourceConfigForWorkspaceQueryReturnType, unknown, T, string[]>
) =>
    useQuery([LINKED_PLUGINS, workspaceId!], () => ListPluginSourceConfigForWorkspace(workspaceId!), {
        ...options,
        enabled: Boolean(workspaceId)
    });
