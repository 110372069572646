import { Serialised } from '@squaredup/ids';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/button/Button';
import { SuggestedSearchResult } from './SuggestedSearchResult';
import type { ProjectedPlugin } from 'dynamo-wrapper';

interface SuggestionFooterProps {
    onSelect?: (plugin: Serialised<ProjectedPlugin>) => void;
    plugins?: Serialised<ProjectedPlugin>[];
    excludedPlugins?: Serialised<ProjectedPlugin>[];
}

export function SuggestionFooter({ onSelect, plugins, excludedPlugins }: SuggestionFooterProps) {
    return (
        <footer>
            <h5 className='mb-4 text-2xl font-semibold'>Can&apos;t see what you&apos;re looking for?</h5>
            {plugins && (
                <SuggestedSearchResult
                    onSelect={onSelect}
                    plugins={plugins || []}
                    excludedPlugins={excludedPlugins}
                />
            )}
            <Button
                href='https://feedback.squaredup.com/plugins'
                variant='secondary'
                icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
            >
                Suggest a data source
            </Button>
        </footer>
    );
}
