import { flattenedDashboardsSortedByWorkspaceOrder } from 'queries/utils/dashboardSorted';
import { useMemo } from 'react';
import { useDashboards } from './useDashboards';
import { useWorkspaces } from './useWorkspaces';

export const useDashboardsWithFolders = () => {
    const workspaces = useWorkspaces();
    const dashboards = useDashboards();

    const isLoading = workspaces.isLoading || dashboards.isLoading;

    const data = useMemo(() => {
        if (isLoading) {
            return undefined;
        }

        const dashboardsByWorkspace = Object.groupBy(dashboards.data || [], ({ workspaceId }) => workspaceId);

        return workspaces.data?.flatMap((workspace) => {
            return flattenedDashboardsSortedByWorkspaceOrder(workspace.data.properties?.dashboardIdOrder)(
                dashboardsByWorkspace[workspace.id] ?? []
            );
        });
    }, [workspaces.data, dashboards.data, isLoading]);

    return { isLoading, data };
};
