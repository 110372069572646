import { WorkspaceTypes, dropdownWorkspaceLandingPageOptions } from '@squaredup/constants';
import { Presence } from 'components/Presence';
import Field from 'components/forms/field/Field';
import { upperFirst } from 'lodash';
import { useEffect } from 'react';
import { useTags } from 'services/TagService';
import { WorkspaceAvatarPicker } from './WorkspaceAvatarPicker';

type Props = {
    modalType: 'create-workspace' | 'edit-workspace';
};

export const WorkspaceModalGeneralTab = (props: Props) => {
    const tags = useTags();

    useEffect(() => {
        const displayNameInput = document.getElementById('displayName');

        if (!displayNameInput) {
            throw new Error('displayName input is missing from the dom');
        }

        displayNameInput.focus();
    }, []);

    return (
        <div className='grid grid-cols-[1fr_auto] gap-x-10'>
            <Field.Input
                name='displayName'
                label='Name'
                title='Name'
                placeholder='Enter a name'
                validation={{ required: true, maxLength: 128, minLength: 2 }}
            />

            <Field.Input
                name='description'
                label='Description'
                placeholder='Enter a description'
                validation={{ required: false, maxLength: 255 }}
            />

            <Field.Input
                name='type'
                type='autocomplete'
                label='Type'
                placeholder='Select a type'
                help='A type is used to organize your workspaces and for filtering purposes.'
                helpAlign='Left'
                options={typeOptions}
                isMulti={false}
            />

            <Field.Input
                type='autocomplete'
                name='tags'
                label='Tags'
                help='Tags are used to organize your workspaces and for filtering purposes.'
                helpAlign='Left'
                options={tags.data?.map((tag) => ({ label: tag, value: tag })) || []}
                onCreate={(v: string, l: string) => ({
                    label: l.trim(),
                    value: v.trim()
                })}
                isValidNewOption={(inputValue: string) => {
                    return inputValue.trim().length <= 128 && inputValue.trim().length > 0;
                }}
                noOptionsMessage={() => 'No tags were found'}
                placeholder='Search or enter one or more tags'
                formatCreateLabel={(label: string) => `Create: ${label}`}
                validation={{
                    validate: (unsafeTags: string[]) => {
                        if (!unsafeTags) {
                            return false;
                        }

                        return unsafeTags.length < 33 || 'maximum of 32 tags allowed';
                    }
                }}
            />

            <Presence isPresent={props.modalType === 'edit-workspace'}>
                <Field.Input
                    name='landingPage'
                    type='autocomplete'
                    label='Default page'
                    placeholder='Select a default page'
                    help='Define the page that is shown when navigating to this workspace.'
                    helpAlign='Left'
                    options={dropdownWorkspaceLandingPageOptions}
                    isMulti={false}
                    isClearable={false}
                />
            </Presence>

            <div className='flex flex-col gap-1 max-w-[9.15rem] col-start-2 row-start-1 row-end-5'>
                <Field label='Icon'>
                    <WorkspaceAvatarPicker />
                </Field>
            </div>
        </div>
    );
};

const typeOptions = WorkspaceTypes?.sort((a, b) => {
    if (a === 'other') {
        return 1;
    } else if (b === 'other') {
        return -1;
    }
    return a.localeCompare(b);
}).map((type) => ({
    label: upperFirst(type.toLowerCase()),
    value: type
}));
