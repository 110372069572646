import type { HealthState, MonitorLogicConditionConfig } from '@squaredup/monitoring';
import { MonitorConfig, VisualisationMonitorConditions } from 'dashboard-engine/types/Monitoring';
import { capitalize } from 'lodash';
import { PartialDeep } from 'type-fest';

const parseMonitorCondition = (
    state: string | undefined,
    condition?: PartialDeep<MonitorLogicConditionConfig>
): VisualisationMonitorConditions => {
    if (state == null || condition == null || (state !== 'error' && state !== 'warning')) {
        return {};
    }

    // The first element in the condition array is the variable, which we don't need here
    // Instead, pick the second element which is the value for the threshold
    const lt = condition?.['<']?.[1];
    const gt = condition?.['>']?.[1];
    if (lt != null && typeof lt === 'number') {
        return {
            [state]: {
                value: lt,
                type: 'lt'
            }
        };
    } else if (gt != null && typeof gt === 'number') {
        return {
            [state]: {
                value: gt,
                type: 'gt'
            }
        };
    }
    return {};
};

export const parseMonitorConfig = (config?: PartialDeep<MonitorConfig>) => {
    const logic = config?.condition?.logic?.if;
    if (config?.monitorType === 'threshold' && logic) {
        const [firstCondition, firstConditionState, secondCondition, secondConditionState] = logic;

        return {
            ...parseMonitorCondition(firstConditionState, firstCondition),
            ...parseMonitorCondition(secondConditionState, secondCondition)
        };
    }
};

export const healthReasonMessage = (state?: HealthState, reason?: string) =>
    reason ? `${capitalize(state)}: ${reason}` : capitalize(state);
