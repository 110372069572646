import { TooltipButton } from '@/components/Button';
import { faLock, faLockOpen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHandleFixedNodePosition } from 'components/map/hooks/useHandleFixedNodePosition';

interface FixButtonProps {
    id: string;
    isFixed?: boolean;
    className?: string;
}

export const FixButton: React.FC<FixButtonProps> = ({ id, isFixed, className }) => {
    const { fixPosition, releasePosition } = useHandleFixedNodePosition();

    return (
        <TooltipButton
            variant='tertiary'
            onClick={(e) => {
                e.stopPropagation();
                isFixed ? releasePosition(id) : fixPosition(id);
            }}
            title={isFixed ? 'Release position' : 'Fix position'}
            className={className}
        >
            <FontAwesomeIcon icon={isFixed ? faLock : faLockOpen} fixedWidth />
        </TooltipButton>
    );
};
