import { TooltipButton } from '@/components/Button';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface EditFilterOptionWrapperProps {
    tooltipTitle: string;
    disabled?: boolean;
    onClick?: () => void;
}

export const EditFilterOptionWrapper: React.FC<EditFilterOptionWrapperProps> = ({
    tooltipTitle,
    disabled,
    children,
    onClick
}) => (
    <div className='flex h-5 group max-w-full'>
        {children}
        {!disabled && (
            <TooltipButton
                variant='tertiary'
                icon={<FontAwesomeIcon icon={faPencil} fixedWidth={true} />}
                title={tooltipTitle}
                className='ml-2 hidden group-hover:block text-textSecondary hover:text-textPrimary'
                onClick={onClick}
            />
        )}
    </div>
);
