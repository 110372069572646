import { MATCHES_ADVANCED, MATCHES_ALL, MATCHES_NONE } from '../TileEditor/constants';

export const getMatchTypes = (matches: any, sourceTypesToTypes?: Map<string, string>): string[] => {
    if (!sourceTypesToTypes) {
        return [];
    }

    let matchesTypes: (string | undefined)[] = [];

    // Better to use a schema to resolve the values than this bunch of horrible ifs!!
    // TODO: Something better here!
    if (matches === 'all' || (typeof matches === 'object' && Object.keys(matches).length === 0)) {
        matchesTypes = MATCHES_ALL;
    }

    if (matches === 'none') {
        matchesTypes = MATCHES_NONE;
    }

    if (Array.isArray(matches)) {
        matchesTypes = matches.flatMap(match => {
            return getMatchTypes(match, sourceTypesToTypes);
        });
    }

    // If the match criteria doesn't include sourcetype or type (or their legacy keys) we know it's some other
    // match type so we declare it as a MATCHES_ADVANCED
    if (
        typeof matches === 'object' &&
        !Array.isArray(matches) &&
        Object.keys(matches).length > 0 &&
        !Object.keys(matches).some(matchType => ['sourceType', 'sourceType.0', 'type', 'type.0'].includes(matchType))
    ) {
        matchesTypes = MATCHES_ADVANCED;
    }

    if (typeof matches?.['type'] === 'string') {
        matchesTypes = [matches['type']];
    }

    if (typeof matches?.['sourceType'] === 'string') {
        matchesTypes = [sourceTypesToTypes?.get(matches['sourceType'])];
    }

    if (matches?.['type']?.type === 'oneOf') {
        matchesTypes = matches.type.values;
    }

    if (matches?.['type']?.type === 'equals') {
        matchesTypes = [matches.type.value];
    }

    if (matches?.['sourceType']?.type === 'oneOf') {
        matchesTypes = matches.sourceType.values.flatMap((sourceType: string) => sourceTypesToTypes?.get(sourceType));
    }

    if (matches?.['sourceType']?.type === 'equals') {
        matchesTypes = [sourceTypesToTypes.get(matches['sourceType'].value)];
    }

    // Legacy '.0' properties handled here
    if (matches?.['type.0']) {
        if (matches?.['type.0'].type) {
            if (matches?.['type.0']?.type === 'oneOf') {
                matchesTypes = matches['type.0'].values;
            }

            if (matches?.['type.0']?.type === 'equals') {
                matchesTypes = [matches['type.0'].value];
            }
        } else {
            matchesTypes = [matches['type.0'].value];
        }
    }

    if (matches?.['sourceType.0']) {
        if (matches?.['sourceType.0'].type) {
            if (matches?.['sourceType.0']?.type === 'oneOf') {
                matchesTypes = matches['sourceType.0'].values.flatMap((sourceType: string) =>
                    sourceTypesToTypes.get(sourceType)
                );
            }

            if (matches?.['sourceType.0']?.type === 'equals') {
                matchesTypes = [sourceTypesToTypes.get(matches['sourceType.0'].value)];
            }
        } else {
            matchesTypes = [sourceTypesToTypes.get(matches['sourceType.0'].value)];
        }
    }

    return matchesTypes.filter(Boolean) as string[];
};
