import { WORKSPACE_NODE, WORKSPACE_NODE_SIZE } from 'components/map/components/nodes/WorkspaceNode';

export const workspaceToNode = (workspace: any) => {
    return {    
        id: workspace.id,
        type: WORKSPACE_NODE,
        position: {
            x: 0,
            y: 0
        },
        width: WORKSPACE_NODE_SIZE,
        height: WORKSPACE_NODE_SIZE,
        data: {
            label: workspace.name[0],
            workspaceId: workspace.sourceId[0]
        }
    };
};
