export enum DataStreamTableStyleModes {
    default,
    transpose
}
type DataStreamTableStyles = {
    [key in DataStreamTableStyleModes]: {
        table?: string;
        thead?: string;
        tbody?: string;
        theadtr?: string;
        tr?: string;
        th?: string;
        td?: string;
    };
};

export const styles: DataStreamTableStyles = {
    [DataStreamTableStyleModes.default]: {
        thead: 'sticky top-0 block border-y border-dividerPrimary',
        theadtr: 'divide-x divide-dividerSecondary',
        tbody: 'block border-dividerPrimary relative',
        tr: 'divide-x divide-dividerPrimary w-full last:border-b border-dividerPrimary',
        td: 'first:border-l last:!border-r border-dividerPrimary'
    },
    [DataStreamTableStyleModes.transpose]: {
        table: 'flex',
        theadtr: 'flex-col border-dividerSecondary border-t',
        th: 'block px-4 py-2 border-dividerSecondary border-b border-l',
        tbody: 'flex relative w-auto',
        tr: 'flex-1 flex-col border-dividerPrimary h-full border-r border-t',
        td: 'block border-dividerPrimary border-b'
    }
};
