import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';
import { getScopeTitle } from 'dashboard-engine/util/graphDataToNetworkData';
import { useMutation, useQueryClient } from 'react-query';
import { DeleteScope, SCOPES } from 'services/WorkspaceService';

interface DeleteScopeModalProps {
    workspaceId: string;
    scope: {
        id: string;
        displayName?: string;
        data?: {
            quickScope?: boolean;
            queryDetail?: string | { plugin: any; types: any[]; objects: any[] };
        };
    };
    onClose: () => void;
}

/**
 * Modal for confirming deletion of Scope
 */
const DeleteScopeModal: React.FC<DeleteScopeModalProps> = ({ workspaceId, scope, onClose }) => {
    const queryClient = useQueryClient();

    const { mutate, isLoading, error } = useMutation(() => DeleteScope(workspaceId, scope.id), {
        onMutate: async () => {
            await queryClient.cancelQueries([SCOPES, workspaceId]);
            const oldScopes: any[] | undefined = queryClient.getQueryData([SCOPES, workspaceId]);
            queryClient.setQueryData([SCOPES, workspaceId], oldScopes?.filter((s) => s.id !== scope.id));
            return { oldScopes };
        },
        onSuccess: () => {
            onClose();
        },
        onError: (_error, _data, context) => {
            queryClient.setQueryData([SCOPES, workspaceId], context?.oldScopes);
        },
        onSettled: () => {
            queryClient.invalidateQueries([SCOPES, workspaceId]);
        }
    });

    const title = getScopeTitle(scope, 'Delete Collection: ');
    const description = 'Are you sure you want to permanently delete this collection?';

    return (
        <Modal title={title} description={description} close={onClose} fullWidth maxWidth='max-w-3xl'>
            {error && <p className='px-8 text-statusErrorPrimary'>An error occurred, please try again.</p>}

            <ModalButtons>
                <Button onClick={onClose} variant='tertiary'>
                    Cancel
                </Button>
                <Button
                    type='submit'
                    onClick={mutate}
                    disabled={isLoading}
                    data-testid='delete-scope'
                    variant='destructive'
                >
                    {isLoading ? <LoadingSpinner size={18} /> : 'Delete'}
                </Button>
            </ModalButtons>
        </Modal>
    );
};

export default DeleteScopeModal;
