import LoadingSpinner from 'components/LoadingSpinner';
import { ChoiceChips } from 'components/forms/choiceChips/ChoiceChips';
import Field from 'components/forms/field/Field';
import { PositionPicker } from 'components/forms/positionPicker/PositionPicker';
import { FormToggle } from 'components/forms/toggle/FormToggle';
import { useFormContext, useWatch } from 'react-hook-form';
import { ImageDropzone } from './ImageDropzone';

interface ImageUploadFormProps {
    isLoadingFile: boolean;
    fileName: string;
    canShowHealthState: boolean;
    handleFileChange: (url: string, name: string) => void;
}

export const ImageUploadForm: React.FC<ImageUploadFormProps> = ({
    isLoadingFile,
    fileName,
    canShowHealthState,
    handleFileChange
}) => {
    const fields = useWatch();
    const { setValue } = useFormContext();

    return (
        <div className='h-full pt-4 pr-4 overflow-scroll scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
            {isLoadingFile ? (
                <div className='flex flex-1 w-full items-center justify-center cursor-pointer h-32 bg-componentBackgroundPrimary border-[1px] border-outlinePrimary rounded-md'>
                    <LoadingSpinner />
                </div>
            ) : (
                <ImageDropzone fileName={fileName} onFileChange={handleFileChange} />
            )}

            <div>
                {/* Not shown but essential to preserve the uploaded timestamp */}
                <Field.Input type='hidden' name='uploaded' />
            </div>

            <div className='pt-6'>
                <p className='font-medium text-textPrimary'>Live Diagram</p>
                <FormToggle
                    wrapperClassName='!mt-2 rounded-md'
                    name='showHealthState'
                    label='Show health state'
                    disabled={!canShowHealthState}
                    onCheckedChange={(checked) => {
                        if (checked) {
                            setValue('healthStateMode', ['fill']);
                            setValue('healthStateIconPosition', {
                                vertical: 'top',
                                horizontal: 'left'
                            });
                            setValue('healthStateIconSize', 3);
                        }
                    }}
                />
                <p className='my-1 text-xs text-textSecondary mx-md'>
                    To enable live health state, upload an SVG with hyperlinks.{' '}
                    <a
                        href='https://docs.squaredup.com/first-steps/image-tiles'
                        target='_blank'
                        rel='noreferrer'
                        className='text-hyperlink'
                    >
                        Learn more.
                    </a>
                </p>
            </div>

            {fields.showHealthState && (
                <div className='pl-md'>
                    <div className='pt-6'>
                        <ChoiceChips
                            name='healthStateMode'
                            label='Style'
                            isMulti={true}
                            options={[
                                {
                                    label: 'Fill',
                                    value: 'fill'
                                },
                                {
                                    label: 'Icon',
                                    value: 'icon'
                                },
                                {
                                    label: 'Glow',
                                    value: 'glow'
                                }
                            ]}
                        />
                    </div>

                    {fields.healthStateMode?.includes('icon') && (
                        <div className='flex flex-row pt-4'>
                            <PositionPicker name='healthStateIconPosition' label='Position' />
                            <div className='flex-1 ml-8'>
                                <Field.Input
                                    type='slider'
                                    name='healthStateIconSize'
                                    label='Icon Size'
                                    min={1}
                                    max={30}
                                    markers={[
                                        { value: 1, label: '1' },
                                        { value: 30, label: '10' }
                                    ]}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}

            <div className='my-8'>
                <Field.Input
                    type='text'
                    name='title'
                    label='Description'
                    placeholder='Enter an optional image description'
                    help='Shown to screen readers and on hover'
                    validation={{ maxLength: 300 }}
                />
            </div>
        </div>
    );
};
