import { DataStreamLineGraphConfig } from './Config';
import { LineSeries } from './lineGraphTypes';

export const getYScaleOptions = (
    series: LineSeries[],
    config?: DataStreamLineGraphConfig
): {
    min: number | 'auto';
    max: number | 'auto';
    areaBaseline: number;
} => {
    const allY = series.flatMap((s) => s.data).map((d) => d.y);
    const maxY = Math.max(...allY);
    const minY = Math.min(...allY);

    const yAxisRangeFrom = !config?.yAxisRangeFrom || isNaN(config?.yAxisRangeFrom) ? null : config?.yAxisRangeFrom;
    const yAxisRangeTo = !config?.yAxisRangeTo || isNaN(config?.yAxisRangeTo) ? null : config?.yAxisRangeTo;

    switch (config?.yAxisRangeMode) {
        case 'percentage': {
            return { min: 0, max: 100, areaBaseline: 0 };
        }
        case 'fit': {
            return { min: 0, max: 'auto', areaBaseline: 0 };
        }
        case 'custom': {
            return {
                min: yAxisRangeFrom ?? minY,
                max: yAxisRangeTo ?? maxY,
                areaBaseline: yAxisRangeFrom ?? minY
            };
        }
        default: {
            return { min: 'auto', max: 'auto', areaBaseline: minY };
        }
    }
};
