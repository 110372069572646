import { cn } from '@/lib/cn';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, forwardRef } from 'react';
import Text from './Text';

type InfoTipProps = {
    icon?: IconProp;
    children?: ReactNode;
    className?: string;
    iconClassName?: string;
};

/**
 * Info tips should be used to suggest a feature or piece of helpful advice.
 */
export const InfoTip = forwardRef<HTMLParagraphElement, InfoTipProps>(
    ({ icon = faInfoCircle, className, iconClassName, children }, ref) => (
        <Text.Body
            className={cn(
                'inline-flex items-center mt-2 mb-4 space-x-2 px-sm py-xs bg-tagBackground rounded-input leading-input text-textPrimary',
                className
            )}
            ref={ref}
            role='note'
        >
            {icon && <FontAwesomeIcon icon={icon} className={cn('mr-[5px]', iconClassName)} />}
            <span>{children}</span>
        </Text.Body>
    )
);
