import { useAppContext } from 'contexts/AppContext';
import { ThemeOptions, getSystemTheme } from 'ui/hooks/useTheme';
import { visualizationPalettes } from '../palette';

export type VisualizationType = keyof typeof visualizationPalettes;

export const useVisualizationTheme = (visualizationType: VisualizationType) => {
    const { theme } = useAppContext();
    const selectedTheme = theme === ThemeOptions.system ? getSystemTheme() : theme;

    const selectedPalette = visualizationPalettes[visualizationType];

    return selectedPalette[selectedTheme];
};
