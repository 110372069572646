import Button from 'components/button/Button';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from 'images/squaredup-logo-full.svg?react';
import { useNavigate } from 'react-router-dom';
import Auth from 'services/Auth';

function getStandardAppUrl() {
    return `${window.location.protocol}//${window.location.host}`;
}
function getStandardAppDashboardUrl() {
    return `${getStandardAppUrl()}/dashboard/${Auth.user?.openAccessTargetId}`;
}

function OpenAccessNotAvailable() {
    const navigate = useNavigate();

    return (
        <div className='flex flex-col items-center justify-center h-screen min-h-screen'>
            <a href='http://squaredup.com' className='pb-6 scale-150'>
                <Logo />
            </a>
            <h1 className='mt-8 text-2xl'>A SquaredUp account is required to access this page.</h1>
            <Button className='mt-14' href={getStandardAppDashboardUrl()}>
                Sign in
            </Button>
            <h1 className='mt-6 text-lg'>Don't have an account? Contact your administrator.</h1>
            <Button
                variant='link'
                icon={<FontAwesomeIcon icon={faAngleLeft} fixedWidth />}
                className='text-lg mt-14'
                onClick={() => navigate(-1)}
            >
                <span>Return to dashboard</span>
            </Button>
        </div>
    );
}

export default OpenAccessNotAvailable;
