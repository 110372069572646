import { useEffect, useState } from 'react';

export function useDelay(delay: number, override?: boolean) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (override) {
            setLoading(false);
        }
    }, [override]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, delay);

        return () => clearTimeout(timeout);
    }, [delay, loading]);

    return loading;
}
