import * as React from "react";
const SvgJfrogArtifactory = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M0 0H30V30H0V0Z", fill: "#41BF47", style: {
  fill: "#41BF47",
  fill: "color(display-p3 0.2549 0.7490 0.2784)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M23 23.2308H7V24.4616H23V23.2308Z", fill: "white", style: {
  fill: "white",
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M15.0048 19.5385C11.2628 19.5385 8.23077 16.5065 8.23077 12.774C8.23077 9.0416 11.2628 6 14.9952 6C18.7372 6 21.7692 9.032 21.7692 12.7644C21.7692 16.5065 18.7468 19.5289 15.0048 19.5385ZM15.0048 7.29532C11.9824 7.29532 9.52609 9.74203 9.5165 12.774C9.5165 15.7964 11.9632 18.2527 14.9952 18.2623C18.0176 18.2623 20.4739 15.8156 20.4835 12.7836V12.774C20.4739 9.75162 18.0272 7.30491 15.0048 7.29532Z", fill: "white", style: {
  fill: "white",
  fill: "white",
  fillOpacity: 1
} }));
export default SvgJfrogArtifactory;
