import { faLink } from '@fortawesome/pro-solid-svg-icons';
import Copy from 'components/Copy';

const CopyDashboardLink = () => {
    const dashboardUrl = getDashboardUrl();

    return (
        <Copy value={dashboardUrl} icon={faLink} variant='link'>
            Copy link
        </Copy>
    );
};

const getDashboardUrl = () => {
    return window.location.href;
};

export default CopyDashboardLink;
