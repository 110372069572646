import { PinnableNodeData } from 'components/map/data/types';
import { NodeProps } from 'reactflow';
import { WorkspaceNodeInternal } from './internals/WorkspaceNodeInternal';

export const WORKSPACE_NODE = 'workspaceNode';
export const WORKSPACE_NODE_SIZE = 70;

export const WorkspaceNode = ({ id, data }: NodeProps<PinnableNodeData>) => {

    return (
        <WorkspaceNodeInternal 
            id={id}
            pinned={data.pinned}
            expanded={data.expanded}
            label={data.label}
            workspaceId={data.workspaceId}
            hiddenConnections={data.hiddenConnections}
            fixedPosition={data.fixedPosition}
        />
    );
};
