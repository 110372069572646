import { datastreamsQueryKeys } from 'queries/queryKeys/datastreamKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { List } from 'services/DataStreamService';

type QueryReturnType = Awaited<ReturnType<typeof List>>;

/**
 * useQuery returning all datastreams
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of all datastreams in a tenant
 */
export const useDatastreams = (options?: UseQueryOptions<QueryReturnType, unknown, QueryReturnType, string[]>) => 
    useQuery(
        datastreamsQueryKeys.all, 
        List, 
        {
            staleTime: Number.POSITIVE_INFINITY,
            cacheTime: Number.POSITIVE_INFINITY,
            keepPreviousData: true,
            ...options
        }
    );
