import { Pill, PillState } from 'components/pill/Pill';
import { useDataSourceConfig } from 'pages/datasources/components/useDataSourceConfig';
import PluginIcon from 'pages/scope/PluginIcon';
import { FC } from 'react';
import { NodeWithCanonical } from './common';

export const NodeDataSourceStatePill: FC<{ node?: NodeWithCanonical }> = ({ node }) => {
    const configId = node?.__configId?.[0];
    const isEnabled = node ? !node.isCanonical && configId !== undefined : false;
    const { data, isLoading } = useDataSourceConfig(configId ?? '', isEnabled);

    if (isLoading || !data?.importStatus) {
        return <></>;
    }

    const { importStatus } = data;
    const status: PillState = importStatus?.status
        ? importStatus.status === 'succeeded' && importStatus.totalWarningCount
            ? 'warnings'
            : importStatus.status
        : 'notRun';

    return (
        <>
            {data.plugin && 'name' in data.plugin ? (
                <Pill.StatusWithIcon status={status} icon={<PluginIcon pluginName={`${data.plugin?.name}`} />} 
                    data-testid='dataSourceStatusWithIcon'
                    data-teststate={status}>
                    {data.displayName}
                </Pill.StatusWithIcon>
            ) : (
                <Pill.Status status={status}>{data.displayName}</Pill.Status>
            )}
        </>
    );
};
