import { cleanGremlinQuery, type Node } from '@squaredup/graph';
import { sortBy } from 'lodash';
import groupBy from 'lodash/groupBy';
import { TypesStore, getNameForType, getTypeNameForNode } from '../lib/types';
import { Query } from './GraphService';
import { tileSourceType, tileType } from '@squaredup/constants';
import { Workspace } from './WorkspaceService';

const unknownGroup = TypesStore.unknown.plural;

type ResultNode = {
    id: string;
    name: string;
    type: [string, ...string[]];
    groupName: [string, ...string[]];
    sourceType: [string, ...string[]];
    sourceName: [string, ...string[]];
};

const workspaceObjectSourceTypes = ['squaredup/space', 'squaredup/dash', 'squaredup/tile', 'squaredup/scope'];

/**
 * @param query Search query text from user
 * @param resultProperties Graph properties required in the results.
 * @params workspaces Workspaces the user has direct access to (i.e. has permission to navigate to)
 * @returns
 */
export const Search = async (
    query: string,
    resultProperties: string[],
    workspaces: Workspace[] | undefined
) => {
    if (!query) {
        throw new Error('Query must be a non-empty string');
    }

    let valueMapProps = 'true'; // true returns id
    if (resultProperties?.length) {
        valueMapProps += ', ' + resultProperties.map((p) => `'${p}'`).join(',');
    }

    // Filter out workspace objects the user technically has some access to, but there's currently no value
    // in showing in the results.
    //
    // We're using directOrAnyWorkspaceLinks mode for access control, which is correct but it means we get back
    // workspace objects (workspaces, dashboards, tiles, scopes) that we only have indirect access to via workspace 
    // links. This means we don't have access to those objects to navigate to, only to access limited information like
    // health state. 
    // 
    // In future we might want to show them in the search results, to show health and allow navigation to a 
    // drilldown page, but for now we'll exclude them, as showing them in the results without being able to do 
    // anything with them or see their health is just confusing.
    //
    const workspaceConfigIds = workspaces?.map(ws => ws.configId) ?? [];
    const workspaceObjectsFilter = workspaceConfigIds?.length ? cleanGremlinQuery(`.not(
        and(
            has('sourceType', within(workspaceObjectSourceTypes)),
            __.not(has('__configId', within(workspaceConfigIds)))
        )
    )`) : '';

    // Note that __matchesQuery is not a real Gremlin predicate. It's our custom predicate
    // for boolean queries that will be expanded server-side to correct Gremlin.
    const gremlinQuery = `g.V().has("__search", __matchesQuery(query))${workspaceObjectsFilter}` + 
        `.not(has('sourceType', 'squaredup/data-source')).optional(out("is")).dedup().limit(200).valueMap(${valueMapProps})`;

    const { gremlinQueryResults: nodes } = (await Query(
        {
            gremlinQuery,
            bindings: {
                query,
                workspaceObjectSourceTypes,
                workspaceConfigIds
            }
        },
        'directOrAnyWorkspaceLinks' // Search is unusual in that we want to search everything linked from every workspace we can access
    )) as { gremlinQueryResults: ResultNode[] };

    return performGrouping(nodes);
};

/**
 * Perform grouping by type, e.g. Hosts
 * @param nodes Set of graph nodes
 */
export const performGrouping = (nodes: ResultNode[]) => {
    const groupedResults = groupBy(nodes, (n) => getSearchGroupDisplayName(n));
    const { Dashboards, Workspaces, Tiles, ...rest } = groupedResults;
    const sortedUnknownKeys = Object.keys(rest).sort((a, b) => {
        // Put unknownGroup at the bottom
        return a === unknownGroup ? 1 : b === unknownGroup ? -1 : a.localeCompare(b);
    });
    const internalKeys = ['Workspaces', 'Dashboards', 'Tiles'].filter(
        (key) => (groupedResults[key]?.length ?? 0) > 0
    );
    const finalKeys = [...internalKeys, ...sortedUnknownKeys];
    return finalKeys.reduce((obj: Record<string, Node[]>, key) => {
        obj[key] = sortBy(groupedResults[key], [(o) => o.name[0].toLocaleLowerCase(), (o) => o.id]);
        return obj;
    }, {});
};

const getSearchGroupDisplayName = (node: ResultNode) => {
    // For search we put all tiles under the Tiles group, even if they are monitored tiles
    // with a two-tier sourceType of ['squaredup/monitor', 'squaredup/tile']
    if (node.sourceType?.includes(tileSourceType)) {
        return getNameForType(tileType, 'plural');
    }
    return getTypeNameForNode(node);
};
