import SearchIconDark from 'icons/darkmode-search.svg?react';
import SearchIconLight from 'icons/lightmode-search.svg?react';
import { FC } from 'react';
import { ThemeOptions, getSystemTheme, useTheme } from 'ui/hooks/useTheme';

type IconProps = {
    className: string;
};

export const SearchIcon: FC<IconProps> = (props) => {
    const theme = useTheme();
    const fixedTheme = theme === ThemeOptions.system ? getSystemTheme() : theme;

    return fixedTheme === ThemeOptions.light ? <SearchIconLight {...props} /> : <SearchIconDark {...props} />;
};
