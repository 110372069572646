import clsx from 'clsx';
import { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';
import { Dispatch, SetStateAction } from 'react';
import { PluginSourceConfig } from 'services/SourceConfigService';
import { NonLinkedPlugins } from './NonLinkedPlugins';
import Text from '@/components/Text';

interface NonLinkedPluginsListProps {
    isLoading: boolean;
    unlinkedDataSources: PluginSourceConfig[];
    cancel: () => void;
    navigateToDataSourcesPage: () => void;
    onSelectedDataSource: Dispatch<SetStateAction<PluginSourceConfig | undefined>>;
}

export const NonLinkedPluginsList = ({
    isLoading,
    unlinkedDataSources,
    cancel,
    onSelectedDataSource,
    navigateToDataSourcesPage
}: NonLinkedPluginsListProps) => {
    return (
        <div className='min-w-[800px]'>
            <div className='mx-8'>
                <NonLinkedPlugins
                    unlinkedDataSources={unlinkedDataSources}
                    onSelectedDataSource={onSelectedDataSource}
                />
                <div className='space-y-4'>
                    <div>
                        {unlinkedDataSources.length > 0 && (
                            <Text.H3 className='pt-4 pb-4'>Add a new data source</Text.H3>
                        )}
                        <Text.Body>
                            {unlinkedDataSources.length === 0 && !isLoading ? 'No data sources found, visit' : 'Visit '}{' '}
                            the{' '}
                            <Button
                                variant='link'
                                onClick={navigateToDataSourcesPage}
                            >
                                data source page
                            </Button>{' '}
                            to add and configure new data sources.
                        </Text.Body>
                    </div>
                </div>
            </div>
            <ModalButtons>
                <Button type='button' variant='tertiary' onClick={cancel} className='py-2'>
                    Cancel
                </Button>
            </ModalButtons>
        </div>
    );
};
