import { Generate } from '@squaredup/ids';
import NotFound from 'pages/not-found/NotFound';
import { FC, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Navigate, useSearchParams } from 'react-router-dom';
import { DRILLDOWN_NODE, GraphNodeLoading, NodeNotFound, queryForGraphNode } from './common';

/**
 * Attempt to redirect to a graph node drilldown by extracting
 * property key/value pairs from the URL Query String
 */
export const SearchForGraphNode: FC = () => {
    const [search] = useSearchParams();
    const queryClient = useQueryClient();

    // Need to memoize otherwise generating the safe bindings will cause the query
    // to re-run endlessly
    const terms = useMemo(() => {
        const searchTerms = [...search.entries()].filter((kvp) => kvp[0] && kvp[1]);
        return searchTerms.map(([prop, value]) => ({
            prop,
            safeBinding: `b_${Generate()}`,
            value
        }));
    }, [search]);

    const hasTerms = terms.length > 0;
    const { data: id, isLoading: isIdLoading } = useQuery<string | null>(
        [DRILLDOWN_NODE, terms],
        async () => {
            const termString = terms
                .map(({ prop, safeBinding }) => {
                    return `has('${prop}', within(${safeBinding}))`;
                })
                .join(',');

            const bindings = terms.reduce((acc: Record<string, string>, val) => {
                acc[val.safeBinding] = val.value;
                return acc;
            }, {});

            const query = `g.V().and(${termString}).valueMap(true)`;

            const nodeData = await queryForGraphNode(query, bindings);

            if (!nodeData) {
                return null;
            }

            // cache node by id so direct queries don't need to refetch
            queryClient.setQueryData([DRILLDOWN_NODE, nodeData.id], nodeData);

            return nodeData.id;
        },
        { enabled: hasTerms }
    );

    if (!hasTerms) {
        return <NotFound />;
    }

    if (isIdLoading) {
        return <GraphNodeLoading />;
    }

    return id ? <Navigate to={`/drilldown/node/${id}`} replace={true} /> : <NodeNotFound />;
};
