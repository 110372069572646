import { faComputer, faMoonStars, faSunBright } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeOptions } from 'ui/hooks/useTheme';

export const themeSwitchOptions = [
    {
        value: ThemeOptions.system,
        label: (
            <span className='inline-flex items-center space-x-2 align-middle'>
                <FontAwesomeIcon size='lg' icon={faComputer} />
                <span>System</span>
            </span>
        )
    },
    {
        value: ThemeOptions.dark,
        label: (
            <span className='inline-flex items-center space-x-2 align-middle'>
                <FontAwesomeIcon size='lg' icon={faMoonStars} />
                <span>Dark</span>
            </span>
        )
    },
    {
        value: ThemeOptions.light,
        label: (
            <span className='inline-flex items-center space-x-2 align-middle'>
                <FontAwesomeIcon size='lg' icon={faSunBright} />
                <span>Light</span>
            </span>
        )
    }
];

export const themeSwitchOptionsWithoutSystem = themeSwitchOptions.filter(o => o.value !== ThemeOptions.system);