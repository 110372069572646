import type { TileConfig } from '@squaredup/dashboards';
import { construct } from 'dashboard-engine/schemas/schema';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { CodeEditor } from './CodeEditor';

export interface TileCodeEditorProps {
    tileConfig: TileConfig;
    update: (v: TileConfig) => void;
    [key: string]: any;
}

const TileCodeEditor: React.FC<TileCodeEditorProps> = ({ update, tileConfig, ...rest }) => {
    const [schema, setSchema] = useState({});

    useEffect(() => {
        const load = async () => {
            const result = await construct();
            setSchema(result);
        };
        load();
    }, []);

    const debouncedUpdate = useMemo(
        () =>
            debounce((updatedTileContent) => {
                update(updatedTileContent);
            }, 500),
        [update]
    );

    return (
        <CodeEditor
            content={tileConfig}
            onValidUpdatedContent={(changedConfig) => debouncedUpdate(changedConfig)}
            schema={schema}
            {...rest}
        />
    );
};

export default TileCodeEditor;
