import { Serialised } from '@squaredup/ids';
import type { Plugin } from 'dynamo-wrapper';

/**
 * Extracts a specific link from the externalLinks of a plugin. Because the external links can be in multiple shapes
 * (string array or object array). We need to cater for all types
 * @param externalLinks Plugin's externalLinks
 * @param category A category for the link (only works for object arrays)
 * @param strictMode Ensure we check the link category
 * @returns The desire link
 */
export const extractPluginLink = (
    externalLinks?: Serialised<Plugin>['externalLinks'], 
    category?: string, 
    strictMode?: boolean
) => {

    if (externalLinks?.length) {
        const link = externalLinks?.find((externalLink) => {
            // If we have a flat list of URLs we just return the first (if not strictMode mode)
            // TODO: remove all string array externalLinks from plugin repo! Ensure there is a type property
            if (typeof externalLink === 'string') {
                return !strictMode;
            }

            if (externalLink.category) {
                return externalLink.category === category;
            }

            // If the link is not a string and doesn't have type we assume the first link is the valid one
            // Provided we aren't using strictMode mode
            return !strictMode;
        });

        if (typeof link === 'string') {
            return link;
        }

        return link?.url;
    }
    
    return '';
};