import DropdownMenu from '@/components/DropdownMenu';
import { faFileExport } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamBaseTileConfig, isDataStreamTileConfig } from '@squaredup/data-streams';
import { useWorkspacePermissions } from 'components/hooks/useWorkspacePermissions';
import { useAppContext } from 'contexts/AppContext';
import { useTileContext } from 'contexts/TileContext';
import { useDataStreamFromTileConfig } from 'dashboard-engine/hooks/useDataStreamFromTileConfig';

export const CopyTo: React.FC = () => {
    const { searchOpen } = useAppContext();
    const { config, onCopy } = useTileContext();
    const isDataStreamTile = isDataStreamTileConfig(config);

    const { canWriteToAtLeastOneWorkspace } = useWorkspacePermissions();
    const canWriteToWorkspace = canWriteToAtLeastOneWorkspace();
    const { isConfigured } = useDataStreamFromTileConfig(config as DataStreamBaseTileConfig);

    // If Search is open we know we're looking at the tile menu in the search panel
    if (!isDataStreamTile || !canWriteToWorkspace || !isConfigured || searchOpen || !onCopy) {
        return null;
    }

    return (
        <DropdownMenu.Item
            icon={<FontAwesomeIcon icon={faFileExport} fixedWidth />}
            onSelect={() => onCopy?.()}
            key='copyTo'
        >
            Copy to
        </DropdownMenu.Item>
    );
};
