import { CustomLayerProps } from '@nivo/line';
import { ScaleLinear } from 'd3-scale';
import { getDateTickFormatter } from 'dashboard-engine/util/tickDateFormatter';
import { tickCount } from 'dashboard-engine/util/tickFormatter';
import { GridLineExtension } from '../components/GridLineExtension';

export interface FormattedTicks {
    tick: Date;
    formattedTickValue: string;
}

/**
 * @returns an array of objects that contains a key identifier and the formatted tick to render
 */
const formatTicks = (ticksToDisplay: Date[], computedTickFormatter: (tick: Date) => string) => {
    return ticksToDisplay.map((tick) => ({
        tick,
        formattedTickValue: computedTickFormatter(tick)
    }));
};

export const CustomBottomAxis = ({ xScale, innerHeight, innerWidth }: CustomLayerProps) => {
    const { ticks } = xScale as ScaleLinear<number, number>; // Types don't match to Nivo's scale, so we have to explicitly state them

    const tickValues = ticks(tickCount(innerWidth)) as unknown as Date[];

    const computedTickFormatter = getDateTickFormatter(tickValues);

    const formattedTicks = formatTicks(tickValues, computedTickFormatter);

    const tickElements = formattedTicks.map(({ formattedTickValue, tick }: FormattedTicks, idx) => (
        <g key={`${tick.toISOString()}_${idx}`} transform={`translate(${xScale(tick)}, 0)`}>
            <GridLineExtension />
            <text
                y={0} // Controls spacing between line and text
                x={0}
                className='fill-textSecondary'
                style={{
                    fontSize: '11px',
                    fontFamily: 'Inter',
                    letterSpacing: '0px'
                }}
                textAnchor='middle'
            >
                {formattedTickValue}
            </text>
        </g>
    ));

    return <g transform={`translate(0, ${innerHeight + 25})`}>{tickElements}</g>;
};
