import { FormattedStreamValue, isJson } from '@squaredup/data-streams';
import { toString } from 'lodash';
import ReactJson from 'react-json-view';
import { getSystemTheme, ThemeOptions, useTheme } from 'ui/hooks/useTheme';

export const JSONCell: React.FC<FormattedStreamValue> = ({ formatted }) => {
    const themeSetting = useTheme();

    const theme = themeSetting === ThemeOptions.system ? getSystemTheme() : themeSetting;

    if (formatted == null) {
        return null;
    }

    if (typeof formatted !== 'string' || !isJson(formatted)) {
        return <pre className='font-mono'>{toString(formatted)}</pre>;
    }

    return (
        <ReactJson
            name={false}
            src={JSON.parse(formatted)}
            theme={theme === ThemeOptions.dark ? 'summerfruit' : 'summerfruit:inverted'}
            style={{ backgroundColor: 'unset' }}
            collapseStringsAfterLength={24}
            enableClipboard={false}
            collapsed={true}
            displayDataTypes={false}
            displayObjectSize={false}
            groupArraysAfterLength={2}
            sortKeys={true}
        />
    );
};
