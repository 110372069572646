import { Dialog, DialogContent } from '@/components/Dialog';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { ControlButton } from 'reactflow';
import { NetworkMap, MapProps } from './NetworkMap';
import Tooltip from 'components/tooltip/Tooltip';

export const FullscreenMap: React.FC<MapProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <NetworkMap 
                {...props}
                controls={
                    <>
                        {props.controls}
                        <Tooltip title='Fullscreen'>
                            <ControlButton onClick={() => setIsOpen(true)}>
                                <FontAwesomeIcon 
                                    icon={faUpRightAndDownLeftFromCenter} 
                                    fixedWidth
                                />
                            </ControlButton>
                        </Tooltip>
                    </>
                }
            />
        
            <Dialog 
                open={isOpen}
                onOpenChange={(newIsOpen) => setIsOpen(newIsOpen)}
            >
                <DialogContent className='fixed flex w-[92.5%] h-full justify-center -translate-x-1/2 -translate-y-1/2 gap-y-sm left-1/2 top-1/2 max-h-[calc(100%-48px)]'>
                    <div 
                        className='flex flex-col w-full h-full min-h-0 p-4 border max-h-max bg-backgroundSecondary text-textPrimary border-modalOutline animate animate-enter'
                        data-testid='fullscreenDialog'
                    >
                        <NetworkMap 
                            {...props} 
                            background='backgroundSecondary'
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};
