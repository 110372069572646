import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SetStateAction } from 'react';

interface WorkspaceDropdownSearchInputProps {
    search: string;
    setSearch: React.Dispatch<SetStateAction<string>>;
}

export const WorkspaceDropdownSearchInput: React.FC<WorkspaceDropdownSearchInputProps> = ({ search, setSearch }) => {
    return (
        <label className='flex items-center w-full pl-[13px] ring-inset ring-1 py-input px-xs ring-outlinePrimary focus-within:ring-outlineSecondary rounded-input'>
            <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className='mr-[9px] ml-0.5 text-[14px] text-textSecondary'
                fixedWidth
            />

            <input
                autoFocus
                role='menuitem'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder='Search for workspaces...'
                type='text'
                className='w-full p-0 bg-transparent border-none text-textPrimary leading-input focus:ring-0 focus:outline-none placeholder:text-textSecondary'
            />

            <span className='sr-only'>Search workspaces</span>
        </label>
    );
};
