import type { DashboardTileContent, DashboardType } from '@squaredup/dashboards';

export function setDashboardContents(dashboard: DashboardType, contents: DashboardTileContent[]): DashboardType {
    const updateDashboard = {
        ...dashboard,
        content: {
            ...dashboard.content,
            contents
        }
    };

    return updateDashboard;
}

export function getDashboardContents(dashboard: DashboardType) {
    return dashboard.content.contents;
}

export function setDashboardColumns(dashboard: DashboardType, columns: number) {
    const updateDashboard = {
        ...dashboard,
        content: {
            ...dashboard.content,
            columns
        }
    };

    return updateDashboard;
}

export function getDashboardColumns(dashboard: DashboardType) {
    return dashboard.content.columns;
}

export const checkIfAllTilesUseFixedTimeframe = (tiles?: DashboardTileContent[]) => {
    if (!tiles) {
        return false;
    }

    const dataStreamTiles = tiles.filter((tile) => tile.config._type === 'tile/data-stream');

    if (dataStreamTiles.length === 0) {
        return false;
    }

    return dataStreamTiles.every((tile) => {
        if (tile.config.dataStream?.id === 'datastream-sql') {
            return tile.config.dataStream?.dataSourceConfig?.tables?.every((table) =>
                Boolean(table?.config?.timeframe)
            );
        }
        return Boolean(tile.config?.timeframe);
    });
};
