import { useTileEditorObjectsFilterContext } from 'ui/editor/dataStream/contexts/TileEditorObjectsFilterContext';
import { PropertyFilter } from './PropertyFilter';

export const PropertyFilters: React.FC = () => {
    const { propertyValues, filterProperties } = useTileEditorObjectsFilterContext();

    return (
        <>
            {Object.entries(filterProperties).map(([property, selectedValues]) => (
                <PropertyFilter
                    key={property}
                    property={property}
                    selectedValues={selectedValues}
                    options={propertyValues?.get(property) || []}
                />
            ))}
        </>
    );
};
