import { Serialised } from '@squaredup/ids';
import type { ProjectedPlugin } from 'dynamo-wrapper';
import { createContext } from 'react';

interface PluginContextValue {
    plugin?: Serialised<ProjectedPlugin>;
    config?: Record<string, unknown>;
    testFunction?: (testName: string, formData: Record<string, unknown>) => Promise<any>;
}

const PluginContext = createContext<PluginContextValue>({});
PluginContext.displayName = 'PluginContext';

export default PluginContext;
