import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { CUSTOM_CORRELATIONS, Get, List, ProjectedCustomCorrelation } from 'services/CustomCorrelationsService';
import { SettingsTemplate } from '../SettingsTemplate';
import CustomCorrelationsAddEditModal from './CustomCorrelationsAddEditModal';
import CustomCorrelationsDeleteModal from './CustomCorrelationsDeleteModal';
import CustomCorrelationsTable from './CustomCorrelationsTable';
import { useTier } from 'queries/hooks/useTier';
import { isFeatureEnabled } from '@squaredup/tenants';
import { FeatureUnavailableBanner } from 'components/plans/FeatureUnavailableBanner';

const useCustomCorrelationRules = () => {
    const { data: customCorrelations, isLoading } = useQuery(
        [CUSTOM_CORRELATIONS],
        async (): Promise<ProjectedCustomCorrelation[] | undefined> => {
            const customCorrelationsList = await List();

            return Promise.all(
                customCorrelationsList?.map(async (correlation) => {
                    const rule: ProjectedCustomCorrelation = await Get(correlation.id);
                    return rule;
                })
            );
        },
        {
            select: (data) =>
                data?.sort((a, b) => {
                    if (a.displayName === undefined) {
                        a.displayName = '';
                    }
                    if (b.displayName === undefined) {
                        b.displayName = '';
                    }
                    return a.displayName.localeCompare(b.displayName);
                })
        }
    );

    return {
        isLoading,
        customCorrelations
    };
};

/**
 * Renders the Custom Correlations settings page
 */
function CustomCorrelations() {
    const [correlationBeingDeleted, setCorrelationBeingDeleted] = useState<ProjectedCustomCorrelation>();
    const [correlationBeingEdited, setCorrelationBeingEdited] = useState<ProjectedCustomCorrelation>();
    const [correlationAddEditOpen, setCorrelationAddEditOpen] = useState(false);

    const { customCorrelations, isLoading: isCustomRulesLoading } = useCustomCorrelationRules();
    const { data: tier, isLoading: isTierLoading } = useTier();
    const isLoading = isTierLoading || isCustomRulesLoading;
    const isFeatureAvailable = tier !== undefined && isFeatureEnabled(tier, 'correlations');

    return (
        <SettingsTemplate
            title='Custom Correlations'
            description={
                !isFeatureAvailable ? undefined :
                'Custom correlation rules are used to create links between objects or merge objects from different data sources.'
            }
            learnMoreLink='https://squaredup.com/cloud/custom-correlations'
        >
            {isLoading && (
                <span className='flex justify-center'>
                    <LoadingSpinner />
                </span>
            )}
            {!isLoading && (
                <div className='flex flex-col flex-1 min-h-0'>
                    <FeatureUnavailableBanner 
                        featureKey='correlations'
                        container='page'
                        title='Create object relationships'
                        className='mb-9'
                    />

                    <div>
                        <Button onClick={() => setCorrelationAddEditOpen(true)} data-testid='addCorrelation' disabled={!isFeatureAvailable}>
                            Add correlation rule
                        </Button>
                    </div>

                    <div className='flex flex-col min-h-0 mt-4 mb-8'>
                        <CustomCorrelationsTable
                            customCorrelations={customCorrelations || []}
                            isFeatureAvailable={isFeatureAvailable}
                            onEdit={(customCorrelation) => {
                                setCorrelationBeingEdited(customCorrelation);
                                setCorrelationAddEditOpen(true);
                            }}
                            onDelete={(customCorrelation) => setCorrelationBeingDeleted(customCorrelation)}
                        />
                    </div>
                </div>
            )}

            {correlationAddEditOpen && (
                <CustomCorrelationsAddEditModal
                    onSave={() => setCorrelationBeingEdited(undefined)}
                    onClose={() => {
                        setCorrelationBeingEdited(undefined);
                        setCorrelationAddEditOpen(false);
                    }}
                    customCorrelation={correlationBeingEdited}
                    key='custom-correlations-add-edit'
                />
            )}

            {correlationBeingDeleted && (
                <CustomCorrelationsDeleteModal
                    customCorrelation={correlationBeingDeleted}
                    onClose={() => setCorrelationBeingDeleted(undefined)}
                    key='custom-correlations-delete'
                />
            )}
        </SettingsTemplate>
    );
}

export default CustomCorrelations;
