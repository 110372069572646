import type { AccordionMultipleProps } from '@radix-ui/react-accordion';
import type {
    DataStreamFilterConfig,
    DataStreamGroupingConfig,
    DataStreamSortingConfig,
    StreamData,
    StreamDataColumn,
    VisualisationHint
} from '@squaredup/data-streams';
import type { HealthState } from '@squaredup/monitoring';
import { DataMatchCriterion } from 'dashboard-engine/dataStreams/dataMatchCriteria';
import { visualisationOptionsRepo } from 'dashboard-engine/repositories/visualisationsRepo';
import { PartialDeep } from 'type-fest';
import Data from './DataTypes';
import { VisualisationMonitorConditions } from './Monitoring';

type VisualisationArgs<T extends Data, C = Record<string, any>> = {
    data: T;
    config: C;
};

type PanelState = { name: string; isOpen: boolean }[];

export interface Visualisation<T extends Data, C> extends React.FC<VisualisationArgs<T, C>> {
    /**
     * Config name
     */
    config?: string;
}

export type VisualisationOptionAction = {
    action: string;
    data?: any;
};

export interface VisualisationOption<TConfig> {
    initialPanels?: (config?: TConfig) => PanelState;
    dataMappingComponent?: React.FC<{
        columns: StreamDataColumn[];
        config: TConfig;
        tileData: StreamData;
        onChange(action: VisualisationOptionAction): void;
    }>;
    configurationComponent?: React.FC<{
        columns: StreamDataColumn[];
        config: TConfig;
        tileData: StreamData;
        accordionControls: AccordionMultipleProps;
        onChange(action: VisualisationOptionAction): void;
    }>;
    handlers: {
        [action: string]: (config: TConfig, data: any) => any;
    };
    validate(columns: StreamDataColumn[], config: TConfig): unknown;
    matchesData(
        data: StreamData | undefined,
        config: TConfig
    ): {
        success: boolean;
        criteria: DataMatchCriterion[];
    };
    getDefaultConfig?(data: StreamData | undefined): TConfig;
}

const supportedVisualisationOptions = visualisationOptionsRepo.list().filter((name) => {
    const options = visualisationOptionsRepo.get(name);
    return options.configurationComponent || options.dataMappingComponent;
});

export const typeSupportsVisualisationOptions = (type: VisualisationHint) => {
    return supportedVisualisationOptions.some((t) => t === type);
};

export type DataStreamShapingConfig = PartialDeep<
    DataStreamGroupingConfig & DataStreamSortingConfig & DataStreamFilterConfig
>;

export type DataStreamVisualisation<C> = React.FC<{
    data: StreamData;
    config: C;
    healthState?: HealthState;
    monitorConditions?: VisualisationMonitorConditions;
    shapingConfig?: DataStreamShapingConfig;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}> & {
    /**
     * Config name
     */
    config?: string;
};
