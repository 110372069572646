import { PinnableNodeData } from 'components/map/data/types';
import { NodeProps } from 'reactflow';
import { GroupNodeInternal } from './internals/GroupNodeInternal';

export const GROUP_NODE = 'groupNode';
export const GROUP_NODE_SIZE = 44;

export const GroupNode = ({ id, data }: NodeProps<PinnableNodeData>) => {

    return (
        <GroupNodeInternal
            id={id}
            pinned={data.pinned}
            expanded={data.expanded}
            label={data.label}
            fixedPosition={data.fixedPosition}
            type={data.type}
            sourceType={data.sourceType}
            hiddenConnections={data.hiddenConnections}
        />
    );
};
