import { Monaco } from '@monaco-editor/react';
import { ShapeName, StreamDataColumn, getShape } from '@squaredup/data-streams';
import { IRange, Uri, editor } from 'monaco-editor';
import { FC } from 'react';
import { CodeEditor } from 'ui/editor/components/CodeEditor';

export const isInsideExpression = (
    model: editor.ITextModel,
    range: {
        startLineNumber: number;
        startColumn: number;
        endLineNumber: number;
        endColumn: number;
    }
) => {
    const expressions =
        model.findMatches('\n{0,}(?<=\\{\\{)([\\s\\S\\r\\n]*?)(?=\\}\\})', true, true, false, null, true) ?? [];

    const isInExpression = expressions.some((e) => e.range.containsRange(range));

    return isInExpression;
};

// Hide squigglies that aren't inside expressions
const hideMarkersOutsideExpressions = (monaco: Monaco, uri: Uri) => {
    const markers = monaco.editor.getModelMarkers({ resource: uri });
    const insideExpressionMarkers = markers.filter((m) => {
        const model = monaco.editor.getModel(uri);

        if (model == null) {
            return false;
        }

        return isInsideExpression(model, m);
    });

    if (markers.length !== insideExpressionMarkers.length) {
        monaco.editor.setModelMarkers(monaco.editor.getModel(uri)!, 'javascript', insideExpressionMarkers);
    }
};

const getLiteralColours = (m: editor.ITextModel) => {
    const startOfTemplatePattern = '(?=\\{\\{)';
    const endOfTemplatePattern = '(?=\\}\\})';
    const betweenTemplatesPattern = '(?<=\\}\\})[\\s\\S\\n\\r]*?(?=\\{\\{)';

    const betweenTemplateMatches = m.findMatches(betweenTemplatesPattern, true, true, false, null, true);
    const beforeTemplatesMatch = m.findMatches(startOfTemplatePattern, true, true, false, null, true);
    const beforeTemplatesRange: IRange[] =
        beforeTemplatesMatch.length === 0
            ? // We didn't find any {{ so all the text must be literal
              [m.getFullModelRange()]
            : [
                  {
                      ...beforeTemplatesMatch[0].range,
                      startColumn: 0,
                      startLineNumber: 0
                  }
              ];

    const afterTemplatesMatches = m.findPreviousMatch(
        endOfTemplatePattern,
        { column: 0, lineNumber: 0 },
        true,
        true,
        null,
        false
    );
    const afterTemplatesRange: IRange[] =
        afterTemplatesMatches == null
            ? []
            : [
                  {
                      ...m.getFullModelRange(),
                      startColumn: afterTemplatesMatches.range.startColumn + 2,
                      startLineNumber: afterTemplatesMatches.range.startLineNumber
                  }
              ];

    return [...beforeTemplatesRange, ...afterTemplatesRange, ...betweenTemplateMatches.map((match) => match.range)].map(
        (range) => ({
            options: {
                inlineClassName: 'text-formatExpressionLiteral',
                inlineClassNameAffectsLetterSpacing: false
            },
            range
        })
    );
};

const getTemplateDelimiterColours = (m: editor.ITextModel) => {
    const delimiterPattern = '(\\{\\{)|(\\}\\})';

    const delimiterMatches = m.findMatches(delimiterPattern, true, true, false, null, true);

    return delimiterMatches
        .map((match) => match.range)
        .map((range) => ({
            options: {
                inlineClassName: 'text-formatExpressionTemplateDelimiter',
                inlineClassNameAffectsLetterSpacing: false
            },
            range
        }));
};

const getTypescriptTypeName = (shapeName: ShapeName): string => {
    const primitiveNames = ['string', 'boolean', 'number'];
    const name = shapeName.slice('shape_'.length);

    if (primitiveNames.includes(name)) {
        return name;
    }

    const shape = getShape(shapeName);

    if (shape != null && !shape.isPrimitive) {
        return getTypescriptTypeName(shape.valueShape.name);
    }

    return 'unknown';
};

export const getFormatExpressionTypeDefinitions = (columns: StreamDataColumn[]) => {
    return `/**
* The current row.
*/
declare var $: {
${columns.map((c) => `['${c.name}']: ${getTypescriptTypeName(c.valueShapeName)}`)}
};

/**
* The index of the current row.
*/
declare var $rowIndex: number;

/**
* An array of all rows that make up the data.
*/
declare var $rows: (typeof $)[];

/**
* An array of all the columns in the data.
*/
declare var $columns: {
    name: string;
    displayName: string;
    /**
     * The index of this column when displayed (the index of this column in the data stream definition)
     */
    displayIndex: number;
    /**
     * The name of the shape that values in this column should be converted to if possible.
     */
    targetShapeName?: ShapeName;
    /**
     * The name of the final shape of this column
     * (the target shape if there is one, or the inferred shape if there is not)
     */
    shapeName: ShapeName;
    /**
     * The config to use when applying the shapes (conversion and formatting).
     */
    rawShapeConfig: Record<string, any>;
    /**
     * The name of the shape of the values this column contains
     * (this may be the target shape if the target shape was a value shape,
     * otherwise this is the inferred shape)
     */
    valueShapeName: ShapeName;
    /**
     * True if this column is visible
     */
    visible: boolean;
    /**
     * A hint as to how this column can be used
     */
    role: StreamDataColumnRole;
    /**
     * A list of columns (paths relative to the root of this column)
     * to expand into separate columns
     */
    expand: (StreamDataColumnDefinition | CloneStreamDataColumnDefinition)[];
    /**
     * The name of the id column that identifies the object this column is a property of.
     * E.g. if this column is the name of a VM, sourceId would be the name of the column containing the id of the VM.
     */
    sourceIdColumn?: string;
    /**
     * JS expression for custom formatting of values in this column
     */
    formatExpression?: string;
}[];

/**
* An object that can be used to cache values between executions of this format expression.
* This is useful for storing derived data which is the same for every row. It starts as an empty object.
* 
* @example
* // $rows.reduce will only be run once across all the rows
* $context.total = $context.total ?? $rows.reduce((total, r) => total + r.value);
* 
* ($.value / $context.total) * 100
*/
declare var $context: Record<string, unknown>;
    `;
};

const isModel = (name: string, uri: Uri): boolean => {
    return uri.path === `/${name}`;
};

export const FormatExpressionEditor: FC<{
    onChange: (value: unknown) => void;
    value: string;
    columns: StreamDataColumn[];
    name: string;
}> = ({ onChange, value, columns, name }) => {
    const isMultiline = value != null && value.includes('\n');

    return (
        <CodeEditor
            content={value || ''}
            language={'javascript'}
            heightConstraints={{ min: isMultiline ? 200 : 42, max: 200 }}
            onValidUpdatedContent={(v: unknown) => onChange(v)}
            modelPath={name}
            onMount={(monaco) => {
                monaco.languages.typescript.javascriptDefaults.addExtraLib(
                    getFormatExpressionTypeDefinitions(columns),
                    'global.d.ts'
                );

                monaco.editor.onDidChangeMarkers(([uri]) => {
                    if (!isModel(name, uri)) {
                        return;
                    }

                    hideMarkersOutsideExpressions(monaco, uri);
                });

                let colourIds: string[] = [];
                const applyColours = (m: editor.ITextModel, colours: editor.IModelDeltaDecoration[]) => {
                    const oldIds = [...colourIds];

                    colourIds = m.deltaDecorations(oldIds, colours);
                };

                monaco.editor.onDidCreateModel((m) => {
                    if (!isModel(name, m.uri)) {
                        return;
                    }

                    applyColours(m, [...getLiteralColours(m), ...getTemplateDelimiterColours(m)]);

                    m.onDidChangeContent(() => {
                        applyColours(m, [...getLiteralColours(m), ...getTemplateDelimiterColours(m)]);
                    });
                });
            }}
            options={{
                wordWrap: 'off',
                lineNumbers: 'off',
                lineNumbersMinChars: 0,
                overviewRulerLanes: 0,
                overviewRulerBorder: false,
                lineDecorationsWidth: 10, // controls left padding of the field
                hideCursorInOverviewRuler: true,
                glyphMargin: false,
                folding: false,
                scrollBeyondLastColumn: 0,
                scrollbar: { horizontal: 'auto', vertical: 'hidden' },
                find: {
                    addExtraSpaceOnTop: false,
                    autoFindInSelection: 'never',
                    seedSearchStringFromSelection: 'never'
                },
                minimap: { enabled: false },
                ...(isMultiline && {
                    wordWrap: 'on',
                    lineNumbers: 'on',
                    lineNumbersMinChars: 2,
                    scrollbar: { horizontal: 'auto', vertical: 'auto' }
                })
            }}
        />
    );
};
