/**
 * This provides a context for the manifest (added at build time).
 * The manifest includes details about the current version & API endpoints to use.
 */

// SQUPRELEASEVARS is defined (globally) in index.html and
// set as part of each release to contain information such
// as the deployed stage, release number etc.
if (typeof window.SQUPRELEASEVARS === 'undefined') {
    const devStage = import.meta.env.VITE_STAGE || 'master'; // Fallback to master for storybook compatability
    window.SQUPRELEASEVARS = {
        Release: 1,
        Stage: devStage,
        Environment: 'Local',
        Branch: 'Unknown',
        Commit: 'Unknown',
        Timestamp: 'Unknown',
        Endpoint: `https://dev.api.squaredup.com/${devStage}`,
        Websocket: `wss://dev.websocket.squaredup.com/${devStage}`,
        x_squp_1: import.meta.env.VITE_X_SQUP_1, // eslint-disable-line camelcase
        x_squp_2: import.meta.env.VITE_X_SQUP_2, // eslint-disable-line camelcase
        Auth: {
            ClientId: 'e0197ddb-5c82-4843-bda3-ff0f2161dbcf',
            TenantId: '74c02866-01a1-4999-b542-30cde41d5047',
            Tenant: 'login-dev'
        }
    };
}

// Setup a React context to consumers can access the injected config
export default window.SQUPRELEASEVARS;
