import type { WorkspaceProperties } from '@squaredup/tenants';
import { forEach, groupBy, sortBy } from 'lodash';
import { Workspace } from 'services/WorkspaceService';
import { flattenedDashboardsSortedByWorkspaceOrder } from './dashboardSorted';

export type Perspective = {
    id: string;
    name: string;
    variables?: string[];
    workspaceId: string;
};

type SortedPerspective = {
    dashboardId: string;
    dashboardName: string;
    workspaceId: string;
    workspaceName: string;
    variables?: string[];
    folderPath: string[];
};

const emptyDashboardContents = { _type: 'layout/grid' as const, version: 0, contents: [], columns: 1 };

export const sortPerspectives = (workspaces: Workspace[], perspectives: Perspective[]) => {
    const workspaceMap = workspaces.reduce((acc, cur) => {
        acc.set(cur.id, {
            name: cur.displayName,
            dashboardOrder: cur.data?.properties?.dashboardIdOrder || []
        });
        return acc;
    }, new Map<string, { name: string; dashboardOrder: WorkspaceProperties['dashboardIdOrder'] }>());

    const perspectivesGrouped = groupBy(perspectives, 'workspaceId');
    const workspacesSorted = sortBy(workspaces, 'displayName');

    const sortedPerspectives: SortedPerspective[] = [];

    // Perspective dashboards are ordered by workspace name
    forEach(workspacesSorted, (value) => {
        if (perspectivesGrouped[value.id]) {
            // Then by the order within that workspace
            const sort = flattenedDashboardsSortedByWorkspaceOrder(workspaceMap.get(value.id)?.dashboardOrder)(
                perspectivesGrouped[value.id].map((v) => ({
                    ...v,
                    // This is to satisfy the DashboardType type argument
                    displayName: v.name,
                    content: emptyDashboardContents
                }))
            );

            sortedPerspectives.push(
                ...sort.map((p) => ({
                    dashboardId: p.id,
                    dashboardName: p.displayName,
                    workspaceId: p.workspaceId,
                    workspaceName: workspaceMap.get(p.workspaceId)?.name ?? '',
                    variables: p.variables ?? [],
                    folderPath: p.folderPath
                }))
            );
        }
    });

    return sortedPerspectives;
};
