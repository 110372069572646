import { PinnableNodeData } from 'components/map/data/types';
import { NodeProps } from 'reactflow';
import { DashboardNodeInternal } from './internals/DashboardNodeInternal';


export const DASHBOARD_NODE = 'dashboardNode';
export const DASHBOARD_NODE_SIZE = 35;

export const DashboardNode = ({ id, data }: NodeProps<PinnableNodeData>) => {

    return (
        <DashboardNodeInternal
            id={id}
            pinned={data.pinned}
            expanded={data.expanded}
            label={data.label}
            sourceId={data.sourceId}
            workspaceId={data.workspaceId}
            fixedPosition={data.fixedPosition}
            hiddenConnections={data.hiddenConnections}
        />
    );
};
