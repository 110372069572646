import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation, faCircleXmark, faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FC } from 'react';
import Tooltip from 'components/tooltip/Tooltip';
import { HealthState } from '@squaredup/monitoring';

interface AgentStatusIconProps {
    state: HealthState,
    title: string;
}

const AgentStatusIcon: FC<AgentStatusIconProps> = ({ state, title }) => {
    const stateValues = {
        success: { icon: faCircleCheck, color: 'text-statusHealthyPrimary' },
        warning: { icon: faCircleExclamation, color: 'text-statusWarningPrimary' },
        error: { icon: faCircleXmark, color: 'text-statusErrorPrimary' },
        unknown: { icon: faCircleQuestion, color: 'text-statusUnknownPrimary' },
        unmonitored: { icon: faCircleQuestion, color: 'text-statusUnmonitoredPrimary' }
    };

    return (
        <Tooltip title={title}>
            <FontAwesomeIcon
                className={stateValues[state].color}
                icon={stateValues[state].icon}
            />
        </Tooltip>
    );
};

export default AgentStatusIcon;
