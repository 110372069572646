import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import Text from '@/components/Text';
import { buttonVariants } from '@/components/Button';

type NotFoundProps = {
    title: string;
    body: string;
}

export const NotFoundPanel: FC<NotFoundProps> = ({ title, body }) => (
    <div className='h-screen mt-48 '>
        <div className='flex flex-col items-center gap-4 mx-auto text-center'>
            <Text.H2>{title}</Text.H2>
            <Text.Body>{body}</Text.Body>
            <Text.Body>Go to the &nbsp; 
                <NavLink to='/status' className={buttonVariants({ variant: 'link' })}>
                    Organization home
                </NavLink>
            </Text.Body>
        </div>
    </div>
);
