import { InputHTMLAttributes, forwardRef, useEffect, useState } from 'react';

type InputProps = {
  value: string
  onChange: (value: string) => void
  debounce?: number
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export const DebouncedInput = forwardRef<HTMLInputElement, InputProps>(({
    value: initialValue,
    onChange,
    debounce = 100,
    ...props
  }, ref) => {
    const [value, setValue] = useState(initialValue);
  
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value);
      }, debounce);
  
      return () => clearTimeout(timeout);
    }, [debounce, onChange, value]);
  
    return (
      <input {...props} value={value} ref={ref} onChange={e => setValue(e.target.value)} />
    );
  });