import { ORGANISATION_NODE, ORGANISATION_NODE_SIZE } from 'components/map/components/nodes/OrganisationNode';

export const organisationToNode = (organisation: any) => {
    return {    
        id: organisation.id,
        type: ORGANISATION_NODE,
        position: {
            x: 0,
            y: 0
        },
        width: ORGANISATION_NODE_SIZE,
        height: ORGANISATION_NODE_SIZE,
        data: {
            label: organisation.name
        }
    };
};
