import { useDatasetContext } from '../../contexts/DatasetContext';

export const DataStreamShapingLabels = () => {
    const { config } = useDatasetContext();

    const hasShapes = new Set<string>();

    if (config.dataStream?.filter?.filters?.length) {
        hasShapes.add('Filter');
    }

    const aggregate = config.dataStream?.group?.aggregate;
    if (config.dataStream?.group?.by?.length || (Array.isArray(aggregate) && aggregate.length > 0)) {
        hasShapes.add('Group');
    }

    if (config.dataStream?.sort?.by !== undefined) {
        hasShapes.add('Sort');
    }

    const shapingSublabel =
        hasShapes.size > 0
            ? Array.from(hasShapes)
                  .join(', ')
                  .replace(/,(?!.*,)/gimu, ' & ')
            : 'None';

    return <span>{shapingSublabel}</span>;
};
