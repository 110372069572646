/* eslint-disable no-console */
import type { SimpleTileConfig } from '@squaredup/dashboards';
import { getGroupingSpec, getSortSpec, VisualisationConfig } from '@squaredup/data-streams';
import { getTimeframe, TimeframeEnumValue } from '@squaredup/timeframes';
import VisualisationSkeleton from 'components/VisualisationSkeleton';
import DashboardContext from 'contexts/DashboardContext';
import TileContext from 'contexts/TileContext';
import datasourceRepo from 'dashboard-engine/repositories/datasourceRepo';
import transformRepo from 'dashboard-engine/repositories/transformRepo';
import { visualisationsRepo } from 'dashboard-engine/repositories/visualisationsRepo';
import { useContext, useEffect, useMemo, useState } from 'react';
import { SizeMeProps, withSize } from 'react-sizeme';
import { useResolvedConfig } from '../hooks/useResolvedConfig';

const SimpleBaseTile: React.FC<{
    tileId: string;
    config: SimpleTileConfig;
    size: SizeMeProps;
    timeframe: TimeframeEnumValue;
}> = ({ tileId, config, size, timeframe }) => {
    const context = useContext(DashboardContext);
    const { tileProcessedData, setTileData, setTileProcessedData } = useContext(TileContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<unknown>();

    const Visualisation = useMemo(() => {
        if (!config.visualisation) {
            return;
        }
        if (typeof config.visualisation.type === 'string') {
            return visualisationsRepo.get(config.visualisation.type);
        } else {
            return config.visualisation.type;
        }
    }, [config.visualisation]);

    const datasource = useMemo(() => {
        if (!config.datasource) {
            return;
        }
        if (config.datasource.config?.type === 'inherited') {
            return 'inherited';
        } else if (typeof config.datasource.type === 'string') {
            return datasourceRepo.get(config.datasource.type);
        } else {
            return config.datasource.type;
        }
    }, [config.datasource]);

    const datasourceConfig = useResolvedConfig(
        {
            options: {
                group: config.dataStream == null ? undefined : getGroupingSpec(config.dataStream),
                sort: config.dataStream == null ? undefined : getSortSpec(config.dataStream)
            },
            ...config.datasource?.config
        },
        context,
        undefined
    );
    const visualisationConfig = useResolvedConfig(config.visualisation?.config || {}, context, tileProcessedData);

    // To stop re-renders we need to compare objects via a string. Not best practise but we're eating up requests!!
    // TODO: Find a better solution (not best practice)
    const tileRenderComparison = useMemo(
        () =>
            JSON.stringify({
                ...context,
                editing: null
            }),
        [context]
    );
    const datasourceConfigComparison = useMemo(() => JSON.stringify(datasourceConfig), [datasourceConfig]);
    const visualisationConfigComparison = useMemo(() => JSON.stringify(visualisationConfig), [visualisationConfig]);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            if (!datasource || datasource === 'inherited') {
                setLoading(false);
                return;
            }

            let data: unknown[] = [];

            try {
                console.group('Loading tile');
                const datasourceFn = datasource;
                const resolvedTimeframe = getTimeframe(timeframe);
                let { data: response } = await datasourceFn(datasourceConfig || {}, context || {}, resolvedTimeframe);
                console.log(`Datasource result from ${datasourceFn.name}`, response);

                data = response;

                // Apply transforms
                (config.transforms || []).forEach((transform) => {
                    const transformToApply = transformRepo.get(transform.type);
                    data = transformToApply(data, transform.config);
                    console.log(`Processed transform: '${transform.type}'`, data);
                });

                console.log('Finished processing transforms', data);

                console.groupEnd();
            } catch (e) {
                setError(e);
            }

            if (isMounted) {
                // Set processed and unprocessed tile data for rendering
                // Any blocks with filtering will handle required processing
                setTileData?.(data);
                setTileProcessedData?.(data);
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
        // Using string comparison (tileRenderComparison) to reduce re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasourceConfigComparison, config.transforms, datasource, timeframe, tileRenderComparison]);

    const loadingComp = useMemo(
        () => (
            <div className='absolute top-0 size-full' data-testid='baseTileLoadingSpinner'>
                <VisualisationSkeleton
                    config={config?.visualisation?.config?.[config.visualisation?.type] as Record<string, unknown>}
                    type={config.visualisation?.type as VisualisationConfig['type']}
                    className='size-full loading-spinner'
                />
            </div>
        ),
        []
    );

    return useMemo(
        () =>
            loading ? (
                loadingComp
            ) : !error && Visualisation ? (
                <Visualisation
                    context={context}
                    tileId={tileId}
                    data={tileProcessedData}
                    size={size}
                    config={visualisationConfig || {}}
                />
            ) : (
                <p className='text-statusErrorPrimary'>An error occurred when rendering the tile</p>
            ),
        // Using string comparison (visualisationConfigComparison) to reduce re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [tileProcessedData, error, loading, loadingComp, size, visualisationConfigComparison]
    );
};

// Wrap tile in sizeMe HOC to provide resize data down to visualization tiles
export default withSize({ monitorHeight: true, refreshMode: 'debounce', refreshRate: 400 })(SimpleBaseTile);
