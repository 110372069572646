import { faLock, faLockOpen, faRemove, faTrash, type IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/button/Button';
import Input from 'components/forms/input/Input';
import { useState, FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Tooltip from 'components/tooltip/Tooltip';

interface KeyValueProps {
    name: string;
    displayName: string;
    keyInput: React.ComponentProps<typeof Input>;
    valueInput: React.ComponentProps<typeof Input>;
    verb: React.ReactNode;
    disabled?: boolean;
}

interface KeyValueRowProps {
    name: string;
    keyInput: React.ComponentProps<typeof Input>;
    valueInput: React.ComponentProps<typeof Input>;
    verb: React.ReactNode;
    defaultKey?: string;
    defaultValue?: string;
    onDelete: () => void;
    disabled?: boolean;
}

interface RowValue {
    key: string | { value: string };
    value: string;
}

function KeyValue({ name, displayName, keyInput, valueInput, verb, disabled = false }: KeyValueProps) {
    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({ control, name });

    const handleDelete = (id?: string) => {
        if (disabled) {
            return;
        }
        const index = fields.findIndex((field) => field.id === id);
        remove(index);
    };

    const handleAdd = () => {
        append({ key: keyInput.defaultValue || '', value: valueInput.defaultValue || '', needsEncryption: false });
    };

    return (
        <div className='flex flex-col' data-testid='keyValueInput'>
            {fields.map((field, index) => (
                <KeyValueRow
                    key={field.id}
                    name={`${name}[${index}]`}
                    keyInput={keyInput}
                    valueInput={valueInput}
                    verb={verb}
                    onDelete={() => handleDelete(field.id)}
                    disabled={disabled}
                />
            ))}
            <div className='flex'>
                <Button 
                    type='button'
                    data-testid='keyValueButton'
                    onClick={handleAdd}
                    variant='secondary'
                    disabled={disabled}
                >
                    Add {fields.length > 0 ? 'another' : 'new'} {displayName || ''}
                </Button>
            </div>
        </div>
    );
}

const OptionalTooltip: FC<{ value: ReturnType<typeof useEncryptValue>['status'] }> = ({ value, children }) => {
    if ( value === 'clear') {
        return <>{children}</>;
    }

    return (<Tooltip title={ value === 'raw' 
        ? 'This value will not be encrypted when the configuration is saved.'
        : 'This value will be encrypted when the configuration is saved.'}>
        {children}
    </Tooltip>);
};

export function KeyValueRow({
    name,
    keyInput,
    valueInput,
    verb,
    defaultKey,
    defaultValue,
    onDelete,
    disabled=false
}: KeyValueRowProps) {
    const encryptValue = useEncryptValue({ fieldId: name });

    return (
        <div className='flex items-center flex-1 mb-5' data-testid='keyValueRow'>
            <div className='flex-1 min-w-0 mr-2' data-testid='keyInput'>
                <Input {...keyInput} name={`${name}.key`} label={`${name}.key`} defaultValue={defaultKey} disabled={disabled} />
            </div>
            {verb && <span>{verb}</span>}
            <div className='relative flex-1 min-w-0 mx-2' data-testid='valueInput'>
                <Input
                    {...valueInput}
                    name={`${name}.value`}
                    label={`${name}.value`}
                    defaultValue={defaultValue}
                    type={encryptValue.status === 'raw' ? valueInput.type : 'password'}
                    className='pr-4'
                    disabled={disabled}
                />
                <button
                    data-testid='encryptValueInput'
                    type='button'
                    data-encryption-status={encryptValue.status}
                    className='absolute text-sm transition-colors w-4 h-4 flex items-center justify-center -translate-y-1/2 right-3 top-1/2 text-textSecondary data-[encryption-status=needsEncryption]:text-current hover:text-current'
                    onClick={encryptValue.updateStatus}
                    disabled={disabled}
                >
                    <OptionalTooltip value={encryptValue.status}>
                        <FontAwesomeIcon className='w-full h-full' icon={encryptValue.icon} />
                    </OptionalTooltip>
                </button>
            </div>
            <FontAwesomeIcon
                data-testid='deleteKeyValueRowButton'
                onClick={disabled ? undefined : onDelete}
                className='ml-4 text-xl cursor-pointer'
                icon={faTrash}
            />
        </div>
    );
}

const useEncryptValue = ({ fieldId }: { fieldId: string }) => {
    const { setValue, getValues } = useFormContext();

    const initialValue = getValues(fieldId)?.value ?? '';
    const isEncrypted = initialValue.startsWith('squp_encrypted');

    const [status, setStatus] = useState<'raw' | 'needsEncryption' | 'clear'>(isEncrypted ? 'clear' : 'raw');

    const updateStatus = () => {
        if (status === 'clear') {
            setStatus('raw');
            setValue(`${fieldId}.value`, '');
            setValue(`${fieldId}.needsEncryption`, false);
            return;
        }

        if (status === 'raw') {
            setStatus('needsEncryption');
            setValue(`${fieldId}.needsEncryption`, true);
            return;
        }

        if (status === 'needsEncryption') {
            setStatus('raw');
            setValue(`${fieldId}.needsEncryption`, false);
            return;
        }

        throw new Error(`Invlid encrypt value status: ${status}`);
    };

    const icons: Record<typeof status, IconDefinition> = {
        raw: faLockOpen,
        needsEncryption: faLock,
        clear: faRemove
    };

    return { status, updateStatus, icon: icons[status] };
};

export function keyValueArrayToObject(array: RowValue[]) {
    const result: Record<string, unknown> = {};
    array?.forEach(({ key, value }) => {
        if (Array.isArray(key)) {
            result[key[0].value] = value;
        } else if (typeof key === 'object' && 'value' in key) {
            result[key.value] = value;
        } else {
            result[key] = value;
        }
    });
    return result;
}

export function keyValueObjectToArray(object: Record<string, unknown>) {
    return Object.entries(object).map(([key, value]) => ({ key, value }));
}

export default KeyValue;
