import { useQuery } from 'react-query';
import { PLUGIN_DETAIL } from 'services/PluginService';
import { Get as GetConfig } from 'services/SourceConfigService';

export const useDataSourceConfig = (configId: string, isEnabled = true) => {
    const { data, isLoading } = useQuery(
        [PLUGIN_DETAIL, configId],
        async () => {
            if (!configId) {
                return undefined;
            }
            const datasourceConfig = await GetConfig(configId);
            return datasourceConfig;
        },
        { enabled: isEnabled }
    );

    return {
        data,
        isLoading
    };
};
