import { Serialised } from '@squaredup/ids';
import { ChannelId } from '@squaredup/monitoring';
import type { Channel, ProjectedChannel } from 'dynamo-wrapper';
import API from './API';
import { handleError, handleResponse } from './util';
import { FeatureKey } from '@squaredup/tenants';

export const CHANNEL_TYPES = 'channeltypes';
export const CHANNELS = 'channels';
export const CHANNEL = 'channel';

export interface ChannelCreate {
    displayName: string;
    channelTypeId: string;
    config: any;
    enabled?: boolean;
}

export interface ChannelUpdate {
    displayName?: string;
    channelTypeId?: string;
    config?: any;
    enabled?: boolean;
}

export interface ExternalLink {
    url: string;
    label: string;
    isKbArticle?: boolean;
}
export interface ChannelType {
    id: string;
    protocol: string;
    displayName: string;
    description: string;
    uiConfiguration: object[];
    externalLinks: ExternalLink[];
    config: object;
    imagePreviewSupported: boolean;
    requiresFeature?: FeatureKey;
}

export const List = async () => {
    return API.get<Serialised<ProjectedChannel>[]>('/alerting/channels').then(handleResponse).catch(handleError);
};

export const Get = async (id: string) => {
    return API.get<Serialised<Channel>>(`/alerting/channels/${id}`).then(handleResponse).catch(handleError);
};

export const Create = async (channel: ChannelCreate) => {
    const created = await API.post('/alerting/channels', channel).then(handleResponse).catch(handleError);

    return new ChannelId(created.id);
};

export const Update = async (id: string, channel: ChannelUpdate) => {
    await API.put(`/alerting/channels/${id}`, channel).then(handleResponse).catch(handleError);

    return new ChannelId(id);
};

export const Delete = async (id: string) => {
    await API.delete(`/alerting/channels/${id}`).then(handleResponse).catch(handleError);

    return true;
};

export const ListTypes = async () => {
    return API.get<Serialised<ChannelType>[]>('/alerting/channeltypes').then(handleResponse).catch(handleError);
};

export const GetType = async (id: string) => {
    return API.get<Serialised<ChannelType>>(`/alerting/channeltypes/${id}`).then(handleResponse).catch(handleError);
};

export const Test = async (channelTypeId: string, config: object, channelId?: string) => {
    const response = await API.post('/alerting/testalert', { channelTypeId, config, ...(channelId && { channelId }) })
        .then(handleResponse)
        .catch((error) => handleError(error, true));

    return response;
};
