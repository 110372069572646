export const getTickAlignment = (angle: number) => {
    const deg90 = Math.PI * 1.5;
    const gap = Math.PI * 0.2;

    // If a tick is near the middle of the gauge, center the text
    if (angle < deg90 + gap && angle > deg90 - gap) {
        return 'middle';
    } else if (angle < deg90) {
        // If it's to the left of the gauge, align the text to the right
        return 'end';
    }
    // If it's to the right, align to the left
    return 'start';
};
