import { faArrowsRotate } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Serialised } from '@squaredup/ids';
import Button from 'components/button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import type { Plugin } from 'dynamo-wrapper';
import useSlowLoading from 'lib/useSlowLoading';
import { useEffect } from 'react';
import { TestConfig } from 'services/PluginService';
import { getRequiredPluginProperties } from './components/useMutatePlugin';
import { PluginConfigFormData } from './types';
import ValidationMessages, { ValidationMessagesProps } from './ValidationMessages';

const mapper = {
    error: 0,
    warning: 1,
    success: 2
};

type TestState = keyof typeof mapper;

export const TestConfigAPI =
    (plugin?: Serialised<Plugin>, configId?: string) =>
    async ({ data, wait }: { data: PluginConfigFormData; wait?: number }) => {
        const { displayName: dataDisplayName, agentGroupId, ...dataConfig } = data;
        let pluginProperties;

        // If creating a config for a Plugin, pull out necessary properties and add them to the config
        if (plugin) {
            const { lambdaName, version, id } = plugin;

            dataConfig.pluginId = id;
            dataConfig.lambdaName = lambdaName;
            dataConfig.version = version;

            pluginProperties = getRequiredPluginProperties(plugin);
            pluginProperties.agentGroupId = agentGroupId?.value;
        }

        const result = await TestConfig(dataConfig, pluginProperties ?? {}, configId)
            .then((pluginResult: any) => {
                return pluginResult?.rawData || {};
            })
            .catch(() => ({
                status: 'error',
                messages: [
                    {
                        status: 'error',
                        message: 'Unable to test data source configuration, check your configuration.'
                    }
                ]
            }));

        const testState: TestState =
            result?.messages?.reduce(
                (state: TestState, curr: { status: TestState }) =>
                    mapper[curr.status] < mapper[state] ? curr.status : state,
                'success'
            ) || 'success';

        if (testState === 'success') {
            result.messages = [
                {
                    status: 'success',
                    message: `Successfully connected to your ${plugin?.displayName} environment.`
                }
            ];

            await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        return {
            result,
            testState
        };
    };

interface TestOutputProps {
    loading: boolean;
    result: TestState;
    messages?: ValidationMessagesProps['messages'];
    onPrem?: boolean;
    helpLink?: string;
    underRef?: React.RefObject<HTMLDivElement>;
    reRun: () => void;
    onError?: () => void;
}

export function TestOutput({ loading, result, messages, helpLink, underRef, onPrem, reRun, onError }: TestOutputProps) {
    const isSlowLoading = useSlowLoading(loading, 15000);

    useEffect(() => {
        if (['warning', 'error'].includes(result)) {
            onError?.();
        }
    }, [result, onError]);

    return (
        <div>
            <div
                className='border text-textPrimary bg-componentBackgroundSecondary border-outlinePrimary rounded-input mt-10'
                aria-label='Configuration Test Results'
            >
                {!loading && ['warning', 'error', 'success'].includes(result) && (
                    <div className='p-4'>
                        {result === 'warning' && (
                            <div className='font-medium text-statusWarningPrimary'>Testing passed with warnings</div>
                        )}
                        {result === 'error' && (
                            <div className='font-medium text-statusErrorPrimary'>Testing failed</div>
                        )}
                        {result === 'success' && (
                            <div className='font-medium text-statusHealthyPrimary'>Testing passed</div>
                        )}
                        {messages && <ValidationMessages messages={messages} />}
                        {result === 'error' && (
                            <div className='mt-4'>
                                You can continue to use this configuration, but you may encounter some issues.
                                {helpLink && (
                                    <a
                                        className='ml-2 text-textLink'
                                        href={helpLink}
                                        target='_blank'
                                        rel='noreferrer noopener'
                                    >
                                        Help adding this data source.
                                    </a>
                                )}
                            </div>
                        )}

                        <div className='flex justify-end items-end mt-8 '>
                            <Button
                                disabled={loading}
                                type='button'
                                variant='secondary'
                                className='ml-auto text-textPrimary'
                                onClick={reRun}
                            >
                                <FontAwesomeIcon className='mr-2' aria-hidden icon={faArrowsRotate} />
                                Rerun tests
                            </Button>
                        </div>
                    </div>
                )}
                {loading && (
                    <div className='inline-flex items-center p-4'>
                        <span className='mr-2'>
                            {!onPrem && !isSlowLoading && 'Testing configuration'}

                            {!onPrem && isSlowLoading && 'Still working, configuration test in progress'}

                            {onPrem && !isSlowLoading && 'Testing configuration (may take up to a minute)'}

                            {onPrem && isSlowLoading && 'Still working, plugin download is in progress'}
                        </span>
                        <LoadingSpinner />
                    </div>
                )}
            </div>
            <div ref={underRef} className='float-left clear-both' />
        </div>
    );
}
