import { motion } from 'framer-motion';
import { getOpacityFn } from './styles';
import { spring } from './transition';

interface LineGraphTrendLineProps {
    id: string;
    hoverId: string | number | undefined;
    width: number;
    y1: number;
    y2: number;
    x1: number;
    x2: number;
    opacity: number;
    color: string;
    onLineLeave: () => void;
    onLineHover: (id: string | number) => void;
    onMouseMove: (e: React.MouseEvent) => void;
}

export const LineGraphTrendLine: React.FC<LineGraphTrendLineProps> = ({
    id,
    hoverId,
    x1,
    x2,
    y1,
    y2,
    opacity,
    color,
    onLineHover,
    onLineLeave,
    onMouseMove
}) => {
    const handleMouseEnter = () => {
        onLineHover(id);
    };

    const handleMouseLeave = () => {
        onLineLeave();
    };

    const opacityFn = getOpacityFn(opacity, 0.2);

    return (
        <g>
            <motion.line
                opacity={opacityFn(id, hoverId)}
                animate={{
                    opacity: opacityFn(id, hoverId),
                    x1,
                    x2,
                    y1,
                    y2
                }}
                initial={{
                    x1,
                    x2,
                    y1,
                    y2
                }}
                transition={spring}
                strokeWidth={hoverId === id ? 3 : 2}
                stroke={color}
                strokeDasharray='10 4'
                pointerEvents='none'
                style={{ filter: 'brightness(1.2)' }}
            />
            {/* Invisible line to expand the mouseover area */}
            <line
                x1={x1}
                x2={x2}
                y1={y1}
                y2={y2}
                strokeWidth={10}
                stroke={'transparent'}
                pointerEvents='stroke'
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter}
                onMouseMove={onMouseMove}
            />
        </g>
    );
};
