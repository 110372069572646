import { Toggle } from '@/components/forms/Toggle';
import { cn } from '@/lib/cn';
import {
    AlertingRuleRenotifyBackoff,
    AlertingRuleRenotifySettings,
    AlertingRuleV2,
    calculateRenotifyDelayMs,
    formatRenotifyDuration,
    HealthState
} from '@squaredup/monitoring';
import { isFeatureEnabled } from '@squaredup/tenants';
import Field from 'components/forms/field/Field';
import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import { FeatureUnavailablePill } from 'components/plans/FeatureUnavailablePill';
import { ToggleStyleWrapper } from 'components/ToggleStyleWrapper';
import { range } from 'lodash';
import { useTier } from 'queries/hooks/useTier';

const defaultRenotifySettings = {
    enabled: false,
    states: ['error'] as HealthState[],
    intervalMins: 60,
    maxCount: 5,
    backoff: 'exponential' as const
};

export interface RenotifyOptionsProps {
    rule: AlertingRuleV2;
    setRule: (rule: AlertingRuleV2) => void;
}

const labellClassName = 'font-normal';

export const RenotifyOptions: React.FC<RenotifyOptionsProps> = ({ rule, setRule }) => {
    const { data: tier } = useTier();
    const renotifyFeatureEnabled = Boolean(tier && isFeatureEnabled(tier, 'renotify'));
    
    return (
        <div className='pt-1 ml-1'>
            <ToggleStyleWrapper
                name='toggleRepeatNotifications'
                label='Repeat notifications'
                labelClasses='!text-base !font-normal'
                help='Configure an interval to repeat notifications if a monitor stays in an error or warning state.'
                wrapperClassName={cn('pr-0 flex-grow', !renotifyFeatureEnabled && '[&_label]:text-textDisabled')}
                upgradePill={<FeatureUnavailablePill featureKey='renotify' className='ml-3' />}
            >
                <div className='w-full mr-6' aria-label='Repeat notifications'>
                    <Toggle
                        checked={(renotifyFeatureEnabled && rule.renotifySettings?.enabled) ?? false}
                        disabled={!renotifyFeatureEnabled}
                        onCheckedChange={() => {
                            setRule(
                                setRenotifySettings(rule, {
                                    enabled: Boolean(!rule.renotifySettings?.enabled)
                                })
                            );
                        }}
                    />
                </div>
            </ToggleStyleWrapper>
            {renotifyFeatureEnabled && rule.renotifySettings?.enabled && (
                <div className='flex flex-wrap gap-x-16 gap-y-4 mt-4 ml-6'>
                    <div className='flex-1 min-w-56'>
                        <Field.Input
                            name='renotifyInterval'
                            type='autocomplete'
                            label='Interval'
                            help='Interval between repeat notifications. May be fixed or increasing, depending on interval type.'
                            labelClassName={labellClassName}
                            className='border-none ring-inset ring-1 ring-outlinePrimary rounded-input bg-componentBackgroundPrimary'
                            isMulti={false}
                            isClearable={false}
                            isSearchable={false}
                            menuShouldBlockScroll={true}
                            defaultValue={rule.renotifySettings?.intervalMins?.toString() ?? '60'}
                            onSelect={(intervalOption: AutocompleteOption) => {
                                setRule(
                                    setRenotifySettings(rule, {
                                        intervalMins: parseInt(intervalOption.value)
                                    })
                                );
                            }}
                            options={[
                                {
                                    label: '30 minutes',
                                    value: '30'
                                },
                                {
                                    label: '1 hour',
                                    value: '60'
                                },
                                {
                                    label: '3 hours',
                                    value: '180'
                                },
                                {
                                    label: '6 hours',
                                    value: '360'
                                },
                                {
                                    label: '12 hours',
                                    value: '720'
                                },
                                {
                                    label: '24 hours',
                                    value: '1440'
                                }
                            ]}
                        />
                    </div>
                    <div className='flex-1 min-w-56'>
                        <Field.Input
                            name='renotifyIntervalType'
                            type='autocomplete'
                            label='Interval type'
                            labelClassName={labellClassName}
                            help='When selecting Increasing, the repeat interval doubles each time (e.g., 1 hour, 2 hours, 4 hours, etc.).'
                            className='border-none ring-inset ring-1 ring-outlinePrimary rounded-input bg-componentBackgroundPrimary'
                            isMulti={false}
                            isClearable={false}
                            isSearchable={false}
                            menuShouldBlockScroll={true}
                            defaultValue={rule.renotifySettings?.backoff ?? 'exponential'}
                            onSelect={(intervalOption: AutocompleteOption) => {
                                setRule(
                                    setRenotifySettings(rule, {
                                        backoff: intervalOption.value as AlertingRuleRenotifyBackoff
                                    })
                                );
                            }}
                            options={[
                                {
                                    label: 'Fixed',
                                    value: 'none'
                                },
                                {
                                    label: 'Increasing',
                                    value: 'exponential'
                                }
                            ]}
                        />
                    </div>
                    <div className='flex-1 min-w-56'>
                        <Field.Input
                            name='renotifyMaxOccurrences'
                            type='autocomplete'
                            label='Max. occurrences'
                            help='Maximum number of repeat notifications for a state change.'
                            labelClassName={labellClassName}
                            className='border-none ring-inset ring-1 ring-outlinePrimary rounded-input bg-componentBackgroundPrimary'
                            isMulti={false}
                            isClearable={false}
                            isSearchable={false}
                            menuShouldBlockScroll={true}
                            defaultValue={rule.renotifySettings?.maxCount?.toString() ?? '5'}
                            onSelect={(intervalOption: AutocompleteOption) => {
                                setRule(
                                    setRenotifySettings(rule, {
                                        maxCount: parseInt(intervalOption.value)
                                    })
                                );
                            }}
                            options={range(1, 11).map((i) => ({
                                label: i.toString(),
                                value: i.toString()
                            }))}
                        />
                    </div>
                    <div className='flex-1 min-w-56'>
                        <Field.Input
                            name='renotifyTriggerState'
                            type='autocomplete'
                            label='Trigger state'
                            help='States that can trigger a repeat notification.'
                            labelClassName={labellClassName}
                            className='border-none ring-inset ring-1 ring-outlinePrimary rounded-input bg-componentBackgroundPrimary'
                            isMulti={false}
                            isClearable={false}
                            isSearchable={false}
                            menuShouldBlockScroll={true}
                            defaultValue={rule.renotifySettings?.states?.length === 1 ? 'errorOnly' : 'errorAndWarning'}
                            onSelect={(intervalOption: AutocompleteOption) => {
                                setRule(
                                    setRenotifySettings(rule, {
                                        states: intervalOption.value === 'errorOnly' ? ['error'] : ['error', 'warning']
                                    })
                                );
                            }}
                            options={[
                                {
                                    label: 'Error only',
                                    value: 'errorOnly'
                                },
                                {
                                    label: 'Error and warning',
                                    value: 'errorAndWarning'
                                }
                            ]}
                        />
                    </div>
                    <div className='text-textSecondary'>
                        The last notification will be sent {getFinalNotificationDurationText(rule.renotifySettings)}{' '}
                        after the original state change.
                    </div>
                </div>
            )}
        </div>
    );
};

function setRenotifySettings(rule: AlertingRuleV2, settings: Partial<AlertingRuleRenotifySettings>): AlertingRuleV2 {
    return {
        ...rule,
        renotifySettings: {
            ...(rule.renotifySettings ?? defaultRenotifySettings),
            ...settings
        }
    };
}

function getFinalNotificationDurationText(renotifySettings: AlertingRuleRenotifySettings) {
    let totalDelayMs = 0;

    for (let i = 0; i < renotifySettings.maxCount; i++) {
        totalDelayMs += calculateRenotifyDelayMs(renotifySettings, i + 1);
    }

    return formatRenotifyDuration(totalDelayMs);
}