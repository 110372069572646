import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownMenu from 'components/dropdownMenu';
import { FC } from 'react';
import type { TileTypeSelectorComponentProps } from './TileTypeSelector';

interface DropdownItemProps {
    title: string;
    name: string;
    icon: IconProp;
    onSelect: () => void;
}

const DropdownItem: React.FC<DropdownItemProps> = ({ name, title, icon, onSelect }) => {
    return (
        <DropdownMenu.Item onSelect={onSelect} key={name}>
            <div className='flex items-center justify-left'>
                <FontAwesomeIcon icon={icon} size='lg' />
                <span className='ml-4'>{title}</span>
            </div>
        </DropdownMenu.Item>
    );
};

export const TileTypeDropdown: FC<TileTypeSelectorComponentProps> = ({ tileTypes, onTileTypeSelected }) => {
    return (
        <DropdownMenu>
            <DropdownMenu.Button>
                <span className='mr-4'>Configure</span>
                <FontAwesomeIcon icon={faChevronDown} />
            </DropdownMenu.Button>

            <DropdownMenu.Menu
                align='start'
                sideOffset={4}
                className='[width:var(--radix-dropdown-menu-trigger-width)] data-[state=open]:bg-secondaryButtonBackgroundHover'
            >
                {tileTypes.map((tileType) => {
                    return (
                        <DropdownItem
                            onSelect={() => onTileTypeSelected(tileType.name)}
                            key={tileType.name}
                            name={tileType.name}
                            title={tileType.displayName}
                            icon={tileType.icon}
                        />
                    );
                })}
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
};
