import Button from 'components/button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import { customTypesQueryKeys } from 'queries/queryKeys/customTypesKeys';
import { useMutation, useQueryClient } from 'react-query';
import { ClientSideCustomType, Delete } from 'services/CustomTypesService';

type CustomTypesDeleteModalProps = {
    customType: ClientSideCustomType;
    onClose: () => void;
};

/**
 *
 * @param {object} type Type to be deleted
 * @param {Function} onClose Handler invoked when cancelling/closing
 */
function CustomTypesDeleteModal({ customType, onClose }: CustomTypesDeleteModalProps) {
    const queryClient = useQueryClient();

    const {
        mutate: handleDelete,
        isLoading,
        isError
    } = useMutation(
        async () => {
            return Delete(customType.id);
        },
        {
            onSettled: async () => {
                await queryClient.invalidateQueries(customTypesQueryKeys.all);
            },
            onSuccess: async () => {
                onClose();
            }
        }
    );

    const description =
        'Are you sure you want to permanently delete this custom type? Associated objects will not be deleted.';

    return (
        <Modal
            title={`Delete Custom Type: ${customType.displayName}`}
            description={description}
            close={onClose}
            fullWidth
            maxWidth='max-w-3xl'
        >
            {isError && <p className='pl-8 text-statusErrorPrimary'>An error occurred, please try again.</p>}

            <ModalButtons>
                <Button variant='tertiary' onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    variant='destructive'
                    type='submit'
                    onClick={handleDelete}
                    data-testid='deleteCustomTypeConfirm'
                    disabled={isLoading}
                >
                    {isLoading ? <LoadingSpinner size={18} /> : 'Delete'}
                </Button>
            </ModalButtons>
        </Modal>
    );
}

export default CustomTypesDeleteModal;
