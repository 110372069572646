import Fuse from 'fuse.js';
import { useMemo, useState } from 'react';

type UseFilterSearchItemsArgs<T> = {
    items: T[];
    keys?: Fuse.FuseOptionKey[] | undefined;
};

export const useFilterSearchItems = <T>({ items, keys }: UseFilterSearchItemsArgs<T>) => {
    const [searchTerm, setSearchTerm] = useState('');
    const fuse = useMemo(() => {
        if (!items.length) {
            return undefined;
        }

        return new Fuse(items, {
            threshold: 0.4,
            ...(keys ? { keys } : {})
        });
    }, [items, keys]);

    const itemsFilteredBySearch = searchTerm ? fuse?.search(searchTerm).map(({ item }) => item) : items;

    return {
        searchItems: itemsFilteredBySearch,
        setSearchTerm
    };
};
