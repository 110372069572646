import { UseQueryOptions } from 'react-query';
import { List } from 'services/WorkspaceService';
import { IterableElement } from 'type-fest';
import { useWorkspaces } from './useWorkspaces';

type QueryReturnType = Awaited<ReturnType<typeof List>>;
type QuerySelectReturnType = IterableElement<QueryReturnType> | undefined;

/**
 * useQuery returning a specific workspace. It uses useWorkspaces under the hood to avoid duplicate lookups
 * @param workspaceId The workspaceId to lookup
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of all workspaces in a tenant
 */ 
export const useWorkspace = (
    workspaceId?: string | null,
    options?: Omit<UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>, 'select' | 'enabled'>
) => 
    useWorkspaces({
        enabled: Boolean(workspaceId),
        select: (data) => {
            return data.find(({ id }) => id === workspaceId);
        },
        ...options
    });
