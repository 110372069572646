import PluginContext from 'contexts/PluginContext';
import { useContext } from 'react';

interface ShowConfigValueProps {
    name: string;
    provider?: string;
    configFieldName: string;
}

/**
 * ShowConfigValue - allow configurable data stream template to show the user a value from the plugin
 * instance config
 *
 * @example
 * `<ShowConfigValue configFieldName="baseUrl" />`
 */
export default function ShowConfigValue(props: ShowConfigValueProps) {
    const pluginContext = useContext(PluginContext);
    const pluginConfig = pluginContext.config?.config;

    if(!pluginConfig) {
        // This should never practically happen but if it does we want to avoid a whole page crash
        // See SAAS-5493 and SAAS-5978
        return (
            <div className='text-statusErrorPrimary'>
                Failed to load data source config, please contact support.
            </div>
        );
    }

    return (
        <div className='px-5'>
            <span>{(pluginConfig as any)[props.configFieldName]}</span>
        </div>
    );
}
