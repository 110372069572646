import type { AccordionMultipleProps } from '@radix-ui/react-accordion';

/**
 * Utility function the returns whether a given panel value is active within the accordion controls
 * @param accordionControls Accordion controls
 * @param value Panel name to test
 * @returns boolean 
 */
export const getIsActivePanel = (
    accordionControls: AccordionMultipleProps, 
    value: string
) => {
    return Array.isArray(accordionControls.value) ? 
        accordionControls.value.includes(value) : 
        accordionControls.value === value;
};