import { linearRegression, linearRegressionLine } from 'simple-statistics';
import { LineSeries } from './lineGraphTypes';

export const getTrendLines = (series: LineSeries[]) => {
    const allXValues = series.flatMap((d) => d.data.map((s) => s.x));

    const xSeriesMin = Math.min(...allXValues);
    const xSeriesMax = Math.max(...allXValues);

    return series.map(({ id, data }) => {
        const points = data.map((d) => [d.x, d.y]);

        const { m, b } = linearRegression(points);

        const minY = linearRegressionLine({ m, b })(xSeriesMin);
        const maxY = linearRegressionLine({ m, b })(xSeriesMax);

        return {
            id,
            x1: xSeriesMin,
            x2: xSeriesMax,
            y1: minY,
            y2: maxY
        };
    });
};
