import Text from '@/components/Text';
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import { useDOMElement } from 'components/hooks/useDOMElement';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons, useModal } from 'components/Modal';
import trackEvent from 'lib/analytics';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import Auth from 'services/Auth';
import { SendUpgradeRequest } from 'services/TenantService';

type ContactSalesFormData = {
    emailAddress: string;
    firstName: string;
    lastName: string;
    message: string;
};

type ContactSalesModalProps = {
    source: string;
    organisationName: string;
    onClose: () => void;
};

type ContactSalesPanelProps = {
    organisationName: string;
    onClose: () => void;
    onSubmit: (data: ContactSalesFormData) => Promise<void>;
};

const getContactHref = (organisationName = '') =>
    `mailto:sales@squaredup.com?subject=Service%20Request&body=%0D%0A%0D%0A%E2%80%94-%0D%0AOrganisation%3A%20${organisationName}`;

type ErrorMessageProps = React.HTMLAttributes<HTMLDivElement> & { organisationName: string };

const ErrorMessage = ({ organisationName, ...rest }: ErrorMessageProps) => (
    <div className='flex items-center mt-6' {...rest}>
        <FontAwesomeIcon icon={faCircleXmark} className='mr-3 text-base text-statusErrorPrimary' />
        <Text.H4 aria-label='errorText'>
            An error occurred. Please contact{' '}
            <a className='text-textLink hover:underline focus:underline' href={getContactHref(organisationName)}>
                sales@squaredup.com
            </a>{' '}
            directly with your request.
        </Text.H4>
    </div>
);

const SuccessMessage = (props: React.HtmlHTMLAttributes<HTMLDivElement>) => (
    <div className='flex items-center mt-6' {...props}>
        <FontAwesomeIcon icon={faCircleCheck} className='mr-3 text-base text-statusHealthyPrimary' />
        <Text.H4 aria-label='successText'>
            Message sent successfully. Our sales team will be in contact with you shortly.
        </Text.H4>
    </div>
);

export const ContactSalesPanel = ({ onClose, onSubmit, organisationName }: ContactSalesPanelProps) => {
    const defaultValues: Partial<ContactSalesFormData> = {
        emailAddress: Auth.user?.email ?? '',
        firstName: undefined,
        lastName: undefined,
        message: undefined
    };

    const form = useForm<ContactSalesFormData>({
        defaultValues,
        shouldUnregister: true,
        mode: 'all'
    });

    const { isError, isSuccess, mutateAsync: handleSubmit } = useMutation(onSubmit);

    const isFormValid = Object.keys(form.formState.errors).length === 0 && form.formState.isValid;
    const isSubmitting = form.formState.isSubmitting;

    return (
        <FormProvider {...form}>
            <form className='flex flex-col flex-1 overflow-auto'>
                <hr className='border-dividerPrimary' />
                <div className='flex flex-col flex-1 px-8 py-4'>
                    <Field.Input
                        name='emailAddress'
                        type='email'
                        label='Email address'
                        validation={{ required: true }}
                        disabled={isSuccess || isSubmitting}
                    />
                    <Field.Input
                        name='firstName'
                        label='First name'
                        placeholder='Enter first name'
                        validation={{ required: true, maxLength: 256 }}
                        disabled={isSuccess || isSubmitting}
                    />
                    <Field.Input
                        name='lastName'
                        label='Last name'
                        placeholder='Enter last name'
                        validation={{ required: true, maxLength: 256 }}
                        disabled={isSuccess || isSubmitting}
                    />
                    <Field.Input
                        name='message'
                        label='Message'
                        type='textarea'
                        className='h-36 scrollbar-thumb-statusUnknownPrimary'
                        placeholder='Enter a message'
                        validation={{ required: true }}
                        disabled={isSuccess || isSubmitting}
                    />
                    {isSuccess && <SuccessMessage />}
                    {isError && <ErrorMessage organisationName={organisationName} />}
                </div>
                <ModalButtons>
                    {isSuccess ? (
                        <Button
                            type='button'
                            variant='primary'
                            onClick={() => onClose()}
                            data-testid='contact-sales-close'
                        >
                            Close
                        </Button>
                    ) : (
                        <>
                            <Button
                                type='button'
                                variant='tertiary'
                                onClick={() => onClose()}
                                data-testid='contact-sales-cancel'
                            >
                                Cancel
                            </Button>
                            <Button
                                // Can't use submit button, as ancestor could be a form and doing so
                                // will trigger form submission there too.
                                type='button'
                                onClick={form.handleSubmit((data) =>
                                    handleSubmit(data).catch(() => {
                                        /* required by hook */
                                    })
                                )}
                                data-testid='contact-sales-send'
                                disabled={!isFormValid || isSubmitting}
                            >
                                {isSubmitting ? <LoadingSpinner size={18} /> : 'Send'}
                            </Button>
                        </>
                    )}
                </ModalButtons>
            </form>
        </FormProvider>
    );
};

export const ContactSalesModal = ({ onClose: close, source, organisationName }: ContactSalesModalProps) => {
    const container = useDOMElement('dialogContent');
    const submitSalesMessage = async (data: ContactSalesFormData) => {
        const result = await SendUpgradeRequest({ ...data, source });

        if (!result.success) {
            // Trigger error handling in the form, but the action for the customer is the same with or without details.
            throw new Error('API did not return a successful response.');
        }
    };

    return (
        <Modal title='Contact sales' fullWidth maxWidth='max-w-3xl' close={close} container={container}>
            <ContactSalesPanel onClose={close} onSubmit={submitSalesMessage} organisationName={organisationName} />
        </Modal>
    );
};

export const useContactSalesModal = (source: string, tenantId: string | undefined, organisationName: string) => {
    const { isOpen, open, close } = useModal();

    const onContactSalesModalOpen = () => {
        if (tenantId) {
            trackEvent('Contact Sales Modal Opened', { tenantId });
        }
        open();
    };

    const contactSalesModal = isOpen ? (
        <ContactSalesModal onClose={close} source={source} organisationName={organisationName} />
    ) : null;

    return {
        openContactSalesModal: onContactSalesModalOpen,
        contactSalesModal
    };
};
