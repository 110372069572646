import { createContext, useContext } from 'react';
import { useDataStreamFilters } from '../TileEditor/hooks/useDataStreamFilters';

const TileEditorDataStreamFilterContext = createContext<Partial<ReturnType<typeof useDataStreamFilters>>>({
    selectedScopeId: ''
});

TileEditorDataStreamFilterContext.displayName = 'TileEditorDataStreamFilterContext';

export const useTileEditorDataStreamFilterContext = () => useContext(TileEditorDataStreamFilterContext);

export default TileEditorDataStreamFilterContext;
