import { Portal } from '@/components/Portal';
import { cn } from '@/lib/cn';
import { faBars, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { FC, SetStateAction } from 'react';

interface SidebarToggleProps {
    navOpen: boolean;
    setNavOpen: React.Dispatch<SetStateAction<boolean>>
}

export const SidebarToggle: FC<SidebarToggleProps> = ({ navOpen, setNavOpen }) => {
    const container = useDOMElement('sidebarPortal');

    return (
        <Portal
            tabIndex={-1}
            container={container ?? document.body}
        >
            <button
                className={cn(
                    'appearance-none flex items-center absolute text-textPrimary mx-6 z-50 mb-4 mt-[20px] h-[40.5px] transition transform duration-200',
                    navOpen ? '-translate-x-16 text-primaryButtonText' : '-translate-x-3'
                )}
                type='button'
                aria-expanded={navOpen}
                aria-pressed={navOpen}
                onClick={() => setNavOpen(!navOpen)}
            >
                <span className='sr-only'>{navOpen ? 'Close' : 'Open'} sidebar</span>
                <FontAwesomeIcon className='text-xl' icon={faBars} />
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    className={clsx('ml-1 transition-opacity text-xs duration-200', navOpen ? 'opacity-100' : 'opacity-0')}
                />
            </button>
        </Portal>
    );
};

