/* eslint-disable no-empty-function */

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

/**
 * Form
 * Simple form wrapper using React Hook Form to enable validation
 * Children can be passed directly or using a render prop to
 * access form state (isValid, isSubmitting, data).
 *
 * @example
 * `<Form submit={submit}><Field.Input ... /></Form>`
 */
function Form({ submit, onChange, className, children, defaultValues = {}, resetOnSubmit = false }) {
    const methods = useForm({ mode: 'all', defaultValues, shouldUnregister: true });
    const data = methods.watch();

    // Trigger validation if the default values change to ensure the validation state is correct
    useEffect(() => {
        if (Object.keys(defaultValues).length > 0) {
            methods.trigger();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(defaultValues)]);

    useEffect(() => {
        onChange?.(data);
    }, [data, onChange]);

    async function onSubmit(submittedData, e) {
        if (submit) {
            await submit(submittedData, e);
        }
        if (resetOnSubmit) {
            methods.reset();
        }
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className={`w-full ${className || ''}`} data-testid='form' onChange={() => {
                methods.trigger();
            }}>
                {typeof children === 'function'
                    ? children(methods.formState.isValid, methods.formState.isSubmitting, data)
                    : children}
            </form>
        </FormProvider>
    );
}

Form.propTypes = {
    /**
     * Function to handle submits, receives one argument which is an object containing the form data.
     */
    submit: PropTypes.func,
    /**
     * Class name to add to the form
     */
    className: PropTypes.string,
    /**
     * Function or list of children to add. Function receives three arguments: isValid, isSubmitting and form data.
     */
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    /**
     * Default values for form data
     */
    defaultValues: PropTypes.object,
    /**
     * Function to handle change events, receives the current form data
     */
    onChange: PropTypes.func
};

export default Form;
