import { Skeleton } from '@/components/Skeleton';
import { cn } from '@/lib/cn';
import { randomRange } from '@squaredup/utilities';
import { useMemo } from 'react';

/**
 * Generates a random line graph for the skeleton
 *
 * key part is non-scaling-stroke, allows the stroke to remain the same width regardless of scaling
 */
function generateGraph() {
    const strokeWidth = 8;
    const points = [] as string[];
    const numPoints = 12;
    const xStep = 100 / numPoints;
    let previousY = Math.random() * 100;
    // graph alternates between up and down lines
    let up = previousY < 50 ? true : false;

    for (let i = 0; i < numPoints; i++) {
        const nextYMin = up ? previousY : 5;
        const nextYMax = up ? 95 : previousY;
        const y = Math.floor(randomRange(nextYMin, nextYMax));
        const x = Math.floor(xStep * i);
        points.push(`${x} ${y}`);
        up = !up;
        previousY = y;
    }

    const mask = `
        url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" fill="none"><polyline stroke="black" stroke-width="${strokeWidth}" points="${points.join(
            ','
        )}"  vector-effect="non-scaling-stroke"/></svg>')
    `;

    return mask;
}

const LineGraphSkeleton = ({ className }: { className?: string }) => {
    const maskImage = useMemo(generateGraph, []);
    return (
        <Skeleton
            className={cn(
                'max-h-[80%] max-w-[90%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-none size-full',
                className
            )}
            style={{
                maskImage,
                maskRepeat: 'no-repeat',
                maskSize: '100% 100%'
            }}
        />
    );
};

export default LineGraphSkeleton;
