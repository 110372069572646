import Modal, { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';
import { useDOMElement } from 'components/hooks/useDOMElement';
import stableStringify from 'fast-json-stable-stringify';
import { useState } from 'react';
import { useTileEditorContext } from '../contexts/TileEditorContext';
import { TileCodeEditor } from './TileCodeEditor';

interface DataStreamTileEditorCodeModalProps {
    setIsModalOpen: (isOpen: boolean) => void;
}

/**
 * @returns Tile Code Editor modal
 */
export const DataStreamTileEditorCodeModal: React.FC<DataStreamTileEditorCodeModalProps> = ({ setIsModalOpen }) => {
    const { tileConfig, setTileConfig } = useTileEditorContext();
    const [currentConfig, setCurrentConfig] = useState(tileConfig);
    const [codeContainsError, setCodeContainsError] = useState(false);
    const container = useDOMElement('dialogContent');

    const handleSave = () => {
        setTileConfig(currentConfig);
        setIsModalOpen(false);
    };

    const handleDiscard = () => {
        setCurrentConfig(tileConfig);
        setIsModalOpen(false);
    };

    return (
        <Modal title='Edit JSON' container={container} maxWidth='w-3/4'>
            <div className='flex-1 min-h-0 pl-5 pr-5'>
                <TileCodeEditor
                    handleError={setCodeContainsError}
                    tileConfig={currentConfig}
                    update={setCurrentConfig}
                    heightConstraints={{ min: 500 }}
                />
            </div>
            <ModalButtons>
                <Button variant='secondary' onClick={handleDiscard}>
                    Cancel
                </Button>
                <Button
                    variant='primary'
                    disabled={stableStringify(tileConfig) === stableStringify(currentConfig) || codeContainsError}
                    onClick={handleSave}
                >
                    Save
                </Button>
            </ModalButtons>
        </Modal>
    );
};
