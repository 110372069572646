import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { NavTitle } from './NavLinks';

export const ExpandableNavSection: React.FC<{
    title: string;
    links: { href: string; label: string }[];
    initiallyExpanded?: boolean;
}> = ({ title, links, initiallyExpanded = true }) => {
    const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

    return (
        <div>
            <NavTitle
                title={title}
                className='flex items-center pl-4 pr-6 mb-2 text-base'
                icon={isExpanded ? faChevronUp : faChevronDown}
                onClick={() => setIsExpanded((wasExpanded) => !wasExpanded)}
                data-testid={`expand${title}`}
            />
            {isExpanded &&
                links.map(({ href, label }) => {
                    return (
                        <NavLink
                            to={`settings${href}`}
                            key={href}
                            className={({ isActive }) =>
                                `flex items-center py-1 pr-6 pl-[44px] hover:text-textPrimary ${
                                    isActive ? 'text-textPrimary font-semibold' : ''
                                }`
                            }
                        >
                            {label}
                        </NavLink>
                    );
                })}
        </div>
    );
};
