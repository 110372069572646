import { Button } from '@/components/Button';
import { Serialised } from '@squaredup/ids';
import Modal from 'components/Modal';
import { Presence } from 'components/Presence';
import type { Plugin } from 'dynamo-wrapper';
import { useState } from 'react';
import { InstallSampleDashboardsForm } from './InstallSampleDashboardsForm';

interface InstallSampleDashboardsModalProps {
    pluginData: Serialised<Plugin> | undefined;
    configID: string;
    displayName: string;
}

export const InstallSampleDashboardsModal = ({
    pluginData,
    configID,
    displayName
}: InstallSampleDashboardsModalProps) => {
    const [isOpen, setIsOpen] = useState(false);

    if (!pluginData) {
        return null;
    }

    const pluginDashboards = pluginData?.defaultContent?.dashboards ?? [];
    const hasPluginDashboards = pluginDashboards.length > 0;

    if (!hasPluginDashboards) {
        return null;
    }

    return (
        <section>
            <Button
                variant='secondary'
                onClick={() => setIsOpen(true)}
                data-testid='installSampleDashboardModalTrigger'
            >
                Install sample dashboards
            </Button>
            <Presence isPresent={isOpen}>
                <Modal
                    title='Install sample dashboards'
                    fullWidth
                    maxWidth='max-w-3xl'
                    maxHeight='max-h-3xl'
                    close={() => setIsOpen(false)}
                    headerBorder
                >
                    <InstallSampleDashboardsForm
                        pluginData={pluginData}
                        configID={configID}
                        displayName={displayName}
                        onClose={() => setIsOpen(false)}
                    />
                </Modal>
            </Presence>
        </section>
    );
};
