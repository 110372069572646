import { createContext, useContext } from 'react';

interface DrilldownContextValue {
    isDrilldownEnabled: boolean;
}

/**
 * Controls whether drilldowns are enabled in a given context
 */
export const DrilldownContext = createContext<DrilldownContextValue>({
    isDrilldownEnabled: true
});

DrilldownContext.displayName = 'DrilldownContext';

export const useDrilldownContext = () => useContext(DrilldownContext);
