import { Skeleton } from '@/components/Skeleton';
import clsx from 'clsx';
import Tooltip from 'components/tooltip/Tooltip';
import { DashboardContextValue } from 'contexts/DashboardContext';
import { Visualisation } from 'dashboard-engine/types/Visualisation';
import { useEffect, useState } from 'react';
import { GetDashboardImage } from 'services/ImageService';
import { ImageConfig } from './Config';
import { SVGImage } from './SVGImage';
import { StaticImage } from './StaticImage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Image: Visualisation<any, ImageConfig> = ({ config, context, tileId }: any) => {
    return <DisplayImage config={config} context={context} tileId={tileId} />;
};

export const DisplayImage: React.FC<{
    config: ImageConfig;
    context: DashboardContextValue;
    tileId: string;
    isPreview?: boolean;
}> = ({ config, context, tileId, isPreview = false }) => {
    const [dataUrl, setDataUrl] = useState('');

    const {
        src,
        title,
        uploaded = 0,
        showHealthState,
        healthStateMode,
        healthStateIconPosition,
        healthStateIconSize,
        ...props
    } = config;
    const [uploadedRead, setUploadedRead] = useState(uploaded);
    const [isReadErr, setIsReadErr] = useState(false);

    const { workspaceId, id } = context.dashboard;

    useEffect(() => {
        // Reset any current read error before doing / checking anything else
        setIsReadErr(false);

        if (src) {
            // We already have the image src so are good to go
            return;
        }

        if (!uploaded) {
            // We have no image src, no file content, and a tile image has never been uploaded so nothing to do
            if (dataUrl) {
                setDataUrl('');
            }
            return;
        }

        (async () => {
            try {
                let dataURL = '';
                if (uploaded) {
                    // A tile image has been uploaded so attempt to read it
                    ({ dataURL } = await GetDashboardImage(workspaceId, id, tileId, uploaded));
                    setUploadedRead(uploaded);
                }
                setDataUrl(dataURL);
            } catch {
                setIsReadErr(true);
            }
        })();
    }, [src, uploaded, workspaceId, id, dataUrl, tileId]);

    let noImage, stale;

    if (!src) {
        if (!uploaded) {
            noImage = true;
        } else if (uploadedRead < uploaded) {
            stale = true;
        }
    }

    if (src || (dataUrl && !isReadErr && !stale)) {
        return (
            <Tooltip disabled={!title} title={title} className='object-contain h-full'>
                {showHealthState && (src || dataUrl).startsWith('data:image/svg') ? (
                    <SVGImage
                        src={src || dataUrl}
                        tileId={`${tileId}-${isPreview ? 'preview' : 'noPreview'}`}
                        isPreview={isPreview}
                        healthConfig={{
                            mode: healthStateMode,
                            iconPosition: healthStateIconPosition,
                            iconSize: healthStateIconSize
                        }}
                    />
                ) : (
                    <StaticImage {...props} src={src || dataUrl} title={title} />
                )}
            </Tooltip>
        );
    } else if (noImage || isReadErr) {
        return (
            <pre
                className={clsx(
                    'flex flex-col justify-center h-full text-center whitespace-normal',
                    noImage ? 'text-textSecondary' : 'text-statusErrorPrimary'
                )}
                data-testid='noImageReason'
            >
                {noImage ? 'No image to display' : 'Failed to read image'}
            </pre>
        );
    } else {
        return <Skeleton className='size-full' />;
    }
};

Image.config = 'ImageConfig';

export default Image;
