export const NUM_Y_TICKS = 4;

/**
 * Calculate the Y grid values based on config & data
 * @param showGrid show or hide the grid
 * @param series line series
 * @param yScaleOptions Configured y scale options
 * @returns Number / array of grid values 
 */
export const getGridYValues = (showGrid: boolean, configuredMinValue: number | 'auto', dataMinValue: number) => {
    // If grid is enabled, show the default number of grid lines
    if(showGrid) {
        return NUM_Y_TICKS;
    }

    // Otherwise we just want to show a single grid line at the bottom
    if(configuredMinValue === 'auto') {
        return [dataMinValue];
    }

    return [configuredMinValue];
};