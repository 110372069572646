import { cn } from '@/lib/cn';
import { ComponentProps, forwardRef } from 'react';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';

export const NavLink = forwardRef<HTMLAnchorElement, ComponentProps<typeof ReactRouterNavLink>>(
    ({ to, children, className, ...rest }, ref) => {
        return (
            <ReactRouterNavLink
                ref={ref}
                to={to}
                className={({ isActive }) =>
                    cn(
                        'flex pb-2 hover:text-textPrimary leading-input items-center',
                        isActive && 'text-textPrimary font-semibold',
                        className
                    )
                }
                {...rest}
            >
                {children}
            </ReactRouterNavLink>
        );
    }
);
