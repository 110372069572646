/* eslint-disable no-console */
import PasswordReset from 'pages/passwordReset/PasswordReset';
import SelectTenant from 'pages/selectTenant/SelectTenant';
import { Route, Routes } from 'react-router-dom';
import { SignInFail } from 'ui/errorHandling/SignInFail';
import Auth from './';

export default function requireAuth({ children }) {
    // User is authenticated and has tenant
    if (Auth.user && Auth.user.tenant) {
        return children;
    }

    // User is authenticated, and in multiple tenants, but has not chosen one
    // Show page to select tenant
    if (Auth.user && Auth.user.tenants) {
        return (
            <Routes>
                <Route path='/*' element={<SelectTenant signIn={true}/>} />
            </Routes>
        );
    }

    // Show password reset page if user has reset their password
    if (Auth.user && Auth.user.passwordReset) {
        return (
            <Routes>
                <Route path='/*' element={<PasswordReset />} />
            </Routes>
        );
    }

    // User has authenticated but they don't have access to any tenants.
    if (Auth.user && getStandardTenants().length === 0) {
        return <SignInFail email={Auth.user.email} />;
    }

    Auth.login();

    return null;
}

function getStandardTenants() {
    return Auth.user?.tenants?.filter(tenant => tenant !== 'SquaredUp Cloud Administrators') ?? [];
}
