import DropdownMenu from '@/components/DropdownMenu';
import { faClipboard } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamBaseTileConfig, StreamPrimitive } from '@squaredup/data-streams';
import { useTileContext } from 'contexts/TileContext';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import useCopyToClipboard from 'lib/useCopyToClipboard';
import ClipboardToast, { toastIds } from 'ui/notifications/ClipboardToast';

type CopyDataProps = {
    useUnshaped?: boolean;
};

export const CopyData: React.FC<CopyDataProps> = ({ useUnshaped }) => {
    const { config } = useTileContext();
    const { data, isConfigured } = useDataStreamConfig({
        ...config,
        ...(useUnshaped && {
            // If unshaped, clear any shaping props
            dataStream: {
                ...config.dataStream,
                filter: undefined,
                group: undefined,
                sort: undefined
            }
        })
    } as DataStreamBaseTileConfig);

    const headers = data.metadata.columns.map((c) => c.displayName);
    const rows = data.rows.map((r) => r.map((c) => c.raw));

    // Wire up the copy tile ID actions
    const { copy } = useCopyToClipboard({
        onNotSupported: () => {
            const log = data.rows.map((streamRow) =>
                data.metadata.columns.reduce(
                    (row, col, index) => {
                        row[col.name] = streamRow[index].raw;
                        return row;
                    },
                    {} as Record<string, StreamPrimitive>
                )
            );

            // eslint-disable-next-line no-console
            console.table(log);
            ClipboardToast('Clipboard not available, data logged to console.', false, toastIds.SUCCESS);
        },
        onSuccess: () => {
            ClipboardToast('Tile data copied to clipboard!', false, toastIds.SUCCESS);
        },
        onError: () => {
            ClipboardToast('Error: Tile data could not be copied to clipboard.', true, toastIds.ERROR);
        }
    });

    if (!isConfigured) {
        return null;
    }

    return (
        <DropdownMenu.Item
            icon={<FontAwesomeIcon icon={faClipboard} fixedWidth />}
            onSelect={() => copy(`${headers.join('\t')}\n${rows.map((r) => r.join('\t')).join('\n')}`)}
            key='copyData'
        >
            Copy data
        </DropdownMenu.Item>
    );
};
