import Text from '@/components/Text';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { Action } from 'pages/components/ApplicationTable/types';
import { FC } from 'react';
import TruncateWithTooltip from '../TruncateWithTooltip';
import { ProjectedTagProperty } from './TagProperties';

interface TagPropertiesTableProps {
    isFeatureAvailable: boolean;
    tagProperties: ProjectedTagProperty[];
    onDelete: (tagProperty: ProjectedTagProperty) => void;
}

const PropsApplicationTable = ApplicationTable<ProjectedTagProperty, string>();

const TagPropertiesTable: FC<TagPropertiesTableProps> = ({ tagProperties, onDelete, isFeatureAvailable }) => {
    const columns: ColumnDef<ProjectedTagProperty>[] = [
        {
            id: 'name',
            header: 'Name',
            cell: ({ row }) => {
                return (
                    <div className='flex items-center gap-2'>
                        <TruncateWithTooltip title={row.original.displayName || ''}>
                            <div className='truncate'>{row.original.displayName}</div>
                        </TruncateWithTooltip>
                        {!isFeatureAvailable && (
                            <Text.SmallBody className='text-textSecondary'>(disabled)</Text.SmallBody>
                        )}
                    </div>
                );
            },
            size: 300,
            accessorKey: 'displayName'
        },
        {
            id: 'type',
            header: 'Type',
            cell: ({ row }) => row.original.subType,
            size: 300,
            accessorKey: 'subType'
        }
    ];

    const actions: Action[] = [
        {
            action: onDelete,
            icon: faTrash,
            dataTestId: 'deleteButton',
            tooltip: 'Delete tag property'
        }
    ];

    return (
        <div className='flex flex-col flex-1 w-full h-full min-h-0'>
            <PropsApplicationTable
                config={{
                    actions,
                    noDataMessage: 'There are no tag properties configured.'
                }}
                columns={columns}
                data={tagProperties}
            />
        </div>
    );
};

export default TagPropertiesTable;
