import { EntityTypes } from '@squaredup/constants';
import { Node } from '@squaredup/graph';
import { getWorkspaceIdFromConfigId } from '@squaredup/ids';
import stringify from 'fast-json-stable-stringify';
import { groupBy, uniqueId } from 'lodash';
import { Query } from 'services/GraphService';
import type { Tenant } from 'services/TenantService';
import { NodesAndEdges } from './types';

const nodeValues = [
    '__configId',
    'name',
    'sourceId',
    'type'
];


interface WorkspaceConnectionPath {
    labels: string[];
    objects: Node[]
}

export const fetchConnectedWorkspaceData = async (
    tenant: Awaited<ReturnType<typeof Tenant>>
): Promise<NodesAndEdges | undefined> => {
    let gremlinQuery = 'g.V()';
    let bindings: Record<string, any> = {};

    gremlinQuery += `.has("sourceType", "squaredup/space") 
        .coalesce(
            outE().inV().has("sourceType", "squaredup/dash")
            .outE().inV().has("sourceType", "squaredup/monitor")
            .outE().inV().has("sourceType", "squaredup/space").simplePath(),
            identity()
        )
        .path()     
        .by(valueMap(true,${nodeValues.map(value => `"${value}"`).join()}))
    `;

    const [
        workspaceConnectionPaths,
        KPIs
    ] = await Promise.all([
        Query({
            gremlinQuery,
            bindings
        }, 'directOrAnyWorkspaceLinks'),
        Query({
            gremlinQuery: 'g.V().has("sourceType", "squaredup/kpi").valueMap(true)',
            bindings: {}
        }, 'directOrAnyWorkspaceLinks')
    ]);

    const { nodes, edges } = (workspaceConnectionPaths.gremlinQueryResults as WorkspaceConnectionPath[]).reduce(
        (workspaceConnections, { objects }) => {
            const startWorkspace = objects[0];
            const endWorkspace = objects[objects.length - 1];
            
            if (!workspaceConnections.workspaceIds.has(startWorkspace.id)) {
                workspaceConnections.nodes.push(startWorkspace);
                workspaceConnections.workspaceIds.add(startWorkspace.id);
            }

            if (objects.length > 1 && endWorkspace.id !== startWorkspace.id) {
                if (!workspaceConnections.workspaceIds.has(startWorkspace.id)) {
                    workspaceConnections.workspaceIds.add(startWorkspace.id);
                    workspaceConnections.nodes.push(endWorkspace);
                }

                workspaceConnections.edges.push({
                    id: uniqueId('edge-'),
                    inV: endWorkspace.id,
                    outV: startWorkspace.id,
                    label: 'Dependency'
                });
            }

            return workspaceConnections;
    }, { nodes: [], edges: [], workspaceIds: new Set() } as NodesAndEdges & { workspaceIds: Set<string> });

    const kpisByWorkspaceId = new Map((
        Object.entries(groupBy(KPIs.gremlinQueryResults, ({ __configId }) => getWorkspaceIdFromConfigId(__configId[0])))
            .map(([ workspaceId, kpis ]) => [workspaceId, kpis.map(({ name, tileId, dashId: dashboardId }) => ({ 
                name, 
                dashboardId, 
                tileId 
            }))])
    ));

    const topLevelNodeIds = nodes.reduce((topNodes, { id }) => {
        const upstreamWorkspace = edges.filter(({ inV }) => inV === id);
        
        if (!upstreamWorkspace.length) {
            topNodes.push(id);
        } 

        // Handle circular dependencies at the top level. i.e. nodes connected to one another but no other's above them
        if (upstreamWorkspace.length === 1) {
            const upstreamWorkspaceConnections = edges.filter(({ inV }) => inV === upstreamWorkspace[0].outV);

            if (upstreamWorkspaceConnections.length === 1 && upstreamWorkspaceConnections[0].outV === id) {
                topNodes.push(id);
            }
        }

        return topNodes;
    }, [] as string[]);

    // Add organisation node
    nodes.push({
        id: tenant.id,
        name: tenant.displayName ?? 'My Organization'
    });

    // Add edges from Organisation node to topmost workspaces/KPIs
    edges.push(...topLevelNodeIds.map((nodeId) => ({
        id: `${tenant.id}-${nodeId}`,
        label: 'Dependency',
        inV: nodeId,
        outV: tenant.id
    })));

    return { 
        nodes: nodes.map((node) => ({
            ...node,
            type: !node.type?.[0] ? [EntityTypes.TENANT] : ['KPI'],
            kpis: stringify(kpisByWorkspaceId.get(node.sourceId?.[0] ?? ''))
        })), 
        edges 
    };
};
