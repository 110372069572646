import { linearGradientDef } from '@nivo/core';

const basicGradient = (name, opacity) => linearGradientDef(name, [
    { offset: 0, color: 'inherit', opacity},
    { offset: 100, color: 'inherit', opacity: 0 }
]);

const opacityGradient = (name, opacity = 0.2) => linearGradientDef(name, [
    { offset: 0, color: 'inherit', opacity },
    { offset: 100, color: 'inherit', opacity }
]);

export { basicGradient, opacityGradient };
