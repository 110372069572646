import { cn } from '@/lib/cn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { CellProps } from './CellProps';

export const BooleanCell: React.FC<CellProps> = ({ value, style }) => {
    if (value == null) {
        return null;
    }

    return (
        <div
            className={cn('flex items-center', {
                'justify-center': style.align === 'center',
                'justify-start': style.align === 'left',
                'justify-end': style.align === 'right'
            })}
        >
            {value ? (
                <FontAwesomeIcon icon={'square-check'} fixedWidth className='h-full' />
            ) : (
                <FontAwesomeIcon icon={'square-xmark'} fixedWidth className='h-full opacity-25' />
            )}
        </div>
    );
};
