import type { DashboardTileContent } from '@squaredup/dashboards';
import { GridLayout } from 'lib/gridLayout';
import { rowHeight } from '../gridConstants';
import { layoutFill } from '../gridUtils';
import { PlaceholderTile } from './PlaceholderTile';

interface PlaceholderGridProps {
    width: number;
    cols: number;
    rows: number;
    onClick: (cell: DashboardTileContent) => void;
}

export const PlaceholderGrid: React.FC<PlaceholderGridProps> = ({ width, cols, rows, onClick }) => (
    <GridLayout
        cols={cols}
        width={width}
        margin={[10, 10]}
        containerPadding={[0, 0]}
        isDraggable={false}
        isResizable={false}
        rowHeight={rowHeight}
    >
        {layoutFill(rows, cols).map((cell) => (
            <div
                className='transition-none placeholder'
                onClick={() => onClick && onClick(cell)}
                key={cell.i}
                data-grid={cell}
                data-testid='gridPlaceholder'
            >
                <PlaceholderTile />
            </div>
        ))}
    </GridLayout>
);
