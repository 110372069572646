import { ClientDataStreamRequest, DataStreamBaseTileConfig, DataStreamOptions, VisualisationConfig, VisualisationHint } from '@squaredup/data-streams';

export const useGenerateDataStreamTileConfig = (
    dataStreamId: string, 
    dataStreamName?: string,
    title?: string, 
    visualisation?: VisualisationHint,
    scope?: ClientDataStreamRequest['scope'], 
    timeframe?: ClientDataStreamRequest['timeframe'],
    dataStreamOptions?: DataStreamOptions,
    visualisationConfig?: VisualisationConfig['config'],
    sourceId?: string
): DataStreamBaseTileConfig => {
    return {
        _type: 'tile/data-stream',
        title,
        scope,
        dataStream: {
            id: dataStreamId,
            name: dataStreamName,
            pluginConfigId: sourceId,
            ...dataStreamOptions
        },
        ...(visualisation && {
            visualisation: {
                type: visualisation,
                ...(visualisationConfig && {
                    config: {
                        [visualisation]: visualisationConfig
                    }
                })

            }
        }),
        ...(timeframe && { timeframe })
    } as DataStreamBaseTileConfig;
};
