import { cn } from '@/lib/cn';
import { ForwardedRef, HTMLAttributes, ReactNode, forwardRef } from 'react';

type TextProps = {
    children?: ReactNode;
    className?: string;
};

const H1 = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement>>(
    ({ children, className, ...props }: TextProps, ref: ForwardedRef<HTMLHeadingElement>) => {
        return (
            <h1
                className={cn('font-montserrat text-[37px] font-semibold -tracking-[.005em]', className)}
                ref={ref}
                {...props}
            >
                {children}
            </h1>
        );
    }
);

const H2 = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement>>(
    ({ children, className, ...props }: TextProps, ref: ForwardedRef<HTMLHeadingElement>) => {
        return (
            <h2
                className={cn('font-montserrat text-[22px] font-semibold -tracking-[.005em]', className)}
                ref={ref}
                {...props}
            >
                {children}
            </h2>
        );
    }
);

const H3 = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement>>(
    ({ children, className, ...props }: TextProps, ref: ForwardedRef<HTMLHeadingElement>) => {
        return (
            <h3
                className={cn('font-montserrat font-semibold text-[16px] -tracking-[.005em]', className)}
                ref={ref}
                {...props}
            >
                {children}
            </h3>
        );
    }
);

const H4 = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement>>(
    ({ children, className, ...props }: TextProps, ref: ForwardedRef<HTMLHeadingElement>) => {
        return (
            <h4
                className={cn('font-montserrat font-semibold text-[14px]', className)}
                ref={ref}
                {...props}
            >
                {children}
            </h4>
        );
    }
);

const H5 = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement>>(
    ({ children, className, ...props }: TextProps, ref: ForwardedRef<HTMLHeadingElement>) => {
        return (
            <h5
                className={cn('font-montserrat font-semibold text-[12px]', className)}
                ref={ref}
                {...props}
            >
                {children}
            </h5>
        );
    }
);

const Body = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLParagraphElement>>(
    ({ children, className, ...props }: TextProps, ref: ForwardedRef<HTMLParagraphElement>) => {
        return (
            <p
                className={cn('font-montserrat text-[14px] -tracking-[.005em]', className)}
                ref={ref}
                {...props}
            >
                {children}
            </p>
        );
    }
);

const SmallBody = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLParagraphElement>>(
    ({ children, className, ...props }: TextProps, ref: ForwardedRef<HTMLParagraphElement>) => {
        return (
            <p
                className={cn('font-montserrat text-[12px] leading-[16px] -tracking-[.005em]', className)}
                ref={ref}
                {...props}
            >
                {children}
            </p>
        );
    }
);

const Label = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>(
    ({ children, className, ...props }: TextProps, ref: ForwardedRef<HTMLSpanElement>) => {
        return (
            <span 
                className={cn('text-[11px] font-inter', className)}
                ref={ref}
                {...props}
            >
                {children}
            </span>
        );
    }
);

const Text = {
    H1, H2, H3, H4, H5,
    Body, SmallBody, Label
};

export default Text;
