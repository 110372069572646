import { Skeleton } from '@/components/Skeleton';
import { cn } from '@/lib/cn';

const ScalarSkeleton = ({ className }: { className?: string }) => {
    return (
        <Skeleton
            className={cn(
                'max-h-[70%] max-w-[70%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 size-full',
                className
            )}
        />
    );
};

export default ScalarSkeleton;
