import { buildQuery } from '@squaredup/graph';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import stringify from 'fast-json-stable-stringify';
import { useQuery } from 'react-query';
import { Query } from 'services/GraphService';
import { PluginSourceConfig } from 'services/SourceConfigService';

export const SOURCE_TYPE_MAP = 'sourceTypeMap';

/**
 * @param sources PluginSourceConfig[]
 * @returns useQuery props for a map of sourceTypes -> types for objects that belong to the passed sources
 */
export const useSourceTypesToTypesMap = (sources?: PluginSourceConfig[] | undefined) => {
    const { workspace, isGlobal } = useDataStreamWorkspaceContext();

    const sourceIds = stringify(sources?.map(({ id }) => ({ value: id })));

    return useQuery(
        [SOURCE_TYPE_MAP, workspace, sourceIds],
        async () => {
            const { gremlinQueryResults } = await Query(
                buildQuery(
                    { plugins: sources!.map(({ id }) => ({ value: id })) },
                    '.has("sourceType").group().by("sourceType").by(choose(has("type"), values("type"), values("sourceType")))'
                ),
                isGlobal ? 'directOrAnyWorkspaceLinks' : undefined
            );

            return new Map(Object.entries<string>(gremlinQueryResults[0]));
        },
        { enabled: Boolean(sources) }
    );
};
