import { HealthState } from '@squaredup/monitoring';
import { UseQueryOptions } from 'react-query';
import { useDashboardHealthStateRollup } from './useDashboardHealthStateRollup';
import { TileState, TileStates } from 'queries/types/types';

type QueryReturnType = {
    state: HealthState;
    tileStates: TileStates;
};
type QuerySelectReturnType = TileState | undefined;

/**
 * useQuery returning a specific tile's healthstate. It uses useDashboardHealthStateRollup under the hood to 
 * avoid duplicate lookups
 * @param workspaceId The workspaceId to lookup
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of all workspaces in a tenant
 */ 
export const useTileHealthState = (
    tileId?: string,
    dashboardId?: string,
    options?: Omit<UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>, 'select' | 'enabled'>
) => 
    useDashboardHealthStateRollup(
        dashboardId,
        {
            enabled: Boolean(tileId && dashboardId),
            select: (data) => tileId ? data.tileStates?.[tileId] : undefined,
            ...options
        }
    );