import { useDatasourceConfigsForAdministration } from 'queries/hooks/useDatasourceConfigsForAdministration';
import {
    PluginSourceConfig
} from 'services/SourceConfigService';

const getDateOfLastAcknowledgedImport = () => localStorage.getItem('timeOfLastAcknowledgedImport') ?? false;
const setDateOfLastAcknowledgedImport = (timeLastAcknowledged: number) =>
    localStorage.setItem('timeOfLastAcknowledgedImport', timeLastAcknowledged.toString());

const getLatestFailure = (latestFailureDate: number, pluginConfig: PluginSourceConfig) => {
    const importStatus = pluginConfig.importStatus;

    if (importStatus?.status !== 'failed') {
        return latestFailureDate;
    }

    if (importStatus.ended > latestFailureDate.valueOf()) {
        return importStatus.ended;
    }

    return latestFailureDate;
};

const acknowledgeLatestFailure = (plugins: PluginSourceConfig[]) => {
    // If we don't have a failed plugin we acknowledge everything up to a second ago,
    // to allow for cases where the client clock is ahead of our backend
    const latestFailure = plugins.reduce(getLatestFailure, Date.now() - 1_000);
    setDateOfLastAcknowledgedImport(latestFailure);
};

export const useImportStatus = () => {
    const onPluginsPage = window.location.pathname.includes('plugins');
    const dateOfLastAcknowledgedImport = getDateOfLastAcknowledgedImport();

    const { data: plugins = [] } = useDatasourceConfigsForAdministration({
        refetchInterval: 30_000,
        onSuccess: (successPlugins) => {
            if (onPluginsPage) {
                acknowledgeLatestFailure(successPlugins);
            }
        }
    });

    const pluginImports = plugins.map((p) => p.importStatus);
    const pluginHasFailed = pluginImports.some((p) => {
        return (
            p?.status === 'failed' && (!dateOfLastAcknowledgedImport || p.ended >= Number(dateOfLastAcknowledgedImport))
        );
    });

    const hasFailedImports = pluginHasFailed && !onPluginsPage;

    return {
        hasFailedImports,
        acknowledgeFailedImports: () => acknowledgeLatestFailure(plugins)
    };
};
