import { cn } from '@/lib/cn';
import { TruncateList } from 'components/TruncateList';
import Text from 'components/primitives/components/Text';
import { MouseEvent } from 'react';

interface DataStreamOptionTagsProps {
    tags: string[];
    isDataStreamSelected: boolean;
    selectedTags: string[];
    toggleTag: (tag: string) => void;
}

export const DataStreamOptionTags: React.FC<DataStreamOptionTagsProps> = ({
    tags,
    selectedTags,
    isDataStreamSelected,
    toggleTag
}) => {
    const handleToggleTag = (e: MouseEvent<HTMLButtonElement>, tag: string) => {
        e.preventDefault();
        e.stopPropagation();

        toggleTag(tag);
    };

    return (
        <TruncateList
            canExpand={false}
            itemMargin={4}
            items={tags.map((tag) => ({
                label: tag,
                component: (
                    <button
                        key={tag}
                        className={cn('bg-tileBackground border border-outlinePrimary rounded-full px-2 py-0.5 mr-1', {
                            'bg-filterActive': selectedTags.includes(tag),
                            'bg-tagBackground': isDataStreamSelected && selectedTags.includes(tag)
                        })}
                        data-testid='datastreamTag'
                        onClick={(e) => handleToggleTag(e, tag)}
                    >
                        <Text.SmallBody className='text-textPrimary'>{tag}</Text.SmallBody>
                    </button>
                )
            }))}
        />
    );
};
