import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import Tooltip from 'components/tooltip/Tooltip';
import { getIconForNode, getNameForType, getTypeNameForNode } from 'lib/types';
import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import WorkspaceNode from './WorkspaceNode';
import { getStylesForNode } from './utils/getStylesForNode';
import { cn } from '@/lib/cn';
import { isNone } from '@squaredup/data-streams';
import { stateStrings } from '@squaredup/monitoring';

export default memo(({ data }: NodeProps) => {
    const isSelected = data.selected;
    const radius = data.radius;
    const isWorkspaceNode = data.sourceType?.includes('squaredup/space') ?? false;
    const isShowingLabel = data.showLabel;
    const nodeState = !isNone(data.state) && data.state ? data.state : stateStrings.unknown;
    const nodeStyles = getStylesForNode(data.state, isWorkspaceNode);
    const nodeSubLabel = `${data.sourceName ? data.sourceName[0] : ''}${
        data.sourceName && data.sourceType ? ' - ' : ''
    }${data.sourceType ? getNameForType(data.sourceType[0], 'singular') : ''}`;

    return (
        <>
            <Tooltip
                title={`${data.label} (${getTypeNameForNode(data, 'singular')})`}
                className='cursor-default'
                disabled={isShowingLabel}
            >
                <div
                    className={`flex items-center justify-center transition overflow-hidden cursor-pointer ${nodeStyles}`}
                    style={{ width: radius * 2, height: radius * 2, position: 'relative' }}
                    aria-label='nodeStyles'
                    data-teststate={nodeState}
                >
                    <Handle
                        style={{ visibility: 'hidden', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                        type='source'
                        position={Position.Top}
                    />

                    {isWorkspaceNode ? (
                        <WorkspaceNode
                            id={data.id}
                            className={nodeStyles}
                            isSelected={isSelected}
                            state={nodeState}
                            size={radius * 2}
                        />
                    ) : (
                        <>
                            <FontAwesomeIcon
                                className={cn(
                                    'text-primaryButtonText', 
                                    nodeState === stateStrings.unknown && 'text-textPrimary',
                                    isShowingLabel ? 'opacity-0' : 'opacity-100'
                                )}
                                icon={getIconForNode(data)}
                                size='3x'
                            />
                            <span className='sr-only'>{data.label}</span>
                        </>
                    )}

                    <Handle
                        style={{ visibility: 'hidden', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                        type='target'
                        position={Position.Bottom}
                    />
                </div>
                {isWorkspaceNode && (
                    <span className='absolute inline-block w-full max-h-full px-8 font-bold text-center truncate -translate-x-1/2 -translate-y-1/2 text-textPrimary left-1/2 top-1/2'>
                        {data.label}
                    </span>
                )}
                <Handle type='source' position={Position.Bottom} style={{ visibility: 'hidden' }} />
            </Tooltip>

            {isShowingLabel && (
                <div
                    className={cn(
                        'absolute text-center px-4 pt-1 pb-2 transition-opacity transform -translate-x-1/2 -translate-y-1/2 pointer-events-none border-4 whitespace-nowrap top-1/2 left-1/2 text-medium',
                        getStylesForNode(nodeState, false)
                    )}
                >
                    <p className={clsx('text-primaryButtonText font-bold', nodeState === stateStrings.unknown && 'text-textPrimary')}>{data.label}</p>
                    <p className={clsx('text-sm text-primaryButtonText', nodeState === stateStrings.unknown && 'text-textSecondary')}>{nodeSubLabel}</p>
                </div>
            )}
        </>
    );
});
