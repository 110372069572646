import * as Constants from '@squaredup/constants';
import { ConfigId, Serialised } from '@squaredup/ids';
import { ConfirmationPrompt } from 'components/ConfirmationPrompt';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { useAgentGroups } from 'components/hooks/useAgentGroups';
import { usePluginPermissions } from 'components/hooks/usePluginPermissions';
import { ModalCloseReason } from 'components/Modal';
import { PluginMutationDetails } from './components/useMutatePlugin';
import type { GroupCountResult, ProjectedConfig } from 'dynamo-wrapper';
import stableStringify from 'fast-json-stable-stringify';
import trackEvent from 'lib/analytics';
import { sortBy, throttle } from 'lodash';
import { useOAuthRedirectParam } from 'pages/datasources/components/useOAuthRedirectParam';
import { useDatasourceConfigsForAdministration } from 'queries/hooks/useDatasourceConfigsForAdministration';
import { useWorkspacesForAdministration } from 'queries/hooks/useWorkspaceForAdministration';
import { datasourceConfigQueryKeys } from 'queries/queryKeys/datasourceConfigKeys';
import { useEffect, useMemo, useState } from 'react';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import {
    Delete,
    Get,
    Import,
    PluginSourceConfig
} from 'services/SourceConfigService';
import {
    DataSourceNodeCount,
    ALL_DATA_SOURCES_OBJECTS_COUNT_KEY,
    PER_DATA_SOURCE_OBJECTS_COUNT_KEY
} from 'services/UsageService';
import { Workspace } from 'services/WorkspaceService';
import { invalidateAfterWorkspaceLinksChange } from 'services/WorkspaceUtil';
import PluginConfigModal from './PluginConfigModal';
import PluginModal from './PluginModal';
import PluginsTable from './PluginsTable';
import PluginsWorkspacesModal from './PluginsWorkspacesModal';
import { useImportStatus } from './useImportStatus';
import { SettingsTemplate } from '../SettingsTemplate';
import { useDatasourceConfigsCount } from 'queries/hooks/useDatasourceConfigsCount';
import { LimitReachedBanner } from 'components/plans/LimitReachedBanner';
import { BelowFeatureLimit } from 'components/plans/BelowFeatureLimit';
import { useTenant } from 'queries/hooks/useTenant';
import { oneMinute, fiveMinutes } from 'queries/constants';

interface Source extends Serialised<ProjectedConfig> {
    label: string;
    source: Constants.DataSource;
    description: string;
}

export interface PluginProjectedRow extends PluginSourceConfig {
    nameAndVersion: string;
    workspaceCount: number;
    onPrem: boolean;
}

const throttledUpdateDataSourceObjectsCountFuncMap =
    new Map<string, (queryClient: QueryClient, invalidate?: boolean) => void>();

export const getThrottledUpdateDataSourceObjectsCountFunc = (configId: string) => {
    let throttledFunc = throttledUpdateDataSourceObjectsCountFuncMap.get(configId);
    if (throttledFunc) {
        return throttledFunc;
    }

    const wait = 8_000;
    // During a data source's indexing run update the data source's objects count at most once every <wait> seconds
    throttledFunc = throttle(async (queryClient: QueryClient, invalidate?: boolean) => {
        if (invalidate === true) {
            queryClient.invalidateQueries(ALL_DATA_SOURCES_OBJECTS_COUNT_KEY);
            return;
        }
        const [groupCountResult] = await DataSourceNodeCount([configId]);
        const count = groupCountResult?.count ?? 0;
        queryClient.cancelQueries(ALL_DATA_SOURCES_OBJECTS_COUNT_KEY);
        const map: Map<string, number> | undefined =
            queryClient.getQueryData(ALL_DATA_SOURCES_OBJECTS_COUNT_KEY);
        if (map) {
            map.set(configId, count);
            queryClient.setQueryData(ALL_DATA_SOURCES_OBJECTS_COUNT_KEY, map);
        } else {
            queryClient.invalidateQueries(ALL_DATA_SOURCES_OBJECTS_COUNT_KEY);
        }
        updatePerDataSourceCacheCount(queryClient, configId, count);
    }, wait, { leading: false, trailing: true });

    throttledUpdateDataSourceObjectsCountFuncMap.set(configId, throttledFunc);

    return throttledFunc;
};

const updatePerDataSourceCacheCount = (queryClient: QueryClient, configId: string, count: number) => {
    // Update the per data source cache that is used elsewhere (e.g. ConfigCard.tsx)
    queryClient.setQueryData([...PER_DATA_SOURCE_OBJECTS_COUNT_KEY, configId], count);
};

function Plugins() {
    const queryClient = useQueryClient();
    const [params] = useSearchParams();

    const { data: objectCountsMap } = useQuery(
        ALL_DATA_SOURCES_OBJECTS_COUNT_KEY,
        async () => {
            const groupCountResults = await DataSourceNodeCount();
            const data = groupCountResults.reduce((map, r) => map.set(r.configId!, r.count), new Map<string, number>());
            for (const [configId, count] of data.entries()) {
                updatePerDataSourceCacheCount(queryClient, configId, count);
            }
            return data;
        },
        {
            refetchOnMount: false,
            refetchInterval: fiveMinutes
        }
    );

    const [pluginBeingDeleted, setPluginBeingDeleted] = useState<null | Record<string, any>>(null); // eslint-disable-line @typescript-eslint/no-explicit-any
    const [pluginBeingEdited, setPluginBeingEdited] = useState<null | Record<string, any>>(null); // eslint-disable-line @typescript-eslint/no-explicit-any
    const [pluginModalOpen, setPluginModalOpen] = useState(Boolean(params.get('adding')));

    const [pluginWorkspaceModal, setPluginWorkspaceModal] = useState<any>();

    const { acknowledgeFailedImports } = useImportStatus();

    const { data: workspaces, isLoading: isLoadingWorkspaces } = useWorkspacesForAdministration(
        { refetchInterval: oneMinute }
    );

    const { data: tenant } = useTenant();

    const workspaceMap = useMemo(() => {
        return workspaces?.reduce((acc: Map<string, Set<Workspace>>, workspace) => {
            workspace.data.links?.plugins?.forEach((link: string) => {
                const set = acc.get(link) ?? new Set();
                acc.set(link, set.add(workspace));
            });
            return acc;
        }, new Map<string, Set<Workspace>>());
    }, [workspaces]);

    const { data: datasourceConfigCount, isLoading: isLoadingConfigCount } = useDatasourceConfigsCount();

    const { isLoading: isLoadingPluginConfigs, data: plugins = [] } = 
        useDatasourceConfigsForAdministration<PluginProjectedRow[]>(
            {
                select: (pluginSources) => {
                    const mappedPlugins = pluginSources.map((plugin: any) => {
                        if (!plugin.plugin) {
                            plugin.plugin = {
                                name: 'UNKNOWN',
                                displayName: 'UNKNOWN'
                            };
                        }

                        if (!plugin.importStatus) {
                            plugin.importStatus = { status: 'notRun', started: 0 };
                        }

                        const version = plugin.plugin.version;
                        const majorVersion = version ? parseInt(version.split('.')[0]) : 1;
                        const versionString = version ? ` v${majorVersion}` : '';
                        const nameAndVersion = `${plugin.plugin.displayName}${versionString}`;
                        const onPrem = plugin.plugin.onPrem;

                        const workspaceCount = workspaceMap?.get(plugin.id)?.size ?? 0;

                        return {
                            ...plugin,
                            nameAndVersion,
                            workspaceCount,
                            onPrem
                        };
                    });
                    return sortBy(mappedPlugins, 'displayName');
                }
            }
        );

    const { isLoadingAgentGroups, agentGroupOptions } = useAgentGroups();

    const { pluginInstanceBeingEdited, hasOAuthRehydration, isLoadingPluginInstanceBeingEdited, clearSearch } =
        useOAuthRedirectParam();

    useEffect(() => {
        if (!isLoadingPluginConfigs && !isLoadingAgentGroups && hasOAuthRehydration && pluginInstanceBeingEdited) {
            handleEdit(pluginInstanceBeingEdited.id, 'PLUGIN');
            clearSearch();
        }
    }, [
        params,
        isLoadingPluginConfigs,
        isLoadingAgentGroups,
        plugins,
        pluginInstanceBeingEdited,
        hasOAuthRehydration,
        clearSearch
    ]);

    const { mutateAsync: deletePlugin } = useMutation({
        mutationFn: async (sourceID: string) => {
            const success = await Delete(sourceID);

            if (success) {
                invalidateAfterWorkspaceLinksChange(queryClient);
                trackEvent('Plugin Deleted', { id: sourceID });
            } else {
                trackEvent('Plugin Delete Failed', { id: sourceID });
            }
        }
    });

    const { isLoading: isLoadingPermissions, canEdit, canDelete } = usePluginPermissions();

    const onImportMutation = useMutation(async (sourceId: string) => Import(sourceId), {
        onMutate: async (sourceId: string) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            queryClient.cancelQueries(datasourceConfigQueryKeys.all);

            // Snapshot in case we have to roll back
            const previousSources = queryClient.getQueryData<Source[]>(
                datasourceConfigQueryKeys.forAdministration
            ) ?? [];

            // Optimistically update...
            queryClient.setQueryData(datasourceConfigQueryKeys.forAdministration, (existingSources: Source[] = []) => {
                const targetSourceIndex = existingSources?.findIndex((source) => source.id === sourceId);

                if (existingSources[targetSourceIndex] != null) {
                    existingSources[targetSourceIndex].importStatus = {
                        status: 'waitingForData',
                        started: Date.now(),
                        warnings: [],
                        totalWarningCount: 0,
                        lastSuccessful: existingSources[targetSourceIndex].importStatus?.lastSuccessful
                    };
                }

                return existingSources; // Return existing array with our optimistically updated plugin
            });

            // Return a context object with the snapshotted value
            return { previousSources };
        },
        // If the mutation fails, use the context returned from onMutate above to roll back:
        onError: async (_err, _source, context) => {
            if (context == null) {
                return;
            }

            return queryClient.setQueryData(datasourceConfigQueryKeys.forAdministration, context.previousSources);
        }
    });

    const handleSourceImport = (sourceId: string) => {
        trackEvent('Plugin Manual Import Run', { id: sourceId });

        const source = plugins.find(({ id }) => id === sourceId);
        if (source) {
            onImportMutation.mutate(sourceId);
        }
    };

    const handleEdit = async (currentConfigId: string, source: Constants.DataSource) => {
        const config = await Get(currentConfigId);

        setPluginBeingEdited({
            source,
            config
        });
    };

    const isLoading = () =>
        (isLoadingPluginConfigs && isLoadingAgentGroups && isLoadingPermissions) ||
        hasOAuthRehydration || // When we're restarting an edit session, the main page always shows a spinner. When the edit is complete we navigate back to this page without the query args
        isLoadingPluginInstanceBeingEdited ||
        isLoadingWorkspaces ||
        isLoadingConfigCount;

    const PluginEditModal = useMemo(
        () => {
            return pluginBeingEdited ? (
                <PluginConfigModal
                    config={pluginBeingEdited.config}
                    close={async () => {
                        queryClient.invalidateQueries(datasourceConfigQueryKeys.all);
                        setPluginBeingEdited(null);
                    }}
                    agentGroups={agentGroupOptions}
                    selectedPlugin={{
                        ...pluginBeingEdited.config,
                        id: (pluginBeingEdited.config?.plugin as { pluginId: ConfigId['value'] } | undefined)?.pluginId!
                    }}
                />
            ) : null;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [params, pluginBeingEdited?.config?.id]
    );

    const stablePlugins = stableStringify(plugins);

    useEffect(() => {
        acknowledgeFailedImports();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stablePlugins]);

    return (
        <>
            <SettingsTemplate
                title='Data Sources'
                description='Data sources are used to connect, index and stream your data on demand.'
                learnMoreLink='https://squaredup.com/cloud/plugins'
                flex
            >
                {isLoading() && (
                    <span className='flex justify-center'>
                        <LoadingSpinner />
                    </span>
                )}
                {!isLoading() && (
                    <div className='flex flex-col flex-1 min-h-0'>
                        {tenant?.licenceData?.graphNodeHardLimitReached ? (
                            <LimitReachedBanner currentUsage={Number.POSITIVE_INFINITY} featureKey='indexedObjects' className='mb-9' container='page' content='subtle' />
                        ) : (
                            <LimitReachedBanner currentUsage={datasourceConfigCount} featureKey='dataSources' className='mb-9' container='page' content='subtle' />
                        )}
                        <div>
                            <Button onClick={() => setPluginModalOpen(true)} data-testid='addPluginButton'>
                                <BelowFeatureLimit 
                                    currentUsage={datasourceConfigCount}
                                    featureKey='dataSources'
                                    children='Add data source'
                                    fallback='Browse data sources'
                                />
                            </Button>
                        </div>

                        <div className='flex flex-col min-h-0 mt-4 mb-8'>
                            <PluginsTable
                                plugins={plugins as PluginProjectedRow[]}
                                objectCountsMap={objectCountsMap ?? new Map()}
                                setPluginWorkspaceModal={setPluginWorkspaceModal}
                                onIndex={(plugin) => handleSourceImport(plugin.id)}
                                onEdit={(plugin) => handleEdit(plugin.id, plugin.source)}
                                onDelete={(plugin) => setPluginBeingDeleted(plugin)}
                                canIndex={(plugin) => canEdit(plugin.id) ?? false}
                                isIndexing={(plugin) => {
                                    const isIndexing = ['running', 'started', 'waitingForData'].includes(
                                        plugin?.importStatus?.status || ''
                                    );
                                    if (isIndexing === true) {
                                        getThrottledUpdateDataSourceObjectsCountFunc(plugin.id)(queryClient);
                                    }
                                    return isIndexing;
                                }}
                                canEdit={(plugin) => canEdit(plugin.id) ?? false}
                                canDelete={(plugin) => canDelete(plugin.id) ?? false}
                            />
                        </div>
                    </div>
                )}
            </SettingsTemplate>

            {pluginBeingDeleted && (
                <ConfirmationPrompt
                    title={`Delete Data Source: ${pluginBeingDeleted.displayName}`}
                    prompt='Are you sure you want to permanently delete this data source? All objects from this data source will be permanently deleted.'
                    confirmButtonText='Delete'
                    confirmButtonVariant='destructive'
                    onConfirm={async () => deletePlugin(pluginBeingDeleted.id)}
                    onClose={() => {
                        setPluginBeingDeleted(null);
                    }}
                />
            )}

            {pluginModalOpen && (
                <PluginModal
                    agentGroups={agentGroupOptions}
                    close={async (reason?: ModalCloseReason, details?: unknown) => {
                        const pluginMutationDetails = details as PluginMutationDetails;
                        if (reason === 'submit' && pluginMutationDetails?.newId) {
                            getThrottledUpdateDataSourceObjectsCountFunc(pluginMutationDetails.newId)(queryClient);
                        }
                        setPluginModalOpen(false);
                    }}
                />
            )}

            {PluginEditModal}

            {pluginWorkspaceModal && (
                <PluginsWorkspacesModal
                    plugin={pluginWorkspaceModal}
                    associatedWorkspaces={Array.from(workspaceMap?.get(pluginWorkspaceModal.id) ?? new Set())}
                    close={() => setPluginWorkspaceModal(undefined)}
                />
            )}
        </>
    );
}

export { Plugins as default };
