import { Skeleton } from '@/components/Skeleton';
import { cn } from '@/lib/cn';

const DonutSkeleton = ({ className }: { className?: string }) => {
    return (
        <Skeleton
            className={cn(
                'size-full max-h-[73%] max-w-[80%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
                className
            )}
            style={{
                maskImage:
                    "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 168 168'%3E%3Cpath fill-rule='evenodd' d='M14.53 37.63A83.5 83.5 0 0 1 88.86.43l-.76 13.34a70.14 70.14 0 0 0-62.44 31.25l-11.13-7.39Zm-4.29 85.1a83.5 83.5 0 0 1 2.98-83.05l11.34 7.06a70.14 70.14 0 0 0-2.5 69.77l-11.82 6.23Zm156.5-50.96a83.5 83.5 0 0 0-17-39.58l-10.5 8.26a70.14 70.14 0 0 1 14.27 33.25l13.23-1.93Zm.3 2.36a83.1 83.1 0 0 1-.35 22l-13.21-1.97a70.12 70.12 0 0 0 .3-18.48l13.26-1.55Zm-7.47 45.42a83.52 83.52 0 0 0 6.72-20.96l-13.15-2.37a70.1 70.1 0 0 1-5.65 17.6l12.08 5.73ZM125 156.6a83.5 83.5 0 0 1-113.42-31.41l11.6-6.63a70.14 70.14 0 0 0 95.28 26.39L125 156.6Zm1.93-1.12a83.5 83.5 0 0 0 17.25-13.68l-9.62-9.28A70.12 70.12 0 0 1 120.08 144l6.85 11.47Zm31.61-33.85a83.5 83.5 0 0 1-12.47 18.14l-9.91-8.96a70.14 70.14 0 0 0 10.47-15.23l11.91 6.05Zm-10.51-91.56A83.5 83.5 0 0 0 91.79.65l-1.23 13.3a70.14 70.14 0 0 1 47.24 24.72l10.23-8.6Z' clip-rule='evenodd'/%3E%3C/svg%3E\")",
                maskPosition: 'center',
                maskRepeat: 'no-repeat'
            }}
        />
    );
};

export default DonutSkeleton;
