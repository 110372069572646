import { buildQuery } from '@squaredup/graph';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import stringify from 'fast-json-stable-stringify';
import { orderBy } from 'lodash';
import { useQuery } from 'react-query';
import { Query } from 'services/GraphService';
import { FilterQueryParameters } from '../../utilities/getObjectFilters';

/**
 * Gets all the types for a datastream on the filtered sources and properties
 */
export const useObjectFilterTypes = ({
    scopeBaseQuery,
    queryParams,
    filterTypes,
    isFilterQueryReady
}: {
    scopeBaseQuery: string;
    queryParams: FilterQueryParameters;
    filterTypes: string[];
    isFilterQueryReady: boolean;
}) => {
    const { isGlobal } = useDataStreamWorkspaceContext();

    return useQuery(
        ['types', scopeBaseQuery, stringify(queryParams), stringify(isGlobal)],
        async () => {
            const typesQuery = scopeBaseQuery + '.has("type").values("type").dedup()';

            const { gremlinQueryResults } = await Query(
                buildQuery(queryParams, typesQuery),
                isGlobal ? 'directOrAnyWorkspaceLinks' : undefined
            );

            return orderBy(
                // Add filterTypes and find unique to persist previously selected types that are no longer in the list
                [...new Set([...(gremlinQueryResults as string[]), ...filterTypes])],
                (type: string) => type.trim().toLowerCase()
            );
        },
        {
            enabled: isFilterQueryReady,
            keepPreviousData: true
        }
    );
};
