import GridSkeleton from '@/components/GridSkeleton';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stateStrings, type HealthState } from '@squaredup/monitoring';
import { Presence } from 'components/Presence';
import { TruncatedText } from 'components/TruncatedText';
import Tooltip from 'components/tooltip/Tooltip';
import { healthStateBGColors } from 'constants/state';
import { useAppContext } from 'contexts/AppContext';
import useOverflowing from 'lib/useOverflowing';
import { useMonitorsInWorkspace } from 'pages/monitoring/useMonitorsInWorkspace';
import type { StatusRequestType } from 'pages/status/ui/StatusOverview';
import { useBlockReason } from 'pages/status/utils/useBlockReason';
import { useDashboardHealthStates } from 'queries/hooks/useDashboardHealthStates';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { flattenedDashboardsSortedByWorkspaceOrder } from 'queries/utils/dashboardSorted';
import { Suspense, type ComponentPropsWithoutRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Link } from 'react-router-dom';
import ErrorFallback from 'ui/errorHandling/ErrorFallback';
import {
    NoDashboardsInWorkspaceMessage,
    NoDashboardsMatchingFiltersCriteriaMessage,
    NoMonitorsInWorkspaceMessage,
    NoMonitorsMatchingFiltersCriteriaMessage
} from './WorkspaceEmptyMessages';
import {
    HealthPanelSortDropdown,
    HealthPanelStatusDropdown,
    HealthPanelViewsDropdown,
    useHealthPanelSelectedView,
    useHealthPanelSort,
    useHealthPanelStatus
} from './WorkspaceHealthDropdowns';
import { Panel, useWorkspaceId } from './WorkspaceHome';

export const WorkspaceHealthOverviewPanel = () => {
    const selectedView = useHealthPanelSelectedView();

    return (
        <Panel className='row-span-2 gap-4'>
            <Header>
                <Text.Body className='text-lg font-semibold'>Health</Text.Body>
                <Tooltip
                    title='The health of monitors on dashboard tiles automatically rolls up to their dashboards and in turn to this workspace.'
                    className='mr-auto'
                >
                    <FontAwesomeIcon icon={faQuestionCircle} className='text-textSecondary' />
                </Tooltip>

                <HealthPanelViewsDropdown />
                <HealthPanelSortDropdown />
                <HealthPanelStatusDropdown />
            </Header>

            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<GridSkeleton cols={1} height='6rem' gap='0.5rem' radius='0.5rem' />}>
                    <Presence isPresent={selectedView === 'dashboards'}>
                        <DashboardsList />
                    </Presence>
                    <Presence isPresent={selectedView === 'monitors'}>
                        <MonitorsList />
                    </Presence>
                </Suspense>
            </ErrorBoundary>
        </Panel>
    );
};

type BlockDataProps = {
    name: string;
    id: string;
    link: string;
    type: StatusRequestType;
    state?: HealthState;
};

const DashboardsList = () => {
    const { currentWorkspaceID } = useAppContext();
    const { data: workspace } = useWorkspace(currentWorkspaceID, {
        suspense: true
    });

    const { data: dashboards = [] } = useDashboardHealthStates(currentWorkspaceID, {
        suspense: true,
        select: flattenedDashboardsSortedByWorkspaceOrder(workspace?.data.properties?.dashboardIdOrder)
    });

    const transformData = useTransformData();
    const transformedData = transformData(dashboards);

    if (!dashboards.length) {
        return (
            <EmptyMessageContainer>
                <NoDashboardsInWorkspaceMessage />
            </EmptyMessageContainer>
        );
    }

    if (!transformedData.length && dashboards.length) {
        return (
            <EmptyMessageContainer>
                <NoDashboardsMatchingFiltersCriteriaMessage />
            </EmptyMessageContainer>
        );
    }

    return (
        <List>
            {transformedData.map((data) => (
                <Link to={`/dashboard/${data.id}`} className='block w-full group' key={data.id}>
                    <ListItem state={data.state}>
                        <BlockDataTitle title={data.displayName} prefix={data.folderPath.join(' / ')} />
                        <BlockDataReason
                            blockData={{
                                type: 'dash',
                                id: data.id,
                                state: data.state
                            }}
                        />
                    </ListItem>
                </Link>
            ))}
        </List>
    );
};

const MonitorsList = () => {
    const workspaceId = useWorkspaceId();
    const monitorsInWorkspace = useMonitorsInWorkspace({ workspaceId, queryOptions: { suspense: true } });

    const transformData = useTransformData();
    const transformedData = transformData(monitorsInWorkspace.data?.monitors ?? []);

    if (!monitorsInWorkspace.data?.monitors?.length) {
        return (
            <EmptyMessageContainer>
                <NoMonitorsInWorkspaceMessage />
            </EmptyMessageContainer>
        );
    }

    if (!transformedData.length && monitorsInWorkspace.data.monitors.length) {
        return (
            <EmptyMessageContainer>
                <NoMonitorsMatchingFiltersCriteriaMessage />
            </EmptyMessageContainer>
        );
    }

    return (
        <List>
            {transformedData.map((data) => (
                <Link to={data.link} className='block w-full group' key={data.id}>
                    <ListItem state={data.state}>
                        <BlockDataTitle
                            prefix={[...(data.dashboardFolderPath || []), data.dashboardDisplayName].join(' / ')}
                            title={data.name}
                        />
                        <BlockDataReason blockData={data} />
                    </ListItem>
                </Link>
            ))}
        </List>
    );
};

const useTransformData = () => {
    const healthPanelSort = useHealthPanelSort();
    const healthPanelStatus = useHealthPanelStatus();

    const transformData = <
        T extends { name?: string; displayName?: string; state?: HealthState; folderPath?: string[] }
    >(
        data: T[]
    ) => {
        return [...data]
            .filter((a) => !healthPanelStatus || 
                !healthPanelStatus.length || 
                healthPanelStatus.includes(a.state ?? stateStrings.unmonitored)
            )
            .sort((a, b) => {
                const aName = a.displayName ?? a.name ?? '';
                const bName = b.displayName ?? b.name ?? '';

                if (healthPanelSort.direction === 'asc' && healthPanelSort.value === 'state') {
                    const sortOrder = { success: 1, warning: 2, error: 3, unknown: 4, unmonitored: 5 };
                    return (
                        sortOrder[a.state ?? stateStrings.unmonitored] - 
                        sortOrder[b.state ?? stateStrings.unmonitored]
                    ) || aName.localeCompare(bName);
                }

                if (healthPanelSort.direction === 'desc' && healthPanelSort.value === 'state') {
                    const sortOrder = { error: 1, warning: 2, success: 3, unknown: 4, unmonitored: 5 };
                    return (
                        sortOrder[a.state ?? stateStrings.unmonitored] - 
                        sortOrder[b.state ?? stateStrings.unmonitored]
                    ) || aName.localeCompare(bName);
                }

                if (healthPanelSort.direction === 'asc' && healthPanelSort.value === 'name') {
                    return aName.localeCompare(bName);
                }

                if (healthPanelSort.direction === 'desc' && healthPanelSort.value === 'name') {
                    return bName.localeCompare(aName);
                }

                return 0;
            });
    };

    return transformData;
};

const List = (props: ComponentPropsWithoutRef<'ul'>) => {
    return (
        <ul
            {...props}
            className='w-full max-h-full space-y-2 overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'
        />
    );
};

const ListItem: React.FC<ComponentPropsWithoutRef<'li'> & { state?: HealthState }> = ({ children, state, ...props }) => {
    return (
        <li
            {...props}
            data-testid='block'
            data-status={state}
            className='flex text-textPrimary group z-0 relative h-24 rounded-lg w-full isolate shadow-[0px_0px_2px_-1px_rgba(0,0,0,0.3)]'
        >
            <div className={cn('w-4 bg-statusUnmonitoredPrimary rounded-l-lg shrink-0', state && healthStateBGColors[state])} />

            <div className='flex-1 min-w-0 py-2 pl-4 pr-2 border border-l-0 rounded-r-lg border-outlinePrimary'>
                {children}  
            </div>
        </li>
    );
};

const Header = (props: ComponentPropsWithoutRef<'header'>) => {
    return <header {...props} className='flex items-center gap-2' />;
};

const EmptyMessageContainer = (props: ComponentPropsWithoutRef<'div'>) => {
    return (
        <div
            {...props}
            className='flex flex-col items-center justify-center gap-4 m-auto text-center text-textSecondary'
        />
    );
};

const BlockDataTitle = ({ title, prefix }: { title: string; prefix?: string }) => {
    const { ref: titleRef, isOverflowing: titleOverflowing } = useOverflowing();
    const { ref: prefixRef, isOverflowing: prefixOverflowing } = useOverflowing();

    return (
        <Tooltip
            title={`${prefix !== undefined ? prefix + ' / ' : ''}${title}`}
            disabled={!(titleOverflowing || prefixOverflowing)}
        >
            <h3 className='flex max-w-full text-lg font-semibold truncate group-hover:underline w-min'>
                {prefix && (
                    <>
                        <span
                            ref={prefixRef}
                            className='flex-1 truncate text-textSecondary basis-0'
                            style={{ minWidth: `${Math.min(5, prefix.length - 1)}ch` }}
                        >
                            {prefix}
                        </span>
                        <span className='text-textSecondary'>&nbsp;/&nbsp;</span>
                    </>
                )}
                <span ref={titleRef} className='truncate'>
                    {title}
                </span>
            </h3>
        </Tooltip>
    );
};

const BlockDataReason = (props: { blockData: Pick<BlockDataProps, 'type' | 'id' | 'state'> }) => {
    const reason = useBlockReason(props.blockData.type, props.blockData.id, props.blockData.state);

    if (!reason) {
        return null;
    }

    return <TruncatedText title={reason} />;
};
