import type { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import type { EvaluatedMonitorState, HealthState, HealthStateReason } from '@squaredup/monitoring';
import API from './API';
import { handleError, handleResponse } from './util';

type State = {
    state: HealthState;
    lastEvaluated: any;
};
type RolledUpState = State & { stateRollsUp: boolean };

export type TileState = RolledUpState & {
    i: string;
    tileId: string;
    tileName?: string;
    configId: string;
    dashId: string;
    dashboardName?: string;
    nodeId: string;
    stateReason?: HealthStateReason & {
        text: string;
    };
    lastChanged?: number;
    lastChangedFrom?: string;
    consecutiveFailures?: number;
    nextEvaluation?: number;
};

type DashboardState = RolledUpState & { dashboardId: string, unhealthyTileStates?: TileState[] };
type WorkspaceState = State & { workspaceId: string; unhealthyTileStates?: TileState[] };

type DashboardHealth = {
    tileStates: TileState[];
    dashboardState: DashboardState;
};

export type WorkspaceHealth = {
    dashboardStates: DashboardState[];
    workspaceState: WorkspaceState;
};

export type DashboardsHealth = {
    dashboardStates: DashboardState[];
};

export type WorkspacesHealth = {
    workspaceStates: WorkspaceState[];
    tileStates?: TileState[];
};
/**
 * Get the rolled up health of the given dashboard
 * @param id Dashboard ID
 */
export const GetDashboardHealthById = async (id: string): Promise<DashboardHealth> => {
    return API.get<DashboardHealth>(`healthrollup/dashboard/${id}`).then(handleResponse).catch(handleError);
};

/**
 * Get the rolled up health of the given dashboards
 * @param dashboardIds List of Dashboard IDs
 */
export const ListDashboardHealthByIds = async (dashboardIds: string[]): Promise<DashboardsHealth> => {
    return API.post<DashboardsHealth>('healthrollup/dashboards', { dashboardIds })
        .then(handleResponse)
        .catch(handleError);
};

/**
 * Get the rolled up health of the given workspace
 * @param id Workspace ID
 */
export const GetWorkspaceHealthById = async (id: string): Promise<WorkspaceHealth> => {
    return API.get<WorkspaceHealth>(`healthrollup/workspace/${id}`).then(handleResponse).catch(handleError);
};

/**
 * Get the rolled up health of the given workspaces
 * @param workspaceIds List of Workspace IDs
 */
export const ListWorkspaceHealthByIds = async (
    workspaceIds: string[],
    includeTileStates = false,
    includeDashboardNames = false
): Promise<WorkspacesHealth> => {
    return API.post<WorkspacesHealth>('healthrollup/workspaces', {
        workspaceIds,
        includeTileStates,
        includeDashboardNames
    })
        .then(handleResponse)
        .catch(handleError);
};

export const EvaluateHealth = async (workspaceIds: string[], force = false): Promise<void> => {
    return API.post('health/evaluate', { workspaceIds, forceEvaluation: force })
        .then(handleResponse)
        .catch(handleError);
};

export const PreviewHealth = async (
    workspaceId: string,
    tileConfig: DataStreamBaseTileConfig
): Promise<EvaluatedMonitorState> => {
    return API.post(
        'health/preview',
        {
            tileConfig
        },
        {
            params: {
                workspaceId
            }
        }
    ).then(handleResponse);
};
