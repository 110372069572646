import { PinnableNode } from '../data/types';

export interface PositionedPinnableNode extends PinnableNode {
    x: number;
    y: number;
    width: number;
    height: number;
}

export enum LayoutTypes {
    network,
    hierarchyVertical,
    hierarchyHorizontal,
}