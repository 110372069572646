/**
 * Parse a global search string into its components
 * @param searchString The raw search string
 */
export const parseSearchString = (searchString: string): { nodeSearch: string; dataStreamSearch?: string } => {
    const dataStreamDelimiter = '>';

    if (!searchString.includes(dataStreamDelimiter)) {
        return { nodeSearch: searchString.trim() };
    }

    const pattern = /^\s*(?<nodeSearch>[^>]*?)\s*(>\s*(?<dataStreamSearch>.*?)\s?)?$/iu;

    const { nodeSearch, dataStreamSearch } = pattern.exec(searchString)?.groups ?? { nodeSearch: searchString };

    return { nodeSearch, dataStreamSearch };
};
