import { faSliders } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFeatureEnabled } from '@squaredup/tenants';
import DropdownMenu from 'components/dropdownMenu';
import { FeatureUnavailablePill } from 'components/plans/FeatureUnavailablePill';
import type { OrganisationHomeConfigViewSettings } from 'dynamo-wrapper';
import { useTier } from 'queries/hooks/useTier';
import { useState } from 'react';
import { useSaveStatusConfig, useStatusConfig } from '../utils/useStatusConfig';

export default function ViewSettingsMenu() {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    const { mutate: saveConfig } = useSaveStatusConfig();
    const { data: config } = useStatusConfig();

    const handleChange = (key: string, value: boolean | number) => {
        if (config) {
            saveConfig({
                ...config,
                viewSettings: {
                    ...config.viewSettings,
                    [key]: value
                }
            });
        }
    };

    const getCheckboxProps = (key: OrganisationHomeConfigViewSettings, fallback: boolean = true) => {
        return {
            checked: Boolean(config?.viewSettings?.[key] ?? fallback),
            onCheckedChange: (checked: boolean) => {
                handleChange(key, checked);
            }
        };
    };

    const getNumberProps = (key: OrganisationHomeConfigViewSettings) => {
        return {
            value: config?.viewSettings?.[key] as number,
            onValueChange: (value: number) => {
                handleChange(key, value);
            }
        };
    };

    const { data: tier } = useTier();

    return (
        <DropdownMenu onOpenChange={setIsDropdownOpened}>
            <DropdownMenu.Button
                className='whitespace-nowrap'
                dropdownOpen={isDropdownOpened}
                showChevron
                useFilterStyle
                icon={<FontAwesomeIcon icon={faSliders} />}
            >
                View
            </DropdownMenu.Button>
            <DropdownMenu.Menu portal zIndex={19} align='end'>
                <DropdownMenu.Group>
                    {/** SECTION 1 */}
                    <DropdownMenu.EyeCheckboxItem {...getCheckboxProps('sublabel')}>
                        Monitor
                    </DropdownMenu.EyeCheckboxItem>
                    <DropdownMenu.EyeCheckboxItem {...getCheckboxProps('state')}>State</DropdownMenu.EyeCheckboxItem>
                    <DropdownMenu.EyeCheckboxItem
                        disabled={tier ? !isFeatureEnabled(tier, 'kpiTypes') : false}
                        {...getCheckboxProps('kpis')}
                    >
                        KPIs
                        <FeatureUnavailablePill featureKey='kpiTypes' />
                    </DropdownMenu.EyeCheckboxItem>

                    <DropdownMenu.Separator />

                    {/** SECTION 2 */}
                    <DropdownMenu.EyeCheckboxItem {...getCheckboxProps('type')}>Type</DropdownMenu.EyeCheckboxItem>
                    <DropdownMenu.EyeCheckboxItem {...getCheckboxProps('tags')}>Tags</DropdownMenu.EyeCheckboxItem>
                    <DropdownMenu.EyeCheckboxItem {...getCheckboxProps('dataSources')}>
                        Data sources
                    </DropdownMenu.EyeCheckboxItem>
                    <DropdownMenu.EyeCheckboxItem {...getCheckboxProps('avatar')}>Icon</DropdownMenu.EyeCheckboxItem>

                    <DropdownMenu.Separator />

                    {/** SECTION 3 */}
                    <DropdownMenu.NumberItem {...getNumberProps('columnCount')} minimum={1} maximum={12}>
                        Columns
                    </DropdownMenu.NumberItem>
                </DropdownMenu.Group>
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
}
