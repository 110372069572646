import type { Edge } from '@squaredup/graph';
import { GraphData } from 'dashboard-engine/types/data/GraphData';
import { cytoscapeWebWorker } from './networkWorker';

export const generateObjectNetworkLayout = async (data: GraphData, config: any) => {
    const nodes = (data.nodes || []).map((node) => ({
        group: 'nodes',
        data: {
            ...node
        }
    }));

    const nodesMap = new Map(nodes.map((node) => [node.data.id, node]));

    const edges = (data.edges || [])
        .filter(({ outV, inV }) => nodesMap.get(outV) && nodesMap.get(inV))
        .map((edge: Edge) => ({
            group: 'edges',
            data: {
                ...edge,
                source: edge.outV,
                target: edge.inV
            }
        }));

    const networkConfig = {
        ...config,
        nodeType: 'kpi',
        layoutType: config.layoutType
    };

    return cytoscapeWebWorker(nodes, edges, networkConfig);
};
