import type { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { omit } from 'lodash';
import type { Dispatch, SetStateAction } from 'react';
import type { QueryClient } from 'react-query';
import { DATA_STREAM_DEFINITION } from 'services/DataStreamDefinitionService';
import type { TileEditorAction } from './TileEditorActions';
import type { TileEditorState } from './TileEditorState';

export type TileEditorStateReducer = (state: TileEditorState, action: TileEditorAction) => TileEditorState;
/**
 * Dependencies used to produce side-effects.
 */
export type TileEditorStateReducerExternals = {
    queryClient: QueryClient;
    setConfig: Dispatch<SetStateAction<DataStreamBaseTileConfig>>;
};

export const createTileEditorStateReducer: (externals: TileEditorStateReducerExternals) => TileEditorStateReducer =
    ({ queryClient, setConfig }) =>
    (state, action) => {
        switch (action.type) {
            case 'selectDataStream': {
                const isSelectingDifferentDataStream = state.selectedDataStream?.id !== action.dataStreamId;
                const dataStream = state.dataStreams.find((ds) => ds.id === action.dataStreamId);

                if (!isSelectingDifferentDataStream || dataStream == null) {
                    return state;
                }

                return {
                    ...state,
                    selectedDataStream: dataStream,
                    sideEffects: () => {
                        // Cache any data stream related data to ensure the
                        // tile editor immediately reflects the current state
                        queryClient.setQueryData([DATA_STREAM_DEFINITION, dataStream.id], dataStream);

                        setConfig((c) => ({
                            ...c,
                            dataStream: {
                                ...omit(c.dataStream, 'pluginConfigId'),
                                dataSourceConfig: dataStream.definition.dataSourceConfig,
                                id: dataStream.id,
                                name: dataStream.definition?.name
                            }
                        }));
                    }
                };
            }

            case 'markSideEffectsExecuted': {
                return {
                    ...state,
                    sideEffects: undefined
                };
            }

            default:
                return state;
        }
    };
