import { PinnableNodeData } from 'components/map/data/types';
import { NodeProps } from 'reactflow';
import { MonitorNodeInternal } from './internals/MonitorNodeInternal';

export const MONITOR_NODE = 'monitorNode';
export const MONITOR_NODE_SIZE = 18;

export const MonitorNode = ({ id, data }: NodeProps<PinnableNodeData>) => {

    return (
        <MonitorNodeInternal
            id={id}
            pinned={data.pinned}
            expanded={data.expanded}
            label={data.label}
            fixedPosition={data.fixedPosition}
            tileId={data.tileId}
            dashId={data.dashId}
            initialState={data.initialState}
            hiddenConnections={data.hiddenConnections}
        />
    );
};
