import { useAppContext } from 'contexts/AppContext';

/**
 * Sets the App context currentWorkspaceId to the passed workspaceID if isn't already set.
 * This is useful if refreshing the page and it gets out of sync/currentWorkspaceId isn't set.
 */
export const useEnsureCorrectCurrentWorkspaceId = (workspaceId?: string) => {
    const { currentWorkspaceID, setCurrentWorkspaceID } = useAppContext();
    
    if (workspaceId && currentWorkspaceID !== workspaceId) {
        setCurrentWorkspaceID({ id: workspaceId });
    }
};
