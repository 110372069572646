import DropdownMenu from '@/components/DropdownMenu';
import { faImage, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamBaseTileConfig, isDataStreamTileConfig } from '@squaredup/data-streams';
import { useTileContext } from 'contexts/TileContext';
import { useDataStreamFromTileConfig } from 'dashboard-engine/hooks/useDataStreamFromTileConfig';
import { downloadTileAsImage } from 'dashboard-engine/util/dashboardImageSnapshots';
import { useMutation } from 'react-query';

export const ExportImage: React.FC = () => {
    const { config, tileId, setIsExporting } = useTileContext();
    const { isConfigured } = useDataStreamFromTileConfig(config as DataStreamBaseTileConfig);

    const { mutateAsync: exportImage, isLoading: isExportingImage } = useMutation(
        () => downloadTileAsImage(tileId || '', config.title, true),
        {
            onMutate: () => setIsExporting?.(true),
            onSettled: () => setIsExporting?.(false)
        }
    );

    if (isDataStreamTileConfig(config) && !isConfigured) {
        return null;
    }

    return (
        <DropdownMenu.Item
            onSelect={() => exportImage()}
            icon={<FontAwesomeIcon
                icon={isExportingImage ? faSpinner : faImage}
                fixedWidth
                {...(isExportingImage && { spin: true })} />}
            key='export'
        >
            Export
        </DropdownMenu.Item>
    );
};
