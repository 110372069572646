import { useQuery } from 'react-query';
import { Get as GetPlugin } from 'services/PluginService';

export const PLUGIN = 'PLUGIN';

export const usePlugin = (pluginId?: string) => {
    return useQuery(
        [PLUGIN, pluginId], 
        async () => GetPlugin(pluginId!), 
        {
            // Plugins very rarely change in any way we care about, so don't refetch unless explicitly invalidated.
            enabled: Boolean(pluginId),
            staleTime: Number.POSITIVE_INFINITY,
            cacheTime: Number.POSITIVE_INFINITY
        }
    );
};
