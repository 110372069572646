import Text from '@/components/Text';
import Field from 'components/forms/field/Field';
import { format } from 'date-fns';
import type { ShapeConfigFieldsComponent } from './ShapeConfigFields';

/**
 * We don't care what this date is, but it makes sense to have it be around
 * the current date. We store it once to avoid it changing every render.
 */
const exampleDate = new Date().toISOString();

export const DateShapeConfigFields: ShapeConfigFieldsComponent = ({ name }) => (
    <>
        <Field.Input
            label='Output Format'
            name={`${name}.format`}
            type='text'
            className='w-60'
            help={
                <>
                    Enter a custom format string for date values. e.g.{' '}
                    <pre className='inline font-mono whitespace-normal'>dd/MM/yy</pre> formats today's date as{' '}
                    {format(new Date(), 'dd/MM/yy')}
                </>
            }
        />
        <Text.Body className='mt-2 text-sm'>
            <a
                className='text-textLink'
                href='https://date-fns.org/v4.1.0/docs/format'
                target='_blank'
                rel='noreferrer noopener'
            >
                Learn more about date output formatting
            </a>
        </Text.Body>
        <Field.Input
            label='Input Format'
            name={`${name}.inputPattern`}
            type='text'
            className='w-60'
            help={
                <>
                    <p>Enter the format that corresponds to the format of your input date.</p>
                    <p className='mt-2'>
                        e.g. if your data has values like
                        <pre className='my-2 font-mono whitespace-normal'>05/27/24 01:44 PM</pre> then Input Format
                        should be set to
                        <pre className='my-2 font-mono whitespace-normal'>dd/MM/yy hh:mm aa</pre> to convert them to
                        date values.
                    </p>
                    <p className='mt-2'>
                        Input Format is required if the date strings in this column are not ISO-8601 formatted, e.g.
                        <pre className='my-2 font-mono whitespace-normal'>{exampleDate}</pre>
                    </p>
                </>
            }
        />
        <Text.Body className='mt-2 text-sm'>
            <a
                className='text-textLink'
                href='https://date-fns.org/v4.1.0/docs/parse'
                target='_blank'
                rel='noreferrer noopener'
            >
                Learn more about date input formatting
            </a>
        </Text.Body>
    </>
);
