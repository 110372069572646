import { Serialised } from '@squaredup/ids';
import type { ApiKey } from 'dynamo-wrapper';
import API from './API';
import { handleError, handleResponse } from './util';

/*
    react-query key for API Key data
 */
export const APIKEY_DATA = 'APIKEYS';

/**
 * List all api keys
 */
export const List = async () => {
    return API.get<Serialised<ApiKey>[]>('/apikeys').then(handleResponse).catch(handleError);
};

/**
 * Adds a new api key
 * @param name
 * @param description
 */
export const Add = async (
    displayName: string,
    description: string,
    subType?: string
): Promise<Serialised<ApiKey & { key: string }>> => {
    return API.post('apikeys', {
        displayName,
        description,
        subType
    })
        .then(handleResponse)
        .catch(handleError);
};

/**
 * Deletes api key
 * @param apiKey
 */
export const Delete = async (id: string) => {
    await API.delete(`/apikeys/${id}`).then(handleResponse).catch(handleError);
    return true;
};
