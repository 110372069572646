import { cn } from '@/lib/cn';
import { faBroadcastTower, faSplit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HealthState } from '@squaredup/monitoring';
import clsx from 'clsx';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import Tooltip from 'components/tooltip/Tooltip';
import { StateIndicator } from 'components/ui/state/StateIndicator';
import { healthStateTextColors, isUnhealthy } from 'constants/state';
import { WorkspaceNetworkContext } from 'contexts/WorkspaceNetworkContext';
import type { KPI } from 'dynamo-wrapper';
import useOverflowing from 'lib/useOverflowing';
import React, { ReactNode, useContext } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { Handle, Node, Position, useReactFlow } from 'reactflow';
import { TileState } from 'services/HealthService';
import { KPI_DATA, List as ListKPIs } from 'services/KPIService';
import { type Workspace } from 'services/WorkspaceService';
import WorkspaceNode from '../WorkspaceNode';
import { WorkspaceNetworkNodeData } from '../utils/generateWorkspaceNetwork';

const borderStateColours = {
    success: '!border-l-statusHealthyPrimary',
    error: '!border-l-statusErrorPrimary',
    warning: '!border-l-statusWarningPrimary',
    unknown: '!border-l-statusUnknownPrimary',
    unmonitored: '!border-l-statusUnmonitoredPrimary'
};

type TileStateWithDashboardName = TileState & { dashboardName: string };

interface WorkspaceKPINodeData extends WorkspaceNetworkNodeData {
    onClick: any;
    isHovered: boolean;
}

const getStylesForNode = (state: any) => {
    // Unmonitored workspaces should be white in the graph
    if (!state || state === 'unknown') {
        return 'text-primaryButtonText';
    }

    return healthStateTextColors[state as keyof typeof healthStateTextColors];
};

const TitleTruncated: React.FC<{
    title: string | ReactNode;
    href?: string;
    className?: string;
}> = ({ title, href, className }) => {
    const { ref, isOverflowing } = useOverflowing<HTMLDivElement>();

    return (
        <Tooltip title={title} disabled={!isOverflowing} className='w-full'>
            <p ref={ref} className={clsx('h-full truncate', className)}>
                {href ? <Link to={href}>{title}</Link> : title}
            </p>
        </Tooltip>
    );
};

const StateBox: React.FC<{ state: HealthState; href: string }> = ({ state, href, children }) => {
    return (
        <Link
            to={href}
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
            className={cn(
                'block py-2 px-4 border-l-8 z-10 bg-componentBackgroundSecondary',
                borderStateColours[state as keyof typeof borderStateColours]
            )}
        >
            {children}
        </Link>
    );
};

const KPIBox: React.FC<{ kpi: any; isLoading: boolean }> = ({ kpi, isLoading }: any) => {
    const { name, kpiState, dashboardId, formattedValue } = kpi;

    return (
        <StateBox href={`/dashboard/${dashboardId}`} state={kpiState as HealthState}>
            <span className='flex'>
                <span className='flex-1 min-w-0'>
                    <TitleTruncated title={name} className='text-textSecondary text-medium' />
                    {isLoading ? (
                        <LoadingSpinner size={28} />
                    ) : (
                        <TitleTruncated title={formattedValue} className='!text-2xl font-bold' />
                    )}
                </span>
                <FontAwesomeIcon icon={faBroadcastTower} className='flex-shrink-0 -mr-2' />
            </span>
        </StateBox>
    );
};

const TileBox: React.FC<{
    tile: TileStateWithDashboardName;
}> = ({ tile }) => {
    const { dashboardName, dashId, state, tileName } = tile;

    return (
        <StateBox href={`/dashboard/${dashId}`} state={state as HealthState}>
            <TitleTruncated title={dashboardName} className='text-textSecondary text-medium' />
            <TitleTruncated title={tileName || 'Unnamed tile'} className='!text-2xl font-bold' />
        </StateBox>
    );
};

const ExtraMonitors: React.FC<{ monitors: TileStateWithDashboardName[] }> = ({ monitors }) => {
    return (
        <Tooltip
            className='p-4 text-2xl font-bold border-l-8 border-l-statusUnknownPrimary'
            title={
                <div className='space-y-2'>
                    {monitors.map(({ state, dashId, dashboardName, tileId, tileName }) => (
                        <div key={`${dashId}-${tileId}`} className='flex items-baseline space-x-4'>
                            <StateIndicator state={state} />
                            <div>
                                <p className='text-sm text-textSecondary'>{dashboardName}</p>
                                <p className='truncate'>{tileName || 'Unnamed tile'}</p>
                            </div>
                        </div>
                    ))}
                </div>
            }
        >
            + {monitors.length} monitors
        </Tooltip>
    );
};

function WorkspaceKPINode(node: Node<WorkspaceKPINodeData>) {
    const reactFlowInstance = useReactFlow();
    const graphNodes = reactFlowInstance.getNodes();

    const {
        workspacesWithDependenciesByNodeId,
        filterCriteria,
        setExpandedNodeIds
    } = useContext(WorkspaceNetworkContext) || {};

    const isSelected = node.data.selected;
    const nodeStyles = getStylesForNode(node.data.state);

    const { showIssuesOnly } = filterCriteria;

    let {
        name,
        dependencies,
        state,
        tiles,
        kpis,
        upstream = [],
        isHovered,
        workspaceID
    } = node.data;

    const { data: kpiValues, isLoading: isLoadingKPIs } = useQuery([KPI_DATA, workspaceID], async () =>
        ListKPIs([workspaceID], undefined, { includeValues: true })
    );

    const nodeTitleLink = useNodeTitleLink({ workspaceID });

    const hasHiddenUpstream =
        upstream &&
        upstream.some((upstreamNodeId) => {
            const upstreamNotAlreadyInGraph = !graphNodes.some(({ id }) => upstreamNodeId === id);
            const upstreamNodeHealth = workspacesWithDependenciesByNodeId.get(upstreamNodeId)?.state;

            return (
                upstreamNotAlreadyInGraph &&
                (!showIssuesOnly ||
                    (upstreamNodeHealth && isUnhealthy(upstreamNodeHealth)))
            );
        });

    const hasHiddenDownstream =
        dependencies &&
        dependencies.some((downstreamNodeId) => {
            const downStream = workspacesWithDependenciesByNodeId.get(downstreamNodeId);

            if (!downStream) {
                return false;
            }

            const downstreamNotAlreadyInGraph = !graphNodes.some(({ id }) => downstreamNodeId === id);
            const downstreamNodeHealth = downStream?.state;

            return (
                downStream &&
                downstreamNotAlreadyInGraph &&
                (!showIssuesOnly ||
                    (downstreamNodeHealth && isUnhealthy(downstreamNodeHealth)))
            );
        });

    const handleUpstreamClick = () => {
        setExpandedNodeIds((currentExpandedNodes) => {
            return [...new Set([...currentExpandedNodes, ...upstream])];
        });
    };

    const handleDownstreamClick = () => {
        setExpandedNodeIds((currentExpandedNodes) => {
            return [...new Set([...currentExpandedNodes, ...dependencies.map((id) => id)])];
        });
    };

    // Add the resolved KPI value to the KPI list - we can't replace the KPI lists
    // here because we're combining monitor state with the KPI temporarily.
    // TODO: once KPIs have health remove this logic
    kpis = kpis?.map((kpiWithoutValue) => {
        const kpiWithValue = kpiValues?.find(
            (resolvedKpi) =>
                resolvedKpi.dashboardId === kpiWithoutValue.dashboardId && resolvedKpi.tileId === kpiWithoutValue.tileId
        );

        return {
            ...kpiWithoutValue,
            ...(kpiWithValue || {})
        };
    });

    let tileMonitors = tiles;
    const hasKpis = kpis && kpis?.length > 0;
    const hasMonitors = tileMonitors && tileMonitors?.length > 0;

    // We only want to show a maximum of 10 KPIs + Monitors
    let tooltipKpis: any[] = [];
    let tooltipMonitors: TileState[] = [];

    if (hasKpis) {
        if (kpis!.length > 10) {
            tooltipKpis = kpis!.slice(10);
            kpis = kpis!.slice(0, 10);
        }

        if (hasMonitors) {
            // We only want to show monitors there is space for (else we'll show a summary)
            tooltipMonitors = tileMonitors!.slice(10 - kpis!.length);
            tileMonitors = tileMonitors!.slice(0, Math.min(10 - tooltipMonitors.length));
        }
    } else {
        if (hasMonitors && tileMonitors!.length > 10) {
            tooltipMonitors = tileMonitors!.slice(10);
            tileMonitors = tileMonitors!.slice(0, 10);
        }
    }

    return (
        <div className='relative p-5 overflow-x-visible cursor-default'>
            <div className={clsx('flex', !hasKpis && !hasMonitors ? 'items-center' : 'items-start')}>
                <div className='relative flex items-center cursor-pointer'>
                    {isHovered && (
                        <>
                            {hasHiddenUpstream && (
                                <div className='absolute z-20 transform left-2 -top-4'>
                                    <div className='absolute top-10 -right-7 w-5 pointer-events-none origin-top-left transform -rotate-[120deg] border-t-2 -z-10 border-dashed border-statusUnknownPrimary' />
                                    <Button
                                        title='Expand upstream'
                                        variant='tertiary'
                                        className='mt-1 whitespace-nowrap'
                                        onClick={() => handleUpstreamClick()}
                                    >
                                        <FontAwesomeIcon className='text-2xl' icon={faSplit} rotation={270} />
                                    </Button>
                                </div>
                            )}

                            {hasHiddenDownstream && (
                                <div className='absolute z-20 transform -bottom-4 left-2'>
                                    <div className='absolute bottom-10 -right-8 w-5 pointer-events-none origin-top-left transform -rotate-[240deg] border-t-2 -z-1 border-dashed border-statusUnknownPrimary' />
                                    <Button
                                        title='Expand downstream'
                                        variant='tertiary'
                                        className='mt-1 whitespace-nowrap'
                                        onClick={() => handleDownstreamClick()}
                                    >
                                        <FontAwesomeIcon className='text-2xl' icon={faSplit} rotation={90} />
                                    </Button>
                                </div>
                            )}
                        </>
                    )}

                    <Handle
                        style={{ bottom: '5%', left: '50%', transform: 'translateX(-50%)' }}
                        type='source'
                        position={Position.Bottom}
                        className='invisible'
                    />

                    <WorkspaceNode
                        id={node.id}
                        key={node.id}
                        state={state as HealthState}
                        isSelected={isSelected}
                        className={clsx('z-10 cursor-default', nodeStyles)}
                    />

                    <Handle
                        style={{ top: '5%', left: '50%', transform: 'translateX(-50%)' }}
                        type='target'
                        position={Position.Top}
                        className='invisible'
                    />

                    {(hasKpis || hasMonitors) && (
                        <div className='absolute z-5 left-full -ml-[50px] top-1/2 w-20 -z-10 border border-dividerPrimary' />
                    )}
                </div>

                <div className='flex flex-col items-start max-w-xs ml-4'>
                    <TitleTruncated title={name} href={nodeTitleLink} className='text-3xl font-bold' />
                    <div className='w-72 flex-flex-col'>
                        {hasKpis && (
                            <div className={cn('mt-4 border-r divide-y divide-dividerPrimary border-t border-dividerPrimary', !hasMonitors && 'border-b')}>
                                {kpis && kpis.length > 0 && (
                                    <>
                                        {kpis?.map((k: KPI) => (
                                            <KPIBox
                                                isLoading={isLoadingKPIs}
                                                kpi={k}
                                                key={`${k.dashboardId}-${k.tileId}`}
                                            />
                                        ))}
                                        {tooltipKpis.length > 0 && (
                                            <Tooltip
                                                className='p-4 text-2xl font-bold border-l-8 border-l-statusUnknownPrimary'
                                                title={
                                                    <div className='space-y-1'>
                                                        {tooltipKpis.map(
                                                            ({
                                                                name: kpiName,
                                                                kpiState,
                                                                formattedValue,
                                                                dashboardId,
                                                                tileId
                                                            }) => (
                                                                <div
                                                                    key={`${dashboardId}-${tileId}`}
                                                                    className='flex items-center space-x-4'
                                                                >
                                                                    <StateIndicator state={kpiState} />
                                                                    <div className='flex flex-col'>
                                                                        <p className='text-sm'>{kpiName}</p>
                                                                        <p className='truncate'>{formattedValue}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                }
                                            >
                                                + {tooltipMonitors.length} KPIs
                                            </Tooltip>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                        {hasMonitors && (
                            <div
                                className={clsx(
                                    'divide-y divide-dividerPrimary border-r border-b border-dividerPrimary',
                                    !hasKpis && 'mt-4 border-t',
                                    hasKpis && 'border-t-2 border-dividerPrimary'
                                )}
                            >
                                {tileMonitors && tileMonitors?.length > 0 && (
                                    <>
                                        {tileMonitors?.map((tile) => (
                                            <div key={`${tile.dashId}-${tile.tileId}`}>
                                                <TileBox tile={tile as TileStateWithDashboardName} />
                                            </div>
                                        ))}
                                        {tooltipMonitors.length > 0 && (
                                            <ExtraMonitors monitors={tooltipMonitors as TileStateWithDashboardName[]} />
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkspaceKPINode;

const useNodeTitleLink = (props: { workspaceID: Workspace['id'] }) => {
    const location = useLocation();

    const isInExplorerPage = location.pathname.startsWith('/explorer');
    if (isInExplorerPage) {
        return `/explorer/${props.workspaceID}`;
    }

    return `/workspace/${props.workspaceID}`;
};
