import { Button, TooltipButton } from '@/components/Button';
import { DialogContent } from '@/components/Dialog';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faCircleNotch, faPencil, faRefresh, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as RadixDialog from '@radix-ui/react-dialog';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { TimeframeEnumValue } from '@squaredup/timeframes';
import { ResizablePanelResizeHandle } from 'components/ResizablePanelResizeHandle';
import { TruncatedText } from 'components/TruncatedText';
import { StateIndicator } from 'components/ui/state/StateIndicator';
import { useDashboardContext } from 'contexts/DashboardContext';
import { FullScreenTileConfig, useFullscreenContext } from 'contexts/FullscreenContext';
import TileContext, { useTileContext } from 'contexts/TileContext';
import DataStreamBaseTile from 'dashboard-engine/basetiles/DataStreamBaseTile';
import { useForceRefresh } from 'dashboard-engine/hooks/useForceRefresh';
import { useFullscreenTileConfig } from 'dashboard-engine/hooks/useFullscreenTileConfig';
import { healthReasonMessage } from 'dashboard-engine/util/monitoring';
import { isOpenAccess } from 'lib/openAccessUtil';
import { ComponentRef, useRef, useState } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { UnshapedDataOption } from 'ui/editor/dataStream/TileEditor/preview/UnshapedDataOption';
import { UnshapedDataTable } from 'ui/editor/dataStream/TileEditor/preview/UnshapedDataTable';
import { KPIIcon } from 'ui/tile/KPIIcon';
import { TileWarnings } from 'ui/tile/TileWarnings';
import { useLastRefreshed } from 'ui/tile/hooks/useLastRefreshed';
import { FullscreenTileMenu } from './FullscreenTileMenu';
import { FullscreenTimeframeChooser } from './FullscreenTimeframeChooser';

const FULLSCREEN_TILE_ID = 'tile-fullscreenTile';

const FullscreenTileToolbar = () => (
    <div id={`${FULLSCREEN_TILE_ID}Toolbar`} className='*:flex hide-for-image-export' />
);

const VISUALISATION_HEIGHT_PERCENTAGE = 70;

export const FullscreenTile: React.FC = () => {
    const [isShowingUnshapedData, setIsShowingUnshapedData] = useState(false);

    const { isEditingEnabled, fullscreenConfig, setIsEditing } = useFullscreenContext();
    const { health, ...config } = fullscreenConfig as FullScreenTileConfig;

    const tileContext = useTileContext();
    const { timeframe: dashboardTimeframe } = useDashboardContext();

    const [timeframeOverride, setTimeframeOverride] = useState<TimeframeEnumValue>(
        config?.timeframe ?? dashboardTimeframe
    );
    const configWithTimeframeOverride = useFullscreenTileConfig(config, timeframeOverride);
    const isTableVisualisation = config.visualisation?.type === 'data-stream-table';
    const verticalPanelGroupRef = useRef<ComponentRef<typeof PanelGroup>>(null);

    const handleResetVerticalPanelGroupLayout = () => {
        verticalPanelGroupRef.current?.setLayout([
            { sizePercentage: 100 - VISUALISATION_HEIGHT_PERCENTAGE },
            { sizePercentage: VISUALISATION_HEIGHT_PERCENTAGE }
        ]);
    };

    // Some hooks will not work in an open access dashboard so it's key we track whether we're in openaccess
    const isOA = isOpenAccess();

    const { forceRefresh, isRefreshing } = useForceRefresh(config);
    const { lastRefreshedString } = useLastRefreshed(config);

    const statusMessage = healthReasonMessage(health?.state, health?.stateReason);

    const hasChangedTimeframe = config?.timeframe !== timeframeOverride;

    const isGrouped = config.dataStream?.group?.aggregate?.length || -1 > 0;
    const isFiltered = config.dataStream?.filter;
    const isSorted = config.dataStream?.sort;
    const isTableShaped = isGrouped || isFiltered || isSorted;

    if (!config) {
        return null;
    }

    const DataTable = isShowingUnshapedData ? UnshapedDataTable : DataStreamBaseTile;

    return (
        <DialogContent className='fixed flex w-[92.5%] h-full justify-center -translate-x-1/2 gap-y-sm left-1/2 top-[24px] max-h-[calc(100%-48px)]'>
            <TileContext.Provider
                value={{
                    ...tileContext,
                    config: configWithTimeframeOverride,
                    health: !hasChangedTimeframe ? health : undefined,
                    tileId: FULLSCREEN_TILE_ID
                }}
            >
                <PanelGroup
                    ref={verticalPanelGroupRef}
                    direction='vertical'
                    className='min-h-0 border bg-backgroundSecondary border-modalOutline animate animate-enter text-textPrimary'
                    dataAttributes={{ 'data-testid': 'fullscreenDialog' }}
                >
                    <Panel
                        minSizePixels={160}
                        defaultSizePercentage={isTableVisualisation ? 100 : VISUALISATION_HEIGHT_PERCENTAGE}
                        className='max-h-full bg-tileBackground'
                    >
                        <div
                            id={FULLSCREEN_TILE_ID}
                            className='relative flex flex-col w-full h-full max-h-full gap-y-sm p-lg bg-tileBackground'
                        >
                            <div className='flex items-start justify-between gap-x-xs shrink-0'>
                                <div className='min-w-0'>
                                    <div className='flex items-center w-full gap-x-sm'>
                                        {health && !hasChangedTimeframe && (
                                            <StateIndicator
                                                className='w-4 h-4'
                                                state={health.state}
                                                titleOverride={statusMessage}
                                            />
                                        )}

                                        <div className='flex flex-1 overflow-hidden gap-x-xs'>
                                            {config.title && (
                                                <Text.H2 className='flex w-full min-w-0' tabIndex={0}>
                                                    <TruncatedText
                                                        title={config.title}
                                                        element='span'
                                                        className='inline-flex max-w-full'
                                                    >
                                                        {config.title}
                                                    </TruncatedText>
                                                </Text.H2>
                                            )}

                                            {config.kpi && (
                                                <div className='flex mr-2'>
                                                    <KPIIcon kpiConfig={(config as DataStreamBaseTileConfig)?.kpi} />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {config.description && (
                                        <Text.H5 className={cn('text-textSecondary w-full', health && 'pl-[30px]')}>
                                            <TruncatedText
                                                title={config.description}
                                                element='span'
                                                className='inline-flex max-w-full'
                                            >
                                                {config.description}
                                            </TruncatedText>
                                        </Text.H5>
                                    )}
                                </div>

                                <div className='flex items-center justify-end gap-x-sm hide-for-image-export'>
                                    {isTableVisualisation && <FullscreenTileToolbar />}

                                    {config._type === 'tile/data-stream' && (
                                        <TileWarnings config={config as DataStreamBaseTileConfig} />
                                    )}

                                    {!isOA && (
                                        <TooltipButton
                                            onClick={() => forceRefresh()}
                                            variant='secondary'
                                            className='px-4'
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={isRefreshing ? faCircleNotch : faRefresh}
                                                    spin={isRefreshing}
                                                    fixedWidth
                                                />
                                            }
                                            disabled={isRefreshing}
                                            title={lastRefreshedString}
                                        ></TooltipButton>
                                    )}
                                    {!isOA && (
                                        <FullscreenTimeframeChooser
                                            timeframe={timeframeOverride}
                                            setTimeframe={setTimeframeOverride}
                                        />
                                    )}
                                    {isEditingEnabled && (
                                        <Button
                                            onClick={() => setIsEditing(true)}
                                            variant='secondary'
                                            icon={<FontAwesomeIcon icon={faPencil} />}
                                        >
                                            Edit
                                        </Button>
                                    )}
                                    <FullscreenTileMenu isShowingUnshapedData={isShowingUnshapedData} />

                                    <RadixDialog.Close asChild>
                                        <FontAwesomeIcon
                                            icon={faXmark}
                                            className='text-xl cursor-pointer text-tertiaryButton hover:text-tertiaryButtonHover'
                                        />
                                    </RadixDialog.Close>
                                </div>
                            </div>

                            <div
                                className={cn(
                                    'relative flex w-full min-h-0',
                                    isTableVisualisation ? 'max-h-full' : 'h-full'
                                )}
                                data-testid='fullscreenTile'
                            >
                                <DataStreamBaseTile config={configWithTimeframeOverride} />
                            </div>
                        </div>
                    </Panel>

                    {!isTableVisualisation && (
                        <>
                            <ResizablePanelResizeHandle
                                direction='vertical'
                                onResetLayout={handleResetVerticalPanelGroupLayout}
                            />
                            <Panel
                                minSizePixels={130}
                                className='flex flex-col w-full h-full min-w-0 gap-y-sm p-lg group/tile bg-tileBackground'
                            >
                                <div className='flex items-center justify-end gap-x-sm'>
                                    <Text.H2 className='mr-auto'>Data</Text.H2>

                                    <FullscreenTileToolbar />

                                    {isTableShaped && (
                                        <UnshapedDataOption
                                            isShowingUnshapedData={isShowingUnshapedData}
                                            setIsShowingUnshapedData={setIsShowingUnshapedData}
                                        />
                                    )}
                                </div>

                                <div className='relative w-full min-h-0 overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                                    <DataTable
                                        config={{
                                            ...configWithTimeframeOverride,
                                            visualisation: {
                                                type: 'data-stream-table',
                                                config: {
                                                    // This ensures we don't try to generate a default config and transpose
                                                    // a single row table given we're meant to be showing raw data for
                                                    // this panel
                                                    'data-stream-table': {}
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </Panel>
                        </>
                    )}
                </PanelGroup>
            </TileContext.Provider>
        </DialogContent>
    );
};
