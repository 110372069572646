import { Edge } from 'reactflow';
import { FLOATING_EDGE } from '../../components/edges/FloatingEdge';

/**
 * The passed contentType is the type of the source node, therefore the type of edge can be inferred
 * e.g. a contentType of 'dashboard' is a part of a workspace and therefore we set the type as 'workspace'
 */

export const edge = (
    source: string, 
    target: string, 
    label?: string
): Edge => {
    
    return {
        id: `${source}-${target}`,
        source,
        target,
        label,
        type: FLOATING_EDGE
    };
};
