import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { NO_ACTIVE_WORKSPACE } from 'services/WorkspaceUtil';

export const getSavedScopeId = (scope: DataStreamBaseTileConfig['scope'], currentWorkspaceID: string) => {
    const isGlobal = currentWorkspaceID === NO_ACTIVE_WORKSPACE;

    if (!scope) {
        return;
    }

    if (!Array.isArray(scope) && 
        'scope' in scope && 
        'workspace' in scope &&
        (isGlobal || scope.workspace === currentWorkspaceID)
    ) {
        return scope.scope;
    }
};
