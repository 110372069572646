import DropdownMenu from '@/components/DropdownMenu';
import { faEllipsisV, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useDOMElement } from 'components/hooks/useDOMElement';
import Tooltip from 'components/tooltip/Tooltip';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import EasyEdit from 'react-easy-edit';
import { DatasetTabInput } from './DatasetTabInput';

const alphaNumericRegex = /^[a-zA-Z][a-zA-Z0-9]*$/u;

const editIconAnimations = {
    initial: { width: 0, opacity: 0 },
    animate: { width: 'auto', opacity: 1, transition: { ease: 'circOut' } }
};

const DatasetValidationMessage = () => {
    return (
        <Tooltip title='Dataset names should be **unique**, **alphanumeric**, and **cannot start with a number**.'>
            <FontAwesomeIcon icon={faExclamationCircle} className='text-statusErrorPrimary' />
        </Tooltip>
    );
};

interface DatasetTabProps {
    datasetName: string;
    onSave: (newDatasetName: string) => void;
    onClone: () => void;
    onDelete: () => void;
    isActive: boolean;
    existingNames: string[];
}

export const DatasetTab: React.FC<DatasetTabProps> = ({
    datasetName,
    onSave,
    onClone,
    onDelete,
    existingNames,
    isActive
}) => {
    const [isValid, setIsValid] = useState(true);
    const menuContainer = useDOMElement('dialogContent');

    return (
        <motion.div className='flex items-center px-6 py-4' initial='initial' whileHover='animate'>
            <EasyEdit
                type='text'
                value={datasetName}
                allowEdit={isActive}
                saveOnBlur={true}
                editComponent={<DatasetTabInput />}
                displayComponent={
                    <span
                        className={clsx(isActive && 'cursor-text hover:italic', !isActive && 'cursor-pointer', 'px-1')}
                    >
                        {datasetName}
                    </span>
                }
                onSave={onSave}
                onValidate={(value: string) => {
                    const isAlphanumeric = alphaNumericRegex.test(value);
                    const duplicateName = existingNames.filter((n) => n !== datasetName).includes(value);
                    const valid = value !== null && value !== '' && isAlphanumeric && !duplicateName;

                    if (valid) {
                        setIsValid(true);
                        return valid;
                    }

                    setIsValid(false);
                    return valid;
                }}
                validationMessage={true}
                role='input'
            />

            {!isValid && <DatasetValidationMessage />}

            <motion.div variants={editIconAnimations}>
                <DropdownMenu>
                    <DropdownMenu.Trigger>
                        <button
                            onClick={(e) => {
                                // Don't switch tab when clicking the ... button
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        >
                            <FontAwesomeIcon
                                className={clsx(
                                    'text-textSecondary px-4 -mr-6 hover:text-textPrimary transition ease-in-out duration-200'
                                )}
                                icon={faEllipsisV}
                            />
                        </button>
                    </DropdownMenu.Trigger>

                    <DropdownMenu.Menu
                        menuId='tileMenu'
                        className='divide-y divide-outlinePrimary'
                        container={menuContainer}
                    >
                        <DropdownMenu.Group>
                            <DropdownMenu.Item onSelect={onClone} key='clone'>
                                Clone
                            </DropdownMenu.Item>
                            <DropdownMenu.Item
                                onSelect={onDelete}
                                key='delete'
                                className={clsx(
                                    'text-statusErrorPrimary',
                                    existingNames.length === 1 && 'text-textDisabled cursor-default'
                                )}
                                disabled={existingNames.length === 1}
                            >
                                Delete
                            </DropdownMenu.Item>
                        </DropdownMenu.Group>
                    </DropdownMenu.Menu>
                </DropdownMenu>
            </motion.div>
        </motion.div>
    );
};
