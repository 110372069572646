import DataStreamWorkspaceContext from 'contexts/DataStreamWorkspaceContext';
import { NO_ACTIVE_WORKSPACE } from 'services/WorkspaceUtil';

/**
 * Wrapper to execute any nested data stream tiles in a global context instead of the current workspace
 * This is useful if you know you need access to unlinked datasources/dataStreams. e.g. in search/drilldown
 */
export const GlobalDataStreamContext: React.FC<{ workspace?: string }> = ({ workspace, children }) => {
    return (
        <DataStreamWorkspaceContext.Provider value={{ workspace: workspace ?? NO_ACTIVE_WORKSPACE }}>
            {children}
        </DataStreamWorkspaceContext.Provider>
    );
};
