import { cn } from '@/lib/cn';
import Tooltip from 'components/tooltip/Tooltip';
import { FC } from 'react';
import { useValidationState } from './useValidationState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';

interface TooltipValidationProps {
    name: string;
    className?: string;
    showIcon?: boolean;
    validateOnLoad?: boolean;
    /**
     * If true (the default), valid values will cause the field to be highlighted in the success colours
     */
    highlightValidValues?: boolean;
}

/**
 * Display form validation messages as a tooltip and apply appropriate CSS
 * around any children
 *
 * @example
 * <TooltipValidation name={'filterValue'}>
 *     <Input.Raw type='text' name={'filterValue'} placeholder='Value' />
 * </TooltipValidation>
 */
export const TooltipValidation: FC<TooltipValidationProps> = ({
    name,
    children,
    className,
    showIcon = true,
    validateOnLoad,
    highlightValidValues
}) => {
    const { isValid, isTouched, isDirty, error, validationClassName } = useValidationState({
        name,
        validateOnLoad
    });

    const icon = isValid ? faCircleCheck : faXmarkCircle;
    const showValidation = (isTouched || validateOnLoad) && (isDirty || !isValid) && (highlightValidValues || !isValid);

    return (
        <Tooltip title={error?.message} disabled={!showValidation} className={className}>
            <div
                className={cn(
                    'bg-componentBackgroundPrimary ring-inset ring-1 ring-outlinePrimary focus-within:ring-inset focus-within:ring-1 rounded-input focus-within:ring-outlineSecondary leading-input text-textPrimary placeholder-textIncomplete disabled:text-textDisabled disabled:pointer-events-none',
                    showValidation && validationClassName,
                    showValidation && 'flex items-center'
                )}
            >
                {children}
                {showIcon && showValidation && <FontAwesomeIcon className='mr-4' fixedWidth icon={icon} />}
            </div>
        </Tooltip>
    );
};
