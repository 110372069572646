import DropdownMenu from '@/components/DropdownMenu';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTileContext } from 'contexts/TileContext';

export const DeleteTile: React.FC = () => {
    const { onDelete } = useTileContext();

    return (
        <DropdownMenu.Item
            icon={<FontAwesomeIcon icon={faTrash} className='text-statusErrorPrimary' fixedWidth />}
            className='text-statusErrorPrimary'
            onSelect={() => onDelete?.()}
            key='delete'
        >
            Delete
        </DropdownMenu.Item>
    );
};
