import Text from '@/components/Text';
import { MarkdownBase } from 'components/markdown/MarkdownBase';

type FieldMarkdownProps = {
    content: string;
};

export const FieldMarkdown: React.FC<FieldMarkdownProps> = ({ content }) => {
    if (!content) {
        return null;
    }
    return (
        <div className='relative mt-6 first:mt-0 text-textPrimary'>
            <MarkdownBase content={content} components={{ p: Text.Body }} />
        </div>
    );
};
