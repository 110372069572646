import LoginWrapper from 'pages/login/LoginWrapper';
import { linkButtonClasses } from './loginStyles';

// NOTE: Use of text-[14px] on this page is to match B2C sign-up / sign-in pages

function signInAgain() {
    // Just reload the app. Should trigger sign-in, either direct to identify provider
    // or via our sign-in page.
    window.location.href = '/';
}

export function SignOutComplete() {
    return (
        <LoginWrapper>
            <div className='flex flex-col flex-1 min-h-0 leading-normal' data-testid='signInFail'>
                <h1 className='mt-10 text-2xl font-semibold'>You have been signed out.</h1>

                <p className='mt-10 text-textSecondary text-[14px]'>
                    <button className={`${linkButtonClasses}`} onClick={() => signInAgain()}>
                        Sign in
                    </button>
                </p>
            </div>
        </LoginWrapper>
    );
}
