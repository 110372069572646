import { StreamDataColumn } from '@squaredup/data-streams';
import Input from 'components/forms/input/Input';
import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import { TooltipValidation } from 'components/forms/validationMessage/TooltipValidationMessage';
import Tooltip from 'components/tooltip/Tooltip';
import EditorSelect from 'ui/editor/components/EditorSelect';
import {
    ColumnOption,
    Filter,
    getEmptyFilter,
    getFilterOperations,
    getFilterValueType,
    multiOperationOptions,
    stateOptions
} from 'ui/editor/dataStream/utilities/filters';
import { DeleteRowButton } from './DeleteRowButton';

interface FilterOptionProps {
    index: number;
    filter: Filter;
    columnOptions: AutocompleteOption[];
    columns: StreamDataColumn[];
    update: (values: Filter) => void;
    remove: () => void;
}

const PlaceholderEmptyInput = () => <Input type='text' name='placeholder' disabled placeholder='Enter a value' />;

export const FilterOption: React.FC<FilterOptionProps> = ({
    index,
    filter,
    columnOptions,
    columns,
    update,
    remove
}) => {
    const column = columns.find((c: any) => filter.column === c.name);

    const operationOptions = getFilterOperations(column);

    const handleColumnChange = (changedColumn: ColumnOption) => {
        // If there's no column, reset the filter as the other fields will be invalid
        if (!changedColumn) {
            update(getEmptyFilter());
            return;
        }

        const newColumn = columns.find((c) => changedColumn.value === c.name);
        const newOperations = getFilterOperations(newColumn);

        // If the column has changed, and the existing operation isn't supported by the new column,
        // update the filter with a valid filter and value
        if (filter?.operation && !newOperations.some((option) => option.value === filter?.operation)) {
            update({
                column: changedColumn.value,
                operation: newOperations[0].value,
                value: null
            });
        }
    };

    const filterValueType = getFilterValueType(column?.shapeName);

    return (
        <div className='flex space-x-2'>
            {index > 0 && (
                <div className='w-32'>
                    <EditorSelect
                        name='multiOperation'
                        options={multiOperationOptions}
                        isClearable={false}
                        isDisabled={index !== 1}
                    />
                </div>
            )}
            <div className='flex-1 min-w-0'>
                <EditorSelect
                    name={`filters.${index}.column`}
                    options={columnOptions}
                    placeholder='Select column'
                    onSelect={handleColumnChange}
                    isClearable={false}
                />
            </div>
            <div className='flex-1 min-w-0'>
                <EditorSelect
                    name={`filters.${index}.operation`}
                    options={operationOptions}
                    placeholder='Equals'
                    isClearable={false}
                    isDisabled={!column}
                />
            </div>
            {filter.operation && !['notempty', 'empty'].includes(filter.operation) && (
                <>
                    {filterValueType === 'state' && (
                        <div className='flex-1 min-w-0'>
                            <EditorSelect name={`filters.${index}.stateValue`} options={stateOptions} />
                        </div>
                    )}
                    {filterValueType === 'text' && (
                        <TooltipValidation
                            name={`filters.${index}.value`}
                            highlightValidValues={false}
                            className='flex-1 min-w-0'
                        >
                            <Input.Raw type='text' name={`filters.${index}.value`} placeholder='Enter a value' />
                        </TooltipValidation>
                    )}
                </>
            )}

            {!filter.operation ||
                (!column && (
                    <div className='flex-1 min-w-0'>
                        <PlaceholderEmptyInput />
                    </div>
                ))}

            <Tooltip title='Remove this filter' className='flex w-6 place-items-center'>
                <DeleteRowButton aria-label='Remove this filter' className='block w-full h-full' onClick={remove} />
            </Tooltip>
        </div>
    );
};
