import Text from '@/components/Text';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons, ifNotOutside } from 'components/Modal';
import Button from 'components/button/Button';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { useAppContext } from 'contexts/AppContext';
import { AnimatePresence } from 'framer-motion';
import trackEvent, { trackEventDebounced } from 'lib/analytics';
import { debounce, omit } from 'lodash';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { streamDataKeys } from 'queries/queryKeys/streamDataKeys';
import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { CreateScope, Scope, UpdateScope } from 'services/WorkspaceService';
import { SearchBar } from 'ui/editor/dataStream/TileEditor/SearchBar';
import { useConvertOobScopeToNodes } from 'ui/editor/dataStream/TileEditor/hooks/useConvertOobScopeToNodes';
import { FilterType } from 'ui/editor/dataStream/TileEditor/hooks/useDataStreamObjectFilters';
import { ObjectDynamicToggle } from 'ui/editor/dataStream/TileEditor/objects/ObjectDynamicToggle';
import { ObjectFilters } from 'ui/editor/dataStream/TileEditor/objects/ObjectFilters';
import { ObjectTable } from 'ui/editor/dataStream/TileEditor/objects/ObjectTable';
import { SelectedObjectsCountButton } from 'ui/editor/dataStream/TileEditor/steps/SelectedObjectsCountButton';
import { SelectedObjectsPanel } from 'ui/editor/dataStream/TileEditor/steps/SelectedObjectsPanel';
import { useDatasetContext } from 'ui/editor/dataStream/contexts/DatasetContext';
import { useTileEditorObjectsFilterContext } from 'ui/editor/dataStream/contexts/TileEditorObjectsFilterContext';
import { ScopeModalHeader } from './scopeModal/ScopeModalHeader';
import { ScopeNameForm } from './scopeModal/ScopeNameForm';
import { ScopeObjectCountLimitMessage } from './scopeModal/ScopeObjectCountLimitMessage';

const formSteps = {
    WARNING: 'warning',
    SCOPE: 'scope',
    NAME: 'name'
};

interface CreateEditScopeModalProps {
    existingScope?: {
        id: string;
        version?: number;
        workspace: string;
        displayName: string;
        isDependency?: boolean;
        query: string;
        queryDetail: any;
        bindings: any;
    };
    defaultName?: string;
    onClose: (modifiedScope?: { id: string }) => void;
}

/**
 * Modal component for creating and editing scopes, with or without an associated data stream.
 * Required to be within a DatasetContext and TileEditorObjectsFilterContext.
 * Used within the Scopes page and Tile Editor.
 *
 * @param existingScope An optional existing scope that is being edited
 * @param onClose A function to be called to close the modal
 * @returns An open modal used to create or edit a scope
 */
export const CreateEditScopeModal: React.FC<CreateEditScopeModalProps> = ({ existingScope, defaultName, onClose }) => {
    const { currentWorkspaceID } = useAppContext();
    const { config } = useDatasetContext();
    const queryClient = useQueryClient();
    const objectFilterState = useTileEditorObjectsFilterContext();
    const container = useDOMElement('dialogContent');
    const [step, setStep] = useState(
        Boolean(existingScope) && !existingScope?.version && objectFilterState.isDynamic
            ? formSteps.WARNING
            : formSteps.SCOPE
    );

    const {
        count,
        isDynamic,
        filterQuery,
        isConfigured,
        selectedObjects,
        dynamicObjectsCount,
        selectedObjectsCount,
        isLoadingFilters,
        isFetchingObjects,
        interactedObjects,
        setFilterQuery,
        setInteractedObjects,
        handleSetFilterState,
        handleFixedScope
    } = objectFilterState;

    const editingExistingScope = Boolean(existingScope);

    const [toggleObjectsPanel, setToggleObjectsPanel] = useState(selectedObjectsCount ? true : false);

    const onConverted = useCallback(() => {
        setToggleObjectsPanel(true);
    }, [setToggleObjectsPanel]);
    useConvertOobScopeToNodes(onConverted);

    // We only want to update the search query every 500ms to avoid sending multiple
    // requests too quickly
    const debouncedSearch = useMemo(
        () =>
            debounce((searchTerm: string) => {
                trackEventDebounced('Objects Searched For', { query: searchTerm });
                return handleSetFilterState(FilterType.query, searchTerm, setFilterQuery);
            }, 500),
        [handleSetFilterState, setFilterQuery]
    );

    const handleObjectsPanelToggle = () => {
        if (!isDynamic) {
            setToggleObjectsPanel(!toggleObjectsPanel);
        }
    };

    const handleSubmit = async (data: { name: string }) => {
        if (!currentWorkspaceID || !config.scope || Array.isArray(config.scope)) {
            return;
        }

        const scopeData = {
            name: data.name,
            version: 2,
            ...omit(config.scope, ['id', 'isDependency'])
        } as Scope;

        if (existingScope) {
            await UpdateScope(currentWorkspaceID, existingScope.id, {
                ...scopeData,
                ...(existingScope.isDependency && { isDependency: true })
            });
            onClose({
                id: existingScope.id
            });
        } else {
            const createdScopeId = await CreateScope(currentWorkspaceID, scopeData);
            trackEvent('Scope Created', { name: scopeData.name, workspace: currentWorkspaceID });
            onClose({
                id: createdScopeId
            });
        }

        queryClient.resetQueries(scopeQueryKeys.workspace(currentWorkspaceID));
        queryClient.resetQueries(streamDataKeys.all);
        queryClient.resetQueries(['scopeNodes']);
        queryClient.resetQueries(['streamMatches']);
    };

    return (
        <Modal
            close={ifNotOutside(() => onClose())}
            container={container}
            fullWidth={true}
            fullHeight={true}
            maxWidth={step === formSteps.SCOPE ? 'max-w-[80%]' : 'max-w-3xl'}
            maxHeight={step === formSteps.SCOPE ? 'h-[80%]' : 'h-auto'}
        >
            <div className='flex h-full overflow-hidden'>
                {step === formSteps.SCOPE && (
                    <>
                        <div className='flex flex-col flex-1 min-w-0'>
                            <ScopeModalHeader
                                isEditing={editingExistingScope}
                                existingScopeName={existingScope?.displayName}
                                onClose={onClose}
                            />

                            {isLoadingFilters ? (
                                <div className='flex items-center justify-center w-full h-full'>
                                    <LoadingSpinner className='m-auto' />
                                </div>
                            ) : (
                                <>
                                    <div className='flex flex-1 min-h-0 py-5 pr-5 space-x-6 pl-9'>
                                        <ObjectFilters />

                                        <div className='flex flex-col flex-1 h-full min-w-0 min-h-0'>
                                            <div className='flex mb-4 mr-4'>
                                                <SearchBar
                                                    initialValue={filterQuery}
                                                    placeholder='Search objects...'
                                                    onChange={debouncedSearch}
                                                    className='flex-1 min-w-0 mr-8'
                                                    showHelp={true}
                                                />

                                                <ObjectDynamicToggle onClick={() => setToggleObjectsPanel(false)} />
                                            </div>

                                            <ObjectTable />

                                            <div className='mt-4'>
                                                <ScopeObjectCountLimitMessage />

                                                <div className='flex'>
                                                    <SelectedObjectsCountButton
                                                        totalCount={count || 0}
                                                        interactedObjectsCount={interactedObjects.length}
                                                        selectedObjectsCount={selectedObjectsCount}
                                                        dynamicObjectsCount={dynamicObjectsCount}
                                                        isDynamic={isDynamic}
                                                        onClick={() => {
                                                            handleObjectsPanelToggle();
                                                            setInteractedObjects(selectedObjects);
                                                        }}
                                                    />
                                                    {isFetchingObjects && <LoadingSpinner size={20} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ModalButtons hideTopMargin={true}>
                                        <div className='flex flex-col flex-1 space-y-2'>
                                            {editingExistingScope && (
                                                <div className='flex-1 text-xs text-textSecondary'>
                                                    <FontAwesomeIcon className='pr-1' icon={faInfoCircle} /> Warning:
                                                    editing this configuration may impact tiles using this collection
                                                </div>
                                            )}
                                        </div>

                                        {!isDynamic && (
                                            <Button
                                                type='button'
                                                disabled={selectedObjectsCount === 0}
                                                onClick={() => handleFixedScope([])}
                                                variant='tertiary'
                                                data-testid='cancelScope'
                                            >
                                                Clear
                                            </Button>
                                        )}

                                        <Button
                                            type='button'
                                            onClick={() => onClose()}
                                            variant='secondary'
                                            data-testid='cancelScope'
                                        >
                                            Cancel
                                        </Button>

                                        <Button
                                            disabled={!isConfigured}
                                            onClick={() => setStep(formSteps.NAME)}
                                            data-testid='nextScope'
                                        >
                                            Next
                                        </Button>
                                    </ModalButtons>
                                </>
                            )}
                        </div>
                        <AnimatePresence>
                            {toggleObjectsPanel && interactedObjects.length !== 0 && !isDynamic && (
                                <SelectedObjectsPanel handleObjectsPanelToggle={handleObjectsPanelToggle} />
                            )}
                        </AnimatePresence>
                    </>
                )}
                {step === formSteps.NAME && (
                    <div className='flex flex-col flex-1 min-w-0'>
                        <ScopeModalHeader
                            isEditing={editingExistingScope}
                            existingScopeName={existingScope?.displayName}
                            onBack={() => setStep(formSteps.SCOPE)}
                            onClose={onClose}
                        />
                        <ScopeNameForm
                            initialValue={defaultName || existingScope?.displayName}
                            onSubmit={handleSubmit}
                            onClose={onClose}
                        />
                    </div>
                )}
                {step === formSteps.WARNING && (
                    <div className='flex flex-col flex-1 min-w-0'>
                        <ScopeModalHeader
                            isEditing={true}
                            existingScopeName={existingScope?.displayName}
                            onClose={onClose}
                        />
                        <div className='flex-1 py-5 px-9'>
                            <Text.Body>
                                Warning: You are editing a legacy collection, you can continue but the previously
                                configured filters may not be applied. Confirm the object selection is accurate before
                                saving.
                            </Text.Body>
                        </div>
                        <ModalButtons hideTopMargin={true}>
                            <Button
                                type='button'
                                onClick={() => onClose()}
                                variant='tertiary'
                                data-testid='cancelScope'
                            >
                                Cancel
                            </Button>

                            <Button disabled={!isConfigured} onClick={() => setStep(formSteps.SCOPE)}>
                                Continue
                            </Button>
                        </ModalButtons>
                    </div>
                )}
            </div>
        </Modal>
    );
};
