import { buildQuery } from '@squaredup/graph';
import stringify from 'fast-json-stable-stringify';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Query } from 'services/GraphService';
import { useCheckScopeIsOobAndHasLimit } from 'ui/tile/hooks/useCheckScopeIsOobAndHasLimit';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { useTileEditorObjectsFilterContext } from '../../contexts/TileEditorObjectsFilterContext';

/**
 * A hook to convert a OOB dashboard scope with a limit into a fixed scope containing
 * the nodes that are returned by the scope's query
 * @param onConverted a function called when a scope has been successfully converted
 */
export const useConvertOobScopeToNodes = (onConverted: (nodeIds: string[]) => void) => {
    const { config, setConfig } = useDatasetContext();
    const { setIsDynamic, setInteractedObjects, setSelectedObjects } = useTileEditorObjectsFilterContext();
    const { isLoading: isLoadingLimit, limit, isSavedScope } = useCheckScopeIsOobAndHasLimit(config);

    // Only run the conversion when it's an in-tile scope, or it's the original scope
    const shouldConvert = limit !== false && !isSavedScope;

    const { scope } = config;

    const { data: nodeIds } = useQuery(
        ['resolveScopeToNodes', stringify(scope)],
        async () => {
            if (
                !scope ||
                typeof scope === 'string' ||
                Array.isArray(scope) ||
                !('query' in scope && 'bindings' in scope && scope.query && scope.bindings)
            ) {
                return [];
            }

            const { gremlinQueryResults } = await Query({
                gremlinQuery: scope.query,
                bindings: scope.bindings
            });

            return gremlinQueryResults.map(({ id }) => id) as string[];
        },
        { enabled: !isLoadingLimit && shouldConvert }
    );

    useEffect(() => {
        if (!shouldConvert || !nodeIds || nodeIds.length === 0) {
            return;
        }

        const scopeContents = {
            ids: nodeIds
        };

        const scopeQuery = buildQuery(scopeContents, '');

        setConfig((currentConfig) => ({
            ...currentConfig,
            scope: {
                query: scopeQuery.gremlinQuery,
                bindings: scopeQuery.bindings,
                queryDetail: scopeContents
            }
        }));

        setIsDynamic(false);
        setInteractedObjects(nodeIds);
        setSelectedObjects(nodeIds);
        onConverted(nodeIds);
    }, [nodeIds, shouldConvert, setConfig, setIsDynamic, setInteractedObjects, setSelectedObjects, onConverted]);
};
