import { Query } from '../../../../services/GraphService';
import { NodeData } from '../../../types/data/GraphData';
import { DatasourceFunction } from '../../../types/Datasource';
import { GraphQueryConfig } from './Config';

const graphCustom: DatasourceFunction<NodeData, GraphQueryConfig> = (config) => {
    return Query(config).then(({gremlinQueryResults}) => {
        return { data: gremlinQueryResults };
    });
};

graphCustom.config = 'GraphQueryConfig';

export default graphCustom;
