import { faExpand, faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/tooltip/Tooltip';
import { ControlButton, ReactFlowState, useReactFlow, useStore } from 'reactflow';
import { shallow } from 'zustand/shallow';

const selector = (s: ReactFlowState) => ({
    minZoomReached: s.transform[2] <= s.minZoom,
    maxZoomReached: s.transform[2] >= s.maxZoom
});
  

export const DefaultControls: React.FC = () => {
    const { minZoomReached, maxZoomReached } = useStore(selector, shallow);
    const { zoomIn, zoomOut, fitView } = useReactFlow();

    const onZoomInHandler = () => {
        zoomIn();
    };
    
    const onZoomOutHandler = () => {
        zoomOut();
    };
    
    const onFitViewHandler = () => {
        fitView();
    };

    return (
        <>
            <Tooltip title='Zoom in'>
                <ControlButton
                    onClick={onZoomInHandler}
                    disabled={maxZoomReached}
                >
                    <FontAwesomeIcon 
                        icon={faPlus} 
                        fixedWidth 
                    />
                </ControlButton>
            </Tooltip>
            <Tooltip title='Zoom out'>
                <ControlButton
                    onClick={onZoomOutHandler}
                    disabled={minZoomReached}
                >
                    <FontAwesomeIcon 
                        icon={faMinus} 
                        fixedWidth 
                    />
                </ControlButton>
            </Tooltip>
            <Tooltip title='Fit view'>
                <ControlButton 
                    onClick={onFitViewHandler}
                >
                    <FontAwesomeIcon 
                        icon={faExpand} 
                        fixedWidth 
                    />
                </ControlButton>
            </Tooltip>
        </>
    );
};
