import { fiveMinutes } from 'queries/constants';
import { datasourceConfigQueryKeys } from 'queries/queryKeys/datasourceConfigKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { GetCountOfDataSourceConfigs } from 'services/SourceConfigService';

type QueryReturnType = Awaited<ReturnType<typeof GetCountOfDataSourceConfigs>>;
type QuerySelectReturnType = QueryReturnType;

/**
 * useQuery returning the count of data source configs within the tenant
 * This method returns the global count (it bypasses access control)
 * @param options The react-query options e.g. refreshInterval
 * @returns The count of all data source configs within the tenant
 */
export const useDatasourceConfigsCount = (
    options?: UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>
) => 
    useQuery(
        datasourceConfigQueryKeys.count,
        GetCountOfDataSourceConfigs,
        {
            staleTime: fiveMinutes,
            cacheTime: fiveMinutes,
            keepPreviousData: true,
            ...options
        }
    );
