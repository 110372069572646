import { faCircleExclamation, faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDistanceToNow } from 'date-fns';
import type { UserGroupModel } from 'dynamo-wrapper';
import { FC, useMemo } from 'react';
import TruncateWithTooltip from '../TruncateWithTooltip';
import { User } from './Users';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { ColumnDef } from '@tanstack/react-table';
import { sortTimeAgo } from 'pages/components/ApplicationTable/ApplicationTableSort';
import { Action } from 'pages/components/ApplicationTable/types';

interface UsersTableProps {
    users: User[];
    userGroups: UserGroupModel[];
    onEdit: (user: User) => void;
    onDelete: (user: User) => void;
    canDelete: (user: User) => boolean;
}

const UsersApplicationTable = ApplicationTable<User, string>();

const UsersTable: FC<UsersTableProps> = ({ users, userGroups, onEdit, onDelete, canDelete }) => {
    const userGroupsMap = useMemo(() => {
        return userGroups.reduce((prev, curr) => {
            for (const user of curr.users ?? []) {
                const set = prev.get(user) ?? new Set();
                prev.set(user, set.add(curr.displayName));
            }

            return prev;
        }, new Map<NonNullable<UserGroupModel['id']>, Set<UserGroupModel['displayName']>>());
    }, [userGroups]);

    const columns: ColumnDef<User, string>[] = [
        {
            id: 'email',
            header: 'Email',
            cell: ({ getValue }) => {
                return (
                    <TruncateWithTooltip title={getValue()}>
                        <div className='truncate'>{getValue()}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 400,
            accessorKey: 'id'
        },
        {
            id: 'userGroups',
            header: 'User Groups',
            cell: ({ getValue }) => {
                const userGroup = userGroupsMap.get(getValue());

                if (!userGroup || userGroup.size < 1) {
                    return (
                        <div className='text-textIncomplete'>
                            <FontAwesomeIcon icon={faCircleExclamation} className='mr-2' />
                            No user groups
                        </div>
                    );
                }

                const userGroupFormatted = [...userGroup].filter(Boolean).join(', ');

                return (
                    <TruncateWithTooltip title={userGroupFormatted}>
                        <span className='truncate'>{userGroupFormatted}</span>
                    </TruncateWithTooltip>
                );
            },
            size: 400,
            accessorKey: 'id'
        },
        {
            id: 'lastLogin',
            header: 'Last Login',
            cell: ({ row }) => (row.original.lastLoginDate ? formatDistanceToNow(row.original.lastLoginDate, { addSuffix: true }) : ''),
            sortingFn: (rowA, rowB, columnId) => sortTimeAgo<User>(rowA, rowB, columnId),
            sortDescFirst: false,
            enableGlobalFilter: false,
            size: 150,
            accessorKey: 'lastLoginDate'
        }
    ];

    const actions: Action[] = [
        {
            action: onEdit,
            icon: faPencil,
            dataTestId: 'editUserButton',
            tooltip: 'Edit user'
        },
        {
            visible: canDelete,
            action: onDelete,
            icon: faTrash,
            dataTestId: 'deleteUserButton',
            tooltip: 'Delete user'
        }
    ];

    return (
        <UsersApplicationTable
            config={{
                actions,
                noDataMessage: 'There are no users configured.'
            }}
            data={users}
            columns={columns}
        />
    );
};

export default UsersTable;
