import * as Constants from '@squaredup/constants';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal from 'components/Modal';
import { Button } from 'components/button';
import Field from 'components/forms/field/Field';
import Form from 'components/forms/form/Form';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Create, SCRIPTS } from 'services/ScriptService';
import ScriptEditor from 'ui/editor/components/ScriptEditor';

interface DataStreamTileEditorCodeModalProps {
    setIsModalOpen: (isOpen: boolean) => void;
    selectMonitorScript: (id: string) => void;
}

type Condition = {
    value: string;
    label: string;
    func: string;
    template?: string;
};

type ScriptTypes = {
    tileDataJS: Condition;
    monitorConditionJS: Condition;
};

const scriptTypes: ScriptTypes = Constants.scriptTypes;

export default function DataStreamTileEditorScriptModal({
    setIsModalOpen,
    selectMonitorScript
}: DataStreamTileEditorCodeModalProps) {
    const container = useDOMElement('dialogContent');
    const queryClient = useQueryClient();
    const scriptType = scriptTypes.monitorConditionJS;

    const closeForm = () => {
        queryClient.invalidateQueries(SCRIPTS);
        setIsModalOpen(false);
    };

    const [src, setSrc] = useState(scriptType.template);

    const handleSubmit = async ({ displayName }: any) => {
        const config = { src };

        await Create(displayName, scriptType.value, config).then((id: string) => {
            selectMonitorScript(id);
            closeForm();
        });
    };

    return (
        <Modal
            title='Add monitoring script'
            container={container}
            close={() => setIsModalOpen(false)}
            maxWidth='w-full max-w-screen-lg'
        >
            <hr className='my-2 border-dividerPrimary' />
            <Form
                submit={handleSubmit}
                defaultValues={{
                    scriptType: scriptTypes.monitorConditionJS
                }}
            >
                {(isValid, isSubmitting) => (
                    <div className='px-8 py-4'>
                        <div className='mb-6'>
                            <Field.Input
                                name='displayName'
                                label='Name'
                                title='Name'
                                placeholder='Enter a name'
                                validation={{ required: true, maxLength: 128, minLength: 2 }}
                            />
                        </div>

                        <div className='mb-12'>
                            <label className='flex items-end mb-2 font-medium'>
                                <span className='flex'>Script *</span>
                            </label>
                            <div className='text-sm text-textSecondary'>
                                <div className='flex flex-col w-full min-h-[12.5rem] bg-componentBackgroundSecondary border border-outlinePrimary'>
                                    <ScriptEditor content={src} onValidUpdatedContent={setSrc} />
                                </div>
                            </div>
                        </div>

                        <div className='flex justify-end space-x-8'>
                            <Button type='button' variant='tertiary' onClick={closeForm}>
                                Cancel
                            </Button>
                            <Button type='submit' disabled={isSubmitting || !isValid}>
                                {isSubmitting ? <LoadingSpinner size={18} /> : 'Add'}
                            </Button>
                        </div>
                    </div>
                )}
            </Form>
        </Modal>
    );
}
