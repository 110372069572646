import { Point } from '@nivo/line';
import { motion } from 'framer-motion';
import { getOpacityFn } from './styles';
import { spring } from './transition';

interface LineGraphPointsProps {
    points: Point[];
    hoverId?: string | number | undefined;
}

export const LineGraphPoints: React.FC<LineGraphPointsProps> = ({ points, hoverId }) => {
    const opacity = getOpacityFn(1, 0.2);

    const mappedPoints = points
        .slice(0)
        .reverse()
        .map((point) => ({
            id: point.id,
            x: point.x,
            y: point.y,
            datum: point.data,
            fill: point.color,
            opacity: opacity(point.serieId, hoverId),
            stroke: point.borderColor
        }));

    return (
        <g>
            {mappedPoints.map((point) => (
                <motion.circle
                    key={point.id}
                    r={3}
                    animate={{
                        opacity: point.opacity,
                        cx: point.x,
                        cy: point.y
                    }}
                    transition={spring}
                    fill={point.fill}
                    stroke='none'
                    pointerEvents='none'
                />
            ))}
        </g>
    );
};
