import { PinnableNodeData } from 'components/map/data/types';
import { NodeProps } from 'reactflow';
import { KPINodeInternal } from './internals/KPINodeInternal';

export const KPI_NODE = 'KPINode';
export const KPI_NODE_SIZE = 70;

export const KPINode = ({ id, data }: NodeProps<PinnableNodeData>) => {

    return (
        <KPINodeInternal 
            id={id}
            pinned={data.pinned}
            expanded={data.expanded}
            label={data.label}
            workspaceId={data.workspaceId}
            hiddenConnections={data.hiddenConnections}
            fixedPosition={data.fixedPosition}
            kpis={data.kpis}
            kpiCount={data.kpiCount}
        />
    );
};
