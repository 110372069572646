import { FullscreenMap } from 'components/map/FullscreenMap';
import { FC } from 'react';
import { DrilldownPanel } from './DrilldownPanel';
import { NodeWithCanonical } from './common';

const DrilldownMapPanel: FC<{ node: NodeWithCanonical }> = ({ node }) => {
    return (
        <DrilldownPanel title='Map' childrenStyles='flex flex-grow basis-80'>
            {node ? 
                <>
                    <FullscreenMap 
                        expandedNodeIds={node?.id ? [node?.id] : undefined} 
                        background='tileBackground'
                    />
                </> : 
                null
            }
        </DrilldownPanel>
    );
};

export const GraphNodeMapPanel: FC<{ node: NodeWithCanonical }> = ({ node }) => {
    return <DrilldownMapPanel node={node} />;
};
