import { FeatureFlagName, getFeatureFlags } from '@squaredup/feature-flags';
import { useTenant } from 'queries/hooks/useTenant';

/**
 * Get the current value of a feature flag, defaulting to false
 */
export const useFlag = (flagName: FeatureFlagName): boolean => {
    const { data: tenantFlagValue, isLoading } = useTenant({
        select: (tenant) => getFeatureFlags(tenant.flags)?.[flagName]
    });

    if (isLoading) {
        return false;
    }

    if (tenantFlagValue == null) {
        throw new Error(
            `Unknown feature flag '${flagName}', do you need to create a flag in @squaredup/feature-flags?`
        );
    }

    return tenantFlagValue;
};
