import { Portal } from '@/components/Portal';
import { ClientDataStreamRequest, isNoData } from '@squaredup/data-streams';
import RefreshContext from 'contexts/RefreshContext';
import TileContext from 'contexts/TileContext';
import DataStreamBaseTile from 'dashboard-engine/basetiles/DataStreamBaseTile';
import useDataStream from 'dashboard-engine/hooks/useDataStream';
import { useGenerateDataStreamTileConfig } from 'dashboard-engine/hooks/useGenerateDataStreamTileConfig';
import { useRefresh } from 'lib/useRefresh';
import { SaveToDashboardModal } from 'pages/explore/SaveToDashboardModal';
import { useEffect, useState } from 'react';
import { TileHeaderActions } from 'ui/tile/TileHeaderActions';
import { DataStreamErrors } from './DataStreamErrors';
import { GlobalDataStreamContext } from './GlobalDataStreamContext';
import { matchVisualisations } from './VisualisationMatching';

export type VisualisationState = 'hasData' | 'loading' | 'hasError' | 'noData';

// Extend ClientDataStreamRequest type to include a callback
type ClientDataStreamRequestWithData = ClientDataStreamRequest & {
    title?: string;
    config?: any;
    tileId?: string;
    showDetailedErrors?: boolean;
    sourceId?: string;
    dataStreamName?: string;
    onStateChange?: (state: VisualisationState) => void;
};

function DataStreamData(request: ClientDataStreamRequestWithData) {
    const { config, title, tileId, sourceId, showDetailedErrors = true, onStateChange } = request;

    const [isCopying, setIsCopying] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const { data, isLoading, error } = useDataStream(request);

    const [bestVisualisation] = matchVisualisations(data, config);

    const hasData = !isNoData(data);

    const tileHeaderPortal = document.getElementById(`${tileId}Toolbar`);
    const { refreshCount, forceRefresh } = useRefresh();

    // Might need to be useLayoutEffect if perf problems crop up in the future
    useEffect(() => {
        if (!onStateChange) {
            return;
        }

        if (isLoading) {
            onStateChange('loading');
        } else if (error) {
            onStateChange('hasError');
        } else {
            onStateChange(hasData ? 'hasData' : 'noData');
        }
    }, [hasData, isLoading, error, onStateChange]);

    const generatedTileConfig = useGenerateDataStreamTileConfig(
        request.dataStreamId ?? '',
        request.dataStreamName,
        title,
        bestVisualisation,
        request.scope,
        request.timeframe,
        request.options,
        config,
        sourceId
    );

    return !error ? (
        <GlobalDataStreamContext>
            <TileContext.Provider
                value={{
                    config: generatedTileConfig,
                    tileId,
                    showDetailedErrors,
                    preview: false,
                    tileReference: undefined,
                    isExporting,
                    onEdit: () => undefined,
                    onClose: () => undefined,
                    onChange: () => undefined,
                    onCopy: () => setIsCopying(true),
                    setTileData: () => undefined,
                    setTileProcessedData: () => undefined,
                    setTileToolbar: () => undefined,
                    setIsExporting
                }}
            >
                <RefreshContext.Provider
                    value={{
                        name: `tile-${tileId}`,
                        refreshCount,
                        forceRefresh
                    }}
                >
                    <Portal container={tileHeaderPortal} className='order-10'>
                        <TileHeaderActions />
                    </Portal>

                    <DataStreamBaseTile
                        config={generatedTileConfig}
                        hasDynamicVisualisation={!config?.visualisation?.type}
                        key='visualisation'
                    />

                    {isCopying && (
                        <SaveToDashboardModal close={() => setIsCopying(false)} config={generatedTileConfig} />
                    )}
                </RefreshContext.Provider>
            </TileContext.Provider>
        </GlobalDataStreamContext>
    ) : (
        <DataStreamErrors error={error} showDetailedErrors={showDetailedErrors} />
    );
}

export default DataStreamData;
