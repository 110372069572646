import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import type { Node } from '@squaredup/graph';
import PluginIcon from 'pages/scope/PluginIcon';
import { useDashboards } from 'queries/hooks/useDashboards';
import { useWorkspaces } from 'queries/hooks/useWorkspaces';
import { FC, useRef } from 'react';
import type { Workspace } from 'services/WorkspaceService';
import { TruncatedText } from './TruncatedText';
import { SearchSource } from './Search';

const findWorkspaceFromConfig = (configId: string, knownWorkspaces: Workspace[]) => {
    return knownWorkspaces.find((workspace) => workspace.configId === configId);
};

type DisplayElement = {
    title: string;
    node?: Node;
    workspace?: Workspace;
    sources?: SearchSource[];
    folderPath?: string[];
};

const titleClasses = 'inline text-textPrimary';
const containerClasses = 'truncate text-textSearchSecondary gap-4 flex items-center overflow-hidden';
const containerClassesSubtitle = 'truncate text-textSearchSecondary gap-2 flex items-center overflow-hidden';

const SubtitleTruncatedText: React.FC<{ text: string }> = ({ text }) => {
    return (
        <TruncatedText
            placement='top-end'
            title={text}
            element='span'
            className='overflow-hidden'
            tooltipClassName='max-w-4xl'
        >
            <Text.SmallBody className={'text-textSearchSecondary inline truncate'}>{text}</Text.SmallBody>
        </TruncatedText>
    );
};

const NameWorkspaceDisplay: FC<DisplayElement> = ({ title, workspace, folderPath }) => (
    <div className={containerClasses}>
        <Text.Body className={titleClasses}>{title}</Text.Body>
        <SubtitleTruncatedText
            text={`${workspace?.displayName}${
                folderPath && folderPath.length > 0 ? ' / ' + folderPath?.join(' / ') : ''
            }`}
        />
    </div>
);

const SourceDisplay: FC<Pick<DisplayElement, 'sources'>> = ({ sources }) => (
    <>
        {sources?.map((source) => (
            <div className={containerClassesSubtitle}>
                <PluginIcon key={`${source.sourceConfigName}-icon`} className='inline w-6 h-6 mr-1 shrink-0' pluginName={source.sourceConfigName}></PluginIcon>
                {source.sourceInstanceName && <SubtitleTruncatedText text={source.sourceInstanceName}></SubtitleTruncatedText>}
            </div>
        ))}
    </>
);

const NameDashboardDisplay: FC<DisplayElement> = ({ title, workspace, node, folderPath }) => (
    <div className={containerClasses}>
        <Text.Body className={titleClasses}>{node?.tileName?.[0] ?? title}</Text.Body>
        <SubtitleTruncatedText
            text={`${workspace?.displayName} / ${
                folderPath && folderPath.length > 0 ? folderPath?.join(' / ') + ' / ' : ''
            }${node?.dashName?.[0]}`}
        />
    </div>
);

const NameDisplay: FC<DisplayElement> = ({ title }) => (
    <div className={containerClasses}>
        <Text.Body className={titleClasses}>{title}</Text.Body>
    </div>
);

const DefaultDisplay: FC<DisplayElement> = ({ title, sources = [] }) => (
    <div className={containerClasses}>
        <Text.Body className={titleClasses}>{title}</Text.Body>
        <SourceDisplay sources={sources} />
    </div>
);

const displayElements: Record<string, FC<DisplayElement>> = {
    'squaredup/dash': NameWorkspaceDisplay,
    'squaredup/monitor': NameDashboardDisplay,
    'squaredup/tile': NameDashboardDisplay,
    'squaredup/space': NameDisplay,
    'squaredup/scope': NameWorkspaceDisplay,
    'squaredup/kpi': NameDashboardDisplay
} as const;

interface SearchItemProps {
    node?: Node;
    folderPath?: string[];
    title: string;
    sources?: SearchSource[];
    active: boolean;
    onClick?: () => void;
    onMouseEnter?: () => void;
    resultId: string;
}

export function SearchItem({ node, title, sources = [], active, onClick, onMouseEnter, folderPath }: SearchItemProps) {
    const mouseEntered = useRef(false);
    const { data: knownWorkspaces } = useWorkspaces();
    const findWorkspaceSource = (configId: string) => findWorkspaceFromConfig(configId, knownWorkspaces ?? []);
    const knownWorkspace = findWorkspaceSource(node?.__configId?.[0] ?? '');
    const { data: knownDashboards } = useDashboards();
    if (node?.sourceType?.[0] === 'squaredup/kpi' && !node.dashName) {
        node.dashName = [knownDashboards?.find((v) => v.id === node.dashId?.[0])?.displayName ?? ''];
    }

    const Display = displayElements[node?.sourceType?.[0] ?? ''] ?? DefaultDisplay;
    return (
        <div
            className={cn(
                'flex items-center pl-4 outline-none cursor-pointer py-2.5 px-4 mx-4',
                active && 'bg-tagBackground'
            )}
            data-testid='searchResultsItem'
            onClick={() => onClick && onClick()}
            onMouseMove={(e) => {
                if (!mouseEntered.current && (e.movementX !== 0 || e.movementY !== 0)) {
                    onMouseEnter && onMouseEnter();
                    mouseEntered.current = true;
                }
            }}
            onMouseLeave={() => {
                mouseEntered.current = false;
            }}
            onMouseEnter={() => {
                // We want the second mouse enter to trigger the hover
                mouseEntered.current = false;
            }}
        >
            <div className='flex-grow flex-shrink max-w-full min-w-0'>
                <Display
                    title={title}
                    node={node}
                    sources={sources}
                    workspace={knownWorkspace}
                    folderPath={folderPath}
                />
            </div>
        </div>
    );
}
