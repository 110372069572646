import { Serialised } from '@squaredup/ids';
import Button from 'components/button/Button';
import { AgentGroupOptions } from 'components/hooks/useAgentGroups';
import type { Config } from 'dynamo-wrapper';
import PluginConfigModal from 'pages/settings/plugins/PluginConfigModal';
import { FC, useEffect, useState } from 'react';
import { useOAuthRedirectParam } from './useOAuthRedirectParam';

const pluginDetails = (config: Serialised<Config<object>> | undefined) => ({
    source: 'PLUGIN',
    id: (config?.plugin as any)?.pluginId,
    displayName: config?.displayName!
});

interface ManageDatasourceButtonProps {
    canWriteToWorkspace: boolean | undefined;
    data: Serialised<Config<object>> | undefined;
    agentGroupOptions: AgentGroupOptions;
    workspaceID: string;
}

export const ManageDatasourceButton: FC<ManageDatasourceButtonProps> = ({
    canWriteToWorkspace,
    data,
    agentGroupOptions,
    workspaceID
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [currentConfig, setCurrentConfig] = useState<Serialised<Config<object>> | undefined>(undefined);
    const { pluginInstanceBeingEdited, hasOAuthRehydration, clearSearch } = useOAuthRedirectParam();

    useEffect(() => {
        if (hasOAuthRehydration && pluginInstanceBeingEdited) {
            setCurrentConfig(pluginInstanceBeingEdited);
            setModalOpen(true);
        }
    }, [hasOAuthRehydration, pluginInstanceBeingEdited]);

    const config = data as any;

    return (
        <>
            {canWriteToWorkspace && data && (
                <Button variant='secondary' className='whitespace-nowrap' onClick={() => setModalOpen(true)}>
                    Manage
                </Button>
            )}
            {modalOpen && (
                <PluginConfigModal
                    selectedPlugin={pluginDetails(currentConfig ?? data) as any}
                    config={currentConfig ?? config}
                    agentGroups={agentGroupOptions}
                    close={(reason) => {
                        clearSearch();
                        setModalOpen(false);
                        if (reason === 'submit') {
                            window.location.reload(); // Refresh to prevent phantom form values
                        }
                    }}
                    workspaceToLinkTo={workspaceID}
                />
            )}
        </>
    );
};
