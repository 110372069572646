import { Serialised } from '@squaredup/ids';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';
import type { ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import { useMutation, useQueryClient } from 'react-query';
import { CUSTOM_DATA_STREAM_DEFINITIONS, Delete } from '../../../services/DataStreamDefinitionService';

type DataStreamDefinitionDeleteModalProps = {
    stream: Serialised<ProjectedDataStreamDefinitionEntity>;
    onClose: () => void;
};

export const DataStreamDefinitionDeleteModal: React.FC<DataStreamDefinitionDeleteModalProps> = ({
    stream,
    onClose
}) => {
    const queryClient = useQueryClient();

    const { mutate: handleDelete, isLoading } = useMutation(
        async () => {
            return Delete(stream.id);
        },
        {
            onSettled: async () => {
                await queryClient.invalidateQueries([CUSTOM_DATA_STREAM_DEFINITIONS]);
            },
            onSuccess: async () => {
                onClose();
            }
        }
    );

    const description = 'Are you sure you want to permanently delete this custom data stream?';

    return (
        <Modal
            title={`Delete Custom Data Stream: ${stream.displayName}`}
            description={description}
            close={onClose}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <ModalButtons>
                <Button type='button' variant='tertiary' onClick={() => onClose()}>
                    Cancel
                </Button>

                <Button
                    variant='destructive'
                    type='button'
                    data-testid='confirmDeleteStream'
                    onClick={() => handleDelete()}
                    disabled={isLoading}
                >
                    {isLoading ? <LoadingSpinner /> : 'Delete'}
                </Button>
            </ModalButtons>
        </Modal>
    );
};
