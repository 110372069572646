import { useDatasource } from 'queries/hooks/useDatasource';
import { extractPluginLink } from '../utils/extractPluginLink';

export const useDatasourceMarketingLink = (datasourceId?: string) => {  
    return useDatasource(
        datasourceId, 
        { 
            enabled: Boolean(datasourceId),
            select: (data) => extractPluginLink(data.externalLinks ?? [], 'marketing', true) 
        }
    );
};