interface ScopeNode {
    data: {
        bindings?: string;
        queryDetail?: string;
    };
}

/**
 * Utility function to return the stringified query detail from a scope node (as an object). We store scopes in
 * the graph database with their queryDetail as a JSON string. We therefore need to parse the JSON string
 * in order to use it effectively.
 * @param scope scope node from the graph data base
 * @returns the scope nodes query detail as an object or an empty object if queryDetail isn't present
 */

export const getParsedScopeQueryDetail = (scope: ScopeNode) => {
    const queryDetail = scope.data?.queryDetail;

    if (queryDetail) {
        return typeof queryDetail === 'string' ? JSON.parse(queryDetail) : queryDetail;
    }

    return {};
};

export const getParsedScopeBindings = (scope: ScopeNode) => {
    const bindings = scope.data?.bindings;

    if (bindings) {
        return typeof bindings === 'string' ? JSON.parse(bindings) : bindings;
    }

    return {};
};
