import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeatureKey, getFeatureDisplay, Tier } from '@squaredup/tenants';
import Modal, { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';
import { useDOMElement } from 'components/hooks/useDOMElement';
import Config from 'config';
import { tenantQueryKeys } from 'queries/queryKeys/tenantKeys';
import { ComponentPropsWithoutRef, MutableRefObject, ReactChild, useReducer, useRef } from 'react';
import { useQueryClient } from 'react-query';
import Select from 'react-select';
import Auth from 'services/Auth';
import { ProjectedTenantResponse, SetUpgradePending } from 'services/TenantService';
import { useContactSalesModal } from './ContactSalesModal';
import { usePaddleCheckout } from './Paddle';
import { ENTERPRISE_FAKE_ID, planOptions, planRanges, STARTER_MONTHLY_PADDLE_ID } from './Plans';

type FormFieldProps = {
    title: string;
    children: ReactChild;
    isDisabled?: boolean;
};

const FormField = ({ title, children, isDisabled = false }: FormFieldProps) => (
    <div className='mt-8 first:mt-0'>
        <Text.H4 className={cn('flex items-end mb-2', isDisabled && 'text-textDisabled')}>{title}</Text.H4>
        {children}
    </div>
);

type PlanUpgradePanelProps = {
    onClose: () => void;
    onSubmit: (plan: string, users: number) => void;
    featureKey?: FeatureKey;
    organisation?: string;
    tier?: Tier;
    tenantId?: string;
};

type PlanUpgradeModalProps = {
    onClose: () => void;
    onPurchase?: () => void;
    tenant: ProjectedTenantResponse;
    featureKey?: FeatureKey;
};

type ReducerState = {
    userCount: number;
    plan: string;
};

type ReducerAction = { type: 'setUserCount'; value: number } | { type: 'setPlan'; value: string };

const checkUsersForPlan = (users: number, plan: string): number => {
    const { min, max } = planRanges[plan];
    return Math.max(Math.min(max, Math.floor(users ?? 0)), min);
};

type DescriptorBlockProps = {
    title: string;
    cost: number;
    items: string[];
    includesPlan: string;
};

const DescriptorBlock = ({ title, cost, items, includesPlan }: DescriptorBlockProps) => (
    <div className='flex flex-col flex-1 border rounded-md border-outlinePrimary'>
        <PlanTitle title={title} />
        <PlanDetails cost={cost} items={items} includesPlan={includesPlan} />
    </div>
);

const PlanTitle = ({ title, ...props }: ComponentPropsWithoutRef<'div'> & { title: string }) => (
    <div className='px-4 py-3 border-b border-outlinePrimary' {...props}>
        <Text.H2>{title}</Text.H2>
    </div>
);

const PlanPrice = ({ cost, ...props }: ComponentPropsWithoutRef<'div'> & { cost: number }) => (
    <div {...props}>
        <span className='font-semibold text-[22px]'>${cost}</span>
        <span className='ml-2 text-[14px]'>user / month</span>
    </div>
);

const PlanDetails = ({
    cost,
    includesPlan,
    items,
    ...props
}: ComponentPropsWithoutRef<'div'> & { cost: number; includesPlan: string; items: string[] }) => (
    <div className='px-4 py-5 space-y-5' {...props}>
        <PlanPrice cost={cost} />
        <hr className='border-outlinePrimary' />
        <div className='text-textTable leading-[16px]'>
            <Text.Body>Everything from {includesPlan} plus:</Text.Body>
            <ul className='mt-3 space-y-4'>
                {items.map((item) => (
                    <li key={item}>
                        <Text.Body>
                            <FontAwesomeIcon icon={faCheck} className='mr-3' />
                            {item}
                        </Text.Body>
                    </li>
                ))}
            </ul>
        </div>
    </div>
);

const PlanImage = () => (
    <div className='flex flex-grow h-full gap-5'>
        <DescriptorBlock
            title='Starter'
            cost={9}
            includesPlan='Free'
            items={['Up to 10 data sources', 'Up to 30 monitors', 'Online support']}
        />
        <DescriptorBlock
            title='Pro'
            cost={35}
            includesPlan='Starter'
            items={[
                'Unlimited data sources',
                'Unlimited monitors',
                'Unlimited sharing',
                'Access control',
                '200,000 queries/month'
            ]}
        />
        <DescriptorBlock
            title='Enterprise'
            cost={78}
            includesPlan='Pro'
            items={[
                'On-prem data sources',
                'Full API access',
                'Volume discounts',
                'Dedicated support',
                '1,000,000 queries/month'
            ]}
        />
    </div>
);

export const PlanUpgradePanel = ({
    onClose: close,
    onSubmit: submit,
    featureKey,
    organisation,
    tenantId,
    tier
}: PlanUpgradePanelProps) => {
    let userCountRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

    const updateRef = (value: number) => {
        if (userCountRef.current) {
            userCountRef.current.value = value.toString();
        }
    };

    const reducer = (state: ReducerState, action: ReducerAction) => {
        switch (action.type) {
            case 'setUserCount': {
                const actionCount = isNaN(action.value) ? 0 : action.value;
                const userCount = checkUsersForPlan(actionCount, state.plan);
                return {
                    ...state,
                    userCount
                };
            }
            case 'setPlan': {
                const userCount = planRanges[action.value].min;
                updateRef(userCount);
                return {
                    userCount,
                    plan: action.value
                };
            }
            default: {
                throw new Error(`Unknown action: '${(action as any)?.type}'`);
            }
        }
    };

    let [state, dispatch] = useReducer(reducer, {
        plan: STARTER_MONTHLY_PADDLE_ID,
        userCount: planRanges[STARTER_MONTHLY_PADDLE_ID].min
    });

    const feature = featureKey ? getFeatureDisplay(featureKey) : undefined;
    const { contactSalesModal, openContactSalesModal } = useContactSalesModal(
        feature?.displayName ?? 'Plan and Usage',
        tenantId,
        organisation ?? ''
    );

    const isOnExistingPlan = tier?.expiry !== undefined;
    const isUpgradeBlocked = state.plan === ENTERPRISE_FAKE_ID || isOnExistingPlan;

    return (
        <>
            <hr className='border-dividerPrimary' />
            <div className='flex px-8 pt-8 gap-14'>
                <div>
                    <Text.Body>
                        For full details about our plans,{' '}
                        <Button
                            type='button'
                            variant='link'
                            href='https://www.squaredup.com/pricing/'
                            data-testid='upgrade-pricing-link'
                        >
                            click here
                        </Button>
                    </Text.Body>
                    <FormField title='Plan' isDisabled={isOnExistingPlan}>
                        <div className='ring-inset ring-1 ring-outlinePrimary focus-within:ring-outlineSecondary rounded-input bg-componentBackgroundPrimary'>
                            <Select
                                name='plan'
                                isDisabled={isOnExistingPlan}
                                classNamePrefix='autocomplete'
                                onChange={(s) => dispatch({ type: 'setPlan', value: s?.value ?? '' })}
                                options={planOptions}
                                defaultValue={planOptions[0]}
                            />
                        </div>
                    </FormField>

                    <div className={cn('mt-8', isUpgradeBlocked && 'hidden')} data-testid='user-container'>
                        <FormField title='Number of users'>
                            <input
                                className='border-none ring-inset ring-1 ring-outlinePrimary text-textPrimary rounded-input leading-input focus:ring-0 focus:outline-none focus-visible:ring-inset focus-visible:ring-1 py-input px-md bg-componentBackgroundPrimary focus:ring-outlineSecondary hover:ring-outlineSecondary'
                                type='number'
                                name='userCount'
                                ref={userCountRef}
                                min={planRanges[state.plan]?.min.toString() ?? 0}
                                max={planRanges[state.plan]?.max.toString() ?? 0}
                                step={1}
                                onBlur={() => {
                                    updateRef(state.userCount ?? checkUsersForPlan(0, state.plan));
                                }}
                                onChange={(e) =>
                                    dispatch({
                                        type: 'setUserCount',
                                        value: e.target.valueAsNumber
                                    })
                                }
                                defaultValue={planRanges[STARTER_MONTHLY_PADDLE_ID].min}
                            />
                        </FormField>

                        <FormField title='Price'>
                            <Text.Body className='text-textSecondary'>Price will be calculated at checkout.</Text.Body>
                        </FormField>
                    </div>
                </div>
                <PlanImage />
            </div>

            <ModalButtons>
                <Button type='button' variant='tertiary' onClick={() => close()} data-testid='upgrade-modal-cancel'>
                    Cancel
                </Button>
                {isUpgradeBlocked ? (
                    <>
                        <Button
                            type='button'
                            variant='upgrade'
                            data-testid='upgrade-modal-contact'
                            onClick={openContactSalesModal}
                        >
                            Contact sales
                        </Button>
                        {contactSalesModal}
                    </>
                ) : (
                    <Button
                        type='button'
                        variant='upgrade'
                        onClick={() => submit(state.plan, state.userCount)}
                        data-testid='upgrade-modal-purchase'
                    >
                        Upgrade
                    </Button>
                )}
            </ModalButtons>
        </>
    );
};

export const PlanUpgradeModal = ({ onClose: close, onPurchase, tenant, featureKey }: PlanUpgradeModalProps) => {
    const queryClient = useQueryClient();
    const { paddle, handlers: paddleHandlers } = usePaddleCheckout();

    const container = useDOMElement('dialogContent');
    const onSuccessfulPurchase = () => {
        // Set update pending on local copy (in-case websocket notification doesn't update)
        queryClient.setQueryData(tenantQueryKeys.tenant, {
            ...tenant,
            licenceData: {
                ...tenant.licenceData,
                upgradePending: true
            }
        });

        // Update server
        SetUpgradePending();

        if (onPurchase && typeof onPurchase === 'function') {
            onPurchase();
        }
    };

    paddleHandlers.set('checkout.completed', onSuccessfulPurchase);
    const submitUpgradeRequest = (planId: string, users: number) => {
        paddle?.Checkout.open({
            items: [{ priceId: planId, quantity: users }],
            customer: { email: Auth.user?.name || '' },
            customData: { tenantId: tenant.id, environment: Config.Environment },
            settings: {
                theme: 'light'
            }
        });
    };

    return (
        <Modal title='Compare plans' close={close} container={container} fullWidth maxWidth='max-w-[1372px]'>
            <PlanUpgradePanel
                onClose={close}
                onSubmit={submitUpgradeRequest}
                organisation={tenant.displayName}
                tier={tenant.tier}
                featureKey={featureKey}
                tenantId={tenant.id}
            />
        </Modal>
    );
};
