import { z } from 'zod';

const objectVariableSchema = z.object({
    name: z.string().nonempty().trim().min(1),
    type: z.literal('object'),
    scope: z.string(),
    default: z.literal('all').or(z.literal('none')),
    allowMultipleSelection: z.boolean()
});

const textVariableSchema = z.object({
    name: z.string().nonempty().trim().min(1),
    type: z.literal('text'),
    options: z.array(z.string()),
    default: z.array(z.string())
});

export const variableFormSchema = objectVariableSchema;
export type VariableFormObjectData = z.infer<typeof objectVariableSchema>;
export type VariableFormData = z.infer<typeof variableFormSchema>;
