export const mapBackgroundColors = {
    tileBackground: 'bg-tileBackground',
    backgroundSecondary: 'bg-backgroundSecondary'
} as const;

export type MapBackgroundColor = keyof typeof mapBackgroundColors;

export const mapBorderColors: Record<MapBackgroundColor, string> = {
    tileBackground: 'border-tileBackground',
    backgroundSecondary: 'border-backgroundSecondary'
} as const;

export const mapFillColors: Record<MapBackgroundColor, string> = {
    tileBackground: 'fill-tileBackground',
    backgroundSecondary: 'fill-backgroundSecondary'
} as const;
