import { Toggle } from '@/components/forms/Toggle';
import { cn } from '@/lib/cn';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/tooltip/Tooltip';
import { Controller, useFormContext } from 'react-hook-form';

export interface FormToggleProps {
    name: string;
    label: string;
    description?: string;
    disabled?: boolean;
    help?: string;
    icon?: IconProp;
    title?: string;
    wrapperClassName?: string;
    testId?: string;
    onCheckedChange?: (checked: boolean) => void;
}

export const FieldToggle = ({
    name,
    label,
    description,
    disabled,
    help,
    icon,
    testId,
    title,
    onCheckedChange
}: FormToggleProps) => {
    const { control } = useFormContext();
    
    return (
        <div className='flex flex-col w-full mt-6 first:mt-0'>
            <label
                className={cn(
                    'relative flex items-center mt-6 leading-input rounded-input py-toggleWrapper px-md first:mt-0 bg-componentToggle',
                    !disabled && 'cursor-pointer'
                )}
                htmlFor={name}
                title={title}
            >
                <div className='flex items-center'>
                    {icon && (
                        <div className={cn('mr-2', disabled && 'opacity-50')}>
                            <FontAwesomeIcon fixedWidth className='' icon={icon} />
                        </div>
                    )}

                    <div className='mr-8'>
                        <div className='flex items-center select-none text-textPrimary'>
                            <span className={cn(disabled && 'opacity-50')}>{label}</span>
                            
                            {help && (
                                <span className='px-2 cursor-pointer text-textSecondary'>
                                    <Tooltip title={help}>
                                        <FontAwesomeIcon icon={faCircleQuestion} />
                                    </Tooltip>
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className={cn('ml-auto inline-flex items-center shrink-0', disabled && 'opacity-50')}>
                    <Controller
                        control={control}
                        name={name}
                        render={({ field: { onChange, value } }) => (
                            <Toggle
                                checked={value}
                                onCheckedChange={() => {
                                    onChange(!value);
                                    onCheckedChange?.(!value);
                                }}
                                disabled={disabled}
                                name={name}
                                data-testid={testId}
                            />
                        )}
                    />
                </div>
            </label>
            {description && <div className='mt-1 text-sm text-textSecondary pl-md'>{description}</div>}
        </div>
    );
};
