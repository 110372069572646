import { createContext, useContext } from 'react';
import { type TileEditorStepsValue } from '../TileEditor/hooks/useTileEditorSteps';

const TileEditorStepsContext = createContext<TileEditorStepsValue>({
    currentEditorStep: '',
    editorSteps: [],
    canMoveToNextStep: false,
    setCurrentEditorStep: () => undefined,
    nextStep: () => undefined
});

TileEditorStepsContext.displayName = 'TileEditorStepsContext';

export const useTileEditorStepsContext = () => useContext(TileEditorStepsContext);

export default TileEditorStepsContext;
