import React from 'react';

export const useHash = (): [string, (s: string) => void] => {
    const [hash, setHash] = React.useState<string>(() => window.location.hash);

    const hashChangeHandler = React.useCallback(() => {
        setHash(window.location.hash);
    }, []);

    React.useEffect(() => {
        window.addEventListener('hashchange', hashChangeHandler);
        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
    }, [hashChangeHandler]);

    const updateHash = React.useCallback(
        (newHash) => {
            if (newHash !== hash) {
                window.location.hash = newHash;
            }
        },
        [hash]
    );

    return [hash, updateHash];
};
