import DropdownMenu from '@/components/DropdownMenu';
import { faClone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTileContext } from 'contexts/TileContext';

export const Clone: React.FC = () => {
    const { onClone } = useTileContext();

    return (
        <DropdownMenu.Item
            icon={<FontAwesomeIcon icon={faClone} fixedWidth />}
            onSelect={() => onClone?.()}
            key='clone'
        >
            Clone
        </DropdownMenu.Item>
    );
};
