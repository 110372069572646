import { DashboardContextValue } from 'contexts/DashboardContext';
import { DisplayImage } from 'dashboard-engine/visualisations/Image/Image';
import { useFormContext } from 'react-hook-form';

interface ImagePreviewProps {
    src: string;
    context: DashboardContextValue;
    tileId: string;
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({ src, context, tileId }) => {
    const { getValues, watch } = useFormContext();

    const fields = watch();
    const showHealthState = watch('showHealthState', getValues('showHealthState'));
    const healthStateMode = watch('healthStateMode', getValues('healthStateMode'));

    return (
        <DisplayImage
            config={{
                src,
                title: fields.title,
                showHealthState,
                healthStateMode,
                healthStateIconPosition: fields.healthStateIconPosition,
                healthStateIconSize: fields.healthStateIconSize
            }}
            context={context}
            tileId={tileId}
            isPreview={true}
        />
    );
};
