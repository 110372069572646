import { NavLink } from 'react-router-dom';
import { getLinkInfo } from 'lib/getLinkInfo';
import React from 'react';

interface LinkWrapperProps {
    link?: string;
    className?: string;
}

/**
 * Handles internal and external links, using an <a> or <NavLink> as appropriate
 * Open Access URLs will use an external link automatically
 * Unstyled by default but supports a className property
 */
export const LinkWrapper: React.FC<LinkWrapperProps> = ({ link, className, children }) => {
    const linkInfo = getLinkInfo(link || '');

    if (link && (linkInfo.isExternal || linkInfo.isOpenAccessUrl)) {
        return (
            <a 
                href={link} 
                target='_blank' rel='noopener noreferrer' 
                className={className}
            >
                {children}
            </a>
        );
    }

    if (link && !linkInfo.isExternal) {
        return (
            <NavLink to={linkInfo.relativeURL} className={className}>
                {children}
            </NavLink>
        );
    }

    return <>{children}</>;
};
