import { isPresetOf } from '@squaredup/data-streams';
import type { DataStream } from 'services/DataStreamDefinitionService';

export type TileEditorState = (
    | {
          selectedDataStream: undefined;
      }
    | {
          selectedDataStream: TileEditorDataStream;
      }
) & {
    dataStreams: TileEditorDataStream[];
    /**
     * A function that performs any side-effects required as a result of an action.
     * This property can only be set in the reducer function and should not be executed
     * outside of `TileEditorStateProvider`.
     */
    sideEffects?: () => void;
};

/**
 * A data stream which has been extended for use in the tile editor state reducer.
 */
export type TileEditorDataStream = DataStream &
    (
        | {
              /**
               * Is this data stream a preset of a configurable data stream?
               */
              isPreset: true;
              /**
               * The configurable data stream that this data stream is a preset of.
               */
              presetParentDataStream: DataStream;
              /**
               * The display name of this data stream, prepended with
               * the name of the data stream this stream is a preset of (if any).
               */
              displayNameFull: string;
          }
        | {
              /**
               * Is this data stream a preset of a configurable data stream?
               */
              isPreset: false;
              presets: DataStream[];
              /**
               * The display name of this data stream.
               */
              displayNameFull: string;
          }
    );

export const toTileEditorDataStream = (
    ds: DataStream,
    _index: number,
    dataStreams: DataStream[]
): TileEditorDataStream => {
    const presetParentDataStream =
        ds.definition.presetOf == null ? undefined : dataStreams.find((parentDs) => isPresetOf(parentDs, ds));

    if (presetParentDataStream == null) {
        const presets =
            ds.template == null || ds.definition.presetOf != null
                ? []
                : dataStreams.filter((preset) => isPresetOf(ds, preset));

        return {
            ...ds,
            isPreset: false,
            presets,
            displayNameFull: ds.displayName
        };
    }

    return {
        ...ds,
        isPreset: true,
        presetParentDataStream,
        displayNameFull: `${presetParentDataStream.displayName} / ${ds.displayName}`
    };
};
