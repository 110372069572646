import { createContext, useContext } from 'react';

export interface RefreshContextValue {
    name: string;
    refreshCount: number;
    /**
     * Trigger a refresh of all useDataStream hooks within this context,
     * bypassing and updating the backend cache.
     */
    forceRefresh: () => void;
}

/**
 * Allows useDataStream hooks within the context to be forcibly
 * refreshed, bypassing and updating the backend cache.
 */
const RefreshContext = createContext<RefreshContextValue>({
    name: 'null',
    refreshCount: 0,
    forceRefresh: () => undefined
});

RefreshContext.displayName = 'RefreshContext';

export const useRefreshContext = () => useContext(RefreshContext);

export default RefreshContext;
