import { cn } from '@/lib/cn';
import { cva, VariantProps } from 'class-variance-authority';
import React from 'react';

export const upgradeMessageContainerVariants = cva('flex-grow px-4 bg-tagBackground rounded-r border-r border-y border-secondaryButtonOutline', {
    variants: {
        variant: {
            short: 'py-2',
            normal: 'py-4'
        }
    },
    defaultVariants: {
        variant: 'normal'
    }
});

type UpgradeMessageContainerProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof upgradeMessageContainerVariants>;

export const UpgradeMessageContainer = ({ className, children, variant, ...props }: UpgradeMessageContainerProps) => {
    return (
        <div
            className={cn(
                'flex before:block before:w-[20px] before:bg-upgrade before:rounded-l before:shrink-0',
                className
            )}
            {...props}
        >
            <div className={upgradeMessageContainerVariants({ variant })}>{children}</div>
        </div>
    );
};
