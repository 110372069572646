import { ADMINISTRATION, LINKING, LIST, COUNT } from './common';

const DATASOURCE_CONFIGS = 'datasourceConfigs';

export const datasourceConfigQueryKeys = {
    all: [DATASOURCE_CONFIGS],
    count: [DATASOURCE_CONFIGS, COUNT],
    list: [DATASOURCE_CONFIGS, LIST],
    forAdministration: [DATASOURCE_CONFIGS, ADMINISTRATION],
    forLinking: [DATASOURCE_CONFIGS, LINKING]
};
