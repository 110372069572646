import type { FoundColumn, StreamDataColumn } from '@squaredup/data-streams';
import { Result } from '@squaredup/utilities';
import Field from 'components/forms/field/Field';
import { VisualisationOptionAction } from 'dashboard-engine/types/Visualisation';
import { OptionDropdown } from './OptionDropdown';

interface ColumnDropdownProps {
    label: string;
    name: string;
    help?: string;
    showNoneOption?: boolean;
    columns: StreamDataColumn[];
    defaultColumn?: Result<FoundColumn>;
    config: any;
    disabled?: boolean;
    onChange(action: VisualisationOptionAction): void;
}

const getAutoOption = (name: string) => ({
    label: `Auto - ${name}`,
    value: 'auto'
});
const noneOption = {
    label: 'None',
    value: 'none'
};

const getAutoColumn = (defaultColumn: ColumnDropdownProps['defaultColumn']) => {
    if (defaultColumn?.succeeded) {
        return getAutoOption(defaultColumn.value.column.displayName);
    } else {
        return noneOption;
    }
};

export const ColumnDropdown: React.FC<ColumnDropdownProps> = ({
    label,
    name,
    help,
    showNoneOption,
    columns,
    defaultColumn,
    config,
    disabled,
    onChange
}) => {
    const otherColumns = defaultColumn?.succeeded
        ? columns.filter((c) => c.name !== defaultColumn.value.column.name)
        : columns;
    const optionGroups = [
        {
            label: 'Built-in',
            options: [
                ...(defaultColumn?.succeeded ? [getAutoOption(defaultColumn.value.column.displayName)] : []),
                ...(showNoneOption ? [noneOption] : [])
            ]
        },
        {
            label: 'Columns',
            options: otherColumns.map((column) => ({
                label: column.displayName,
                value: column.name
            }))
        }
    ];

    const defaultValue =
        optionGroups.flatMap((group) => group.options).find((option) => option.value === config[name]) ??
        getAutoColumn(defaultColumn);

    return (
        <Field label={label} htmlFor={name} help={help}>
            <OptionDropdown
                name={name}
                options={optionGroups}
                defaultValue={defaultValue}
                onChange={onChange}
                isDisabled={disabled}
            />
        </Field>
    );
};
