import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import type { FC } from 'react';

/**
 * A chevron icon which can change direction and animates between states.
 */
export const Chevron: FC<{
    initialDirection?: 'up' | 'down';
    direction: 'up' | 'down';
    className?: string;
    children?: never;
}> = ({ direction, initialDirection = 'down', className }) => {
    return (
        <motion.div
            variants={{
                up: { transform: 'rotate(180deg)' },
                down: { transform: 'rotate(0deg)' }
            }}
            transition={{ duration: 0.3 }}
            initial={initialDirection}
            animate={direction}
            className={className}
        >
            <FontAwesomeIcon icon='chevron-down' />
        </motion.div>
    );
};
