import Field from 'components/forms/field/Field';
import type { ShapeConfigFieldsComponent } from './ShapeConfigFields';

export const UrlShapeConfigFields: ShapeConfigFieldsComponent = ({ name, children }) => {
    return (
        <>
            <Field.Input
                label='Link Text'
                name={`${name}.label`}
                type='text'
                help={
                    <>
                        <p>Controls the text of the link to the URLs in this column.</p>
                        Use <pre className='font-mono whitespace-normal'>{'{{ column.columnName }}'}</pre> or{' '}
                        <pre className='font-mono whitespace-normal'>
                            {"{{ column['column Name With Spaces or dots'] }}"}
                        </pre>{' '}
                        to include values from other columns.
                    </>
                }
                helpAlign='Right'
            />
            {children}
        </>
    );
};
