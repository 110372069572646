import {
    FormattedStreamValue,
    FoundColumn,
    StreamDataColumn,
    date,
    findColumn,
    findColumns,
    number,
    preferred,
    required,
    state,
    string
} from '@squaredup/data-streams';
import { Result } from '@squaredup/utilities';
import { dataMatchCriteria } from 'dashboard-engine/dataStreams/dataMatchCriteria';
import { match } from 'ts-pattern';
import { DataStreamLineGraphConfig } from './Config';

export const findLabelColumns = (labelColumnsResult: Result<FoundColumn[]>): FoundColumn[] =>
    match(labelColumnsResult)
        .with({ type: 'succeeded' }, ({ value }) => {
            if (value.every((v) => v.column.shapeName === date.name)) {
                // label columns which are dates are ignored
                return [];
            }

            return value;
        })
        .with({ type: 'failed' }, () => {
            // if we can't find a label column we use the column display names as labels
            return [];
        })
        .exhaustive();

export const findLineGraphUnitLabelColumn = (columns: StreamDataColumn[]) =>
    findColumn(columns, required('valueShapeName', string.name), required('role', 'unitLabel'));

export const findLineGraphLabelColumns = (columns: StreamDataColumn[]) =>
    findColumns(
        columns,
        preferred('role', 'label'),
        preferred('valueShapeName', string.name),
        required.not('role', 'value'),
        required.not('valueShapeName', number.name),
        required.not('shapeName', state.name),
        required.not('shapeName', date.name)
    );

export const findLineGraphLabelColumn = (columns: StreamDataColumn[]) =>
    findColumn(
        columns,
        preferred('role', 'label'),
        preferred('valueShapeName', string.name),
        required.not('role', 'value'),
        required.not('valueShapeName', number.name),
        required.not('shapeName', state.name),
        required.not('shapeName', date.name)
    );

export const findLineGraphValueColumns = (
    columns: StreamDataColumn[],
    yAxisColumnConfig: string | string[] | undefined
) => {
    const configuredValueColumns = Array.isArray(yAxisColumnConfig)
        ? findColumns(
              columns,
              required.custom('name is specified in config', (c) => yAxisColumnConfig.includes(c.name))
          )
        : findColumns(columns, required('name', yAxisColumnConfig ?? ''));

    if (configuredValueColumns.succeeded) {
        return configuredValueColumns;
    }

    const autoSelectedValueColumns = findColumns(
        columns,
        required('valueShapeName', number.name),
        preferred('role', 'value')
    );

    return autoSelectedValueColumns;
};

export const getLinegraphColumns = (
    columns: StreamDataColumn[],
    rows: FormattedStreamValue[][],
    config?: DataStreamLineGraphConfig
) => {
    const lineGraphCriteria = dataMatchCriteria<{
        timestampColumn: FoundColumn;
        valueColumns: FoundColumn[];
        labelColumnsResult: Result<FoundColumn[]>;
        unitLabelColumnResult: Result<FoundColumn>;
    }>();

    if (rows.length === 0) {
        lineGraphCriteria.fail('No rows');
    }

    let timestampColumn = findColumn(columns, required('name', config?.xAxisColumn ?? ''));
    if (timestampColumn.failed) {
        timestampColumn = findColumn(columns, required('shapeName', date.name), preferred('role', 'timestamp'));
    }

    if (timestampColumn.failed) {
        lineGraphCriteria.fail('Missing X-Axis column', timestampColumn.reason);
    } else {
        lineGraphCriteria.pass('Automatically selected X-Axis', {
            timestampColumn: timestampColumn.value
        });
    }

    const valueColumns = findLineGraphValueColumns(columns, config?.yAxisColumn);

    if (valueColumns.failed) {
        lineGraphCriteria.fail('Missing Y-Axis Column', valueColumns.reason);
    } else {
        lineGraphCriteria.pass('Automatically selected Y-Axis', {
            valueColumns: valueColumns.value
        });
    }

    if (config?.seriesColumn !== 'none') {
        let labelColumns = findColumns(columns, required('name', config?.seriesColumn ?? ''));
        if (labelColumns.failed || labelColumns.value.length === 0) {
            labelColumns = findColumns(
                columns,
                preferred('role', 'label'),
                preferred('valueShapeName', string.name),
                preferred.not('role', 'value'),
                preferred.not('valueShapeName', number.name),
                preferred.not('shapeName', state.name),
                preferred.not('shapeName', date.name)
            );
        }

        lineGraphCriteria.pass('Automatically selected Series', {
            labelColumnsResult: labelColumns.map((cols) => {
                if (cols.some((c) => c.column.role === 'label')) {
                    return cols.filter((c) => c.column.role === 'label');
                }

                // UI is likely to set to Auto (or column no longer exists) so default to first column
                return [cols[0]];
            })
        });
    } else {
        // UI is set to 'none' so we don't return any series here
        // line chart will label using the Y-Axis column name, e.g Duration
        lineGraphCriteria.pass('No Series selected', {
            labelColumnsResult: Result.success([])
        });
    }

    let unitLabelColumn = findColumn(columns, required('name', config?.unitLabelColumn ?? ''));
    if (unitLabelColumn.failed) {
        unitLabelColumn = findLineGraphUnitLabelColumn(columns);
    }

    lineGraphCriteria.pass('Automatically selected Unit', {
        unitLabelColumnResult: unitLabelColumn
    });

    return lineGraphCriteria;
};
