import OpenAccessNotAvailable from './OpenAccessNotAvailable';
import { Route, Routes } from 'react-router-dom';
import { OpenAccessDashboard } from '../pages/openAccess/OpenAccessDashboard';
import { OpenAccessTile } from 'pages/openAccess/OpenAccessTile';

// Sub-routes within /openaccess/
export default function OpenAccessAppRoutes() {
    return (
        <Routes>
            <Route path='/openaccess/:id/tile/:tileId' element={<OpenAccessTile />} />
            <Route path='/openaccess/:id' element={<OpenAccessDashboard />} />            

            {/*
              Anything else and we'll assume the user has performed a drill-down,
              so show an appropriate error page. The OpenAccessDashboard component above has it's own
              error screen/s for any failure to load or invalid IDs.
            */}
            <Route path='*' element={<OpenAccessNotAvailable />} />
        </Routes>
    );
}
