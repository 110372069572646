import { VisualisationOption } from 'dashboard-engine/types/Visualisation';
import { DataStreamBarChartOptions } from 'dashboard-engine/visualisations/DataStreamBarChart/DataStreamBarChartOptions';
import DataStreamDonutOptions from 'dashboard-engine/visualisations/DataStreamDonut/DataStreamDonutOptions';
import DataStreamGaugeChart from 'dashboard-engine/visualisations/DataStreamGaugeChart/DataStreamGaugeChart';
import { DataStreamGaugeChartOptions } from 'dashboard-engine/visualisations/DataStreamGaugeChart/DataStreamGaugeChartOptions';
import DataStreamIframeOptions from 'dashboard-engine/visualisations/DataStreamIframe/DataStreamIframeOptions';
import Image from 'dashboard-engine/visualisations/Image/Image';
import React from 'react';
import { DataStreamBarChart } from '../visualisations/DataStreamBarChart/DataStreamBarChart';
import DataStreamBlocks from '../visualisations/DataStreamBlocks/DataStreamBlocks';
import DataStreamBlocksOptions from '../visualisations/DataStreamBlocks/DataStreamBlocksOptions';
import DataStreamDonut from '../visualisations/DataStreamDonut/DataStreamDonut';
import DataStreamIframe from '../visualisations/DataStreamIframe/DataStreamIframe';
import DataStreamLineGraph from '../visualisations/DataStreamLineGraph/DataStreamLineGraph';
import DataStreamLineGraphOptions from '../visualisations/DataStreamLineGraph/DataStreamLineGraphOptions';
import DataStreamScalar from '../visualisations/DataStreamScalar/DataStreamScalar';
import DataStreamScalarOptions from '../visualisations/DataStreamScalar/DataStreamScalarOptions';
import DataStreamTable from '../visualisations/DataStreamTable/DataStreamTable';
import DataStreamTableOptions from '../visualisations/DataStreamTable/DataStreamTableOptions';
import IFrame from '../visualisations/IFrame/IFrame';
import ObjectNetwork from '../visualisations/Network/ObjectNetwork';
import WorkspaceNetwork from '../visualisations/Network/WorkspaceNetwork';
import Table from '../visualisations/Table/Table';
import Text from '../visualisations/Text/Text';
import GenericRepo from './GenericRepo';

const dataStreams = {
    'data-stream-blocks': DataStreamBlocks,
    'data-stream-line-graph': DataStreamLineGraph,
    'data-stream-bar-chart': DataStreamBarChart,
    'data-stream-scalar': DataStreamScalar,
    'data-stream-gauge': DataStreamGaugeChart,
    'data-stream-table': DataStreamTable,
    'data-stream-donut-chart': DataStreamDonut,
    'data-stream-iframe': DataStreamIframe
};

export const dataStreamVisualisationRepo = new GenericRepo<React.FC<any>>(dataStreams);

export const visualisationsRepo = new GenericRepo<React.FC<any>>({
    text: Text,
    table: Table,
    network: ObjectNetwork,
    'workspace-network': WorkspaceNetwork,
    iframe: IFrame,
    image: Image,
    ...dataStreams
});

const options: { [key: string]: VisualisationOption<any> } = {
    'data-stream-table': DataStreamTableOptions,
    'data-stream-blocks': DataStreamBlocksOptions,
    'data-stream-scalar': DataStreamScalarOptions,
    'data-stream-gauge': DataStreamGaugeChartOptions,
    'data-stream-line-graph': DataStreamLineGraphOptions,
    'data-stream-bar-chart': DataStreamBarChartOptions,
    'data-stream-donut-chart': DataStreamDonutOptions,
    'data-stream-iframe': DataStreamIframeOptions
};

export const visualisationOptionsRepo = new GenericRepo<VisualisationOption<unknown>>(options);
