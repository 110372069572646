import { getPropTypeForSubType } from '@squaredup/constants';
import { isFeatureEnabled } from '@squaredup/tenants';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { FeatureUnavailableBanner } from 'components/plans/FeatureUnavailableBanner';
import { useTier } from 'queries/hooks/useTier';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { List, PROPS } from 'services/PropsService';
import { SettingsTemplate } from '../SettingsTemplate';
import { TagPropertiesAddModal } from './TagPropertiesAddModal';
import PropsDeleteModal from './TagPropertiesDeleteModal';
import TagPropertiesTable from './TagPropertiesTable';

export type ProjectedTagProperty = {
    id: string;
    displayName: string;
    subType: string;
};

/**
 * Renders the Properties settings page
 */
function TagProperties() {
    const [tagPropertyBeingDeleted, setTagPropertyBeingDeleted] = useState<ProjectedTagProperty>();
    const [tagPropertyAddOpen, setTagPropertyAddOpen] = useState(false);
    const { data: tier, isLoading: isLoadingTier } = useTier();
    const isTagsAvailable = tier !== undefined && isFeatureEnabled(tier, 'promotedProps');

    const { data: tagProperties, isLoading: isLoadingTags } = useQuery([PROPS], List, {
        select: (data: ProjectedTagProperty[]) =>
            data
                .sort((a, b) => {
                    if (a.displayName === undefined) {
                        a.displayName = '';
                    }
                    if (b.displayName === undefined) {
                        b.displayName = '';
                    }
                    return a.displayName.localeCompare(b.displayName);
                })
                .map((t) => {
                    return {
                        id: t.id,
                        displayName: t.displayName,
                        subType:
                            getPropTypeForSubType(t.subType) !== ''
                                ? getPropTypeForSubType(t.subType)
                                : 'Applies to all objects'
                    };
                })
    });

    const isLoading = isLoadingTags || isLoadingTier;

    return (
        <SettingsTemplate
            title='Tags'
            description={
                isTagsAvailable
                    ? "Tags are used to make an object's property visible in the knowledge graph."
                    : undefined
            }
            learnMoreLink='https://squaredup.com/cloud/promoted-properties'
        >
            {isLoading && (
                <span className='flex justify-center'>
                    <LoadingSpinner />
                </span>
            )}
            {!isLoading && (
                <div className='flex flex-col flex-1 min-h-0'>
                    <FeatureUnavailableBanner
                        featureKey='promotedProps'
                        container='page'
                        title='Promote key properties'
                        className='mb-9'
                    />
                    <div>
                        <Button
                            onClick={() => setTagPropertyAddOpen(true)}
                            data-testid='addProp'
                            disabled={!isTagsAvailable}
                        >
                            Add tag property
                        </Button>
                    </div>

                    <div className='flex flex-col min-h-0 mt-4 mb-8'>
                        <TagPropertiesTable
                            isFeatureAvailable={isTagsAvailable}
                            tagProperties={tagProperties || []}
                            onDelete={(prop) => setTagPropertyBeingDeleted(prop)}
                        />
                    </div>
                </div>
            )}

            {tagPropertyBeingDeleted && (
                <PropsDeleteModal
                    tagProperty={tagPropertyBeingDeleted}
                    onClose={() => setTagPropertyBeingDeleted(undefined)}
                    key='props-delete'
                />
            )}

            {tagPropertyAddOpen && (
                <TagPropertiesAddModal
                    onClose={() => {
                        setTagPropertyAddOpen(false);
                    }}
                    key='props-add'
                />
            )}
        </SettingsTemplate>
    );
}

export default TagProperties;
