import { HealthState, unmonitoredLabel } from '@squaredup/monitoring';
import pluralize from 'pluralize';
import { useWorkspacesWithHealthRollup } from 'queries/hooks/useWorkspacesWithHealthRollup';
import { useMemo } from 'react';
import { TileState } from 'services/HealthService';
import { StatusRequestType } from '../ui/StatusOverview';

type BlockReason = Partial<TileState & { text: string }>;

function getDashboardMonitorId(reason: BlockReason, type: StatusRequestType) {
    if (type === 'dash') {
        return reason.dashId;
    }
    return `${reason.dashId}/${reason.tileId}`;
}

export const getCountText = (errorCount: number, warningCount: number) => {
    if (errorCount === 0) {
        return `${warningCount} ${pluralize('monitor', warningCount)} in a warning state`;
    }
    if (warningCount === 0) {
        return `${errorCount} ${pluralize('monitor', errorCount)} in an error state`;
    }
    return `${errorCount + warningCount} ${pluralize(
        'monitor',
        errorCount + warningCount
    )} ${errorCount + warningCount > 1 ? 'are' : 'is'} in an error or warning state`;
};

export function useBlockReason(type: StatusRequestType, id: string, state?: HealthState) {
    const { data: workspaces = [], isLoading } = useWorkspacesWithHealthRollup({
        enabled: state && ['error', 'warning'].includes(state),
        suspense: true
    });

    const reason = useMemo(() => {
        if (!state) {
            return unmonitoredLabel;
        }

        if (type === 'monitor' && state === 'success') {
            return undefined;
        }

        if (state === 'success' || isLoading) {
            return 'All monitors healthy';
        }

        if (state === 'unknown' || isLoading) {
            return 'Unknown state';
        }

        let reasons = [];

        // Get all the reasons for the object
        if (type === 'space') {
            reasons.push(...(workspaces.find((w) => w.id === id)?.reasons || []));
        } else {
            for (const w of workspaces) {
                const tempReasons = w.reasons?.filter((d) => getDashboardMonitorId(d, type) === id);
                if (tempReasons && tempReasons.length > 0) {
                    reasons.push(...tempReasons);
                    break; // We found the one and only reason
                }
            }
        }

        const firstTextReason = reasons[0]?.text ? reasons[0] : undefined;

        // Only ever 1 monitor
        if (type === 'monitor' && !firstTextReason) {
            return 'This monitor is in a degraded state';
        }

        if (!firstTextReason || firstTextReason.state !== state) {
            const errorCount = reasons.filter((r) => r.state === 'error').length;
            return getCountText(errorCount, reasons.length - errorCount);
        }

        let shortTextReason = firstTextReason.text;
        if (firstTextReason.stateReason?.comparison) {
            shortTextReason = `${firstTextReason.stateReason.metricDisplayName} ${firstTextReason.stateReason.comparison} ${firstTextReason.stateReason.thresholdValue} [${firstTextReason.stateReason.monitorValue}]`;
        }

        return `${shortTextReason}${
            reasons.length > 1 ? ` (+${reasons.length - 1} ${pluralize('monitor', reasons.length - 1)})` : ''
        }`;
    }, [state, isLoading, type, workspaces, id]);

    return reason;
}
