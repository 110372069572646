import { Serialised } from '@squaredup/ids';
import { Button } from 'components/button';
import Modal, { CloseHandler, ModalCloseReason, ModalButtons } from 'components/Modal';
import type { ProjectedPlugin } from 'dynamo-wrapper';
import Plugins from 'pages/datasources/Datasources';
import { Dispatch, SetStateAction } from 'react';
import { useLatestAvailableDatasources } from '../../../queries/hooks/useLatestAvailableDatasources';
import Text from '@/components/Text';
import { TruncatedText } from 'components/TruncatedText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

interface SelectPluginModalProps {
    setSelectedPlugin: Dispatch<SetStateAction<Serialised<ProjectedPlugin> | undefined>>;
    close: CloseHandler;
}

export const SelectPluginModal: React.FC<SelectPluginModalProps> = ({ setSelectedPlugin, close }) => {
    const { data: plugins, isLoading } = useLatestAvailableDatasources();

    return (
        <Modal
            close={(reason?: ModalCloseReason) => close(reason)}
            maxWidth='max-w-7xl'
            headerBorder
            fullHeight
            fullWidth
        >
            <div className='flex flex-row items-center flex-shrink-0 px-8 py-5 overflow-hidden border-b border-dividerPrimary'>
                <span className='flex flex-col items-start flex-1 overflow-hidden'>
                    <Text.H2 className='w-full truncate text-textPrimary' data-testid='modalHeader'>
                        <TruncatedText title='Add a data source' className='max-w-full truncate' />
                    </Text.H2>
                </span>

                <FontAwesomeIcon
                    icon={faXmark}
                    className='ml-4 text-xl cursor-pointer text-tertiaryButton hover:text-tertiaryButtonHover'
                    onClick={() => close()}
                    data-testid='closeModal'
                />
            </div>
            
            <div className='relative flex-1 h-full min-h-0 px-8 mt-4 overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                <Plugins 
                    onSelect={setSelectedPlugin}
                    existing={[]}
                    empty={plugins?.length === 0 && !isLoading}
                    background='bg-backgroundSecondary'
                    modal
                />
            </div>

            <ModalButtons hideTopMargin>
                <Button type='button' onClick={close} variant='tertiary' data-testid='cancelPluginModalButton'>
                    Cancel
                </Button>
            </ModalButtons>
        </Modal>
    );
};