import type { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    type Dispatch,
    type FC,
    type SetStateAction
} from 'react';
import { useQueryClient } from 'react-query';
import { useDataStreamDefinitionsForWorkspace } from 'services/DataStreamDefinitionService';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { type TileEditorAction } from './TileEditorActions';
import { toTileEditorDataStream, type TileEditorState } from './TileEditorState';
import { createTileEditorStateReducer } from './TileEditorStateReducer';

type TileEditorStateContextValue = {
    dispatch: Dispatch<TileEditorAction>;
    state: Omit<TileEditorState, 'sideEffects'>;
};

const TileEditorStateContext = createContext<TileEditorStateContextValue>({
    dispatch: () => {
        throw new Error('No TileEditorStateContext set');
    },
    get state(): TileEditorState {
        throw new Error('No TileEditorStateContext set');
    }
});

/**
 * Provide tile editor state and reducer dispatch to all children.
 * Must be inside a `DatasetContext`.
 */
export const TileEditorStateProvider: FC<{
    config?: DataStreamBaseTileConfig;
    setConfig?: Dispatch<SetStateAction<DataStreamBaseTileConfig>>;
}> = ({ children }) => {
    const { config, setConfig } = useDatasetContext();
    const queryClient = useQueryClient();

    const { data: dataStreams = [] } = useDataStreamDefinitionsForWorkspace({
        queryOptions: {
            select: (d) => d.map(toTileEditorDataStream),
            keepPreviousData: true
        }
    });

    const reducer = useMemo(() => createTileEditorStateReducer({ queryClient, setConfig }), [queryClient, setConfig]);

    const [state, dispatch] = useReducer(reducer, {
        dataStreams,
        selectedDataStream: dataStreams.find((ds) => ds.id === config?.dataStream?.id)
    });

    useEffect(() => {
        if (state.sideEffects != null) {
            state.sideEffects();
            dispatch({ type: 'markSideEffectsExecuted' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.sideEffects]);

    return <TileEditorStateContext.Provider value={{ state, dispatch }}>{children}</TileEditorStateContext.Provider>;
};

/**
 * Get the current state and dispatch function for the tile editor state reducer.
 */
export const useTileEditorState = () => {
    return useContext(TileEditorStateContext);
};
