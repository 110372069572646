import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import Form from 'components/forms/form/Form';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons, ifNotOutside } from 'components/Modal';
import { getIconForType } from 'lib/types';
import { allowProp } from 'pages/settings/Common';
import trackEvent from 'lib/analytics';
import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Create, PROPS } from 'services/PropsService';

const propBlackList = ['name', 'sourceId', 'sourceType'];

type TagPropertiesFormData = {
    displayName: string;
    propType: string;
};

type TagPropertiesAddModalProps = {
    onClose: () => void;
};

export const TagPropertiesAddModal: FC<TagPropertiesAddModalProps> = ({ onClose }) => {
    const queryClient = useQueryClient();

    const { mutateAsync: saveTagProperty } = useMutation(
        async (data: TagPropertiesFormData) => {
            const { displayName, propType} = data;

                // add new prop type
                await Create(displayName, propType);
                trackEvent('Tag Created', { name: displayName, type: propType });
        },
        {
            onSuccess: () => {
                onClose();
            },
            onSettled: async () => {
                queryClient.invalidateQueries([PROPS]);
            }
        }
    );
        
    return (
        <Modal
            title={'Add tag property'}
            close={ifNotOutside(onClose)}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <div className='pr-1 tile-scroll-overflow'>
                <Form submit={saveTagProperty}>
                    {(isValid, isSubmitting, data) => (
                        <>
                            <div className='flex-1 min-h-0 px-8 tile-scroll-overflow'>
                                <Field.Input
                                     name='displayName'
                                     label='Name'
                                     title='Name'
                                     placeholder='Enter the name of a property to tag, e.g. tag:owner, emailAddress, author, etc.'
                                     help='Identifies the object property to promote to a tag object.'
                                     validation={{
                                         required: true,
                                         maxLength: 128 ,
                                         minLength: 1,
                                         validate: (prop) => {
                                             if (allowProp(prop, propBlackList)) {
                                                 return true;
                                             }
                                             return `Property ${prop} is not permitted.`;
                                         }
                                     }}
                                 />
                                 <Field.Input
                                     name='propType'
                                     label='Type'
                                     title='Type'
                                     onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                         const start = e.target.selectionStart;
                                         const end = e.target.selectionEnd;
                                         const textVal = e.target.value;
                                         e.target.value = textVal ? textVal.toLowerCase() : '';
                                         e.target.setSelectionRange(start, end);
                                     }}
                                     placeholder='Enter a type to which to restrict property to tag object promotion.'
                                     help='Property promotion to tag objects will only occur for objects of this type. If not specified tag objects will be created for matching properties of all object types.'
                                     prepend={
                                         <FontAwesomeIcon icon={getIconForType(data.propType)} />
                                     }
                                     validation={{
                                         required: false,
                                         maxLength: 128,
                                         minLength: 1
                                     }}
                                 />
                            </div>
                            <ModalButtons>
                                <Button type='button' variant='tertiary' onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button
                                    type='submit'
                                    disabled={isSubmitting || !isValid}
                                    data-testid='submit-custom-types'
                                >
                                    {isSubmitting ? <LoadingSpinner size={18} /> : 'Save'}
                                </Button>
                            </ModalButtons>
                        </>
                    )}
                </Form>
            </div>
        </Modal>        
    );
};
