import {
    StreamDataColumn,
    StreamDataColumnDefinition,
    flattenColumnExpands,
    inferDisplayNames,
    getColumnShape
} from '@squaredup/data-streams';
import { hasStringProperty, isDefined } from '@squaredup/utilities';
import stringify from 'fast-json-stable-stringify';
import { omit, omitBy } from 'lodash';
import { PartialDeep } from 'type-fest';
import {
    CloneStateColumn,
    DataStreamMetadataState,
    type ColumnDefinitionEntry
} from './MetadataEditorState';

export type TileConfigMetadataOverride = PartialDeep<StreamDataColumnDefinition>;
export type TileConfigMetadataOverrideArray = PartialDeep<StreamDataColumnDefinition[]>;

const removeNullProperties = <T extends Record<string, unknown>>(x: T) => {
    return omitBy(x, (v) => v == null);
};

/**
 * Find all clones of the given column
 */
export const findClones = (state: DataStreamMetadataState, sourceColumnName: string): CloneStateColumn[] => {
    return [...state.columnMap.values()].filter(
        (c): c is CloneStateColumn => c.isClone && c.sourceColumnName === sourceColumnName
    );
};

/**
 * Convert cloned columns in the reducer state to the format used by the tile config,
 * merging columns in the reducer state with columns in the tile config that have the
 * same name
 */
const clonesToTileMetadata = (
    state: DataStreamMetadataState,
    existingExpand: PartialDeep<StreamDataColumnDefinition>[],
    clones: CloneStateColumn[]
): PartialDeep<StreamDataColumnDefinition>[] => {
    return clones.filter(clone => clone.isEditable).map((clone) => {
        const existingClone = existingExpand.find((e) => 'cloneAs' in e && e.cloneAs === clone.cloneName);

        const nestedClones = clonesToTileMetadata(
            state,
            existingClone?.expand?.filter(isDefined) ?? [],
            findClones(state, clone.columnName)
        );

        return {
            cloneAs: clone.cloneName,
            ...clone.metadataOverrides,
            expand: nestedClones.length === 0 ? undefined : nestedClones
        };
    });
};

/**
 * Count the number of changes being made via the tile config metadata overrides
 */
export const countModifiedColumns = (
    metadataOverrides: TileConfigMetadataOverrideArray | undefined,
    validColumnNames: string[]
): number => {
    return flattenColumnExpands(metadataOverrides?.filter(isDefined).filter((c) => !('pattern' in c)))
        .filter((c) => Object.values(omit(c, 'expand', 'name')).filter((v) => v != null).length > 0)
        .filter((c) => hasStringProperty(c, 'name') && validColumnNames.includes(c.name)).length;
};

const hasOverrides = (override: TileConfigMetadataOverride) => {
    const keys = Object.keys(override);
    // Don't include 'pattern' here because when hasDefinedMetadata
    // is true we need a pattern override with just an identifier
    const identifierProperties = ['name', 'cloneAs'];

    return keys.some((k) => !identifierProperties.includes(k));
};

/**
 * Remove unused properties from a set of overrides.
 * Returns undefined if the override has no effect (i.e. does have any properties set).
 */
export const sanitizeMetadataOverride = (
    override: TileConfigMetadataOverride,
    existingOverride: TileConfigMetadataOverride | undefined,
    metadata: StreamDataColumn | undefined,
    columnDefinitions: ColumnDefinitionEntry[]
) => {
    const withoutNullProperties: TileConfigMetadataOverride = removeNullProperties(override);

    if (!hasOverrides(withoutNullProperties)) {
        return undefined;
    }

    const withoutUnnecessaryOverrides = omitBy(withoutNullProperties, (v, k) => {
        if (k === 'shape' && v != null) {
            const shapeOverride = getColumnShape(v as TileConfigMetadataOverride['shape']);
            const existingShapeOverride = getColumnShape(existingOverride?.shape);

            if (stringify(shapeOverride) === stringify(existingShapeOverride)) {
                return false;
            }

            return (
                shapeOverride?.name === metadata?.shapeName &&
                stringify(shapeOverride?.config) === stringify(metadata?.rawShapeConfig)
            );
        }

        const areValuesEqual = v === metadata?.[k as keyof StreamDataColumn];
        const hasExistingOverride = existingOverride?.[k as keyof TileConfigMetadataOverride] != null;

        if (k !== 'displayName') {
            return areValuesEqual && !hasExistingOverride;
        }

        /**
         * displayName is special because it can be inferred from the column name,
         * but only if it wasn't set by the plugin. We need to figure out where the
         * default is coming from and compare against that.
         */
        const defaultDisplayName =
            columnDefinitions.find(
                // Ignore request definitions because they're the overrides set by the metadata editor
                (d) => d.source !== 'request' && d.definition.name === metadata?.name
            )?.definition.displayName ?? (metadata != null ? inferDisplayNames([metadata.name])[0] : '');
        const isDisplayNameEqualToDefault = v === '' || v === defaultDisplayName;

        return (areValuesEqual && !hasExistingOverride) || isDisplayNameEqualToDefault;
    });

    return hasOverrides(withoutUnnecessaryOverrides) ? withoutUnnecessaryOverrides : undefined;
};

/**
 * Convert the reducer state to the format used by the tile config
 */
export const toTileMetadata = (state: DataStreamMetadataState): PartialDeep<StreamDataColumnDefinition>[] => {
    return [...state.columnMap.values()].flatMap((c): PartialDeep<StreamDataColumnDefinition>[] => {
        if (c.isPattern) {
            return [{ pattern: c.pattern, ...c.metadataOverrides }];
        }

        const clones = findClones(state, c.columnName).filter((n) => n.isEditable);

        if (
            c.isClone ||
            ((c.metadataOverrides == null || !hasOverrides(removeNullProperties(c.metadataOverrides))) &&
                clones.length === 0)
        ) {
            return [];
        }

        const mergedClones = clonesToTileMetadata(state, c.metadataOverrides?.expand?.filter(isDefined) ?? [], clones);

        return [
            {
                ...c.metadataOverrides,
                name: c.columnName,
                expand: mergedClones.length === 0 ? undefined : mergedClones
            }
        ];
    });
};
