import { Button } from '@/components/Button';
import { dropdownWorkspaceLandingPageOptions, type DropdownWorkspaceLandingPageOptions } from '@squaredup/constants';
import { isFeatureEnabled } from '@squaredup/tenants';
import LoadingSpinner from 'components/LoadingSpinner';
import { ModalButtons } from 'components/Modal';
import { useEveryoneGroup } from 'components/hooks/useEveryoneGroup';
import type { AccessControlEntryModel } from 'dynamo-wrapper';
import { workspacePredefinedIconKey } from 'lib/fontawesome/fontawesome';
import { useTier } from 'queries/hooks/useTier';
import { useWorkspaceAvatar } from 'queries/hooks/useWorkspaceAvatar';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { PropsWithChildren, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import { AccessControlQueryKeys, GetEntityACL } from 'services/AccessControlService';
import { Update, WORKSPACES, Workspace } from 'services/WorkspaceService';
import ClipboardToast from 'ui/notifications/ClipboardToast';
import { useEditWorkspaceModalContext } from './EditWorkspaceModal';
import { getWorkspaceACLQueryKey, useWorkspaceACLUtils } from './WorkspaceModalAccessControlTab';

export type EmailDomainData = {
    label: string;
    value: string;
};
type EditWorkspaceFormData = {
    displayName: string;
    description: string | undefined;
    type: { label: string; value: string } | undefined;
    tags: Array<{ label: string; value: string }>;
    landingPage: {
        label: DropdownWorkspaceLandingPageOptions['label'];
        value: DropdownWorkspaceLandingPageOptions['value'];
    };
    accessControlToggle: boolean;
    openAccessEnabled: boolean;
    acl: AccessControlEntryModel[];
    links: {
        workspaces: Array<{ label: string; value: string }>;
    };
    avatar: string | undefined;
    authorizedEmailDomains: EmailDomainData[] | undefined;
};

interface EditWorkspaceFormProps {
    /**
     * If true, the default client ACL ([User:Full Control, Everyone:Viewer]) is used when access control is enabled.
     * This is designed for the scenario where the user is deliberately enabling access control and we want to offer
     * a sensible initial value for them to work from.
     *
     * If false, no default ACL is applied and the user sees whatever the existing ACL is.  When access control is
     * disabled, this is [Everyone: Full Control].
     * This is designed for the scenario where the user is just viewing/editing the ACL and there is no explicit
     * enabling or disabling of access control.
     *
     * If not specified, useDefaultClientACL defaults to true.
     */
    useDefaultClientACL?: boolean;
}

export const EditWorkspaceForm = (props: PropsWithChildren<EditWorkspaceFormProps>) => {
    const modalCtx = useEditWorkspaceModalContext();
    const workspaceACLUtils = useWorkspaceACLUtils();
    const workspaceMutation = useWorkspaceMutation();
    const defaultACL = props.useDefaultClientACL === false ? undefined : workspaceACLUtils.getDefaultACLClient();
    const defaultFormValues = useDefaultFormValues(defaultACL);
    const form = useForm<EditWorkspaceFormData>({ defaultValues: defaultFormValues, mode: 'all' });
    const isFormValid = Object.keys(form.formState.errors).length === 0 && form.formState.isValid;

    useWorkspaceACL({
        id: modalCtx.workspace.id,
        defaultACL,
        onSuccess: ({ acl, userModifiedACL }) => {
            form.setValue('accessControlToggle', userModifiedACL);
            form.setValue('acl', acl);
        }
    });

    useWorkspaceAvatar({
        id: modalCtx.workspace.id,
        enabled: !modalCtx.workspace.data?.properties?.avatarIconName,
        onSuccess: (avatar) => {
            if (!form.getFieldState('avatar').isTouched) {
                form.setValue('avatar', avatar);
            }
        }
    });

    useEffect(() => {
        const icon = modalCtx.workspace.data?.properties?.avatarIconName;

        if (!form.getFieldState('avatar').isTouched && icon) {
            form.setValue('avatar', icon);
        }
    }, [form, modalCtx.workspace.data?.properties?.avatarIconName]);

    const onSubmit = async (updatedWorkspace: EditWorkspaceFormData) => {
        const defaultServerACL = workspaceACLUtils.getDefaultACLServer();

        await workspaceMutation.mutateAsync({
            id: modalCtx.workspace.id,
            displayName: updatedWorkspace.displayName.trim(),
            properties: {
                dashboardIdOrder: modalCtx.workspace.data.properties?.dashboardIdOrder,
                description: updatedWorkspace.description?.trim(),
                tags: updatedWorkspace.tags.map((x) => x.value),
                type: updatedWorkspace.type?.value,
                landingPage: updatedWorkspace.landingPage.value,
                openAccessEnabled: updatedWorkspace.openAccessEnabled,
                avatarUploadedAt: form.getFieldState('avatar').isDirty
                    ? Date.now()
                    : modalCtx.workspace?.data?.properties?.avatarUploadedAt,
                avatarIconName: updatedWorkspace?.avatar?.startsWith(workspacePredefinedIconKey)
                    ? updatedWorkspace.avatar
                    : undefined,
                authorizedEmailDomains: updatedWorkspace.authorizedEmailDomains?.map((domain) => domain.value) ?? []
            },
            permissions: updatedWorkspace.accessControlToggle ? updatedWorkspace.acl : defaultServerACL,
            links: {
                ...modalCtx.workspace.data.links,
                workspaces: updatedWorkspace.links.workspaces.map((link) => link.value)
            },
            avatar: form.getFieldState('avatar').isDirty ? updatedWorkspace.avatar : undefined
        });
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col flex-1 overflow-auto'>
                {props.children}

                <ModalButtons hideTopMargin>
                    <Button
                        type='button'
                        variant='tertiary'
                        className='capitalize'
                        onClick={() => modalCtx.close('cancel')}
                        data-testid='cancel-create-workspace-form'
                    >
                        cancel
                    </Button>
                    <Button
                        type='submit'
                        className='capitalize'
                        disabled={!isFormValid || form.formState.isSubmitting}
                        data-testid='submit-create-workspace-form'
                    >
                        {form.formState.isSubmitting && <LoadingSpinner size={18} className='mr-3' />}

                        <span>save</span>
                    </Button>
                </ModalButtons>
            </form>
        </FormProvider>
    );
};

const editWorkspace = (
    props: Pick<Workspace, 'displayName' | 'id'> &
        Pick<Workspace['data'], 'properties'> &
        Pick<Workspace['data'], 'links'> & { permissions: AccessControlEntryModel[] } & { avatar?: string }
) => {
    return Update(
        props.id,
        props.displayName,
        undefined,
        props.links,
        props.permissions,
        props.properties,
        props.avatar
    );
};

const useWorkspaceMutation = () => {
    const { workspace, close } = useEditWorkspaceModalContext();
    const queryClient = useQueryClient();

    const workspaceMutation = useMutation(editWorkspace, {
        onMutate: async (updatedWorkspace) => {
            queryClient.cancelQueries(workspaceQueryKeys.all);
            queryClient.cancelQueries(getWorkspaceACLQueryKey({ id: workspace.id }));
            queryClient.cancelQueries([WORKSPACES, workspace.id]);
            queryClient.cancelQueries(workspaceQueryKeys.avatar(workspace.id));

            const optimisticWorkspace: Workspace = {
                ...workspace,
                ...updatedWorkspace,
                data: {
                    ...workspace.data,
                    properties: {
                        ...workspace.data.properties,
                        ...updatedWorkspace.properties
                    }
                }
            };

            queryClient.setQueryData<Workspace[]>(workspaceQueryKeys.list, (data) => {
                if (!data) {
                    return [];
                }

                return data.map((w) => (w.id === workspace.id ? optimisticWorkspace : w));
            });

            queryClient.setQueryData<Workspace[]>(workspaceQueryKeys.forAdministration, (data) => {
                if (!data) {
                    return [];
                }

                return data.map((w) => (w.id === workspace.id ? optimisticWorkspace : w));
            });

            queryClient.setQueryData(workspaceQueryKeys.avatar(workspace.id), updatedWorkspace.avatar);
        },
        onSuccess: () => {
            close('exit');
        },
        onSettled: () => {
            queryClient.invalidateQueries(workspaceQueryKeys.all);
            queryClient.invalidateQueries([WORKSPACES, workspace.id]);
            queryClient.invalidateQueries(getWorkspaceACLQueryKey({ id: workspace.id }));
            queryClient.invalidateQueries(workspaceQueryKeys.list);
            queryClient.invalidateQueries(workspaceQueryKeys.avatar(workspace.id));
            queryClient.invalidateQueries(AccessControlQueryKeys.EntityTypePermissions('space'));
        },
        onError: () => {
            ClipboardToast('saving workspace failed', true);
        }
    });

    return workspaceMutation;
};

const useDefaultFormValues = (defaultACL: AccessControlEntryModel[] | undefined) => {
    const { workspace } = useEditWorkspaceModalContext();
    const workspaceACL = useWorkspaceACL({ id: workspace.id, defaultACL });
    const queryClient = useQueryClient();

    const description = workspace.data.properties?.description;
    const type = workspace.data.properties?.type;
    const tags = workspace.data.properties?.tags;
    const landingPage = workspace?.data?.properties?.landingPage ?? 'workspace-overview';
    const accessControlToggle = workspaceACL.data?.userModifiedACL ?? false;
    const openAccessEnabled = workspace?.data?.properties?.openAccessEnabled ?? false;
    const workspaceLinks = workspace?.data?.links.workspaces;
    const acl = workspaceACL.data?.acl ?? [];
    const avatar = queryClient.getQueryData<string>(workspaceQueryKeys.avatar(workspace.id));
    const authorizedEmailDomains = workspace.data.properties?.authorizedEmailDomains ?? [];

    return {
        displayName: workspace.displayName,
        description: description,
        type: type ? { label: type, value: type } : undefined,
        tags: tags ? tags.map((tag) => ({ label: tag, value: tag })) : [],
        landingPage: dropdownWorkspaceLandingPageOptions.find((option) => option.value === landingPage),
        accessControlToggle: accessControlToggle,
        openAccessEnabled: openAccessEnabled,
        acl: acl,
        links: {
            workspaces: workspaceLinks?.map((link) => ({ label: link, value: link })) ?? []
        },
        avatar: avatar,
        authorizedEmailDomains: authorizedEmailDomains.map((domain) => ({ label: domain, value: domain })) ?? []
    };
};

export const useWorkspaceACL = (
    props: UseQueryOptions<{ acl: AccessControlEntryModel[]; userModifiedACL: boolean }> &
        Pick<Workspace, 'id'> & { defaultACL?: AccessControlEntryModel[] }
) => {
    const workspaceACLUtils = useWorkspaceACLUtils();
    const everyoneGroup = useEveryoneGroup();
    const { data: tier } = useTier();

    const getWorkspaceACL = async () => {
        const acl = await GetEntityACL(props.id);

        const isACLFeatureEnabled = tier && isFeatureEnabled(tier, 'accessControl');
        const userModifiedACL = Boolean(isACLFeatureEnabled && !workspaceACLUtils.isDefaultServer(acl));

        if (!userModifiedACL && props.defaultACL) {
            return {
                userModifiedACL,
                acl: props.defaultACL
            };
        }

        return {
            userModifiedACL,
            acl
        };
    };

    return useQuery({
        queryKey: getWorkspaceACLQueryKey({ id: props.id }, props.defaultACL),
        queryFn: getWorkspaceACL,
        enabled: Boolean(everyoneGroup.data),
        ...props
    });
};
