import { allCurrencies } from '@squaredup/data-streams';
import Field from 'components/forms/field/Field';
import type { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import { partition } from 'lodash';
import type { ReactNode } from 'react';
import type { ShapeConfigFieldsComponent } from './ShapeConfigFields';

export const CurrencyShapeConfigFields: ShapeConfigFieldsComponent = ({ name, children }) => {
    const [commonCurrencies, otherCurrencies] = partition([...allCurrencies.values()], (c) =>
        ['usd', 'eur', 'gbp'].includes(c.code)
    );

    otherCurrencies.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));

    const currencyToOption = (
        c: (typeof commonCurrencies)[number]
    ): AutocompleteOption & { labelElement: ReactNode } => {
        const exampleFormattedValue = c.format(12.34);
        return {
            label: c.displayName,
            labelElement: (
                // Min width ensures a gap between display name and sample value when selected
                <div className='flex justify-between min-w-[12rem]'>
                    <span>{c.displayName}</span>
                    <span className='text-sm text-textSecondary'>{exampleFormattedValue}</span>
                </div>
            ),
            value: c.code
        };
    };

    const commonCurrencyOptions = commonCurrencies.map(currencyToOption);
    const otherCurrencyOptions = otherCurrencies.map(currencyToOption);

    return (
        <>
            <Field.Input
                label='Currency'
                name={`${name}.code`}
                type='autocomplete'
                isMulti={false}
                selectOptionsAs={'valueString'}
                className='w-80'
                help='Currency to format the values as. This does not apply any currency conversion.'
                formatOptionLabel={(o: { labelElement: ReactNode }) => o.labelElement}
                isSearchable={true}
                options={[
                    { label: 'Common', options: commonCurrencyOptions },
                    { label: 'Other', options: otherCurrencyOptions }
                ]}
            />
            {children}
        </>
    );
};
