import { cn } from '@/lib/cn';
import { Datum } from '@nivo/line';
import useSize from '@react-hook/size';
import { numberColumn } from '@squaredup/data-streams';
import { HealthState, healthStates, unmonitoredLabel } from '@squaredup/monitoring';
import { validateNonEmptyData } from 'dashboard-engine/dataStreams/DataValidation';
import { formatTickAsColumn } from 'dashboard-engine/util/tickFormatter';
import { useMemo, useRef, useState } from 'react';
import { useTheme } from 'ui/hooks/useTheme';
import NoDataPlaceholder from '../../../components/NoDataPlaceholder';
import { DataStreamVisualisation } from '../../types/Visualisation';
import { LegendWrapper } from '../components/LegendWrapper';
import { getColor } from '../getColor';
import { useVisualizationTheme } from '../hooks/useVisualizationTheme';
import { DataStreamDonutConfig } from './Config';
import { DataStreamDonutViz } from './DataStreamDonutViz';
import { toDonut } from './DonutTransformations';
import { formatPercentageTooltipValue, getDonutColumns, getStateColors } from './dataUtils';

const healthStateCheck = (state: string) =>
    healthStates.includes(state.toLowerCase() as HealthState) || 
        state === unmonitoredLabel;

const tileHeaderHeight = 44;

const DataStreamDonut: DataStreamVisualisation<DataStreamDonutConfig> = ({ data, config, onClick }) => {
    const series = useMemo(() => toDonut(data, config), [data, config]);
    const theme = useTheme();

    const containerRef = useRef<HTMLDivElement>(null);

    const totalValue = series.reduce((acc, cur) => acc + cur.value, 0);

    const [centerValue, setCenterValue] = useState<number>(totalValue);
    const [currentlyHoveredId, setCurrentlyHoveredId] = useState<number | undefined>();

    const [containerWidth, containerHeight] = useSize(containerRef);

    const position = config.legendPosition;

    const isAutoVertical = position === 'auto' && containerWidth * 0.9 < containerHeight + tileHeaderHeight;
    const isVertical = isAutoVertical || position === 'top' || position === 'bottom';

    const isHealthState = series.every((x) =>
        healthStateCheck(x.label) || 
            (x.composition?.length && x.composition.every(y => healthStateCheck(y.label))
    ));

    const donutGraphTheme = useVisualizationTheme('donut');

    const donutColumnsResult = getDonutColumns(data.metadata.columns);
    const { valueColumn } = donutColumnsResult.success
        ? donutColumnsResult.value
        : // If we have no data (and therefore no columns), we still want to render the no data placeholder,
          // so invent a fake column to avoid throwing or having to handle nulls
          // in code that will never be called
          { valueColumn: { column: numberColumn({ name: '' }), dataIndex: -1 } };

    const formatValue = useMemo(
        () =>
            formatTickAsColumn(valueColumn.column, {
                columns: data.metadata.columns,
                rows: data.rows
            }),
        [data, valueColumn]
    );

    const formatPercentage = useMemo(
        () =>
            formatTickAsColumn(
                { ...valueColumn.column, targetShapeName: 'shape_percent', shapeName: 'shape_percent' },
                {
                    columns: data.metadata.columns,
                    rows: data.rows
                }
            ),
        [data, valueColumn]
    );

    if (!series || series.length === 0) {
        return <NoDataPlaceholder />;
    }

    if (!validateNonEmptyData(series)) {
        return <NoDataPlaceholder message='No non-zero values to display.' />;
    }

    const onHover = (attributes: Datum) => {
        // if hovering legend, percentage is top level prop, if donut, it's nested
        const percentage = attributes.percentage || attributes.data.percentage;
        setCenterValue(config.showValuesAsPercentage ? percentage : attributes.value);
        setCurrentlyHoveredId(parseInt(attributes.id.toString()));
    };

    const onLeave = () => {
        setCenterValue(totalValue);
        setCurrentlyHoveredId(undefined);
    };

    const getDonutColor = (datum: Datum) =>
        getColor(config, isHealthState ? getStateColors(theme) : donutGraphTheme, datum.label, datum.data.index) ?? '#1d5cf8';

    const legend = series.map((s) => ({
        id: s.id,
        index: s.index,
        label: s.label,
        value: s.value,
        percentage: s.percentage,
        formattedPercentage: formatPercentage(s.percentage),
        isFocused: currentlyHoveredId !== undefined ? currentlyHoveredId === s.id : true,
        formattedValue: formatValue(s.value),
        composition: s.composition?.map((c, i) => {
            const value = s.rows[i][valueColumn.dataIndex].formatted;
            return {
                ...c,
                value: config.showValuesAsPercentage
                    ? formatPercentageTooltipValue(c.percentage, value, formatPercentage)
                    : value
            };
        }),
        color: getDonutColor({
            label: s.label,
            data: {
                index: s.index
            }
        })
    }));

    const donut = (
        <DataStreamDonutViz
            series={series}
            config={config}
            isAutoVertical={isAutoVertical}
            currentlyHoveredId={currentlyHoveredId}
            formatValue={formatValue}
            formatPercentage={formatPercentage}
            getDonutColor={getDonutColor}
            containerRef={containerRef}
            valueColumn={valueColumn}
            centerValue={centerValue}
            totalValue={totalValue}
            onHover={onHover}
            onLeave={onLeave}
            onClick={onClick}
        />
    );

    if (config.legendMode === 'table' && config.legendPosition) {
        const legendPosition = isAutoVertical ? 'bottom' : config.legendPosition;

        return (
            <div ref={containerRef} className='w-full h-full'>
                <LegendWrapper
                    legend={legend}
                    position={legendPosition}
                    showValuesAsPercentage={config.showValuesAsPercentage}
                    vizRef={containerRef}
                    showTooltip={true}
                    containerSizePercentage={0.6}
                    vizContainerClassname={cn('aspect-square', {
                        'w-full h-auto max-h-[50%]': isVertical,
                        'h-full w-auto max-w-[50%]': !isVertical
                    })}
                    legendContainerClassname={cn({
                        'w-full max-h-[50%]': isVertical,
                        'h-full max-w-[50%]': !isVertical,
                        'mt-4': legendPosition === 'bottom',
                        'mb-4': legendPosition === 'top',
                        'mr-8': legendPosition === 'left',
                        'ml-8': legendPosition === 'right'
                    })}
                    onHover={onHover}
                    onLeave={onLeave}
                    formatValue={formatValue}
                    formatPercentage={formatPercentage}
                >
                    {donut}
                </LegendWrapper>
            </div>
        );
    }

    return donut;
};

DataStreamDonut.config = 'DonutConfig';

export default DataStreamDonut;
