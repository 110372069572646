import { HorizontalPosition, VerticalPosition } from 'components/forms/positionPicker/PositionPicker';
import { createRef, useLayoutEffect, useState } from 'react';

interface HealthStateIconProps {
    healthId: string;
    size?: number;
    verticalPosition: VerticalPosition;
    horizontalPosition: HorizontalPosition;
}

export const HealthStateIcon: React.FC<HealthStateIconProps> = ({
    healthId,
    size,
    verticalPosition,
    horizontalPosition,
    children
}) => {
    const ref = createRef<SVGGElement>();
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const iconSize = 20 + 5 * (size || 1);

    useLayoutEffect(() => {
        if (ref.current) {
            const bbox = ref.current.getBBox();
            let { x, y, width, height } = bbox;
            if (horizontalPosition === 'left') {
                x -= iconSize / 2;
            } else if (horizontalPosition === 'center') {
                x += width / 2 - iconSize / 2;
            } else if (horizontalPosition === 'right') {
                x += width - iconSize / 2;
            }
            if (verticalPosition === 'top') {
                y -= iconSize / 2;
            } else if (verticalPosition === 'middle') {
                y += height / 2 - iconSize / 2;
            } else if (verticalPosition === 'bottom') {
                y += height - iconSize / 2;
            }
            setPosition({ x, y });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verticalPosition, horizontalPosition, iconSize]);

    return (
        <>
            <g ref={ref}>{children}</g>
            <use
                href={`#health-icon-${healthId}`}
                filter={`url(#health-icon-${healthId}-shadow)`}
                x={position.x}
                y={position.y}
                width={iconSize}
                height={iconSize}
            />
        </>
    );
};
