import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    DataStreamFilterConfig,
    FilterObject,
    NonValueFilterOperationTypeSchema,
    NumberValueFilterOperationTypeSchema,
    StreamDataColumn,
    StringValueFilterOperationTypeSchema,
    date,
    isValuelessFilterOperation,
    number,
    state,
    string
} from '@squaredup/data-streams';
import { stateStrings, unmonitoredLabel } from '@squaredup/monitoring';
import { Result, isDefined } from '@squaredup/utilities';
import { capitalize, cloneDeep, orderBy } from 'lodash';
import { DeepPartial } from 'react-hook-form';
import { PartialDeep } from 'type-fest';
import { ZodSchema, z } from 'zod';

const ColumnSchema = z
    // eslint-disable-next-line camelcase
    .string({ required_error: 'Select a column to filter by' })
    .min(1, { message: 'Select a column to filter by' });

const FilterValidationSchema: ZodSchema<Filter> = z.union([
    z.object({
        column: ColumnSchema,
        operation: StringValueFilterOperationTypeSchema,
        value: z
            // eslint-disable-next-line camelcase
            .string({ required_error: 'Enter a value to filter by' })
            .min(1, { message: 'Enter a value to filter by' })
    }),
    z.object({
        column: ColumnSchema,
        operation: NumberValueFilterOperationTypeSchema,
        value: z.custom(
            (s) => {
                return s && !isNaN(Number(s));
            },
            { message: 'Filter value should be a number, no symbols required' }
        )
    }),
    z.object({
        column: ColumnSchema,
        operation: NonValueFilterOperationTypeSchema
    }),
    z.object({
        column: ColumnSchema,
        stateValue: z
            // eslint-disable-next-line camelcase
            .string({ required_error: 'Enter a value to filter by' })
            .min(1, { message: 'Enter a value to filter by' })
    })
]);

export const FilteringFormValidationSchema: ZodSchema<FilterFormData> = z.object({
    filters: z.array(FilterValidationSchema),
    multiOperation: z.literal('and').or(z.literal('or'))
});

export type ColumnOption = {
    column: StreamDataColumn;
    value: string;
    label: string;
};

export type Filter = {
    column?: string | null;
    operation?: FilterObject['operation'] | null;
    value?: string | null;
    stateValue?: string | null;
};

export type FilterFormData = {
    filters: Filter[];
    multiOperation: 'and' | 'or';
};

const equalityOperations = [
    {
        label: 'Equals',
        value: 'equals'
    },
    {
        label: 'Not equals',
        value: 'notequals'
    }
] as const;

const compareOperations = [
    {
        label: 'Greater than',
        value: 'greaterthan'
    },
    {
        label: 'Less than',
        value: 'lessthan'
    }
] as const;

const containsOperations = [
    {
        label: 'Contains',
        value: 'contains'
    },
    {
        label: "Doesn't contain",
        value: 'notcontains'
    }
] as const;

const dateOperations = [
    {
        label: 'Before now',
        value: 'datebefore'
    },
    {
        label: 'After now',
        value: 'dateafter'
    }
] as const;

export const existenceOperations = [
    {
        label: 'Is empty',
        value: 'empty'
    },
    {
        label: 'Is not empty',
        value: 'notempty'
    }
] as const;

const defaultOperations = [...equalityOperations, ...existenceOperations];

const numberOperations = [...equalityOperations, ...compareOperations, ...existenceOperations];

const stringOperations = [...equalityOperations, ...containsOperations, ...existenceOperations];

const stateOperations = [...equalityOperations, ...compareOperations, ...existenceOperations];

export const allOperations = [
    ...equalityOperations,
    ...compareOperations,
    ...containsOperations,
    ...dateOperations,
    ...existenceOperations
];

export const stateOptions = [
    {
        label: (
            <>
                <FontAwesomeIcon icon={faCircle} className='mr-2 text-statusHealthyPrimary' /> Success
            </>
        ),
        value: stateStrings.success,
        labelText: capitalize(stateStrings.success)
    },
    {
        label: (
            <>
                <FontAwesomeIcon icon={faCircle} className='mr-2 text-statusWarningPrimary' /> Warning
            </>
        ),
        value: stateStrings.warning,
        labelText: capitalize(stateStrings.warning)
    },
    {
        label: (
            <>
                <FontAwesomeIcon icon={faCircle} className='mr-2 text-statusErrorPrimary' /> Error
            </>
        ),
        value: stateStrings.error,
        labelText: capitalize(stateStrings.error)
    },
    {
        label: (
            <>
                <FontAwesomeIcon icon={faCircle} className='mr-2 text-statusUnknownPrimary' /> Unknown
            </>
        ),
        value: stateStrings.unknown,
        labelText: capitalize(stateStrings.unknown)
    },
    {
        label: (
            <>
                <FontAwesomeIcon icon={faCircle} className='mr-2 text-statusUnmonitoredPrimary' /> Not monitored
            </>
        ),
        value: stateStrings.unmonitored,
        labelText: unmonitoredLabel
    }
];

export const multiOperationOptions = [
    {
        label: 'AND',
        value: 'and'
    },
    {
        label: 'OR',
        value: 'or'
    }
];

export const standardiseOperationName = (v: string | null | undefined) => v?.replace(/\s+/gu, '').toLowerCase();

export const getFilterOperations = (column?: StreamDataColumn) => {
    if (!column) {
        return defaultOperations;
    }

    if (column.shapeName === date.name) {
        return [...dateOperations, ...existenceOperations];
    }

    if (column.shapeName === state.name) {
        return stateOperations;
    }

    if (column.valueShapeName === number.name) {
        return numberOperations;
    }

    if (column.valueShapeName === string.name) {
        return stringOperations;
    }

    return defaultOperations;
};

/**
 * Determine what kind of value field should be shown
 */
export const getFilterValueType = (columnShapeName: string | undefined) => {
    if (columnShapeName == null || columnShapeName === date.name) {
        return 'none';
    }

    if (columnShapeName === state.name) {
        return 'state';
    }

    return 'text';
};

export const getFilterFormDefaultValues = (
    filter: PartialDeep<FilterFormData> | undefined,
    columns: StreamDataColumn[]
): FilterFormData => {
    const filters = filter?.filters
        ? filter.filters.map((f) => {
              const configuredColumn = columns.find((v) => v.name === f?.column);
              const isStateColumn = configuredColumn?.shapeName === 'shape_state';
              return {
                  value: !isStateColumn ? f?.value : undefined,
                  stateValue: isStateColumn ? stateOptions.find(({ value }) => value === f?.value)?.value : undefined,
                  operation: allOperations.find((v) => v.value === standardiseOperationName(f?.operation))?.value,
                  column: f?.column
              };
          })
        : [getEmptyFilter()];
    return {
        multiOperation: filter?.multiOperation ?? 'and',
        filters
    };
};

export const getFilterColumns = (columns: StreamDataColumn[]) =>
    orderBy(
        columns.map((c) => ({
            value: c.name,
            label: c.displayName
        })),
        'label'
    );

// Values need to be null, otherwise react-hook-form will continue to use the form defaultValues
// when a item is removed and then a new item is appended
export const getEmptyFilter = (): Filter => ({
    column: null,
    operation: 'equals',
    stateValue: null,
    value: null
});

export const getValidFilters = (
    fields: DeepPartial<FilterFormData>,
    columns: StreamDataColumn[]
): Result<Exclude<DataStreamFilterConfig, undefined>['filter']> => {
    if (fields.filters?.some((f) => !FilterValidationSchema.safeParse(f).success)) {
        return Result.fail('One or more filters are invalid');
    }

    const filters = cloneDeep(fields.filters || [])
        .filter(isDefined)
        .map((filter: Filter): FilterObject | undefined => {
            const configuredColumn = columns.find((v) => v.name === filter.column);
            const operationOptions = getFilterOperations(configuredColumn);
            const isStateColumn = configuredColumn?.shapeName === state.name;
            const v = isStateColumn ? filter.stateValue : filter.value;

            if (configuredColumn == null) {
                return undefined;
            }

            if (filter.operation == null || !operationOptions.some((o) => o.value === filter.operation)) {
                return undefined;
            }

            if (isValuelessFilterOperation(filter.operation)) {
                return {
                    column: configuredColumn?.name,
                    operation: filter.operation
                };
            }

            if (v == null) {
                return undefined;
            }

            return {
                column: configuredColumn?.name,
                operation: filter.operation,
                value: v
            };
        })
        .filter(isDefined);

    const multiOperation = fields?.multiOperation || 'and';

    return Result.success({
        filters,
        multiOperation
    });
};
