import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import * as Popover from '@radix-ui/react-popover';
import LoadingSpinner from 'components/LoadingSpinner';
import { ScrollArea } from 'components/scrollArea';
import { useAppContext } from 'contexts/AppContext';
import FilterStringData from 'lib/FilterStringData';
import { trackEventDebounced } from 'lib/analytics';
import { useWorkspaces } from 'queries/hooks/useWorkspaces';
import { useEffect, useMemo, useState } from 'react';
import { CreateWorkspaceButton } from './CreateWorkspaceButton';
import { OverviewLink } from './OverviewLink';
import { WorkspaceDropdownLink } from './WorkspaceDropdownLink';
import { WorkspaceDropdownOption } from './WorkspaceDropdownOption';
import { WorkspaceDropdownSearchInput } from './WorkspaceDropdownSearchInput';
import { WorkspaceDropdownTrigger } from './WorkspaceDropdownTrigger';

export const WorkspaceDropdown: React.FC = () => {
    const { currentWorkspaceID } = useAppContext();

    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    
    const { data: workspaces, isLoading } = useWorkspaces({
        select: (data) => data.sort((a, b) => a.displayName.localeCompare(b.displayName))
    });

    // TODO use deferred value for input when upgraded to react 18
    const filteredWorkspaces = useMemo(() => {
        if (search === '') {
            return workspaces || [];
        }
        
        return (workspaces)?.filter(
            FilterStringData((data) => data.displayName, search)
        ) ?? [];
    }, [search, workspaces]);

    // Side effect to track workspace searching
    useEffect(() => {
        if (search) {
          trackEventDebounced('Workspaces Searched', { search });
        }
      }, [search]);

    if (isLoading) {
        return <LoadingSpinner size={16} className='ml-3' />;
    }

    return (
        <div className='mr-px '>
            <Popover.Root
                open={open}
                onOpenChange={(o) => {
                    setSearch('');
                    setOpen(o);
                }}
            >
                <div className='flex items-stretch justify-center overflow-hidden border-b divide-x shrink-0 w-[250px] border-dividerPrimary divide-dividerPrimary'>
                    <WorkspaceDropdownLink />
                    <WorkspaceDropdownTrigger open={open} />
                </div>

                <Popover.Portal container={document.getElementById('navbar')}>
                    <Popover.Content
                        onCloseAutoFocus={(e) => e.preventDefault()}
                        sideOffset={0}
                        alignOffset={4}
                        align='end'
                        className='dropdownMenuContent bg-backgroundSecondary workspace-drop-down-animation relative flex flex-col py-1 w-[246px] mx-0.5 max-w-[var(--radix-dropdown-menu-content-available-width)] max-h-[var(--radix-dropdown-menu-content-available-height)] border shadow-lg focus:outline-none border-dividerPrimary'
                    >
                        {currentWorkspaceID && 
                            <OverviewLink onClick={() => setOpen(false)} />
                        }

                        {workspaces && workspaces.length > 0 && (
                            <WorkspaceDropdownSearchInput search={search} setSearch={setSearch} />
                        )}

                        {workspaces && workspaces.length === 0 && (
                            <Text.Label className='py-2 pt-3 pl-11 text-textIncomplete'>No workspaces</Text.Label>
                        )}

                        <ScrollArea className='flex flex-col flex-grow min-h-0'>
                            <div role='group' className={cn('flex-grow w-full max-h-96 max-w-[244px]', filteredWorkspaces.length && 'mt-xxxs')}>
                                {filteredWorkspaces.map((workspace) => (
                                    <WorkspaceDropdownOption 
                                        key={workspace.id} 
                                        workspace={workspace} 
                                        onClick={() => setOpen(false)} 
                                    />
                                ))}
                            </div>
                        </ScrollArea>

                        <CreateWorkspaceButton workspaceCount={workspaces?.length} setOpen={setOpen} />
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </div>
    );
};