import { useCallback, useEffect, useState } from 'react';

/**
 * Use this hook to access the position of the mouse.
 *
 */
export const useMousePosition = () => {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const changeMousePosition = useCallback(({ x, y }) => {
        setMousePosition({ x: x, y: y });
    }, []);

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            window.addEventListener('mousemove', changeMousePosition);
        }

        return () => {
            window.removeEventListener('mousemove', changeMousePosition);
            isMounted = false;
        };
    });
    return mousePosition;
};
