import { DataStreamFilterOption } from '../hooks/useDataStreamFilters';
import { orderBy } from 'lodash';
import { useState } from 'react';

type SortingMethod = (
    stream: DataStreamFilterOption[] | undefined,
    dataStreamId: string | undefined,
    recentStreams: string[] | undefined
) => DataStreamFilterOption[];

/**
 * Three sort orders
 * 1. Featured Streams prioritised
 * 2. Alphabetical by-plugin by-datastream
 * 3. Descending Alphabetical by-plugin by-datastream
 */
const sortOrders: SortingMethod[] = [
    (streams, dataStreamId, recentStreams) =>
        orderBy(streams, [
            (ds) => !(ds.id === dataStreamId),
            (ds) => {
                const streamIndex = recentStreams?.indexOf(ds.id) ?? -1;
                // Place anything is isn't recent after the recent index (at most five items, so at the sixth position)
                return streamIndex >= 0 ? streamIndex : 6;
            },
            (ds) => !ds.definition.featured,
            (ds) => ds.pluginName?.toLowerCase(),
            (ds) => ds.displayNameFull.toLowerCase()
        ]),
    (streams, dataStreamId) =>
        orderBy(
            streams,
            [
                (ds) => !(ds.id === dataStreamId),
                (ds) => ds.pluginName?.toLowerCase(),
                (ds) => ds.displayNameFull.toLowerCase()
            ],
            ['asc', 'asc', 'asc']
        ),
    (streams, dataStreamId) =>
        orderBy(
            streams,
            [
                (ds) => !(ds.id === dataStreamId),
                (ds) => ds.pluginName?.toLowerCase(),
                (ds) => ds.displayNameFull.toLowerCase()
            ],
            ['asc', 'desc', 'desc']
        )
];

export const useDataStreamSortOrder = () => {
    const [sortOrder, setSortOrder] = useState(0);

    const nextSortOrder = () => {
        setSortOrder(order => (order+1) % sortOrders.length);
    };

    const sort = sortOrders[sortOrder];

    return {
        sort,
        sortOrder,
        nextSortOrder
    };
};
