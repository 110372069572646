import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { GetTenantUserProperties } from 'services/UserService';
import { useForcedTheme } from './useForcedTheme';

export const THEME = 'theme';
export enum ThemeOptions {
    'light' = 'light',
    'dark' = 'dark',
    'system' = 'system'
}

const themeLocalStorageRef = 'SqUpTheme';
const systemThemeDataAttribute = 'data-theme';

export const getSystemTheme = () =>
    window?.matchMedia?.('(prefers-color-scheme: light)')?.matches ? ThemeOptions.light : ThemeOptions.dark;

export const useTheme = () => {
    const forcedTheme = useForcedTheme();

    const { data: theme } = useQuery(
        [THEME],
        ({ signal }) =>
            GetTenantUserProperties(signal).then(
                // If there is no theme in the database use the system theme
                (data) => (data?.theme as ThemeOptions) ?? ThemeOptions.system
            ),
        {
            enabled: !forcedTheme,
            placeholderData:
                (forcedTheme as ThemeOptions) ??
                (localStorage.getItem(themeLocalStorageRef) as ThemeOptions) ??
                ThemeOptions.system,
            cacheTime: Number.POSITIVE_INFINITY,
            staleTime: Number.POSITIVE_INFINITY
        }
    );

    useEffect(() => {
        if (!theme) {
            return;
        }

        // Persist theme in local storage for initial colours on app load (if not being forced by URL).
        if (!forcedTheme) {
            localStorage.setItem(themeLocalStorageRef, theme);
        }

        document.body.setAttribute(systemThemeDataAttribute, theme !== ThemeOptions.system ? theme : getSystemTheme());

        if (theme !== ThemeOptions.system) {
            return;
        }

        // If the user has selected to follow the system theme we attach an event listener and update as it changes.
        const handleSystemThemeChange = (event: MediaQueryListEvent) => {
            document.body.setAttribute(
                systemThemeDataAttribute,
                event.matches ? ThemeOptions.light : ThemeOptions.dark
            );
        };

        const systemThemeWatcher = window.matchMedia('(prefers-color-scheme: light)');
        systemThemeWatcher.addEventListener('change', handleSystemThemeChange);

        return () => {
            systemThemeWatcher.removeEventListener('change', handleSystemThemeChange);
        };
    }, [theme, forcedTheme]);

    // React query placeholder data forces theme to always be defined
    return !theme ? ThemeOptions.system : (theme as ThemeOptions);
};
