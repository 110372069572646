import { DASHBOARD_NODE } from 'components/map/components/nodes/DashboardNode';
import { GROUP_NODE, GROUP_NODE_SIZE } from 'components/map/components/nodes/GroupNode';
import { MONITOR_NODE } from 'components/map/components/nodes/MonitorNode';
import { OBJECT_NODE } from 'components/map/components/nodes/ObjectNode';
import { WORKSPACE_NODE } from 'components/map/components/nodes/WorkspaceNode';
import stringify from 'fast-json-stable-stringify';
import { groupBy } from 'lodash';
import pluralize from 'pluralize';
import { Edge } from 'reactflow';
import { PinnableNode } from '../types';

export const groupSizeThreshold = 3;

/**
 * When a node has lots of connected nodes it's best to group them based on the sourceType otherwise clarity is lost
 * We need to recursively call this function as grouped nodes may point to other grouped nodes
 */
export const rewriteLargeNodeGroups = (
    nodes: PinnableNode[],
    edges: Edge[],
    expandedNodeIds: string[],
    pinnedNodeIds: string[],
    pinnedGroupsWithMemberNodeIds: Map<string, string[]>,
    ungroupedNodeIds: string[]
): { nodes: PinnableNode[], edges: Edge[] } => {
    // We only want to group non-squp, non-pinned, non-expanded graph objects
    const eligibleTargetNodes = nodes
        .filter(({ id, type }) => 
            ![WORKSPACE_NODE, DASHBOARD_NODE].includes(type ?? '') &&
            !(pinnedNodeIds.includes(id) || expandedNodeIds.includes(id) || ungroupedNodeIds.includes(id))
        )
        .map(({ id }) => id);

    const eligibleSourceNodes = nodes
        .filter(({ id, type }) =>
            [MONITOR_NODE, OBJECT_NODE].includes(type ?? '') &&
            !(pinnedNodeIds.includes(id) || expandedNodeIds.includes(id) || ungroupedNodeIds.includes(id))
        )
        .map(({ id }) => id);

    const pinnedGroupMembers = [...pinnedGroupsWithMemberNodeIds.values()].flat();

    const nodeConnectionsByNodeId = edges.reduce((edgeConnectionCounter, { target, source }) => {
        if (
            eligibleTargetNodes.includes(target) && 
            (
                expandedNodeIds.includes(source) || 
                // If the source or target are group node members we want to add the connection, else they'll 
                // disappear when we collapse the map
                pinnedGroupMembers.includes(target) || pinnedGroupMembers.includes(source)
            )
        ) {
            edgeConnectionCounter.set(source, [...edgeConnectionCounter.get(source) ?? [], target]);
        }

        if (
            eligibleSourceNodes.includes(source) &&
            (
                expandedNodeIds.includes(target) || 
                // If the source or target are group node members we want to add the connection, else they'll 
                // disappear when we collapse the map
                pinnedGroupMembers.includes(source) || pinnedGroupMembers.includes(target)
            )
        ) {
            edgeConnectionCounter.set(target, [...edgeConnectionCounter.get(target) ?? [], source]);
        }

        return edgeConnectionCounter;
    }, new Map<string, string[]>());

    // Filter out nodes that don't have child counts exceeding the groupSizeThreshold
    [...nodeConnectionsByNodeId.entries()]
        .forEach(([parentNodeId, childNodeIds]) => {
            if (childNodeIds.length < groupSizeThreshold) {
                nodeConnectionsByNodeId.delete(parentNodeId);
            }
        });

    const rewrittenNodeIds = new Map<string, string>();

    const groupNodes = [...nodeConnectionsByNodeId.entries()]
        .reduce((groups, [parentNodeId, childNodeIds]) => {
            const connectedNodes = nodes.filter(({ id }) => childNodeIds.includes(id));

            // Group the connected nodes by type/sourceType
            const groupedConnectedNodes = groupBy(connectedNodes, (node) => node.data.type ?? node.data.sourceType);

            Object.entries(groupedConnectedNodes).forEach(([type, nodesInGroup]) => {
                const groupNodeId = `${parentNodeId}-${type.replace(/\s/gu, '')}-nodes`;

                // Filter out nodes that exist in another group or are currently members of a pinned group node
                const eligibleNodes = nodesInGroup.filter(node => 
                    !groups.some(({ data }) => data.sourceNodeIds?.includes(node.id)) &&
                    ![...pinnedGroupsWithMemberNodeIds.keys()]
                        .filter(key => key !== groupNodeId)
                        .some(pinnedGroup => pinnedGroupsWithMemberNodeIds.get(pinnedGroup)?.includes(node.id))
                );

                if (eligibleNodes.length >= groupSizeThreshold) {
                    const groupSourceTypes = eligibleNodes
                        .reduce((sourceTypes, { data }) => {
                            data.sourceType && sourceTypes.add(data.sourceType);
                            return sourceTypes;
                        }, new Set<string>());
 
                    groups.push({
                        id: groupNodeId,
                        position: { x: 0, y: 0 },
                        type: GROUP_NODE,
                        width: GROUP_NODE_SIZE,
                        height: GROUP_NODE_SIZE,
                        data: {
                            label: `${eligibleNodes.length} objects`,
                            sourceNodeIds: eligibleNodes.map(({ id }) => id),
                            pinned: pinnedNodeIds.includes(groupNodeId),
                            ...groupSourceTypes.size === 1 && { sourceType: [...groupSourceTypes.values()][0] },
                            ...(type !== 'undefined' && { type: pluralize(type, eligibleNodes.length) })
                        }
                    }); 

                    eligibleNodes.forEach(({ id }) => rewrittenNodeIds.set(id, groupNodeId));
                }
            });

            return groups;
        }, [] as PinnableNode[]);


    const rewrittenEdges = edges.reduce((acc: Edge[], edge) => {
        const rewrittenEdge = {...edge};
        
        const newsource = rewrittenNodeIds.get(edge.source);
        if (newsource) {
            rewrittenEdge.source = newsource;
        }
        // Rewrite any edges that point to a source ID
        const newtarget = rewrittenNodeIds.get(rewrittenEdge.target);
        if (newtarget) {
            rewrittenEdge.target = newtarget;
        }

        // Remove any duplicates and edges that now point to themselves
        if (
            rewrittenEdge.source !== rewrittenEdge.target &&
            !acc.find(({ target, source }) => 
                target === rewrittenEdge.target && 
                source === rewrittenEdge.source
            )
        ) {
            acc.push(rewrittenEdge);
        }
        return acc;
    }, []);

    const rewrittenNodes = [
        ...groupNodes,
        ...nodes.filter(({ id }) => !rewrittenNodeIds.has(id))
    ];

    if (stringify(rewrittenNodes) !== stringify(nodes)) {
        return rewriteLargeNodeGroups(
            rewrittenNodes,
            rewrittenEdges,
            expandedNodeIds,
            pinnedNodeIds,
            pinnedGroupsWithMemberNodeIds,
            ungroupedNodeIds
        );
    }

    return {
        nodes: rewrittenNodes,
        edges: rewrittenEdges
    };
};