import { ConfirmationPrompt } from 'components/ConfirmationPrompt';
import { streamDataKeys } from 'queries/queryKeys/streamDataKeys';
import { useQueryClient } from 'react-query';
import { Delete } from 'services/DataStreamDefinitionService';

type DataStreamDeleteModalProps = {
    dataStreamTitle: string;
    dataStreamId: string;
    close: (deleted?: boolean) => void;
};

function DataStreamDeleteModal({ dataStreamTitle, close, dataStreamId }: DataStreamDeleteModalProps) {
    const queryClient = useQueryClient();

    async function closeModal(performDelete?: boolean): Promise<void> {
        if (performDelete) {
            const success = await Delete(dataStreamId);
            if (success) {
                queryClient.removeQueries(streamDataKeys.forDataStream(`datastream-${dataStreamId}`), { exact: true });
            } else {
                // Deleting failed
                performDelete = false;
            }
        }
        close(performDelete);
    }

    return (
        <ConfirmationPrompt
            title={`Deleting ${dataStreamTitle}`}
            prompt='Are you sure you want to delete this data stream? This may impact other tiles also using this data stream and will reset the editor'
            onClose={() => closeModal()}
            onConfirm={() => closeModal(true)}
            confirmButtonText='Delete'
            confirmButtonVariant='destructive'
        />
    );
}

export default DataStreamDeleteModal;
