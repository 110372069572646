import { createContext, FC, useContext, useState } from 'react';
import { defaultSQL } from '../TileEditor/hooks/useDatasets';

interface DraftSQLContextValue {
    draftQuery: string;
    setDraftQuery: (newQuery: string) => void;
    hasQueryChanged: boolean;
}

export const DraftSQLContext = createContext<DraftSQLContextValue>({
    draftQuery: '',
    setDraftQuery: () => undefined,
    hasQueryChanged: false
});

DraftSQLContext.displayName = 'DraftSQLContext';

interface ProviderValue {
    initialQuery: string;
}

export const DraftSQLContextProvider: FC<ProviderValue> = ({ initialQuery, children }) => {
    const [draftQuery, setDraftQuery] = useState(initialQuery.length > 0 ? initialQuery : defaultSQL);
    const [hasQueryChanged, setHasQueryChanged] = useState(false);

    const changeSQLDraft = (newQuery: string) => {
        setHasQueryChanged(true);
        setDraftQuery(newQuery);
    };
    return (
        <DraftSQLContext.Provider
            value={{
                draftQuery,
                hasQueryChanged,
                setDraftQuery: changeSQLDraft
            }}
        >
            {children}
        </DraftSQLContext.Provider>
    );
};

export const useDraftSQLContext = () => useContext(DraftSQLContext);
