import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { SearchHelp } from 'components/SearchHelp';

interface SearchProps {
    placeholder: string;
    initialValue?: string;
    className?: string;
    showHelp?: boolean;
    onChange: (value: string) => void;
    testId?: string;
}

export const SearchBar: React.FC<SearchProps> = ({ placeholder, initialValue, className = '', showHelp, onChange, testId }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = e.target.value;
        e.stopPropagation();
        onChange(searchTerm);
    };

    return (
        <div
            className={clsx(
                'flex flex-row items-center w-full overflow-hidden border-[1px] px-4 py-2.5 border-dividerPrimary rounded bg-transparent ring-transparent ring-0 focus:ring-0 focus:outline-none focus:border-dividerPrimary',
                className
            )}
            data-testid='SearchBar'
        >
            <FontAwesomeIcon icon={faMagnifyingGlass} className='mr-2' />
            <input
                type='text'
                className='flex-1 min-w-0 p-0 m-0 bg-transparent border-none outline-none placeholder-textIncomplete center focus:ring-0'
                placeholder={placeholder}
                onChange={handleChange}
                // Stop keydown propagation to prevent radix dropdowns from jumping to dropdown items
                onKeyDown={(e) => e.stopPropagation()}
                defaultValue={initialValue || ''}
                autoFocus={true}
                autoComplete='off'
                data-testid={testId}
            />
            
            {showHelp && <SearchHelp />}
        </div>
    );
};
