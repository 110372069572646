import { Pill } from 'components/pill/Pill';
import Tooltip from 'components/tooltip/Tooltip';

export const PluginPill = ({ onPrem }: { onPrem?: boolean }) => {
    if (!onPrem) { 
        return null;
    }
    return (
        <Tooltip title='This data source requires a relay agent group.'>
            <Pill className='text-xs text-textSecondary border-dividerPrimary' variant='outline'>
                On-Prem
            </Pill>
        </Tooltip>
    );
};
