import { Button } from '@/components/Button';
import DropdownMenu from '@/components/DropdownMenu';
import { faCircleNotch, faEllipsisVertical, faFileExport, faImage } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { useWorkspacePermissions } from 'components/hooks/useWorkspacePermissions';
import { useAppContext } from 'contexts/AppContext';
import { FullScreenTileConfig, useFullscreenContext } from 'contexts/FullscreenContext';
import { useDataStreamFromTileConfig } from 'dashboard-engine/hooks/useDataStreamFromTileConfig';
import { downloadTileAsImage } from 'dashboard-engine/util/dashboardImageSnapshots';
import { isOpenAccess } from 'lib/openAccessUtil';
import { useMutation } from 'react-query';
import { CopyData } from 'ui/tile/actions/CopyData';
import { Explore } from 'ui/tile/actions/Explore';

interface FullScreenTileMenuProps {
    isShowingUnshapedData: boolean;
}

export const FullscreenTileMenu: React.FC<FullScreenTileMenuProps> = ({ isShowingUnshapedData }) => {
    const { fullscreenConfig, setIsCopyingTo } = useFullscreenContext();

    const { health, ...config } = fullscreenConfig as FullScreenTileConfig;

    const { isNarrowLayout } = useAppContext();
    const { canWriteToAtLeastOneWorkspace } = useWorkspacePermissions();
    const canWriteToWorkspace = canWriteToAtLeastOneWorkspace();
    const { isConfigured } = useDataStreamFromTileConfig(config as DataStreamBaseTileConfig);

    const { mutateAsync: download, isLoading: isDownloadingImage } = useMutation(() =>
        downloadTileAsImage('fullscreenTile', fullscreenConfig?.title, true)
    );

    const menuContainer = useDOMElement('dialogContent');

    return (
        <DropdownMenu>
            <DropdownMenu.Trigger>
                <Button
                    variant='secondary'
                    disabled={isDownloadingImage}
                    icon={
                        <FontAwesomeIcon
                            className='flex items-center text-xl'
                            icon={isDownloadingImage ? faCircleNotch : faEllipsisVertical}
                            {...(isDownloadingImage && { spin: true })}
                        />
                    }
                />
            </DropdownMenu.Trigger>
            <DropdownMenu.Menu
                menuId='fullscreenTileMenu'
                className='divide-y divide-outlinePrimary'
                container={menuContainer}
            >
                {!isOpenAccess() && !isNarrowLayout && (
                    <DropdownMenu.Group>
                        <Explore />
                        {canWriteToWorkspace && (
                            <DropdownMenu.Item
                                icon={<FontAwesomeIcon icon={faFileExport} fixedWidth />}
                                onSelect={() => setIsCopyingTo(true)}
                                key='copyTo'
                            >
                                Copy to
                            </DropdownMenu.Item>
                        )}
                    </DropdownMenu.Group>
                )}
                <DropdownMenu.Group>
                    {isConfigured && (
                        <DropdownMenu.Item
                            icon={<FontAwesomeIcon icon={faImage} fixedWidth />}
                            onSelect={() => download()}
                            key='export'
                        >
                            Export
                        </DropdownMenu.Item>
                    )}
                    <CopyData useUnshaped={isShowingUnshapedData} />
                </DropdownMenu.Group>
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
};
