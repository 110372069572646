import Config from '../config';
import { ConfigEnvironment } from './configTypes';

/**
 * Get a direct link to the S3 bucket that stores the client resources.
 *
 * Normally we don't use this - we load the client resources via a DNS alias (e.g. app.squaredup.com),
 * but in some circumstances the underlying S3 URL is required.
 */
export function getClientS3BucketUrl(config = Config, locationHostName = window.location.hostname) {
    const env = config.Environment as ConfigEnvironment;

    const s3Domain = env === 'ProductionEU' ? 'https://s3.eu-west-1.amazonaws.com' : 'https://s3.amazonaws.com';

    const bucket =
        env === 'Local' || env === 'Development'
            ? `${config.Stage}.dev.app.squaredup.com`
            : `${config.Stage}.${locationHostName}`;

    return `${s3Domain}/${bucket}`;
}
