import { stateStrings } from '@squaredup/monitoring';
import DropdownMenu from 'components/dropdownMenu';
import { healthStatesEnum } from 'constants/state';
import SortMenu from 'pages/status/ui/SortMenu';
import StateFilter from 'pages/status/ui/StateFilterMenu';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

export const HealthPanelViewsDropdown = () => {
    const saveSelectedView = useHealthPanelSaveSelectedView();
    const selectedStatus = useHealthPanelStatus();
    const selectedView = useHealthPanelSelectedView();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <DropdownMenu onOpenChange={setIsDropdownOpen}>
            <DropdownMenu.Button
                className='capitalize whitespace-nowrap'
                showChevron
                useFilterStyle
                dropdownOpen={isDropdownOpen}
            >
                {selectedView}
            </DropdownMenu.Button>
            <DropdownMenu.Menu align='end'>
                {healthPanelviewSchema.options.map((view) => (
                    <DropdownMenu.CheckItem
                        key={view}
                        checked={selectedView === view}
                        onSelect={() => {
                            if (view === 'monitors' && selectedStatus?.includes(stateStrings.unmonitored)) {
                                searchParams.set('selected-view', view);
                                searchParams.set('status', selectedStatus.filter((state) => state !== stateStrings.unmonitored).join(','));
                                return setSearchParams(searchParams);
                            }
                            
                            saveSelectedView.mutate(view);
                        }}
                        className='capitalize'
                    >
                        {view}
                    </DropdownMenu.CheckItem>
                ))}
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
};

const healthPanelStatusSchema = healthStatesEnum.removeCatch().array();
type HealthPanelStatus = z.infer<typeof healthPanelStatusSchema>;

export const HealthPanelStatusDropdown = () => {
    const healthPanelStatus = useHealthPanelStatus();
    const saveHealthPanelStatus = useHealthPanelSaveStatus();
    const selectedView = useHealthPanelSelectedView();

    return (
        <StateFilter 
            states={healthPanelStatus} 
            setStates={saveHealthPanelStatus.mutate} 
            menuProps={{ align: 'end' }} 
            hideUnmonitored={selectedView === 'monitors'}
        />
    );
};

export const useHealthPanelStatus = () => {
    const [searchParams] = useSearchParams();

    try {
        const unsafeStatus = searchParams.get('status')?.split(',');
        return healthPanelStatusSchema.parse(unsafeStatus);
    } catch {
        return undefined;
    }
};

const useHealthPanelSaveStatus = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const mutate = (states: HealthPanelStatus) => {
        searchParams.set('status', states.join(','));
        setSearchParams(searchParams);
    };

    return { mutate };
};

const healthPanelSortSchema = z.object({
    value: z.enum(['state', 'name']),
    direction: z.enum(['asc', 'desc'])
});
type HealthPanelSort = z.infer<typeof healthPanelSortSchema>;

export const HealthPanelSortDropdown = () => {
    const selectedSort = useHealthPanelSort();
    const saveSelectedSort = useHealthPanelSaveSort();

    return <SortMenu sort={selectedSort} setSort={saveSelectedSort.mutate} />;
};

export const useHealthPanelSort = () => {
    const [searchParams] = useSearchParams();
    const defaultSort: HealthPanelSort = { direction: 'desc', value: 'state' };

    try {
        const unsafeSort = searchParams.get('sort');

        if (!unsafeSort) {
            return defaultSort;
        }

        const [unsafeValue, unsafeDirection] = unsafeSort.split(':');

        return healthPanelSortSchema.parse({ value: unsafeValue, direction: unsafeDirection });
    } catch {
        return defaultSort;
    }
};

const useHealthPanelSaveSort = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const mutate = (sort: HealthPanelSort) => {
        searchParams.set('sort', `${sort.value}:${sort.direction}`);
        setSearchParams(searchParams);
    };

    return { mutate };
};

export const healthPanelviewSchema = z.enum(['monitors', 'dashboards']);
type HealthPanelView = z.infer<typeof healthPanelviewSchema>;

export const useHealthPanelSelectedView = () => {
    const [searchParams] = useSearchParams();

    try {
        return healthPanelviewSchema.parse(searchParams.get('selected-view'));
    } catch {
        return healthPanelviewSchema.options[0];
    }
};

//NOTE: for now saving selected view in the url as where
//      should the selected view be saved is TBD
const useHealthPanelSaveSelectedView = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const mutate = (view: HealthPanelView) => {
        searchParams.set('selected-view', view);
        setSearchParams(searchParams);
    };

    return { mutate };
};
