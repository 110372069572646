import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { DetailedHTMLProps, FC, forwardRef, HTMLAttributes, ReactNode } from 'react';
import { NavLink, To } from 'react-router-dom';

interface NavTitleProps extends DetailedHTMLProps<Omit<HTMLAttributes<HTMLDivElement>, 'title'>, HTMLDivElement> {
    title: ReactNode;
    icon?: IconProp;
    iconClassName?: string;
    testId?: string;
}

export const NavTitle: FC<NavTitleProps> = ({ onClick, title, icon, className, iconClassName, ...rest }) => {
    return (
        <div
            className={cn(
                'flex items-center',
                onClick && 'cursor-pointer hover:text-textPrimary',
                className
            )}
            onClick={onClick}
            {...rest}
        >
            {icon && <FontAwesomeIcon fixedWidth icon={icon} className={cn('mr-3 shrink-0 text-[12px] ml-1', iconClassName)} />}
            <Text.H4 className='ml-px'>{title}</Text.H4>
        </div>
    );
};

interface NavTitleLinkProps extends Omit<NavTitleProps, 'title'> {
    to: To;
    children: ReactNode;
}

export const NavTitleLink: FC<NavTitleLinkProps> = ({ to, children, className, testId, ...rest }) => {
    return (
        <NavTitle
            title={
                <NavLink
                    className={({ isActive }) =>
                        cn('inline-flex items-center', isActive ? 'text-textPrimary' : 'hover:text-textPrimary', className)
                    }
                    to={to}
                    data-testid={testId ?? 'navTitleLink'}
                >
                    {children}
                </NavLink>
            }
            {...rest}
        />
    );
};

interface NavListWrapperProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    children: ReactNode;
}

export const NavListWrapper = forwardRef<HTMLDivElement, NavListWrapperProps>(
    ({ children, className, ...rest }, ref) => {
        return (
            <div ref={ref} className={clsx('text-textSecondary', className)} {...rest}>
                {children}
            </div>
        );
    }
);
