//      |  <--- Grid line
//      |_ <--- Graph ends
//      |  <--- GridLineExtension component to extend the grid line
//    {tick}

/**
 * @returns An SVG line that extends the grid line of the parent graph to connect grid line to the tick value
 *
 */
export const GridLineExtension = () => {
    return (
        <line
            x1={0} // xN when equal positions line left/right, when unequal it rotates the line
            x2={0}
            y1={-25} // yN controls height and position of line
            y2={-20}
            style={{ stroke: '#818C9C', strokeWidth: 1 }}
        />
    );
};
