import { fiveMinutes } from 'queries/constants';
import { datasourceQueryKeys } from 'queries/queryKeys/datasourceKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { GetLatestMajorVersions } from 'services/PluginService';

type QueryReturnType = Awaited<ReturnType<typeof GetLatestMajorVersions>>;

/**
 * useQuery returning the latest datasources available for adding
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of all datasources in a tenant
 */
export const useLatestAvailableDatasources = <T = QueryReturnType>(
    options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>
) => 
    useQuery(
        datasourceQueryKeys.latest, 
        GetLatestMajorVersions, 
        {
            staleTime: fiveMinutes,
            cacheTime: fiveMinutes,
            keepPreviousData: true,
            ...options
        }
    );
