import * as Constants from '@squaredup/constants';
import { Serialised } from '@squaredup/ids';
import { handleError, handleResponse } from './util';

import type { AccessControlEntryModel, Config, ProjectedConfig } from 'dynamo-wrapper';
import { QueryClient } from 'react-query';
import API from './API';
import { AccessControlQueryKeys } from './AccessControlService';
import { getCurrentWorkspaceId } from './WorkspaceUtil';
import { datasourceConfigQueryKeys } from 'queries/queryKeys/datasourceConfigKeys';

export type PluginSourceConfig = Serialised<ProjectedConfig> & {
    label?: 'Workspace' | 'Plugin';
    source: 'WORKSPACE' | 'PLUGIN';
    description: string;
    plugin?: {
        pluginId?: string;
        displayName?: string;
        name?: string;
        onPrem?: boolean;
        importNotSupported?: boolean;
    };
};

/*
 * react-query key for plugin data source configs
 */
export const PLUGIN_SOURCES = 'pluginsources';
export const PLUGIN_WORKSPACE_LINKS = 'PLUGIN_WORKSPACE_LINKS';

export const Get = async (id: string) => {
    return API.get<Serialised<Config> | undefined>(`/datasources/${id}`).then(handleResponse).catch(handleError);
};


export const ListPluginSourceConfigs = async () => listPluginSourceConfigs();

/**
 * @deprecated use the hook useDatasourceConfigsForWorkspace to get this data
 */
export const ListPluginSourceConfigForWorkspace = async (workspaceId: string) =>
    listPluginSourceConfigs({
        workspaceId
    });

export const ListPluginSourceConfigsForAdmin = async () => listPluginSourceConfigs({ administration: true });

export const ListPluginSourceConfigsForLinking = async (workspaceId?: string) =>
    listPluginSourceConfigs({
        linking: true,
        linkingWorkspaceId: workspaceId
    });

/**
 * @deprecated use the hook useDatasourceConfigs to get this data
 */
export const ListPluginSourceConfigsForAllWorkspaces = async () =>
    listPluginSourceConfigs({
        allWorkspaces: true
    });

interface PluginConfigsUsage {
    /**
     * The configs will be used for administration, e.g. the Plugins settings page in the UI.
     * For tenant admins this will be the full list of plugin configs, not just those the admin
     * has access to via standard access control.
     */
    administration?: boolean;

    /**
     * The configs will be used as options for workspace linking (i.e. will include configs the
     * user has direct access to as well as any configs already linked to the workspace)
     */
    linking?: boolean;

    /**
     * If linking === true, this is the workspace that the configs can be linked to.
     */
    linkingWorkspaceId?: string;

    /**
     * If true, we want configs for all workspaces the user has access to
     * (i.e. access control mode 'directOrAnyWorkspaceLinks')
     */
    allWorkspaces?: boolean;
    /**
     * workspaceId is used to filter the configs to those that are linked to the workspace
     * This overrides the workspaceId if linking and linkingWorkspaceId aren't true
     *
     */
    workspaceId?: string;
}

const listPluginSourceConfigs = async (usage: PluginConfigsUsage = {}) => {
    const workspaceId =
        usage.linking && usage.linkingWorkspaceId
            ? usage.linkingWorkspaceId
            : usage.workspaceId ?? getCurrentWorkspaceId();

    const pluginConfigs = await API.get<Serialised<ProjectedConfig>[]>('/datasources', {
        params: {
            type: Constants.Sources.PLUGIN.type,
            forAdministration: usage.administration,
            forLinking: usage.linking,
            workspaceId: workspaceId && !usage.administration && !usage.allWorkspaces ? workspaceId : undefined,
            allWorkspaces: usage.allWorkspaces
        }
    })
        .then(handleResponse)
        .catch(handleError);

    return pluginConfigs
        .map((pluginConfig): PluginSourceConfig => {
            const subtype = Constants.getDataSourceForSubType(pluginConfig.subType);
            return {
                ...pluginConfig,
                label: subtype?.label,
                source: Constants.getDataSourceNameForSubType(pluginConfig.subType),
                description: pluginConfig.displayName ?? ''
            };
        })
        .sort((a, b) => a.displayName?.localeCompare(b.displayName ?? '') ?? -1);
};

export const GetCountOfDataSourceConfigs = async () => {
    const count = await API.get<{ count: number }>('/usage/current/datasources').then(handleResponse).catch(handleError);
    return count.count;
};

export const Create = async (
    displayName: string,
    config: any,
    plugin: object,
    agentGroupId?: string,
    linkToWorkspaces?: boolean,
    acl?: AccessControlEntryModel[],
    draftConfigId?: string
) => {
    const created = await API.post('/datasources', {
        displayName,
        config,
        plugin,
        agentGroupId,
        linkToWorkspaces,
        acl,
        draftConfigId
    })
        .then(handleResponse)
        .catch(handleError);

    return created.id;
};

export const Update = async (
    id: string,
    displayName: string,
    config: any,
    agentGroupId?: string,
    acl?: AccessControlEntryModel[],
    draftConfigId?: string
) => {
    await API.put(`/datasources/${id}`, { displayName, config, agentGroupId, acl, draftConfigId })
        .then(handleResponse)
        .catch(handleError);
    return id;
};

export const Import = async (id: string) => {
    await API.post(`/datasources/${id}/import`).then(handleResponse).catch(handleError);
    return true;
};

export const Delete = async (id: string) => {
    const success = await API.delete(`/datasources/${id}`)
        .then(handleResponse)
        .then(() => true)
        .catch(handleError)
        .catch(() => false);
    return success;
};

export const InvalidatePluginConfigQueries = async (queryClient: QueryClient) => {
    await Promise.all([
        queryClient.invalidateQueries(datasourceConfigQueryKeys.all),
        queryClient.invalidateQueries(AccessControlQueryKeys.EntityTypePermissions('config'))
    ]);
};

export const GetWorkspaceLinks = async (configId: string) => {
    return API.get<string[]>(`/datasources/${configId}/links`).then(handleResponse).catch(handleError);
};

export const DeleteAllWorkspaceLinks = async (configId: string) => {
    return API.delete(`/datasources/${configId}/links`).then(handleResponse).catch(handleError);
};
