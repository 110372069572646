import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import Handlebars from 'handlebars';

/**
 * Register the raw helper so users can turn off Handlebars as needed
 */
Handlebars.registerHelper('raw', function(options) {
    return options.fn();
});

/**
 * Render the given template & view data to string
 * @param template Template to render
 * @param view Data for view
 */
const render = (template: string, view: any): string => Handlebars.compile(template)(view);

/**
 * Resolves label from data
 * Fallsback to label value
 * @param {any} data Data object/array
 * @param {string} template Template to resolve
 */
export const resolveLabel = (template: string, data: any, context: object, defaultValue = template) => {
    if(!template || template.indexOf('{{') === -1) {
        return defaultValue;
    }

    return render(template, { data, context }) || defaultValue;
};

/**
 * Recursively walk the config object, rendering any strings as mustache templates
 * @param config Config object
 * @param view View data
 */
export const deepResolveLabels = (config: any, view: any) => {
    const cloned = cloneDeep(config);
    return traverse(cloned, (v: string) => render(v, view));
};

/**
 * Recursively walk the an object, invoking the given function on any strings
 * @param config Target object to walk
 * @param skipKeys Keys to be skipped
 * @param func Function to invoke
 */
export const traverse = (target: any, func: (v: string) => any) => {
    if (target && typeof target === 'object') {
        each(target, (value: any, k: string) => {
            if (typeof value === 'string') { // If this key isn't being skipped and it is a string
                target[k] = func(target[k]);
            }
            if (typeof value === 'object' && value != null) {
                traverse(value, func);
            }
        });
    }
    return target;
};