import { IconDefinition, IconName } from '@fortawesome/pro-solid-svg-icons';

export const stackedBar: IconDefinition = {
    iconName: 'stacked-bar' as IconName,
    prefix: 'fas', // solid icon
    icon: [
        512, // viewBox width
        512, // viewBox height
        [], // ligatures
        'f269', // unicode codepoint - private use area
        'M32.002 32C49.0021 32 64.004 47.0019 64.004 64.002V399.996C64.004 408.993 71.0028 415.992 79.9997 415.992H479.998C496.998 415.992 512 430.994 512 447.994C512 465.998 496.998 479.996 479.998 479.996H79.9997C35.0045 479.996 0 444.991 0 399.996V64.002C0 47.0019 13.9975 32 32.002 32ZM192.001 64.002C192.001 46.3278 177.674 32 159.999 32C142.325 32 127.997 46.3278 127.997 64.002V212.387C127.997 230.061 142.325 244.389 159.999 244.389C177.674 244.389 192.001 230.061 192.001 212.387V64.002ZM255.995 32C273.669 32 287.997 46.3278 287.997 64.002V180.385C287.997 198.059 273.669 212.387 255.995 212.387C238.32 212.387 223.993 198.059 223.993 180.385V64.002C223.993 46.3278 238.32 32 255.995 32ZM384.003 64.002C384.003 46.3278 369.675 32 352.001 32C334.326 32 319.999 46.3278 319.999 64.002V142.442C319.999 160.116 334.326 174.444 352.001 174.444C369.675 174.444 384.003 160.116 384.003 142.442V64.002ZM352.001 218.05C369.675 218.05 384.003 232.378 384.003 250.052V319.997C384.003 337.671 369.675 351.999 352.001 351.999C334.326 351.999 319.999 337.671 319.999 319.997V250.052C319.999 232.378 334.326 218.05 352.001 218.05ZM287.997 288.006C287.997 270.331 273.669 256.003 255.995 256.003C238.32 256.003 223.993 270.331 223.993 288.006V319.997C223.993 337.672 238.32 352 255.995 352C273.669 352 287.997 337.672 287.997 319.997V288.006ZM160 287.995C177.674 287.995 192.002 302.323 192.002 319.997C192.002 337.671 177.674 351.999 160 351.999C142.325 351.999 127.997 337.671 127.997 319.997C127.997 302.323 142.325 287.995 160 287.995ZM479.999 319.997C479.999 302.323 465.671 287.995 447.996 287.995C430.322 287.995 415.994 302.323 415.994 319.997C415.994 337.671 430.322 351.999 447.996 351.999C465.671 351.999 479.999 337.671 479.999 319.997ZM447.996 32C465.67 32 479.998 46.3278 479.998 64.002V212.387C479.998 230.061 465.67 244.389 447.996 244.389C430.322 244.389 415.994 230.061 415.994 212.387V64.002C415.994 46.3278 430.322 32 447.996 32Z' // path
    ]
};
