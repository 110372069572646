import clsx from 'clsx';
import { useOpenAccessBackground } from 'pages/openAccess/useOpenAccessBackground';
import { PoweredByLogo } from '../../components/PoweredByLogo';

export const OpenAccessLoadingSpinner: React.FC<{
    message?: string;
}> = ({ message = 'Loading...' }) => {
    const backgroundClass = useOpenAccessBackground();
    return (
        <div
            className={clsx(
                'flex flex-col items-center justify-center w-full h-full space-y-10 text-textPrimary',
                backgroundClass
            )}
        >
            <PoweredByLogo isLoading={true} loadingMessage={message} />
        </div>
    );
};
