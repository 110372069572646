import { DataStreamBaseTileConfig, DataStreamScope, resolveScopeToTargetNodes } from '@squaredup/data-streams';
import { Node } from '@squaredup/graph';
import { ConfigId } from '@squaredup/ids';
import { isDefined } from '@squaredup/utilities';
import { groupBy } from 'lodash';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { useQueries } from 'react-query';
import { Query } from 'services/GraphService';
import { NO_ACTIVE_WORKSPACE } from 'services/WorkspaceUtil';

export const useTileDatasources = (config: DataStreamBaseTileConfig, workspaceId?: string | null) => {
    const isGlobal = workspaceId === NO_ACTIVE_WORKSPACE;
    const isSql = Boolean(config?.dataStream?.dataSourceConfig?.sql?.length);

    const tileDataStreamDatasources = (
        isSql ?
            config?.dataStream?.dataSourceConfig?.tables?.map(
                tableConfig => tableConfig?.config?.dataStream?.pluginConfigId
            ) :
            [config.dataStream?.pluginConfigId]
    )?.filter(isDefined) ?? [];

    const tileScopes = isSql ? 
        config?.dataStream?.dataSourceConfig?.tables?.map(tableConfig => tableConfig?.config?.scope) :
        [config.scope];

    const tileScopeDatasourcesQueries = useQueries(
        tileScopes
            ?.filter(tileScope => tileScope !== undefined)
            .map((tileScope) => {
                return {
                    queryFn: async () => resolveScopeToTargetNodes(
                        (query) => Query(query, isGlobal ? 'directOrAnyWorkspaceLinks' : 'workspaceLinks')
                    )(tileScope as DataStreamScope),
                    queryKey: scopeQueryKeys.scopeContentToObjects(tileScope as DataStreamScope, workspaceId),
                    select: (nodes: Node[]) => {
                        return Object.keys(
                            groupBy(nodes, ({ __configId }) => Array.isArray(__configId) ? __configId[0] : __configId)
                        );
                    }
                };
            }) ?? []
    );

    const tileScopeDatasources = tileScopeDatasourcesQueries.every(query => query.isFetched) ? 
        tileScopeDatasourcesQueries.reduce((scopeConfigIds, { data }) => {
            if (data) {
                scopeConfigIds.push(...data as ConfigId['value'][]);
            }
            return scopeConfigIds;
        }, [] as ConfigId['value'][]) : [];

    return [...new Set([...tileDataStreamDatasources, ...tileScopeDatasources])];
};