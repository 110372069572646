import * as Constants from '@squaredup/constants';

const internalPropSet = new Set(Object.keys(Constants.internalPropsCallerMustNotSupply));

export function allowProp(prop: string, propBlackList: string[] = [], propWhiteList: string[] = []) {
    if (propBlackList.includes(prop)) {
        return false;
    }
    if (propWhiteList.includes(prop)) {
        return true;
    }
    if (internalPropSet.has(prop) || prop.startsWith('__')) {
        return false;
    }
    return true;
}
