import { FormattedStreamValue, StreamDataColumn } from '@squaredup/data-streams';
import { range, sum } from 'lodash';
import { ResizedColumns } from './types';

const maxWidth = 500;
const minWidth = 70;
const cellPadding = 28;
const charWidth = 8.125;

export const getColumnSizes = (
    columns: StreamDataColumn[],
    rows: FormattedStreamValue[][],
    resizes?: ResizedColumns
) => {
    const rowLength = rows.length;
    const sampleRows = range(0, Math.min(rowLength, 30));

    return columns.reduce((columnWidths, { name, displayName }, columnIndex) => {
        // If there is a set width in the visualisation config use that
        if (resizes?.columnWidths[name]) {
            columnWidths[name] = Math.max(resizes.columnWidths[name], minWidth);
            return columnWidths;
        }

        const columnHeaderWidth = Math.max(minWidth, Math.ceil(displayName.length * charWidth + cellPadding));

        // Get widest value in sample rows. If there is only one row just return that value's width
        let widestValue = Math.ceil(rows?.[0]?.[columnIndex].formatted.length * charWidth + cellPadding);
        if (sampleRows.length > 1) {
            widestValue = sampleRows.reduce((rowMax, rowIndex) =>
                Math.max(rowMax, Math.ceil(rows[rowIndex][columnIndex].formatted.length * charWidth + cellPadding))
            );
        }

        // Set the column width to the larger of the widest row or the header
        columnWidths[name] = Math.min(maxWidth, Math.max(minWidth, columnHeaderWidth, widestValue));
        return columnWidths;
    }, {} as Record<string, number>);
};

// TODO: Remove this once we switch the scoping panel
export const columnsWidths = (rows: string[][], size: number, columnMins: number[]): number[] => {
    const columns = rows.length;
    const rowLength = rows[0].length;
    const sample = rowLength > 100 ? 100 : rowLength;
    const textWidths: number[] = new Array(columns).fill(0);
    for (let index = 0; index < sample; index++) {
        let rowIndex = index;
        for (let column = 0; column < columns; column++) {
            if (rowLength > 100) {
                rowIndex = Math.floor(Math.random() * rowLength);
            }

            textWidths[column] = Math.max(rows[column][rowIndex].length * 10, textWidths[column]);
        }
    }

    if (sum(textWidths) < size) {
        return textWidths.map((width, index) => Math.max(width, columnMins[index]));
    }
    let totalWidth = size;
    let average = size / columns;
    const indexes: number[] = Array.from(Array(columns).keys());
    let hasSmallerColumns = true;
    while (hasSmallerColumns && indexes) {
        hasSmallerColumns = false;
        average = totalWidth / indexes.length;
        for (let i = 0; i < indexes.length; i++) {
            if (textWidths[indexes[i]] < average) {
                hasSmallerColumns = true;
                totalWidth = totalWidth - textWidths[indexes[i]];
                indexes.splice(i, 1);
            }
        }
        if (!hasSmallerColumns) {
            for (const index in indexes) {
                if (Object.prototype.hasOwnProperty.call(indexes, index)) {
                    textWidths[index] = average;
                }
            }
        }
    }

    return textWidths.map((width, index) => Math.max(width, columnMins[index]));
};
