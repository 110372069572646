import { DashboardIdRegex } from '@squaredup/ids';
import { HealthState } from '@squaredup/monitoring';
import { oneMinute } from 'queries/constants';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { Query } from 'services/GraphService';
import { useDashboards } from './useDashboards';

export interface MonitorWithHealth {
    tileId: string;
    workspaceId: string;
    state: HealthState;
    dashId: string;
    dashName: string;
    tileName: string;
}

type QueryReturnType = MonitorWithHealth[];

export const useMonitorsWithHealth = <T = QueryReturnType>({
    enabled = true,
    ...options
}: UseQueryOptions<QueryReturnType, unknown, T, string[]> = {}) => {
    const { data: dashboards } = useDashboards({
        enabled,
        select: (data) => data.filter(({ id }) => id.match(DashboardIdRegex))
    });

    const dashboardIds = dashboards?.map(({ id }) => id);

    return useQuery(
        dashboardQueryKeys.monitorsForDashboard(dashboardIds),
        async () => {
            const { gremlinQueryResults } = await Query({
                gremlinQuery:
                    'g.V().has("sourceType", "squaredup/monitor").has("dashId", within(dashboardIds)).valueMap("tileId", "dashId", "state", "tileName", "dashName")',
                bindings: { dashboardIds }
            });

            return gremlinQueryResults.map(({ dashId, state, tileId, tileName, dashName }) => ({
                state: state[0],
                dashId: dashId[0],
                tileId: tileId[0],
                tileName: tileName[0],
                dashName: dashName[0],
                workspaceId: dashboards?.find((dash) => dash.id === dashId[0])?.workspaceId!
            }));
        },
        {
            enabled: Boolean(dashboardIds?.length) && enabled,
            staleTime: oneMinute,
            cacheTime: oneMinute,
            refetchInterval: oneMinute,
            ...options
        }
    );
};
