import { cn } from '@/lib/cn';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

type ChoiceChipButtonProps = {
    option: { value: string; label: string };
    disabled?: boolean;
    handleClick: (value: string) => void;
} & (
    | { isMulti: true; currentValue: string[] }
    | {
          isMulti?: false;
          currentValue: string;
      }
);

export const ChoiceChipButton: React.FC<ChoiceChipButtonProps> = ({
    isMulti,
    option,
    currentValue,
    disabled,
    handleClick
}) => {
    const isEnabled = isMulti ? currentValue.includes(option.value) : currentValue === option.value;

    return (
        <button
            className={clsx(
                'px-sm py-inputWithBorder align-top bg-componentBackgroundPrimary border rounded border-outlinePrimary text-textPrimary leading-input disabled:pointer-events-none disabled:bg-secondaryButtonBackgroundDisabled disabled:text-secondaryButtonTextDisabled',
                {
                    'border-outlineSecondary': isEnabled
                }
            )}
            type='button'
            disabled={disabled}
            onClick={() => handleClick(option.value)}
        >
            <div className='flex flex-row items-center space-x-2'>
                {isMulti && (
                    <div
                        className={cn(
                            'flex items-center justify-center w-4 h-4 rounded-sm border border-outlineSecondary',
                            {
                                'bg-primaryButtonBackground': isEnabled,
                                'border-primaryButtonBackground': isEnabled
                            }
                        )}
                    >
                        {isEnabled && (
                            <FontAwesomeIcon icon={faCheck} size='xs' className='text-textPrimary' data-theme='dark' />
                        )}
                    </div>
                )}
                <span>{option.label}</span>
            </div>
        </button>
    );
};
