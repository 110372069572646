/**
 * Function to generate a filter function for seraching filtering arrays of strings
 * Example ussage in WorkspaceSelect.tsx
 * Try to use the term as a deferred value
 */
function FilterStringData<TData>(getString: (data: TData) => string, term: string) {
    const mTerm = term.toLowerCase().replaceAll(' ', '');
    return (data: TData) => getString(data).toLowerCase().replaceAll(' ', '').includes(mTerm);
}

export default FilterStringData;
