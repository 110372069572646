/* eslint-disable max-len */
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from './tooltip/Tooltip';
import './SearchHelp.css';

export interface SearchHelpProps {
    forGlobalSearch?: boolean;
}

export function SearchHelp({ forGlobalSearch = false }: SearchHelpProps) {
    return (
        <Tooltip
            className='ml-4'
            title={
                <div className='px-3 py-2'>
                    <div>Search</div>
                    <hr className='mt-1 mb-2 border-dividerPrimary' />
                    <div className='text'>
                        <div className='mb-1'>
                            Enter search terms with <Em> AND</Em>, <Em> OR</Em>, or <Em> NOT </Em> operators if
                            required.
                        </div>
                        <div className='mb-1'>
                            Use <Em>double quotes</Em> for phrases and <Em>parentheses</Em> for grouping.
                        </div>
                        <div className='mb-1'>
                            Use a <Em>colon</Em> for property names. &nbsp;Case is significant for some properties.
                        </div>
                        {forGlobalSearch && (
                            <div>
                                Use <InlineExample>{'>'}</InlineExample> to show data streams with the results, for
                                example <InlineExample>web-server {'>'} Health</InlineExample>.
                            </div>
                        )}
                    </div>

                    <div className='mt-4'>Examples</div>
                    <hr className='mt-1 mb-3 border-dividerPrimary' />
                    <div className='columns-2'>
                        <Example>
                            web-server-01 <Op>OR</Op> web-server-02
                        </Example>
                        <Example>
                            web-server-01 <Op>AND</Op> <Prop>type:</Prop>host
                        </Example>
                        <Example>
                            web-server-01 <Op>NOT</Op> <Prop>sourceName:</Prop>GCP
                        </Example>
                        <Example>
                            web-server-01 <Punc>(</Punc>
                            <Prop>tag:</Prop>PROD <Op>OR</Op> <Prop>tag:</Prop>DEV<Punc>)</Punc>
                        </Example>
                        <Example>
                            <Prop>sourceType:</Prop>
                            <Punc>"</Punc>Azure DevOps Repo<Punc>"</Punc>
                        </Example>
                        <Example>
                            <Prop>region:</Prop>
                            <Punc>(</Punc>us-east <Op>OR</Op> us-west<Punc>)</Punc>
                        </Example>
                    </div>
                </div>
            }
        >
            <FontAwesomeIcon className='text-textSecondary' fixedWidth icon={faQuestionCircle} />
        </Tooltip>
    );
}

function Example({ children }: { children: string | React.ReactNode }) {
    return (
        <div>
            <span className='query-example'>{children}</span>
        </div>
    );
}

function InlineExample({ children }: { children: string | React.ReactNode }) {
    return <span className='inline-query-example'>{children}</span>;
}

function Em({ children }: { children: string }) {
    return <span className='font-semibold'>{children}</span>;
}

function Op({ children }: { children: string }) {
    return <span className='text-[#8CDCFE]'>{children}</span>;
}

function Prop({ children }: { children: string }) {
    return <span className='text-[#4EC9B0]'>{children}</span>;
}

function Punc({ children }: { children: string }) {
    return <span className='font-bold text-textIncomplete'>{children}</span>;
}
