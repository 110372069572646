import { number } from '@squaredup/data-streams';
import {
    EvaluatedMonitorState,
    MonitorGroupState,
    removeRedundantDecimalPlaces,
    stateStrings
} from '@squaredup/monitoring';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import LoadingSpinner from 'components/LoadingSpinner';
import { StateIndicator } from 'components/ui/state/StateIndicator';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { PreviewHealth } from 'services/HealthService';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import { useCurrentWorkspaceId } from 'services/WorkspaceUtil';
import Tooltip from 'components/tooltip/Tooltip';
import Button from 'components/button/Button';
import fromExponential from 'from-exponential';
import useOverflowing from 'lib/useOverflowing';

const DEFAULT_ERROR_MESSAGE = 'Monitor failed, check configuration';
const MAX_GROUPS_SHOWN_BY_DEFAULT = 5;

export const DataStreamTileEditorMonitorPreview: React.FC = () => {
    const { tileConfig, setPreviewHealth } = useTileEditorContext();
    const workspaceId = useCurrentWorkspaceId();
    const [showAllGroups, setShowAllGroups] = useState(false);

    const {
        data,
        error,
        mutateAsync: handlePreview,
        isLoading: isLoadingPreview,
        isError: isErrorLoadingPreview
    } = useMutation(
        async () => PreviewHealth(workspaceId, tileConfig),
        {
            onSuccess: (previewHealthState) => {
                setPreviewHealth?.(previewHealthState);
            }
        }
    );

    sanitiseScalarsForDisplay(data);
    const { state: healthState, scalar, formattedScalar } = data || {};
    const groupStates = data?.groupStates as MonitorGroupState[];
    let displayedGroupStates = groupStates;
    let groupDisplayLimitExceeded = groupStates?.length > MAX_GROUPS_SHOWN_BY_DEFAULT;
    if (!showAllGroups && groupDisplayLimitExceeded) {
        displayedGroupStates = groupStates.slice(0, MAX_GROUPS_SHOWN_BY_DEFAULT);
    }
    const previewValue = formatPreviewValue(scalar, formattedScalar);

    // We need to debounce the preview as it can be quite heavy/expensive
    const debouncedPreviewFetch = useMemo(() => debounce(handlePreview, 500), [handlePreview]);

    useEffect(() => {
        debouncedPreviewFetch();
    }, [tileConfig, debouncedPreviewFetch]);

    return (
        <div className='text-sm'>
            {isLoadingPreview ? (
                <LoadingSpinner size={18} />
            ) : (
                <>
                    {healthState && !isErrorLoadingPreview && (
                        <div className='flex-shrink-0'>
                            {!groupStates && (
                                <>
                                    <div className='flex items-center mb-1 ml-1 space-x-2'>
                                        <StateIndicator
                                            state={healthState || stateStrings.unknown}
                                            className='w-4 h-4'
                                        />
                                        <span className='capitalize'>{healthState}</span>
                                        { previewValue &&
                                            <span className='whitespace-nowrap'>{previewValue}</span>
                                        }
                                    </div>
                                </>
                            )}
                            {groupStates && (
                                <>
                                    <div className='flex items-center mb-2 ml-1 space-x-2'>
                                        <StateIndicator
                                            state={healthState || stateStrings.unknown}
                                            className='w-4 h-4'
                                        />
                                        <div className='flex items-center space-x-1'>
                                            <span className='font-semibold capitalize'>
                                                {healthState || stateStrings.unknown}
                                            </span>                                           
                                        </div>
                                    </div>
                                    <div>
                                        <div className='ml-6'>
                                            {displayedGroupStates.map((groupState) => (
                                                <GroupStatePreview groupState={groupState} />
                                            ))}
                                        </div>
                                    </div>
                                    <div>
                                        {groupDisplayLimitExceeded && (
                                            <Button
                                                variant='tertiary'
                                                onClick={() => setShowAllGroups(!showAllGroups)}
                                                data-testid='monitorPreviewShowAllGroupsBtn'
                                                className='mt-1 ml-6 text-sm'
                                            >
                                                {showAllGroups ? 'Show fewer values' : 'Show all values'}
                                            </Button>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    {isErrorLoadingPreview && (
                        <div>
                            <p className='font-semibold text-statusErrorPrimary'>
                                An error occurred during monitor evaluation:
                            </p>
                            {getCustomErrorMessage(error) ? (
                                <div className='mt-2'>{getCustomErrorMessage(error)}</div>
                            ) : (
                                <pre className='mt-2 whitespace-normal'>{getFallbackErrorMessage(error)}</pre>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

const GroupStatePreview: React.FC<{ groupState: MonitorGroupState }> = ({ groupState }) => {
    const { ref: groupNameRef, isOverflowing: groupNameOverflowing } = useOverflowing<HTMLDivElement>();

    return (
        <div className='flex items-center mt-3 mb-1 space-x-2'>
            <div>
                <StateIndicator state={groupState.state || stateStrings.unknown} className='w-4 h-4' />
            </div>
            <div className='overflow-hidden grow-0 overflow-ellipsis whitespace-nowrap'>
                <Tooltip
                    title={groupState.group}
                    disabled={!groupNameOverflowing}
                    tooltipClassName='break-words max-w-[600px]'
                >
                    <div
                        ref={groupNameRef}
                        className={clsx('font-semibold overflow-ellipsis whitespace-nowrap overflow-hidden')}
                    >
                        {groupState.group || '-'}
                    </div>
                </Tooltip>
            </div>
            <div className='grow-1 whitespace-nowrap'>
                {formatPreviewValue(groupState?.scalar, groupState?.formattedScalar)}
            </div>
        </div>
    );
};

/**
 * Format the preview value in a way that shows correct formatting (with units etc) where possible,
 * but *also* shows the raw value because it's the raw value that's used to configure the monitoring threshold.
 */
function formatPreviewValue(scalar: number | undefined, formattedScalar: string | undefined): string {
    const formattedScalarNumericOnly = formattedScalar?.replace(/[^0-9.]/gu, '')?.trim(); // e.g. '123' from '123 ms'
    
    // Ensure we don't get exponetial format for very small or very large numbers.
    const scalarString = scalar ? fromExponential(scalar) : scalar; 

    if (scalar == null || formattedScalar == null) {
        // Only got raw or formatted value (or neither), so use whatever we've got.
        return `${formattedScalar ?? scalarString ?? ''}`;
    } else if (`${scalar}` === formattedScalar || number.format(scalar) === formattedScalar) {
        // Formatted value is basically the same as the raw value, so don't show both.
        return `${scalarString}`;
    } else if (`${scalar}` === formattedScalarNumericOnly) {
        // Formatted value is same as the raw value except it has units, so we can just show the formatted value.
        return formattedScalar;
    } else {
        // Formatted value is different from raw value (e.g. 123 GB vs 123000000000) so show both.
        // (User needs to know raw value because that's what is entered in the warning/error thresholds)
        return `${formattedScalar} (${scalarString})`;
    }
}

const getCustomErrorMessage = (error: unknown): string | undefined => {
    if (error instanceof AxiosError) {        
        const message = error.response?.data?.error?.toString();
        if (message?.includes('GroupLimitExceeded')) {
            const matches = message.match(/^\[GroupLimitExceeded\/(\d+)\/(\d+)\]/u);
            if (matches && matches.length === 3) {
                const groupCount = parseInt(matches[1]);
                const groupLimit = parseInt(matches[2]);
                return `The number of values is ${groupCount}, which exceeds the limit of ${groupLimit}.  Choose another column to evaluate by or use shaping to reduce the number of values.`;
            }
        }
    }
};

const getFallbackErrorMessage = (error: unknown): string => {
    if (error instanceof AxiosError) {
        return error.response?.data?.error || DEFAULT_ERROR_MESSAGE;
    }
    return DEFAULT_ERROR_MESSAGE;
};

const sanitiseScalarsForDisplay = (monitorState: EvaluatedMonitorState | undefined) => {
    if (monitorState?.scalar != null) {
        monitorState.scalar = parseFloat(sanitiseScalarForDisplay(monitorState.scalar));
    }

    monitorState?.groupStates?.forEach(groupState => {
        if (groupState.scalar != null) {
            groupState.scalar = parseFloat(sanitiseScalarForDisplay(groupState.scalar));
        }
    });
};

/**
 * Avoid displaying scalars like 123.374278392837473 in the UI.
 * 
 * (Normally we show a formatted scalar, e.g. '123 ms', but sometimes we want to 
 * display the raw value rather than the formatted version)
 */
const sanitiseScalarForDisplay = (scalar: number): string => {
    let formattedValue: string;
    if (Math.abs(scalar) < 1) {
        formattedValue = scalar.toPrecision(2).toString();
    } else {
        formattedValue = scalar.toFixed(2).toString();
    }
    return removeRedundantDecimalPlaces(formattedValue);
};
