import { DashboardIdFolder } from '@squaredup/tenants';
import { useAppContext } from 'contexts/AppContext';
import type { DashboardType } from '@squaredup/dashboards';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { isDashboard, recursiveFolderToDashboardIdOrder, type DashboardFolder } from 'queries/utils/dashboardSorted';
import { useMutation, useQueryClient } from 'react-query';
import { UpdateWorkspaceOrder, type Workspace } from 'services/WorkspaceService';

type OrderItem = string | DashboardIdFolder;
type DashboardOrFolder = DashboardFolder | DashboardType;

export function useUpdateWorkspaceDashboardOrder() {
    const queryClient = useQueryClient();
    const { currentWorkspaceID } = useAppContext();
    const { data: workspace } = useWorkspace(currentWorkspaceID);

    type ContextType = { previousWorkspaces: Workspace[] | undefined };

    const updateMutation = useMutation<void, Error, OrderItem[], ContextType>(
        async (items): Promise<void> => {
            if (!workspace) {
                throw new Error('Workspace not found');
            }
            await UpdateWorkspaceOrder(workspace, items);
        },
        {
            onMutate: async (items) => {
                await queryClient.cancelQueries(workspaceQueryKeys.list);
                const previousWorkspaces = queryClient.getQueryData<Workspace[]>(workspaceQueryKeys.list);

                queryClient.setQueryData<Workspace[] | undefined>(workspaceQueryKeys.list, (old) =>
                    old?.map((space) =>
                        space.id === currentWorkspaceID
                            ? {
                                  ...space,
                                  data: {
                                      ...space.data,
                                      properties: {
                                          ...space.data.properties,
                                          dashboardIdOrder: items
                                      }
                                  }
                              }
                            : space
                    )
                );

                return { previousWorkspaces };
            },
            onSettled: () => {
                queryClient.invalidateQueries(workspaceQueryKeys.list);
            },
            onError: (_, __, context) => {
                if (context?.previousWorkspaces) {
                    queryClient.setQueryData(workspaceQueryKeys.list, context.previousWorkspaces);
                }
            }
        }
    );

    const convertToOrderItems = (items: DashboardOrFolder[]): OrderItem[] =>
        items.map((item) => (isDashboard(item) ? item.id : recursiveFolderToDashboardIdOrder(item)));

    return {
        ...updateMutation,
        mutate: (items: DashboardOrFolder[]) => updateMutation.mutate(convertToOrderItems(items)),
        mutateAsync: (items: DashboardOrFolder[]) => updateMutation.mutateAsync(convertToOrderItems(items)),
        mutateDirect: updateMutation.mutate,
        mutateDirectAsync: updateMutation.mutateAsync
    };
}
