import * as React from "react";
const SvgJira = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M0 0H30V30H0V0Z", fill: "#253858", style: {
  fill: "#253858",
  fill: "color(display-p3 0.1451 0.2196 0.3451)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M23.2479 7H14.5738C14.5738 9.09405 16.3287 10.7985 18.4846 10.7985H20.0891V12.2838C20.0891 14.3778 21.844 16.0823 24 16.0823V7.73048C24 7.31654 23.6741 7 23.2479 7Z", fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M18.961 11.188H10.2869C10.2869 13.282 12.0418 14.9865 14.1978 14.9865H15.8022V16.4962C15.8022 18.5902 17.5571 20.2947 19.7131 20.2947V11.9184C19.7131 11.5289 19.3872 11.188 18.961 11.188Z", fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M14.6742 15.4005H6C6 17.4945 7.75488 19.199 9.91088 19.199H11.5153V20.6843C11.5153 22.7783 13.2702 24.4828 15.4262 24.4828V16.1309C15.4262 15.717 15.0753 15.4005 14.6742 15.4005Z", fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }));
export default SvgJira;
