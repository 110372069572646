import { LINKED_PLUGINS } from 'components/hooks/useLinkedPluginConfigs';
import { useQueryClient, useMutation } from 'react-query';
import { Import as ImportDataSourceConfig, PluginSourceConfig } from 'services/SourceConfigService';

export const useImportTrigger = (configId: string, workspaceId: string) => {
    const queryClient = useQueryClient();

    const onImportMutation = useMutation(async () => ImportDataSourceConfig(configId), {
        onMutate: async () => {
            await queryClient.cancelQueries([LINKED_PLUGINS, workspaceId]);

            // Snapshot for rollback
            const previousSources = queryClient.getQueryData([LINKED_PLUGINS, workspaceId]);

            // Optimistically update
            queryClient.setQueryData<PluginSourceConfig[]>([LINKED_PLUGINS, workspaceId], (existingSources) => {
                let newSource: any = existingSources?.find((v) => v.id === configId);
                if (newSource) {
                    newSource.importStatus = {
                        status: 'waitingForData',
                        started: Date.now(),
                        warnings: [],
                        totalWarningCount: 0,
                        lastSuccessful: newSource.importStatus?.lastSuccessful
                    };
                }

                return existingSources || [];
            });

            // Return a context object with the snapshotted value
            return { previousSources };
        },
        onError: async (err, source, context) => {
            if (context == null) {
                return;
            }

            return queryClient.setQueryData([LINKED_PLUGINS, workspaceId], context.previousSources);
        },
        onSettled: () => {
            queryClient.refetchQueries([LINKED_PLUGINS, workspaceId]);
        }
    });

    return {
        triggerImport: onImportMutation.mutate,
        isLoading: onImportMutation.isLoading
    };
};
