import { get } from 'lodash';
import { FieldError, FieldErrorsImpl, Merge, useFormContext } from 'react-hook-form';

type ValidationState = (
    | {
          isValid: false;
          error: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
      }
    | {
          isValid: true;
          error: undefined;
      }
) & {
    isDirty: boolean;
    isTouched: boolean;
    /**
     * The CSS classes for the current validation state (valid or invalid)
     */
    validationClassName: string;
};

interface ValidationColoursProps {
    name: string;
    validateOnLoad?: boolean;
}

const validClassName = 'text-statusHealthyPrimary';
const invalidClassName = 'text-statusErrorPrimary';

/**
 * Get the validation state and related CSS for a field
 */
export const useValidationState = ({ name, validateOnLoad = false }: ValidationColoursProps): ValidationState => {
    const {
        formState: { errors, dirtyFields, touchedFields }
    } = useFormContext();

    const error = get(errors, name);
    const isDirty = get(dirtyFields, name) || validateOnLoad;
    const isTouched: boolean = get(touchedFields, name) ?? false;

    const isValid = !error || !isTouched;

    if (isValid) {
        return {
            isValid,
            isTouched,
            isDirty,
            error: undefined,
            validationClassName: validClassName
        };
    }

    return {
        isValid,
        isTouched,
        isDirty,
        error,
        validationClassName: invalidClassName

    };
};
