import { cn } from '@/lib/cn';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { LoadingWrapper } from 'components/LoadingWrapper';
import { DataStreamErrors } from 'dashboard-engine/dataStreams/DataStreamErrors';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import DataStreamTable from 'dashboard-engine/visualisations/DataStreamTable/DataStreamTable';
import { useTileEditorContext } from '../../contexts/TileEditorContext';

interface DataTableProps {
    config?: DataStreamBaseTileConfig;
    rawHeaderLabels?: boolean;
    className?: string;
}

export const DataTable: React.FC<DataTableProps> = ({ config, className, rawHeaderLabels }) => {
    const { tileConfig } = useTileEditorContext();
    const tableConfig = config ?? tileConfig;

    const { data, isLoading, isPreviousData, isError, error } = useDataStreamConfig(tableConfig);

    return (
        <div className={cn('h-full', className)}>
            <LoadingOverlay loading={isPreviousData}>
                <LoadingWrapper loading={isLoading}>
                    <>
                        {isError && (
                            <div className='flex flex-col justify-center h-full'>
                                <DataStreamErrors error={error} />
                            </div>
                        )}

                        {data && (
                            <DataStreamTable
                                data={data}
                                config={{
                                    showShapeInTooltip: true,
                                    useAccessorHeaderLabels: rawHeaderLabels
                                }}
                            />
                        )}
                    </>
                </LoadingWrapper>
            </LoadingOverlay>
        </div>
    );
};
