import * as Comlink from 'comlink';

// Separated into its own module so it can be mocked for Jest (which can't handle the import.meta)
export function createNetworkWorker() {
    return Comlink.wrap(new Worker(new URL('./network.worker.js', import.meta.url), { type: 'module' }));
}

const worker: any = createNetworkWorker();

export const cytoscapeWebWorker = async (nodes: any[], edges: any[], config: any) => {
    const result = await worker.generateNetwork({ nodes, edges }, config);

    return { nodes: result.nodes, edges: result.edges };
};
