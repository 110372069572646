import AsyncSelect from 'react-select/async';

export interface RemoteSelectProps extends React.ComponentProps<AsyncSelect> {
    immediateFetch?: boolean;
    emptyMessage?: string;
}

function RemoteSelect({ immediateFetch = true, emptyMessage, ...props }: RemoteSelectProps) {
    const defaultOptions = [];

    if (emptyMessage) {
        defaultOptions.push({
            label: emptyMessage,
            value: emptyMessage,
            isDisabled: true
        });
    }

    return (
        <AsyncSelect
            cacheOptions
            defaultOptions={immediateFetch ? true : defaultOptions}
            isClearable={true}
            {...props}
        />
    );
}

export default RemoteSelect;
