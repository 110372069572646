import clsx from 'clsx';
import { FC } from 'react';

interface LoadingSpinnerProps {
    size?: number | 'full';
    colorClasses?: string;
    className?: string;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({ size = 24, colorClasses = 'text-inherit', className }) => {
    return (
        <svg
            data-testid='loading-spinner'
            height={size !== 'full' ? size : undefined}
            width={size !== 'full' ? size : undefined}
            className={clsx(
                'inline-block stroke-current animate-spin loading-spinner',
                colorClasses,
                className,
                size === 'full' && 'h-full'
            )}
            viewBox='0 0 100 100'
        >
            <circle
                fill='transparent'
                strokeWidth='10'
                strokeDashoffset='60'
                strokeDasharray='280 100'
                cx='50'
                cy='50'
                r='45'
            />
        </svg>
    );
};

export default LoadingSpinner;
