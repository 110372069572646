import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, SortDirection } from '@tanstack/react-table';
import { buttonVariants } from 'components/button';
import { LINKED_PLUGINS } from 'components/hooks/useLinkedPluginConfigs';
import { Pill, PillState, pillStatusText } from 'components/pill/Pill';
import Tooltip from 'components/tooltip/Tooltip';
import TableSkeleton from 'dashboard-engine/visualisations/DataStreamTable/TableSkeleton';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { sortImportStatus } from 'pages/components/ApplicationTable/ApplicationTableSort';
import PluginIcon from 'pages/scope/PluginIcon';
import TruncateWithTooltip from 'pages/settings/TruncateWithTooltip';
import { ComponentPropsWithoutRef, PropsWithChildren, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Query } from 'services/GraphService';
import { ListPluginSourceConfigForWorkspace, PluginSourceConfig } from 'services/SourceConfigService';
import { Workspace } from 'services/WorkspaceService';
import ErrorFallback from 'ui/errorHandling/ErrorFallback';
import { NoDataSourcesInWorkspaceMessage } from './WorkspaceEmptyMessages';
import { Panel, useWorkspaceId } from './WorkspaceHome';

type TableData = PluginSourceConfig & { objectsCount: number };

const DataSourcesApplicationTable = ApplicationTable<TableData, string>();

export const WorkspaceDataSourcesOverviewPanel = () => {
    return (
        <Panel className='gap-4'>
            <Header>
                <Text.Body className='text-lg font-semibold capitalize'>data sources</Text.Body>
                <Tooltip
                    title='Data sources in this workspace are available to use on dashboards within this workspace.'
                    className='mr-auto'
                >
                    <FontAwesomeIcon icon={faQuestionCircle} className='text-textSecondary' />
                </Tooltip>
            </Header>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<TableSkeleton />}>
                    <EmptyTableGuard>
                        <DataSourcesInWorkspaceTable />
                    </EmptyTableGuard>
                </Suspense>
            </ErrorBoundary>
        </Panel>
    );
};

const EmptyTableGuard = (props: PropsWithChildren<object>) => {
    const workspaceId = useWorkspaceId();
    const dataSourcesInWorkspace = useDataSourcesInWorkspace({ id: workspaceId });

    if (!dataSourcesInWorkspace.data?.length) {
        return (
            <div className='flex flex-col items-center justify-center gap-4 m-auto'>
                <NoDataSourcesInWorkspaceMessage />
            </div>
        );
    }

    return <>{props.children}</>;
};

let getIsSortedStatus: () => SortDirection | false;

const columns: ColumnDef<TableData>[] = [
    {
        accessorKey: 'displayName',
        header: 'Name',
        cell: ({ row }) => {
            const dataSourceName = row.original.displayName;
            const pluginName = row.original.plugin?.name ?? '';

            if (!dataSourceName) {
                return null;
            }

            return (
                <Link
                    to={`/datasource/${row.original.id}`}
                    className={cn(buttonVariants({ variant: 'link' }), 'flex items-center gap-4')}
                    data-testid={`dataSourcesInWorkspaceTable-dataSourceLink-${row.original.id}`}
                >
                    <PluginIcon className='h-6 aspect-square shrink-0' pluginName={pluginName} />
                    <TruncateWithTooltip title={dataSourceName}>
                        <span
                            className='truncate'
                            data-testid={`dataSourcesInWorkspaceTable-dataSourceName-${row.original.id}`}
                        >
                            {dataSourceName}
                        </span>
                    </TruncateWithTooltip>
                </Link>
            );
        },
        size: 240
    },
    {
        accessorKey: 'importStatus',
        header: 'Status',
        cell: ({ row, column }) => {
            getIsSortedStatus = column.getIsSorted;
            const dataSourceStatus = row.original.importStatus;

            if (!dataSourceStatus) {
                return null;
            }

            const status = (
                dataSourceStatus?.status
                    ? (dataSourceStatus.status === 'succeeded' &&
                          (dataSourceStatus.totalWarningCount ? 'warnings' : 'succeeded')) ||
                      dataSourceStatus.status
                    : 'notRun'
            ) as PillState;

            return (
                <Pill.Status
                    status={status}
                    className='first-letter:capitalize'
                    data-testid={`dataSourcesInWorkspaceTable-dataSourceStatus-${row.original.id}`}
                >
                    {pillStatusText[status]}
                </Pill.Status>
            );
        },
        size: 180,
        sortingFn: (rowA, rowB, columnId) => sortImportStatus(rowA, rowB, columnId, getIsSortedStatus),
        sortDescFirst: false
    },
    {
        accessorKey: 'objectsCount',
        header: 'Objects',
        cell: ({ row }) => {
            const dataSourceObjectsCount = row.original.objectsCount;
            return dataSourceObjectsCount === 0 && row.original.plugin?.importNotSupported === true ? (
                <Tooltip title={'This data source does not support object indexing'}>
                    <Text.Body
                        className='truncate first-letter:capitalize text-tertiaryButtonHover'
                        data-testid={`dataSourcesInWorkspaceTable-dataSourceObjectCount-${row.original.id}`}
                    >
                        {'-'}
                    </Text.Body>
                </Tooltip>
            ) : (
                <TruncateWithTooltip title={dataSourceObjectsCount.toString()}>
                    <Text.Body
                        className='truncate first-letter:capitalize text-tertiaryButtonHover'
                        data-testid={`dataSourcesInWorkspaceTable-dataSourceObjectCount-${row.original.id}`}
                    >
                        {dataSourceObjectsCount}
                    </Text.Body>
                </TruncateWithTooltip>
            );
        },
        size: 100
    }
];

const DataSourcesInWorkspaceTable = () => {
    const workspaceId = useWorkspaceId();
    const dataSourcesInWorkspace = useDataSourcesInWorkspace({ id: workspaceId });

    return <DataSourcesApplicationTable columns={columns} data={dataSourcesInWorkspace.data ?? []} config={{}} />;
};

const useDataSourcesInWorkspace = (props: Pick<Workspace, 'id'>) => {
    const dataSourcesInWorkspace = useQuery(
        [LINKED_PLUGINS, props.id, 'OBJECTS_COUNT'],
        async () => {
            const queries = await Promise.allSettled([
                ListPluginSourceConfigForWorkspace(props.id),
                Query(
                    {
                        gremlinQuery: `g.V().has('sourceName', neq('Workspace')).has('sourceType', neq('squaredup/data-source'))
                        .hasNot('__canonicalType').has('__configId').groupCount().by('__configId')`
                    },
                    'directOrAnyWorkspaceLinks'
                )
            ]);

            const [dataSourceInWorkspaceQuery, objectsCountQuery] = queries;

            if (dataSourceInWorkspaceQuery.status === 'fulfilled' && objectsCountQuery.status === 'fulfilled') {
                const dataSourceIdToObjectsCount: Record<PluginSourceConfig['id'], number> =
                    objectsCountQuery.value.gremlinQueryResults.at(0) ?? {};

                return dataSourceInWorkspaceQuery.value.map((dataSource) => {
                    return {
                        ...dataSource,
                        objectsCount: dataSourceIdToObjectsCount[dataSource.id] ?? 0
                    };
                });
            }

            return null;
        },
        {
            suspense: true
        }
    );

    return dataSourcesInWorkspace;
};

const Header = (props: ComponentPropsWithoutRef<'header'>) => {
    return <header {...props} className='flex items-center gap-2' />;
};
