import { ReactElement } from 'react';
import LoadingSpinner from './LoadingSpinner';

export const LoadingWrapper: React.FC<{ loading: boolean; children: ReactElement }> = ({ loading, children }) => {
    if (loading) {
        return (
            <div className='flex items-center justify-center h-full text-center'>
                <LoadingSpinner />
            </div>
        );
    }
    return children;
};
