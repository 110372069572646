import { EntityTypes } from '@squaredup/constants';
import { dashboardToNode } from './dashboardToNode';
import { kpiToNode } from './kpiToNode';
import { monitorToNode } from './monitorToNode';
import { objectToNode } from './objectToNode';
import { organisationToNode } from './organisationToNode';
import { workspaceToNode } from './workspaceToNode';

enum nodeEntityTypes {
    WORKSPACE = EntityTypes.WORKSPACE,
    DASHBOARD = EntityTypes.DASHBOARD,
    MONITOR = EntityTypes.MONITOR,
    ORGANISATION = EntityTypes.TENANT,
    KPI = 'KPI',
    OBJECT = 'object'
};

/**
 * utility wrapper to correctly select the type of node being added to the graph (based on type)
 */
export const entityToNode = (node: any) => {
    switch (node.type?.[0]) {
        case nodeEntityTypes.KPI:
            return kpiToNode(node);
        case nodeEntityTypes.WORKSPACE:
            return workspaceToNode(node);
        case nodeEntityTypes.ORGANISATION:
            return organisationToNode(node);
        case nodeEntityTypes.DASHBOARD:
            return dashboardToNode(node);
        case nodeEntityTypes.MONITOR:
            // We only want squaredup moinitors to show state and be treated as an internal monitor
            if (node.sourceType?.[0] === 'squaredup/monitor') {
                return monitorToNode(node);
            }
            return objectToNode(node);
        default:
            return objectToNode(node);
    }
};
