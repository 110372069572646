import { tenantQueryKeys } from 'queries/queryKeys/tenantKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { GetUsers } from 'services/UserService';

export type TenantUser = {
    id: string;
    isCurrentUser: boolean;
    name: string;
    userId: string;
    lastLogin?: Date;
};

/**
 * useQuery returning all users for the tenant
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of all users for the tenant
 */
export const useTenantUsers = (
    options?: UseQueryOptions<Array<TenantUser>, unknown, Array<TenantUser>, Array<string>>
) => {
    return useQuery({
        ...options,
        queryKey: tenantQueryKeys.tenantUsers,
        queryFn: GetUsers
    });
};
