import { type DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { getTimeframeLabel, type TimeframeEnumValue } from '@squaredup/timeframes';
import DashboardContext from 'contexts/DashboardContext';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import { getDataStreamType, type DataStreamType } from 'dashboard-engine/util/getDataStreamType';
import { useContext, useEffect, useRef } from 'react';
import { useCheckScopeIsOobAndHasLimit } from 'ui/tile/hooks/useCheckScopeIsOobAndHasLimit';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import StepsContext from '../../contexts/TileEditorStepsContext';
import { EditorSteps } from '../constants';
import { useDataStreamDefinition } from '../hooks/useDataStreamDefinition';
import { useDataStreamFilters } from '../hooks/useDataStreamFilters';
import { useDataStreamSupportedTimeframes } from '../hooks/useDataStreamSupportedTimeframes';
import { useDataStreamType } from '../hooks/useDataStreamType';
import { useTileEditorSteps, type StepWithCondition } from '../hooks/useTileEditorSteps';
import { DataStreamConfigurationStep } from './DataStreamConfiguration/DataStreamConfigurationStep';
import { DataStreamConfigurationStepSubLabel } from './DataStreamConfiguration/DataStreamConfigurationStepSubLabel';
import { DataStreamDisplayName } from './DataStreamDisplayName';
import { DataStreamMetadata } from './DataStreamMetadata';
import { DataStreamObjectsSublabel } from './DataStreamObjectsSublabel';
import { DataStreamShapingLabels } from './DataStreamShapingLabels';
import { DataStreamStep } from './DataStreamStep';
import { DataStreamTileEditorCurrentStep } from './DataStreamTileEditorCurrentStep';
import { DataStreamTileEditorStepSelector } from './DataStreamTileEditorStepSelector';
import { countModifiedColumns } from './MetadataEditor/MetadataEditorTileState';
import { ObjectsStep } from './ObjectsStep';
import { ShapingStep } from './ShapingStep';
import { TimeframeStep } from './TimeframeStep';

const stepComponents = {
    [EditorSteps.dataStream]: DataStreamStep,
    [EditorSteps.objects]: ObjectsStep,
    [EditorSteps.query]: DataStreamConfigurationStep,
    [EditorSteps.timeframe]: TimeframeStep,
    [EditorSteps.shaping]: ShapingStep,
    [EditorSteps.metadata]: DataStreamMetadata
} satisfies Record<EditorSteps, unknown>;

const getInitialEditorStep = (config: DataStreamBaseTileConfig, dataStreamType: DataStreamType) => {
    // If there's no datastream selected, show the datastream step
    if (!config.dataStream?.id) {
        return EditorSteps.dataStream;
    }

    // We expect a scope and there isn't one yet, show the scope step
    if (!config.scope) {
        return EditorSteps.objects;
    }
    // If we expect config and there isn't one yet, show the query step
    if (dataStreamType.isConfigurable && !config.dataStream?.dataSourceConfig) {
        return EditorSteps.query;
    }
    // Otherwise, the tile is configured, show data stream
    // Note: we don't show timeframe as it's there's always a default
    return EditorSteps.dataStream;
};

const getTimeframeSteplabel = (
    supportedTimeframes: boolean | TimeframeEnumValue[],
    inheritedTimeframe: TimeframeEnumValue,
    configTimeframe?: TimeframeEnumValue
) => {
    if (supportedTimeframes === false) {
        return 'Not supported';
    }

    if (configTimeframe) {
        if (configTimeframe === 'none') {
            return 'None';
        }
        return `${getTimeframeLabel(configTimeframe, 'nonrelative')} (Fixed)`;
    }

    return `${getTimeframeLabel(inheritedTimeframe, 'nonrelative')} (Dashboard)`;
};

export const DataStreamTileEditorSteps: React.FC = () => {
    const { config, setConfig } = useDatasetContext();
    const { savedTileDataStreamType } = useTileEditorContext();
    const { timeframe: inheritedTimeframe } = useContext(DashboardContext);
    const automaticallyNavigated = useRef(false);

    const { dataStreamType, isLoading } = useDataStreamType(config);

    const supportedTimeframes = useDataStreamSupportedTimeframes(config.dataStream?.id);
    const { data: dataStream } = useDataStreamDefinition(config.dataStream?.id);
    const { data, isConfigured } = useDataStreamConfig(config);
    const dataStreamFilters = useDataStreamFilters();

    const hasDataStream = Boolean(config.dataStream);
    const isConfigurableDataStream = dataStreamType && dataStreamType.isConfigurable;

    const hasScope = Object.keys(config.scope || {}).length > 0;

    const editorSteps: StepWithCondition[] = [
        {
            name: EditorSteps.dataStream,
            condition: !isLoading,
            sublabel: <DataStreamDisplayName />
        },
        {
            name: EditorSteps.objects,
            condition: !isLoading && hasDataStream && dataStreamType.supportsScope,
            sublabel: hasDataStream ? <DataStreamObjectsSublabel /> : '-'
        },
        {
            name: EditorSteps.query,
            condition: !isLoading && isConfigurableDataStream && (hasScope || !dataStreamType.supportsScope),
            sublabel: isConfigurableDataStream ? <DataStreamConfigurationStepSubLabel /> : '-'
        },
        {
            name: EditorSteps.timeframe,
            condition: !isLoading && hasDataStream && supportedTimeframes !== false && isConfigured,
            sublabel: getTimeframeSteplabel(supportedTimeframes, inheritedTimeframe, config.timeframe)
        },
        {
            name: EditorSteps.shaping,
            condition: !isLoading && hasDataStream && isConfigured,
            sublabel: <DataStreamShapingLabels />
        },
        {
            name: EditorSteps.metadata,
            condition: !isLoading && hasDataStream && isConfigured,
            sublabel: (() => {
                const numberOfModifiedColumns = countModifiedColumns(
                    config.dataStream?.metadata,
                    data.metadata.columns.map((c) => c.name)
                );

                return numberOfModifiedColumns > 0 ? `${numberOfModifiedColumns} configured` : '';
            })()
        }
    ];

    const stepsValue = useTileEditorSteps(
        editorSteps,
        savedTileDataStreamType ? getInitialEditorStep(config, getDataStreamType(dataStream)) : EditorSteps.dataStream
    );

    const { isLoading: isLoadingLimit, limit } = useCheckScopeIsOobAndHasLimit(config);

    useEffect(() => {
        if (!isLoadingLimit && limit !== false && !automaticallyNavigated.current) {
            stepsValue.setCurrentEditorStep(EditorSteps.objects);
            automaticallyNavigated.current = true;
        }
    }, [isLoadingLimit, limit, stepsValue.setCurrentEditorStep]);

    return (
        <StepsContext.Provider value={stepsValue}>
            <div className='flex-1 w-full h-full min-h-0 bg-tileBackground'>
                <div className='flex h-full'>
                    <DataStreamTileEditorStepSelector />
                    <DataStreamTileEditorCurrentStep
                        stepComponents={stepComponents}
                        stepProps={{ config, setConfig, dataStreamFilters }}
                    />
                </div>
            </div>
        </StepsContext.Provider>
    );
};
