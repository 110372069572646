import { handleError, handleResponse } from './util';

import API from './API';
import { Serialised } from '@squaredup/ids';

export const CUSTOM_CORRELATIONS = 'customCorrelations';

export type ProjectedCustomCorrelation = {
    displayName: string;
    subType: string;
    type: string;
    id: string;
    tenant: string;
    config: {
        verb: string,
        toProp: string,
        toType: string,
        fromProp: string,
        fromType: string        
    }    
}

export const Get = async (id: string) => {
    return API.get(`/correlations/${id}`).then(handleResponse).catch(handleError);
};

export const List = async (): Promise<Serialised<ProjectedCustomCorrelation[]>> => {
    return API.get('/correlations').then(handleResponse).catch(handleError);
};

export const Create = async (displayName: string, config: any) => {
    const created = await API.post('/correlations', { displayName, config }).then(handleResponse).catch(handleError);

    return created.id;
};

export const Update = async (id: string, displayName: string, config: any) => {
    await API.put(`/correlations/${id}`, { displayName, config }).then(handleResponse).catch(handleError);
    return id;
};

export const Delete = async (id: string) => {
    await API.delete(`/correlations/${id}`).then(handleResponse).catch(handleError);
    return true;
};
