import Text from '@/components/Text';
import Modal, { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';
import { ChoiceChips } from 'components/forms/choiceChips/ChoiceChips';
import Field from 'components/forms/field/Field';
import { FormToggle } from 'components/forms/toggle/FormToggle';
import { useTileContext } from 'contexts/TileContext';
import { TileTypes } from 'dashboard-engine/constants';
import { TextTileConfig } from 'dashboard-engine/tiles/text';
import { TextConfig } from 'dashboard-engine/visualisations/Text/Config';
import { FormProvider, useForm } from 'react-hook-form';
import { TextPreview } from './TextPreview';
import { InfoTip } from '@/components/InfoTip';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

export const TextTileEditor: React.FC<{
    config: TextTileConfig;
    onClose: () => void;
}> = ({ config, onClose }) => {
    const { onChange } = useTileContext();

    const defaultValues: TextConfig = {
        autoSize: config.visualisation?.config?.autoSize ?? true,
        fontSize: config.visualisation?.config?.fontSize || 16,
        align: config.visualisation?.config?.align || 'left',
        content: config.visualisation?.config?.content || 'Sample text'
    };

    const formProps = useForm({ mode: 'all', defaultValues, shouldUnregister: true });
    const {
        formState: { isValid, isSubmitting },
        watch,
        handleSubmit
    } = formProps;
    const fields = watch();

    const handleFormSubmission = (data: TextConfig) => {
        onChange({
            ...config,
            _type: TileTypes.text,
            visualisation: {
                ...config.visualisation,
                config: {
                    ...data
                }
            }
        });
        onClose();
    };

    return (
        <Modal title={null} close={onClose} fullWidth maxWidth='max-w-7xl'>
            <FormProvider {...formProps}>
                <div className='flex flex-row min-h-[600px]'>
                    <div className='flex-1 grow-[4] border-r border-solid border-dividerPrimary'>
                        <form
                            className='flex flex-col h-full'
                            onSubmit={handleSubmit((data: TextConfig) => handleFormSubmission(data))}
                        >
                            <div className='flex flex-col flex-1 px-8 pt-8 overflow-hidden'>
                                <Text.H2 className='text-xl font-bold truncate !overflow-visible'>
                                    Edit text tile
                                </Text.H2>

                                <Field.Input
                                    type='textarea'
                                    className='h-36 scrollbar-thumb-statusUnknownPrimary'
                                    inputWrapperClass='items-start'
                                    name='content'
                                    label='Content'
                                    placeholder='Type some text here'
                                    validation={{ required: true }}
                                />

                                <div className='pt-6'>
                                    <p className='font-medium text-textPrimary'>Sizing</p>
                                    <FormToggle wrapperClassName='!mt-2 rounded-md' name='autoSize' label='Fit to tile' />
                                </div>

                                <div
                                    className={`flex items-end space-x-8 pt-6 ${
                                        fields.autoSize === true ? 'hidden' : ''
                                    }`}
                                >
                                    <div className='flex-1'>
                                        <Field.Input
                                            type='slider'
                                            name='fontSize'
                                            label='Font size'
                                            validation={{ required: true }}
                                            min={12}
                                            max={80}
                                            markers={[
                                                { value: 12, label: '12px' },
                                                { value: 80, label: '80px' }
                                            ]}
                                        />
                                    </div>
                                    <span className='min-w-[8rem] text-center px-4 py-3 bg-componentBackgroundSecondary'>
                                        {fields.fontSize} px
                                    </span>
                                </div>

                                <div className='pt-6'>
                                    <ChoiceChips
                                        name='align'
                                        label='Text alignment'
                                        isMulti={false}
                                        options={[
                                            { value: 'left', label: 'Left' },
                                            { value: 'center', label: 'Center' },
                                            { value: 'right', label: 'Right' }
                                        ]}
                                    />
                                </div>

                                <div className='pt-6'>
                                    <InfoTip icon={faInfoCircle} iconClassName='text-textSecondary'>
                                        Note: This visualization supports Markdown content, <a href='https://docs.squaredup.com/first-steps/text-tiles' className='text-textLink' target='_blank' rel='noreferrer'>learn more</a>.
                                    </InfoTip>
                                </div>
                            </div>

                            <ModalButtons hideTopMargin={true}>
                                <Button
                                    type='button'
                                    onClick={onClose}
                                    variant='tertiary'
                                    data-testid='cancelAddButton'
                                >
                                    Cancel
                                </Button>
                                <Button type='submit' data-testid='addButton' disabled={!isValid || isSubmitting}>
                                    Save
                                </Button>
                            </ModalButtons>
                        </form>
                    </div>
                    <div className='flex flex-col flex-1 grow-[5] min-h-0 p-4 items-center justify-center'>
                        <TextPreview textConfig={fields} />
                    </div>
                </div>
            </FormProvider>
        </Modal>
    );
};
