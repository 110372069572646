import { faFolderPlus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCreateDashboardAndNavigate } from 'components/hooks/useCreateDashboardAndNavigate';
import Tooltip from 'components/tooltip/Tooltip';
import { useAppContext } from 'contexts/AppContext';
import { useDashboardsForWorkspace } from 'queries/hooks/useDashboardsForWorkspace';
import { useUpdateWorkspaceDashboardOrder } from 'queries/hooks/useUpdateWorkspaceDashboardOrder';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { DashboardFolder, dashboardsSortedByWorkspaceOrder } from 'queries/utils/dashboardSorted';
import { useWorkspaceCanWrite } from 'services/AccessControlService';
import { v4 as generateId } from 'uuid';
import { folderInitEdit } from './DashboardTreeUtils';

export const AddDashboardAndFolderButtons: React.FC = () => {
    const { currentWorkspaceID } = useAppContext();

    const { data: canWrite, isLoading } = useWorkspaceCanWrite(currentWorkspaceID ?? '');
    const { createDashboardAndNavigate } = useCreateDashboardAndNavigate();

    const updateDashboardOrder = useUpdateWorkspaceDashboardOrder();

    const { data: workspace } = useWorkspace(currentWorkspaceID);

    const { data: dashboards = [] } = useDashboardsForWorkspace(currentWorkspaceID, {
        suspense: true,
        enabled: Boolean(workspace),
        select: dashboardsSortedByWorkspaceOrder(workspace?.data.properties?.dashboardIdOrder)
    });

    if (isLoading || !canWrite) {
        return null;
    }

    return (
        <div className='flex mr-1 pr-px ml-2.5'>
            <Tooltip title='Add folder'>
                <button
                    type='button'
                    data-testid='create-folder'
                    onClick={(e) => {
                        e.stopPropagation();
                        const newFolder: DashboardFolder = {
                            name: 'New folder',
                            children: [],
                            id: generateId()
                        };

                        updateDashboardOrder.mutate([...dashboards, newFolder]);
                        folderInitEdit(newFolder);
                    }}
                >
                    <span className='sr-only'>Add folder</span>
                    <FontAwesomeIcon
                        aria-hidden
                        icon={faFolderPlus}
                        className='invisible group-hover:visible text-textSecondary cursor-pointer w-6 hover:text-textPrimary'
                    />
                </button>
            </Tooltip>
            <Tooltip title='Add dashboard'>
                <button
                    type='button'
                    data-testid='create-dashboards'
                    onClick={(e) => {
                        e.stopPropagation();
                        createDashboardAndNavigate();
                    }}
                >
                    <span className='sr-only'>Add dashboard</span>
                    <FontAwesomeIcon
                        aria-hidden
                        icon={faPlus}
                        className='cursor-pointer w-6 text-textSecondary hover:text-textPrimary'
                    />
                </button>
            </Tooltip>
        </div>
    );
};
