import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { HealthState } from '@squaredup/monitoring';
import { noop } from 'lodash';
import { createContext, useContext } from 'react';

export interface FullScreenTileConfig extends DataStreamBaseTileConfig {
    tileId?: string;
    health?: {
        state: HealthState;
        stateReason?: string;
    };
}

interface FullscreenContextValue {
    isEditing: boolean;
    isEditingEnabled: boolean;
    fullscreenConfig: FullScreenTileConfig | undefined;
    setIsEditing: React.Dispatch<boolean>;
    setIsCopyingTo: React.Dispatch<boolean>;
    setFullScreenConfig: React.Dispatch<FullScreenTileConfig | undefined>;
}

const FullscreenContext = createContext<FullscreenContextValue>({
    isEditing: false,
    isEditingEnabled: false,
    fullscreenConfig: { _type: 'tile/data-stream' },
    setIsEditing: noop,
    setFullScreenConfig: noop,
    setIsCopyingTo: noop
});

export const useFullscreenContext = () => useContext(FullscreenContext);

export default FullscreenContext;
