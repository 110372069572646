import { createPortal } from 'react-dom';

export default function TooltipMessage({ children }) {
    const tooltipElement = document.getElementById('graphTooltip');

    // We don't want to attempt to render the tooltip unless the mount point still exists
    if (!tooltipElement) {
        return null;
    }

    return createPortal(children, tooltipElement);
}
