import { useEffect } from 'react';

const DEFAULT_TITLE = 'SquaredUp';

/**
 * Update the page/tab title to the given title
 * Resets when unmounting component
 * @param title Title to set
 */
export const usePageTitle = (title = DEFAULT_TITLE) => {
    useEffect(() => {
        document.title = `${title} | ${DEFAULT_TITLE}`;

        return () => { // Reset on unmount
            document.title = DEFAULT_TITLE;
        };
    }, [title]);
};
