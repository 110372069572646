import { useEffect, useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { CodeEditor, CodeEditorArgs } from 'ui/editor/components/CodeEditor';

type CodeProps = Omit<CodeEditorArgs, 'content' | 'onValidUpdatedContent'> & {
    name: string;
    defaultValue?: unknown;
    language?: string;
    validation?: RegisterOptions;
};

/**
 * @returns An intellisense code editor that supports any provided language within Monaco
 */
export const Code = ({ name, language, validation, defaultValue, ...props }: CodeProps) => {
    const [error, setError] = useState(true);
    const { trigger } = useFormContext();

    useEffect(() => {
        trigger(name);
    }, [name, trigger, error]);

    return (
        <div className='flex flex-col w-full'>
            <Controller
                name={name}
                rules={{ ...validation }}
                defaultValue={defaultValue}
                render={({ field: { value, onChange } }) => {
                    return (
                        <CodeEditor
                            content={value || ''}
                            language={language}
                            onValidUpdatedContent={(v) => onChange(v)}
                            handleError={setError}
                            {...props}
                        />
                    );
                }}
            />
        </div>
    );
};
