import { Controller, UseControllerReturn } from 'react-hook-form';
import Select from 'react-select';
import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import { debounce, isNumber, isNaN } from 'lodash';
import { defaultWrapperClassName } from 'components/forms/input/Input';
import { useEffect, useMemo } from 'react';
import { useDOMElement } from 'components/hooks/useDOMElement';

const rangeOptions: AutocompleteOption[] = [
    {
        label: 'Auto',
        value: 'auto'
    },
    {
        label: 'Custom',
        value: 'custom'
    }
];

type RangeType = 'auto' | 'custom';

type RangeState = {
    type: RangeType;
    minimum?: number;
    maximum?: number;
};

const RangeInputs = ({
    value,
    onChange,
    isDisabled
}: {
    value: RangeState;
    onChange: (data: RangeState) => void;
    isDisabled?: boolean;
}) => {
    const debouncedOnChange = useMemo(() => debounce(onChange, 700), [onChange]);

    // Ensure we dont try to set state when unmounted
    useEffect(() => {
        return debouncedOnChange.cancel();
    }, [debouncedOnChange]);

    const portalTarget = useDOMElement('visualizationConfigPanel');

    return (
        <>
            <Select<AutocompleteOption>
                value={rangeOptions.find((v) => v.value === value.type)}
                options={rangeOptions}
                classNamePrefix='autocomplete'
                className='ring-1 ring-inset ring-outlinePrimary focus-within:ring-outlineSecondary rounded-input bg-componentBackgroundPrimary'
                isDisabled={isDisabled ?? false}
                blurInputOnSelect={true}
                menuPortalTarget={portalTarget}
                onChange={(v) => {
                    onChange({
                        ...value,
                        type: (v?.value ?? 'auto') as RangeType
                    });
                }}
            />

            {value.type === 'custom' && (
                <div className='flex items-center justify-between mt-6 space-x-4'>
                    <input
                        placeholder='Minimum'
                        type='number'
                        className={defaultWrapperClassName}
                        defaultValue={value.minimum}
                        disabled={isDisabled ?? false}
                        onChange={(e) =>
                            debouncedOnChange({
                                ...value,
                                minimum: e.target.valueAsNumber
                            })
                        }
                    />
                    <p>to</p>
                    <input
                        placeholder='Maximum'
                        type='number'
                        className={defaultWrapperClassName}
                        defaultValue={value.maximum}
                        disabled={isDisabled ?? false}
                        onChange={(e) =>
                            debouncedOnChange({
                                ...value,
                                maximum: e.target.valueAsNumber
                            })
                        }
                    />
                </div>
            )}
        </>
    );
};

export const RangeControl = ({ name, isDisabled }: { name: string; isDisabled?: boolean }) => {

    return (
        <Controller
            name={name}
            render={({ field: { onChange, value } }: UseControllerReturn) => {
                return (
                    <div>
                        <RangeInputs 
                            value={value} 
                            onChange={onChange} 
                            isDisabled={isDisabled} 
                        />
                    </div>
                );
            }}
            rules={{
                validate: (v: RangeState) => {
                    if (v.type === 'auto') {
                        return true;
                    }

                    return (
                        isNumber(v.minimum) &&
                        isNumber(v.maximum) &&
                        !isNaN(v.minimum) &&
                        !isNaN(v.maximum) &&
                        v.minimum < v.maximum
                    );
                }
            }}
        />
    );
};
