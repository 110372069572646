import { Skeleton } from '@/components/Skeleton';
import { cn } from '@/lib/cn';

const GridSkeleton = ({
    className,
    cols,
    gap,
    height,
    radius
}: {
    className?: string;
    cols: number;
    gap: string;
    height: string;
    radius: string;
}) => {
    return (
        <Skeleton
            className={cn(className)}
            style={{
                maskImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  width='100%25' height='100%25' %3E%3Cdefs%3E%3Cpattern id='gridPattern' width='${
                    100 / cols
                }%25' height='${height}' patternUnits='userSpaceOnUse'%3E%3Crect rx='${radius}' ry='${radius}' width='calc(${
                    100 / cols
                }% - ${gap})' height='calc(${height} - ${gap})' x='${gap}' y='${gap}' fill='black' /%3E%3C/pattern%3E%3C/defs%3E%3Crect width='100%25' height='100%25' fill='url(%23gridPattern)' /%3E%3C/svg%3E")`,
                maskPosition: 'center',
                maskRepeat: 'no-repeat',
                marginLeft: `-${gap}`,
                width: `calc(100% + ${gap})`,
                marginTop: `-${gap}`,
                height: `calc(100% + ${gap})`
            }}
        />
    );
};

export default GridSkeleton;
