import { CustomLayerProps, Datum } from '@nivo/line';
import GraphTooltip from 'components/GraphTooltip';
import { motion } from 'framer-motion';
import { orderBy } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { LineGraphArea } from './LineGraphArea';
import { dateFormatterFullDate } from './LineGraphDataValidation';
import { LineGraphLine } from './LineGraphLine';
import { LineGraphPoints } from './LineGraphPoints';
import { LineGraphTrendLine } from './LineGraphTrendLine';
import { LineGraphSlice } from './lineGraphTypes';
import { spring } from './transition';

interface LineGraphLinesLayerProps extends CustomLayerProps {
    slices: LineGraphSlice[];
    areaGenerator: (data: Datum[]) => string;
    graphRef: React.RefObject<HTMLInputElement>;
    legendContainerRef: React.RefObject<HTMLDivElement>;
    hoveredLineId: string | number | undefined;
    setHoveredLineId: Dispatch<SetStateAction<string | number | undefined>>;
    trendLines:
        | {
              id: string;
              x1: number;
              x2: number;
              y1: number;
              y2: number;
          }[]
        | null;
}

/**
 *
 * @returns A custom SVG layer that manages individual line rendering, line hovering,
 * and the slice tooltip when hovering on a individual line
 */
export const LineGraphLinesLayer: React.FC<LineGraphLinesLayerProps> = ({
    lineWidth,
    series,
    margin,
    slices,
    points,
    innerWidth,
    innerHeight,
    graphRef,
    legendContainerRef,
    trendLines,
    enableArea,
    enablePoints,
    areaOpacity,
    hoveredLineId,
    setHoveredLineId,
    yScale,
    xScale,
    lineGenerator,
    areaGenerator
}) => {
    const [currentSlice, setCurrentSlice] = useState<LineGraphSlice>();

    const handleLineHover = (lineId: string | number) => {
        if (series.length > 1) {
            setHoveredLineId(lineId);
        }
    };

    const handleLineLeave = () => {
        setHoveredLineId(undefined);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!graphRef.current) {
            return;
        }
        const bounds = graphRef.current.getBoundingClientRect();
        const mouseX = e.clientX - bounds.left - (margin?.left || 0);

        const slice = slices.find((s) => mouseX >= s.x0 && mouseX < s.x0 + s.width);
        if (slice) {
            setCurrentSlice(slice);
        }
    };

    const lineGraphPoints = useMemo(
        () => enablePoints && <LineGraphPoints points={points} hoverId={hoveredLineId} />,
        [enablePoints, points, hoveredLineId]
    );

    const lines = series.slice(0).reverse();

    return (
        <LinesGroupWrapper
            legendContainerRef={legendContainerRef}
            setCurrentSlice={setCurrentSlice}
            setHoveredLineId={setHoveredLineId}
        >
            <rect
                x={0}
                y={0}
                width={innerWidth}
                height={innerHeight}
                fill='transparent'
                onMouseEnter={handleMouseMove}
                onMouseMove={handleMouseMove}
            />
            {enableArea &&
                lines.map(({ id, data, fill }) => (
                    <LineGraphArea
                        key={id}
                        lineId={id}
                        hoverId={hoveredLineId}
                        fill={fill ?? 'white'}
                        areaOpacity={areaOpacity || 0.3}
                        areaGenerator={areaGenerator}
                        points={data.map((d) => d.position)}
                    />
                ))}
            {lineGraphPoints}
            {lines.map(({ id, data, color, getDrilldownUrl }) => {
                return (
                    <LineGraphLine
                        key={id}
                        lineId={id}
                        hoverId={hoveredLineId}
                        color={color ?? 'white'}
                        strokeWidth={lineWidth ?? 1}
                        points={data.map((d) => d.position)}
                        lineGenerator={lineGenerator}
                        onLineHover={handleLineHover}
                        onLineLeave={handleLineLeave}
                        onMouseMove={handleMouseMove}
                        drilldownUrl={getDrilldownUrl}
                    />
                );
            })}
            {trendLines?.map((line) => (
                <LineGraphTrendLine
                    key={`trend-${line.id}`}
                    id={`${line.id}`}
                    hoverId={hoveredLineId}
                    width={innerWidth}
                    x1={xScale(line.x1)}
                    x2={xScale(line.x2)}
                    y1={yScale(line.y1)}
                    y2={yScale(line.y2)}
                    onLineHover={handleLineHover}
                    onLineLeave={handleLineLeave}
                    onMouseMove={handleMouseMove}
                    opacity={1}
                    color={lines.find((l) => l.id === line.id)?.color || 'white'}
                />
            ))}
            
            {currentSlice && (
                <>
                    <motion.line
                        animate={{
                            x1: currentSlice.x,
                            x2: currentSlice.x
                        }}
                        transition={spring}
                        y1={0}
                        y2={innerHeight}
                        strokeDasharray='6 6'
                        stroke='#818c9c'
                        pointerEvents='none'
                    />
                    <GraphTooltip
                        points={orderBy(currentSlice.points, (p) => p.serieId.toString()).map((point) => ({
                            label: point.serieId.toString(),
                            subLabel: dateFormatterFullDate(point.data.x),
                            value: point.data.valueFormatted,
                            yValue: (point.data.y as number) || 0,
                            color: point.color,
                            highlight: point.serieId === hoveredLineId
                        }))}
                        graphRef={graphRef}
                        spacing={70}
                    />
                </>
            )}
        </LinesGroupWrapper>
    );
};

/**
 * Handles hiding the tooltip and resetting the hovered line when the mouse is not over the graph
 */
const LinesGroupWrapper: React.FC<{
    legendContainerRef: React.RefObject<HTMLDivElement>;
    setCurrentSlice: Dispatch<SetStateAction<LineGraphSlice | undefined>>;
    setHoveredLineId: Dispatch<SetStateAction<string | number | undefined>>;
}> = ({ legendContainerRef, children, setCurrentSlice, setHoveredLineId }) => {
    const containerRef = useRef<SVGGElement>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            const isLegendHovered = legendContainerRef?.current?.matches(':hover');
            const isGraphHovered = containerRef.current?.matches(':hover');
            const isHovered = legendContainerRef.current ? isGraphHovered || isLegendHovered : isGraphHovered;
            setCurrentSlice((slice) => (!isHovered && slice != null ? undefined : slice));
            setHoveredLineId((lineId) => (!isHovered && lineId != null ? undefined : lineId));
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, [containerRef, legendContainerRef, setCurrentSlice, setHoveredLineId]);

    return <g ref={containerRef}>{children}</g>;
};
