import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { ButtonProps } from 'components/button';
import { forwardRef } from 'react';

/**
 * A button showing a vertically-centered delete icon,
 * usually used for removing rows of inputs such as in grouping and filtering
 */
export const DeleteRowButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return (
        <Button
            {...props}
            ref={ref}
            variant='tertiary'
            className={props.className}
        >
            <FontAwesomeIcon icon={faTrash} size={'lg'} />
        </Button>
    );
});
