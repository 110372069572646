import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { Action } from 'pages/components/ApplicationTable/types';
import PluginIcon from 'pages/scope/PluginIcon';
import { FC } from 'react';
import TruncateWithTooltip from '../TruncateWithTooltip';
import { CustomDataStreamProjectedRow } from './DataStreamDefinitions';

interface DataStreamDefinitionsTableProps {
    dataStreamDefinitions: CustomDataStreamProjectedRow[];
    onEdit: (customType: CustomDataStreamProjectedRow) => void;
    onDelete: (workspace: CustomDataStreamProjectedRow) => void;
}

const DataStreamApplicationTable = ApplicationTable<CustomDataStreamProjectedRow, string>();

const DataStreamDefinitionsTable: FC<DataStreamDefinitionsTableProps> = ({
    dataStreamDefinitions,
    onEdit,
    onDelete
}) => {
    const columns: ColumnDef<CustomDataStreamProjectedRow>[] = [
        {
            id: 'name',
            header: 'Name',
            cell: ({ row }) => {
                return (
                    <TruncateWithTooltip title={row.original.displayName || ''}>
                        <div className='truncate'>{row.original.displayName}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 300,
            accessorKey: 'displayName'
        },
        {
            id: 'pluginName',
            header: 'Data Source',
            cell: ({ row }) => {
                return (
                    <TruncateWithTooltip title={row.original.pluginName || ''}>
                        <PluginIcon className='h-6 mr-2' pluginName={row.original.pluginName || ''} />
                        <div className='truncate'>{row.original.pluginName}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 300,
            accessorKey: 'pluginName'
        },
        {
            id: 'id',
            header: 'Id',
            cell: ({ row }) => {
                return (
                    <TruncateWithTooltip title={row.original.id || ''}>
                        <div className='truncate'>{row.original.id}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 300,
            accessorKey: 'id'
        }
    ];

    const actions: Action[] = [
        {
            action: onEdit,
            icon: faPencil,
            dataTestId: 'edit',
            tooltip: 'Edit custom data stream'
        },
        {
            action: onDelete,
            icon: faTrash,
            dataTestId: 'delete',
            tooltip: 'Delete custom data stream'
        }
    ];

    return (
        <div className='flex flex-col flex-1 w-full h-full min-h-0'>
            <DataStreamApplicationTable
                config={{
                    actions,
                    noDataMessage: 'There are no custom data streams configured.'
                }}
                columns={columns}
                data={dataStreamDefinitions}
            />
        </div>
    );
};

export default DataStreamDefinitionsTable;
