import * as Scroll from '@radix-ui/react-scroll-area';
import clsx from 'clsx';
import { forwardRef } from 'react';

export interface ScrollAreaProps {
    children: React.ReactNode;
    viewPortClassName?: string;
    className?: string;
}

const ScrollArea = forwardRef<HTMLDivElement, ScrollAreaProps>(({ children, className }, ref) => {
    return (
        <Scroll.Root className={clsx('overflow-hidden', className)}>
            <Scroll.Viewport ref={ref} className='flex w-full h-full'>
                {children}
            </Scroll.Viewport>
            <Scroll.Scrollbar className='flex select-none touch-none p-0.5 bg-transparent w-2.5' orientation='vertical'>
                <Scroll.Thumb className="flex-1 bg-statusUnknownPrimary relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[16px] before:min-h-[16px]" />
            </Scroll.Scrollbar>
            <Scroll.Scrollbar
                className='flex select-none touch-none p-0.5 bg-transparent flex-col h-2.5'
                orientation='horizontal'
            >
                <Scroll.Thumb className="flex-1 bg-statusUnknownPrimary relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[16px] before:min-h-[16px]" />
            </Scroll.Scrollbar>
            <Scroll.Corner className='bg-dividerPrimary' />
        </Scroll.Root>
    );
});

export default ScrollArea;
