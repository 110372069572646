import {
    GetIdToken,
    GetIdTokenAuthenticated,
    GetShareRestrictions,
    OpenAccessIdToken,
    RefreshOpenAccessToken,
    RefreshTokenContext,
    ShareRestrictions
} from 'services/OpenAccessLoginService';
import {
    deleteRefreshTokenFromLocalStorage,
    getRefreshTokenFromLocalStorage,
    getRequestedOpenAccessId,
    oaLog,
    storeRefreshTokenInLocalStorage
} from 'lib/openAccessUtil';
import { JwtPayload, jwtDecode } from 'jwt-decode';

type SetAuthInfoFn = (authInfo: any) => void;

let refreshTimeout: NodeJS.Timeout;

/**
 * Acquire an Open Access JWT and use it as our bearer token for all API requests.
 * First attempts to extend any existing session, otherwise tries to start a new
 * one.
 *
 * For shares that require authentication, a standard user login must be performed before
 * calling this function.
 */
export async function beginOrExtendOpenAccessSession(setAuthInfo: SetAuthInfoFn, restrictions?: ShareRestrictions) {
    if (await isAuthenticationRequired(restrictions)) {
        try {
            oaLog('Trying to extend existing session, if there is one.');
            await extendAuthenticatedSession(setAuthInfo);
        } catch (err) {
            oaLog('Could not extend existing session, so will try to start a new one.', err);
            await beginAuthenticatedSession(setAuthInfo);
        }
    } else {
        await newAnonymousToken(setAuthInfo);
    }
}

/**
 * Acquire a new access token so we can continue to display the dashboard after the current token
 * has expired.
 *
 * For anonymously-accessible shares this just means getting a new token. For shares that require
 * authentication it means using a refresh token to get a new access token (if that's allowed for the share)
 */
export async function extendOpenAccessSession(setAuthInfo: SetAuthInfoFn, restrictions?: ShareRestrictions) {
    if (await isAuthenticationRequired(restrictions)) {
        await extendAuthenticatedSession(setAuthInfo);
    } else {
        await newAnonymousToken(setAuthInfo);
    }
}

export function endOpenAccessSession() {
    clearTimeout(refreshTimeout);
    deleteRefreshTokenFromLocalStorage(getRequestedOpenAccessId());
}

async function isAuthenticationRequired(restrictions?: ShareRestrictions) {
    restrictions = restrictions ?? (await GetShareRestrictions(getRequestedOpenAccessId()));
    return restrictions?.restrictedToUsers === true;
}

async function newAnonymousToken(setAuthInfo: SetAuthInfoFn) {
    oaLog('Getting access token for anonymous Open Access.');
    const openAccessIdToken = await GetIdToken(getRequestedOpenAccessId());
    oaLog('Got access token.', { openAccessIdToken });

    setAuthInfoForOpenAccess(openAccessIdToken, setAuthInfo);
    scheduleRefresh(setAuthInfo);
}

interface OpenAccessJwt extends JwtPayload {
    openAccessId: string;
    openAccessTenantId: string;
    openAccessTargetId: string;
    openAccessWorkspaceId: string;
}

/**
 * If the current browser URL contains a token query string parameter, use it to 
 * initialise the bearer token we use to make API calls.
 * 
 * Returns true if the token was found and set, false otherwise.
 */
export function configureAuthFromQueryString(setAuthInfo: SetAuthInfoFn): boolean {
    const token = new URLSearchParams(window.location.search).get('token');
    if (!token) {
        return false;
    }

    const jwt = jwtDecode<OpenAccessJwt>(token); 

    // As a sanity check, make sure the token is for the share referenced in the URL.
    // (This isn't a security check - the token always gives you access to whatever the token
    // gives you access to, and nothing else.)
    if (jwt.openAccessId !== getRequestedOpenAccessId()) {
        return false; // Token doesn't match share in URL, so don't use it. It will just give confusing results.
    }

    const openAccessIdToken: OpenAccessIdToken = {
        // eslint-disable-next-line camelcase
        id_token: token,
        openAccessId: jwt.openAccessId,
        openAccessTenantId: jwt.openAccessTenantId,
        openAccessTargetId: jwt.openAccessTargetId,
        openAccessWorkspaceId: jwt.openAccessWorkspaceId
    };

    setAuthInfoForOpenAccess(openAccessIdToken, setAuthInfo);
    return true;
}

async function beginAuthenticatedSession(setAuthInfo: SetAuthInfoFn) {
    oaLog('Getting access token for authenticated OA using our user token.');
    const openAccessIdToken = await GetIdTokenAuthenticated(getRequestedOpenAccessId());
    oaLog('Got access token.', { openAccessIdToken });

    setAuthInfoForOpenAccess(openAccessIdToken, setAuthInfo);
    handleNewRefreshContext(openAccessIdToken.openAccessId, openAccessIdToken.refreshContext, setAuthInfo);
}

export async function extendAuthenticatedSession(setAuthInfo: SetAuthInfoFn) {
    const openAccessId = getRequestedOpenAccessId();
    const refreshContext = getRefreshTokenFromLocalStorage(openAccessId);

    if (!refreshContext) {
        throw new Error('No refresh token, so cannot extend session.');
    }

    try {
        oaLog('Got a valid refresh token - will try to extend session', refreshContext);
        const openAccessIdToken = await RefreshOpenAccessToken(openAccessId, refreshContext);
        oaLog('Got new access token for Open Access.', { openAccessIdToken });

        handleNewRefreshContext(openAccessIdToken.openAccessId, openAccessIdToken.refreshContext, setAuthInfo);
        setAuthInfoForOpenAccess(openAccessIdToken, setAuthInfo);
    } catch (err) {
        oaLog(
            "Failed to refresh OA token. Probably expired, so deleting refresh token so we don't try to re-use.",
            err
        );
        deleteRefreshTokenFromLocalStorage(openAccessId);
        throw err;
    }
}

function handleNewRefreshContext(
    openAccessId: string,
    refreshContext: RefreshTokenContext | undefined,
    setAuthInfo: SetAuthInfoFn
) {
    if (refreshContext) {
        storeRefreshTokenInLocalStorage(openAccessId, refreshContext);
        scheduleRefresh(setAuthInfo);
    }
}

function setAuthInfoForOpenAccess(openAccessToken: OpenAccessIdToken, setAuthInfo: SetAuthInfoFn) {
    setAuthInfo({
        name: 'openaccess',
        id: '',
        accountIdentifier: openAccessToken.openAccessId,
        openAccessId: openAccessToken.openAccessId,
        openAccessTargetId: openAccessToken.openAccessTargetId,
        openAccessWorkspaceId: openAccessToken.openAccessWorkspaceId,
        tenant: openAccessToken.openAccessTenantId,
        tenants: [openAccessToken.openAccessTenantId],
        passwordReset: false,
        isLocal: false,
        getToken: () => openAccessToken.id_token
    });
}

function scheduleRefresh(setAuthInfo: SetAuthInfoFn) {
    // Renew our access token before it expires, so we stay logged in indefinitely (anonymously-accessible shares),
    // or until our maximum session length (for shares that require authentication).
    // Currently our access tokens expire every 12 hours.
    //
    clearTimeout(refreshTimeout);
    const refreshIntervalMs = 11 * 60 * 60 * 1000; // 11 hours

    refreshTimeout = setTimeout(() => {
        extendOpenAccessSession(setAuthInfo)
            .then(() => {
                oaLog('Acquired new OA access token, so can continue to display dashboard for another 12 hours.');
            })
            .catch((err) => {
                oaLog('Failed to acquire new access token, so session will end when the access token expires.', err);
            });
    }, refreshIntervalMs);
}
