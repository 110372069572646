import { FormattedStreamValue } from '@squaredup/data-streams';
import { TruncatedText } from 'components/TruncatedText';
import Tooltip from 'components/tooltip/Tooltip';

export const ValueCell: React.FC<FormattedStreamValue> = ({ raw, formatted }) => {
    const rawString = String(raw ?? '');

    return rawString && rawString !== formatted ? (
        <Tooltip disabled={false} className='truncate' title={rawString}>
            {formatted}
        </Tooltip>
    ) : (
        <TruncatedText title={formatted}>{formatted}</TruncatedText>
    );
};
