import { VisualisationOptionAction } from 'dashboard-engine/types/Visualisation';

interface NumberParams {
    name: string;
    title: string;
    options: {
        max?: number;
        min?: number;
        step?: number;
        defaultValue?: number;
    };
    config: any;
    onChange: (action: VisualisationOptionAction) => void;
}

export default function Number({ name, options, config, onChange }: NumberParams) {
    const { defaultValue, ...props } = options;
    const value = config?.columns || defaultValue || options.min || 1;

    const handleChange = (event: any) => {
        let parsedValue = parseInt(event.target.value);

        if (!isNaN(parsedValue)) {
            if (options.min && parsedValue < options.min) {
                parsedValue = options.min;
            }
            if (options.max && parsedValue > options.max) {
                parsedValue = options.max;
            }
            onChange({ action: `${name}-number`, data: parsedValue });
        }
    };

    return (
        <div aria-label={name}>
            <input
                name={name}
                type='number'
                className='w-full border-none ring-inset ring-1 ring-outlinePrimary text-textPrimary rounded-input leading-input focus:ring-0 focus:outline-none focus-visible:ring-inset focus-visible:ring-1 py-input px-md bg-componentBackgroundPrimary focus:ring-outlineSecondary hover:ring-outlineSecondary placeholder:text-textIncomplete disabled:text-textDisabled disabled:pointer-events-none'
                value={value}
                onChange={handleChange}
                {...props}
            />
        </div>
    );
}
