import { handleError, handleResponse } from './util';

import type { DashboardType } from '@squaredup/dashboards';
import API from './API';

/*
    react-query key for dashboard data
 */
export const DASHBOARDS = 'dashboards';
export const DETAIL = 'detail';

// Using a consistent refetch interval for workspace/dashboard health refetches helps
// us keep the UI updates in sync (to some extent).
//
export const workspaceAndDashboardHealthRefetchInterval = 60_000;
/**
 * @deprecated use the hook useDashboards to get this data
 */
export const List = async (): Promise<DashboardType[]> => {
    return API.get('/dashboards').then(handleResponse).catch(handleError);
};

export const Get = async (id: string): Promise<DashboardType> => {
    return API.get(`/dashboards/${id}`).then(handleResponse).catch(handleError);
};

export const Create = async (workspaceId: string, displayName?: string, content?: any, group?: string) => {
    if (!workspaceId) {
        throw new Error('A workspace ID must be provided');
    }
    const created = await API.post<DashboardType>('/dashboards', { workspaceId, displayName, content, group })
        .then(handleResponse)
        .catch(handleError);

    return created;
};

export const Update = async (id: string, dashboard: object): Promise<DashboardType | undefined> => {
    const data = await API.put(`/dashboards/${id}`, dashboard).then(handleResponse).catch(handleError);
    return data;
};

export const Delete = async (id: string) => {
    await API.delete(`/dashboards/${id}`).then(handleResponse).catch(handleError);
    return true;
};

export const Clone = async (id: string) => {
    return API.put(`/dashboards/${id}/clone`).then(handleResponse).catch(handleError);
};

export const CopyTo = async (dashboardId: string, sourceWorkspaceId: string, targetWorkspaceId: string) => {
    try {
        const data = await API.post('/dashboards', {
            workspaceId: targetWorkspaceId,
            from: {
                workspaceId: sourceWorkspaceId,
                dashboardId
            }
        })
            .then(handleResponse)
            .catch(handleError);

        return data;
    } catch (e: any) {
        return e.response.data;
    }
};
