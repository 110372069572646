import { faCheck, faLink, faPause, faPlay, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { formatDistanceToNow } from 'date-fns';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import TruncateWithTooltip from '../TruncateWithTooltip';
import { Share } from './OpenAccess';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { Action } from 'pages/components/ApplicationTable/types';
import { ColumnDef } from '@tanstack/react-table';
import { sortTimeAgo } from 'pages/components/ApplicationTable/ApplicationTableSort';

interface OpenAccessTableProps {
    shares: Share[];
    onCopy: (share: Share) => void;
    onToggle: (share: Share) => void;
    onDelete: (share: Share) => void;
    canCopy: boolean;
    canToggle: (share: Share) => boolean;
    canDelete: (share: Share) => boolean;
}

const OpenAccessApplicationTable = ApplicationTable<Share, string>();

const OpenAccessTable: FC<OpenAccessTableProps> = ({
    shares,
    canCopy,
    onCopy,
    canToggle,
    onToggle,
    canDelete,
    onDelete
}) => {
    const columns: ColumnDef<Share>[] = [
        {
            id: 'status',
            header: 'Status',
            cell: ({ getValue }) => getValue(),
            size: 150,
            accessorKey: 'statusText'
        },
        {
            id: 'dashboard',
            header: 'Dashboard',
            cell: ({ row }) => {
                return (
                    <TruncateWithTooltip title={row.original.dashboardName || ''}>
                        <Link
                            to={`/dashboard/${row.original.targetId}`}
                            className='truncate cursor-pointer text-textLink hover:underline'
                        >
                            {row.original.dashboardName}
                        </Link>
                    </TruncateWithTooltip>
                );
            },
            size: 300,
            accessorKey: 'dashboardName'
        },
        {
            id: 'workspace',
            header: 'Workspace',
            cell: ({ row }) => {
                return (
                    <TruncateWithTooltip title={row.original.workspaceName || ''}>
                        <div className='truncate'>{row.original.workspaceName}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 200,
            accessorKey: 'workspaceName'
        },
        {
            id: 'sharedWith',
            header: 'Shared With',
            cell: ({ row }) => row.original.sharedWithText,
            size: 130,
            accessorKey: 'sharedWithText'
        },
        {
            id: 'createdBy',
            header: 'Created By',
            cell: ({ row }) => {
                const createdBy = row.original.properties.createdByExternalId;
                return (
                    <TruncateWithTooltip title={createdBy || ''}>
                        <div className='truncate'>{createdBy}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 200,
            accessorKey: 'properties.createdByExternalId'
        },
        {
            id: 'lastAccessed',
            header: 'Last Accessed',
            cell: ({ row }) => row.original.lastLoginDate ? formatDistanceToNow(row.original.lastLoginDate, { addSuffix: true }) : '(never)',
            sortingFn: (rowA, rowB, columnId) => sortTimeAgo<Share>(rowA, rowB, columnId),
            sortDescFirst: false,
            enableGlobalFilter: false,
            size: 180,
            accessorKey: 'lastLoginDate'
        }
    ];

    const actions: Action[] = [
        {
            action: onCopy,
            icon: faLink,
            ...(canCopy && { confirmationIcon: faCheck }),
            tooltip: 'Copy link',
            ...(canCopy && { confirmationTooltip: 'Link copied' }),
            dataTestId: 'copyShareButton'
        },
        {
            visible: canToggle,
            action: onToggle,
            icon: (share: any) => (share.properties.enabled ? faPause : faPlay),
            tooltip: (share: any) => (share.properties.enabled ? 'Disable link' : 'Enable link'),
            dataTestId: 'toggleShareButton'
        },
        {
            visible: canDelete,
            action: onDelete,
            icon: faTrash,
            tooltip: 'Delete link',
            dataTestId: 'deleteShareButton'
        }
    ];

    return (
        <OpenAccessApplicationTable
            config={{
                actions,
                noDataMessage: 'There are no shared dashboards configured.'
            }}
            data={shares}
            columns={columns}
        />
    );
};

export default OpenAccessTable;
