import { Serialised } from '@squaredup/ids';
import type { DashboardType } from '@squaredup/dashboards';
import type { OpenAccessShare } from 'dynamo-wrapper';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { ListSharesForTarget } from 'services/OpenAccessService';

type Options = UseQueryOptions<
    Serialised<OpenAccessShare> | undefined,
    unknown,
    Serialised<OpenAccessShare> | undefined,
    Array<string>
>;

/**
 * useQuery to fetch the dashboard sharingn link
 * @param options The useMutation options e.g. refreshInterval
 * @param id of the dashboard
 * @returns a useQuery variable
 */
export const useDashboardSharingLink = ({ id, ...options }: Options & Pick<DashboardType, 'id'>) => {
    return useQuery({
        ...options,
        queryKey: dashboardQueryKeys.openAccessForDashboard(id),
        queryFn: async () => {
            const existingOpenAccessShares = await ListSharesForTarget(id);

            if (existingOpenAccessShares.length > 0) {
                return existingOpenAccessShares[0];
            }
        }
    });
};
