import { groupBy } from 'lodash';
import { UseQueryOptions } from 'react-query';
import { List } from 'services/DashboardService';
import { useDashboards } from './useDashboards';

export type QueryReturnType = Awaited<ReturnType<typeof List>>;

/**
 * useQuery returning the dashboards within the passed workspace. It uses the DashboardService List API
 * and leverages the `select` query option to group the data and select only those for the passed workspace.
 *
 * If you pass `select` it will operate on the dashboards for the workspace
 * @param workspaceId The workspaceId you'd like dashboards for
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of dashboards for the passed workspace
 */
export const useDashboardsForWorkspace = <T = QueryReturnType>(
    workspaceId?: string | null,
    options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>
) => {
    const { select, enabled, ...queryOptions } = options ?? {};

    return useDashboards<T>({
        enabled: (enabled === undefined || enabled) && Boolean(workspaceId),
        select: (data) => {
            const workspaceDashboards = groupBy(data, 'workspaceId')?.[workspaceId!] ?? [];

            if (select) {
                return select(workspaceDashboards);
            }

            return workspaceDashboards as T;
        },
        ...queryOptions
    });
};
