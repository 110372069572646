import { VisualisationOption } from 'dashboard-engine/types/Visualisation';
import { matchesData, findIframeLinkColumn } from './dataUtils';
import { ColumnDropdown } from '../sharedComponents/ColumnDropdown';
import { DataStreamIframeConfig } from './Config';

const DataStreamIframeOptions: VisualisationOption<DataStreamIframeConfig> = {
    initialPanels: () => [{ name: 'mapping', isOpen: true }],
    dataMappingComponent: ({ columns, config, onChange }) => (
        <>
            <ColumnDropdown
                label='URL'
                name='linkColumn'
                help='Select the column to use as the embedded URL'
                columns={columns}
                defaultColumn={findIframeLinkColumn(columns)}
                config={config}
                onChange={onChange}
            />
        </>
    ),
    handlers: {
        'linkColumn-select': (config, data) => {
            return {
                ...config,
                linkColumn: data
            };
        }
    },
    validate: (_, config) => {
        return config;
    },
    matchesData
};

export default DataStreamIframeOptions;
