import clsx from 'clsx';
import * as React from 'react';

export const Table = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(
    ({ className, ...props }, ref) => (
        <table
            ref={ref}
            className={clsx('relative border-collapse border-spacing-0 caption-bottom table-fixed text-sm', className)}
            {...props}
        />
    )
);
