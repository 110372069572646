import { Serialised } from '@squaredup/ids';
import { AxiosError } from 'axios';
import Copy from 'components/Copy';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import type { ApiKey as ApiKeyEntity } from 'dynamo-wrapper';
import trackEvent from 'lib/analytics';
import isFunction from 'lodash/isFunction';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { APIKEY_DATA, Add } from 'services/ApiKeyService';

interface ApiKeyProps {
    buttonTitle?: string;
    currentValue?: { lastFour: string };
    disabled?: boolean;
    keyDescription?: string;
    keyDisplayName?: string;
    keyFormatter?: (key: string) => string;
    postSubmit?: (apiKey: { id: string; lastFour: string }) => void;
    subType?: string;
    /**
     * setErrorField: The name of the input field that will have errors set on
     * it using react-hook-forms
     */
    setErrorField?: string;
    setApiKeyErrors?: (errorsExist: boolean) => void;
}

interface ValidatedFormData {
    displayName: string;
    description: string;
}

export const APIKEY = '_APIKEYRECORD_';

/**
 * Shows a button to generate an API key that must be clicked before form can
 * be submitted.
 */
export default function ApiKey({
    disabled,
    buttonTitle,
    keyDisplayName,
    keyDescription,
    keyFormatter,
    postSubmit,
    subType,
    currentValue,
    setErrorField,
    setApiKeyErrors
}: ApiKeyProps) {
    const [apiKey, setApiKey] = useState<string | null>(null);

    const queryClient = useQueryClient();
    const {
        setError,
        formState: { errors }
    } = useFormContext();
    useEffect(() => {
        setApiKeyErrors?.(Object.keys(errors).length > 0);
    }, [errors, setApiKeyErrors]);

    const { mutate: addApiKey, isLoading } = useMutation(
        (data: ValidatedFormData) => Add(data.displayName, data.description, subType),
        {
            onSuccess: (created) => {
                const { id, key } = created;
                trackEvent('API Key Created', { type: subType });

                const formattedKey = keyFormatter ? keyFormatter(key) : key;
                const lastFour = formattedKey.slice(-4);

                setApiKey(formattedKey);

                queryClient.setQueryData(APIKEY, id);
                queryClient.setQueryData(
                    APIKEY_DATA,
                    (
                        apiKeys: Serialised<
                            ApiKeyEntity & {
                                key: string;
                            }
                        >[] = []
                    ) => {
                        return apiKeys.concat(created);
                    }
                );

                postSubmit?.({ id, lastFour });
            },
            onError(error) {
                if (setErrorField) {
                    setError(setErrorField, {
                        type: 'customError',
                        message: (error as AxiosError<any>).response?.data.error
                    });
                    setApiKeyErrors && setApiKeyErrors(Object.keys(errors).length > 0);
                }

                queryClient.invalidateQueries(APIKEY_DATA);
            }
        }
    );

    const clickHandler = () => {
        const displayName = isFunction(keyDisplayName) ? keyDisplayName() : keyDisplayName;
        const description = isFunction(keyDescription) ? keyDescription() : keyDescription;

        addApiKey({ displayName, description });
    };

    return (
        <div className='w-full align-middle rounded text-textSecondary'>
            {!apiKey && !isLoading && currentValue && (
                <>
                    <Button variant='secondary' onClick={clickHandler} disabled={disabled} data-testid='createApiKey'>
                        {'Regenerate API key'}
                    </Button>
                    {currentValue && (
                        <div className='m-2'>
                            Existing key{' '}
                            <code>
                                {'*'.repeat(16)}
                                {currentValue.lastFour}
                            </code>{' '}
                            will be replaced. Any references to this key will need to be updated.
                        </div>
                    )}
                </>
            )}
            {!apiKey && !isLoading && !currentValue && (
                <div className='flex justify-center'>
                    <Button onClick={clickHandler} disabled={disabled} data-testid='createApiKey'>
                        {buttonTitle || 'Generate API key'}
                    </Button>
                </div>
            )}
            {!apiKey && isLoading && (
                <div className='p-2 text-center'>
                    <LoadingSpinner />
                </div>
            )}
            {apiKey && (
                <div className='flex px-5 py-2 text-center border rounded text-textPrimary bg-tileBackground border-tileOutline'>
                    <pre className='flex-grow overflow-x-auto' data-testid='generatedApiKey'>
                        {apiKey}
                    </pre>
                    <Copy value={apiKey} title='Copy API key to clipboard' />
                </div>
            )}
        </div>
    );
}
