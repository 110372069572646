import Text from '@/components/Text';
import { Radio as RadioPrimitive } from '@/components/forms/Radio';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { RegisterOptions, UseFormRegister } from 'react-hook-form';

type RadioProps = React.ComponentPropsWithoutRef<typeof RadioPrimitive> & {
    name: string;
    label: string;
    labelNode?: React.ReactNode;
    help?: string;
    checkboxLabel?: string;
    description?: React.ReactNode;
    validation?: RegisterOptions;
    register: UseFormRegister<Record<string, any>>;
    icon?: IconProp;
};

/**
 * Radio
 * A radio input component
 */
function Radio({ name, label, labelNode, register, description, validation, icon, ...inputProps }: RadioProps) {
    return (
        <label
            className='flex select-none items-center gap-3 text-textPrimary aria-[disabled="true"]:text-textDisabled'
            aria-disabled={inputProps.disabled}
        >
            <div className='relative inline-flex items-center flex-shrink-0'>
                <RadioPrimitive {...register(name)} required={validation?.required ? true : false} {...inputProps} />
            </div>

            {icon && <FontAwesomeIcon icon={icon} />}

            <span className='flex flex-col leading-6'>
                <span>{labelNode ? labelNode : label}</span>
                {description && (
                    <Text.SmallBody className='text-textSecondary mt-0.5 font-'>{description}</Text.SmallBody>
                )}
            </span>
        </label>
    );
}

Radio.propTypes = {
    /**
     * Name of the input, e.g. in the output data
     */
    name: PropTypes.string.isRequired,
    /**
     * Text to be displayed as radio label
     */
    label: PropTypes.string.isRequired,
    /**
     * Text to be displayed as radio description
     */
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /**
     * Register function for forms - see React Hook Form
     */
    register: PropTypes.func,
    /**
     * Validation options, see React Hook Form
     */
    validation: PropTypes.object
};

export default Radio;
