import { VisualisationHint } from '@squaredup/data-streams';
import { useEffect } from 'react';

export const useAutomaticallySetBestVisualization = (
    canSetVisualization: boolean,
    validVisualizations: VisualisationHint[],
    selectVisualization: (visualization: VisualisationHint) => void
) => {
    const [bestVisualization] = validVisualizations;

    // If there is data, the config changes and the current visualization isn't valid, update the visualization
    useEffect(() => {
        if (canSetVisualization && bestVisualization) {
            selectVisualization(bestVisualization);
        }
    }, [canSetVisualization, bestVisualization, selectVisualization]);
};
