import { cn } from '@/lib/cn';
import useOverflowing from 'lib/useOverflowing';
import Tooltip from './tooltip/Tooltip';

export interface TruncatedTextWithPrefixProps {
    prefix?: string;
    text: string;
    className?: string;
    prefixClasses?: string;
    textClasses?: string;
}

export const TruncatedTextWithPrefix: React.FC<TruncatedTextWithPrefixProps> = ({
    prefixClasses,
    prefix,
    text,
    textClasses,
    className
}) => {
    const { ref: textRef, isOverflowing: textOverflowing } = useOverflowing();
    const { ref: prefixRef, isOverflowing: prefixOverflowing } = useOverflowing();

    return (
        <Tooltip
            title={`${prefix ? prefix + ' / ' : ''}${text}`}
            disabled={!(textOverflowing || prefixOverflowing)}
            className={cn('overflow-hidden truncate flex whitespace-nowrap w-min max-w-full', className)}
        >
            {prefix && (
                <span
                    ref={prefixRef}
                    className={cn('basis-0 flex-1 truncate', prefixClasses)}
                    style={{ minWidth: `${Math.min(5, prefix.length - 1)}ch` }}
                >
                    {prefix}
                </span>
            )}
            <span ref={textRef} className={cn('truncate', textClasses)}>
                {prefix && <span className='px-1'>/</span>}
                {text}
            </span>
        </Tooltip>
    );
};
