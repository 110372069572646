import { HealthState } from '@squaredup/monitoring';
import { useEffect, useRef } from 'react';
import { useSetNetworkNodeHealthState } from '../context/NetworkMapStoreContext';

export const useStoreHealthStateNode = (id: string, healthState?: HealthState) => {
    const previousState = useRef<string>();
    const updateNodeHealthState = useSetNetworkNodeHealthState();

    useEffect(() => {
        if (previousState.current !== healthState) {
            previousState.current = healthState;
    
            updateNodeHealthState(id, healthState);
        }
    }, [healthState, id, updateNodeHealthState]);
};
