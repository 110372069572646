import TileEditorObjectsFilterContext from '../../contexts/TileEditorObjectsFilterContext';
import { FallbackDefaults, ObjectFilters, useDataStreamObjectFilters } from '../hooks/useDataStreamObjectFilters';

interface ObjectsFilterContextWrapperProps {
    objectFilters?: ObjectFilters;
    fallbackDefaults?: FallbackDefaults;
}

/**
 * Wraps children in ObjectsFilterContext, providing functions to filter and scope objects
 */
export const ObjectsFilterContextWrapper: React.FC<ObjectsFilterContextWrapperProps> = ({
    objectFilters,
    fallbackDefaults,
    children
}) => {
    const objectFilterState = useDataStreamObjectFilters(objectFilters, fallbackDefaults);

    return (
        <TileEditorObjectsFilterContext.Provider value={objectFilterState}>
            {children}
        </TileEditorObjectsFilterContext.Provider>
    );
};
