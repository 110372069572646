import type { FormattedStreamData, FoundColumn } from '@squaredup/data-streams';
import { SuccessfulResult } from '@squaredup/utilities';
import { getAllDonutData } from 'dashboard-engine/visualisations/DataStreamDonut/dataUtils';

type ValidateDatum = {
    value: number;
};

type Columns = SuccessfulResult<{
    valueColumn: FoundColumn;
    labelColumn: FoundColumn;
}>;

export const validateNonEmptyData = (data: ValidateDatum[]) => {
    return data.length > 0 && data.some((s) => s.value !== 0);
};

export const validateDonutData = (data: FormattedStreamData, columns: Columns) => {
    const series = getAllDonutData(data, columns.value);
    return validateNonEmptyData(series);
};
