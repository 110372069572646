import { ApplicationSubdomain, sanitiseSubdomain } from '@squaredup/tenants';
import { isOpenAccess } from 'lib/openAccessUtil';
import { GetApplicationSubdomain } from 'services/SubdomainService';
import { Serialised } from '@squaredup/ids';

let requiredIdentityProvider: string | undefined | null;
let applicationSubdomain: Serialised<ApplicationSubdomain> | undefined;
let getApplicationSubdomainPromise: Promise<Serialised<ApplicationSubdomain>>; // Ensure a single API request
let forceSignInAfterSignOut = false;
let signUpAfterSignOut = false;

/**
 * Get info about the subdomain we're running the app on, if any.
 * Returns {} if running on the standard domain (e.g. app.squaredup.com).
 * 
 * The result is cached so you can call this multiple times without invoking extra round-trips.
 * Populates requiredIdentityProvider if appropriate.
 */
export async function getApplicationSubdomain(appLoadUrl: URL): Promise<Serialised<ApplicationSubdomain>> {
    if (applicationSubdomain) {
        return applicationSubdomain;
    }

    const subdomainFromUrl = getSubdomainFromAppUrl(appLoadUrl);
    if (!subdomainFromUrl) {
        return {}; // Just standard app URL, e.g. app.squaredup.com, so not subdomain info
    }

    // Load subdomain info from server if we haven't already.
    if (!getApplicationSubdomainPromise) {
        getApplicationSubdomainPromise = GetApplicationSubdomain(subdomainFromUrl);
    }
    applicationSubdomain = await getApplicationSubdomainPromise;
    
    // If the subdomain only allows one identity provider, we can use a domain_hint later to tell B2C 
    // to go straight to that identity provider and skip the sign-in page.
    if (applicationSubdomain.identityProviders?.length === 1 && applicationSubdomain.identityProviders[0]) {
        requiredIdentityProvider = applicationSubdomain.identityProviders[0].trim();
    }

    return applicationSubdomain;
}

/**
 * Get the required identity provider (MS, Google, Okta etc) for this app subdomain, if there is one.
 * 
 * This can be used to tell B2C which identity provider to use (MS, Google, Okta etc), so the user doesn't
 * need to choose (i.e. our sign-in page is bypassed).
 *
 * This may be done for convenience, but more important it is required for customer-specific
 * IdPs like Okta where the sign-in page has no button (because each customer needs a different button).
 *
 * For standard app sign-in we don't know in advance who the user is or what tenant they are
 * in, so we cannot check tenant settings to get the IdP. So it has to be supplied up front
 * via query parameters or a sub-domain.
 */
export async function getRequiredIdentityProvider(appLoadUrl: URL): Promise<string | undefined> {
    if (requiredIdentityProvider) {
        return requiredIdentityProvider;
    }

    if (!appLoadUrl) {
        return undefined;
    }

    // The 'proper' way to specify a required identity provider is through an app subdomain,
    // but for testing/engineering use we support a hacky altermative via a query parameter.
    const queryParams = new URLSearchParams(appLoadUrl.search);
    const requiredIdPFromQueryParams = queryParams.get('idp');

    if (!requiredIdPFromQueryParams) {
        // Load app subdomain if we haven't already. This populates requiredIdentityProvider as a side-effect.
        await getApplicationSubdomain(appLoadUrl);
    }

    return requiredIdPFromQueryParams ?? requiredIdentityProvider ?? undefined;
}

export function getPostLogoutRedirectUri(appLoadUrl: URL): string | undefined {
    // Remove any token from the URL because if we're logging out it's probably because that
    // token is no longer valid, and we don't want to get stuck in a reload loop trying to use
    // a broken token over and over.
    const sanitisedAppLoadUrl = new URL(appLoadUrl);
    sanitisedAppLoadUrl.searchParams.delete('token');

    if (signUpAfterSignOut === true) {
        return getSignUpUrl(sanitisedAppLoadUrl);
    }

    // Normally when we sign out we redirect to our app, to trigger display of the sign-in page.
    //
    // But if we've got a domain hint, sign-in will skip the sign-in page and go direct to the identity
    // provider. That's ok in some scenarios, e.g. token expiry, where an automatic sign-back-in is fine,
    // desirable even.  But for the scenario where a user has explicitly signed out, it's highly undesirable.
    //
    // Note that we're using the cached requiredIdentityProvider here because this function can't be async so
    // we can't call getRequiredIdentityProvider. This hopefully shouldn't be an issue because this is called
    // during sign-out and the user should have loaded the app some time ago.
    const useSignOutCompletePage = requiredIdentityProvider && !forceSignInAfterSignOut && !isOpenAccess();
    return useSignOutCompletePage ? getSignOutCompleteUrl(sanitisedAppLoadUrl) : sanitisedAppLoadUrl.toString();
}

export function setForceSignInAfterSignOut(force: boolean) {
    forceSignInAfterSignOut = force;
}

export function setSignUpAfterSignOut(enabled: boolean) {
    signUpAfterSignOut = enabled;
}

function getSubdomainFromAppUrl(appLoadUrl: URL): string | undefined {
    const subdomain = sanitiseSubdomain(appLoadUrl.hostname.split('.')[0]) ?? 'app';
    if (['app', 'eu', 'uk', 'preprod'].includes(subdomain)) {
        return undefined;
    }
    return subdomain;
}

function getSignOutCompleteUrl(appLoadUrl: URL): string {
    return `${appLoadUrl.origin}/signoutComplete`;
}

function getSignUpUrl(appLoadUrl: URL): string {
    return `${appLoadUrl.origin}?signup=true`;
}
