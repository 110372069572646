import { Switch } from '@/components/Switch';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import Field, { FormFieldProps } from '../Field';

type FieldSwitchProps = FormFieldProps &
    {
        name: string;
        options: { label: string; value: string }[];
        validation?: RegisterOptions;
        disabled?: boolean;
    }

export const FieldSwitch: React.FC<FieldSwitchProps> = ({ 
    name,
    label,
    description,
    options,
    validation,
    help,
    helpAlign,
    descriptionAbove,
    disabled
}: FieldSwitchProps) => {
    const { control } = useFormContext();

    return (
        <Field
            label={label}
            description={description}
            required={Boolean(validation?.required)}
            help={help}
            helpAlign={helpAlign}
            descriptionAbove={descriptionAbove}
            htmlFor={name}
        >
            <Controller
                control={control}
                name={name}
                render={({ field: { value, onChange, ref } }) => (
                    <Switch 
                        options={options}
                        value={value}
                        disabled={disabled}
                        ref={ref}
                        onValueChange={(option) => onChange(option)}
                    />
                )}
            />
        </Field>
    );
};
