import { scopeLimitMaximum } from '@squaredup/constants';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { useDashboardContext } from 'contexts/DashboardContext';
import { getNameForType } from 'lib/types';
import type { Scope } from 'pages/scope/ScopesPage';
import { useScopes } from 'queries/hooks/useScopes';

/**
 * A function that checks a scope's query string or query detail to check if a limit is present
 * @param scope A resolved scope with either a query or queryDetail
 * @returns A number which is the limit, or false if no limit is found
 */
export const checkScopeHasLimit = ({ query, queryDetail }: { query?: string; queryDetail?: any }) => {
    const parsedQueryDetail = typeof queryDetail === 'string' ? JSON.parse(queryDetail || '{}') : queryDetail;

    // Limit may be supplied as a query filter in the scope's queryDetail, use that if present
    if (parsedQueryDetail && parsedQueryDetail.limit) {
        // Some OOB scopes don't apply a limit and default to -1/unlimited
        if (parsedQueryDetail.limit === -1) {
            return false;
        }
        return parsedQueryDetail.limit as number;
    }

    if (!query) {
        return false;
    }

    // Match the occurrance of `.limit(n)` at the end of the query string
    // Ignores any limits used elsewhere in the query
    const limitMatch = query.match(/\.limit\(([^)]+)\)$/u);

    if (!limitMatch || limitMatch[1] == null) {
        return false;
    }

    const limitNumber = parseInt(limitMatch[1]);

    // Ignore if we haven't found a valid limit
    if (isNaN(limitNumber)) {
        return false;
    }

    // If the limit we have found is the scope limit maximum, which is appended automatically to all
    // dynamic scopes, this is a user-created scope so we ignore it
    if (limitNumber === scopeLimitMaximum) {
        return false;
    }

    return limitNumber;
};

const getObjectTypeNameForOobScope = (queryDetail: any) => {
    const parsedQueryDetail = typeof queryDetail === 'string' ? JSON.parse(queryDetail || '{}') : queryDetail;

    if (parsedQueryDetail?.types?.[0]?.value) {
        return getNameForType(parsedQueryDetail.types[0].value, 'singular');
    }
};

/**
 * A hook that checks if a scope is an OOB dashboard scope, and if it is, whether it has a limit
 * @param config The tile config to check
 */
export const useCheckScopeIsOobAndHasLimit = (
    config: DataStreamBaseTileConfig
): {
    limit?: number | false;
    isSavedScope?: boolean;
    isLoading?: boolean;
    scopeDisplayName?: string;
    scopeId?: string;
    scope?: Scope;
    objectType?: string;
} => {
    const isSql = config?.dataStream?.id === 'datastream-sql';

    const firstTable = config?.dataStream?.dataSourceConfig?.tables?.[0]?.config?.scope;

    const scope = isSql ? firstTable : config?.scope;

    const dashboardContext = useDashboardContext();
    const { data: scopes, isLoading } = useScopes(dashboardContext.currentWorkspace?.id!, {
        enabled: Boolean(dashboardContext.currentWorkspace?.id) && scope && 'scope' in scope
    });

    if (isLoading) {
        return {
            isLoading,
            limit: false
        };
    }

    if (!scope || typeof scope === 'string' || Array.isArray(scope)) {
        return { limit: false };
    }

    if ('query' in scope) {
        return {
            limit: checkScopeHasLimit(scope),
            objectType: getObjectTypeNameForOobScope(scope.queryDetail)
        };
    } else if (scopes && 'scope' in scope) {
        const foundScope = scopes.find((s: { id: string }) => s.id === scope.scope);

        if (!foundScope) {
            // scope possibly deleted
            return { limit: false };
        }

        return {
            limit: checkScopeHasLimit(foundScope.data),
            isSavedScope: true,
            scopeDisplayName: foundScope.displayName,
            scopeId: foundScope.id,
            scope: foundScope,
            objectType: getObjectTypeNameForOobScope(foundScope.data.queryDetail)
        };
    }

    return { limit: false };
};
