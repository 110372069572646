import type { DashboardVariable } from '@squaredup/dashboards';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { saveScopeToStorage } from './variableLocalStorageUtils';

export const setVariableParamsOnUrlSearchParams = (search: URLSearchParams, variables: DashboardVariable[]) => {
    search.delete('nodeId');
    search.delete('selectedAllNodes');
    variables.flatMap((v) => v.selectedObjects).forEach((o) => search.append('nodeId', o.id));

    if (variables[0]?.selectedAll) {
        search.set('selectedAllNodes', 'true');
    }
};

export const useSetVariableSearchParams = (variables: DashboardVariable[]) => {
    const [search, setSearchParams] = useSearchParams();

    useEffect(() => {
        setVariableParamsOnUrlSearchParams(search, variables);
        //Update localstorage with new values
        variables.map((variable) => saveScopeToStorage(variable.scopeId, variable.allowMultipleSelection, variable));

        setSearchParams(search, { replace: true });
    }, [variables, search, setSearchParams]);
};
