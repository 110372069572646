import { fiveMinutes } from 'queries/constants';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { ListScopes } from 'services/WorkspaceService';

type QueryReturnType = Awaited<ReturnType<typeof ListScopes>>;
type QuerySelectReturnType = QueryReturnType;

/**
 * useQuery returning the saved scopes for a specific workspace
 * @param workspaceId The workspaceId you'd like scopes for
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of saved scopes
 */
export const useScopes = (
    workspaceId?: string | null,
    options?: UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>
) => {
    const { enabled, ...queryOptions } = options ?? {};

    return useQuery(scopeQueryKeys.workspace(workspaceId), async () => ListScopes(workspaceId!), {
        staleTime: fiveMinutes,
        cacheTime: fiveMinutes,
        keepPreviousData: true,
        enabled: (enabled === undefined || enabled) && Boolean(workspaceId),
        ...queryOptions
    });
};
