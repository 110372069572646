import { Skeleton } from '@/components/Skeleton';
import { cn } from '@/lib/cn';
import { useMemo } from 'react';

/**
 * Generates a random bar chart for the skeleton
 * @param isHorizontal - whether the bar chart is horizontal or vertical
 */
function generateBarChart(isHorizontal: boolean) {
    const barCount = 5;
    const barSpacing = isHorizontal ? 12 : 30;
    const barThickness = isHorizontal ? 30 : 50;
    const barSpace = barSpacing + barThickness;
    const patternSize = (barSpacing + barThickness) * barCount;
    // min and max length of bars as a percentage of the pattern size
    const minLengthPercent = 30;
    const maxLengthPercent = 100;

    let maskImage = [];
    let maskSize = [];

    for (let i = 0; i < barCount; i++) {
        maskImage.push(
            `linear-gradient(to ${isHorizontal ? 'bottom' : 'right'}, rgba(0,0,0,0) ${i * barSpace}px, black ${
                i * barSpace
            }px, black ${i * barSpace + barThickness}px, rgba(0,0,0,0) ${i * barSpace + barThickness}px)`
        );

        maskSize.push(
            isHorizontal
                ? `${
                      Math.floor(Math.random() * (maxLengthPercent - minLengthPercent)) + minLengthPercent
                  }% ${patternSize}px`
                : `${patternSize}px ${
                      Math.floor(Math.random() * (maxLengthPercent - minLengthPercent)) + minLengthPercent
                  }%`
        );
    }

    return { maskImage: maskImage.join(','), maskSize: maskSize.join(',') };
}

const BarChartSkeleton = ({ className, horizontalLayout }: { className?: string; horizontalLayout?: string }) => {
    const isHorizontal = horizontalLayout === 'horizontal';
    const barSpacing = isHorizontal ? 12 : 30;
    const barThickness = isHorizontal ? 30 : 50;
    const patternSize = barSpacing + barThickness;
    const barChart = useMemo(() => generateBarChart(isHorizontal), [isHorizontal]);

    return (
        <div
            className={cn(
                'grid absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[80%] w-[90%]',
                !isHorizontal && 'justify-center',
                className
            )}
            style={{
                // allows for mask to be tiled without clipping
                gridTemplateRows: isHorizontal ? `repeat(auto-fill, ${patternSize}px)` : undefined,
                gridTemplateColumns: isHorizontal ? undefined : `repeat(auto-fill, ${patternSize}px)`
            }}
        >
            <Skeleton
                className='rounded-none'
                style={{
                    maskImage: barChart.maskImage,
                    maskSize: barChart.maskSize,
                    maskRepeat: isHorizontal ? 'repeat-y' : 'repeat-x',
                    maskPosition: isHorizontal ? '0 0' : 'left bottom',
                    gridRow: isHorizontal ? '1 / -1' : undefined,
                    gridColumn: isHorizontal ? undefined : '1 / -1'
                }}
            />
        </div>
    );
};

export default BarChartSkeleton;
