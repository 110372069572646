import DropdownMenu from '@/components/DropdownMenu';
import { faMagnifyingGlassChart } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { useTileContext } from 'contexts/TileContext';
import { TileTypes } from 'dashboard-engine/constants';
import { useNavigateToExplore } from 'dashboard-engine/hooks/useNavigateToExplore';

export const Explore: React.FC = () => {
    const { config } = useTileContext();
    const { isGlobal } = useDataStreamWorkspaceContext();

    const navigateToExplore = useNavigateToExplore(config, isGlobal);
    const isDataStreamTile = config._type === TileTypes.dataStream;

    if (!isDataStreamTile) {
        return null;
    }

    return (
        <DropdownMenu.Item
            icon={<FontAwesomeIcon icon={faMagnifyingGlassChart} fixedWidth />}
            onSelect={() => navigateToExplore()}
            key='explore'
        >
            Explore data
        </DropdownMenu.Item>
    );
};
