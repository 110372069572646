import { cn } from '@/lib/cn';
import { Row } from '@tanstack/react-table';
import { Fragment } from 'react';
import { ApplicationTableActionButton, ApplicationTableActionSpinner } from './ApplicationTableActionButton';
import { Action } from './types';

export type ApplicationTableActionColumnProps<T> = {
    actions: Action[];
    row: Row<T>;
    className?: string;
    /**
     * How to display actions that have visible = false.
     *
     * - `hide`: leave empty space where hidden actions would have been
     * - `collapse`: allow visible actions to take the space where hidden actions would have been
     */
    hiddenActions?: 'collapse' | 'hide';
};

export const ApplicationTableActionColumn = <T,>({
    actions,
    row,
    className,
    hiddenActions = 'hide'
}: ApplicationTableActionColumnProps<T>) => {
    const actionColumn =
        actions.filter((a) => a.visible == null || a.visible?.(row.original)).length > 0 ? (
            <div className='sticky top-0 right-0 flex items-center self-stretch justify-end h-full pointer-events-none place-self-stretch'>
                <div
                    className={cn(
                        'grid grid-flow-col place-items-center h-full py-3 pl-8 pr-4 gap-x-1 pointer-events-auto from-[calc(100%-1rem)] to-100% bg-gradient-to-l from-backgroundSecondary group-hover:from-tableHover',
                        className
                    )}
                >
                    {actions?.map((action, index) => {
                        const visible = (action.visible && action.visible(row.original)) ?? true;
                        const loading = (action.loading && action.loading(row.original)) ?? false;

                        const icon = typeof action.icon === 'function' ? action.icon(row.original) : action.icon;
                        const confirmationIcon =
                            typeof action.confirmationIcon === 'function'
                                ? action.confirmationIcon(row.original)
                                : action.confirmationIcon;

                        const tooltip =
                            typeof action.tooltip === 'function' ? action.tooltip(row.original) : action.tooltip;
                        const confirmationTooltip =
                            typeof action.confirmationTooltip === 'function'
                                ? action.confirmationTooltip(row.original)
                                : action.confirmationTooltip;

                        const loadingTooltip =
                            typeof action.loadingTooltip === 'function'
                                ? action.loadingTooltip(row.original)
                                : action.loadingTooltip;

                        return (
                            <Fragment key={index}>
                                {visible && !loading && (
                                    <ApplicationTableActionButton
                                        onClick={() => action.action(row.original)}
                                        icon={icon}
                                        confirmationIcon={confirmationIcon}
                                        dataTestId={action.dataTestId}
                                        tooltip={tooltip}
                                        confirmationTooltip={confirmationTooltip}
                                    />
                                )}
                                {visible && loading && <ApplicationTableActionSpinner tooltip={loadingTooltip} />}
                                {!visible && hiddenActions === 'hide' && <div className='w-[17.5px]'></div>}
                            </Fragment>
                        );
                    })}
                </div>
            </div>
        ) : (
            <></>
        );
    return actionColumn;
};
