import { WorkspaceTypes, WorkspaceNotSetType } from '@squaredup/constants';
import DropdownMenu from 'components/dropdownMenu';
import { toggleArray } from './StateFilterMenu';
import { useState } from 'react';
import { upperFirst } from 'lodash';

export interface WorkspaceTypeMenuProps {
    types?: string[];
    setTypes: (type: string[]) => void;
}

export default function WorkspaceTypeMenu({ types = [], setTypes }: WorkspaceTypeMenuProps) {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const typeList = [...WorkspaceTypes];

    return (
        <DropdownMenu onOpenChange={setIsDropdownOpened}>
            <DropdownMenu.Button active={types.length > 0} className='whitespace-nowrap' dropdownOpen={isDropdownOpened} showChevron useFilterStyle>
                Type
                {types.length > 0 && (
                    <span className='px-2 ml-2 rounded-full bg-textDisabled' aria-label='numFiltersApplied'>{types.length}</span>
                )}
            </DropdownMenu.Button>
            <DropdownMenu.Menu align='start'>
                <DropdownMenu.Group>
                    {[
                        ...typeList.sort((a, b) => {
                            if (a === 'other') {
                                return 1;
                            } else if (b === 'other') {
                                return -1;
                            }
                            return a.localeCompare(b);
                        }),
                        WorkspaceNotSetType
                    ].map((type) => (
                        <DropdownMenu.CheckboxItem
                            key={type}
                            checked={types.includes(type)}
                            onCheckedChange={() => setTypes(toggleArray(types, type))}
                        >
                            {upperFirst(type)}
                        </DropdownMenu.CheckboxItem>
                    ))}
                </DropdownMenu.Group>
                <DropdownMenu.Separator />
                <DropdownMenu.SecondaryItem disabled={types.length === 0} onSelect={() => setTypes([])}>
                    Clear filter
                </DropdownMenu.SecondaryItem>
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
}
