import { getShape, getValueShapeOf, toShapeName } from '@squaredup/data-streams';
import { FC } from 'react';
import { toFormFieldName } from './MetadataEditorForm';
import { metadataEditorShapesByName } from './MetadataEditorShapes';

export const ShapeConfigField: FC<{ columnName: string; shapeName: string }> = ({ columnName, shapeName }) => {
    const shape = getShape(toShapeName(shapeName));

    if (shape == null) {
        return <></>;
    }

    const valueShape = shape?.isPrimitive ? undefined : getValueShapeOf(shape);

    const ValueShapeConfigForm =
        valueShape == null ? undefined : metadataEditorShapesByName.get(valueShape.name)?.shapeConfigFields;
    const ShapeConfigForm = metadataEditorShapesByName.get(shape.name)?.shapeConfigFields;

    if (ShapeConfigForm == null && ValueShapeConfigForm == null) {
        return <></>;
    }

    const fields =
        ShapeConfigForm == null ? (
            ValueShapeConfigForm && <ValueShapeConfigForm name={`${toFormFieldName(columnName)}.shapeConfig`} />
        ) : (
            <ShapeConfigForm name={`${toFormFieldName(columnName)}.shapeConfig`}>
                {ValueShapeConfigForm && <ValueShapeConfigForm name={`${toFormFieldName(columnName)}.shapeConfig`} />}
            </ShapeConfigForm>
        );

    return <div className='max-w-4xl'>{fields}</div>;
};
