import { AccordionContent, AccordionTrigger } from '@/components/Accordion';
import Text from '@/components/Text';
import * as Accordion from '@radix-ui/react-accordion';
import { Chevron } from 'components/Chevron';
import { getIsActivePanel } from './getActiveVisualisationPanel';
import { AnimatePresence, motion, Variants } from 'framer-motion';

const expandVariant: Variants = {
    hidden: { height: 0 },
    visible: { height: 'auto' }
};

interface VisualisationConfigAccordionProps {
    value: string,
    label: string,
    accordionControls: Accordion.AccordionMultipleProps
}

export const VisualisationConfigAccordion: React.FC<VisualisationConfigAccordionProps> = ({ 
    value, 
    label, 
    accordionControls, 
    children 
}) => {
    const open = accordionControls.value?.includes(value);

    return (
        <Accordion.Item value={value} className='w-full px-5'>
            <AccordionTrigger className='justify-between w-full py-5 select-none'>
                <Text.H4 className='pr-4 text-textPrimary'>{label}</Text.H4>
                <Chevron
                    initialDirection='down'
                    className='text-textSecondary'
                    direction={getIsActivePanel(accordionControls, value) ? 'up' : 'down'}
                />
            </AccordionTrigger>
            
            <AccordionContent forceMount={true}>
                <AnimatePresence initial={false} key={value}>
                   {open && (
                        <motion.div 
                            variants={expandVariant}
                            initial='hidden'
                            animate='visible'
                            exit='hidden'
                            className='overflow-hidden'
                            key={value}
                            transition={{ ease: 'circIn', duration: 0.15 }}
                        >
                            <div className='pb-5'>
                                {children}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </AccordionContent>
        </Accordion.Item>
    );
};