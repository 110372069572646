import { cn } from '@/lib/cn';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useController } from 'react-hook-form';
import Field from '../field/Field';

export type VerticalPosition = 'top' | 'middle' | 'bottom';
export type HorizontalPosition = 'left' | 'center' | 'right';

export type PickerPosition = { vertical: VerticalPosition; horizontal: HorizontalPosition };

export interface PositionPickerProps {
    name: string;
    label?: string;
    defaultValue?: PickerPosition;
}

const vertical = ['top', 'middle', 'bottom'];
const horizontal = ['left', 'center', 'right'];

export const PositionPicker: React.FC<PositionPickerProps> = ({ name, label }) => {
    const { field } = useController({
        name
    });

    return (
        <div>
            <div>{label && <Field.Label label={label} />}</div>
            <div className='inline-block bg-componentBackgroundPrimary border rounded border-outlinePrimary'>
                {vertical.map((v) => (
                    <div key={v}>
                        {horizontal.map((h) => (
                            <button
                                key={h}
                                className='group p-2 align-middle'
                                type='button'
                                onClick={() =>
                                    field.onChange({
                                        vertical: v,
                                        horizontal: h
                                    })
                                }
                            >
                                <div
                                    className={cn('w-1 h-1 m-1.5 rounded-full bg-textSecondary group-hover:hidden', {
                                        hidden: field.value?.vertical === v && field.value?.horizontal === h
                                    })}
                                />

                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className={cn('hidden text-textSecondary group-hover:block', {
                                        'text-primaryButtonBackground':
                                            field.value?.vertical === v && field.value?.horizontal === h,
                                        '!block': field.value?.vertical === v && field.value?.horizontal === h
                                    })}
                                />
                            </button>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};
