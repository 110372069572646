import { Edge, getIncomers, Node } from 'reactflow';

export const getAllIncomers = (node: Node, nodes: Node[], edges: Edge[], existingIncomers: Node[] = []) => {
    const incomers = getIncomers(node, nodes, edges);
    const result = incomers.reduce((currentIncomers, incomer) => {
        currentIncomers.push(incomer);

        if (!existingIncomers.some((n) => n.id === incomer.id)) {
            existingIncomers.push(incomer);

            getAllIncomers(incomer, nodes, edges, existingIncomers).forEach((foundNode) => {
                currentIncomers.push(foundNode);

                if (!existingIncomers.some((n) => n.id === foundNode.id)) {
                    existingIncomers.push(incomer);
                }
            });
        }
        return currentIncomers;
    }, [] as Node[]);
    return result;
};
