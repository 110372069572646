import { NavLink } from 'react-router-dom';

function NotFound() {
    return (
        <div className='flex flex-col items-center justify-center h-screen'>
            <div className='mx-auto text-center'>
                <h1 className='mb-1 text-2xl font-bold'>Invalid URL</h1>
                <p className='text-textSecondary'>
                    This URL is invalid, would you like to go{' '}
                    <NavLink to='/' className='text-textLink'>
                        home
                    </NavLink>
                    ?
                </p>
            </div>
        </div>
    );
}

export default NotFound;
