import { faAsterisk, faDash, faGears } from '@fortawesome/pro-solid-svg-icons';
import { getIconForType, getNameForType } from 'lib/types';

/**
 *
 * @param matchedName passed when mapping over datastreams: dataStream.definition.matchesTypes
 * @returns Object array with type name and icon
 */
export const resolveEachMatchedType = (matchedName: Array<string>) => {
    return matchedName
        .map((name: string) => {
            if (name === 'none') {
                return {
                    name: '',
                    icon: faDash
                };
            } else if (name === 'advanced') {
                return {
                    name: 'Advanced',
                    icon: faGears
                };
            } else if (name === 'all') {
                return {
                    name: 'Any',
                    icon: faAsterisk
                };
            } else {
                return {
                    name: getNameForType(name, 'singular'),
                    icon: getIconForType(name)
                };
            }
        })
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
};
