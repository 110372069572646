import LoadingSpinner from 'components/LoadingSpinner';
import { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import Form from 'components/forms/form/Form';

interface ScopeNameFormProps {
    initialValue?: string;
    onSubmit: (data: { name: string }) => void;
    onClose: () => void;
}

export const ScopeNameForm: React.FC<ScopeNameFormProps> = ({ initialValue, onSubmit, onClose }) => (
    <Form className='flex flex-col flex-1 h-full min-h-0' submit={onSubmit}>
        {(isValid, isSubmitting) => (
            <>
                <div className='flex-1 py-5 px-9'>
                    <Field.Input
                        name='name'
                        label='Name'
                        title='Name'
                        placeholder='Enter a name'
                        defaultValue={initialValue}
                        validation={{
                            required: true,
                            maxLength: 128,
                            minLength: 1
                        }}
                    />
                </div>

                <ModalButtons>
                    <Button type='button' onClick={onClose} variant='tertiary' data-testid='cancelScope'>
                        Cancel
                    </Button>
                    <Button type='submit' disabled={isSubmitting || !isValid} data-testid='saveScope'>
                        {isSubmitting ? <LoadingSpinner size={18} /> : 'Save'}
                    </Button>
                </ModalButtons>
            </>
        )}
    </Form>
);
