import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Editor, { Monaco } from '@monaco-editor/react';
import Color from 'color';
import monaco from 'monaco-editor';
import { useEffect, useState } from 'react';

type ScriptEditorArgs = {
    content: string;
    theme?: object;
    readOnly: boolean;
    contentValidator: (content: string | undefined) => string | boolean;
    onValidUpdatedContent: (content: string | undefined) => void;
    handleError: (hasError: boolean) => void;
};

const ignoreErrors = ['is declared but its value is never read', "implicitly has an 'any' type"];

const ScriptEditor: React.FC<Partial<ScriptEditorArgs>> = (props) => {
    const editorBackground = Color(getComputedStyle(document.body).getPropertyValue('--componentBackgroundSecondary')).hex();
    const { content, contentValidator, onValidUpdatedContent, theme, handleError, readOnly = false } = props;

    const [configError, setConfigError] = useState(false); // Errors in our config
    const [jsError, setJsError] = useState(''); // JavaScript language errors

    const handleEditorWillMount = (monacoEditor: Monaco) => {
        // validation settings
        monacoEditor.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
            noSemanticValidation: false,
            noSyntaxValidation: false
        });

        // compiler options
        monacoEditor.languages.typescript.javascriptDefaults.setCompilerOptions({
            target: monacoEditor.languages.typescript.ScriptTarget.ES2020,
            allowNonTsExtensions: true,
            allowJS: true,
            checkJs: false, // unfortunately just too strict
            strict: true
        });

        monacoEditor.editor.defineTheme('squpTheme', {
            base: document.body.dataset.theme === 'dark' ? 'vs-dark' : 'vs',
            inherit: true,
            rules: [{ background: editorBackground } as { background: string; token: string }],
            colors: {
                'editor.background': editorBackground,
                'scrollbar.shadow': '#ffffff00'
            },
            ...(theme || {})
        });
    };

    // Watch config/js errors and report up if there is a change
    useEffect(() => {
        handleError && handleError(Boolean(configError) || Boolean(jsError));
    }, [configError, jsError, handleError]);

    /**
     * Update the height based on the editor content height
     * @param {} editor Monaco
     */
    const updateHeight = (editor: monaco.editor.ICodeEditor) => {
        if (editor && editor.getContentHeight()) {
            const contentHeight = Math.max(330, editor.getContentHeight());
            const height = Math.min(1000, contentHeight);
            editor.layout({ height } as { height: number; width: number });
        }
    };

    const onMount = (editor: monaco.editor.ICodeEditor) => {
        updateHeight(editor);
    };

    const handleContentChange = (changedContent: string | undefined) => {
        if (jsError) {
            setConfigError(false);
        }

        try {
            const updatedContent = changedContent;

            // Allow the editor consumer chance to validate the content:
            const contentErrors = contentValidator && contentValidator(updatedContent);
            if (contentErrors) {
                // @ts-ignore
                setConfigError(contentErrors);
                return;
            }

            setConfigError(false);
            onValidUpdatedContent?.(updatedContent);
            // eslint-disable-next-line no-empty
        } catch {
            // Ignore
        }
    };

    const handleEditorValidation = (markers: { message: string; endLineNumber: number }[]) => {
        let error = '',
            ignore = false;
        if (markers.length > 0) {
            let index = 0;
            do {
                const msg = markers[index].message;
                if (msg) {
                    for (const err of ignoreErrors) {
                        if (msg.includes(err)) {
                            ignore = true;
                            break;
                        }
                    }
                    if (ignore) {
                        continue;
                    }
                }
            } while (++index < markers.length);

            if (index < markers.length && markers[index].message) {
                error = `${markers[index].message} (Line ${markers[index].endLineNumber})`;
            }
        }
        setJsError(error);
    };

    return (
        <div className='flex flex-col flex-grow w-full h-full rounded' data-testid='jsEditor'>
            <>
                <Editor
                    language='javascript'
                    path='.js'
                    theme='squpTheme'
                    loading={<span>Loading editor...</span>}
                    value={content}
                    onChange={handleContentChange}
                    onValidate={handleEditorValidation}
                    beforeMount={handleEditorWillMount}
                    onMount={onMount}
                    options={{
                        scrollBeyondLastLine: false,
                        minimap: { enabled: false },
                        padding: { top: 10 },
                        renderLineHighlight: 'none',
                        readOnly: readOnly
                    }}
                    // @ts-ignore
                    onDidContentSizeChange={updateHeight}
                    wrapperClassName='flex-grow'
                />
                {(configError || jsError) && (
                    <div className='flex pt-2 truncate flex-nowrap'>
                        <FontAwesomeIcon icon={faCircleExclamation} className='ml-2 mr-2 text-statusErrorPrimary' />
                        <div>
                            <span className='text-sm text-textSecondary'>{`Configuration error: ${
                                configError || jsError
                            }`}</span>
                        </div>
                    </div>
                )}
            </>
        </div>
    );
};

ScriptEditor.propTypes = {};

export default ScriptEditor;
