import { Serialised } from '@squaredup/ids';
import { OpenAccessShareProperties } from '@squaredup/open-access';
import type { OpenAccessShare } from 'dynamo-wrapper';
import API from './API';
import { handleError, handleResponse } from './util';

export const OA_SHARES_DATA = 'oaShares';

export type OpenAccessShareWithDetails = OpenAccessShare & {
    dashboardName?: string;
    workspaceName?: string;
    workspaceSharingEnabled?: boolean;
};

/**
 * Get all Open Access shares accessible to the caller.
 */
export const ListShares = async () =>
    API.get<Serialised<OpenAccessShareWithDetails>[]>('/openaccess/shares').then(handleResponse).catch(handleError);

/**
 * Get all of the Open Access shares for a particular target (e.g. dashboard).
 * Normally there is at most one share per target, but in theory multiple shares for a single target
 * are possible.
 */
export const ListSharesForTarget = async (targetId: string) =>
    API.get<Serialised<OpenAccessShare>[]>(`/openaccess/shares?targetId=${targetId}`)
        .then(handleResponse)
        .catch(handleError);

/**
 * Get the details for a specific Open Access share (dashboard, workspace etc)
 */
export const GetShare = async (id: string) =>
    API.get<Serialised<OpenAccessShare>>(`/openaccess/shares/${id}`).then(handleResponse).catch(handleError);

/**
 * Update the enabled state of an individual share target
 */
export const UpdateShare = async (
    id: string,
    enabled: boolean,
    requireAuthentication?: boolean,
    restrictToUsers?: string[],
    allowExtendedSession?: boolean
) =>
    API.put(`/openaccess/shares/${id}`, {
        properties: {
            enabled,
            restrictToUsers,
            requireAuthentication,
            allowExtendedSession
        }
    })
        .then(handleResponse)
        .catch(handleError);

/**
 * Remove (delete) an individual share target
 */
export const DeleteShare = async (id: string) =>
    API.delete(`/openaccess/shares/${id}`).then(handleResponse).catch(handleError);
/**
 * Share a target (currently always a dashboard) for open access.
 */
export const CreateShare = async (
    workspaceId: string,
    targetId: string,
    properties?: Serialised<OpenAccessShareProperties>
) =>
    API.post('/openaccess/shares', {
        workspaceId,
        targetId,
        properties
    })
        .then(handleResponse)
        .catch(handleError);
