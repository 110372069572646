import Text from '@/components/Text';
import { faChevronDown, faPlus, faQuestionCircle, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import Tooltip from 'components/tooltip/Tooltip';
import { ReactNode, forwardRef, useState } from 'react';

interface CollapsibleFilterPanelProps {
    initiallyCollapsed?: boolean;
    title: string;
    disabled?: boolean;
    tooltip?: string;
    children: ReactNode;
    onCreate?: () => void;
    onClear?: () => void;
    onCreateTestId?: string;
    onCreateTooltip?: string;
}

export const CollapsibleFilterPanel = forwardRef<HTMLDivElement, CollapsibleFilterPanelProps>(
    (
        {
            initiallyCollapsed,
            title,
            disabled,
            tooltip,
            children,
            onCreate,
            onClear,
            onCreateTestId,
            onCreateTooltip = 'Add filter'
        },
        ref
    ) => {
        const [collapsed, setCollapsed] = useState(Boolean(initiallyCollapsed));

        return (
            <div
                ref={ref}
                className={clsx('flex flex-col min-h-0 max-h-[content]', !collapsed && !disabled && 'flex-1')}
            >
                <div
                    className={clsx(
                        'flex items-center space-x-4 cursor-pointer',
                        disabled && 'opacity-50 pointer-events-none'
                    )}
                    onClick={() => setCollapsed((prev) => !prev)}
                >
                    <h4 className='flex items-center flex-1 space-x-2 select-none'>
                        <Text.H5>{title}</Text.H5>
                        {tooltip && (
                            <Tooltip title={tooltip}>
                                <FontAwesomeIcon icon={faQuestionCircle} className='text-textSecondary' />
                            </Tooltip>
                        )}
                    </h4>

                    <div className='flex items-center flex-shrink-0 space-x-2'>
                        {onCreate && (
                            <Tooltip title={onCreateTooltip}>
                                <button
                                    aria-label={onCreateTooltip}
                                    className='px-1 hover:text-textPrimary'
                                    data-testid={`filterPanel-onCreate-${onCreateTestId}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onCreate();
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} className='align-middle' />
                                </button>
                            </Tooltip>
                        )}

                        {onClear && (
                            <Tooltip title='Clear filter'>
                                <button
                                    className='px-1 hover:text-textPrimary'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onClear();
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTimes} className='align-middle' />
                                </button>
                            </Tooltip>
                        )}

                        <FontAwesomeIcon
                            icon={faChevronDown}
                            className={clsx(
                                'transition-transform duration-300 !ml-3 hover:text-textPrimary',
                                (collapsed || disabled) && 'rotate-180'
                            )}
                        />
                    </div>
                </div>
                <div
                    className={clsx(
                        'flex flex-col flex-1 min-h-0 pl-2 transition-all duration-500 ease-in-out',
                        collapsed || disabled ? 'h-0 opacity-0 invisible' : 'opacity-100 visible'
                    )}
                >
                    {!collapsed && !disabled && <div className='py-4 space-y-2'>{children}</div>}
                </div>
            </div>
        );
    }
);
