import { Button, buttonVariants } from '@/components/Button';
import { Switch } from '@/components/Switch';
import Template from '@/components/Template';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faCalendar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeatureLimit, getFeatureLimit, getFeatures, listTierTemplates, validateTier } from '@squaredup/tenants';
import { DataStreamBarChart } from 'dashboard-engine/visualisations/DataStreamBarChart/DataStreamBarChart';
import { subMonths } from 'date-fns';
import type { GroupCountResult, MetricsResults } from 'dynamo-wrapper';
import { sum } from 'lodash';
import TruncateWithTooltip from 'pages/settings/TruncateWithTooltip';
import { useDatasourceConfigsCount } from 'queries/hooks/useDatasourceConfigsCount';
import { useTenant } from 'queries/hooks/useTenant';
import { UsageQueryKeys } from 'queries/queryKeys/usageQueryKeys';
import { ComponentPropsWithoutRef, FC, useState } from 'react';
import { useQuery } from 'react-query';
import {
    DashboardsCount,
    DetailedRequestsForBillingPeriod,
    GetCountOfMonitors,
    NodeCount,
    UserCount,
    WorkspacesCount
} from 'services/UsageService';
import { useContactSalesModal } from './ContactSalesModal';
import { MetricPanel, UsageMetric } from './MetricPanel';
import { UpgradeButton } from './UpgradeButton';
import { requestMetricsToBarChartData } from './requestMetricsToBarChartData';

const featureKeys = ['users', 'indexedObjects', 'dataSources', 'monitors', 'dataQueries'] as const;

const lastMonthDisplayOptions = [
    {
        value: 'true',
        label: 'Previous month'
    },
    {
        value: 'false',
        label: 'Current month'
    }
];

const getCount = (response: GroupCountResult[] | number | undefined = []) => {
    if (typeof response === 'number') {
        return response;
    }

    if (!response[0]) {
        return 0;
    }
    return response[0].count;
};

const getQueryCount = (queryGroups: MetricsResults[] = []) => {
    return queryGroups.reduce((acc, cur) => {
        acc += sum(cur.MetricDataResults[0].Values);
        return acc;
    }, 0);
};

const TemplateWrapper: FC = ({ children }) => {
    return (
        <Template title='Plan & Usage' flex equalPadding truncateDescription>
            {children}
        </Template>
    );
};

const GridLayout = (props: ComponentPropsWithoutRef<'main'>) => {
    return <main {...props} className='h-[90vh] grid grid-cols-2 gap-2.5 grid-rows-1 overflow-hidden' />;
};

export const Panel = ({ className, ...props }: ComponentPropsWithoutRef<'section'>) => {
    return (
        <section
            {...props}
            className={cn(
                'bg-tileBackground h-full rounded-lg flex overflow-x-hidden overflow-y-auto flex-col border-[16px] border-tileBackground scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary',
                className
            )}
        />
    );
};

const DATE_FORMATTER = new Intl.DateTimeFormat('en-us', { dateStyle: 'long' });

const PlanDetails = ({ name, date }: { name?: string; date?: Date }) => {
    const dateDisplay = date ? 'Until ' + DATE_FORMATTER.format(date) : 'Renews indefinitely';
    return (
        <div>
            <div className='flex flex-row gap-2'>
                <TruncateWithTooltip title={name ?? ''}>
                    <Text.H2 className='truncate'>{name}</Text.H2>
                </TruncateWithTooltip>
                <Text.Body className='content-end pb-1 font-normal'>Plan</Text.Body>
            </div>
            <Text.Body className='flex'>
                <FontAwesomeIcon fixedWidth icon={faCalendar} className='mt-1 mr-2' />
                <span className='content-end mt-[1px]'>{dateDisplay}</span>
            </Text.Body>
        </div>
    );
};

const currentDate = new Date();
const lastMonthDate = subMonths(currentDate, 1);
const toSimpleDatestring = (date: Date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

const tiertemplates = listTierTemplates();

export const UsagePage = () => {
    const [showLastMonthData, setShowLastMonthData] = useState<boolean>(false);
    const barChartDate = showLastMonthData ? lastMonthDate : currentDate;
    const { data: userCount, isLoading: isUsersLoading } = useQuery(UsageQueryKeys.Users, UserCount);
    const { data: nodeCount, isLoading: isNodesLoading } = useQuery(UsageQueryKeys.Nodes, NodeCount);
    const { data: monitorCount, isLoading: isMonitorsLoading } = useQuery(UsageQueryKeys.Monitors, GetCountOfMonitors);
    const { data: workspacesCount, isLoading: isWorkspacesLoading } = useQuery(
        UsageQueryKeys.Workspaces,
        WorkspacesCount
    );
    const { data: dashboardsCount, isLoading: isDashboardsLoading } = useQuery(
        UsageQueryKeys.Dashboards,
        DashboardsCount
    );
    const { data: requestData } = useQuery([...UsageQueryKeys.Requests, toSimpleDatestring(barChartDate)], () =>
        DetailedRequestsForBillingPeriod(barChartDate)
    );

    const { data: requestDataForCount, isLoading: isRequestCountLoading } = useQuery(
        [...UsageQueryKeys.Requests, toSimpleDatestring(currentDate)],
        () => DetailedRequestsForBillingPeriod(currentDate)
    );
    const { data: configCount, isLoading: isDataSourcesLoading } = useDatasourceConfigsCount();
    const { data: tenant, isLoading: isTenantLoading } = useTenant({
        select: (result) => {
            const validatedTier = validateTier(result.tier!);
            if (!validatedTier.success) {
                return { features: [], licence: result.licenceData, tenant: result };
            }
            const limits: Record<string, FeatureLimit> = {};
            featureKeys.forEach((k) => {
                limits[k] = getFeatureLimit(validatedTier.data, k);
            });
            return {
                tier: validatedTier.data,
                features: getFeatures(validatedTier.data),
                licence: result.licenceData,
                limits: limits as Record<Partial<(typeof featureKeys)[number]>, FeatureLimit>,
                tenant: result
            };
        }
    });

    const { contactSalesModal, openContactSalesModal } = useContactSalesModal(
        'Plan and Usage',
        tenant?.tenant.id,
        tenant?.tenant.displayName ?? tenant?.tenant.name ?? ''
    );

    const rows = requestMetricsToBarChartData(requestData ?? [], barChartDate);

    const usageMetrics: UsageMetric[] = [
        {
            name: 'users',
            count: getCount(userCount),
            limit: tenant?.limits?.users,
            loading: isUsersLoading || isTenantLoading
        },
        {
            name: 'indexedObjects',
            count: getCount(nodeCount),
            limit: tenant?.limits?.indexedObjects,
            loading: isNodesLoading || isTenantLoading
        },
        {
            name: 'dataSources',
            count: configCount ?? 0,
            limit: tenant?.limits?.dataSources,
            loading: isDataSourcesLoading || isTenantLoading
        },
        {
            name: 'monitors',
            count: getCount(monitorCount),
            limit: tenant?.limits?.monitors,
            loading: isMonitorsLoading || isTenantLoading
        },
        {
            name: 'dataQueries',
            count: getQueryCount(requestDataForCount),
            limit: tenant?.limits?.dataQueries,
            loading: isRequestCountLoading || isTenantLoading
        },
        {
            name: 'dashboards',
            count: getCount(dashboardsCount),
            loading: isDashboardsLoading
        },
        {
            name: 'workspaces',
            count: getCount(workspacesCount),
            loading: isWorkspacesLoading
        }
    ];

    const tierCanBeUpgraded =
        (tiertemplates.find((t) => t.id === tenant?.tier?.tierTemplate)?.sortRank ?? 0) < tiertemplates.length - 1;

    return (
        <TemplateWrapper>
            <GridLayout>
                <div>
                    <Panel>
                        <div className='grid grid-cols-2'>
                            <PlanDetails name={tenant?.tier?.displayName} date={tenant?.tier?.expiry} />
                            <div className='flex flex-col content-end ml-auto'>
                                <UpgradeButton tenant={tenant?.tenant} tierCanBeUpgraded={tierCanBeUpgraded} />
                            </div>
                        </div>
                        <hr className='mt-4 mb-5 border-dividerPrimary' />
                        <MetricPanel metrics={usageMetrics} />
                    </Panel>
                </div>
                <Panel>
                    <Text.H2>Usage Analysis</Text.H2>
                    <Text.Body>Data queries</Text.Body>
                    <Switch
                        className='my-8'
                        value={showLastMonthData.toString()}
                        options={lastMonthDisplayOptions}
                        onValueChange={() => {
                            setShowLastMonthData((toggle) => !toggle);
                        }}
                    />
                    <div className='relative w-full h-1/2'>
                        <DataStreamBarChart
                            data={rows}
                            config={{
                                horizontalLayout: 'vertical',
                                showLegend: true,
                                legendPosition: 'bottom',
                                palette: ['#612ce8', '#3eb5ff', '#c95dd2']
                            }}
                        />
                    </div>
                    <Text.Body>
                        <a
                            className={buttonVariants({ variant: 'link' })}
                            href='https://docs.squaredup.com/billing-subscriptions/plan-and-usage'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Learn more
                        </a>{' '}
                        about how data queries are calculated.
                    </Text.Body>
                </Panel>
            </GridLayout>
            <Text.Body className='mt-4 mb-4'>
                Contact our friendly team at{' '}
                <Button variant='link' onClick={openContactSalesModal}>
                    sales@squaredup.com
                </Button>{' '}
                for any questions regarding your usage, upgrade or renewal options.
            </Text.Body>
            {contactSalesModal}
        </TemplateWrapper>
    );
};
