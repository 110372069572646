import { ScrollArea } from 'components/scrollArea';
import { useAppContext } from 'contexts/AppContext';
import { useDashboardTreeAutoScroll } from '../dashboards/useDashboardTreeAutoScroll';
import WorkspaceDetail from './WorkspaceDetail';
import { WorkspaceDropdown } from './dropdown/WorkspaceDropdown';

export const WorkspaceNavigation: React.FC = () => {
    const { currentWorkspaceID } = useAppContext();

    const scrollRef = useDashboardTreeAutoScroll();

    return (
        <>
            <WorkspaceDropdown />

            <ScrollArea ref={scrollRef} className='w-full pb-4 min-h-0 grow'>
                <div className='flex-grow h-full mt-5 text-textSecondary w-[249px] min-h-full'>
                    {currentWorkspaceID && <WorkspaceDetail />}
                </div>
            </ScrollArea>
        </>
    );
};
