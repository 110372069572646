import type { PropsWithChildren } from 'react';

type Props = { isPresent: boolean };

export const Presence = (props: PropsWithChildren<Props>) => {
    if (!props.isPresent) {
        return null;
    }

    return <>{props.children}</>;
};
