import type { UIConfigFieldType } from '@squaredup/utilities';
import Field from './Field';

/**
 * Field types that cannot be displayed from JSON config
 */
export type NonUIConfigFieldTypes =
    | Exclude<
          // Types accepted by standard HTML input,
          // there is a React type for this but it includes (string & {})
          | 'button'
          | 'checkbox'
          | 'color'
          | 'date'
          | 'datetime-local'
          | 'email'
          | 'file'
          | 'hidden'
          | 'image'
          | 'month'
          | 'password'
          | 'radio'
          | 'range'
          | 'reset'
          | 'search'
          | 'submit'
          | 'tel'
          | 'text'
          | 'time'
          | 'url'
          | 'week',
          UIConfigFieldType
      >
    | 'apiKey'
    | 'key-value'
    | 'choiceChips'
    | 'toggle'
    | 'switch'
    | 'custom'
    | 'slider';

export type FieldType = UIConfigFieldType | NonUIConfigFieldTypes;

export const getFormFieldComponent = (fieldType: FieldType | 'checkboxes') => {
    switch (fieldType) {
        case 'markdown':
            return Field.Markdown;
        case 'payloadViewer':
            return Field.TestOutput;
        case 'radio':
            return Field.Radio;
        case 'hidden':
            return Field.Hidden;
        case 'checkboxes':
            return Field.Checkboxes;
        case 'checkbox':
            return Field.Checkbox;
        case 'choiceChips':
            return Field.ChoiceChips;
        case 'toggle':
            return Field.Toggle;
        case 'switch':
            return Field.Switch;
        case 'custom':
            return Field;
        default:
            return Field.Input;
    }
};
