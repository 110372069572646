import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Pill, PillsWrapper } from 'components/pill/Pill';
import { upperFirst } from 'lodash';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import TruncateWithTooltip from '../TruncateWithTooltip';
import { Workspace } from 'services/WorkspaceService';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Action } from 'pages/components/ApplicationTable/types';

interface WorkspacesTableProps {
    workspaces: Workspace[];
    onEdit: (workspace: Workspace) => void;
    onDelete: (workspace: Workspace) => void;
    canEdit: (workspace: Workspace) => boolean | undefined;
    canDelete: (workspace: Workspace) => boolean | undefined;
}

const WorkspacesApplicationTable = ApplicationTable<Workspace, string>();

const WorkspacesTable: FC<WorkspacesTableProps> = ({ workspaces, onEdit, onDelete, canEdit, canDelete }) => { 
    const columnHelper = createColumnHelper<Workspace>();
    const columns: ColumnDef<Workspace, string>[] = [
        columnHelper.accessor(row => row.displayName, {
            id: 'name',
            header: 'Name',
            cell: ({ row, getValue }) => {
                return (
                    <TruncateWithTooltip title={getValue()}>
                        <Link
                            to={`/workspace/${row.original.id}`}
                            className='truncate cursor-pointer text-textLink hover:underline'
                        >
                            {getValue()}
                        </Link>
                    </TruncateWithTooltip>
                );
            },
            size: 300
        }),
        columnHelper.accessor(row => row.data.properties?.description, {
            id: 'description',
            header: 'Description',
            cell: ({ getValue }) => {
                return (
                    <TruncateWithTooltip title={getValue() || ''}>
                        <div className='truncate'>{getValue()}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 300
        }),
        columnHelper.accessor(row => row.data.properties?.type, {
            id: 'type',
            header: 'Type',
            cell: ({ getValue }) => {
                if (!getValue()) {
                    return null;
                }

                return <Pill>{upperFirst(getValue())}</Pill>;
            },            
            size: 150
        }),
        columnHelper.accessor(row => row.data.properties?.tagString, {
            id: 'tags',
            header: 'Tags',
            cell: ({ getValue }) => {
                const tags = getValue()
                    ?.split(',')
                    .filter(Boolean)
                    .map((a) => a.trim())
                    .sort((a, b) => a.localeCompare(b));

                if (!tags || tags.length < 1) {
                    return null;
                }

                return (
                    <PillsWrapper.Truncate
                        items={[...new Set(tags)].map((tag: any) => (
                            <Pill variant='outline' key={tag}>
                                {tag}
                            </Pill>
                        ))}
                    />
                );
            },                             
            size: 250
        })
    ];

    const actions: Action[] = [
        {
            visible: canEdit,
            action: onEdit,
            icon: faPencil,
            dataTestId: 'editWorkspaceButton',
            tooltip: 'Edit workspace'
        },
        {
            visible: canDelete,
            action: onDelete,
            icon: faTrash,
            dataTestId: 'deleteWorkspaceButton',
            tooltip: 'Delete workspace'
        }
    ];

    return (
        <div className='flex flex-col flex-1 w-full h-full min-h-0'>
            <WorkspacesApplicationTable
                config={{
                    actions,
                    noDataMessage: 'There are no workspaces configured.'
                }}
                columns={columns}
                data={workspaces}
            />
        </div>
    );
};

export default WorkspacesTable;
