import { createContext, useContext } from 'react';
import { NO_ACTIVE_WORKSPACE } from 'services/WorkspaceUtil';
import { useAppContext } from './AppContext';

interface DataStreamWorkspaceContextValue {
    workspace?: string;
}

const DataStreamWorkspaceContext = createContext<DataStreamWorkspaceContextValue>({});

DataStreamWorkspaceContext.displayName = 'DataStreamWorkspaceContext';

/**
 * Returns DataStreamWorkspaceContext. If there is no workspace in context, we assume the data stream
 * should be invoked in the context of the current workspace (set in AppContext)
 */
export const useDataStreamWorkspaceContext = () => {
    const { currentWorkspaceID } = useAppContext();
    const { workspace = currentWorkspaceID } = useContext(DataStreamWorkspaceContext);

    return {
        workspace: workspace ?? currentWorkspaceID ?? NO_ACTIVE_WORKSPACE,
        isGlobal: workspace === NO_ACTIVE_WORKSPACE
    };
};

export default DataStreamWorkspaceContext;
