import Button from 'components/button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import { Delete, PROPS } from 'services/PropsService';
import { ProjectedTagProperty } from './TagProperties';
import { useMutation, useQueryClient } from 'react-query';

type PropsDeleteModalProps = {
    tagProperty: ProjectedTagProperty;
    onClose: () => void;
};

/**
 * @param {object} prop Prop to be deleted
 * @param {Function} onClose Handler invoked when cancelling/closing
 */
function TagPropertiesDeleteModal({ tagProperty, onClose }: PropsDeleteModalProps) {
    const queryClient = useQueryClient();

    const { mutate: handleDelete, isLoading, isError } = useMutation(
        async () => {
            return Delete(tagProperty.id);
        },
        {
            onSettled: async () => {
                await queryClient.invalidateQueries([PROPS]);
            },
            onSuccess: async () => {
                onClose();
            }                 
        }
    );    

    const description =
        'Are you sure you want to permanently delete this tag property? Associated tag objects will be permanently deleted.';

    return (
        <Modal
            title={`Delete Tag Property: ${tagProperty.displayName}`}
            description={description}
            close={onClose}
            fullWidth
            maxWidth='max-w-3xl'
        >
            {isError && <p className='px-8 text-statusErrorPrimary'>An error occurred, please try again.</p>}

            <ModalButtons>
                <Button variant='tertiary' onClick={onClose} data-testid='cancelDeleteButton'>
                    Cancel
                </Button>
                <Button
                    variant='destructive'
                    type='submit'
                    onClick={handleDelete}
                    disabled={isLoading}
                    data-testid='confirmDeleteButton'
                >
                    {isLoading ? <LoadingSpinner size={18} /> : 'Delete'}
                </Button>
            </ModalButtons>
        </Modal>
    );
}

export default TagPropertiesDeleteModal;
