import LoadingSpinner from 'components/LoadingSpinner';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import RequireTenantAdmin from '../../routes/RequireTenantAdmin';
import Agents from './agents/Agents';
import ApiKeys from './api-keys/ApiKeys';
import CustomCorrelations from './custom-correlations/CustomCorrelations';
import CustomTypes from './custom-types/CustomTypes';
import DataStreamDefinitions from './data-stream-definitions/DataStreamDefinitions';
import KPITypes from './kpi-types/KPITypes';
import Notifications from './notifications/Notifications';
import Plugins from './plugins/Plugins';
import PluginsOAuth2 from './plugins/PluginsOAuth2';
import TagProperties from './props/TagProperties';
import Scripts from './scripts/Scripts';
import Users from './users/Users';
import Workspaces from './workspaces/Workspaces';
import OpenAccess from './open-access/OpenAccess';
import OrganisationPage from './organisation/OrganisationPage';

const Tenants = lazy(() => import('./tenants/Tenants'));

function Settings() {
    return (
        <Suspense fallback={<div className='pt-md'><LoadingSpinner /></div>}>
            <Routes>
                <Route path='/plugins' element={<Plugins />} />
                <Route path='/pluginsoauth2' element={<PluginsOAuth2 />} />
                <Route path='/notifications' element={<Notifications />} />
                <Route path='/types' element={<CustomTypes />} />
                <Route path='/workspaces' element={<Workspaces />} />
                <Route path='/scripts' element={<Scripts />} />
                <Route path='/datastreams' element={<DataStreamDefinitions />} />
                <Route path='/kpis' element={<KPITypes />} />
                <Route path='/openaccess' element={<OpenAccess />} />
                <Route path='/organization' element={<OrganisationPage />} />

                {/* Routes behind a feature flag: */}

                {/* ADMIN ONLY ROUTES BELOW */}
                <Route
                    path='/users'
                    element={
                        <RequireTenantAdmin redirectTo='/settings'>
                            <Users />
                        </RequireTenantAdmin>
                    }
                />
                <Route
                    path='/apiKeys'
                    element={
                        <RequireTenantAdmin redirectTo='/settings'>
                            <ApiKeys />
                        </RequireTenantAdmin>
                    }
                />
                <Route
                    path='/relay'
                    element={
                        <RequireTenantAdmin redirectTo='/settings'>
                            <Agents />
                        </RequireTenantAdmin>
                    }
                />
                <Route
                    path='/props'
                    element={
                        <RequireTenantAdmin redirectTo='/settings'>
                            <TagProperties />
                        </RequireTenantAdmin>
                    }
                />
                <Route
                    path='/correlations'
                    element={
                        <RequireTenantAdmin redirectTo='/settings'>
                            <CustomCorrelations />
                        </RequireTenantAdmin>
                    }
                />
                <Route
                    path='/tenants/*'
                    element={
                        <RequireTenantAdmin redirectTo='/settings'>
                            <Tenants />
                        </RequireTenantAdmin>
                    }
                />
            </Routes>
        </Suspense>
    );
}

export { Settings as default };
