import { AllowIdStrings, Serialised } from '@squaredup/ids';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import type {
    DataStreamDefinitionEntity,
    ProjectedDataStreamDefinitionEntity,
    SettableDataStreamProperties
} from 'dynamo-wrapper';
import { fiveMinutes } from 'queries/constants';
import { UseQueryOptions, useQuery } from 'react-query';
import API from './API';
import { NO_ACTIVE_WORKSPACE } from './WorkspaceUtil';
import { handleError, handleResponse } from './util';

/**
 * A data stream entity as received by the client from the API.
 */
export type DataStream = Serialised<ProjectedDataStreamDefinitionEntity>;

/*
    react-query key for Data Stream definitions
 */
export const DATA_STREAM_CONFIG = 'dataStreamConfig';
export const DATA_STREAM_DEFINITIONS = 'dataStreamDefinitions';
export const DATA_STREAM_DEFINITIONS_FOR_WORKSPACE = 'dataStreamDefinitionsForWorkspace';
export const DATA_STREAM_DEFINITIONS_FOR_ALL_WORKSPACES = 'dataStreamDefinitionsForAllWorkspaces';
export const DATA_STREAM_DEFINITION = 'dataStreamDefinition';
export const INCLUDE_TEMPLATES = 'includeTemplates';

/*
    react-query key for custom Data Stream definitions
 */
export const CUSTOM_DATA_STREAM_DEFINITIONS = 'customDataStreamDefinitions';

/**
 * Check if the stream can match objects
 * @param def Data stream definition
 */
export const isMatchable = (stream: DataStream): boolean => !stream.definition.options?.noMatch;

export const Get = async (id: string) => {
    return API.get<Serialised<DataStreamDefinitionEntity>>(`/datastreams/${id}`)
        .then(handleResponse)
        .catch(handleError);
};

/**
 * Get all data streams defined in this tenant
 * @returns
 */
export const ListCustomDataStreams = async (): Promise<DataStream[]> => {
    return API.get<DataStream[]>('/datastreams/custom').then(handleResponse).catch(handleError);
};

/**
 * Get all data streams visible to this tenant (defined in the tenant and defined in
 * plugins which have been added to this tenant)
 * @returns
 */
export const List = async (): Promise<DataStream[]> => {
    return API.get<DataStream[]>('/datastreams').then(handleResponse).catch(handleError);
};

/**
 * Get all the data streams that are relevant to a workspace (e.g. those for plugins that are
 * linked to the workspace)
 */
export const ListForWorkspace = async (
    workspaceId: string | undefined | null,
    includeTemplates?: boolean | undefined | null
): Promise<DataStream[]> =>
    API.get<DataStream[]>('/datastreams', {
        params: {
            workspaceId,
            includeTemplates
        }
    })
        .then(handleResponse)
        .catch(handleError);

/**
 * Get all the data streams that apply to any workspace we have access to.
 * Useful for global scenarios like search -> drilldown.
 */
export const ListForAllWorkspaces = async (includeTemplates?: boolean | undefined | null): Promise<DataStream[]> =>
    API.get<DataStream[]>('/datastreams', {
        params: {
            accessControlType: 'directOrAnyWorkspaceLinks',
            includeTemplates
        }
    })
        .then(handleResponse)
        .catch(handleError);

export const Create = async (props: AllowIdStrings<SettableDataStreamProperties>) => {
    const created = await API.post<Serialised<DataStreamDefinitionEntity>>('/datastreams', props)
        .then(handleResponse)
        .catch(handleError);

    return created.id;
};

export const Update = async (id: string, props: Partial<AllowIdStrings<SettableDataStreamProperties>>) => {
    await API.put<Serialised<DataStreamDefinitionEntity>>(`/datastreams/${id}`, props)
        .then(handleResponse)
        .catch(handleError);
    return id;
};

export const Delete = async (id: string) => {
    const success = await API.delete(`/datastreams/${id}`)
        .then(handleResponse)
        .then(() => true)
        .catch(handleError)
        .catch(() => false);
    return success;
};

export const useDataStreamDefinitions = <T = Awaited<ReturnType<typeof List>>>(
    options?: Omit<
        UseQueryOptions<Awaited<ReturnType<typeof List>>, unknown, T, string[]>,
        'enabled' | 'cacheTime' | 'staleTime'
    >
) => {
    return useQuery([DATA_STREAM_DEFINITIONS], List, {
        ...options,
        // Full list of data stream definitions is very large and expensive to load.
        // It also changes quite rarely. So we should cache indefinitely unless/until
        // we know if has changed and needs to be invalidated.
        cacheTime: Number.POSITIVE_INFINITY,
        staleTime: Number.POSITIVE_INFINITY
    });
};

/**
 * Only the data stream definitions for data streams that can be used in the specified workspace,
 * i.e. are relevant for plugins that are linked to the workspace.
 *
 * If the workspace is not supplied, the data stream workspace context is used (this defaults to the current
 * workspace if there is no declared override).
 */
export const useDataStreamDefinitionsForWorkspace = <T = DataStream[]>({
    workspace,
    queryOptions
}: {
    workspace?: string;
    queryOptions?: UseQueryOptions<DataStream[], unknown, T>;
} = {}) => {
    const { workspace: dataStreamWorkspace } = useDataStreamWorkspaceContext();
    const targetWorkspace = workspace ?? dataStreamWorkspace;

    return useQuery<DataStream[], unknown, T>(
        [DATA_STREAM_DEFINITIONS_FOR_WORKSPACE, targetWorkspace],
        async () => {
            const allDataStreams =
                targetWorkspace !== NO_ACTIVE_WORKSPACE
                    ? await ListForWorkspace(targetWorkspace, true)
                    : await ListForAllWorkspaces(true);
            return Object.values(allDataStreams).flat();
        },
        {
            cacheTime: fiveMinutes * 2,
            staleTime: fiveMinutes,
            ...queryOptions
        }
    );
};
