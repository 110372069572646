import { Route, Routes } from 'react-router-dom';

import Auth from 'services/Auth';
import Button from 'components/button/Button';
import LoginWrapper from 'pages/login/LoginWrapper';

/**
 * Password reset
 * Shows a success message for resetting password, as well as a sign-in button.
 *
 * @example
 * `<PasswordReset />`
 */
function PasswordReset() {
    // Note: We use Auth.logout here to get rid of the 'password reset' token before trying to sign in again.
    // This avoids us getting into a loop where we show the 'Password reset successful' message multiple times.
    const login = (
        <div>            
            <Button onClick={Auth.logout}>
                <span className='block text-center' data-testid='loginButton'>
                    Sign in
                </span>
            </Button>
        </div>
    );

    return (
        <LoginWrapper>
            <div data-testid='loginPage'>
                <h2 className='my-10 text-3xl font-bold'>Password reset successfully.</h2>
            </div>
            {
                <Routes>
                    <Route path='/*' element={login} />
                </Routes>
            }
        </LoginWrapper>
    );
}

PasswordReset.propTypes = {};

export default PasswordReset;
