import { handleError, handleResponse } from './util';

import API from './API';

/*
    react-query key for props data
 */
export const PROPS = 'props';

export const List = async () => {
    return API.get('/propmotedprops').then(handleResponse).catch(handleError);
};

export const Create = async (displayName: string, propType: string) => {
    const created = await API.post('/propmotedprops', { displayName, propType })
        .then(handleResponse)
        .catch(handleError);
    return created.id;
};

export const Delete = async (id: string) => {
    await API.delete(`/propmotedprops/${id}`).then(handleResponse).catch(handleError);
    return true;
};
