import { fiveMinutes } from 'queries/constants';
import { datasourceQueryKeys } from 'queries/queryKeys/datasourceKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { Get } from 'services/PluginService';

type QueryReturnType = Awaited<ReturnType<typeof Get>>;
type QuerySelectReturnType = QueryReturnType;

/**
 * useQuery returning the data sources
 * @param options The react-query options e.g. refreshInterval
 * @returns All data sources within the tenant
 */
export const useDatasource = <T = QuerySelectReturnType>(
    id?: string,
    options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>
) => 
    useQuery(
        datasourceQueryKeys.detail(id), 
        () => Get(id!), 
        {
            staleTime: fiveMinutes,
            cacheTime: fiveMinutes,
            keepPreviousData: true,
            ...options
        }
    );
