import PropTypes from 'prop-types';

import { PropsForInputType } from 'components/forms/input/buildInput';
import Input from '../../input/Input';

type FieldHiddenProps = Omit<PropsForInputType<'hidden'>, 'type'> & {
    name: string;
};

/**
 * Field.Hidden
 * Helper for creating a hidden field
 * Automatically sets required attributes
 *
 * @example
 * <Field.Hidden name='name'
 */
function FieldHidden({ name, ...rest }: FieldHiddenProps) {
    return <Input type={'hidden'} name={name} id={name} {...rest} />;
}

FieldHidden.propTypes = {
    /**
     * Name of the input, used in output data.
     */
    name: PropTypes.string.isRequired
};

export default FieldHidden;
