import useSize from '@react-hook/size';
import Tooltip from 'components/tooltip/Tooltip';
import { useLayoutEffect, useRef, useState } from 'react';

interface BlockSublabelProps {
    sublabel: string;
    sublabelDetail: string;
    fontSize: number;
    wrapLabels: boolean;
}

export const BlockSublabel: React.FC<BlockSublabelProps> = ({ sublabel, sublabelDetail, fontSize, wrapLabels }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const textRef = useRef<HTMLDivElement>(null);
    const [overflowing, setOverflowing] = useState(false);
    const [, labelHeight] = useSize(containerRef);

    useLayoutEffect(() => {
        const scrollHeight = textRef.current?.scrollHeight || 0;

        setOverflowing(Math.round(labelHeight) < scrollHeight);
    }, [textRef, labelHeight]);

    const labelLineHeight = 1.25;
    const labelLines = wrapLabels ? Math.floor(labelHeight / (fontSize * labelLineHeight)) : 1;

    return (
        <div ref={containerRef} className='flex-1 overflow-hidden break-words'>
            <Tooltip
                title={sublabelDetail}
                disabled={!overflowing && (sublabel === sublabelDetail || sublabelDetail == null)}
            >
                <p
                    ref={textRef}
                    className='overflow-hidden font-light text-ellipsis line-clamp-1'
                    aria-label={sublabel}
                    style={{
                        WebkitLineClamp: labelLines,
                        fontSize: fontSize,
                        lineHeight: `${fontSize * labelLineHeight}px`
                    }}
                >
                    {sublabel}
                </p>
            </Tooltip>
        </div>
    );
};
