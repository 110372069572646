import GenericRepo from './GenericRepo';
import { get, groupBy, set, zipWith } from 'lodash';

type transformData = Record<string, any> | number[];

const transformRepo = new GenericRepo<(data: transformData, config?: any) => any>({
  pick: (data, config) => get(data, config!.property),
  sum: (data) => data.reduce((total: number, value: number) => total + value),
  mean: (data) => data.reduce((total: number, value: number) => total + value) / data.length,
  min: (data) => Math.min(...(data as number[])),
  max: (data) => Math.max(...(data as number[])),
  parse: (data) => JSON.parse(data as any),
  parseFloat: (data) => parseFloat(data as any),
  parseInt: (data) => parseInt(data as any),
  parseDate: (data) => new Date(data as any),
  setDecimalPlaces: (data, config) => (data as any).toFixed(config.decimalPlaces),
  custom: (data, config) => config(data),
  wrap: (data) => [data],
  wrapAs: (data, config) => ({[config.property]: data}),
  prefix: (data, config) => `${config.prefix}${data}`,
  suffix: (data, config) => `${data}${config.suffix}`,
  addProperty: (data, config) => ({...data, [config.property]: config.value}),
  filter: (data, config) => data.filter((d: any) => !config.exclude.includes(get(d, config.property || config.prop))),
  map: (data, config) =>
    data?.map((d: any) =>
      Object.entries(config).reduce((acc: any, [key, path]) => {
        acc[key] = get(d, path as string, path);
        return acc;
      }, {})
    ),
  mapValues: (data, config) => 
    data?.map((item: any) => {
      const value: string = get(item, config.property);
      return value;
    }),
  mapValueToState: (data, config) => 
    data?.map((item: any) => {
        const value: number = Number(get(item, config.property));

        let state = 'unknown';
        if(value > 0) {
            state = 'down';
        }else if(value === 0) {
            state = 'up';
        }

        set(item, config.property, state);
        return item;
    }),
  groupBy: (data, config) => {
    return groupBy(data, config.property);
  },
  joinArrays: (data, config: { accessor: string, name: string}[] ) => {
    // get the arrays of data
    const arrays = config.map(c => get(data, c.accessor)); 
    // zip the arrays, reducing the values into an object with the names specified
    return zipWith(...arrays, (...values) => {
      // Create object with properties from each array, using the name given as the key
      return config.reduce((acc: { [prop: string]: unknown; }, c: any, i: number) => {
        acc[c.name] = values[i];
        return acc;
      }, {});
    });
  }
});

export default transformRepo;