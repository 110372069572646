import RenderDynamic from 'dashboard-engine/render/RenderDynamic';

function List({ config }) {
    return config.contents.map((content, index) =>
        <div className='w-full' key={`list-${index}`}>
            <RenderDynamic config={content} />
        </div>
    );
}

export default List;
