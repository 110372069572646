import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { GetAvatar, Workspace } from 'services/WorkspaceService';
import { useWorkspace } from './useWorkspace';

type Options = UseQueryOptions<string | undefined, unknown, string | undefined, Array<string>>;

export const useWorkspaceAvatar = ({ id, enabled = true, ...options }: { id: string } & Options) => {
    const workspace = useWorkspace(id);
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: workspaceQueryKeys.avatar(id),
        queryFn: async () => {
            //NOTE: getting the workspace from the cache rather than with the useWorkspace hook
            //      as useWorkspace will return a subscription to the workspaces query.
            //      After the user updates his workspace icon, the property avatarUploadedAt will not be up to date
            //      even after an optimistic update, reading straight from the cache solves that issue,
            //      and the avatarUploadedAt key is up to date.

            const workspaces = queryClient.getQueryData<Array<Workspace>>(workspaceQueryKeys.list);
            const workspaceFromCache = workspaces?.find((w) => w.id === id);

            return GetAvatar(id, workspaceFromCache?.data.properties?.avatarUploadedAt);
        },
        enabled: Boolean(workspace?.data?.data?.properties?.avatarUploadedAt) && enabled,
        staleTime: Infinity,
        cacheTime: Infinity,
        ...options
    });
};
