import { Skeleton } from '@/components/Skeleton';
import { cn } from '@/lib/cn';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { healthStatesEnum } from 'constants/state';
import { workspacePredefinedIconKey } from 'lib/fontawesome/fontawesome';
import { useWorkspaceId } from 'pages/workspaceHome/WorkspaceHome';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { useWorkspaceAvatar } from 'queries/hooks/useWorkspaceAvatar';
import { useWorkspaceHealthStates } from 'queries/hooks/useWorkspaceHealthStates';
import { ComponentPropsWithoutRef, Suspense } from 'react';
import { z } from 'zod';

export const WorkspaceHomeStateIndicator = () => {
    return (
        <Suspense fallback={<WorkspaceHomedStateIndicatorSkeleton />}>
            <StateIndicator>
                <WorkspaceAvatar />
            </StateIndicator>
        </Suspense>
    );
};

const WorkspaceAvatar = () => {
    const workspaceId = useWorkspaceId();
    const workspace = useWorkspace(workspaceId);
    const workspaceAvatar = useWorkspaceAvatar({
        id: workspaceId,
        suspense: true,
        enabled: !workspace.data?.data.properties?.avatarIconName
    });

    const workspaceHealthStates = useWorkspaceHealthStates({ suspense: true });
    const workspaceState = workspaceHealthStates.data?.find((w) => w.id === workspaceId)?.state;
    const state = healthStatesEnum.parse(workspaceState);

    if (!workspaceAvatar.data && !workspace.data?.data.properties?.avatarIconName) {
        return (
            <svg
                width={32}
                height={32}
                viewBox='0 1.5 27 30'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                aria-label={`Current status is ${state}`}
                className='text-current'
            >
                <path
                    d='M13.6365 15.796L2.37161 9.8197V22.8632L13.6365 29.385V15.796Z'
                    fill='currentColor'
                    fillOpacity='0.5'
                />
                <path
                    d='M13.7212 15.8333L24.986 9.86038L13.4826 3.36561L2.2869 9.73498L13.7212 15.8333Z'
                    fill='currentColor'
                />
                <path
                    d='M2.37239 9.8603L13.6364 3.35701L24.9004 9.8603V22.8669L13.6364 29.3702L2.37239 22.8669V9.8603Z'
                    stroke='currentColor'
                    strokeWidth='1.09091'
                />
            </svg>
        );
    }

    if (workspace.data?.data.properties?.avatarIconName) {
        return (
            <FontAwesomeIcon
                icon={workspace.data.data.properties.avatarIconName.replace(workspacePredefinedIconKey, '') as IconProp}
                height={32}
                width={32}
                className='h-[32px] w-[32px]'
            />
        );
    }

    if (workspaceAvatar.data?.startsWith(workspacePredefinedIconKey)) {
        return (
            <FontAwesomeIcon
                icon={workspaceAvatar.data.replace(workspacePredefinedIconKey, '') as IconProp}
                height={32}
                width={32}
                className='h-[32px] w-[32px]'
            />
        );
    }

    return (
        <img
            src={workspaceAvatar.data}
            alt='workspace'
            height={32}
            width={32}
            className='w-[32px] h-[32px] rounded-sm bg-white object-contain'
        />
    );
};

const StateIndicator = (props: ComponentPropsWithoutRef<'div'>) => {
    const workspaceId = useWorkspaceId();
    const workspaceHealthStates = useWorkspaceHealthStates({ suspense: true });
    const workspaceState = workspaceHealthStates.data?.find((w) => w.id === workspaceId)?.state;
    const state = healthStatesEnum.parse(workspaceState);

    return (
        <div
            role='status'
            className={cn(
                'p-[4px] mb-auto rounded-md bg-backgroundSecondary text-workspaceIcon flex border-2',
                workspaceIndicatorStyle[state]
            )}
            {...props}
        />
    );
};

const WorkspaceHomedStateIndicatorSkeleton = () => {
    return <Skeleton className='w-[44px] h-[44px] mb-auto' />;
};

const workspaceIndicatorStyle: Record<z.infer<typeof healthStatesEnum>, string> = {
    error: 'border-statusErrorPrimary',
    success: 'border-statusHealthyPrimary',
    unknown: 'border-statusUnknownSecondary',
    warning: 'border-statusWarningPrimary',
    unmonitored: 'border-statusUnmonitoredPrimary'
};
