import { cn } from '@/lib/cn';
import { useTileContext } from 'contexts/TileContext';
import { TextConfig } from 'dashboard-engine/visualisations/Text/Config';
import TextViz from 'dashboard-engine/visualisations/Text/Text';

interface TextPreviewProps {
    textConfig: TextConfig;
}

const previewSize = 600;

export const TextPreview: React.FC<TextPreviewProps> = ({ textConfig }) => {
    const { tileReference } = useTileContext();

    const tileDimensions = tileReference?.current?.getBoundingClientRect();

    const scaleFactor = previewSize / (tileDimensions?.width || previewSize);

    return (
        <div
            className={cn(
                'max-w-full max-h-full p-4 bg-componentBackgroundSecondary overflow-y-scroll scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary',
                {
                    'w-full h-full': tileDimensions && tileDimensions.width === tileDimensions.height
                }
            )}
            style={
                tileDimensions && {
                    ...(tileDimensions.width > tileDimensions.height && {
                        width: previewSize,
                        height: previewSize * (tileDimensions.height / tileDimensions.width)
                    }),
                    ...(tileDimensions.height > tileDimensions.width && {
                        height: previewSize,
                        width: previewSize * (tileDimensions.width / tileDimensions.height)
                    })
                }
            }
            data-testid='textTileEditorPreview'
        >
            <TextViz
                config={{
                    ...textConfig,
                    fontSize: textConfig.fontSize && textConfig.fontSize * scaleFactor
                }}
                data={''}
            />
        </div>
    );
};
