import { Skeleton } from '@/components/Skeleton';
import Template from '@/components/Template';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModal } from 'components/Modal';
import { Presence } from 'components/Presence';
import { useTenantSessionStorage } from 'components/hooks/useTenantSessionStorage';
import { CreateWorkspaceModal } from 'components/workspaceModals/CreateWorkspaceModal';
import { TenantNetwork } from 'dashboard-engine/visualisations/Network/TenantNetwork';
import { useFlag } from 'lib/useFlag';
import { useOrgTitle } from 'queries/hooks/useOrgTitle';
import { useEffect } from 'react';
import StatusMenuBar, { StatusView } from './ui/MenuBar';
import StatusOverview from './ui/StatusOverview';
import { useStatusConfig } from './utils/useStatusConfig';
import withoutWorkspace from './utils/withoutWorkspace';

const VIEW_TYPE_KEY = '__TENANT_STATUS_VIEW__';

export function StatusPage() {
    const { data: orgTitle, isLoading: isOrgTitleLoading } = useOrgTitle();

    const [statusView, setStatusView] = useTenantSessionStorage(VIEW_TYPE_KEY, 'tile');

    const createWorkspaceModal = useModal();

    const tenantMapFlag = useFlag('tenantMap');

    const { data: config, isLoading: isConfigLoading } = useStatusConfig();

    useEffect(() => {
        // Map view is only permitted for workspace config
        if ((statusView === 'map' && config?.type !== 'space' && !isConfigLoading) || !tenantMapFlag) {
            setStatusView('tile');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config?.type]);

    return (
        <Template
            icon={<FontAwesomeIcon className='p-1 h-[27px]' icon={faGlobe} />}
            iconAlignment='center'
            title={!isOrgTitleLoading && orgTitle ? orgTitle : ''}
            flex
            equalPadding
        >
            <div className='flex flex-col grow overflow-x-auto mb-4'>
                <StatusMenuBar
                    view={statusView as StatusView}
                    setView={setStatusView}
                    isLoading={isConfigLoading}
                    config={config}
                />
                <div className='relative flex mt-5 overflow-auto grow scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                    {isConfigLoading && statusView === 'tile' && <Skeleton className='grow' />}
                    {statusView === 'map' && (
                        <div className='w-full p-2 bg-tileBackground rounded-lg h-full'>
                            <TenantNetwork />
                        </div>
                    )}
                    {statusView === 'tile' && !isConfigLoading && config && (
                        <StatusOverview
                            sort={config.sort}
                            type={config.type}
                            tags={config.tags}
                            maxColumns={Number(config?.viewSettings?.columnCount ?? 3)}
                            workspaceTypes={config.workspaceTypes}
                            stateFilters={config.filter || []}
                            viewSettings={config.viewSettings}
                            kpiTypeIds={config.kpiTypeIds}
                            openCreateWorkspace={createWorkspaceModal.open}
                        />
                    )}
                </div>
            </div>

            <Presence isPresent={createWorkspaceModal.isOpen}>
                <CreateWorkspaceModal close={createWorkspaceModal.close} />
            </Presence>
        </Template>
    );
}

export default withoutWorkspace(StatusPage);
