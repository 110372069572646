import type { Input, Position } from '@atlaskit/pragmatic-drag-and-drop/types';
import type { DashboardType } from '@squaredup/dashboards';
import { DashboardFolder } from 'queries/utils/dashboardSorted';
import { INDENT_PER_LEVEL, Instruction, MAX_NESTING_DEPTH } from './DashboardTreeUtils';

export type ItemType = 'dashboard' | 'folder' | 'ghost';

function getCenter(rect: DOMRect): Position {
    return {
        x: (rect.right + rect.left) / 2,
        y: (rect.bottom + rect.top) / 2
    };
}

function standardHitbox({
    client,
    borderBox,
    droppable
}: {
    client: Position;
    borderBox: DOMRect;
    droppable: boolean;
}): 'reorder-above' | 'reorder-below' | 'make-child' {
    // We can use 2 if not droppable since we don't need to detect the middle zone
    const quarterOfHeight = borderBox.height / (droppable ? 4 : 2);

    if (client.y <= borderBox.top + quarterOfHeight) {
        return 'reorder-above';
    }

    if (client.y >= borderBox.bottom - quarterOfHeight) {
        return 'reorder-below';
    }

    return 'make-child';
}

function getInstruction({
    element,
    input,
    currentLevel,
    itemType,
    expanded,
    lastInGroup
}: {
    element: Element;
    input: Input;
    currentLevel: number;
    itemType: ItemType;
    expanded?: boolean;
    lastInGroup?: boolean;
}): Instruction {
    const client: Position = {
        x: input.clientX,
        y: input.clientY
    };

    const borderBox = element.getBoundingClientRect();
    if ((itemType === 'dashboard' || (itemType === 'folder' && !expanded)) && !lastInGroup) {
        const type = standardHitbox({ borderBox, client, droppable: itemType === 'folder' });

        return { type, currentLevel };
    }

    const center: Position = getCenter(borderBox);

    if (itemType === 'folder' && expanded && !lastInGroup) {
        const type = standardHitbox({ borderBox, client, droppable: true });
        return {
            // If we're moving the item below then we want to turn this
            // into a make-child since the item will be added into our folder
            type: type === 'reorder-above' ? type : 'make-child',
            currentLevel
        };
    }

    // We now want to see if we're attempting to move the item to another nesting level

    const visibleInset = INDENT_PER_LEVEL * currentLevel + 12;

    // Before the left edge of the visible item.
    if (client.x < borderBox.left + visibleInset) {
        // If we're on the ghost item (item at end of the list) then
        // We always want to treat the instruction as a reparent
        if (client.y < center.y && itemType !== 'ghost') {
            return { type: 'reorder-above', currentLevel };
        }

        const rawLevel = (client.x - borderBox.left) / INDENT_PER_LEVEL;

        // We can get sub pixel negative numbers as getBoundingClientRect gives sub-pixel accuracy,
        // where as clientX is rounded to the nearest pixel.
        // Using Math.max() ensures we can never get a negative level
        const desiredLevel = Math.max(Math.floor(rawLevel), 0);

        return {
            type: 'reparent',
            desiredLevel,
            currentLevel
        };
    }

    if (itemType === 'ghost') {
        // No reparenting so we want to just treat this as an order below
        return {
            type: 'reorder-below',
            currentLevel
        };
    }

    return {
        type: standardHitbox({ borderBox, client, droppable: itemType === 'folder' }),
        currentLevel
    };
}

function blockInstructions(instruction: Instruction, source: DashboardTreeItemData): Instruction {
    if (instruction.type === 'make-child' && instruction.currentLevel + source.maxDepth >= MAX_NESTING_DEPTH) {
        return { type: 'instruction-blocked', desired: instruction, reason: 'Max folder depth reached' };
    }

    if (
        (instruction.type === 'reorder-above' || instruction.type === 'reorder-below') &&
        instruction.currentLevel + source.maxDepth > MAX_NESTING_DEPTH
    ) {
        return { type: 'instruction-blocked', desired: instruction, reason: 'Max folder depth reached' };
    }

    return instruction;
}

export type DashboardTreeItemData = {
    id: string;
    type: ItemType;
    isOpenOnDragStart?: boolean;
    maxDepth: number;
    treeId: string;
};

export function attachInstruction(
    instructionData: Parameters<typeof getInstruction>[0] & {
        source: DashboardTreeItemData;
        item: DashboardType | DashboardFolder;
    }
) {
    return {
        instruction: blockInstructions(getInstruction(instructionData), instructionData.source),
        id: instructionData.item.id
    };
}

export type AttachedInstruction = ReturnType<typeof attachInstruction>;
