import { AppContext, SetWorkspaceArgs } from 'contexts/AppContext';
import { isOpenAccess } from 'lib/openAccessUtil';
import useWindowDimensions from 'lib/useWindowDimensions';
import { useWorkspaces } from 'queries/hooks/useWorkspaces';
import { FC, ReactNode, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import Auth from 'services/Auth';
import { CURRENT_WORKSPACE, Workspace } from 'services/WorkspaceService';
import { useTheme } from 'ui/hooks/useTheme';

interface AppContextProps {
    children: ReactNode;
    workspaceLoadingElement?: any;
}

const checkWorkspaceExists = (workspaces: Workspace[], workspaceID?: string | null) =>
    workspaces.some((w: Workspace) => w.id === workspaceID);

/**
 * Wrapper to add a context provider to our app
 *
 */
const AppContextWrapper: FC<AppContextProps> = ({ children, workspaceLoadingElement = <div></div> }) => {
    const [currentWorkspaceIDState, setCurrentWorkspaceIDState] = useState<string | null | undefined>(() =>
        sessionStorage.getItem(CURRENT_WORKSPACE)
    );
    const { width } = useWindowDimensions();

    const [searchOpen, setSearchOpen] = useState<boolean>(false);

    const navigate = useNavigate();

    const isNarrowLayout = width != null && width < 550;
    const theme = useTheme();

    const { data: workspaces } = useWorkspaces();

    const setCurrentWorkspaceID = useMemo(
        () => (args?: SetWorkspaceArgs) => {
            const { performRedirect, callback, replaceHistory, force = false, pickBest = false } = args || {};
            if (!workspaces) {
                return;
            }

            if (isOpenAccess()) {
                // Open access workspace is always the target workspace for the open access share.
                setCurrentWorkspaceIDState(Auth?.user?.openAccessWorkspaceId ?? null);
                return;
            }

            const sessionStorageWorkspaceID = sessionStorage.getItem(CURRENT_WORKSPACE);

            let internalWorkspaceID: undefined | string | null = args?.id;

            const workspaceExists = checkWorkspaceExists(workspaces, internalWorkspaceID);
            const sessionWorkspaceExists = checkWorkspaceExists(workspaces, internalWorkspaceID);

            // If the workspace doesn't exist we need to pick one that does
            if ((!force && !workspaceExists && internalWorkspaceID) || pickBest) {
                // If the local storage workspace exists then we choose that
                if (internalWorkspaceID !== sessionStorageWorkspaceID && sessionWorkspaceExists) {
                    internalWorkspaceID = sessionStorageWorkspaceID;
                } else {
                    // Else we pick the first one in our list
                    internalWorkspaceID = workspaces[0]?.id || null;
                }
            }

            const workspace = workspaces.find((w) => w.id === internalWorkspaceID);
            // Only redirect if required
            if (performRedirect && workspace) {
                navigate(`/workspace/${workspace.id}`, { replace: replaceHistory });
            }

            setCurrentWorkspaceIDState(internalWorkspaceID);
            sessionStorage.setItem(CURRENT_WORKSPACE, internalWorkspaceID || '');

            callback?.();
        },
        [navigate, workspaces]
    );

    const value = useMemo(
        () => ({
            currentWorkspaceID: currentWorkspaceIDState,
            searchOpen,
            theme,
            isNarrowLayout,
            setCurrentWorkspaceID,
            setSearchOpen
        }),
        [currentWorkspaceIDState, theme, searchOpen, isNarrowLayout, setCurrentWorkspaceID]
    );

    return workspaces ? <AppContext.Provider value={value}>{children}</AppContext.Provider> : workspaceLoadingElement;
};

export default AppContextWrapper;
