import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StreamDataColumn, type DataStreamGroupingConfig } from '@squaredup/data-streams';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import stringify from 'fast-json-stable-stringify';
import { useCallback, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import type { PartialDeep } from 'type-fest';
import { useFormChange } from 'ui/editor/dataStream/hooks/useFormChange';
import {
    GroupingUpdateData,
    getAllRequiredColumnOptions,
    getGroupingColumns,
    toGroupingConfig,
    validateAggregations
} from 'ui/editor/dataStream/utilities/grouping';
import { AggregationRow } from './AggregationRow';
import { GroupRow } from './GroupRow';

interface GroupingFormProps {
    defaultValues: PartialDeep<GroupingUpdateData>;
    columns: StreamDataColumn[];
    onChange?: (
        event:
            | { isValid: true; formData: DataStreamGroupingConfig['group'] }
            | { isValid: false; formData: PartialDeep<GroupingUpdateData> }
    ) => void;
}

export const GroupingForm: React.FC<GroupingFormProps> = ({ defaultValues, columns, onChange }) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columnOptions = useMemo(() => getGroupingColumns(columns), [stringify(columns)]);

    const formProps = useForm<GroupingUpdateData>({ defaultValues });

    const { watch, control, setValue } = formProps;

    const {
        fields: groupFields,
        append: appendGroup,
        remove: removeGroup
    } = useFieldArray({
        control,
        name: 'groups'
    });

    const {
        fields: aggregationFields,
        append: appendAggregation,
        remove: removeAggregation
    } = useFieldArray({
        control,
        name: 'aggregations'
    });

    const handleChange = useCallback(
        (values: PartialDeep<GroupingUpdateData>) => {
            const isAggregationsAlreadySet = (values?.aggregations?.length ?? 0) > 0;
            const isGroupingAlreadySet = defaultValues.groups?.[0]?.groupBy != null;

            if (!isGroupingAlreadySet && !isAggregationsAlreadySet && values.groups?.[0]?.groupBy) {
                values.aggregations = [{ aggregationType: 'count' }];
                appendAggregation({ aggregationType: 'count' });
            }

            const validGroup = toGroupingConfig(values);

            if (validGroup == null) {
                onChange?.({ isValid: false, formData: values });
                return;
            }

            onChange?.({ isValid: true, formData: validGroup });
        },
        [appendAggregation, onChange, defaultValues]
    );

    useFormChange(watch, handleChange);

    const aggregations = watch('aggregations', []);
    const validAggregatorColumns = useMemo(() => getAllRequiredColumnOptions(columnOptions), [columnOptions]);

    validateAggregations(setValue, validAggregatorColumns, aggregations);

    return (
        <FormProvider {...formProps}>
            <form>
                <div className='grid grid-cols-[minmax(0,1fr)_minmax(0,1fr)_1.5rem] gap-2'>
                    {groupFields.length > 0 && (
                        <>
                            <Field.Label label='Group by' spacing='none' className='col-start-1 mt-2 -mb-2' />
                            <Field.Label
                                label='Bucket by'
                                spacing='none'
                                className='mt-2 -mb-2'
                                help='Groups data based on the date, e.g. grouped by day, month or year.'
                            />
                        </>
                    )}

                    {groupFields.map((field, index) => (
                        <GroupRow
                            key={field.id}
                            fieldIndex={index}
                            columns={columns}
                            columnOptions={columnOptions}
                            deleteGroup={() => removeGroup(index)}
                        />
                    ))}

                    <Button
                        onClick={() => appendGroup({ groupBy: null, bucketBy: null })}
                        variant='secondary'
                        className='col-start-1 mt-2 w-fit'
                        data-testid='grouping-addGroup'
                    >
                        <FontAwesomeIcon icon={faPlus} className='mr-3' /> <span>Add grouping</span>
                    </Button>

                    {aggregationFields.length > 0 && (
                        <>
                            <Field.Label label='Aggregation type' spacing='none' className='col-start-1 mt-2 -mb-2' />
                            <Field.Label label='Aggregation column' spacing='none' className='mt-2 -mb-2' />
                        </>
                    )}

                    {aggregationFields.map((field, index) => (
                        <AggregationRow
                            key={field.id}
                            fieldIndex={index}
                            aggregations={aggregations}
                            deleteAggregation={() => removeAggregation(index)}
                            requiredColumns={validAggregatorColumns.get(aggregations[index]?.aggregationType) ?? []}
                        />
                    ))}

                    <Button
                        onClick={() => appendAggregation({ aggregationType: 'count' })}
                        variant='secondary'
                        className='col-start-1 mt-2 w-fit'
                        data-testid='grouping-addAggregation'
                    >
                        <FontAwesomeIcon icon={faPlus} className='mr-3' /> <span>Add aggregation</span>
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};
