import PropTypes from 'prop-types';
import { UseFormRegister } from 'react-hook-form';

export type FormFieldProps = JSX.IntrinsicElements['textarea'] & {
    name: string;
    validation?: any;
    register: UseFormRegister<Record<string, any>>;
};

/**
 * TextArea
 * A textarea input component
 *
 * @example
 * `<TextArea name='test' rows=10 cols=10/>`
 */
function TextArea({ name, register, validation, ...inputProps }: FormFieldProps) {
    return (
        <textarea
            {...register(name, validation)}
            data-testid='textAreaInput'
            className='w-full h-full bg-transparent border-0 resize-none py-input leading-input px-md text-textPrimary autofill:text-textPrimary placeholder-textIncomplete disabled:text-textDisabled disabled:pointer-events-none focus:ring-0 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-inherit'
            {...inputProps}
        />
    );
}

TextArea.propTypes = {
    /**
     * Name of the input, e.g. in the output data
     */
    name: PropTypes.string.isRequired,
    /**
     * Text to be displayed as radio description
     */
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /**
     * Register function for forms - see React Hook Form
     */
    register: PropTypes.func,
    /**
     * Validation options, see React Hook Form
     */
    validation: PropTypes.object
};

export default TextArea;
