import Text from '@/components/Text';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Constants from '@squaredup/constants';
import { isFeatureEnabled } from '@squaredup/tenants';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import Form from 'components/forms/form/Form';
import Input from 'components/forms/input/Input';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ifNotOutside, ModalButtons } from 'components/Modal';
import { FeatureUnavailablePill } from 'components/plans/FeatureUnavailablePill';
import { TruncatedText } from 'components/TruncatedText';
import { useTier } from 'queries/hooks/useTier';
import { FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Create, Get, SCRIPTS, Update } from 'services/ScriptService';
import ScriptEditor from 'ui/editor/components/ScriptEditor';
import { ProjectedScript, scriptTypes, ScriptTypesKeys } from './Scripts';

type ScriptsFormData = {
    displayName: string;
    scriptType: ScriptTypesKeys;
};

type ScriptsAddEditModalProps = {
    onClose: () => void;
    onSave: () => void;
    script: ProjectedScript | undefined;
};

export const ScriptsAddEditModal: FC<ScriptsAddEditModalProps> = ({ onClose, onSave, script }) => {
    const initialScriptType = script?.scriptType
        ? Constants.getScriptTypeForSubType(script.scriptType)
        : scriptTypes.tileDataJS.value;

    const [scriptContent, setScriptContent] = useState<string | undefined>(undefined);

    const { data: tier } = useTier();

    const isScriptMonitorAvailable = tier ? isFeatureEnabled(tier, 'scriptMonitors') : false;

    useQuery(
        script && script.id ? script?.id : 'Default',
        async () => {
            if (script) {
                return Get(script.id);
            }
            return undefined;
        },
        {
            onSuccess: (s) => {
                setScriptContent(s?.config?.src !== undefined ? s?.config?.src : scriptTypes.tileDataJS.template);
            }
        }
    );

    const queryClient = useQueryClient();
    const defaults = { displayName: script?.displayName, scriptType: initialScriptType };

    const { mutateAsync: saveCustomScript } = useMutation(
        async (data: ScriptsFormData) => {
            const { displayName, scriptType } = data;
            const config = { src: scriptContent };

            if (script?.id) {
                await Update(script.id, displayName, config);
            } else {
                await Create(displayName, scriptType, config);
            }
        },
        {
            onSuccess: () => {
                onSave();
                onClose();
            },
            onSettled: async () => {
                queryClient.invalidateQueries([SCRIPTS]);
            }
        }
    );

    const scriptTypeLabelNode = (
        <div className='flex gap-2'>
            {scriptTypes.monitorConditionJS.label}
            <div className='flex items-center justify-center'>
                <FeatureUnavailablePill featureKey='scriptMonitors' title='Script evaluation' className='ml-2' />
            </div>
        </div>
    );

    return (
        <Modal close={ifNotOutside(onClose)} fullWidth maxWidth='max-w-6xl'>
            <div className='flex flex-row items-center flex-shrink-0 px-8 py-5 overflow-hidden border-b border-dividerPrimary'>
                <span className='flex flex-col items-start flex-1 overflow-hidden'>
                    <Text.H2 className='w-full truncate text-textPrimary' data-testid='modalHeader'>
                        <TruncatedText
                            title={script ? `Edit script: ${script.displayName}` : 'Add script'}
                            className='max-w-full truncate'
                        />
                    </Text.H2>
                </span>

                <FontAwesomeIcon
                    icon={faXmark}
                    className='ml-4 text-xl cursor-pointer text-tertiaryButton hover:text-tertiaryButtonHover'
                    onClick={() => onClose()}
                    data-testid='closeModal'
                />
            </div>
            <Form submit={saveCustomScript} defaultValues={defaults} className='flex flex-col flex-1 min-h-0'>
                {(isValid, isSubmitting) => (
                    <>
                        <div className='px-8 tile-scroll-overflow'>
                            <div className='mt-4 mb-4'>
                                <Field.Input
                                    name='displayName'
                                    label='Name'
                                    title='Name'
                                    placeholder='Enter a name'
                                    validation={{ required: true, maxLength: 128, minLength: 2 }}
                                />
                                <div className='relative mt-6 first:mt-0' data-testid='fieldTitle'>
                                    <label htmlFor='displayName' className='flex items-end mb-2 font-medium'>
                                        <span className='flex'>Script Type</span>
                                    </label>
                                    <Input
                                        type='radio'
                                        name='scriptType'
                                        value={scriptTypes.tileDataJS.value}
                                        label={scriptTypes.tileDataJS.label}
                                        validation={{ required: true, maxLength: 64, minLength: 2 }}
                                        className='py-radioWrapper'
                                        disabled={script !== undefined}
                                        selected={script === undefined}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const selectedType = e.target.value as ScriptTypesKeys;
                                            setScriptContent(scriptTypes[selectedType].template);
                                        }}
                                    />
                                    <Input
                                        type='radio'
                                        name='scriptType'
                                        value={scriptTypes.monitorConditionJS.value}
                                        label={scriptTypes.monitorConditionJS.label}
                                        labelNode={scriptTypeLabelNode}
                                        validation={{ required: true, maxLength: 64, minLength: 2 }}
                                        className='py-radioWrapper'
                                        disabled={script !== undefined || !isScriptMonitorAvailable}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const selectedType = e.target.value as ScriptTypesKeys;
                                            setScriptContent(scriptTypes[selectedType].template);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='text-sm text-textSecondary'>
                                <div className='flex flex-col w-full mb-4 min-h-[12.5rem] border border-outlinePrimary'>
                                    <ScriptEditor content={scriptContent} onValidUpdatedContent={setScriptContent} />
                                </div>
                            </div>
                        </div>
                        <ModalButtons>
                            <Button type='button' variant='tertiary' onClick={onClose}>
                                Cancel
                            </Button>
                            <Button type='submit' disabled={isSubmitting || !isValid} data-testid='submit-custom-types'>
                                {isSubmitting ? <LoadingSpinner size={18} /> : 'Save'}
                            </Button>
                        </ModalButtons>
                    </>
                )}
            </Form>
        </Modal>
    );
};
