import { faCircleQuestion, faPause, faPencil, faPlay, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Serialised } from '@squaredup/ids';
import { isV1AlertingRule } from '@squaredup/monitoring';
import { FeatureKey, isFeatureEnabled, Tier } from '@squaredup/tenants';
import { ColumnDef } from '@tanstack/react-table';
import Button from 'components/button/Button';
import { FeatureUnavailablePill } from 'components/plans/FeatureUnavailablePill';
import Tooltip from 'components/tooltip/Tooltip';
import type { ChannelStatus, ProjectedChannel } from 'dynamo-wrapper';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { NotificationDestinationIcon } from 'pages/monitoring/NotificationDestinationIcon';
import { FC, useMemo } from 'react';
import { ChannelType } from 'services/NotificationChannelService';
import { Workspace } from 'services/WorkspaceService';

interface NotificationsTableProps {
    channels: Serialised<ProjectedChannel>[];
    workspaces: Workspace[];
    setEnabledState: (id: string, enabled: boolean) => void;
    setEditing: (id: string) => void;
    setDeleting: (id: string) => void;
    setInspectingWorkspaceCount: (channel: Serialised<ProjectedChannel>) => void;
    channelTypes: Serialised<ChannelType>[];
    tier: Tier | undefined;
}

interface ProjectedRow {
    id: `channel-${string}`;
    displayName: string;
    channelTypeId: string;
    workspaceCount: number;
    ruleCount: number;
    status: string;
    enabled: Serialised<boolean>;
    state: Serialised<ChannelStatus>;
    channel: Serialised<ProjectedChannel>;
    requiresFeature: FeatureKey | undefined;
}

const NotificationsApplicationTable = ApplicationTable<ProjectedRow, string | boolean>();

export const NotificationsTable: FC<NotificationsTableProps> = ({
    channels,
    workspaces,
    setDeleting,
    setEditing,
    setEnabledState,
    setInspectingWorkspaceCount,
    tier,
    channelTypes
}) => {
    const channelInTierById = new Map(
        channelTypes?.map((ct) => [
            ct.id, !ct.requiresFeature || (tier !== undefined && isFeatureEnabled(tier, ct.requiresFeature))
        ]) ?? []
    );

    const columns: ColumnDef<ProjectedRow>[] = [
        {
            accessorKey: 'displayName',
            id: 'destination',
            header: 'Destination',
            cell: ({ row }) => {
                return (
                    <div className='flex items-center'>
                        <NotificationDestinationIcon className='w-5 h-5' channelTypeId={row.original.channelTypeId} />
                        <span className='ml-2'>{row.original.displayName}</span>
                    </div>
                );
            },
            size: 350
        },
        {
            accessorKey: 'workspaceCount',
            id: 'workspaces',
            header: 'Workspaces',
            cell: ({ row }) => (
                <Button
                    type='button'
                    variant='tertiary'
                    onClick={() => setInspectingWorkspaceCount(row.original.channel)}
                >
                    <span className='cursor-pointer select-none text-textLink hover:underline'>
                        {row.original.workspaceCount}
                    </span>
                </Button>
            ),
            size: 100
        },
        {
            accessorKey: 'ruleCount',
            id: 'rules',
            header: 'Rules',
            cell: ({ row }) => row.original.ruleCount,
            size: 100
        },
        {
            accessorKey: 'status',
            id: 'status',
            header: 'Status',
            cell: ({ row }) => {
                const status = row.original.status;
                const isEnabled = status === 'Active';
                const isFailing = row.original.state && row.original.state.failed;

                return (
                    <div className='flex items-center'>
                        {isEnabled && isFailing ? (
                            <Tooltip title={row.original.state.failureReason}>
                                <span>Failed</span>
                                <FontAwesomeIcon icon={faCircleQuestion} className='ml-1' />
                            </Tooltip>
                        ) : (
                            <>
                                <span>{status}</span>
                                {row.original.requiresFeature && (
                                    <FeatureUnavailablePill featureKey={row.original.requiresFeature} className='ml-3' />
                                )}
                            </>
                        )}
                    </div>
                );
            },
            size: 180
        }
    ];

    const usageCounts = useMemo(
        () =>
            workspaces.reduce(
                (acc, workspace) => {
                    const rules = workspace.data.alertingRules ?? [];
                    const channelIds = rules.map((r) =>
                        isV1AlertingRule(r) ? [r.channelId] : r.channels.map((c) => c.id)
                    );

                    for (const rule of channelIds) {
                        for (const channelId of rule) {
                            const workspaceSet = acc.workspaceUseByChannelId.get(channelId) ?? new Set<string>();
                            acc.ruleUseByChannelId.set(channelId, (acc.ruleUseByChannelId.get(channelId) ?? 0) + 1);
                            acc.workspaceUseByChannelId.set(channelId, workspaceSet.add(workspace.id));
                        }
                    }

                    return acc;
                },
                {
                    workspaceUseByChannelId: new Map<string, Set<string>>(),
                    ruleUseByChannelId: new Map<string, number>()
                }
            ),
        [workspaces]
    );

    const projectedRows: ProjectedRow[] = channels.map((c) => ({
        id: c.id,
        displayName: c.displayName ?? c.name,
        channelTypeId: c.channelTypeId,
        workspaceCount: usageCounts.workspaceUseByChannelId.get(c.id)?.size ?? 0,
        ruleCount: usageCounts.ruleUseByChannelId.get(c.id) ?? 0,
        status: channelInTierById.get(c.channelTypeId) 
            ? (c.enabled ? 'Active' : 'Paused') 
            : 'Disabled',
        enabled: c.enabled,
        state: c.status,
        channel: c,
        requiresFeature: channelTypes.find((ct) => ct.id === c.channelTypeId)?.requiresFeature
    }));

    const actions = [
        {
            action: ({ id, enabled }: { id: string; enabled: boolean }) => setEnabledState(id, !enabled),
            icon: (channel: { enabled: boolean }) => (channel.enabled ? faPause : faPlay),
            dataTestId: 'toggleEnabled',
            tooltip: (channel: { enabled: boolean }) => `${channel.enabled ? 'Disable' : 'Enable'} destination`,
            visible: ({ channelTypeId }: { channelTypeId: string }) => channelInTierById.get(channelTypeId)
        },
        {
            action: ({ id }: { id: string }) => setEditing(id),
            icon: faPencil,
            dataTestId: 'editNotificationRule',
            tooltip: 'Edit destination'
        },
        {
            action: ({ id }: { id: string }) => setDeleting(id),
            icon: faTrash,
            dataTestId: 'deleteNotificationRule',
            tooltip: 'Delete destination'
        }
    ];

    return (
        <NotificationsApplicationTable
            config={{
                actions,
                noDataMessage: 'There are no destinations configured.'
            }}
            columns={columns}
            data={projectedRows}
        />
    );
};
