import type { TileConfig } from '@squaredup/dashboards';
import type { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import Modal, { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';
import React from 'react';

type TileDeleteModalProps = {
    tileConfig: TileConfig;
    close: (shouldDelete?: boolean) => void;
};

export const TileDeleteModal: React.FC<TileDeleteModalProps> = ({ tileConfig, close }) => {
    let description = 'Are you sure you want to permanently delete this tile?';

    if ((tileConfig as DataStreamBaseTileConfig).monitor) {
        description = `This tile has monitoring enabled. Deleting it may effect the health of the current workspace. ${description}`;
    }

    return (
        <Modal
            title={`Deleting ${tileConfig.title}`}
            description={description}
            close={() => close()}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <ModalButtons>
                <Button type='button' variant='tertiary' onClick={() => close()}>
                    Cancel
                </Button>

                <Button type='button' variant='destructive' onClick={() => close(true)} data-testid='confirmDeleteTile'>
                    Delete
                </Button>
            </ModalButtons>
        </Modal>
    );
};
