import { getSharingEnvironment } from '@squaredup/open-access';
import { TenantResponse } from 'services/TenantService';
import type { Workspace } from 'services/WorkspaceService';
import { useTenant } from './useTenant';
import { useWorkspace } from './useWorkspace';

const useWorkspaceAuthenticatedDomainsBase = (
    workspace: Workspace | null | undefined,
    isWorkspaceEmailDomainsAllowed: boolean
) => {
    if (!isWorkspaceEmailDomainsAllowed) {
        return [];
    }
    return workspace?.data.properties?.authorizedEmailDomains ?? [];
};

export const useWorkspaceAuthenticatedDomains = (workspaceId: string | null | undefined) => {
    const tenant = useTenant().data;
    const { tenantSharingSettings: sharingSettings } = getSharingEnvironment(tenant);
    const isWorkspaceEmailDomainsAllowed = sharingSettings.oaAllowWorkspaceLevelDomains === true;
    const { data: workspace } = useWorkspace(workspaceId);

    return useWorkspaceAuthenticatedDomainsBase(workspace, isWorkspaceEmailDomainsAllowed);
};

export const useWorkspaceAuthenticatedDomainsWithWorkspace = (
    workspace: Workspace | undefined,
    tenant?: TenantResponse
) => {
    const { tenantSharingSettings: sharingSettings } = getSharingEnvironment(tenant);
    const isWorkspaceEmailDomainsAllowed = sharingSettings.oaAllowWorkspaceLevelDomains === true;

    return useWorkspaceAuthenticatedDomainsBase(workspace, isWorkspaceEmailDomainsAllowed);
};

export const getWorkspaceAuthenticatedDomains = (workspace: Workspace | undefined, tenant?: TenantResponse) => {
    const { tenantSharingSettings: sharingSettings } = getSharingEnvironment(tenant);
    const isWorkspaceEmailDomainsAllowed = sharingSettings.oaAllowWorkspaceLevelDomains === true;

    if (!isWorkspaceEmailDomainsAllowed) {
        return [];
    }

    return workspace?.data.properties?.authorizedEmailDomains ?? [];
};
