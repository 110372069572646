import { Serialised } from '@squaredup/ids';
import type { ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import { streamDataKeys } from 'queries/queryKeys/streamDataKeys';
import { useQuery, UseQueryOptions } from 'react-query';
import { GetDataStreamsForPlugin } from 'services/DataStreamService';

type QueryReturnType = Serialised<ProjectedDataStreamDefinitionEntity>[];

export const usePluginDataStreams = <T = QueryReturnType>(
    pluginId: string,
    options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>
) => 
    useQuery(
        streamDataKeys.pluginDataStreams(pluginId), 
        async () => GetDataStreamsForPlugin(pluginId),
        {
            staleTime: Number.POSITIVE_INFINITY,
            cacheTime: Number.POSITIVE_INFINITY,
            ...options
        });
