import { fiveMinutes } from 'queries/constants';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { List, Workspace } from 'services/WorkspaceService';

type QueryReturnType = Awaited<ReturnType<typeof List>>;

/**
 * useQuery returning all workspaces
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of all workspaces in a tenant
 */
export const useWorkspaces = <T = Workspace[]>(options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>) => 
    useQuery(
        workspaceQueryKeys.list, 
        List, 
        {
            staleTime: fiveMinutes,
            cacheTime: fiveMinutes,
            keepPreviousData: true,
            ...options
        }
    );
