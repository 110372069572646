import { IconName, Library, library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { IconDefinition, fas } from '@fortawesome/pro-solid-svg-icons';
import { stackedBar } from './stackedBar';

/**
 * Add custom icon definitions here
 * @see https://github.com/FortAwesome/Font-Awesome/issues/13079
 */
const customIcons = [stackedBar];

/**
 * Initialize the font awesome icon library with our sets & any custom definitions
 * Note: this initializes the libray singleton in the global namespace so should only be called once
 * @returns Icon library
 */
export const setupLibrary = (): Library => {
    library.add(fas, fab, ...customIcons);

    return library;
};

/**
 * variable that holds all of the icons in a single object.
 */
export const allIcons = Object.values(setupLibrary().definitions).reduce((acc, icon) => ({ ...acc, ...icon }), {});

/**
 * @returns true if the iconName is part of the app icon library
 */
export const iconNameValid = (iconName: string): iconName is IconName => {
    return Boolean(allIcons[iconName]);
};

/**
 * key that is used to know if the workspace avatar is a svg from the app library
 */
export const workspacePredefinedIconKey = 'predefined-icon';

/**
 * extending the type definition of Library to include the custom one added above
 */
declare module '@fortawesome/fontawesome-svg-core' {
    interface Library {
        definitions: {
            fas: Record<string, IconDefinition>;
            fab: Record<string, IconDefinition>;
        };
    }
}
