import { cn } from '@/lib/cn';
import { TruncatedText } from 'components/TruncatedText';
import { WorkspaceStateIndicator } from 'components/ui/state/WorkspaceStateIndicator';
import { useAppContext } from 'contexts/AppContext';
import { Workspace } from 'services/WorkspaceService';

interface WorkspaceDropdownOptionProps {
    workspace: Workspace;
    onClick: () => void;
}

export const WorkspaceDropdownOption: React.FC<WorkspaceDropdownOptionProps> = ({ workspace, onClick }) => {
    const { currentWorkspaceID, setCurrentWorkspaceID } = useAppContext();
    const isActive = workspace.id === currentWorkspaceID;

    return (
        <button
            role='menuitem'
            className={cn(
                'flex w-full items-center group px-xs cursor-pointer leading-input hover:bg-dividerTertiary focus:bg-dividerTertiary py-autocomplete focus:ring-0 focus:outline-none',
                currentWorkspaceID && 'font-semibold'
            )}
            onClick={() => {
                setCurrentWorkspaceID({ id: workspace.id, performRedirect: true });
                onClick();
            }}
            key={workspace.id}
        >
            <WorkspaceStateIndicator
                id={workspace.id}
                className='flex-shrink-0'
                data-testid='current-workspace-health'
            />
            <TruncatedText
                className={cn(
                    'ml-2 block truncate group-hover:text-textPrimary font-normal text-textSecondary',
                    isActive && 'font-semibold text-textPrimary'
                )}
                title={workspace.displayName || ''}
            >
                {workspace.displayName}
            </TruncatedText>
        </button>
    );
};
