import DropdownMenu from 'components/dropdownMenu';
import { Dispatch, SetStateAction, useState } from 'react';
import { StatusRequestType } from './StatusOverview';

export const TypeMap = {
    space: 'Workspaces',
    dash: 'Dashboards',
    monitor: 'Monitors'
} as const;

export interface TypeFilterProps {
    type: StatusRequestType;
    setType: Dispatch<SetStateAction<StatusRequestType>>;
}

export default function TypeFilter({ type, setType }: TypeFilterProps) {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    return (
        <DropdownMenu onOpenChange={setIsDropdownOpened}>
            <DropdownMenu.Button className='capitalize whitespace-nowrap' dropdownOpen={isDropdownOpened} showChevron useFilterStyle>{TypeMap[type]}</DropdownMenu.Button>
            <DropdownMenu.Menu align='start'>
                {(['space', 'dash', 'monitor'] as StatusRequestType[]).map((t) => (
                    <DropdownMenu.CheckItem key={t} checked={type === t} onSelect={() => setType(t)}>
                        {TypeMap[t]}
                    </DropdownMenu.CheckItem>
                ))}
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
}
