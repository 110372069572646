import { Edge, Node } from '@squaredup/graph';
import { stateValues } from '@squaredup/monitoring';
import { NodesAndEdges } from '../types';

/* Replaces any instances of 'source nodes' with canonical nodes, and rewrites any edges that
* point to/from those the source nodes to point to/from the canonical nodes.
*
* Source nodes refer to the nodes imported from a given source, e.g. SCOM Host, Azure VM, New Relic host.
* e.g.
* OPP-APP01 (New Relic) ---is---> OPP-APP01 (canonical)
* OPP-APP01 (New Relic) ----hosts---> Order Processing Platform
* ends up as
* OPP-APP01 (canonical) ---hosts---> Order Processing Platform
*/
export const rewriteSourceToCanonical = ({nodes, edges}: NodesAndEdges): NodesAndEdges => {
   // Find all canonicals nodes
   const canonicalNodeIdStateMap = nodes.reduce((acc, n) => {
       if (Object.prototype.hasOwnProperty.call(n, '__canonicalType')) {
           acc.set(n.id, stateValues.unknown);
       }
       return acc;
   }, new Map());

   // Create a map of IDs for any 'is' edges (Source ID -> Canonical ID), any edges that reference these need rewriting
   const toRewrite = edges.reduce((acc: Record<string, any>, e) => {
       if (e.label ==='is' && canonicalNodeIdStateMap.has(e.inV)) {
           acc[e.outV] = e.inV;
       }
       return acc;
   }, {});

   // Rewrite any edges that start/end with a 'source ID', and make it start/end to the canonical
   const rewrittenEdges = edges.reduce((newEdges: Edge[], edge) => {
        const newEdge = {...edge};
        // Rewrite any edges that originate from a source ID
        const newOutV = toRewrite[newEdge.outV];
        if (newOutV) {
            newEdge.outV = newOutV;
        }
        // Rewrite any edges that point to a source ID
        const newInV = toRewrite[newEdge.inV];
        if (newInV) {
            newEdge.inV = newInV;
        }

        if (!(newEdge.label === 'is' && newEdge.inV === newEdge.outV)) { // Don't include any edges that now point to themselves
            newEdges.push(newEdge);
        }

       return newEdges;
   }, []);

   let haveCanonicalWithUpdatedState = false;
   const rewrittenNodes = nodes.reduce((acc: Node[], n) => {
       const canonicalIdOfSourceNode = toRewrite[n.id];
       if (canonicalIdOfSourceNode) {
           // Get state of connected source node and apply to canonical if a worse state but do
           // *NOT* call acc.push for this source node as it's been re-written as the canonical
           const stateValue = (stateValues as never)[`${n.state}`];
           if (stateValue > canonicalNodeIdStateMap.get(canonicalIdOfSourceNode)) {
               canonicalNodeIdStateMap.set(canonicalIdOfSourceNode, stateValue);
               haveCanonicalWithUpdatedState = true;
           }
       } else {
            // Not a source node with an 'is' edge to a canonical so we want it
            acc.push({
                ...n,
                ...'__canonicalType' in n && {
                    sourceNodeIds: Object.entries(toRewrite).reduce((nodeIds, [sourceNodeId, canonicalId]) => {
                        if (canonicalId === n.id) {
                            nodeIds.push(sourceNodeId);
                        }
                        return nodeIds;
                    }, [] as string[])
                } 
            });
       }
       return acc;
   }, []);

   if (haveCanonicalWithUpdatedState) {
       // Apply final (worse case) canonical state to canonicals if they have a known (not unknown) state
       const stateValueKeys = Object.keys(stateValues);
       for (const n of rewrittenNodes) {
           const canonicalStateValue = canonicalNodeIdStateMap.get(n.id);
           if (canonicalStateValue) {
               n.state = stateValueKeys[canonicalStateValue];
           }
       }
   }

   return {
       nodes: rewrittenNodes,
       edges: rewrittenEdges
   };
};