import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EntityTypes } from '@squaredup/constants';
import { GraphResults } from '@squaredup/graph';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import Form from 'components/forms/form/Form';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ifNotOutside, ModalButtons } from 'components/Modal';
import ObjectNetwork from 'dashboard-engine/visualisations/Network/ObjectNetwork';
import trackEvent from 'lib/analytics';
import { allowProp } from 'pages/settings/Common';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Create, CUSTOM_CORRELATIONS, ProjectedCustomCorrelation, Update } from 'services/CustomCorrelationsService';
import { Query } from 'services/GraphService';

type CustomCorrelationFormData = {
    displayName: string;
    verb: string;
    fromType: { label: string; value: string };
    fromProp: { label: string; value: string };
    toType: { label: string; value: string };
    toProp: { label: string; value: string };
};

type CustomCorrelationsAddEditModalProps = {
    onClose: () => void;
    onSave: () => void;
    customCorrelation: ProjectedCustomCorrelation | undefined;
};

/**
 * Type of access control to use when looking up types/properties in the graph.
 *
 * Note that directOrAnyWorkspaceLinks isn't quite right - in theory an admin user with no access to any workspaces or
 * plugins may want to configure a correlation rule for data they can't see.  But that's unlikely, so we'll go
 * with this for now.
 *
 * (If we want to show all types here, regardless of the user's plugin/workspace permissions, we could use the
 * types loaded through /customtypes?includePluginCustomTypes=true, but that doesn't include properties.
 * An alternative would be to allow graph queries through /query to bypass access control for admins,
 * but that would open a huge security hole, so is a non-starter.  Perhaps the best option would be to add
 * a specific API for retrieving correlation types/properties, which requires admin rights and bypasses access
 * control.)
 */
const queryAccessControlType = 'directOrAnyWorkspaceLinks';

const propBlackList = ['type', 'sourceType', 'sourceAccount', 'sourceInstance', 'sourceKind'];
const propWhiteList = ['__name'];
const internalTypes: string[] = [
    EntityTypes.WORKSPACE,
    EntityTypes.DASHBOARD,
    EntityTypes.SCOPE,
    EntityTypes.CONFIG,
    EntityTypes.VARIABLE,
    EntityTypes.MONITOR,
    EntityTypes.TILE
];

function propValid(prop: string) {
    return allowProp(prop, propBlackList, propWhiteList);
}

const validateProp = (prop: string) => {
    return propValid(prop) ? true : `Property ${prop} is not permitted.`;
};

const asOption = (v: string) => ({ label: v, value: v });

function CustomCorrelationsAddEditModal({ onClose, onSave, customCorrelation }: CustomCorrelationsAddEditModalProps) {
    const [currentSourceType, setCurrentSourceType] = useState(customCorrelation?.config.fromType);
    const [currentTargetType, setCurrentTargetType] = useState(customCorrelation?.config.toType);

    const queryClient = useQueryClient();

    const { mutateAsync: saveCustomCorrelation } = useMutation(
        async (data: CustomCorrelationFormData) => {
            const { displayName, ...configOptions } = data;
            const config = {
                verb: configOptions.verb,
                fromType: configOptions.fromType.value,
                fromProp: configOptions.fromProp.value,
                toType: configOptions.toType.value,
                toProp: configOptions.toProp.value
            };

            if (customCorrelation?.id) {
                // editing existing custom correlation
                await Update(customCorrelation.id, displayName, config);
                trackEvent('Correlation Updated', {
                    name: displayName,
                    from: `${config.fromType} ${config.fromProp}`,
                    to: `${config.toType} ${config.toProp}`
                });
            } else {
                // add new custom correlation
                await Create(displayName, config);
                trackEvent('Correlation Created', {
                    name: displayName,
                    from: `${config.fromType} ${config.fromProp}`,
                    to: `${config.toType} ${config.toProp}`
                });
            }
        },
        {
            onSuccess: () => {
                onSave();
                onClose();
            },
            onSettled: async () => {
                queryClient.invalidateQueries([CUSTOM_CORRELATIONS]);
            }
        }
    );

    // Set defaults if we are editing
    const defaults = customCorrelation && {
        displayName: customCorrelation.displayName,
        verb: customCorrelation.config.verb,
        fromType: asOption(customCorrelation.config.fromType),
        fromProp: asOption(customCorrelation.config.fromProp),
        toType: asOption(customCorrelation.config.toType),
        toProp: asOption(customCorrelation.config.toProp)
    };

    // Load the list of available types
    const { data: types, isLoading: typesLoading } = useQuery(['TYPES'], () =>
        Query(
            {
                gremlinQuery:
                    "g.V().has('type').not(has('sourceType', within(internalTypes))).group().by('type').select(keys)",
                bindings: {
                    internalTypes: internalTypes.map((t) => `squaredup/${t}`)
                }
            },
            queryAccessControlType
            // eslint-disable-next-line max-len
        ).then(({ gremlinQueryResults }) =>
            gremlinQueryResults[0].sort().map((t: GraphResults) => ({ label: t, value: t }))
        )
    );

    const { data: propsSource, isLoading: propsSourceLoading } = useLookupPropsForType(currentSourceType);
    const { data: propsTarget, isLoading: propsTargetLoading } = useLookupPropsForType(currentTargetType);

    return (
        <Modal
            title={
                customCorrelation ? `Edit correlation rule: ${customCorrelation.displayName}` : 'Add correlation rule'
            }
            close={ifNotOutside(onClose)}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <Form submit={saveCustomCorrelation} defaultValues={defaults} className='flex flex-col flex-1 min-h-0'>
                {(isValid, isSubmitting, data) => (
                    <>
                        <div className='px-8 tile-scroll-overflow'>
                            <Field.Input
                                name='displayName'
                                label='Name'
                                title='Name'
                                placeholder='Enter a name'
                                help='This name is only used to identify your correlation rule and is not displayed in the application.'
                                validation={{
                                    required: true,
                                    maxLength: 128,
                                    minLength: 1
                                }}
                            />
                            <div>
                                <h2 className='mt-6 font-medium text-md'>From</h2>
                                <p className='text-textSecondary'>
                                    Select a type and property to be used as the source for this rule, associations will
                                    originate from objects that match the data entered.
                                </p>
                                <div className='flex pl-4 mt-4 mb-4 border-l-4 border-dividerPrimary'>
                                    <div className='w-1/2 mt-0'>
                                        <Field.Input
                                            name='fromType'
                                            label='Type'
                                            title='Type'
                                            type='autocomplete'
                                            options={types}
                                            isMulti={false}
                                            forceLower={true}
                                            formatCreateLabel={(label: string) => `Add ${label.toLowerCase()}`}
                                            isLoading={typesLoading}
                                            onCreate={(v: string) => ({
                                                label: v.toLowerCase(),
                                                value: v.toLowerCase()
                                            })}
                                            onSelect={(v?: { label: string; value: string }) =>
                                                setCurrentSourceType(v?.value)
                                            }
                                            placeholder='Enter a type'
                                            help='This should match the type field on the source object.'
                                            validation={{
                                                required: true,
                                                maxLength: 128,
                                                minLength: 1
                                            }}
                                        />
                                    </div>
                                    <div className='w-1/2 mt-0 ml-4'>
                                        <Field.Input
                                            name='fromProp'
                                            label='Property'
                                            title='Property'
                                            type='autocomplete'
                                            options={propsSource}
                                            isMulti={false}
                                            isLoading={propsSourceLoading}
                                            onCreate={(v: string) => ({ label: v, value: v })}
                                            placeholder='Enter a property name'
                                            help='This property will be matched against the to property value.'
                                            validation={{
                                                required: true,
                                                maxLength: 128,
                                                minLength: 1,
                                                validate: (prop) => validateProp(prop.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2 className='mt-8 font-medium text-md'>To</h2>
                                <p className=' text-textSecondary'>
                                    Select a type and property to be used as the target for this rule, associations will
                                    target objects that match the data entered.
                                </p>
                                <div className='flex pl-4 mt-4 mb-6 border-l-4 border-dividerPrimary'>
                                    <div className='w-1/2 mt-0'>
                                        <Field.Input
                                            name='toType'
                                            label='Type'
                                            title='Type'
                                            type='autocomplete'
                                            options={types}
                                            isMulti={false}
                                            forceLower={true}
                                            formatCreateLabel={(label: string) => `Add ${label.toLowerCase()}`}
                                            isLoading={typesLoading}
                                            onCreate={(v: string) => ({
                                                label: v.toLowerCase(),
                                                value: v.toLowerCase()
                                            })}
                                            onSelect={(v?: { label: string; value: string }) =>
                                                setCurrentTargetType(v?.value)
                                            }
                                            placeholder='Enter a type'
                                            help='This should match the type field on the to object.'
                                            validation={{
                                                required: true,
                                                maxLength: 128,
                                                minLength: 1
                                            }}
                                        />
                                    </div>
                                    <div className='w-1/2 mt-0 ml-4'>
                                        <Field.Input
                                            name='toProp'
                                            label='Property'
                                            title='Property'
                                            type='autocomplete'
                                            options={propsTarget}
                                            isMulti={false}
                                            isLoading={propsTargetLoading}
                                            onCreate={(v: string) => ({ label: v, value: v })}
                                            placeholder='Enter a property name'
                                            help='This property will be matched against the from property value.'
                                            validation={{
                                                required: true,
                                                maxLength: 128,
                                                minLength: 1,
                                                validate: (prop) => validateProp(prop.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Field.Input
                                name='verb'
                                label='Association label'
                                title='Association label'
                                placeholder='Enter a label'
                                help='This label is used to describe the association, e.g. `hosts` or `contains`.'
                                validation={{
                                    required: true,
                                    maxLength: 25,
                                    minLength: 1,
                                    validate: (val: string) => {
                                        const isCanonical = val?.toLowerCase() === 'is';
                                        if (!isCanonical) {
                                            return true;
                                        }
                                        const fromTypeVal = data.fromType?.value;
                                        const toTypeVal = data.toType?.value;
                                        if (!fromTypeVal || !toTypeVal || fromTypeVal !== toTypeVal) {
                                            return 'Canonical associations are only supported for matching From and To Type fields.';
                                        }
                                        return true;
                                    }
                                }}
                            />

                            <div style={{ height: 200 }} className='relative z-0 mb-16'>
                                <h2 className='mt-6 mb-4 font-medium text-md'>Preview</h2>
                                {getPreview(data)}
                            </div>

                            {customCorrelation && (
                                <div className='flex mt-4 rounded-md py-input px-md bg-statusWarningPrimary text-primaryButtonText bg-opacity-40'>
                                    <FontAwesomeIcon icon={faCircleExclamation} className='mt-1 mr-2' />
                                    <p>
                                        Editing an existing correlation rule may result in 'stale' associations being
                                        present for a short time.
                                    </p>
                                </div>
                            )}
                        </div>

                        <ModalButtons>
                            <Button type='button' onClick={() => onClose()} variant='tertiary'>
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                disabled={isSubmitting || !isValid}
                                data-testid='submit-custom-correlations'
                            >
                                {isSubmitting ? <LoadingSpinner size={18} /> : 'Save and run'}
                            </Button>
                        </ModalButtons>
                    </>
                )}
            </Form>
        </Modal>
    );
}

function getPreview(data: CustomCorrelationFormData) {
    const fromType = data.fromType?.value;
    const fromProp = data.fromProp?.value;
    const toType = data.toType?.value;
    const toProp = data.toProp?.value;
    const verb = data.verb;

    if (fromType && fromProp && toType && toProp && verb) {
        if (verb.toLowerCase() === 'is' && fromType !== toType) {
            const msg = "Canonical 'is' associations are only supported for matching From and To Type fields.";
            return (
                <p className='h-full text-center border text-statusErrorPrimary pt-7 bg-tileBackground border-tileOutline'>
                    {msg}
                </p>
            );
        } else {
            return <Preview data={data} />;
        }
    }

    return (
        <p className='h-full text-center border pt-7 rounded-input bg-tileBackground border-tileOutline'>
            Configure the rule above to preview.
        </p>
    );
}

type PreviewProps = {
    data: CustomCorrelationFormData;
};

/**
 * Renders a small preview of the correlation
 */
function Preview({ data }: PreviewProps) {
    return (
        <div className='h-full rounded-input bg-componentBackgroundPrimary'>
            <ObjectNetwork
                data={{
                    nodes: [
                        { id: '1', label: 'Example', type: [data.fromType.value] },
                        { id: '2', label: 'Example', type: [data.toType.value] }
                    ],
                    edges: [{ id: '3', label: data.verb, outV: '1', inV: '2' }]
                }}
            />
        </div>
    );
}

/**
 * For a given type, load the properties available
 * Sets the options available for the source/target as needed
 */
const useLookupPropsForType = (type: any) =>
    useQuery(
        ['PROPS_FOR_TYPE', type],
        () =>
            type &&
            Query(
                {
                    gremlinQuery: "g.V().has('type', type).properties().key().dedup()",
                    bindings: { type }
                },
                queryAccessControlType
            ).then(({ gremlinQueryResults }) =>
                gremlinQueryResults
                    .filter((p) => propValid(p))
                    .sort()
                    .map((p) => ({ label: p, value: p }))
            )
    );

export default CustomCorrelationsAddEditModal;
