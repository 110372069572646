import { Skeleton } from '@/components/Skeleton';
import { cn } from '@/lib/cn';

const BlocksSkeleton = ({ className, columns = 3 }: { className?: string; blockHeight?: number; columns?: number }) => {
    return (
        <Skeleton
            className={cn('rounded-none size-full', className)}
            style={{
                maskImage:
                    'linear-gradient(to right, rgba(0, 0, 0, 0) 7px, rgba(0, 0, 0, 1) 7px, rgba(0, 0, 0, 1) 67px, rgba(0, 0, 0, 0.7) 60px, rgba(0, 0, 0, 0.7))',
                maskSize: `calc(100% / ${columns})`,
                maskPosition: '-7px 0',
                maskRepeat: 'repeat-x'
            }}
        />
    );
};

export default BlocksSkeleton;
