import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import * as Constants from '@squaredup/constants';
import { Serialised } from '@squaredup/ids';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import type { ApiKey } from 'dynamo-wrapper';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { Action } from 'pages/components/ApplicationTable/types';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { APIKEY_DATA, List } from 'services/ApiKeyService';
import { SettingsTemplate } from '../SettingsTemplate';
import ApiKeysDeleteModal from './ApiKeysDeleteModal';
import ApiKeysModal from './ApiKeysModal';
import { useTier } from 'queries/hooks/useTier';
import { isFeatureEnabled } from '@squaredup/tenants';
import { FeatureUnavailableBanner } from 'components/plans/FeatureUnavailableBanner';

export interface ApiKeyRow extends Serialised<ApiKey> {
    last4Chars: string;
}

const ApiKeysApplicationTable = ApplicationTable<ApiKeyRow, string>();

function ApiKeys() {
    const queryClient = useQueryClient();
    const [newApiKeyModalOpen, setNewApiKeyModalOpen] = useState(false);
    const [apiKeyToDelete, setApiKeyToDelete] = useState<Serialised<ApiKey> | null>(null);

    const { data: tier, isLoading: isLoadingTier } = useTier();
    const accessAllowed = tier !== undefined && isFeatureEnabled(tier, 'apiUsage');

    const {
        data: existingApiKeys,
        isIdle,
        isLoading: isLoadingAPIKeys
    } = useQuery(APIKEY_DATA, List, {
        select: (data) => {
            return data
                .sort((a, b) => (a.displayName ?? '').localeCompare(b.displayName ?? ''))
                .map((key) => ({
                    ...key,
                    last4Chars: Array(17).join('*') + key.last4Chars
                }))
                .filter(({ subType }) => subType === Constants.ApiKeySubTypes.PROGRAMMATIC);
        }
    });

    const onDelete = async (apiKey: Serialised<ApiKey>) => {
        setApiKeyToDelete(apiKey);
    };

    const columnHelper = createColumnHelper<ApiKeyRow>();
    const columns: ColumnDef<ApiKeyRow, string>[] = [
        columnHelper.accessor((row) => row.displayName, {
            id: 'name',
            header: 'Name',
            cell: ({ getValue }) => `${getValue()}${!accessAllowed ? ' (disabled)' : ''}`,
            size: 200
        }),
        columnHelper.accessor((row) => row.description, {
            id: 'description',
            header: 'Description',
            cell: ({ getValue }) => getValue() || '',
            size: 200
        }),
        columnHelper.accessor((row) => row.last4Chars, {
            id: 'key',
            header: 'API Key',
            cell: ({ getValue }) => getValue(),
            size: 150
        })
    ];

    const actions: Action[] = [
        {
            visible: () => true,
            action: onDelete,
            icon: faTrash,
            dataTestId: 'deleteApiKey',
            tooltip: 'Delete API key'
        }
    ];

    const isLoading = isIdle || isLoadingAPIKeys || isLoadingTier;

    return (
        <SettingsTemplate
            title='API'
            description={accessAllowed ? 'The SquaredUp API can be used to automate configuration and trigger specific actions.' : undefined}
            learnMoreLink='https://squaredup.com/cloud/advanced-features/api'
        >
            {isLoading ? (
                <span className='flex justify-center'>
                    <LoadingSpinner />
                </span>
            ) : (
                <>
                    <FeatureUnavailableBanner
                        className='mb-9'
                        featureKey='apiUsage'
                        summary='The SquaredUp API can be used to automate configuration and trigger specific actions.'
                        title='Automate your observability'
                    />
                    <Button
                        onClick={() => setNewApiKeyModalOpen(true)}
                        data-testid='addProgrammaticApiKey'
                        disabled={!accessAllowed}
                    >
                        Add API key
                    </Button>
                    <div className='my-4' data-testid='programmaticApiKeys'>
                        <ApiKeysApplicationTable
                            config={{
                                actions,
                                noDataMessage: 'There are no API keys configured.'
                            }}
                            columns={columns}
                            data={existingApiKeys ?? []}
                        />
                    </div>
                </>
            )}

            {newApiKeyModalOpen && (
                <ApiKeysModal
                    subType={Constants.ApiKeySubTypes.PROGRAMMATIC}
                    close={async () => {
                        setNewApiKeyModalOpen(false);
                    }}
                    apiKeys={existingApiKeys?.map((k) => k.displayName?.toLowerCase() || undefined) || []}
                />
            )}

            {apiKeyToDelete && (
                <ApiKeysDeleteModal
                    apiKey={apiKeyToDelete}
                    close={async () => {
                        await queryClient.invalidateQueries(APIKEY_DATA);
                        setApiKeyToDelete(null);
                    }}
                />
            )}
        </SettingsTemplate>
    );
}

export default ApiKeys;
