import PropTypes from 'prop-types';
import React from 'react';
import Input from '../../input/Input';
import Field from '../Field';

/**
 * Field.Checkboxes
 * Helper for creating a Checkbox field. Checkbox are a little different as they have two labels and we should
 * show the required marker against the checkbox, rather than the label.
 * @param { any } param0
 * @example
 * `<Field.Checkboxes label='Options' inputLabel='Enable Access' name='test' validation={{ required: true }}/>`
 */
function FieldCheckboxes({ name, label, options, title, description, help, helpAlign, descriptionAbove, ...rest }) {
    return (
        <Field
            description={description}
            htmlFor={name}
            title={title || label}
            {...(help && { help })}
            {...(helpAlign && { helpAlign })}
            {...(descriptionAbove && { descriptionAbove })}
        >
            {options.length > 0 &&
                options.map((o, i) => (
                    <Input
                        type='checkbox'
                        key={i}
                        name={o.name || name}
                        label={o.label}
                        {...rest}
                    />
                ))}
        </Field>
    );
}

FieldCheckboxes.propTypes = {
    /**
     * Name of the input, used in output data.
     */
    name: PropTypes.string.isRequired,
    /**
     * Text to be displayed as field label
     */
    label: PropTypes.string,
    /**
     * Array of options (value/label)
     */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
            label: PropTypes.string,
            checked: PropTypes.bool
        })
    ),
    /**
     * Text to be displayed underneath input
     */
    description: PropTypes.string,
    /**
     * Text to display as the tooltip
     */
    title: PropTypes.string,
    /**
     * Text to displayed as help icon tooltip
     */
    help: PropTypes.string,
    /**
     * Validation settings - see React Hook Form
     */
    validation: PropTypes.object
};

export default FieldCheckboxes;
