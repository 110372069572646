import { faCodeMerge } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/tooltip/Tooltip';
import { useCallback } from 'react';
import { ControlButton } from 'reactflow';
import { useGetGraphEdges, useGetGraphNodes, useGetPinnedGroupsWithMemberIds, useGetPinnedNodeIds, useNetworkNodes, useSetExpandedNodeIds } from '../context/NetworkMapStoreContext';
import { useRevealExpandedNodes } from '../hooks/useRevealExpandedNodes';

export const CollapseAllButton: React.FC = () => {
    const nodes = useNetworkNodes();
    const getPinnedNodeIds = useGetPinnedNodeIds();
    const getPinnedGroupMemberNodeIds = useGetPinnedGroupsWithMemberIds();
    const setExpandedNodeIds = useSetExpandedNodeIds();
    const getGraphNodes = useGetGraphNodes();
    const getGraphEdges = useGetGraphEdges();
    const revealExpandedNodes = useRevealExpandedNodes();

    const expandedNodeIds = nodes
        .filter(({ data }) => data.expanded)
        .map(({ id }) => id);

    const handleOnClick = useCallback(() => {
        const pinnedNodeIds = getPinnedNodeIds();
        const pinnedGroupMemberNodeIds = getPinnedGroupMemberNodeIds();
        const graphNodes = [...getGraphNodes()];
        const graphEdges = [...getGraphEdges()];

        setExpandedNodeIds([]);

        revealExpandedNodes(
            [],
            graphNodes,
            graphEdges,
            [],
            pinnedNodeIds,
            pinnedGroupMemberNodeIds,
            []
        );
    }, [
        getPinnedNodeIds, 
        getPinnedGroupMemberNodeIds, 
        getGraphNodes, 
        getGraphEdges, 
        setExpandedNodeIds, 
        revealExpandedNodes
    ]);

    if (!expandedNodeIds.length) {
        return null;
    }

    return (
        <Tooltip title='Collapse all' >
            <ControlButton className='relative' onClick={handleOnClick}>
                <FontAwesomeIcon icon={faCodeMerge} />
            </ControlButton>
        </Tooltip>
    );
};