import { cn } from '@/lib/cn';
import React from 'react';
import LoadingSpinner from './LoadingSpinner';

const AbsoluteDiv: React.FC<{ className?: string }> = ({ children, className }) => (
    <div className={cn('absolute inset-0 flex items-center justify-center w-full h-full align-middle', className)}>
        {children}
    </div>
);

/**
 * Wraps the child components with a dimmed overlay and spinner when loading is true
 * This means the child components are still partially visible while loading new data
 */
export const LoadingOverlay: React.FC<{ loading: boolean }> = ({ loading, children }) => {
    return (
        <div className='relative w-full h-full'>
            {children}
            {loading && <>
                <AbsoluteDiv className='opacity-50 bg-tileBackground'></AbsoluteDiv>
                <AbsoluteDiv>
                    <LoadingSpinner />
                </AbsoluteDiv>
            </>}
        </div>
    );
};
