import { cn } from '@/lib/cn';
import { faClock, faThumbTack } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TimeframeEnumValue, getTimeframeLabel, timeframeNames } from '@squaredup/timeframes';
import DropdownMenu from 'components/dropdownMenu';
import Tooltip from 'components/tooltip/Tooltip';
import { groupBy } from 'lodash';
import { useState } from 'react';

type DashboardTimeframeChooserProps = {
    currentTimeframe: TimeframeEnumValue;
    defaultTimeframe?: TimeframeEnumValue;
    disabled?: boolean;
    isFixedTimeframe?: boolean;
    title?: string;
    canChangeTimeframe?: boolean;
    isNarrowLayout?: boolean;
    onCurrentTimeframeChange: (timeframe: TimeframeEnumValue) => void;
    onDefaultTimeframeChange: (timeframe: TimeframeEnumValue) => void;
};

const DashboardTimeframeChooser: React.FC<DashboardTimeframeChooserProps> = ({
    currentTimeframe,
    defaultTimeframe,
    disabled = false,
    isFixedTimeframe = false,
    title = 'Current dashboard timeframe',
    canChangeTimeframe,
    isNarrowLayout,
    onCurrentTimeframeChange,
    onDefaultTimeframeChange
}) => {
    const groupedTimeframes = groupBy(timeframeNames, 'group');
    const timeframeLabel = getTimeframeLabel(currentTimeframe, 'nonrelative');
    const [open, setOpen] = useState(false);

    return (
        <DropdownMenu modal={false} onOpenChange={setOpen}>
            <DropdownMenu.Button
                icon={!isNarrowLayout && <FontAwesomeIcon data-testid='icon' icon={faClock} />}
                disabled={disabled || isFixedTimeframe}
                data-testid='dashboardTimeframeChooser'
                className='data-[state=open]:bg-secondaryButtonBackgroundHover'
                title={isFixedTimeframe ? 'Unavailable, all tiles are using a fixed timeframe' : title}
                showChevron
                dropdownOpen={open}
            >
                {isNarrowLayout ? (
                    <FontAwesomeIcon data-testid='icon' icon={faClock} />
                ) : (
                    <>{isFixedTimeframe ? 'Unavailable' : timeframeLabel}</>
                )}
            </DropdownMenu.Button>

            <DropdownMenu.Menu portal={false} align='end' className='w-[320px] bg-backgroundPrimary'>
                {Object.entries(groupedTimeframes).map(([timeframeGroup, timeframes], index) => {
                    return (
                        <DropdownMenu.Group key={timeframeGroup}>
                            <p className='font-semibold px-xs py-xxxs text-textSecondary'>{timeframeGroup}</p>

                            {timeframes.map((timeframeInstance) => {
                                const { name: timeframe, label, getResolvedTimeRange } = timeframeInstance;
                                const isActive = currentTimeframe === timeframe;
                                const isDefault = defaultTimeframe === timeframe;
                                return (
                                    <DropdownMenu.Item
                                        key={timeframe}
                                        onSelect={() => onCurrentTimeframeChange(timeframe as TimeframeEnumValue)}
                                        className={cn('group flex', isActive && 'bg-dividerPrimary font-semibold')}
                                    >
                                        <span data-testid={timeframe} className='block mr-2 whitespace-nowrap'>
                                            {label}
                                        </span>

                                        {isDefault && (
                                            <span>
                                                <Tooltip title='Default timeframe' placement='right'>
                                                    <FontAwesomeIcon
                                                        className='text-textSecondary'
                                                        fixedWidth
                                                        icon={faThumbTack}
                                                    />
                                                </Tooltip>
                                            </span>
                                        )}

                                        <span className='opacity-0 group-hover:opacity-100'>
                                            <Tooltip
                                                title='Set default timeframe'
                                                placement='right'
                                                disabled={!isDefault && !canChangeTimeframe}
                                            >
                                                <FontAwesomeIcon
                                                    className={cn('text-textIncomplete hover:text-textSecondary', {
                                                        hidden: isDefault || !canChangeTimeframe
                                                    })}
                                                    fixedWidth
                                                    icon={faThumbTack}
                                                    onClick={() =>
                                                        onDefaultTimeframeChange(timeframe as TimeframeEnumValue)
                                                    }
                                                />
                                            </Tooltip>
                                        </span>

                                        <span
                                            data-testid={`resolvedTimeframe-${timeframe}`}
                                            className='flex justify-end flex-1 font-normal whitespace-nowrap text-textSecondary'
                                        >
                                            {getResolvedTimeRange()}
                                        </span>
                                    </DropdownMenu.Item>
                                );
                            })}

                            {index !== Object.keys(groupedTimeframes).length - 1 && <DropdownMenu.Separator />}
                        </DropdownMenu.Group>
                    );
                })}
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
};

export default DashboardTimeframeChooser;
