import { Post } from '../../../../services/WebApiService';
import { DatasourceFunction } from '../../../types/Datasource';
import Data from '../../../types/DataTypes';
import { WebApiConfig } from './Config';


//TODO: This can return anything, but is the right type here?
const webApi: DatasourceFunction<Data, WebApiConfig> = async (config) => {
    const response = await Post(config);

    return { 
        data: response 
    };
};

webApi.config = 'WebApiConfig';

export default webApi;

