import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useMemo } from 'react';

export const useVariableContext = (config: DataStreamBaseTileConfig) => {
    const { variables = [] } = useDashboardContext();

    return useMemo(() => {
        if (!config.variables || config.variables.length === 0 || variables.length === 0) {
            return;
        }

        return {
            variables: variables.map((v) => ({
                nodeIds: v.selectedObjects.map((o) => o.id).sort()
            }))
        };
    }, [config, variables]);
};
