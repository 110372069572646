import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/tooltip/Tooltip';
import { useDatasetContext } from '../contexts/DatasetContext';

export const AddDatasetButton: React.FC = () => {
    const { addDataset } = useDatasetContext();

    return (
        <Tooltip title='Add dataset' className='flex'>
            <button onClick={addDataset} className='text-textSecondary hover:text-textPrimary' data-testid='addDataset'>
                <FontAwesomeIcon icon={faPlus} />
            </button>
        </Tooltip>
    );
};
