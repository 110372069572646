import { CheckoutEventNames, initializePaddle, Paddle, PaddleEventData } from '@paddle/paddle-js';
import { isProd } from 'lib/environment';
import { useEffect, useState } from 'react';

// Typescript trick to allow callers to use strings rather than import the enum
type PaddleEvents = `${CheckoutEventNames}`;

export const usePaddleCheckout = () => {
    const [paddle, setPaddle] = useState<Paddle>();
    const [handlers] = useState(new Map<PaddleEvents, (data: PaddleEventData) => void>());

    // Will download and initialize Paddle instance from CDN
    // This ensures compliance with any security updates
    useEffect(() => {
        initializePaddle({
            environment: isProd ? 'production' : 'sandbox',
            // these tokens are not sensitive, and are fine to be client facing
            token: isProd ? 'live_47799fab1cfae3d0e273ce6b654' : 'test_7232a9e2d3607a01af86f4356b9',
            checkout: {
                settings: {
                    allowLogout: false,
                    displayMode: 'overlay'
                }
            },
            eventCallback: (data) => {
                if (data.name) {
                    handlers.get(data.name)?.(data);
                }
            }
        }).then((instance: Paddle | undefined) => {
            if (instance) {
                setPaddle(instance);
            }
        });
    }, [handlers]);

    return {
        paddle,
        handlers
    };
};
