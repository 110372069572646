import { cn } from '@/lib/cn';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppContext } from 'contexts/AppContext';
import { useOrgTitle } from 'queries/hooks/useOrgTitle';
import { useNavigate } from 'react-router';

interface OverviewLinkProps {
    onClick: () => void;
}

export const OverviewLink: React.FC<OverviewLinkProps> = ({ onClick }) => {
    const navigate = useNavigate();

    const { currentWorkspaceID } = useAppContext();  
    const { data: orgTitle } = useOrgTitle();

    return (
        <button
            role='menuitem'
            title={orgTitle}
            className={cn(
                'flex items-center text-textSecondary my-xxxs border-b border-dividerPrimary hover:text-textPrimary cursor-pointer leading-input hover:bg-dividerTertiary focus:bg-dividerTertiary py-autocomplete focus:ring-0 focus:outline-none px-[13px]',
                currentWorkspaceID && 'font-semibold'
            )}
            onClick={() => {
                navigate('/status?skip-single-workspace-redirect=true');
                onClick();
            }}
        >
            <FontAwesomeIcon
                icon={faGlobe}
                className='ml-px text-lg shrink-0'
            />
            <span className='block truncate ml-[10px]'>{orgTitle}</span>
        </button>
    );
};