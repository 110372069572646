import Template from '@/components/Template';
import { Serialised } from '@squaredup/ids';
import LoadingSpinner from 'components/LoadingSpinner';
import { CloseHandler } from 'components/Modal';
import type { Config, ProjectedPlugin } from 'dynamo-wrapper';
import PluginConfigModal from 'pages/settings/plugins/PluginConfigModal';
import { getThrottledUpdateDataSourceObjectsCountFunc } from 'pages/settings/plugins/Plugins';
import { PluginMutationDetails } from 'pages/settings/plugins/components/useMutatePlugin';
import { InjectedWorkspaceProps, withWorkspaceID } from 'pages/workspace/WorkspaceRequired';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUnlinkedDataSources } from 'ui/editor/dataStream/TileEditor/steps/DataSourceModal/useUnlinkedDataSources';
import { useAgentGroups } from '../../components/hooks/useAgentGroups';
import Datasources from './Datasources';
import { useOAuthRedirectParam } from './components/useOAuthRedirectParam';
import { useQueryClient } from 'react-query';

function PluginsPage({ workspaceID }: InjectedWorkspaceProps) {
    const [selectedPlugin, setSelectedPlugin] = useState<Serialised<ProjectedPlugin> | undefined>();
    const [rehydrationConfig, setRehydrationConfig] = useState<Serialised<Config<object>> | undefined>();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { pluginInstanceBeingEdited, hasOAuthRehydration, clearSearch } = useOAuthRedirectParam();

    const { agentGroupOptions, isLoadingAgentGroups } = useAgentGroups();

    const { unlinkedDataSources, datasourceConfigs, isLoading } = useUnlinkedDataSources(workspaceID);

    useEffect(() => {
        if (hasOAuthRehydration) {
            setRehydrationConfig(pluginInstanceBeingEdited);
            setSelectedPlugin({
                id: (pluginInstanceBeingEdited?.plugin as any)?.pluginId,
                displayName: pluginInstanceBeingEdited?.displayName!
            } as any);
        }
    }, [hasOAuthRehydration, pluginInstanceBeingEdited]);

    const closeAndRedirect: CloseHandler = (reason, details) => {
        clearSearch();
        setSelectedPlugin(undefined);
        setRehydrationConfig(undefined);
        if (reason === 'submit') {
            const { newId } = details as PluginMutationDetails;
            getThrottledUpdateDataSourceObjectsCountFunc(newId)(queryClient, true);
            navigate(`/datasource/${newId}`);
        }
    };

    if (selectedPlugin && isLoadingAgentGroups) {
        return (
            <div className='pt-[20px]'>
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <Template title='Add a data source' sticky={true}>
            <div className=''>
                <Datasources
                    workspaceId={workspaceID}
                    onSelect={setSelectedPlugin}
                    existing={unlinkedDataSources}
                    empty={datasourceConfigs.length === 0 && !isLoading}
                />
            </div>

            {selectedPlugin && (
                <PluginConfigModal
                    selectedPlugin={selectedPlugin as any}
                    config={rehydrationConfig as any}
                    agentGroups={agentGroupOptions}
                    close={closeAndRedirect}
                    permissiveLinking={false}
                    workspaceToLinkTo={workspaceID}
                />
            )}
        </Template>
    );
}

export default withWorkspaceID(PluginsPage);
