import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { useMousePosition } from 'lib/useMousePosition';
import React, { RefObject, useLayoutEffect, useRef, useState } from 'react';
import TooltipMessage from './tooltip/TooltipMessage';

// Space between mouse and tooltip
const defaultSpacing = 20;

interface GraphTooltipProps {
    points: {
        label: string | number;
        subLabel?: string | number;
        value?: number | string;
        color: string;
        highlight?: boolean;
        composition?: {
            label: string;
            value?: string | number;
            color?: string;
        }[];
    }[];
    spacing?: number;
    graphRef: RefObject<HTMLElement>;
}

const GraphTooltip: React.FC<GraphTooltipProps> = ({ points, spacing = defaultSpacing, graphRef }) => {
    const messageRef = useRef<HTMLDivElement>(null);
    const [style, setStyle] = useState({ left: 0, top: 0 });
    const mousePosition = useMousePosition();
    const otherLimit = 15;
    const anyPointsHighlighted = points.some((p) => p.highlight);

    // We need to render this before first paint
    useLayoutEffect(() => {
        const { x: left, y: top } = mousePosition;

        // If a graph renders under a mouse without it moving, an event with 0,0 is triggered
        // We ignore this as there'll never be a real graph at 0,0
        if ((left === 0 && top === 0) || !messageRef.current) {
            return;
        }

        const { height: messageHeight, width: messageWidth } = messageRef.current.getBoundingClientRect();
        let newStyle = { left: left + spacing, top: top - messageHeight / 2 };
        if (graphRef.current != null) {
            const { width: graphWidth, left: graphLeft } = graphRef.current.getBoundingClientRect();
            if (left > graphLeft + graphWidth / 2) {
                newStyle = { left: left - spacing - messageWidth, top: top - messageHeight / 2 };
            }
        }
        setStyle(newStyle);

        //I need the position to rerender only when the cursor moves which is when the points parameter changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mousePosition]);

    const hasSublabel = points[0].subLabel !== undefined;

    const hideTooltip = style.left === 0 && style.top === 0;

    return (
        <div className='relative'>
            <TooltipMessage>
                <div
                    className={cn(
                        'absolute z-40 p-4 px-4 py-3 text-sm transition ease-out rounded bg-tooltipBackground text-primaryButtonText',
                        {
                            'opacity-0': hideTooltip,
                            'space-y-1': hasSublabel
                        }
                    )}
                    style={style}
                    ref={messageRef}
                    data-theme='dark'
                >
                    {hasSublabel && (
                        <>
                            <span className='font-bold text-textPrimary'>{points[0].subLabel}</span>{' '}
                        </>
                    )}
                    {points.map((point, index) => {
                        const isNotCurrentlyHighlight = !point.highlight && anyPointsHighlighted;

                        return (
                            <div key={index} className='flex flex-col m-0 align-top'>
                                <div className={cn('flex', { 'opacity-50': isNotCurrentlyHighlight })}>
                                    <span
                                        className='w-3 h-3 mt-1 mr-2 rounded-sm shrink-0'
                                        style={{ background: point.color }}
                                    />
                                    <div className='flex items-center'>
                                        <span
                                            className={cn('font-semibold text-textPrimary', {
                                                'font-normal': hasSublabel
                                            })}
                                        >
                                            {point.label}:
                                        </span>
                                        
                                        <span className={cn('ml-2 font-semibold text-textSecondary')}>
                                            <Text.H5>{point.value}</Text.H5>
                                        </span>
                                    </div>
                                </div>

                                {point.composition && (
                                    <ul className='self-start ml-8 list-none mt-0.5'>
                                        {point.composition.slice(0, otherLimit).map((element) => (
                                            <li className='flex'>
                                                {element.color && (
                                                    <div
                                                        style={{ backgroundColor: element.color }}
                                                        className='w-3 h-3 mt-1 mr-2 rounded-sm'
                                                    />
                                                )}
                                                <span className='flex-1 font-normal'>
                                                    {element.label}
                                                    {element.value != null && ':'}
                                                </span>
                                                {element.value != null && (
                                                    <span className='ml-2 font-bold text-textSecondary'>
                                                        {element.value}
                                                    </span>
                                                )}
                                            </li>
                                        ))}
                                        {point.composition.length > otherLimit && (
                                            <li>{point.composition.length - otherLimit - 1} more elements</li>
                                        )}
                                    </ul>
                                )}
                            </div>
                        );
                    })}
                </div>
            </TooltipMessage>
        </div>
    );
};

export default GraphTooltip;
