const stateColors = {
    error: 'var(--statusErrorPrimary)',
    warning: 'var(--statusWarningPrimary)',
    success: 'var(--statusHealthyPrimary)',
    unknown: 'var(--statusUnknownPrimary)',
    unmonitored: 'var(--statusUnmonitoredPrimary)'
};

export const getEdgeWithState = (
    edge: any, 
    nodeType: string, 
    hoveredUpStreamNodes?: string[] | undefined
) => {
    const { source, target } = edge;
    const edgeState = edge.data.state || 'unknown';
    const stateColor = stateColors[(edgeState || 'unknown') as keyof typeof stateColors];
    const isHoveredUpstreamNode = [source, target].every((adjacentNode) =>
        hoveredUpStreamNodes?.includes(adjacentNode)
    );

    return {
        style: {
            fill: 'transparent',
            stroke: stateColor,
            strokeWidth: 5,
            strokeDasharray: 5,
            opacity: nodeType === 'kpi' || !hoveredUpStreamNodes || isHoveredUpstreamNode ? 1 : 0.15,
            transition: 'opacity 0.3s ease-out'
        },
        isHovered: isHoveredUpstreamNode,
        labelStyle: { fill: 'var(--textSecondary)', fontSize: '1rem' },
        labelBgBorderRadius: 4,
        labelBgPadding: [10, 8] as [number, number],
        labelBgStyle: { fill: 'var(--componentBackgroundSecondary)' },
        zIndex: isHoveredUpstreamNode ? 2 : 0,
        ...edge,
        label: edge.label && isHoveredUpstreamNode ? edge.label : undefined
    };
};