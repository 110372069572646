import { Serialised } from '@squaredup/ids';
import { DashboardIdFolder } from '@squaredup/tenants';
import { UIConfig } from '@squaredup/utilities';
import type { Plugin, ProjectedPlugin } from 'dynamo-wrapper';
import API from './API';
import { handleError, handleResponse } from './util';

/*
    react-query key for plugin data
 */
export const PLUGINS = 'plugins';
export const PLUGIN_DETAIL = 'plugin-detail';

export const List = async (): Promise<Serialised<ProjectedPlugin[]>> => {
    return API.get('/plugins').then(handleResponse).catch(handleError);
};

export const GetLatestMajorVersions = async (): Promise<Serialised<ProjectedPlugin[]>> => {
    return API.get('/plugins/latest').then(handleResponse).catch(handleError);
};

export const Get = async (id: string): Promise<Serialised<Plugin>> => {
    return API.get(`/plugins/${id}`).then(handleResponse).catch(handleError);
};

export const GetConfig = async (id: string) => {
    return API.get<Serialised<UIConfig[]>>(`/plugins/${id}/ui`).then(handleResponse).catch(handleError);
};

export const TestConfig = async (config: any, plugin: object, configId?: string) => {
    return API.post('/plugins/test', { config, plugin, configId }).then(handleResponse).catch(handleError);
};

export const CreateDefaultDashboards = async (
    sourceId: string,
    pluginId: string,
    workspaceId: string,
    selectedSampleDashboards?: string[]
) => {
    return API.post<{ id: string; displayName?: string; rootFolder?: DashboardIdFolder }[]>('/plugins/dashboards', {
        sourceId,
        pluginId,
        workspaceId,
        selectedSampleDashboards
    })
        .then(handleResponse)
        .catch(handleError);
};

export const OAuth2Begin = async (pluginId: string, oAuth2Name: string, configValues: object, configId?: string) => {
    const windowLocation = {
        origin: window.location.origin,
        pathname: window.location.pathname,
        search: window.location.search
    };
    return API.post('/plugins/oauth2begin', { windowLocation, pluginId, oAuth2Name, configValues, configId })
        .then(handleResponse)
        .catch(handleError);
};

export const OAuth2CodeResponse = async (state: string, queryArgs: object) => {
    const windowLocationOrigin = window.location.origin;
    return API.post('/plugins/oauth2coderesponse', { windowLocationOrigin, state, queryArgs })
        .then(handleResponse)
        .catch(handleError);
};
