import { cn } from '@/lib/cn';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { useOnResize } from 'components/hooks/useOnResize';
import Tooltip from 'components/tooltip/Tooltip';
import useOverflowing from 'lib/useOverflowing';
import {
    cloneElement,
    forwardRef,
    useMemo,
    useRef,
    useState,
    type ComponentPropsWithoutRef,
    type ComponentRef,
    type ReactNode
} from 'react';

const pillVariants = cva('w-max whitespace-nowrap border py-px text-sm font-semibold', {
    variants: {
        variant: {
            default: 'border-transparent bg-tertiaryButton text-backgroundPrimary rounded-md',
            outline: 'text-textPrimary border-current font-normal rounded-md',
            rounded: 'border-transparent bg-tertiaryButton text-backgroundPrimary',
            tag: 'text-textPrimary border-cardTypeOutline font-normal rounded-md',
            type: 'text-textPrimary border-cardTypeOutline bg-cardTypeBackground font-semibold rounded-md'
        },
        size: {
            small: 'px-xxs',
            medium: 'px-xs'
        }
    },
    defaultVariants: {
        variant: 'default',
        size: 'medium'
    },
    compoundVariants: [
        {
            variant: 'rounded',
            size: 'small',
            className: 'rounded-[9px]'
        },
        {
            variant: 'rounded',
            size: 'medium',
            className: 'rounded-2xl'
        }
    ]
});

type PillStatusProps = { status: PillState } & Omit<PillProps, 'variant'>;

const PillStatus = ({ status, children, className, ...props }: PillStatusProps) => {
    return (
        <Pill className={cn(pillStatusColor[status], className)} {...props}>
            {children}
        </Pill>
    );
};

type PillStatusWithIconProps = PillStatusProps & { icon: ReactNode };

const PillStatusWithIcon = ({ icon, children, className, ...props }: PillStatusWithIconProps) => {
    return (
        <Pill.Status className={'flex items-center gap-xxs text-textPrimary'} {...props}>
            <Slot className='w-5 h-5 shrink-0'>{icon}</Slot>

            <span>{children}</span>
        </Pill.Status>
    );
};

type PillClosableProps = { children: ReactNode; closeHandler: () => void } & Omit<PillProps, 'variant'>;

const PillClosable = ({ children, closeHandler, className, ...props }: PillClosableProps) => {
    return (
        <Pill variant='outline' className={cn(className, 'flex items-center gap-xxs')} {...props}>
            <span>{children}</span>

            <button
                type='button'
                className='flex items-center justify-center'
                aria-label='close'
                onClick={closeHandler}
            >
                <FontAwesomeIcon icon={faClose} className='w-4 h-4' />
            </button>
        </Pill>
    );
};

type PillProps = ComponentPropsWithoutRef<'div'> & VariantProps<typeof pillVariants>;

export const Pill = Object.assign(
    forwardRef<HTMLDivElement, PillProps>(({ className, variant, ...props }, ref) => {
        return <div className={cn(pillVariants({ variant }), className)} {...props} ref={ref} />;
    }),
    {
        Status: PillStatus,
        StatusWithIcon: PillStatusWithIcon,
        Closable: PillClosable
    }
);

export type PillState = 'notRun' | 'running' | 'started' | 'succeeded' | 'waitingForData' | 'failed' | 'warnings';

const pillStatusColor: Record<PillState, string> = {
    notRun: 'text-statusUnknownPrimary border-statusUnknownPrimary bg-statusUnknownFaded',
    running: 'text-statusAction border-statusAction bg-statusActionFaded',
    started: 'text-statusAction border-statusAction bg-statusActionFaded',
    succeeded: 'text-statusHealthyPrimary border-statusHealthyPrimary bg-statusHealthyFaded',
    waitingForData: 'text-statusAction border-statusAction bg-statusActionFaded',
    failed: 'text-statusErrorPrimary border-statusErrorPrimary bg-statusErrorFaded',
    warnings: 'text-statusWarningPrimary border-statusWarningPrimary bg-statusWarningFaded'
};

export const pillStatusText: Record<PillState, string> = {
    notRun: 'Not Run',
    running: 'Running',
    started: 'Started',
    succeeded: 'Connected',
    waitingForData: 'Running',
    failed: 'Failed',
    warnings: 'Connected'
};

type PillsWrapperTruncateProps = {
    items: ReactNode[];
    wrapperClasses?: string;
    variant?: PillProps['variant'];
} & PillsWrapperProps;

const PillsWrapperTruncate = ({
    className,
    items,
    wrapperClasses,
    variant = 'outline',
    ...rest
}: PillsWrapperTruncateProps) => {
    const labelRef = useRef<ComponentRef<typeof Pill>>(null);
    const [widths, setWidths] = useState<number[] | undefined>(undefined);
    const [hiddenCount, setHiddenCount] = useState(0);
    const { ref: firstItemRef, isOverflowing: firstItemTruncated } = useOverflowing<any>();

    const newItems = useMemo(
        () => [
            ...items.slice(1),
            <Tooltip
                title={
                    <dl>
                        {items
                            .slice(items.length - hiddenCount)
                            .map((i: any) => i.props.children.trim())
                            .sort((a, b) => a.localeCompare(b))
                            .map((t) => (
                                <dd key={`tag-${t}`}>{t}</dd>
                            ))}
                    </dl>
                }
                key={items.length}
            >
                <Pill ref={labelRef} variant={variant} className='cursor-pointer' />
            </Tooltip>
        ],
        [hiddenCount, items, variant]
    );

    const { ref: pillsWrapperRef } = useOnResize<ComponentRef<typeof PillsWrapper>>({
        onResizeHandler: (pillsWrapper) => {
            const children = pillsWrapper.children;
            if (!widths) {
                // Get all pill widths on first render

                const childrenWithoutLast = Array.from(children).slice(0, children.length - 1);
                const elementsAreInDom = childrenWithoutLast.every((c) => Boolean(c.clientWidth));

                if (elementsAreInDom) {
                    setWidths([...childrenWithoutLast.map((c) => c.clientWidth + 2), 40]);
                }
            } else {
                let pillCount = 0;
                let currentPillWidthTotal = 0;

                // Add pill widths until total width is greater than wrapper width
                do {
                    currentPillWidthTotal += widths[pillCount] + 8;
                    pillCount++;
                } while (currentPillWidthTotal < pillsWrapper.offsetWidth);

                // Total width is 1 pill's worth too much, subtract the last pill width
                currentPillWidthTotal -= widths[pillCount - 1] + 8;
                pillCount--;

                let remaining = items.length - pillCount;

                if (remaining > 0) {
                    // Add estimate of +N pill width to total
                    currentPillWidthTotal += 33 + remaining.toString().length * 10;

                    // If it's too wide, remove the last pill
                    if (currentPillWidthTotal > pillsWrapper.offsetWidth) {
                        currentPillWidthTotal -= widths[pillCount - 1];
                        pillCount--;
                    }
                }

                pillCount = Math.max(pillCount, 0);

                // Show pills that fit
                for (let i = 0; i < pillCount; i++) {
                    children[i]?.classList.remove('hidden');
                }

                // Hide rest of pills
                for (let i = pillCount; i < children.length - 1; i++) {
                    children[i].classList.add('hidden');
                }

                const actualRemaining = children.length - 1 - pillCount;

                if (actualRemaining > 0) {
                    // Show +N pill
                    children[children.length - 1].classList.remove('hidden');
                    if (labelRef.current) {
                        labelRef.current.textContent = `+${actualRemaining}`;
                        setHiddenCount(actualRemaining);
                    }
                } else {
                    // Hide +N pill
                    children[children.length - 1].classList.add('hidden');
                }
            }
        }
    });

    let firstItem = <></>;

    if (items[0]) {
        firstItem = cloneElement(items[0] as any, { className: 'truncate', ref: firstItemRef });
    }

    return (
        <PillsWrapper className={cn('flex flex-nowrap w-full', className)} {...rest}>
            <Tooltip title={firstItem.props.children} className='flex truncate' disabled={!firstItemTruncated}>
                {firstItem}
            </Tooltip>
            <PillsWrapper ref={pillsWrapperRef} className={cn('flex-1 w-full', wrapperClasses)}>
                {newItems}
            </PillsWrapper>
        </PillsWrapper>
    );
};

type PillsWrapperProps = ComponentPropsWithoutRef<'div'>;

export const PillsWrapper = Object.assign(
    forwardRef<HTMLDivElement, PillsWrapperProps>(({ className, ...props }, ref) => {
        return <div ref={ref} className={cn('flex items-center flex-wrap gap-xxs', className)} {...props} />;
    }),
    {
        Truncate: PillsWrapperTruncate
    }
);
