import { cn } from '@/lib/cn';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasStringProperty } from '@squaredup/utilities';
import {
    ColumnDef,
    SortingState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table';
import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, useMemo, useState, type FC } from 'react';
import { ApplicationTableActionColumn, type ApplicationTableActionColumnProps } from './ApplicationTableActionColumn';
import { ApplicationTableGlobalFilter } from './ApplicationTableGlobalFilter';
import { Action, ApplicationTableConfig } from './types';

export interface ApplicationTableProps<T, U> extends Pick<ApplicationTableActionColumnProps<T>, 'hiddenActions'> {
    config: ApplicationTableConfig<T>;
    data: T[] | undefined;
    columns: ColumnDef<T, U>[];
    classNames?: {
        actionColumn: string;
        tableHeader?: string;
    };
    getRowId?: (row: T) => string;
    defaultColumn?: Partial<ColumnDef<T, unknown>>;
    /**
     * v1 (default, not recommended)
     *  - headers and rows are laid out using separate flex containers
     *  - minSize on individual columns is ignored
     *  - minSize is measured in pixels
     *  - minSize defaults to 50 pixels
     *  - size is measured in flex-grow units and is also used as flex-basis
     *  - actions column size is calculated based on the number of actions and the search bar width
     *
     * v2
     *  - uses native table layout
     *  - minSize on individual columns is respected
     *  - minSize is measured in rem
     *  - minSize defaults to 1 rem
     *  - size is measured in % of the table width
     *  - actions column is set to zero width
     *  - may not work properly with manual column resizing
     */
    layoutVersion?: 'v1' | 'v2';
}

/**
 * Returns the props required to render ControlledApplicationTable.
 * Use <ApplicationTable> if you don't need to interact with the table programmatically.
 */
export const useApplicationTable = <T, U>({
    config,
    data = [],
    columns,
    classNames,
    hiddenActions,
    getRowId,
    defaultColumn,
    layoutVersion = 'v1'
}: ApplicationTableProps<T, U>) => {
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [sorting, setSorting] = useState<SortingState>([]);

    const searchWidth = getSearchWidth(columns.at(-1)!);

    const memoizedData = useMemo(() => data, [data]);

    const memoizedColumns = useMemo(() => {
        const actions = config.actions
            ? config.actions.filter((a: Action) => memoizedData?.some((row) => (a.visible && a.visible(row)) ?? true))
            : [];

        return [
            ...columns,
            {
                id: 'actions',
                header: () => {
                    return !config.hideSearch ? (
                        <ApplicationTableGlobalFilter
                            globalFilter={globalFilter}
                            setGlobalFilter={config.searchFunction ?? setGlobalFilter}
                        />
                    ) : (
                        <></>
                    );
                },
                cell: ({ row }) => {
                    return (
                        <ApplicationTableActionColumn
                            className={classNames?.actionColumn}
                            actions={actions}
                            row={row}
                            hiddenActions={hiddenActions}
                        />
                    );
                },
                accessorKey: '',
                enableSorting: false,
                size: layoutVersion === 'v1' ? getActionsWidth(actions.length, searchWidth, config.hideSearch) : 0,
                minSize: 0
            }
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, config.actions]);

    const table = useReactTable({
        data: memoizedData,
        columns: memoizedColumns,
        defaultColumn: {
            minSize: layoutVersion === 'v1' ? 50 : 1,
            enableSorting: !config.disableSortBy,
            enableGlobalFilter: true,
            ...defaultColumn
        },
        state: {
            globalFilter: globalFilter,
            sorting: sorting
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getColumnCanGlobalFilter: () => true,
        getFilteredRowModel: getFilteredRowModel(),
        getRowCanExpand: config.getRowCanExpand,
        getExpandedRowModel: getExpandedRowModel(),
        getRowId
    });

    return { table, config, data, columns, classNames, layoutVersion };
};

type ApplicationTable = <T, U>(props: ApplicationTableProps<T, U>) => JSX.Element;

export type ApplicationTableType<T, U> = FC<ApplicationTableProps<T, U>>;

export const ApplicationTable =
    <T, U>(): FC<ApplicationTableProps<T, U>> =>
    (props) =>
        UncontrolledApplicationTable(props);

const UncontrolledApplicationTable: ApplicationTable = (props) => {
    const tableProps = useApplicationTable(props);
    return ControlledApplicationTable(tableProps);
};

/**
 * An application table that is controlled by the parent component.
 * Props can be obtained from the useApplicationTable hook.
 * Use <ApplicationTable> if you don't need to interact with the table programmatically,
 * e.g. expanding or collapsing rows without user interaction.
 */
export const ControlledApplicationTable = <T, U>({
    config,
    data = [],
    columns,
    classNames,
    table,
    layoutVersion
}: ReturnType<typeof useApplicationTable<T, U>>) => {
    const totalTableWidth = columns.reduce((p, c) => p + (c.size || 0), 0);

    return (
        <table
            className={cn('min-h-0 overflow-auto leading-normal table-scroll-overflow', {
                'inline-flex flex-col flex-1': layoutVersion === 'v1',
                // !important to override height:100% in table-scroll-overflow
                '!h-auto': layoutVersion === 'v2'
            })}
            onScroll={(e) => config.scrollFunction?.(e.target as HTMLDivElement)}
            data-testid={config.dataTestId ?? 'table'}
            style={{ tableLayout: 'auto', width: '100%' }}
            role='table'
        >
            {layoutVersion === 'v2' && (
                // Controls the width of the columns in layoutVersion v2
                <colgroup>
                    {table.getVisibleFlatColumns().map((c) => {
                        return (
                            <col
                                key={c.id}
                                style={{
                                    width: `${c.getSize()}%`,
                                    minWidth: `${c.columnDef.minSize ?? table._getDefaultColumnDef().minSize ?? 0}rem`
                                }}
                            />
                        );
                    })}
                </colgroup>
            )}

            <thead
                className={cn(
                    'z-40 rounded-t',
                    {
                        'sticky top-0 flex min-w-full shrink-0 border-x border-dividerTertiary': layoutVersion === 'v1',
                        'w-full': layoutVersion === 'v2'
                    },
                    classNames?.tableHeader
                )}
            >
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                        className={cn(
                            'relative items-center justify-between rounded-t border-y bg-tagBackground border-dividerTertiary',
                            { 'flex grow basis-auto shrink-0': layoutVersion === 'v1' }
                        )}
                        key={headerGroup.id}
                        role='row'
                    >
                        {headerGroup.headers.map((header, i) => (
                            <th
                                className={cn(
                                    'px-4 py-3 font-semibold text-left break-word group last:px-0 last:sticky last:right-0 last:py-0 last:align-middle last:pointer-events-none first:rounded-t last:rounded-t',
                                    { 'flex last:flex-grow shrink-0 basis-auto': layoutVersion === 'v1' },
                                    !header.column.getCanSort() && 'pointer-events-none',
                                    hasStringProperty(header.column.columnDef.meta, 'className')
                                        ? header.column.columnDef.meta.className
                                        : ''
                                )}
                                key={header.id}
                                style={
                                    layoutVersion === 'v1'
                                        ? {
                                              width: header.column.getSize(),
                                              minWidth: table._getDefaultColumnDef().minSize,
                                              flexGrow: header.column.getSize()
                                          }
                                        : {
                                              maxWidth:
                                                  header.column.columnDef.maxSize != null
                                                      ? `${header.column.columnDef.maxSize}rem`
                                                      : undefined
                                          }
                                }
                            >
                                <span
                                    className='relative w-full cursor-pointer group text-textPrimary'
                                    title={
                                        i !== headerGroup.headers.length - 1 &&
                                        header.column.getCanSort() &&
                                        !config.disableSortBy
                                            ? 'Sort by'
                                            : ''
                                    }
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(header.column.columnDef.header, header.getContext())}
                                    {header.column.getCanSort() && (
                                        <FontAwesomeIcon
                                            icon={
                                                (header.column.getIsSorted() as string) === 'desc'
                                                    ? faChevronDown
                                                    : faChevronUp
                                            }
                                            className={`ml-2 mt-1 absolute ${
                                                header.column.getIsSorted()
                                                    ? 'visible'
                                                    : 'invisible group-hover:visible text-textSecondary'
                                            }`}
                                        />
                                    )}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody
                className={cn('min-h-0 text-textTable', {
                    'min-w-full': layoutVersion === 'v1',
                    'w-full': layoutVersion === 'v2'
                })}
                style={{ minWidth: totalTableWidth }}
            >
                {table.getRowModel().rows.length > 0 ? (
                    table.getRowModel().rows.map((row) => {
                        return (
                            <Fragment key={row.id}>
                                <tr
                                    className={cn('border-b border-dividerTertiary hover:bg-tableHover group', {
                                        'cursor-pointer': row.getCanExpand(),
                                        'relative flex': layoutVersion === 'v1'
                                    })}
                                    data-testid='tableRow'
                                    role='row'
                                    onClick={row.getCanExpand() ? row.getToggleExpandedHandler() : undefined}
                                >
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td
                                                className={cn(
                                                    'px-4 py-3 break-words last:self-stretch last:px-0 last:sticky last:right-0 last:py-0 last:pointer-events-none',
                                                    {
                                                        'self-center last:flex-grow shrink-0 basis-auto':
                                                            layoutVersion === 'v1'
                                                    },
                                                    hasStringProperty(cell.column.columnDef.meta, 'className')
                                                        ? cell.column.columnDef.meta.className
                                                        : ''
                                                )}
                                                key={`${row.id}-${cell.id}`}
                                                style={
                                                    layoutVersion === 'v1'
                                                        ? {
                                                              width: cell.column.getSize(),
                                                              minWidth: table._getDefaultColumnDef().minSize,
                                                              flexGrow: cell.column.getSize()
                                                          }
                                                        : {
                                                              maxWidth:
                                                                  cell.column.columnDef.maxSize != null
                                                                      ? `${cell.column.columnDef.maxSize}rem`
                                                                      : undefined
                                                          }
                                                }
                                                role='cell'
                                            >
                                                {config.splitArrayCellsIntoRows && Array.isArray(cell.getValue())
                                                    ? (cell.getValue() as Array<T>).map((value, valueIndex) => {
                                                          return (
                                                              <div
                                                                  key={valueIndex}
                                                                  className={cn(
                                                                      // eslint-disable-next-line max-len
                                                                      valueIndex <
                                                                          (cell.getValue() as Array<T>).length - 1 &&
                                                                          'border-b border-dividerTertiary pb-2 mb-2'
                                                                  )}
                                                              >
                                                                  {typeof value === 'object'
                                                                      ? Object.entries(value as object).map(
                                                                            ([k, v], index) => {
                                                                                return (
                                                                                    <div key={index} className='flex'>
                                                                                        <em className='mr-1'>{k}: </em>{' '}
                                                                                        {v.toString()}
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )
                                                                      : value}
                                                              </div>
                                                          );
                                                      })
                                                    : flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {config.renderExpandedRow != null && row.getCanExpand() && (
                                    <tr className='border-b border-dividerTertiary' role='row'>
                                        <AnimatePresence>
                                            {row.getIsExpanded() && (
                                                <td colSpan={row.getVisibleCells().length}>
                                                    <motion.div
                                                        className='flex overflow-hidden grow'
                                                        role='cell'
                                                        key='tableRowExpansion'
                                                        initial={{ height: 0 }}
                                                        animate={{ height: 'fit-content' }}
                                                        exit={{ height: 0 }}
                                                        transition={{ duration: 0.3 }}
                                                    >
                                                        {config.renderExpandedRow({ row })}
                                                    </motion.div>
                                                </td>
                                            )}
                                        </AnimatePresence>
                                    </tr>
                                )}
                            </Fragment>
                        );
                    })
                ) : (
                    <div
                        className='relative px-4 py-3 text-center border-b text-textIncomplete border-dividerTertiary'
                        data-testid='tableRow'
                        style={{ minWidth: totalTableWidth }}
                    >
                        {data.length > 0
                            ? `${config.noSearchResultsMessage || 'No search results were found.'}`
                            : `${config.noDataMessage || 'No data to display.'}`}
                    </div>
                )}
            </tbody>
        </table>
    );
};

function getSearchWidth<T, U>(lastColumn: ColumnDef<T, U>) {
    const lastHeaderWidth = lastColumn.size || 0;
    const lastHeader = lastColumn.header;
    const lastHeaderWidthEstimate =
        typeof lastHeader === 'string'
            ? lastHeader?.length * 16 + (lastColumn.enableSorting ? 12 : 0) // Character width estimate + sort arrow width
            : 0;
    const lastHeaderBlankWidthEstimate = Math.max(lastHeaderWidth - lastHeaderWidthEstimate, 0); // Width estimate could be greater than the set width, so make sure we don't go negative
    const searchWidth = Math.max(240 - lastHeaderBlankWidthEstimate, 66); // Blank space could be bigger than search bar, so make sure we don't go negative
    return searchWidth;
}

function getActionsWidth(actionsLength: number, searchWidth: number, hideSearch: boolean | undefined) {
    return Math.max(
        actionsLength > 0
            ? 14 + 7 + 17.5 * actionsLength + 3.5 * (actionsLength - 1) + 14 // Total with padding, margins and button widths
            : 0,
        !hideSearch ? searchWidth : 0
    );
}
