import { faShare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModal } from 'components/Modal';
import { Presence } from 'components/Presence';
import Button from 'components/button/Button';
import { useAppContext } from 'contexts/AppContext';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { ShareDashboardModal } from './SharingExperience/ShareDashboardModal';

const ShareDashboard: React.FC<{
    disabled?: boolean;
    pageType: string;
    dashboardId?: string | null;
}> = ({ disabled = false }) => {
    const { currentWorkspaceID } = useAppContext();
    const { data: workspace } = useWorkspace(currentWorkspaceID);

    const shareModal = useModal();

    return (
        <>
            <Button
                onClick={shareModal.open}
                disabled={disabled || !workspace}
                className='flex items-center'
                variant='secondary'
                icon={<FontAwesomeIcon icon={faShare} />}
                aria-label='Share dashboard'
            >
                Share
            </Button>

            <Presence isPresent={shareModal.isOpen}>
                <ShareDashboardModal close={shareModal.close} />
            </Presence>
        </>
    );
};

export default ShareDashboard;
