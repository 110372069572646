import { useWorkspaceDetailedPermissions } from 'pages/settings/workspaces/useWorkspaceDetailedPermissions';
import { useWorkspaceLinks } from 'pages/settings/workspaces/useWorkspaceLinks';
import { useQuery } from 'react-query';
import { TENANT_DATA, Tenant } from 'services/TenantService';
import { GetUsers, TENANT_USERS_QUERY_KEY } from 'services/UserService';
import { Workspace } from 'services/WorkspaceService';
import { useWorkspaceACL } from './EditWorkspaceForm';
import { useWorkspaceACLUtils } from './WorkspaceModalAccessControlTab';

export const usePrefetchEditWorkspaceModalQueries = (props: { workspace?: Workspace }) => {
    const workspaceACLUtils = useWorkspaceACLUtils();
    useWorkspaceACL({ id: props.workspace?.id ?? '' });
    useWorkspaceACL({ id: props.workspace?.id ?? '', defaultACL: workspaceACLUtils.getDefaultACLClient() });
    useQuery(TENANT_USERS_QUERY_KEY, GetUsers);
    useQuery(TENANT_DATA, Tenant);
    useWorkspaceDetailedPermissions(props.workspace);
    useWorkspaceLinks(props.workspace);
};

export const usePrefetchCreateWorkspaceModalQueries = () => {
    useQuery(TENANT_USERS_QUERY_KEY, GetUsers);
    useQuery(TENANT_DATA, Tenant);
};
