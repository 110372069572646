import LoadingSpinner from 'components/LoadingSpinner';
import { upperFirst } from 'lodash';
import { useLayoutEffect, useRef } from 'react';
import { CollapsibleFilterPanel } from 'ui/editor/components/CollapsibleFilterPanel/CollapsibleFilterPanel';
import { FilterOption } from 'ui/editor/components/CollapsibleFilterPanel/FilterOption';
import { useTileEditorObjectsFilterContext } from 'ui/editor/dataStream/contexts/TileEditorObjectsFilterContext';
import { FilterType } from '../../hooks/useDataStreamObjectFilters';
import { getNewFilterValue } from '../../utilities/getNewFilterValue';

interface FilterPropertyProps {
    property: string;
    selectedValues: any[];
    options: any[];
}

export const PropertyFilter: React.FC<FilterPropertyProps> = ({ property, selectedValues, options }) => {
    const { filterProperties, isLoadingPropertyValues, filtersDisabled, setFilterProperties, handleSetFilterState } =
        useTileEditorObjectsFilterContext();

    const filterRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        if (filterRef?.current) {
            filterRef.current.scrollIntoView();
        }
    }, []);

    return (
        <CollapsibleFilterPanel
            title={upperFirst(property)}
            onClear={() => {
                const { [property]: _, ...newFilterProperties } = filterProperties;
                handleSetFilterState(FilterType.properties, newFilterProperties, setFilterProperties);
            }}
            disabled={filtersDisabled}
            ref={filterRef}
        >
            {isLoadingPropertyValues && options.length === 0 ? (
                <LoadingSpinner className='m-auto' />
            ) : (
                <>
                    {options.length > 0 ? (
                        options.map((option: string) => {
                            const optionLabel = String(option);

                            return (
                                <FilterOption
                                    key={optionLabel}
                                    title={optionLabel}
                                    isSelected={selectedValues ? selectedValues.includes(option) : undefined}
                                    onClick={() => {
                                        const newFilterProperties = {
                                            ...filterProperties,
                                            [property]: getNewFilterValue(filterProperties[property], option)
                                        };

                                        handleSetFilterState(
                                            FilterType.properties,
                                            newFilterProperties,
                                            setFilterProperties
                                        );
                                    }}
                                />
                            );
                        })
                    ) : (
                        <p className='opacity-50 pointer-events-none select-none'>No options available</p>
                    )}
                </>
            )}
        </CollapsibleFilterPanel>
    );
};
