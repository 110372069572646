import { Serialised } from '@squaredup/ids';
import Button from 'components/button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import type { ApiKey } from 'dynamo-wrapper';
import { useMutation, useQueryClient } from 'react-query';
import { APIKEY_DATA, Delete } from 'services/ApiKeyService';

interface ApiKeysDeleteModalProps {
    apiKey: Serialised<ApiKey>;
    close: () => void;
}

function ApiKeysDeleteModal({ apiKey, close }: ApiKeysDeleteModalProps) {
    const queryClient = useQueryClient();
    const { mutate: handleDelete, isLoading: isDeleting } = useMutation(Delete, {
        onSuccess() {
            queryClient.setQueryData([APIKEY_DATA], (apiKeys: Serialised<ApiKey>[] | undefined) => {
                if (apiKeys == null) {
                    return [];
                }

                return apiKeys.filter((k) => k.id !== apiKey.id);
            });
            close();
        }
    });

    const description = 'Are you sure you want to permanently delete this API key?';

    return (
        <Modal
            title={`Delete API key: ${apiKey.displayName}`}
            description={description}
            close={close}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <ModalButtons>
                <Button variant='tertiary' onClick={close}>
                    Cancel
                </Button>
                <Button
                    variant='destructive'
                    onClick={() => handleDelete(apiKey.id)}
                    disabled={isDeleting}
                    data-testid='deleteApiKeyConfirm'
                >
                    {isDeleting ? <LoadingSpinner size={18} /> : 'Delete'}
                </Button>
            </ModalButtons>
        </Modal>
    );
}

export default ApiKeysDeleteModal;
