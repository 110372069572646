import Text from '@/components/Text';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal, { ModalButtons } from 'components/Modal';
import NoDataPlaceholder from 'components/NoDataPlaceholder';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import { FormToggle } from 'components/forms/toggle/FormToggle';
import { useTileContext } from 'contexts/TileContext';
import { TileTypes } from 'dashboard-engine/constants';
import { IframeTileConfig } from 'dashboard-engine/tiles/iframe';
import { IframeConfig } from 'dashboard-engine/visualisations/IFrame/Config';
import IFrame from 'dashboard-engine/visualisations/IFrame/IFrame';
import { useTenant } from 'queries/hooks/useTenant';
import { FormProvider, useForm } from 'react-hook-form';
import { IsTenantAdmin } from 'services/AccessControlService';
import { validateUrlAgainstOrigins } from './validation';
import { TruncatedText } from 'components/TruncatedText';
import { useEffect } from 'react';
/**
 * Editor used when editing embed tiles
 */
export const IframeTileEditor: React.FC<{
    config: IframeTileConfig;
    onClose: () => void;
}> = ({ config, onClose }) => {
    const { onChange } = useTileContext();
    const { data: tenant } = useTenant();
    const isAdmin = IsTenantAdmin(tenant);

    const defaultValues: IframeConfig = {
        scale: config.visualisation?.config?.scale || 1,
        src: config.visualisation?.config?.src || '',
        openOnClick: config.visualisation?.config.openOnClick || false
    };

    const formProps = useForm({ mode: 'all', defaultValues, shouldUnregister: true });

    const {
        formState: { isValid, isSubmitting },
        watch,
        handleSubmit,
        trigger
    } = formProps;
    const fields = watch();

    const handleFormSubmission = (data: IframeConfig) => {
        onChange({
            ...config,
            _type: TileTypes.embed,
            visualisation: {
                ...config.visualisation,
                config: {
                    ...data
                }
            }
        });
        onClose();
    };

    const allowedDomains = tenant?.settings?.cspFrameSrcEntries;

    useEffect(() => {
        trigger(); // Re-trigger validation if the tenant config changes
    }, [tenant?.settings?.cspFrameSrcEntries, trigger]);

    return (
        <Modal title={null} close={onClose} fullWidth maxWidth='max-w-7xl'>
            <FormProvider {...formProps}>
                <div className='flex flex-row min-h-[600px]'>
                    <div className='flex-1 grow-[4] border-r border-solid border-dividerPrimary max-w-[50%]'>
                        <form
                            className='flex flex-col h-full'
                            onSubmit={handleSubmit((data: IframeConfig) => handleFormSubmission(data))}
                        >
                            <div className='flex flex-col flex-1 px-8 pt-8 overflow-hidden'>
                                <Text.H2 className='text-xl font-bold truncate !overflow-visible'>
                                    Edit embed tile
                                </Text.H2>

                                <Field.Input
                                    type='text'
                                    name='src'
                                    label='URL'
                                    placeholder='Enter a URL here'
                                    validateOnLoad={true}
                                    validation={{ 
                                        required: true,
                                        pattern: {
                                            value: /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/u,
                                            message: 'Please enter a valid URL, must start with http:// or https://.'
                                        },
                                        validate: (v: string) => {
                                            if (
                                                validateUrlAgainstOrigins(
                                                    v.toLowerCase(),
                                                    tenant?.settings?.cspFrameSrcEntries || []
                                                )
                                            ) {
                                                return true;
                                            } else {
                                                return 'The URL must match an allowed domain, see below.';
                                            }
                                        }
                                    }}
                                />

                                <div className='flex items-end pt-6 space-x-8'>
                                    <div className='flex-1'>
                                        <Field.Input
                                            type='slider'
                                            name='scale'
                                            label='Scale'
                                            validation={{ required: true }}
                                            min={0.05}
                                            step={0.05}
                                            max={2}
                                            markers={[
                                                { value: 0, label: '0%' },
                                                { value: 2, label: '200%' }
                                            ]}
                                        />
                                    </div>
                                    <span className='min-w-[8rem] text-center px-4 py-3 bg-componentBackgroundSecondary'>
                                        {Math.floor((fields?.scale === undefined ? 1 : fields.scale) * 100)}%
                                    </span>
                                </div>

                                <div className='pt-6'>
                                    <p className='font-medium text-textPrimary'>Options</p>
                                    <FormToggle
                                        wrapperClassName='!mt-2 rounded-md'
                                        name='openOnClick'
                                        label='Open URL when clicked (no interactivity)'
                                    />
                                </div>                                
                            </div>

                            <div className='px-8'>
                                <div className='w-full mb-4 px-sm py-xs bg-tagBackground rounded-input leading-input text-textPrimary'>
                                    <div className='mb-2'>
                                        <Text.H4>
                                            <FontAwesomeIcon icon={faLock} className='mr-1 text-textSecondary' /> Allowed domains
                                        </Text.H4>
                                        <div className='mt-1 space-y-1 max-h-[100px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                                            {allowedDomains?.map((d) => <pre>
                                                <TruncatedText title={d}>{d}</TruncatedText>
                                            </pre>)}
                                            {!allowedDomains?.length && <>There are no allowed domains configured.</>}
                                        </div>                                        
                                    </div>
                                    {isAdmin 
                                        ? <a href='/settings/organization' target='_blank' className='text-textLink'>Configure</a>
                                        : <>Contact your administrator for more information.</>
                                    }                                     
                                </div>
                            </div>

                            <ModalButtons hideTopMargin={true}>
                                <Button
                                    type='button'
                                    onClick={onClose}
                                    variant='tertiary'
                                    data-testid='cancelAddButton'
                                >
                                    Cancel
                                </Button>
                                <Button type='submit' data-testid='addButton' disabled={!isValid || isSubmitting}>
                                    Save
                                </Button>
                            </ModalButtons>
                        </form>
                    </div>
                    <div className='flex flex-col flex-1 grow-[5] min-h-0 p-4 items-center justify-center'>
                        <div className='w-full h-full'>
                            {(isValid && fields.src) && <IFrame config={fields} data={{}} />}
                            {(!isValid || !fields.src) && <NoDataPlaceholder message='You must enter a URL' />}
                        </div>
                    </div>
                </div>
            </FormProvider>
        </Modal>
    );
};
