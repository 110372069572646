import { useDashboardContext } from 'contexts/DashboardContext';
import { isOpenAccess } from 'lib/openAccessUtil';
import { useParams } from 'react-router';
import Auth from 'services/Auth';

export const useDashboardId = () => {
    const { id } = useParams();
    const { dashboard } = useDashboardContext();

    // This hook may be used on a page where the id param doesn't reflect the dashboard id,
    // so use the dashboard context if available
    const dashboardId = dashboard ? dashboard.id : id;
    return isOpenAccess() ? Auth.user?.openAccessTargetId : dashboardId;
};
