import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { v4 } from 'uuid';

export const generateDashboardForTile = (config: DataStreamBaseTileConfig) => {
    return {
        _type: 'layout/grid',
        contents: [
            generateTileForConfig(config)
        ],
        columns: 4
    };
};

export const generateTileForConfig = (config: DataStreamBaseTileConfig) => {
    return {
        x: 0,
        h: 2,
        i: v4(),
        y: 0,
        config: config,
        w: 4
    };
};