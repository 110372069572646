import Text from '@/components/Text';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SqUpPluginConfigId } from '@squaredup/ids';
import Button from 'components/button';
import Tooltip from 'components/tooltip/Tooltip';
import { useDashboardContext } from 'contexts/DashboardContext';
import trackEvent from 'lib/analytics';
import { getIconForType, getNameForType } from 'lib/types';
import { orderBy } from 'lodash';
import { CreateEditScope } from 'pages/scope/CreateEditScope';
import PluginIcon from 'pages/scope/PluginIcon';
import { useMemo, useRef, useState } from 'react';
import { CollapsibleFilterPanel } from 'ui/editor/components/CollapsibleFilterPanel/CollapsibleFilterPanel';
import { EditFilterOptionWrapper } from 'ui/editor/components/CollapsibleFilterPanel/EditFilterOptionWrapper';
import { FilterOption } from 'ui/editor/components/CollapsibleFilterPanel/FilterOption';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { useTileEditorObjectsFilterContext } from '../../contexts/TileEditorObjectsFilterContext';
import { FilterType } from '../hooks/useDataStreamObjectFilters';
import { getNewFilterValue } from '../utilities/getNewFilterValue';
import { AddPropertyFilter } from './filter/AddPropertyFilter';
import { PropertyFilters } from './filter/PropertyFilters';

interface ObjectFiltersProps {
    allowCreateScope?: boolean;
    allowEditScope?: boolean;
}

export const ObjectFilters: React.FC<ObjectFiltersProps> = ({ allowCreateScope, allowEditScope }) => {
    const {
        scopes,
        types,
        sources,
        pluginLookup,
        nonScopefiltersApplied,
        isFiltered,
        filtersDisabled,
        scopesDisabled,
        filterScope,
        filterSources,
        filterTypes,
        selectedObjects,
        filterQuery,
        filterProperties,
        setFilterScope,
        setFilterSources,
        setFilterTypes,
        resetFilters,
        handleSetFilterState
    } = useTileEditorObjectsFilterContext();

    const { config } = useDatasetContext();

    const { variables = [] } = useDashboardContext();

    const variablesScopeIds = variables.map((v) => v.scopeId);

    const [scopeModalOpen, setScopeModalOpen] = useState(false);
    const [editingScope, setEditingScope] = useState();

    const isSqUp = config?.dataStream?.pluginConfigId === SqUpPluginConfigId.value;

    const typesWithPreviousFilterTypes = useMemo(() => {
        if (!types) {
            return [];
        }

        const previousTypes = orderBy(
            filterTypes.filter((type) => !types.includes(type)),
            (type: string) => getNameForType(type, 'singular').toLowerCase()
        );

        const orderedTypes = orderBy([...previousTypes, ...types], (type: string) =>
            getNameForType(type, 'singular').toLowerCase()
        );

        return orderedTypes;
    }, [filterTypes, types]);

    const selectedFilterOptionRef = useRef<HTMLButtonElement>(null);

    if (selectedFilterOptionRef.current) {
        selectedFilterOptionRef.current.scrollIntoView();
    }

    let currentScope;
    if (!(typeof config.scope === 'string' || Array.isArray(config.scope)) && config.scope) {
        if ('query' in config.scope && config.scope.query) {
            currentScope = config.scope as {
                query: string;
                queryDetail: any;
                bindings: any;
            };
        }
    }

    return (
        <aside className='flex flex-col space-y-4 text-sm border-r w-[20%] min-w-56 text-textSecondary border-dividerPrimary'>
            <Text.H3 className='flex items-center flex-shrink-0 w-full text-textPrimary'>
                <span className='mr-4'>Filter</span>

                <Button
                    disabled={!isFiltered}
                    variant='tertiary'
                    className='ml-auto mr-4 text-sm font-normal'
                    onClick={resetFilters}
                >
                    Reset
                </Button>
            </Text.H3>
            {scopeModalOpen && (
                <CreateEditScope
                    scope={editingScope}
                    filters={
                        !editingScope
                            ? {
                                  selectedObjects,
                                  scope: filterScope,
                                  sources:
                                      filterSources.length > 0 || isSqUp
                                          ? filterSources
                                          : (sources || []).map(({ id }) => id),
                                  types: filterTypes.length > 0 || isSqUp ? filterTypes : typesWithPreviousFilterTypes,
                                  query: filterQuery,
                                  properties: filterProperties
                              }
                            : undefined
                    }
                    currentScope={currentScope}
                    onClose={(modifiedScope) => {
                        setScopeModalOpen(false);
                        setEditingScope(undefined);
                        if (modifiedScope) {
                            setFilterSources([]);
                            setFilterTypes([]);
                            handleSetFilterState(FilterType.scope, modifiedScope.id, setFilterScope, true);
                        }
                    }}
                />
            )}
            <div className='flex-1 min-h-0 pr-4 space-y-4 overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                {sources && sources.length > 0 && (
                    <Tooltip
                        title='Filtering by Data Source is not available when filtering by Collection with dynamic selection'
                        disabled={!filtersDisabled}
                    >
                        <CollapsibleFilterPanel
                            title='Data Source'
                            disabled={filtersDisabled}
                            {...(filterSources.length > 0 && {
                                onClear: () => handleSetFilterState(FilterType.sources, [], setFilterSources)
                            })}
                        >
                            {sources.map((source) => (
                                <FilterOption
                                    key={source.id}
                                    title={source.displayName!}
                                    icon={<PluginIcon pluginName={pluginLookup.get(source.id)?.pluginName} />}
                                    isSelected={
                                        filterSources.length > 0 ? filterSources.includes(source.id) : undefined
                                    }
                                    disabled={sources.length === 1}
                                    onClick={() => {
                                        trackEvent('Objects Data Source Filter Selected', { name: source.displayName });
                                        return handleSetFilterState(
                                            FilterType.sources,
                                            getNewFilterValue(filterSources, source.id),
                                            setFilterSources
                                        );
                                    }}
                                />
                            ))}
                        </CollapsibleFilterPanel>
                    </Tooltip>
                )}

                {typesWithPreviousFilterTypes && typesWithPreviousFilterTypes.length > 0 && (
                    <Tooltip
                        title='Filtering by Type is not available when filtering by Collection with dynamic selection'
                        disabled={!filtersDisabled}
                    >
                        <CollapsibleFilterPanel
                            title='Type'
                            disabled={filtersDisabled}
                            {...(filterTypes.length > 0 && {
                                onClear: () => handleSetFilterState(FilterType.types, [], setFilterTypes)
                            })}
                        >
                            {typesWithPreviousFilterTypes.map((type) => {
                                const name = getNameForType(type, 'singular');
                                return (
                                    <FilterOption
                                        key={type}
                                        title={name}
                                        icon={<FontAwesomeIcon icon={getIconForType(type)} fixedWidth={true} />}
                                        isSelected={filterTypes.length > 0 ? filterTypes.includes(type) : undefined}
                                        disabled={typesWithPreviousFilterTypes.length === 1}
                                        onClick={() => {
                                            trackEvent('Objects Type Filter Selected', { name });
                                            return handleSetFilterState(
                                                FilterType.types,
                                                getNewFilterValue(filterTypes, type),
                                                setFilterTypes
                                            );
                                        }}
                                    />
                                );
                            })}
                        </CollapsibleFilterPanel>
                    </Tooltip>
                )}

                {scopes && (
                    <Tooltip
                        title='Filtering by Collection is not available when using other filters with dynamic selection'
                        disabled={!scopesDisabled}
                    >
                        <CollapsibleFilterPanel
                            title='Collection'
                            disabled={scopesDisabled}
                            {...(allowCreateScope && {
                                onCreateTooltip: 'Add collection',
                                onCreate: () => setScopeModalOpen(true)
                            })}
                            {...(filterScope && {
                                onClear: () => handleSetFilterState(FilterType.scope, '', setFilterScope)
                            })}
                        >
                            {scopes.length > 0 ? (
                                scopes.map((scope) => {
                                    const isSelected = filterScope === scope.id;

                                    return (
                                        <EditFilterOptionWrapper
                                            key={scope.id}
                                            tooltipTitle='Edit collection'
                                            disabled={!allowEditScope}
                                            onClick={() => {
                                                setEditingScope(scope);
                                                setScopeModalOpen(true);
                                            }}
                                        >
                                            <FilterOption
                                                ref={isSelected ? selectedFilterOptionRef : undefined}
                                                title={scope.displayName!}
                                                isSelected={filterScope ? isSelected : undefined}
                                                onClick={() =>
                                                    handleSetFilterState(
                                                        FilterType.scope,
                                                        isSelected ? undefined : scope.id,
                                                        setFilterScope,
                                                        !isSelected &&
                                                            !selectedObjects?.length &&
                                                            !nonScopefiltersApplied
                                                    )
                                                }
                                            >
                                                {variablesScopeIds.includes(scope.id) && (
                                                    <Tooltip
                                                        title={'This collection is being used as a dashboard variable'}
                                                        placement='bottom-start'
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faBarsFilter}
                                                            fixedWidth={true}
                                                            className='w-3 h-3 ml-2 text-textPrimary'
                                                        />
                                                    </Tooltip>
                                                )}
                                            </FilterOption>
                                        </EditFilterOptionWrapper>
                                    );
                                })
                            ) : (
                                <Text.SmallBody className='text-textDisabled'>No collections available.</Text.SmallBody>
                            )}
                        </CollapsibleFilterPanel>
                    </Tooltip>
                )}

                <PropertyFilters />
            </div>

            <div className='flex-shrink-0 pr-4'>
                <div className='pt-4 border-t border-dividerPrimary'>
                    <AddPropertyFilter />
                </div>
            </div>
        </aside>
    );
};
