import { stateStrings } from '@squaredup/monitoring';
import { useAppContext } from 'contexts/AppContext';
import pluralize from 'pluralize';
import { useDashboardHealthStates } from 'queries/hooks/useDashboardHealthStates';
import { FC, SVGProps } from 'react';
import { StateIndicator } from './StateIndicator';

interface DashboardStateIndicatorProps extends SVGProps<SVGSVGElement> {
    dashboardId: string;
    workspaceId?: string;
    className?: string;
    hideTooltip?: boolean;
    showUnmonitored?: boolean;
}

export const DashboardStateIndicator: FC<DashboardStateIndicatorProps> = ({ 
    dashboardId, 
    workspaceId, 
    className,
    hideTooltip,
    showUnmonitored
}) => {
    const { currentWorkspaceID } = useAppContext();
    const { data: dashboardStates } = useDashboardHealthStates(workspaceId ?? currentWorkspaceID);
    
    const { state, unhealthyTileStates } = dashboardStates?.find(
        ({ id }) => dashboardId === id
    ) ?? {};
    
    const stateTooltip = () => {
        if (state === stateStrings.unknown) {
            return 'Unknown state';
        }

        if (state === stateStrings.success) {
            return 'Success: All monitors healthy';
        }

        const dashboardMonitorCounts = unhealthyTileStates?.reduce((tileStates, { state: tileState }) => {
            if (tileState === stateStrings.error || tileState === stateStrings.warning) {
                tileStates[tileState] += 1;
            }

            return tileStates;
        }, { error: 0, warning: 0 });

        const { error: errorCount, warning: warningCount } = dashboardMonitorCounts ?? {};

        if (errorCount && errorCount > 0) {
            return `Error: ${errorCount} ${pluralize('monitor', errorCount)} in an error state`;
        } 
        
        if (warningCount && warningCount > 0) {
            return `Warning: ${warningCount} ${pluralize('monitor', warningCount)} in a warning state`;
        }
        
        return `${state} state`;
    };

    if (!state && !showUnmonitored) {
        return null;
    }

    return (
        <StateIndicator
            state={state ?? stateStrings.unmonitored}
            className={className}
            hideTooltip={hideTooltip}
            titleOverride={stateTooltip}
        />
    );
};