import { faArrowUpArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownMenu from 'components/dropdownMenu';
import { SortValue } from './StatusOverview';
import { useState } from 'react';

const options: {
    label: string;
    direction: 'asc' | 'desc';
    value: SortValue;
}[] = [
    {
        label: 'Error-Success',
        direction: 'desc',
        value: 'state'
    },
    {
        label: 'Success-Error',
        direction: 'asc',
        value: 'state'
    },
    {
        label: 'A-Z',
        direction: 'asc',
        value: 'name'
    },
    {
        label: 'Z-A',
        direction: 'desc',
        value: 'name'
    }
];

export interface WorkspaceSort {
    value: SortValue;
    direction: 'asc' | 'desc';
}

export interface SortMenuProps {
    sort: WorkspaceSort;
    setSort: (sort: WorkspaceSort) => void;
}

export default function SortMenu({ sort, setSort }: SortMenuProps) {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    return (
        <DropdownMenu onOpenChange={setIsDropdownOpened}>
            <DropdownMenu.Button className='whitespace-nowrap' dropdownOpen={isDropdownOpened} showChevron useFilterStyle icon={<FontAwesomeIcon icon={faArrowUpArrowDown} />}>
                Sort
            </DropdownMenu.Button>
            <DropdownMenu.Menu align='start'>
                {options.map(({ label, direction, value }) => (
                    <DropdownMenu.CheckItem
                        key={label}
                        checked={sort.value === value && sort.direction === direction}
                        onSelect={() => {
                            setSort({ value, direction });
                        }}
                    >
                        {label}
                    </DropdownMenu.CheckItem>
                ))}
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
}
