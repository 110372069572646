import { Serialised } from '@squaredup/ids';
import type { ApiKeyId, ProjectedAgent } from 'dynamo-wrapper';
import { UseQueryOptions, useQuery } from 'react-query';
import { AGENT_GROUPS, List as ListAgentGroups } from 'services/AgentGroupService';
import { AGENTS, List as ListAgents } from 'services/AgentService';
import { APIKEY_DATA, List as ListApiKeys } from 'services/ApiKeyService';

type options = Omit<
    UseQueryOptions<Serialised<ProjectedAgent>[], unknown, Serialised<ProjectedAgent>[], string[]>,
    'queryFn' | 'queryKey'
>;

export const useAgents = (options?: options) => {
    const { isLoading: isLoadingAgents, data: agents } = useQuery([AGENTS], ListAgents, options);

    return {
        isLoadingAgents,
        agents
    };
};

export type AgentWithRelatedObjects = Serialised<ProjectedAgent> & {
    apiKey: {
        id: Serialised<ApiKeyId | undefined>,
        lastFour: string | undefined,
        display: string
    },
    formattedAgentGroups: { label: string, value: string}[]
};

export const useAgentsWithRelatedObjects = (options?: { refetchInterval: number }) => {
    const { isLoading: isLoadingRawAgents, data: rawAgents } = useQuery([AGENTS], ListAgents, options);
    const { isLoading: isLoadingAgentGroups, data: agentGroupMap } = useQuery([AGENT_GROUPS], ListAgentGroups, {
        ...options,
        select: (agentGroups) => new Map(agentGroups.map((group) => [group.id, group]))
    });

    const { isLoading: isLoadingApiKeys, data: apiKeyMap } = useQuery([APIKEY_DATA], ListApiKeys, {
        ...options,
        select: (apiKeys) => new Map<string, string>(apiKeys.map((key) => [key.id, key.last4Chars]))
    });

    const isLoadingAgents = isLoadingRawAgents || isLoadingAgentGroups || isLoadingApiKeys;
    const agents = isLoadingAgents
        ? undefined
        : rawAgents
              ?.sort((a, b) => (a.displayName ?? a.name).localeCompare(b.displayName ?? b.name))
              .map<AgentWithRelatedObjects>((agent) => {
                  const groups = agent.agentGroups.map((id) => ({
                      label: agentGroupMap?.get(id)?.displayName ?? id,
                      value: id
                  }));
                  const lastFour = agent.apiKeyId && apiKeyMap?.get(`${agent.apiKeyId}`);

                  return {
                      ...agent,
                      apiKey: {
                          id: agent.apiKeyId,
                          lastFour: lastFour,
                          display: agent.apiKeyId && lastFour ? Array(17).join('*') + lastFour : ''
                      },
                      formattedAgentGroups: groups
                  };
              });

    return {
        isLoadingAgents,
        agents
    };
};
