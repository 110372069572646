import { WorkspaceIdRegex } from '@squaredup/ids';
import type { AlertingRules } from '@squaredup/monitoring';
import { WorkspaceLinks, WorkspaceProperties } from '@squaredup/tenants';
import type { AccessControlEntryModel, ScopeContent } from 'dynamo-wrapper';
import API from './API';
import { getCurrentWorkspaceId } from './WorkspaceUtil';
import { handleError, handleResponse } from './util';

export const CURRENT_WORKSPACE = 'CURRENT_WORKSPACE';
export const WORKSPACES = 'WORKSPACES';
export const WORKSPACE = 'WORKSPACE';
export const SCOPES = 'SCOPES';
export const SCOPE = 'SCOPE';

/**
 * List all workspaces we have access to for normal usage (dashboards etc)
 * @deprecated use the hook useWorkspaces to get this data
 */
export const List = async (): Promise<Workspace[]> => {
    return API.get('/workspaces').then(handleResponse).catch(handleError);
};

/**
 * List workspaces that we are allowed to administer. (If we're a tenant admin,
 * this may be a superset of the workspaces we normally have access to)
 *
 * Note that this may include workspaces we don't have permission to see
 * dashboards/scopes etc. So only use this for administration of workspaces, not
 * normal workspace/dashboard/scope usage.
 * @deprecated use the hook useWorkspacesForAdministration to get this data
 */
export const ListForAdministration = async (): Promise<Workspace[]> => {
    return API.get('/workspaces?forAdministration=true').then(handleResponse).catch(handleError);
};

/**
 * List workspaces that can be used for linking to the specified workspace, or linked to the active workspace
 * if none is specified.
 *
 * These includes workspaces that the user has direct permissions on, plus any workspaces already
 * linked to this workspace.
 */
export const ListForLinking = async (linkToWorkspaceId?: string | null) => {
    const workspaceId = linkToWorkspaceId ?? getCurrentWorkspaceId();
    const workspaces = await API.get<{ displayName?: string }[]>('/workspaces', {
        params: {
            forLinking: true,
            workspaceId
        }
    })
        .then(handleResponse)
        .catch(handleError);
    return workspaces.sort((a, b) => a.displayName?.localeCompare(b.displayName ?? '') ?? -1);
};

export interface Workspace {
    configId: string;
    id: string;
    type: string;
    displayName: string;
    tenant: string;
    data: {
        alertingRules?: AlertingRules;
        properties?: WorkspaceProperties;
        links: {
            plugins: string[];
            workspaces: string[];
        };
        [key: string]: any;
    };
}

/**
 * Get the given workspace
 * @deprecated use the hook useWorkspace to get this data
 * @param id Workspace ID
 */

export const Get = async (id: string): Promise<Workspace> => {
    return API.get(`/workspaces/${id}`).then(handleResponse).catch(handleError);
};

/**
 * Get the given workspace avatar
 * @deprecated use the hook useWorkspaceAvatar to get this data
 * @param id Workspace ID
 * @param uploadedAt timestamp of the uploaded at avatar
 */
export const GetAvatar = async (id: string, uploadedAt?: number) => {
    // Include the uploaded timestamp as a query string param (unused) just so we can include it
    // in the CloudFront cache key and avoid hitting the origin unless a new image is uploaded.
    return API.get<string | undefined>(`/workspaces/${id}/images/avatars?uploaded=${uploadedAt || Date.now()}`)
        .then(handleResponse)
        .catch(handleError);
};

/**
 * Create a new workspace
 * @param displayName Name of the workspace
 * @param links Plugins and workspaces that should be linked to this workspace (so they can be used within the
 * workspace). If undefined, the server will populate with reasonable defaults.
 * @param linkToWorkspaces If true, add a link to existing workspaces for the new workspace, meaning the new
 * workspace can be used within the other workspaces without further configuration.
 * @param acl Access control list for the workspace (i.e. who has access to it)
 */
export const Create = async (
    displayName: string,
    links?: WorkspaceLinks,
    linkToWorkspaces?: boolean,
    acl?: AccessControlEntryModel[],
    properties?: WorkspaceProperties,
    avatar?: string
) => {
    return API.post<string>('/workspaces', { displayName, links, linkToWorkspaces, acl, properties, avatar })
        .then(handleResponse)
        .catch(handleError);
};

/**
 * Update the given workspace's dashboard order
 * @param workspace The workspace in question
 * @param order The new dashboard and folder order
 */
export const UpdateWorkspaceOrder = async (workspace: Workspace, order: WorkspaceProperties['dashboardIdOrder']) => {
    return Update(workspace.id, undefined, undefined, undefined, undefined, {
        ...workspace.data.properties,
        dashboardIdOrder: order
    });
};

/**
 * Update the given workspace
 * @param id Workspace ID
 * @param displayName New name of the workspace
 * @param links Plugins and workspaces that should be linked to this workspace (so they can be used within the
 * workspace).
 * @param acl Access control list for the workspace (i.e. who has access to it)
 */
export const Update = async (
    id: string,
    displayName?: string,
    alertingRules?: AlertingRules,
    links?: WorkspaceLinks,
    acl?: AccessControlEntryModel[],
    properties?: WorkspaceProperties,
    avatar?: string
) => {
    await API.put(`/workspaces/${id}`, { displayName, alertingRules, links, acl, properties, avatar })
        .then(handleResponse)
        .catch(handleError);
    return true;
};

/**
 * Link plugins to a workspace.  This allows the plugin to be used within the workspace, to populate tiles etc.
 * Normally this is done explicitly by a user, but sometimes we do it as a side-effect of another action.
 * Note that this function ONLY ADDS LINKS, never removes any existing links.
 */
export const AddPluginLinksToWorkspace = async (workspaceId: string, pluginConfigIds: string[]) => {
    const workspace = await Get(workspaceId);
    const links = workspace.data.links ?? {};
    links.plugins = links.plugins ?? [];
    pluginConfigIds.forEach((pluginConfigId) => {
        if (pluginConfigId && !links.plugins.includes(pluginConfigId)) {
            links.plugins.push(pluginConfigId);
        }
    });
    return Update(workspaceId, undefined, undefined, links);
};

/**
 * Link a workspace to other workspaces, to allow the linked workspace to have its health monitored etc.
 * Normally this is done explicitly by a user, but sometimes we do it as a side-effect of another action.
 * Note that this function ONLY ADDS LINKS, never removes any existing links.
 */
export const AddWorkspaceLinksToWorkspace = async (
    workspaceId: string,
    linkedWorkspaceIds: string[]
): Promise<boolean> => {
    if (linkedWorkspaceIds?.length) {
        const workspace = await Get(workspaceId);
        const links = workspace.data.links ?? {};
        links.workspaces = links.workspaces ?? [];
        let linksChanged = false;
        linkedWorkspaceIds.forEach((linkedWorkspaceId) => {
            if (linkedWorkspaceId && !links.workspaces.includes(linkedWorkspaceId)) {
                links.workspaces.push(linkedWorkspaceId);
                linksChanged = true;
            }
        });
        if (linksChanged) {
            await Update(workspaceId, undefined, undefined, links);
            return true;
        }
    }
    return false;
};

/**
 * Delete the given workspace
 * @param id Workspace ID
 */
export const Delete = async (id: string) => {
    await API.delete(`/workspaces/${id}`).then(handleResponse).catch(handleError);
    return true;
};

/**
 * List dashboards within a workspace
 * @deprecated use the hook useDashboardsForWorkspace to get this data
 * @param id Workspace ID
 */
export const ListDashboards = async (id: string) => {
    return API.get(`/workspaces/${id}/dashboards`).then(handleResponse).catch(handleError);
};

/**
 * List scopes within a workspace
 * @deprecated use the hook useScopes to get this data
 * @param workspaceId Workspace ID
 */
export const ListScopes = async (workspaceId: string) => {
    return API.get(`/workspaces/${workspaceId}/scopes`).then(handleResponse).catch(handleError);
};

/**
 * Get a scope
 * @param workspaceId Workspace ID
 * @param scopeId Scope ID
 */
export const GetScope = async (
    workspaceId: string | null | undefined,
    scopeId: string
): Promise<{
    id: string;
    workspaceId: string;
    data: {
        queryDetail?: string;
    };
}> => {
    // No point attempting to fetch the scope if we have an invalid workspaceId
    if (!workspaceId || !workspaceId.match(WorkspaceIdRegex)) {
        workspaceId = (await GetScopesWorkspace(scopeId)).id;
    }

    return API.get(`/workspaces/${workspaceId}/scopes/${scopeId}`)
        .then(handleResponse)
        .catch(async (e) => {
            // If we don't have a workspaceId we'll request /workspaces/undefined/scopes/${scopeId}
            // which causes the accessControlMiddleware function to return a 403
            // We will also get a 403 if the scope is in a different workspace
            if (e.response?.status === 403) {
                const scopeWorkspaceId = (await GetScopesWorkspace(scopeId)).id;

                if (scopeWorkspaceId && scopeWorkspaceId !== workspaceId) {
                    return GetScope(scopeWorkspaceId, scopeId); // We try one more time with the correct workspaceId
                }
            }
            return handleError(e);
        });
};

/**
 * Get a scopes workspace
 * @param scopeId Scope ID
 */
export const GetScopesWorkspace = async (scopeId: string) => {
    return API.get<any>(`/workspaces?scopeId=${scopeId}`)
        .then(handleResponse)
        .then((data) => data[0])
        .catch(handleError);
};

/**
 * Get the dashboards referencing a scope
 * @param workspaceId Workspace ID
 * @param scopeId Scope ID
 */
export const GetScopeDashboards = async (workspaceId: string, scopeId: string) => {
    return API.get<{ id: string }>(`/workspaces/${workspaceId}/scopes/${scopeId}/dashboards`)
        .then(handleResponse)
        .catch(handleError);
};

export type Scope = {
    displayName: string;
    query: string;
    version?: number;
    queryDetail: Record<string, unknown>;
    data?: {
        isDependency: boolean;
    };
    name: string;
};

/**
 * Create a new scope
 * @param workspaceId Workspace ID
 * @param scope Scope object
 */
export const CreateScope = async (workspaceId: string, scope: ScopeContent) => {
    return API.post<string>(`/workspaces/${workspaceId}/scopes`, { scope }).then(handleResponse).catch(handleError);
};

/**
 * Update the given scope
 * @param workspaceId Workspace ID
 * @param scopeId Scope ID
 * @param displayName New name of the workspace
 */
export const UpdateScope = async (workspaceId: string, scopeId: string, scope: Scope) => {
    await API.put(`/workspaces/${workspaceId}/scopes/${scopeId}`, { scope }).then(handleResponse).catch(handleError);
    return true;
};

/**
 * Delete the given scope
 * @param workspaceId Workspace ID
 * @param scopeId Scope ID
 */
export const DeleteScope = async (workspaceId: string, scopeId: string) => {
    await API.delete(`/workspaces/${workspaceId}/scopes/${scopeId}`).then(handleResponse).catch(handleError);
    return true;
};
