import { Avatar, InitialAvatar } from '@/components/InitialAvatar';
import Text from '@/components/Text';
import { faCircle, faTrash, faUserGroup } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFeatureLimit, listTierTemplates } from '@squaredup/tenants';
import { AxiosError } from 'axios';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import { useShowUpgradeModal } from 'components/plans/common';
import Tooltip from 'components/tooltip/Tooltip';
import Table from 'dashboard-engine/visualisations/Table/Table';
import type { AccessControlEntryModel, AcePermission } from 'dynamo-wrapper';
import { emailRegex } from 'lib/validation';
import { useContactSalesModal } from 'pages/usage/ContactSalesModal';
import { useGroupsForACLEditor } from 'queries/hooks/useGroupsForACLEditor';
import { useTenant } from 'queries/hooks/useTenant';
import { useTier } from 'queries/hooks/useTier';
import { useUsersForACLEditor } from 'queries/hooks/useUsersForACLEditor';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { StylesConfig, components, type CSSObjectWithLabel } from 'react-select';
import { AccessControlQueryKeys, IsTenantAdmin } from 'services/AccessControlService';
import { AddUser, TENANT_USERS_QUERY_KEY } from 'services/UserService';
import { useDashboardId } from 'ui/hooks/useDashboardId';

type CustomACLEditorArgs = {
    acl: AccessControlEntryModel[];
    entityId?: string;
    permissionOptions: PermissionOption[];
    defaultPermissionsForNewACE: AcePermission[];
    onChange?: (acl: AccessControlEntryModel[]) => void;
};

const userLimitError = 'User limit reached';

const tiertemplates = listTierTemplates();

const UserLimitReachedText = () => {
    const { data: tenant } = useTenant();
    const { data: tier } = useTier();
    const { openUpgradeModal, upgradeModal } = useShowUpgradeModal(tenant, 'users');
    const { contactSalesModal, openContactSalesModal } = useContactSalesModal(
        'users',
        tenant?.id,
        tenant?.displayName ?? ''
    );
    const userLimit = tier ? getFeatureLimit(tier, 'users') : { value: 0 };
    // Ignoring isUnlimited as this will never be hit in that scenario
    const maxNumberOfUsers = 'value' in userLimit ? userLimit.value : 0;
    const tierCanBeUpgraded =
        (tiertemplates.find((t) => t.id === tenant?.tier?.tierTemplate)?.sortRank ?? 0) < tiertemplates.length - 1;
    return (
        <div className='flex items-center gap-2 mt-2 ml-4 animate-in fade-in'>
            <span className={'text-upgrade'}>
                <FontAwesomeIcon icon={faCircle} />
            </span>
            <Text.Body>
                User limit reached ({maxNumberOfUsers}).{' '}
                {tierCanBeUpgraded ? (
                    <>
                        Upgrade to increase your limit.{' '}
                        <Button
                            type='button'
                            variant='link'
                            onClick={openUpgradeModal}
                            data-testid='acl-editor-compare-plans'
                        >
                            Compare plans.
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            type='button'
                            variant='link'
                            onClick={openContactSalesModal}
                            data-testid='acl-editor-contact-sales'
                        >
                            Contact sales
                        </Button>{' '}
                        to increase your limit.
                    </>
                )}
            </Text.Body>
            {upgradeModal}
            {contactSalesModal}
        </div>
    );
};

/**
 * Editor for a custom access control list, where the user specifies the subjects (users or groups)
 * who have access to an object, and what permissions the subject has (viewer, editor etc)
 */
export const CustomACLEditor: React.FC<CustomACLEditorArgs> = ({
    acl,
    entityId,
    permissionOptions,
    defaultPermissionsForNewACE,
    onChange
}) => {
    const queryClient = useQueryClient();
    const dashboardId = useDashboardId();
    const [selectedSubject, setSelectedSubject] = useState<any>();
    const { setValue: setFormValue } = useFormContext();
    const { data: users } = useUsersForACLEditor();
    const { data: groups } = useGroupsForACLEditor();
    const { data: tenant, isLoading: isTenantLoading } = useTenant();
    const [subjectErrorMessage, setSubjectErrorMessage] = useState<string>('');

    const handleACLUpdate = (aclRows: AccessControlEntryRow[]) => {
        onChange?.(
            aclRows.map((aclRow) => ({
                subjectId: aclRow.subjectId,
                permissions: aclRow.permissions
            }))
        );
    };

    const aclRows: AccessControlEntryRow[] | null = useMemo(() => {
        if (users && groups) {
            return acl
                .map((ace) => ({
                    ...ace,
                    subjectDisplayName: getSubjectDisplayName(ace.subjectId),
                    permissionsDisplayName: getPermissionsDisplayName(permissionOptions, ace.permissions),
                    isGroup: isGroupId(ace.subjectId)
                }))
                .sort((ace1, ace2) =>
                    (ace1.isGroup + ace1.subjectDisplayName)
                        .toLowerCase()
                        .localeCompare((ace2.isGroup + ace2.subjectDisplayName).toLowerCase())
                );
        }
        return null;
    }, [acl, users, groups]);

    const subjectOptions = useMemo(() => {
        if (aclRows && users && groups) {
            return [
                {
                    label: 'Groups',
                    options: groups
                        .filter((group) => !aclRows.find((row) => row.subjectId === group.id))
                        .sort((group1, group2) =>
                            group1.displayName!.toLowerCase().localeCompare(group2.displayName!.toLowerCase())
                        )
                        .map((group) => ({ label: group.displayName, value: group.id, type: 'group' }))
                },
                {
                    label: 'Users',
                    options: users
                        .filter((user) => !aclRows.find((row) => row.subjectId === user.id))
                        .sort((user1, user2) => user1.name.toLowerCase().localeCompare(user2.name.toLowerCase()))
                        .map((user) => ({ label: user.name, value: user.id, type: 'user' }))
                }
            ];
        }
        return [];
    }, [acl, aclRows, users, groups]);

    const updateACE = (aceToUpdate: AccessControlEntryModel) => {
        if (aceToUpdate && aclRows) {
            const aclRowToUpdate = aclRows.find((ace) => ace.subjectId === aceToUpdate.subjectId);
            if (aclRowToUpdate) {
                aclRowToUpdate.permissions = aceToUpdate.permissions;
                aclRowToUpdate.permissionsDisplayName = getPermissionsDisplayName(
                    permissionOptions,
                    aceToUpdate.permissions
                );
                handleACLUpdate([...aclRows]);
            }
        }
    };

    const deleteSubject = (subjectToDelete: string) => {
        if (subjectToDelete && aclRows) {
            const newACLRows = aclRows.filter((aceRow) => aceRow.subjectId !== subjectToDelete);
            handleACLUpdate(newACLRows);
        }
    };

    function getDefaultPermissionsOption(cell: any) {
        return permissionOptions.find((option) => option.value === cell.row.original.permissions[0]);
    }

    function getSubjectDisplayName(subjectId: string) {
        return (
            groups?.find((group) => group.id === subjectId)?.displayName ||
            users?.find((user) => user.id === subjectId)?.name ||
            'Unknown'
        );
    }

    const { mutateAsync: addUser, isLoading: addingUser } = useMutation(
        (validSubjectId: string) => AddUser({ email: validSubjectId, dashboardId }),
        {
            onError(error: AxiosError<any>) {
                if (error.response?.data.error.includes(userLimitError)) {
                    setSubjectErrorMessage(`${userLimitError}.`);
                } else {
                    setSubjectErrorMessage(error.response?.data.error || error.message);
                }
            }
        }
    );

    async function addSelectedSubject() {
        setSubjectErrorMessage('');
        if (selectedSubject && aclRows) {
            const subjectId = selectedSubject.value.toLowerCase().trim();
            if (selectedSubject.isNew) {
                if (users?.some((user) => user.id === subjectId || user.name === subjectId)) {
                    setSubjectErrorMessage('User already invited.');
                    return;
                }
                const user = await addUser(subjectId);

                if (user) {
                    queryClient.setQueryData<typeof users>(AccessControlQueryKeys.UsersWithLazyUpdate, (u) => [
                        user,
                        ...(u ?? [])
                    ]);
                }
            }
            const newACLRow: AccessControlEntryRow = {
                subjectId: selectedSubject.value,
                objectId: entityId,
                permissions: defaultPermissionsForNewACE,
                subjectDisplayName: getSubjectDisplayName(subjectId),
                permissionsDisplayName: getPermissionsDisplayName(permissionOptions, defaultPermissionsForNewACE)
            };
            const newACLRows = [newACLRow, ...aclRows];
            handleACLUpdate(newACLRows);
            setSelectedSubject(undefined);
            setFormValue('subject', null);
            queryClient.invalidateQueries(TENANT_USERS_QUERY_KEY);
        }
    }

    const InviteUserNoOptionsMessage = (props: any) => {
        const input = props.selectProps.inputValue;
        const alreadyExisting = users?.some((user) => user.id === input || user.name === input);
        return (
            <components.NoOptionsMessage {...props}>
                {alreadyExisting ? 'User already has access' : 'No options, or not a valid email address'}
            </components.NoOptionsMessage>
        );
    };

    const columns = useMemo(
        () => [
            {
                accessor: 'subjectId',
                width: 40,
                minWidth: 40,
                hideHeader: true,
                Cell: (cell: any) => {
                    if (isGroupId(cell.row.original.subjectId)) {
                        return (
                            <Avatar>
                                <FontAwesomeIcon icon={faUserGroup} className='w-4 h-4 text-xs shrink-0' />
                            </Avatar>
                        );
                    }

                    return <InitialAvatar text={cell.row.original.subjectId} />;
                }
            },
            {
                accessor: 'subjectDisplayName',
                width: 600,
                disableSortBy: true,
                hideHeader: true,
                Cell: (cell: any) => <div>{cell.value}</div>
            },
            {
                accessor: 'permissions[0]',
                width: 400,
                disableSortBy: true,
                hideHeader: true,
                Cell: (cell: any) => (
                    <div className='overflow-visible text-textSecondary'>
                        <Field.Input
                            name={'permissions.' + cell.row.original.subjectId?.replaceAll('.', '')}
                            type='autocomplete'
                            options={permissionOptions}
                            defaultValue={getDefaultPermissionsOption(cell)}
                            value={getDefaultPermissionsOption(cell)}
                            onSelect={(permissionOption: PermissionOption) => {
                                updateACE({
                                    subjectId: cell.row.original.subjectId,
                                    objectId: cell.row.original.objectId,
                                    permissions: [permissionOption.value]
                                });
                            }}
                            isMulti={false}
                            isClearable={false}
                            isSearchable={false}
                            menuShouldBlockScroll={true}
                            styles={styles}
                        />
                    </div>
                )
            },
            {
                id: 'options',
                accessor: '',
                width: 20,
                disableSortBy: true,
                hideHeader: true,
                Cell: (cell: any) => (
                    <div className='flex justify-end pr-2'>
                        <Tooltip title='Remove'>
                            <FontAwesomeIcon
                                icon={faTrash}
                                className='cursor-pointer'
                                onClick={() => deleteSubject(cell.row.original.subjectId)}
                                size={'lg'}
                            />
                        </Tooltip>
                    </div>
                )
            }
        ],
        [acl, aclRows, users]
    );

    const subjectSelector =
        !isTenantLoading && IsTenantAdmin(tenant) ? (
            <Field.Input
                name='subject'
                type='autocomplete'
                options={subjectOptions}
                isMulti={false}
                isClearable={true}
                isSearchable={true}
                onSelect={(option: any) => {
                    setSubjectErrorMessage('');
                    setSelectedSubject(option);
                }}
                defaultValue={null}
                placeholder='Select a user, group or type an email address'
                help={''}
                isValidNewOption={(input: string) =>
                    Boolean(input.match(emailRegex)) && !users?.some((user) => user.id === input || user.name === input)
                }
                onCreate={(v: string, l: string) => {
                    setSubjectErrorMessage('');
                    return { label: l, value: v, isNew: true };
                }}
                formatCreateLabel={(label: string) => `Invite new user: ${label}`}
                components={{ NoOptionsMessage: InviteUserNoOptionsMessage }}
            />
        ) : (
            <Field.Input
                name='subject'
                type='autocomplete'
                options={subjectOptions}
                isMulti={false}
                isClearable={true}
                isSearchable={true}
                onSelect={(option: any) => {
                    setSubjectErrorMessage('');
                    setSelectedSubject(option);
                }}
                defaultValue={null}
                placeholder='Choose a group or user ...'
                help={''}
                prepend={<FontAwesomeIcon icon={faUserGroup} fixedWidth className='w-6 primaryButtonText shrink-0' />}
            />
        );

    return (
        <div className='flex flex-col w-full max-h-full pt-6'>
            {!aclRows && <LoadingSpinner />}

            {aclRows && (
                <>
                    <div className='flex items-center justify-between w-full mt-2'>
                        <div className='flex-shrink w-full'>
                            {/* Note there is no form here - we assume there is one defined higher up */}
                            {subjectSelector}
                        </div>
                        <div className='ml-4'>
                            <Button
                                disabled={!selectedSubject || addingUser}
                                onClick={() => addSelectedSubject()}
                                type='button'
                                variant='secondary'
                                aria-label='Add user or group'
                            >
                                {addingUser ? <LoadingSpinner size={18} /> : selectedSubject?.isNew ? 'Invite' : 'Add'}
                            </Button>
                        </div>
                    </div>
                    {subjectErrorMessage && subjectErrorMessage.includes(userLimitError) && <UserLimitReachedText />}
                    <div className='max-h-full mt-4 tile-scroll-overflow'>
                        <Table
                            data={aclRows}
                            config={{
                                columns: columns,
                                autoResetSortBy: false,
                                hideSearch: true,
                                striped: false,
                                noDataMessage: 'Select a user or group above.'
                            }}
                        />
                    </div>
                    {subjectErrorMessage && !subjectErrorMessage.includes(userLimitError) && (
                        <p className='px-2 pt-2 text-statusErrorPrimary'>{subjectErrorMessage}</p>
                    )}
                </>
            )}
        </div>
    );
};

interface AccessControlEntryRow extends AccessControlEntryModel {
    subjectDisplayName: string;
    permissionsDisplayName: string;
    isGroup?: boolean;
}

export interface PermissionOption {
    label: string;
    value: AcePermission;
}

function getPermissionsDisplayName(permissionOptions: PermissionOption[], permissions: AcePermission[]) {
    if (permissions.length === 1) {
        const displayName = permissionOptions.find((option) => option.value === permissions[0])?.label;
        return displayName || permissions[0];
    }
    return 'Multiple'; // Should never happen currently
}

function isGroupId(id: string): boolean {
    // eslint-disable-next-line require-unicode-regexp
    return id?.match(/^group-[a-zA-Z0-9]{20}$/) != null;
}

const styles: StylesConfig<any> = {
    option: (provided, { data }) => {
        const colour = data.value === 'remove' ? '#ff3e51' : provided.color;
        const fontWeight = data.value === 'remove' ? 'bold' : provided.fontWeight;
        return {
            ...provided,
            color: colour,
            fontWeight: fontWeight
        } as CSSObjectWithLabel;
    }
};

export default CustomACLEditor;
