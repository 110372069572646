import { createContext, useContext, type Dispatch, type SetStateAction } from 'react';
import { ThemeOptions } from 'ui/hooks/useTheme';

export interface SetWorkspaceArgs {
    id?: string;
    performRedirect?: boolean;
    replaceHistory?: boolean;
    force?: boolean;
    // If true, we will pick the best workspace to redirect to, usefull if we don't know which workspace to redirect
    // to but we know we want to redirect to a workspace
    pickBest?: boolean;
    callback?: () => void;
}

export interface AppContextInterface {
    currentWorkspaceID?: string | null;
    searchOpen: boolean;
    theme: ThemeOptions;
    isNarrowLayout: boolean;
    setSearchOpen: Dispatch<SetStateAction<boolean>>;
    setCurrentWorkspaceID: (value?: SetWorkspaceArgs) => void;
}

export const AppContext = createContext<AppContextInterface>({
    theme: ThemeOptions.dark,
    searchOpen: false,
    isNarrowLayout: false,
    setSearchOpen: () => undefined,
    setCurrentWorkspaceID: () => undefined
});

export const useAppContext = () => useContext(AppContext);
