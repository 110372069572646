import { UseQueryOptions } from 'react-query';
import { Tenant } from 'services/TenantService';
import { useTenant } from './useTenant';

type QueryReturnType = Awaited<ReturnType<typeof Tenant>>;

/**
 * useQuery returning the title of the current Tenant. We leverage the `select` query option 
 * to extract the displayName.
 * @param options The react-query options e.g. refreshInterval
 * @returns The name of the current Tenant
 */
export const useOrgTitle = (options?: Omit<UseQueryOptions<QueryReturnType, unknown, string | undefined, string[]>, 'select'>) => 
    useTenant({
        select: (data) => {
            if (!data || data.displayName === data.name) {
                return 'My Organization';
            }

            return data.displayName;
        },
        ...options
    });
