import { getTimeframe } from '@squaredup/timeframes';
import { DatasourceFunction } from '../../../types/Datasource';
import Data from '../../../types/DataTypes';
import { ScriptConfig } from './Config';
import API from 'services/API';
import { runWorker } from './runWorker';

const script: DatasourceFunction<Data, ScriptConfig> = async (config, context, timeframe) => {
    // Must remove functions from clone of context before passing to worker / backend (they should not be there!)
    const ctx = JSON.parse(JSON.stringify(context));
    // Make the full Timeframe object available to scripts
    const tf = getTimeframe(timeframe);

    if (config.runInBrowser === true) {
        return runWorker(config, ctx, tf);
    }

    const body = {
        tileConfig: {
            datasource: {
                config
            }
        },
        timeframe: tf,
        context: ctx
    };
    return API.post('/tiledata', body);
};

script.config = 'ScriptConfig';

export default script;