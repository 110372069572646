import { Button } from '@/components/Button';
import Text from '@/components/Text';
import { EntityTypes } from '@squaredup/constants';
import { Node } from '@squaredup/graph';
import LoadingSpinner from 'components/LoadingSpinner';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { Query } from 'services/GraphService';

// react-query keys
export const DRILLDOWN_NODE = 'drilldownnode';
export const NODE_TAGS = 'nodetags';

export type NodeWithCanonical = (Node & { isCanonical: boolean }) | null;

export const queryForGraphNode = async (query: string, bindings?: object): Promise<NodeWithCanonical> => {
    const { gremlinQueryResults } = await Query(
        {
            gremlinQuery: query,
            bindings
        },
        'directOrAnyWorkspaceLinks'
    );

    // Take first entry from graph query - if empty something went wrong
    const nodeData = gremlinQueryResults?.[0];
    if (!nodeData) {
        return null;
    }
                
    // Add isCanonical property for conditional component display
    nodeData.isCanonical = Object.prototype.hasOwnProperty.call(nodeData, '__canonicalType');

    return nodeData;
};

export const reduceNodeJSONProperty = <TResult, TElement>(
    property: string | string[] | undefined,
    reducer: (accumulator: TResult, value: TElement, index: number) => TResult,
    initialValue: TResult
) => {
    // normalize input into a string array
    const propArray = typeof property === 'string' ? [property] : property ?? [];

    return propArray.reduce((acc, val, index) => {
        // Don't aggregate empty/undefined strings
        if (typeof val !== 'string' || val.length === 0) {
            return acc;
        }

        return reducer(acc, JSON.parse(val), index);
    }, initialValue);
};

// comma is required as this is a generic arrow function in a
// .tsx file, and react gets confused
export const reduceNodeProperty = <TResult,>(
    property: unknown | unknown[] | undefined,
    reducer: (accumulator: TResult, value: unknown, index: number) => TResult,
    initialValue: TResult
) => {
    // Normalize input into array
    const propArray = Array.isArray(property) ? (property as unknown[]) : property !== undefined ? [property] : [];

    return propArray.reduce(
        (acc: TResult, val, index) => (val === undefined ? acc : reducer(acc, val, index)),
        initialValue
    );
};

export const useTags = (nodeId?: string, nodeWithTags?: NodeWithCanonical) => {
    const { data: graphTags, isLoading } = useQuery<string[]>([NODE_TAGS, nodeId], async () => {
        if (!nodeId) {
            return [];
        }
        const { gremlinQueryResults } = await Query(
            {
                gremlinQuery: 'g.V().has("id", id).in("tags").valueMap(true)',
                bindings: { id: nodeId }
            },
            'directOrAnyWorkspaceLinks'
        );
        return (
            gremlinQueryResults
                ?.filter((result) => result.key)
                ?.map((tag) => (tag.value ? `${tag.key}:${tag.value}` : tag.key)) ?? []
        );
    });

    const tags = nodeWithTags?.type?.includes(EntityTypes.WORKSPACE)
        ? (graphTags ?? []).concat(
              reduceNodeJSONProperty(
                  nodeWithTags['properties'],
                  (acc: string[], val: Record<string, unknown>) =>
                      Array.isArray(val?.tags) ? acc.concat(val.tags) : acc,
                  []
              )
          )
        : graphTags;

    return {
        tags,
        isLoading
    };
};

export const GraphNodeLoading: FC = () => (
    <div className='pt-1 mt-10 text-center'>
        <LoadingSpinner />
    </div>
);

export const NodeNotFound: FC = () => (
    <div className='flex flex-col items-center h-screen mt-48'>
        <div className='mx-auto text-center'>
            <Text.H2>Object not accessible</Text.H2>
            <Text.Body>It may take up to a minute for new objects to appear.</Text.Body>
            <Button className='pt-4' variant='link' onClick={() => window.location.reload()}>Refresh</Button>
        </div>
    </div>
);
