import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faCircleCheck, faCircleXmark, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, ReactNode } from 'react';

const STATUS_CONFIG = {
    error: {
        fg: 'statusErrorPrimary',
        icon: faCircleXmark
    },
    success: {
        fg: 'statusHealthyPrimary',
        icon: faCircleCheck
    },
    warning: {
        fg: 'statusWarningPrimary',
        icon: faExclamationCircle
    }
} as const;

const STATUS_STYLES = {
    statusErrorPrimary: {
        border: 'border-statusErrorPrimary',
        text: 'text-statusErrorPrimary'
    },
    statusHealthyPrimary: {
        border: 'border-statusHealthyPrimary',
        text: 'text-statusHealthyPrimary'
    },
    statusWarningPrimary: {
        border: 'border-statusWarningPrimary',
        text: 'text-statusWarningPrimary'
    }
} as const;

type StatusType = keyof typeof STATUS_CONFIG;

type ResultMessageProps = {
    state: StatusType;
    title: ReactNode;
    body?: ReactNode;
    list?: ReactNode[];
    className?: string;
    wrapperClassName?: string;
};

const MessageBody = ({ body }: { body: ReactNode }): ReactElement | null => {
    const isBodyString = typeof body === 'string';

    if (!body) {
        return null;
    }

    return isBodyString ? (
        <Text.Body className='mt-2' aria-label='errorText'>
            {body}
        </Text.Body>
    ) : (
        (body as ReactElement)
    );
};

const MessageList = ({ list }: { list?: ReactNode[] }) => {
    if (!list?.length) {
        return null;
    }

    return (
        <ul className='mt-2 overflow-y-scroll max-h-64 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
            {list.map((listItem, index) => (
                <li key={index}>
                    <Text.Body>{listItem}</Text.Body>
                </li>
            ))}
        </ul>
    );
};

export const ResultMessage: React.FC<ResultMessageProps> = ({
    state,
    title,
    body,
    list,
    className,
    wrapperClassName
}) => {
    const { fg, icon } = STATUS_CONFIG[state];
    const styles = STATUS_STYLES[fg];
    const isBodyString = typeof body === 'string';

    return (
        <div className={cn('w-full max-h-full', className)}>
            <div className={cn('relative p-4 text-left border-2 rounded', styles.border)}>
                <div className='absolute top-0 left-0 w-full h-full opacity-10' />
                <div className={cn('relative flex flex-col h-full', wrapperClassName)}>
                    <div className='flex'>
                        <div className='mr-3 align-middle'>
                            <FontAwesomeIcon icon={icon} className={cn('text-base', styles.text)} />
                        </div>
                        <Text.H4 className={styles.text} aria-label='resultText'>
                            {title}
                        </Text.H4>
                    </div>
                    <div className={cn(isBodyString && 'ml-7')}>
                        <MessageBody body={body} />
                        <MessageList list={list} />
                    </div>
                </div>
            </div>
        </div>
    );
};
