const SqupSignInIdpPromptKey = 'SqupSignInIdpPrompt';

export type SignInPrompt =
    | 'select_account' // IdP should prompt user to select an account, if they are already signed-in to at least one
    | 'login'; // IdP should prompt user to sign in from scratch, ignoring existing browser sign-ins.

/**
 * Returns true if we should specify a prompt type for the auth identity provider
 * (MS, Google) when redirecting to B2C sign-in.
 *
 * Normally used when we want to ask the IdP to prompt the user
 * for the account to use, in cases where they might not want to sign-in with
 * the currently logged-in account.
 */
export function getSignInPrompt(): SignInPrompt | null {
    try {
        return localStorage.getItem(SqupSignInIdpPromptKey) as SignInPrompt | null;
    } catch {
        return null; // local storage not available, probably.
    }
}

/**
 * Call before sign out to signal that the app should specify this prompt to the IdP
 * on next sign-in.
 *
 * Normally used when we want to ask the IdP to prompt the user
 * for the account to use, in cases where they might not want to sign-in with
 * the currently logged-in account.
 */
export function setSignInPrompt(prompt: SignInPrompt): void {
    try {
        localStorage.setItem(SqupSignInIdpPromptKey, prompt);
    } catch {
        // Local storage not available, probably.
    }
}

export function clearSignInPrompt(): void {
    try {
        localStorage.removeItem(SqupSignInIdpPromptKey);
    } catch {
        // Local storage not available, probably.
    }
}
