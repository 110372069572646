import Field from 'components/forms/field/Field';
import type { ShapeConfigFieldsComponent } from './ShapeConfigFields';

export const NumberShapeConfigFields: ShapeConfigFieldsComponent = ({ name }) => {
    return (
        <Field.Input
            label='Decimal Places'
            name={`${name}.decimalPlaces`}
            type='number'
            min='0'
            max='10'
            className='w-28'
            help='Must be between 0 and 20. Clearing the field resets back to the default for the selected type.'
        />
    );
};
