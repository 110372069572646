import { cn } from '@/lib/cn';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/tooltip/Tooltip';
import type { ComponentProps } from 'react';

type Props = Omit<ComponentProps<typeof Tooltip>, 'title'>;

export const ObjectTooltip = (props: Props) => (
    <Tooltip
        {...props}
        title={'Objects are indexed into the graph and are used for scoping on dashboards and explored via drilldowns.'}
        className={cn(props.className, 'inline-block ml-2')}
    >
        <FontAwesomeIcon icon={faQuestionCircle} className='text-textSecondary' />
    </Tooltip>
);
