import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TruncatedText } from 'components/TruncatedText';
import { Fragment } from 'react';
import { resolveEachMatchedType } from '../../utilities/resolveEachMatchedType';
import { DataStreamFilterOption } from '../hooks/useDataStreamFilters';

interface DataStreamMatchDisplayProps {
    matchTypes: DataStreamFilterOption['definition']['matchesTypes']
}
export const DataStreamMatchTypeDisplay: React.FC<DataStreamMatchDisplayProps> = ({ matchTypes }) => {
    if (!matchTypes) {
        return null;
    }

    const resolvedTypes = resolveEachMatchedType(matchTypes);

    return (
        <TruncatedText
            element='div'
            className='flex min-w-0'
            title={resolvedTypes.map(({ name }) => name).join(', ')}
        >
            {resolvedTypes.map(({ name, icon }, index) =>
                <Fragment key={`${name}-${index}`}>
                    {index !== 0 ? ' ' : ''}
                    {icon && 
                        <FontAwesomeIcon 
                            icon={icon} 
                            className='h-[15px] w-[15px] mr-2 align-text-bottom flex-shrink-0'
                        />
                    }
                    {name}
                    {matchTypes.length && index !== matchTypes.length -1 ? ',  ' : ''}
                    &nbsp;
                </Fragment>
            )}
        </TruncatedText>
    );
};