import DropdownMenu from '@/components/DropdownMenu';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTileContext } from 'contexts/TileContext';

export const Edit: React.FC = () => {
    const { onEdit } = useTileContext();

    return (
        <DropdownMenu.Item icon={<FontAwesomeIcon icon={faPencil} fixedWidth />} onSelect={() => onEdit()} key='edit' testId='tileEdit'>
            Edit
        </DropdownMenu.Item>
    );
};
