import { date, FormattedStreamValue, type ShapeName } from '@squaredup/data-streams';
import { formatDate, standardDatePatterns } from '@squaredup/utilities';

/**
 *
 * @param shapeName The name of the data shape
 * @returns A function to transform the FormattedStreamValue into a formatted string
 */
export const makeFormatter = (
    shapeName: ShapeName | undefined
): ((value: FormattedStreamValue, options?: { style?: 'default' | 'long'; appendUtcOffset?: boolean }) => string) => {
    switch (shapeName) {
        case date.name:
            return ({ value }, { style, appendUtcOffset } = {}): string =>
                formatDate(value as string | number, {
                    pattern: standardDatePatterns[style ?? 'default'],
                    appendUtcOffset: appendUtcOffset || style === 'long'
                });
        default:
            return (value): string => value.formatted;
    }
};
