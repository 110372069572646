import LoadingSpinner from 'components/LoadingSpinner';
import { useModal } from 'components/Modal';
import { Presence } from 'components/Presence';
import Button from 'components/button/Button';
import { useWorkspacePermissions } from 'components/hooks/useWorkspacePermissions';
import { CreateWorkspaceModal } from 'components/workspaceModals/CreateWorkspaceModal';
import { EditWorkspaceModal } from 'components/workspaceModals/EditWorkspaceModal';
import { useWorkspacesForAdministration } from 'queries/hooks/useWorkspaceForAdministration';
import { DATA_STREAM_DATA } from 'queries/queryKeys/streamDataKeys';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { AccessControlQueryKeys } from 'services/AccessControlService';
import { Workspace } from 'services/WorkspaceService';
import { SettingsTemplate } from '../SettingsTemplate';
import WorkspacesDeleteModal from './WorkspacesDeleteModal';
import WorkspacesTable from './WorkspacesTable';
import { useTier } from 'queries/hooks/useTier';
import { isFeatureEnabled } from '@squaredup/tenants';
import { FeatureUnavailableBanner } from 'components/plans/FeatureUnavailableBanner';

/**
 * Renders the Workspaces settings page
 */
function Workspaces() {
    const queryClient = useQueryClient();
    const [deleteWorkspace, setDeleteWorkspace] = useState<Workspace>();
    const [workspaceBeingEdited, setWorkspaceBeingEdited] = useState<Workspace>();
    const editWorkspaceModal = useModal();
    const createWorkspaceModal = useModal();

    const { data: workspaces, isLoading: isLoadingWorkspaces } = useWorkspacesForAdministration({
        refetchInterval: 60_000,
        select: (data) => data.sort((a, b) => a.displayName.localeCompare(b.displayName))
    });

    const { data: tier, isLoading: isLoadingTier } = useTier();
    const isWorkspacesAvailable = (tier && isFeatureEnabled(tier, 'workspaces')) || workspaces?.length === 0;

    const mappedWorkspaces =
        workspaces?.map((workspace) => {
            return {
                ...workspace,
                data: {
                    ...workspace.data,
                    properties: {
                        ...workspace.data.properties,
                        tagString: workspace.data.properties?.tags ? workspace.data.properties.tags.join(', ') : ''
                    }
                }
            };
        }) ?? [];

    const { canEditSettings, canDelete, isLoading: isLoadingPermissions } = useWorkspacePermissions();

    const handleOnDelete = async (workspace: Workspace) => {
        setDeleteWorkspace(workspace);
    };

    const handleAddEdit = (workspace: Workspace) => {
        //NOTE: manually caching the workspace as the settings / workspaces uses the useWorkspacesForAdministration hook
        //      but the useWorkspace uses the useWorkspaces hook.
        queryClient.setQueryData<Array<Workspace>>(workspaceQueryKeys.list, (w) => [...(w ?? []), workspace]);

        setWorkspaceBeingEdited(workspace);
        editWorkspaceModal.open();
    };

    const isLoading = isLoadingWorkspaces || isLoadingPermissions || isLoadingTier;

    const reloadWorkspaces = async () => {
        await queryClient.invalidateQueries(workspaceQueryKeys.all);
        // Global overview page
        queryClient.invalidateQueries([DATA_STREAM_DATA, 'datastream-health']);
        await queryClient.invalidateQueries(AccessControlQueryKeys.EntityTypePermissions('space'));
    };

    return (
        <SettingsTemplate
            title='Workspaces'
            description={isWorkspacesAvailable ? 'Workspaces are used to logically organize your dashboards and data.' : undefined}
            learnMoreLink='https://squaredup.com/cloud/workspaces'
            flex
        >
            {isLoading && (
                <span className='flex justify-center'>
                    <LoadingSpinner />
                </span>
            )}
            {!isLoading && (
                <div className='flex flex-col flex-1 min-h-0'>
                    {!isWorkspacesAvailable && (
                        <FeatureUnavailableBanner
                            featureKey='workspaces'
                            container='page'
                            title='Organize your dashboards and data'
                            content='normal'
                            className='mb-9'
                        />
                    )}
                    <div>
                        <Button onClick={createWorkspaceModal.open} data-testid='addWorkspace' disabled={!isWorkspacesAvailable}>
                            Add workspace
                        </Button>
                    </div>

                    <div className='flex flex-col min-h-0 mt-4 mb-8'>
                        <WorkspacesTable
                            workspaces={mappedWorkspaces}
                            onEdit={handleAddEdit}
                            onDelete={handleOnDelete}
                            canEdit={canEditSettings}
                            canDelete={(w) => canDelete(w) && (isWorkspacesAvailable || mappedWorkspaces.length !== 1) }
                        />
                    </div>
                </div>
            )}

            {deleteWorkspace && (
                <WorkspacesDeleteModal
                    workspace={deleteWorkspace}
                    onClose={async () => {
                        reloadWorkspaces();
                        setDeleteWorkspace(undefined);
                    }}
                    key='workspaces-delete'
                />
            )}

            <Presence isPresent={editWorkspaceModal.isOpen}>
                <EditWorkspaceModal workspace={workspaceBeingEdited!} close={editWorkspaceModal.close} />
            </Presence>

            <Presence isPresent={createWorkspaceModal.isOpen}>
                <CreateWorkspaceModal close={createWorkspaceModal.close} />
            </Presence>
        </SettingsTemplate>
    );
}

export default Workspaces;
