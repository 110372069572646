import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';
import { useFlag } from 'lib/useFlag';
import { useRef } from 'react';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { DataStreamFilterOption } from '../hooks/useDataStreamFilters';
import { useSortedDataStreams } from '../hooks/useSortedDataStreams';
import { DataStreamOption } from './DataStreamOption';

interface DataStreamVirtualListProps {
    dataStreams?: DataStreamFilterOption[];
    allowSort?: boolean;
    selectedTags: string[];
    toggleTag: (tag: string) => void;
}

const icons: Record<number, IconProp> = {
    1: 'chevron-up',
    2: 'chevron-down'
};

export const DataStreamVirtualList: React.FC<DataStreamVirtualListProps> = ({
    dataStreams = [],
    allowSort = false,
    selectedTags,
    toggleTag
}) => {
    const parentRef = useRef<HTMLDivElement>(null);
    const { config, setConfig } = useDatasetContext();
    const dataStreamTagsFlagEnabled = useFlag('dataStreamTags');

    const { sortedStreams, sortOrder, topRecent, nextSortOrder } = useSortedDataStreams(dataStreams, allowSort);

    const rowVirtualizer = useVirtualizer({
        count: sortedStreams?.length || 0,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 35 // Height of each row in px
    });

    return (
        <div className='w-full min-h-[100px] overflow-hidden pl-6 pr-5'>
            <div
                ref={parentRef}
                className='w-full h-full overflow-auto text-sm scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary !overflow-x-scroll'
                role='list'
            >
                <div
                    className={clsx(
                        'py-2 px-2 rounded-input select-none grid min-w-fit w-full gap-6 text-textPrimary font-semibold',
                        {
                            'grid-cols-[1rem,minmax(7rem,_1fr),minmax(4rem,_1fr),minmax(8rem,_2fr),1rem]':
                                dataStreamTagsFlagEnabled,
                            'grid-cols-[1rem,minmax(7rem,_1fr),minmax(7rem,_2fr),minmax(5rem,_1.2fr),1rem]':
                                !dataStreamTagsFlagEnabled
                        }
                    )}
                >
                    <span onClick={() => allowSort && nextSortOrder()} className='flex items-center cursor-pointer'>
                        Name
                        {allowSort && icons[sortOrder] && <FontAwesomeIcon className='ml-4' icon={icons[sortOrder]} />}
                    </span>
                    <span></span>
                    <span>Description</span>
                    {dataStreamTagsFlagEnabled ? <span>Tags</span> : <span>Type</span>}
                    <span></span>
                </div>
                <div className='relative h-full min-h-full' style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                    {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                        const dataStream = sortedStreams?.[virtualItem.index];

                        return (
                            <div
                                key={virtualItem.key.toString()}
                                className='absolute top-0 left-0 w-full'
                                style={{
                                    height: `${virtualItem.size}px`,
                                    transform: `translateY(${virtualItem.start}px)`
                                }}
                            >
                                {dataStream && (
                                    <DataStreamOption
                                        dataStream={dataStream}
                                        recent={topRecent.includes(dataStream.id)}
                                        config={config}
                                        setConfig={setConfig}
                                        selectedTags={selectedTags}
                                        toggleTag={toggleTag}
                                    />
                                )}
                            </div>
                        );
                    })}

                    {dataStreams && dataStreams.length === 0 && (
                        <div className='text-textIncomplete'>No search results were found.</div>
                    )}
                </div>
            </div>
        </div>
    );
};
