import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

/**
 *  A version of the react-select dropdown optimised for form use
 *  This one is specially configured that it will only register a SINGLE VALUE.
 *  Specifically the `value` property of the selection.
 */
export const FormDropdown = ({
    name,
    options,
    isDisabled
}: {
    name: string;
    options: AutocompleteOption[];
    isDisabled?: boolean;
}) => {
    const portalTarget = useDOMElement('visualizationConfigPanel');

    return (
        <Controller
            name={name}
            render={({ field: { onChange, onBlur, value } }) => (
                <Select<AutocompleteOption>
                    defaultValue={options.find((v) => v?.value === value)}
                    onChange={(v) => {
                        onChange(v?.value);
                    }}
                    onBlur={onBlur}
                    options={options}
                    isDisabled={isDisabled ?? false}
                    blurInputOnSelect={true}
                    classNamePrefix='autocomplete'
                    className='ring-1 ring-inset ring-outlinePrimary focus-within:ring-outlineSecondary rounded-input bg-componentBackgroundPrimary '
                    key={`select_key_${value}`}
                    menuPortalTarget={portalTarget}
                />
            )}
        />
    );
};
