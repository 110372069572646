import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import ToggleField from 'components/accessControl/ToggleField';
import Button from 'components/button/Button';
import { ResultMessage } from 'components/ui/state/ResultMessage';
import { FC, useState } from 'react';
import { ContactSupportLink } from './InstallSampleDashboardsForm';
import { useHasDefaultContent } from './useHasDefaultContent';

interface LinkImportModalCancelModalProps {
    dataSourceName: string;
    sourcePluginId: string;
    onClose: () => void;
    onSubmit: (addDashboards: boolean) => Promise<void>;
}

export const LinkImportCancelModal: FC<LinkImportModalCancelModalProps> = ({
    dataSourceName,
    sourcePluginId,
    onClose,
    onSubmit
}) => {
    const { hasDefaultContent, isLoading } = useHasDefaultContent(sourcePluginId);
    const [importSampleDashboards, setImportSampleDashboards] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setErrorMessage(null);

        try {
            await onSubmit(importSampleDashboards && (hasDefaultContent ?? false));
        } catch {
            setErrorMessage('Failed to install sample dashboards.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const getButtonConfig = () => ({
        onClick: errorMessage ? onClose : handleSubmit,
        text: isSubmitting ? <LoadingSpinner size={18} /> : errorMessage ? 'Close' : 'Add'
    });

    const buttonConfig = getButtonConfig();

    const isError = errorMessage != null;

    return (
        <Modal title={`Add data source: ${dataSourceName}`} close={onClose}>
            <div className='px-8 min-w-[800px] text-textPrimary space-y-6'>
                <p>Are you sure you want to add this data source to this workspace?</p>
                {!isLoading && hasDefaultContent && (
                    <div className='pt-6'>
                        <ToggleField
                            label='Install sample dashboards'
                            toggleDefaultValue={importSampleDashboards}
                            onToggle={setImportSampleDashboards}
                            toggleDisabled={isSubmitting || isError}
                        />
                    </div>
                )}

                {isError && (
                    <ResultMessage
                        state='error'
                        title={errorMessage}
                        body={<ContactSupportLink />}
                        wrapperClassName='flex-row justify-start items-start gap-x-2'
                    />
                )}
            </div>

            <ModalButtons>
                {!errorMessage && (
                    <Button type='button' variant='tertiary' onClick={onClose} disabled={isLoading}>
                        Cancel
                    </Button>
                )}

                <Button type='button' onClick={getButtonConfig().onClick} disabled={isLoading || isSubmitting}>
                    {buttonConfig.text}
                </Button>
            </ModalButtons>
        </Modal>
    );
};
