import { cn } from '@/lib/cn';
import { Presence } from 'components/Presence';
import Tooltip from 'components/tooltip/Tooltip';
import { ComponentPropsWithoutRef } from 'react';
import { z } from 'zod';
import { Skeleton } from './Skeleton';

const initialAvatarSchema = z
    .string()
    .trim()
    .min(1)
    .max(255)
    .catch(() => 'unknown')
    .transform((text) => ({ text, firstChar: text[0] }));

export const InitialAvatar = (props: { text: string }) => {
    const avatar = initialAvatarSchema.parse(props.text);

    return (
        <Tooltip title={avatar.text}>
            <Avatar>{avatar.firstChar}</Avatar>
        </Tooltip>
    );
};

export const InitialAvatarSkeleton = () => {
    return (
        <Skeleton className='rounded-full'>
            <Avatar className='bg-transparent border-none backdrop-blur-md' />
        </Skeleton>
    );
};

export const StackedInitialAvatars = (props: { texts: Array<string> }) => {
    const limit = 5;
    const itemsToDisplay = props.texts.slice(0, limit);
    const itemsOverLimit = props.texts.slice(limit);

    if (!itemsToDisplay.length) {
        return null;
    }

    return (
        <StackedContainer>
            {itemsToDisplay.map((text, i) => (
                <InitialAvatar key={i} text={text} />
            ))}

            <Presence isPresent={Boolean(itemsOverLimit.length)}>
                <Tooltip
                    title={itemsOverLimit.map((text) => (
                        <p>{text}</p>
                    ))}
                >
                    <Avatar>+{itemsOverLimit.length}</Avatar>
                </Tooltip>
            </Presence>
        </StackedContainer>
    );
};

export const StackedInitialAvatarsSkeleton = () => {
    const count = 5;

    return (
        <StackedContainer>
            {[...Array(count).keys()].map((i) => (
                <InitialAvatarSkeleton key={i} />
            ))}

            <InitialAvatarSkeleton />
        </StackedContainer>
    );
};

export const Avatar = ({ className, ...props }: ComponentPropsWithoutRef<'div'>) => {
    return (
        <div
            className={cn(
                'flex items-center justify-center w-[2rem] h-[2rem] text-base font-medium text-textPrimary bg-componentBackgroundSecondary border border-outlinePrimary rounded-full uppercase hover:border-outlineSecondary hover:bg-filterActive transition-colors',
                className
            )}
            data-testid='avatar'
            {...props}
        />
    );
};

const StackedContainer = (props: ComponentPropsWithoutRef<'div'>) => {
    return <div className='flex -space-x-3' {...props} />;
};
