import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useAppContext } from 'contexts/AppContext';
import { clamp } from 'lodash';
import { useEffect, useRef } from 'react';
import { useWorkspaceCanWrite } from 'services/AccessControlService';

export const useDashboardTreeAutoScroll = () => {
    const { currentWorkspaceID } = useAppContext();

    const { data: canWrite } = useWorkspaceCanWrite(currentWorkspaceID ?? '');
    const scrollRef = useRef<HTMLDivElement>(null);

    const scrollVelocity = useRef(0);
    const start = useRef(0);

    useEffect(() => {
        const handleScroll = (timestamp: number) => {
            if (start.current === 0) {
                start.current = timestamp;
            }
            const elapsed = timestamp - start.current;

            if (scrollVelocity.current === 0) {
                start.current = 0;
                return;
            }
            const scrollTop = scrollRef.current!.scrollTop;

            scrollRef.current?.scrollTo({
                top: scrollTop + scrollVelocity.current * elapsed * 0.01,
                behavior: 'instant'
            });

            start.current = timestamp;
            requestAnimationFrame(handleScroll);
        };

        return monitorForElements({
            canMonitor: ({ source }) => source.data.treeId === 'navbar-dashboard-tree' && Boolean(canWrite),
            onDrag(e) {
                const { top, bottom } = scrollRef.current!.getBoundingClientRect();

                const scrollingStart = 10;

                const pageY = e.location.current.input.pageY;

                if (pageY < top + scrollingStart) {
                    // Start scrolling up
                    scrollVelocity.current = clamp(pageY - top, -75, 0);
                } else if (pageY > bottom - scrollingStart) {
                    // start scrolling down
                    scrollVelocity.current = clamp(pageY - bottom, 0, 75);
                } else {
                    scrollVelocity.current = 0;
                }

                if (scrollVelocity.current && !start.current) {
                    requestAnimationFrame(handleScroll);
                }
            },
            onDrop() {
                scrollVelocity.current = 0;
            }
        });
    }, [canWrite]);

    return scrollRef;
};
