import { Divider } from '@/components/Divider';
import { findColumns, required, state } from '@squaredup/data-streams';
import Field from 'components/forms/field/Field';
import Slider from 'components/forms/slider/Slider';
import Number from 'components/visualisationOptions/Number';
import { OptionToggle } from 'components/visualisationOptions/OptionToggle';
import { VisualisationOption } from 'dashboard-engine/types/Visualisation';
import { ColumnDropdown } from '../sharedComponents/ColumnDropdown';
import { VisualisationConfigAccordion } from '../VisualisationConfigAccordion';
import { DataStreamBlocksConfig } from './Config';
import {
    findBlocksLabelColumn,
    findBlocksLinkColumn,
    findBlocksLinkColumns,
    findBlocksStateColumn,
    matchesData
} from './dataUtils';

const DataStreamBlocksOptions: VisualisationOption<DataStreamBlocksConfig> = {
    initialPanels: (config?: DataStreamBlocksConfig) => {
        const panels = [{ name: 'mapping', isOpen: true }];

        if (
            (config?.columns !== undefined && config.columns !== 3) || 
            config?.blockHeight !== undefined
        ) {
            panels.push({ name: 'layout', isOpen: true });
        }

        if (config?.wrapLabels) {
            panels.push({ name: 'options', isOpen: true });
        }

        return panels;
    },
    dataMappingComponent: ({ columns, config, onChange }) => {
        const stateColumns = findColumns(columns, required('shapeName', state.name));
        const linkColumns = findBlocksLinkColumns(columns);

        return (
            <>
                <ColumnDropdown
                    label='State'
                    name='stateColumn'
                    help='Select the column to use for the state color of each block'
                    columns={stateColumns.succeeded ? stateColumns.value.map((c) => c.column) : []}
                    defaultColumn={findBlocksStateColumn(columns)}
                    showNoneOption={true}
                    config={config}
                    onChange={onChange}
                />
                <ColumnDropdown
                    label='Label'
                    name='labelColumn'
                    help='Select the column to use for the label of each block'
                    columns={columns}
                    defaultColumn={findBlocksLabelColumn(columns)}
                    config={config}
                    onChange={onChange}
                />
                <ColumnDropdown
                    label='Sublabel'
                    name='sublabel'
                    showNoneOption={true}
                    help='Select the column to use for the sublabel of each block'
                    columns={columns}
                    config={config}
                    onChange={onChange}
                />
                <ColumnDropdown
                    label='Link'
                    name='linkColumn'
                    help='Select the column to use for the link of each block'
                    columns={linkColumns ? linkColumns.map((c) => c.column) : []}
                    defaultColumn={findBlocksLinkColumn(columns)}
                    config={config}
                    onChange={onChange}
                    showNoneOption={true}
                />
            </>
        );
    },
    configurationComponent: ({ config, accordionControls, onChange }) => {
        const columnOptions = {
            min: 1,
            max: 12,
            step: 1,
            defaultValue: 3
        };

        return (
            <>
                <VisualisationConfigAccordion
                    value='layout'
                    label='Layout'
                    accordionControls={accordionControls}
                >
                    <Field
                        label='Max columns'
                        help='If fewer blocks are returned, they will automatically expand to fill the space.'
                    >
                        <Number
                            name='columns'
                            title='Maximum number of columns'
                            options={columnOptions}
                            config={config}
                            onChange={onChange}
                        />
                    </Field>

                    <OptionToggle
                        name='manual height'
                        label='Manual height'
                        checked={config.blockHeight !== undefined}
                        onChange={() => onChange({ action: 'block-autoSize-toggle' })}
                    />

                    {config.blockHeight !== undefined && (
                        <div className='flex-1 min-w-0 mt-3 px-md'>
                            <Slider
                                min={50}
                                max={310}
                                defaultValue={config.blockHeight ?? 180}
                                onChange={(e) => onChange({ action: 'block-height', data: e.target.value })}
                                markers={[
                                    { value: 50, label: 'Min' },
                                    { value: 310, label: 'Max' }
                                ]}
                            />
                        </div>
                    )}
                </VisualisationConfigAccordion>

                <Divider />

                <VisualisationConfigAccordion
                    value='options'
                    label='Options'
                    accordionControls={accordionControls}
                >
                    <OptionToggle
                        name='wrapText'
                        label='Wrap text'
                        onChange={(data) => onChange({ action: 'block-wrapLabels-toggle', data })}
                        checked={config.wrapLabels ?? false}
                    />
                </VisualisationConfigAccordion>
            </>
        );
    },
    handlers: {
        'sublabel-select': (config, data) => {
            if (data && data !== 'none') {
                return { ...config, sublabel: data };
            } else {
                const { sublabel: _, ...rest } = config;
                return rest;
            }
        },
        'stateColumn-select': (config, data) => {
            return {
                ...config,
                stateColumn: data
            };
        },
        'labelColumn-select': (config, data) => {
            return {
                ...config,
                labelColumn: data
            };
        },
        'linkColumn-select': (config, data) => {
            return {
                ...config,
                linkColumn: data
            };
        },
        'columns-number': (config, data) => {
            return { ...config, columns: data };
        },
        'block-autoSize-toggle': (config) => {
            if (config.blockHeight !== undefined) {
                const { blockHeight, ...configWithoutBlockHeight } = config;
                return configWithoutBlockHeight;
            }

            // Just add the blockHeight key to the config so we know we can set it
            return {
                ...config,
                blockHeight: 180
            };
        },
        'block-height': (config, data) => {
            return { ...config, blockHeight: data };
        },
        'block-wrapLabels-toggle': (config, data) => {
            return { ...config, wrapLabels: data };
        }
    },
    validate: (_, config) => {
        return config;
    },
    matchesData
};

export default DataStreamBlocksOptions;
