import { VariableFormData } from 'pages/dashboard/components/utils/getVariableFromFormData';
import API from './API';
import { handleError, handleResponse } from './util';

export const ListVariables = (workspaceId: string) =>
    API.get(`/workspaces/${workspaceId}/variables`).then(handleResponse).catch(handleError);

export const ListVariablesForDashboard = (dashboardId: string) =>
    API.get(`/dashboards/${dashboardId}/variables`).then(handleResponse).catch(handleError);

export const ListVariableObjects = (params: Record<string, unknown>) =>
    API.get('/variables/objects', { params }).then(handleResponse).catch(handleError);

export const GetVariableObjects = (params: { nodeIds: string[] }) =>
    API.get('/variables/objects/ids', { params }).then(handleResponse).catch(handleError);

export const AddVariable = (workspaceId: string, data: VariableFormData & { dashboardId: string }) =>
    API.post(`/workspaces/${workspaceId}/variables`, data)
        .then(handleResponse)
        .catch((error) => handleError(error, true));

export const UpdateVariable = (variableId: string, data: VariableFormData & { dashboardId: string }) =>
    API.put(`/variables/${variableId}`, data)
        .then(handleResponse)
        .catch((error) => handleError(error, true));

export const DeleteVariable = (variableId: string) =>
    API.delete(`/variables/${variableId}`)
        .then(handleResponse)
        .catch((error) => handleError(error, true));
