import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { TruncatedText } from 'components/TruncatedText';

interface LegendItemProps {
    label: string;
    value?: string;
    color: string;
    maxWidth: number;
    isDimmed: boolean;
    showTooltip?: boolean;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

export const LegendItem: React.FC<LegendItemProps> = ({
    label,
    value,
    color,
    maxWidth,
    isDimmed,
    showTooltip,
    onMouseEnter,
    onMouseLeave
}) => (
    <div
        className={cn('flex gap-3 text-left transition ease-in-out duration-200', { 'opacity-30': isDimmed })}
        style={{ maxWidth }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-testid={label}
    >
        <div>
            <div style={{ backgroundColor: color }} className='w-3 h-3 mt-1 rounded-sm' />
        </div>
        <div className='w-full overflow-hidden'>
            <div className='break-all text-textPrimary line-clamp-1'>
                {showTooltip ? (
                    <Text.H5>{label}</Text.H5>
                ) : (
                    <TruncatedText
                        title={label}
                        element='h5'
                        className='font-montserrat font-semibold text-[12px] truncate'
                    />
                )}
            </div>
            {value && (
                <div className='break-all text-textSecondary line-clamp-1'>
                    <Text.H5>{value}</Text.H5>
                </div>
            )}
        </div>
    </div>
);
