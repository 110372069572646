import { AxiosError } from 'axios';
import { tenantQueryKeys } from 'queries/queryKeys/tenantKeys';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { AddUser } from 'services/UserService';
import { TenantUser, useTenantUsers } from './useTenantUsers';

type Options = UseMutationOptions<TenantUser, AxiosError<{ error: string }>, Parameters<typeof AddUser>[0]>;

/**
 * useMutation to invite a user to a dashboard
 * @param options The useMutation options object
 * @returns uesMutation instance
 */
export const useInviteUserToDashboard = (options?: Options) => {
    const queryClient = useQueryClient();
    const tenantUsers = useTenantUsers();

    const sendInvitation = useMutation({
        ...options,
        mutationFn: AddUser,
        onSettled: () => {
            queryClient.invalidateQueries(tenantQueryKeys.tenantUsers);
        },
        onMutate: (newUser) => {
            const userInTenant = tenantUsers.data?.find((user) => user.id === newUser.email);

            if (userInTenant) {
                throw new Error('user already joined');
            }
        }
    });

    return sendInvitation;
};
