import { Skeleton } from '@/components/Skeleton';
import Template from '@/components/Template';
import type { DashboardType, DashboardVariable } from '@squaredup/dashboards';
import { TimeframeEnumValue, defaultTimeframeEnum } from '@squaredup/timeframes';
import { DashboardStateIndicator } from 'components/ui/state/DashboardStateIndicator';
import { AppContext } from 'contexts/AppContext';
import DashboardContext from 'contexts/DashboardContext';
import RefreshContext from 'contexts/RefreshContext';
import { FullScreenWrapper } from 'dashboard-engine/fullscreen/FullScreenWrapper';
import RenderDynamic from 'dashboard-engine/render/RenderDynamic';
import {
    checkIfAllTilesUseFixedTimeframe,
    getDashboardContents,
    setDashboardContents
} from 'dashboard-engine/util/dashboard';
import type { OOBInfo } from 'dynamo-wrapper';
import { usePageTitle } from 'lib/usePageTitle';
import { useRefresh } from 'lib/useRefresh';
import { debounce } from 'lodash';
import { NotFoundPanel } from 'pages/components/Panels/NotFoundPanel';
import { useDashboard } from 'queries/hooks/useDashboard';
import { useDashboardVariables } from 'queries/hooks/useDashboardVariables';
import { optimisticMonitorCountUpdate } from 'queries/hooks/useMonitorsCount';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import EasyEdit from 'react-easy-edit';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useWorkspaceCanWrite } from 'services/AccessControlService';
import { useCachedCurrentWorkspace } from 'services/WorkspaceUtil';
import { CodeEditor } from 'ui/editor/components/CodeEditor';
import { useEnsureCorrectCurrentWorkspaceId } from 'ui/hooks/useEnsureCorrectCurrentWorkspaceId';
import { useZendeskLabels } from 'ui/zendesk/ZendeskContext';
import { DashboardVariablesControl } from './components/DashboardVariablesControl';
import { useInitialVariableSelection } from './components/utils/useInitialVariableSelection';
import { useSetVariableSearchParams } from './components/utils/useSetVariableSearchParams';
import { DashboardActions } from './DashboardActions';
import { DashboardMetricsProvider } from './DashboardMetrics';
import { getComparableDashboardString } from './DashboardVersioning';
import { useHandleSave } from './hooks/useHandleSave';
import { displayNameIsValid, validateConfig } from './utils';

const DashboardActionsSkeleton = () => (
    <div className='flex ml-auto gap-x-4'>
        <Skeleton className='h-button w-[129px]' />
        <Skeleton className='h-button w-[107px]' />
        <Skeleton className='h-button w-[95px]' />
        <Skeleton className='h-button w-[46px]' />
    </div>
);

const DashboardVariableSkeleton = () => (
    <div className='flex items-center text-base'>
        <div className='h-full py-3 mx-6 border-l border-dividerPrimary'></div>
        <Skeleton className='w-[145px] h-button' />
    </div>
);

const DashboardLoader: FC = () => {
    const { id } = useParams();
    const { currentWorkspaceID } = useContext(AppContext);

    const navigate = useNavigate();
    const { data: dashboard, isLoading: isLoadingDashboard } = useDashboard(id, { refetchOnMount: 'always' });
    if (!dashboard && id === undefined) {
        navigate(`/workspace/${currentWorkspaceID}`);
    }

    useEnsureCorrectCurrentWorkspaceId(dashboard?.workspaceId);

    if (dashboard) {
        return (
            <DashboardMetricsProvider
                key={dashboard?.id}
                dashboardContents={dashboard.content?.contents ?? []}
                oobInfo={dashboard.oobInfo as OOBInfo}
            >
                <Dashboard dashboard={dashboard as DashboardType} />
            </DashboardMetricsProvider>
        );
    }

    if (isLoadingDashboard && id !== undefined) {
        // show skeleton of header
        return (
            <div className='pt-[20px] flex gap-x-4 items-center'>
                <DashboardStateIndicator dashboardId={id} className='w-[10px] h-[10px]' />
                <Skeleton className='max-w-[400px] flex-1 h-button' />
                <DashboardActionsSkeleton />
            </div>
        );
    }

    return (
        <div className='flex flex-col items-center justify-center mt-48'>
            <div className='p-10 mx-auto text-center rounded-md text-textPrimary'>
                {!isLoadingDashboard && id && (
                    <NotFoundPanel
                        title='Dashboard not accessible'
                        body='The dashboard does not exist or you do not have permissions to access it.'
                    />
                )}
                {id === undefined && (
                    <div>
                        <h1 className='mb-1 text-2xl font-bold' data-testid='noDashboardHeader'>
                            Create a dashboard or scope to get started.
                        </h1>
                    </div>
                )}
            </div>
        </div>
    );
};

interface DashboardProps {
    dashboard: DashboardType;
}

const Dashboard: FC<DashboardProps> = ({ dashboard }) => {
    const initialNodes = useInitialVariableSelection(dashboard.id);
    const { data: dashboardVariables, isLoading: isLoadingDashboardVariables } = useDashboardVariables(
        dashboard.id,
        initialNodes
    );
    const [search, setSearchParams] = useSearchParams();
    const [variables, setVariables] = useState<DashboardVariable[]>(dashboardVariables ?? []);
    const [timeframe, setTimeframe] = useState<TimeframeEnumValue>(dashboard.timeframe || defaultTimeframeEnum);
    const [editing, setEditing] = useState(false);
    const [editingJson, setEditingJson] = useState(false);
    const [editingName, setEditingName] = useState(false);
    const { currentWorkspaceID, isNarrowLayout } = useContext(AppContext);
    const EasyEditRef = useRef<any>();
    const queryClient = useQueryClient();
    /* If we have a dashboard but no content we know we're using the initialData 
        from the cache and therefore are fetching the dashboard content */
    const isLoaded = Boolean(dashboard.content) && !isLoadingDashboardVariables;

    const { data: canWriteToWorkspace } = useWorkspaceCanWrite(currentWorkspaceID || '');
    const { mutate: updateDashboard } = useHandleSave();

    useZendeskLabels('dashboard');
    usePageTitle(dashboard.displayName);
    useSetVariableSearchParams(variables);

    const allTilesUseFixedTimeframe = checkIfAllTilesUseFixedTimeframe(dashboard.content?.contents);

    // Ensure that the saved timeframe is set when the dashboard loads asynchronously
    useEffect(() => {
        if (dashboard.timeframe) {
            setTimeframe(dashboard.timeframe);
        }
    }, [dashboard.timeframe]);

    useEffect(() => {
        if (!isLoadingDashboardVariables) {
            setVariables(dashboardVariables ?? []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingDashboardVariables]);

    const debouncedUpdate = useMemo(
        () =>
            debounce((updatedDashboardContent: any) => {
                updateDashboard(updatedDashboardContent);
            }, 1000),
        [updateDashboard]
    );

    useEffect(() => {
        if (search.has('editing')) {
            setEditing(true);
            search.delete('editing');
            // Remove query param from URL
            setSearchParams(search, { replace: true });
        }
    }, [search, setSearchParams]);

    const saveDisplayName = (value: string) => {
        if (displayNameIsValid(value)) {
            const updatedDashboard = { ...dashboard, displayName: value };
            updateDashboard(updatedDashboard);
        }
        setEditingName(false);
    };

    const renderCodeEditor = dashboard?.content ? (
        <CodeEditor
            content={dashboard.content}
            contentValidator={validateConfig}
            heightConstraints={{ min: 400 }}
            onValidUpdatedContent={(changedConfig: any) => {
                debouncedUpdate({ ...dashboard, content: changedConfig });
            }}
        />
    ) : null;

    const currentWorkspace = useCachedCurrentWorkspace();

    const DashboardProviderValue = useMemo(
        () => ({
            editing,
            setEditing: canWriteToWorkspace ? setEditing : undefined,
            dashboard,
            timeframe,
            currentWorkspace,
            showDetailedErrors: canWriteToWorkspace ?? false,
            variables,
            setVariables,
            updateTile: (changedConfig: any, tileId: any) => {
                let monitorDifference = 0;
                // Modify config for tile
                const updatedContents = getDashboardContents(dashboard).map((dashboardTile) => {
                    if (dashboardTile.i === tileId) {
                        const originalMonitorEnabled =
                            'monitor' in dashboardTile.config && dashboardTile.config.monitor ? 1 : 0;
                        const updatedMonitorEnabled = changedConfig?.monitor ? 1 : 0;
                        monitorDifference = updatedMonitorEnabled - originalMonitorEnabled;

                        return {
                            ...dashboardTile,
                            config: changedConfig
                        };
                    }
                    return dashboardTile;
                });

                // Optimistically update the monitor count if that has changed
                if (monitorDifference !== 0) {
                    optimisticMonitorCountUpdate(queryClient, monitorDifference);
                }

                updateDashboard(setDashboardContents(dashboard, updatedContents));
            }
        }),
        [dashboard, editing, timeframe, variables, currentWorkspace, canWriteToWorkspace, updateDashboard, queryClient]
    );

    const { refreshCount, forceRefresh } = useRefresh();

    const MemoedRenderDynamic = useMemo(
        () => (
            <FullScreenWrapper isEditingEnabled={Boolean(canWriteToWorkspace)}>
                <RenderDynamic config={dashboard.content} key={dashboard.id} />
            </FullScreenWrapper>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [canWriteToWorkspace, getComparableDashboardString(dashboard), dashboard.id]
    );

    const handleSetEditing = (isEditing: boolean) => {
        setEditing(isEditing);
        if (!isEditing) {
            setEditingJson(false);
        }
    };

    return (
        <DashboardContext.Provider value={DashboardProviderValue}>
            <Template
                key={dashboard.id}
                title={
                    canWriteToWorkspace ? (
                        <EasyEdit
                            ref={EasyEditRef}
                            type='text'
                            validationMessage=''
                            placeholder='Dashboard Name'
                            onFocus={() => setEditingName(true)}
                            onCancel={() => setEditingName(false)}
                            onSave={saveDisplayName}
                            value={dashboard.displayName}
                            saveOnBlur={true}
                            displayComponent={<span className='group-hover/dashboardName:pr-2'>{dashboard.displayName}</span>}
                            viewAttributes={{ className: 'truncate group/dashboardName' }}
                        />
                    ) : (
                        dashboard.displayName
                    )
                }
                postTitleActions={
                    Boolean(dashboard.variables?.length) && isLoadingDashboardVariables ? (
                        <DashboardVariableSkeleton />
                    ) : (
                        <DashboardVariablesControl canEdit={Boolean(editing && canWriteToWorkspace)} />
                    )
                }
                icon={<DashboardStateIndicator dashboardId={dashboard.id} className='w-[10px] h-[10px] mr-4' />}
                iconAlignment='center'
                iconWrapperClassName='mr-0'
                actions={
                    isLoaded ? (
                        !dashboard.builtIn && (
                            <DashboardActions
                                editing={editing}
                                editingName={editingName}
                                setEditing={handleSetEditing}
                                toggleJsonEditor={() => setEditingJson(!editingJson)}
                                triggerRefresh={forceRefresh}
                                timeframe={timeframe}
                                setTimeframe={setTimeframe}
                                isFixedTimeframe={allTilesUseFixedTimeframe}
                                dashboard={dashboard}
                                isNarrowLayout={isNarrowLayout}
                                updateDashboard={updateDashboard}
                                canWriteToWorkspace={!isNarrowLayout && canWriteToWorkspace}
                            />
                        )
                    ) : (
                        <DashboardActionsSkeleton />
                    )
                }
                sticky
                equalPadding
                isNarrowLayout={isNarrowLayout}
            >
                {editing && editingJson && renderCodeEditor}
                {isLoaded && (
                    <div className='pb-4 mt-px'>
                        <RefreshContext.Provider
                            value={{
                                name: 'dashboard',
                                refreshCount,
                                forceRefresh
                            }}
                        >
                            {MemoedRenderDynamic}
                        </RefreshContext.Provider>
                    </div>
                )}
            </Template>
        </DashboardContext.Provider>
    );
};

DashboardLoader.propTypes = {};

export default DashboardLoader;
