import { Checkbox } from '@/components/forms/Checkbox';
import { Node } from '@squaredup/graph';
import { TruncatedText } from 'components/TruncatedText';
import { ObjectOptionColumn } from './ObjectOption';
import { cn } from '@/lib/cn';

interface SelectedObjectsOptionProps {
    object: Node;
    isActive?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

export const SelectedObjectsOption: React.FC<SelectedObjectsOptionProps> = ({
    object,
    isActive,
    disabled,
    onClick
}) => {
    const { name, id } = object;

    return (
        <ObjectOptionColumn>
            <label
                className={cn('flex flex-row items-center space-x-2 truncate', !disabled && 'cursor-pointer')}
                onClick={!disabled ? onClick : undefined}
                aria-label='Interacted object'
                htmlFor={`object-${id}`}
            >
                {onClick && <Checkbox checked={isActive} disabled={disabled} />}
                <TruncatedText title={name} className='min-w-0 py-1' />
            </label>
        </ObjectOptionColumn>
    );
};
