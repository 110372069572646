import { LayoutFlow } from 'components/map/components/LayoutFlow';
import { NetworkMapStoreProvider } from 'components/map/context/NetworkMapStoreContext';
import { useGlobalMapData } from 'components/map/data/useGlobalMapData';
import { setNodesAndEdgeVisibility } from 'components/map/data/utils/setNodesAndEdgeVisibility';
import { LayoutTypes } from 'components/map/layout/types';
import NetworkSkeleton from 'components/map/NetworkSkeleton';
import stringify from 'fast-json-stable-stringify';
import { useStatusConfig } from 'pages/status/utils/useStatusConfig';
import { ReactFlowProvider } from 'reactflow';

export const TenantNetwork: React.FC = () => {
    const { data, isLoading } = useGlobalMapData();

    const { data: config } = useStatusConfig();

    const { nodes: graphNodes, edges: graphEdges } = data ?? {};
    const expandedNodeIds = graphNodes?.map(({ id }) => id) ?? [];

    const { nodes, edges } = setNodesAndEdgeVisibility(
        graphNodes ?? [],
        graphEdges ?? [],
        expandedNodeIds,
        expandedNodeIds,
        new Map<string, string[]>(),
        []
    );

    if (isLoading) {
        return <NetworkSkeleton variant='hierarchical' />;
    }

    return (
        <div id='globalMap' className='relative w-full h-full'>
            <div className='absolute inset-0 w-full'>
                <NetworkMapStoreProvider
                    key={stringify({
                        ...config,
                        viewSettings: {
                            downstream: config?.viewSettings.downstream
                        }
                    })}
                    mapId='globalMap'
                    layoutType={LayoutTypes.hierarchyVertical}
                    nodes={nodes ?? []}
                    edges={edges ?? []}
                    graphNodes={graphNodes ?? []}
                    graphEdges={graphEdges ?? []}
                    background='tileBackground'
                    expandedNodeIds={expandedNodeIds}
                    pinnedNodeIds={expandedNodeIds}
                    ungroupedNodeIds={[]}
                    pinnedGroupsWithMemberIds={new Map<string, string[]>()}
                >
                    <ReactFlowProvider key='mapReactFlow'>
                        <LayoutFlow key='mapReactFlowLayout' hideCollapseButton={true} />
                    </ReactFlowProvider>
                </NetworkMapStoreProvider>
            </div>
        </div>
    );
};
