import { ADMINISTRATION, DETAIL, LIST } from './common';

// Tenant is part of the root key so we can invalidate it when
// tier updates occur

const allKey = 'types';

export const customTypesQueryKeys = {
    all: [allKey],
    list: [allKey, LIST],
    forAdministration: [allKey, ADMINISTRATION],
    detail: (customTypeId: string) => [allKey, DETAIL, customTypeId]
};
