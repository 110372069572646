import { cn } from '@/lib/cn';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { ReactNode, forwardRef } from 'react';

interface SwitchProps {
    options: {
        value: string;
        label: ReactNode;
    }[];
    className?: string;
    itemClassName?: string;
    value?: string;
    defaultValue?: string;
    disabled?: boolean;
    onValueChange?: (value: string) => void;
}

export const Switch = forwardRef<React.ElementRef<typeof ToggleGroup.Root>, SwitchProps>(
    ({ options, className, itemClassName, value, defaultValue, disabled, onValueChange }, ref) => (
        <ToggleGroup.Root
            ref={ref}
            type='single'
            value={value}
            defaultValue={defaultValue}
            className={cn(
                'ring-1 inline-flex items-center w-max flex-row ring-inset text-textSecondary ring-outlinePrimary rounded-input bg-componentBackgroundPrimary p-xxxs disabled:pointer-events-none',
                className
            )}
            onValueChange={onValueChange}
            disabled={disabled}
        >
            {options.map(({ value: itemValue, label }) => (
                <ToggleGroup.Item
                    key={itemValue}
                    value={itemValue}
                    className={cn(
                        'py-smallButton px-xs leading-input rounded-input flex items-center data-[state=on]:bg-dividerPrimary data-[state=on]:text-textPrimary',
                        itemClassName,
                        disabled && 'text-textDisabled'
                    )}
                >
                    {label}
                </ToggleGroup.Item>
            ))}
        </ToggleGroup.Root>
    )
);
