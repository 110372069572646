import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import type { ObjectPermissionsModel } from 'dynamo-wrapper';
import { useDatasourceConfigsForLinking } from 'queries/hooks/useDatasourceConfigsForLinking';
import { useWorkspacesForLinking } from 'queries/hooks/useWorkspacesForLinking';
import { useQuery } from 'react-query';
import { AccessControlQueryKeys, GetEntityTypePermissions } from 'services/AccessControlService';

const querySettings = {
    refetchOnMount: 'always' as const // Just in case another user/browser has modified the permissions or workspace links
};

interface WorkspaceLinkData {
    id: string;
    data: {
        links: {
            plugins: string[];
            workspaces: string[];
        };
    };
}

export const useWorkspaceLinks = (workspace?: WorkspaceLinkData) => {
    const isNewWorkspace = !workspace;

    const {
        data: plugins = [],
        isLoading: isLoadingPlugins,
        isFetching: isFetchingPlugins
    } = useDatasourceConfigsForLinking(querySettings);

    const {
        data: workspaces = [],
        isLoading: isLoadingWorkspaces,
        isFetching: isFetchingWorkspaces
    } = useWorkspacesForLinking(workspace?.id, querySettings);

    const { data: workspacePermissions, isLoading: isLoadingWorkspacePermissions } = useQuery(
        AccessControlQueryKeys.EntityTypePermissions('space'),
        () => GetEntityTypePermissions('space'),
        querySettings
    );

    const isLoading =
        isLoadingWorkspaces ||
        isLoadingPlugins ||
        isFetchingPlugins ||
        isFetchingWorkspaces ||
        isLoadingWorkspacePermissions;

    const pluginOptions = plugins?.map((plugin) => ({
        label: plugin.displayName as string,
        value: plugin.id as string
    }));

    const workspaceOptions = workspaces
        ?.filter((ws: any) => ws.id !== workspace?.id)
        .map((ws: any) => ({
            label: ws.displayName as string,
            value: ws.id as string
        }));

    function getDefaultLinkedPlugins() {
        return isNewWorkspace
            ? []
            : pluginOptions?.filter((option) => workspace?.data?.links?.plugins?.includes(option.value));
    }

    function getDefaultLinkedWorkspaces() {
        return isNewWorkspace
            ? getOptionsForObjectsAccessibleToEveryone(workspaceOptions ?? [], workspacePermissions ?? [])
            : workspaceOptions?.filter((option) => workspace?.data?.links?.workspaces?.includes(option.value));
    }

    function getOptionsForObjectsAccessibleToEveryone(
        options: AutocompleteOption[],
        allObjectPerms: ObjectPermissionsModel[]
    ) {
        return options.filter((option) => {
            const objectPerms = allObjectPerms.find((objectPerm) => objectPerm.objectId === option.value);
            return objectPerms?.everyonePermissions?.length;
        });
    }

    return {
        isNewWorkspace,
        pluginOptions,
        workspaceOptions,
        getDefaultLinkedPlugins,
        getDefaultLinkedWorkspaces,
        isLoading
    };
};
