import { AccordionContent, AccordionTrigger, useAccordionControls } from '@/components/Accordion';
import { Button, buttonVariants } from '@/components/Button';
import { Divider } from '@/components/Divider';
import { InfoTip } from '@/components/InfoTip';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import * as Accordion from '@radix-ui/react-accordion';
import { isNoData } from '@squaredup/data-streams';
import { Chevron } from 'components/Chevron';
import LoadingSpinner from 'components/LoadingSpinner';
import NoDataPlaceholder from 'components/NoDataPlaceholder';
import Tooltip from 'components/tooltip/Tooltip';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import deepEqual from 'fast-deep-equal/es6';
import { useMemo, useState, type FC } from 'react';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import { useTileEditorStepsContext } from '../../contexts/TileEditorStepsContext';
import { getFilterFormDefaultValues } from '../../utilities/filters';
import { toFormData } from '../../utilities/grouping';
import { getSortingColumns, getSortingFormDefaultValues } from '../../utilities/sorting';
import { FilteringForm } from './shaping/FilteringForm';
import { GroupingForm } from './shaping/GroupingForm';
import { SortingForm } from './shaping/SortingForm';

export const ShapingStep: FC = () => {
    const { canMoveToNextStep, nextStep } = useTileEditorStepsContext();
    const { isDatasetMode } = useTileEditorContext();
    const { config, activeDataset, setConfig } = useDatasetContext();
    const { data, isLoading, isLoaded, isConfigured } = useDataStreamConfig(config, { keepPreviousData: true });

    const [validPanels, setValidPanels] = useState<string[]>(['filtering', 'grouping', 'sorting']);

    const isPanelValid = (panelName: string) => {
        return validPanels.includes(panelName);
    };

    const initialPanelState = useMemo(
        () => [
            { name: 'filtering', isOpen: (config.dataStream?.filter?.filters?.length ?? 0) > 0 },
            {
                name: 'grouping',
                isOpen:
                    (config.dataStream?.group?.aggregate?.length ?? 0) > 0 ||
                    (config.dataStream?.group?.by?.length ?? 0) > 0
            },
            { name: 'sorting', isOpen: (config.dataStream?.sort?.by?.length ?? 0) > 0 }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const accordionControls = useAccordionControls({
        type: 'multiple',
        initialPanelState,
        canCollapse: isPanelValid
    });

    const { columns: groupedColumns, ungrouped, sortable } = { ungrouped: [], ...data?.metadata };
    const ungroupedColumns = ungrouped && ungrouped.length > 0 ? ungrouped : undefined;
    const columns = ungroupedColumns || groupedColumns;

    if (!isLoaded && isLoading) {
        // Show loading spinner until first data load is complete
        return (
            <div className='flex flex-col h-full px-8 py-6 text-sm'>
                <div className='flex-1 space-y-4'>
                    <div className='flex items-center justify-center h-full'>
                        <LoadingSpinner />
                    </div>
                </div>
            </div>
        );
    }

    if (!isConfigured) {
        return (
            <div className='h-full p-sm'>
                <NoDataPlaceholder message='Shaping will be available after selecting objects and/or configuring parameters.' />
            </div>
        );
    }

    return (
        <div className='flex flex-col h-full px-6 pr-0 overflow-auto py-7'>
            <Text.H3 className='mb-2 text-textPrimary'>Shaping</Text.H3>
            <Text.Body className='mb-2'>
                Shaping allows you to filter, group or sort the data. This can be useful to shape data into the format
                needed for different visualizations.{' '}
                <a
                    className={buttonVariants({ variant: 'link' })}
                    href='https://squaredup.com/cloud/shaping'
                    target='_blank'
                    rel='noreferrer'
                >
                    Learn more.
                </a>
            </Text.Body>

            <div
                key={`dataset=${activeDataset}`}
                className='relative flex flex-col items-start w-full pr-5 mt-2 overflow-auto text-sm scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'
            >
                <div className='w-full max-w-4xl'>
                    <Accordion.Root {...accordionControls} className='flex flex-col w-full max-w-4xl'>
                        <Accordion.Item value='filtering'>
                            <Tooltip title='Invalid filter' disabled={isPanelValid('filtering')}>
                                <AccordionTrigger
                                    className={cn('w-full py-4', {
                                        'cursor-not-allowed': !isPanelValid('filtering')
                                    })}
                                >
                                    <Text.H4 className='text-textPrimary'>Filter</Text.H4>
                                    <Chevron
                                        initialDirection={
                                            initialPanelState.find((p) => p.name === 'filtering')?.isOpen
                                                ? 'up'
                                                : 'down'
                                        }
                                        direction={accordionControls.value?.includes('filtering') ? 'up' : 'down'}
                                    />
                                </AccordionTrigger>
                            </Tooltip>
                            <AccordionContent className='mb-4 ml-2'>
                                <FilteringForm
                                    defaultValues={getFilterFormDefaultValues(config.dataStream?.filter, columns)}
                                    columns={columns}
                                    onChange={({ formData, isValid }) => {
                                        setValidPanels(
                                            isPanelValid('filtering') ? validPanels : ['filtering', ...validPanels]
                                        );

                                        if (!isValid) {
                                            setValidPanels(validPanels.filter((p) => p !== 'filtering'));
                                            return;
                                        }

                                        if (!deepEqual(formData, config.dataStream?.filter)) {
                                            setConfig({
                                                ...config,
                                                dataStream: {
                                                    ...config.dataStream,
                                                    filter: formData
                                                }
                                            });
                                        }
                                    }}
                                />
                            </AccordionContent>
                            <Divider />
                        </Accordion.Item>

                        <Accordion.Item value='grouping' className='mt-2'>
                            <AccordionTrigger className='w-full py-4'>
                                <Text.H4 className='text-textPrimary'>Group</Text.H4>
                                <Chevron
                                    initialDirection={
                                        initialPanelState.find((p) => p.name === 'grouping')?.isOpen ? 'up' : 'down'
                                    }
                                    direction={accordionControls.value?.includes('grouping') ? 'up' : 'down'}
                                />
                            </AccordionTrigger>
                            <AccordionContent className='mb-4 ml-2'>
                                <GroupingForm
                                    defaultValues={toFormData(config.dataStream?.group)}
                                    columns={columns}
                                    onChange={({ formData, isValid }) => {
                                        setValidPanels(
                                            isPanelValid('grouping') ? validPanels : ['grouping', ...validPanels]
                                        );

                                        if (!isValid) {
                                            setValidPanels(validPanels.filter((p) => p !== 'grouping'));
                                            return;
                                        }

                                        setConfig({
                                            ...config,
                                            dataStream: {
                                                ...config.dataStream,
                                                group: formData
                                            }
                                        });
                                    }}
                                />
                            </AccordionContent>
                            <Divider />
                        </Accordion.Item>

                        <Accordion.Item value='sorting' className='mt-2'>
                            <AccordionTrigger className='w-full py-4'>
                                <Text.H4 className='text-textPrimary'>Sort</Text.H4>
                                <Chevron
                                    initialDirection={
                                        initialPanelState.find((p) => p.name === 'sorting')?.isOpen ? 'up' : 'down'
                                    }
                                    direction={accordionControls.value?.includes('sorting') ? 'up' : 'down'}
                                />
                            </AccordionTrigger>
                            <Accordion.Content className='mb-4 ml-2'>
                                {(sortable || isNoData(data)) && (
                                    <SortingForm
                                        defaultValues={getSortingFormDefaultValues(
                                            config.dataStream?.sort,
                                            getSortingColumns(groupedColumns)
                                        )}
                                        columns={groupedColumns}
                                        onChange={({ formData, isValid }) => {
                                            setValidPanels(
                                                isPanelValid('sorting') ? validPanels : ['sorting', ...validPanels]
                                            );

                                            if (!isValid) {
                                                setValidPanels(validPanels.filter((p) => p !== 'sorting'));
                                                return;
                                            }

                                            setConfig({
                                                ...config,
                                                dataStream: {
                                                    ...config.dataStream,
                                                    sort: formData
                                                }
                                            });
                                        }}
                                    />
                                )}

                                {!sortable && !isNoData(data) && (
                                    <div className='pt-4'>
                                        <Text.Body>This datastream does not support sorting.</Text.Body>
                                    </div>
                                )}
                            </Accordion.Content>
                            <Divider />
                        </Accordion.Item>
                    </Accordion.Root>

                    {!isDatasetMode && (
                        <InfoTip icon={faQuestionCircle} className='mt-4'>
                            <Text.SmallBody>
                                For more shaping options, try SQL Analytics.{' '}
                                <a
                                    className='text-textLink'
                                    href='https://squaredup.com/cloud/sql-analytics'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    Learn more.
                                </a>
                            </Text.SmallBody>
                        </InfoTip>
                    )}
                </div>
            </div>

            <div className='flex items-end flex-1 mt-4 pr-9 md:justify-end'>
                <div>
                    <Button variant='primary' disabled={!canMoveToNextStep} onClick={nextStep}>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    );
};
