import { useDashboards } from 'queries/hooks/useDashboards';
import { useWorkspaces } from 'queries/hooks/useWorkspaces';
import { useQuery } from 'react-query';
import { DASHBOARDS } from 'services/DashboardService';
import { Query } from 'services/GraphService';

/**
 * Counts the number of workspaces, dashboards and monitors in the system
 * This is used to display different content in the status page
 * e.g an add workspace button if there aren't sufficient workspaces
 * or changing the text of the status page if there are no dashboards or monitors showing
 */
export function useDataCount() {
    const { data: workspaces = [] } = useWorkspaces();
    const { data: dashboards = [] } = useDashboards({
        select: (data) => data.filter((d) => !d.builtIn)
    });
    const { data: monitorCount = 0 } = useQuery([DASHBOARDS, 'MONITORS', 'COUNT'], () =>
        Query({
            gremlinQuery: 'g.V().has("sourceType", "squaredup/monitor").count()',
            workspaceId: null
        }).then((res) => res.gremlinQueryResults[0])
    );

    return {
        workspaceCount: workspaces.length,
        dashboardCount: dashboards.length,
        monitorCount
    };
}
