import { Datum } from '@nivo/line';
import { motion } from 'framer-motion';
import { getOpacityFn } from './styles';
import { spring } from './transition';

interface LineGraphAreaProps {
    lineId: string | number;
    hoverId: string | number | undefined;
    fill: string;
    areaOpacity: number;
    points: Record<'x' | 'y', number>[];
    areaGenerator: (data: Datum[]) => string;
}

/**
 *
 * @returns gradient fill layer when enableArea is selected
 */
export const LineGraphArea: React.FC<LineGraphAreaProps> = ({
    fill,
    areaOpacity,
    lineId,
    hoverId,
    points,
    areaGenerator
}) => {
    const areaPath = areaGenerator(points);

    const opacity = getOpacityFn(areaOpacity, 0.05);

    return (
        <motion.path
            fill={fill}
            animate={{
                opacity: opacity(lineId, hoverId),
                d: areaPath
            }}
            transition={spring}
            strokeWidth={0}
            pointerEvents='none'
        />
    );
};
