import Modal, { CloseHandler, ifNotOutside } from 'components/Modal';
import { useWorkspaceDetailedPermissions } from 'pages/settings/workspaces/useWorkspaceDetailedPermissions';
import { PropsWithChildren, createContext, useContext, useState, type ComponentPropsWithoutRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { type Workspace } from 'services/WorkspaceService';
import { TabOption, Tabs } from 'ui/Tabs';
import ErrorFallback from 'ui/errorHandling/ErrorFallback';
import { z } from 'zod';
import { EditWorkspaceForm } from './EditWorkspaceForm';
import { WorkspaceModalAccessControlTab } from './WorkspaceModalAccessControlTab';
import { WorkspaceModalAdvancedTab } from './WorkspaceModalAdvancedTab';
import { WorkspaceModalGeneralTab } from './WorkspaceModalGeneralTab';
import { usePrefetchEditWorkspaceModalQueries } from './usePrefetchWorkspaceModalQueries';

type EditWorkspaceModalProps = {
    workspace: Workspace;
    close: CloseHandler;
    startingTab?: StartingTabOption;
};

export const EditWorkspaceModal = (props: EditWorkspaceModalProps) => {
    const { workspace, close, startingTab = startingTabOptions[0] } = props;
    const [selectedTabIndex, setSelectedTabIndex] = useState(tabs.findIndex((tab) => tab.key === startingTab));

    const editWorkspaceTabs = useEditWorkspaceTabs({ workspace });

    usePrefetchEditWorkspaceModalQueries({ workspace });

    return (
        <Modal
            title={`Edit workspace: ${workspace.displayName}`}
            close={ifNotOutside(close)}
            modalClassName='w-[700px] h-[700px] flex flex-col'
        >
            <EditWorkspaceModalProvider workspace={workspace} close={close}>
                <EditWorkspaceForm>
                    <Tabs
                        tabs={editWorkspaceTabs}
                        tabsID='visualizationTabs'
                        selectedIndex={selectedTabIndex}
                        onSelect={setSelectedTabIndex}
                    />
                </EditWorkspaceForm>
            </EditWorkspaceModalProvider>
        </Modal>
    );
};

const ContentContainer = (props: ComponentPropsWithoutRef<'div'>) => {
    return <div {...props} className='p-6 tile-scroll-overflow' />;
};

const GeneralTab = () => {
    return (
        <ContentContainer>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <WorkspaceModalGeneralTab modalType='edit-workspace' />
            </ErrorBoundary>
        </ContentContainer>
    );
};

const AccessControlTab = () => {
    const { workspace } = useEditWorkspaceModalContext();

    return (
        <ContentContainer>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <WorkspaceModalAccessControlTab modalType='edit-workspace' workspace={workspace} />
            </ErrorBoundary>
        </ContentContainer>
    );
};

const AdvancedTab = () => {
    const modalCtx = useEditWorkspaceModalContext();

    return (
        <ContentContainer>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <WorkspaceModalAdvancedTab workspace={modalCtx.workspace} />
            </ErrorBoundary>
        </ContentContainer>
    );
};

const startingTabOptions = ['general', 'access-control', 'advanced'] as const;
export const startingTabSchema = z.enum(startingTabOptions).default('general').catch('general');
type StartingTabOption = z.infer<typeof startingTabSchema>;

const tabs = [
    {
        label: 'General',
        component: <GeneralTab />,
        key: startingTabOptions[0],
        className: 'px-8 py-4'
    },
    {
        label: 'Access Control',
        component: <AccessControlTab />,
        key: startingTabOptions[1],
        className: 'px-8 py-4'
    },
    {
        label: 'Advanced',
        component: <AdvancedTab />,
        key: startingTabOptions[2],
        className: 'px-8 py-4'
    }
] as const satisfies TabOption[];

const useEditWorkspaceTabs = (props: Pick<EditWorkspaceModalProps, 'workspace'>) => {
    const workspaceDetailedPermissions = useWorkspaceDetailedPermissions(props.workspace);

    return tabs.filter((tab) => {
        if (tab.label === 'Access Control' && !workspaceDetailedPermissions.canConfigureAccessControl) {
            return false;
        }

        return true;
    });
};

type EditWorkspaceModalContextType = EditWorkspaceModalProps;

export const EditWorkspaceModalContext = createContext<EditWorkspaceModalContextType | null>(null);

export const EditWorkspaceModalProvider = (props: PropsWithChildren<object> & EditWorkspaceModalProps) => {
    return (
        <EditWorkspaceModalContext.Provider value={{ close: props.close, workspace: props.workspace }}>
            {props.children}
        </EditWorkspaceModalContext.Provider>
    );
};

export const useEditWorkspaceModalContext = () => {
    const context = useContext(EditWorkspaceModalContext);

    if (!context) {
        throw new Error('useWorkspaceModalContext needs to be used in a WorkspaceModalContext.Provider');
    }

    return context;
};
