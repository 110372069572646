import type { UserGroupModel } from 'dynamo-wrapper';
import { UseQueryOptions, useQuery } from 'react-query';
import { AccessControlQueryKeys } from 'services/AccessControlService';
import { GetGroups } from 'services/UserService';

type UseEveryoneGroupProps = Omit<
    UseQueryOptions<UserGroupModel[], unknown, UserGroupModel | undefined, string>,
    'queryKey' | 'queryFn' | 'select'
>;

export const useEveryoneGroup = (props?: UseEveryoneGroupProps) => {
    return useQuery({
        ...props,
        queryKey: AccessControlQueryKeys.UserGroups,
        queryFn: GetGroups,
        select: (data) => {
            return data?.find((group) => group.name === 'everyone');
        },
        // Everyone group never changes
        staleTime: Number.POSITIVE_INFINITY,
        cacheTime: Number.POSITIVE_INFINITY
    });
};
