import _ from 'lodash';
import React from 'react';
import { TileError } from 'ui/tile/TileError';
import { warningMessages } from 'ui/tile/TileWarnings';

interface DataStreamErrorsProps {
    error: any;
    showDetailedErrors?: boolean;
}

export const DataStreamErrors: React.FC<DataStreamErrorsProps> = ({ error, showDetailedErrors = true }) => {
    const errors = error?.response?.data?.errors || (error.message ? [error.message] : null) || [_.toString(error)];

    const reason = errors?.[0];
    const errorMessage = reason ? warningMessages.find((w) => reason.startsWith(w.message)) : null;

    return (
        <TileError
            heading={
                <>
                    This tile failed to load
                    {showDetailedErrors && <span>: {errorMessage?.heading || 'Unexpected error'}</span>}
                </>
            }
            articleAnchor={errorMessage?.heading?.toLowerCase().replace(/ /gu, '-')}
            detail={errorMessage?.message || reason}
            showDetail={showDetailedErrors}
        />
    );
};
