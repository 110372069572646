import { fiveMinutes } from 'queries/constants';
import { datasourceConfigQueryKeys } from 'queries/queryKeys/datasourceConfigKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { ListPluginSourceConfigsForAdmin } from 'services/SourceConfigService';

type QueryReturnType = Awaited<ReturnType<typeof ListPluginSourceConfigsForAdmin>>;
type QuerySelectReturnType = QueryReturnType;

/**
 * useQuery returning the data source configs for administration within the tenant
 * @param options The react-query options e.g. refreshInterval
 * @returns Data source configs for administration
 */
export const useDatasourceConfigsForAdministration = <T = QuerySelectReturnType>(
    options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>
) => 
    useQuery(
        datasourceConfigQueryKeys.forAdministration, 
        ListPluginSourceConfigsForAdmin, 
        {
            staleTime: fiveMinutes,
            cacheTime: fiveMinutes,
            keepPreviousData: true,
            ...options
        }
    );
