import { cn } from '@/lib/cn';
import useSize from '@react-hook/size';
import React, { useState } from 'react';
import { gridResponsiveClassName } from './common';

// Magic number that's two rows + gap + margin in the Datsources component
const expandedThreshold = 196;

interface ShowAllProps {
    initiallyExpanded?: boolean;
}

export const ShowAll: React.FC<ShowAllProps> = ({ initiallyExpanded, children }) => {
    const [isExpanded, setIsExpanded] = useState(Boolean(initiallyExpanded));
    const [element, ref] = useState<HTMLOListElement | null>(null);

    const [, height] = useSize(element);
    const showAll = () => setIsExpanded(current => !current);

    return (
        <div className='mt-2'>
            <div 
                className={cn('overflow-hidden', !isExpanded && 'max-h-[196px]')}
            >
                <ol
                    ref={ref}
                    className={gridResponsiveClassName}
                >
                    {children}
                </ol>
            </div>

            {height > expandedThreshold && (
                <div className='flex items-center justify-center mt-4'>
                    <button onClick={showAll} className='text-textLink'>
                        {isExpanded ? 'Show less' : 'Show all'}
                    </button>
                </div>
            )}
        </div>
    );
};
