import Button from 'components/button';
import PluginContext from 'contexts/PluginContext';
import { useContext, useState } from 'react';
import { useWatch } from 'react-hook-form';
import Field, { FormFieldProps } from '../Field';

interface TestResult {
    state: 'notRun' | 'pending' | 'received';
    result?: any;
}

type FieldTestOutputProps = FormFieldProps & {
    name: string;
    testName?: string;
    testButtonName?: string;
}

export const FieldTestOutput: React.FC<FieldTestOutputProps> = ({ testName, testButtonName, ...rest }) => {
    const pluginContext = useContext(PluginContext);

    const [latestTestResult, setLatestTestResult] = useState<TestResult>();

    const formData = useWatch();

    const getTestEventHandler = () => {
        const testFunction = pluginContext.testFunction;
        if (!testFunction) {
            return undefined;
        }

        if (!testName) {
            return undefined;
        }

        return async () => {
            setLatestTestResult({ state: 'pending' });

            let testResult;
            try {
                testResult = await testFunction(testName, formData);
            } catch (err: any) {
                setLatestTestResult({ state: 'received', result: { result: { messages: [{ status: 'error', message: err.message }] } } });
                return;
            }

            setLatestTestResult({ state: 'received', result: testResult });
        };
    };

    // Test result fields for field groups need to be prefixed by the button to run the test
    const testEventHandler = testName ? getTestEventHandler() : undefined;
    const prefixComponent = testName && testEventHandler && (
        <div>
            <Button className='mt-8' onClick={testEventHandler}>
                {testButtonName ?? 'Test'}
            </Button>
        </div>
    );

    return (
        <>
            {prefixComponent}
            <Field.Input {...rest} testResult={latestTestResult} />
        </>
    );
};
