import Button from 'components/button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import { useMutation, useQueryClient } from 'react-query';
import { CUSTOM_CORRELATIONS, Delete, ProjectedCustomCorrelation } from 'services/CustomCorrelationsService';

type CustomCorrelationsDeleteModalProps = {
    customCorrelation: ProjectedCustomCorrelation;
    onClose: () => void;
};

/**
 * Modal for confirming deletion of a custom correlation rule
 * @param {object} rule Rule to be deleted
 * @param {Function} onClose Handler invoked when cancelling/closing
 */
function CustomCorrelationsDeleteModal({ customCorrelation, onClose }: CustomCorrelationsDeleteModalProps) {
    const queryClient = useQueryClient();

    const { mutate: handleDelete, isLoading, isError } = useMutation(
        async () => {
            return Delete(customCorrelation.id);
        },
        {
            onSettled: async () => {
                await queryClient.invalidateQueries([CUSTOM_CORRELATIONS]);
            },
            onSuccess: async () => {
                onClose();
            }               
        }
    );

    const description =
        'Are you sure you want to delete this correlation rule? Associations created by this rule will be removed shortly after deletion.';

    return (
        <Modal
            title={`Delete Correlation Rule: ${customCorrelation.displayName}`}
            description={description}
            close={onClose}
            fullWidth
            maxWidth='max-w-3xl'
        >
            {isError && <p className='text-statusErrorPrimary'>An error occurred, please try again.</p>}

            <ModalButtons>
                <Button variant='tertiary' onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    variant='destructive'
                    type='submit'
                    onClick={handleDelete}
                    disabled={isLoading}
                    data-testid='delete-correlation'
                >
                    {isLoading ? <LoadingSpinner size={18} /> : 'Delete'}
                </Button>
            </ModalButtons>
        </Modal>
    );
}

export default CustomCorrelationsDeleteModal;
