import { cn } from '@/lib/cn';
import { Visualisation } from 'dashboard-engine/types/Visualisation';
import { IframeConfig } from './Config';

const IFrame: Visualisation<any, IframeConfig> = ({ config }) => {
    if (!config.src) {
        throw new Error('A URL must be specified');
    }

    const styles = {
        border: 'none',
        width: `${100 / (config.scale || 1)}%`,
        height: `${100 / (config.scale || 1)}%`,
        margin: 0,
        padding: 0,
        transform: `scale(${config.scale || 1})`,
        transformOrigin: '0 0'
    };

    return (
        <LinkWrapper config={config}>
            <iframe
                src={config.src}
                title={'Embed'}
                style={styles}
                className={cn({
                    'pointer-events-none': config.openOnClick
                })}
            ></iframe>
        </LinkWrapper>
    );
};

/**
 * Optionally wraps the children in a link based on the given config
 */
const LinkWrapper: React.FC<{ config: IframeConfig }> = ({ config, children }) => {
    if (config.openOnClick) {
        return (
            <a target='_blank' rel='noopener noreferrer' className='block h-full overflow-hidden' href={config.src}>
                {children}
            </a>
        );
    } else {
        return <div className='h-full overflow-hidden'>{children}</div>;
    }
};

IFrame.config = 'IframeConfig';

export default IFrame;
