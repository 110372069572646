import * as Constants from '@squaredup/constants';
import { ConfigId, Serialised } from '@squaredup/ids';
import type { Config } from 'dynamo-wrapper';
import { PluginConfigFormData } from '../types';

export function getEditMode(config: Serialised<Config<Omit<PluginConfigFormData, 'displayName'>>> | undefined) {
    const globalConfigId = new ConfigId(Constants.GlobalConfigId).value;

    if (config) {
        if (config.isDraft && config.baseIdString === globalConfigId) {
            return 'newWithDraft';
        }
        return 'existing';
    }
    return 'new';
}
