import dataSourceRepo from 'dashboard-engine/repositories/datasourceRepo';
import tileRepo from 'dashboard-engine/repositories/tileRepo';
import { dataStreamVisualisationRepo, visualisationsRepo } from '../repositories/visualisationsRepo';
import generated from './schema.json';

/**
 * Construct the tile schema from the available tiles/vis/datasources
 */
export const construct = async () => {
  const configs = await getTileConfigs();
  const visualisations = await getFromRepo(visualisationsRepo);
  const datasources = await getFromRepo(dataSourceRepo);
  const dataStreamVisualisation = await getFromRepo(dataStreamVisualisationRepo);

  // Create allOf blocks for each available tile
  const allOfs = Object.entries(configs).reduce((acc, [name, [visualisation, datasource]]) => {
    if (name && (visualisation || datasource)) {
      acc.push(createAllOf('_type', `tile/${name}`, visualisation, datasource));
    }
    return acc;
  }, []);

  // Add allOf block for dataStream tile
  allOfs.push(createCustomVisualisationBlock('tile/data-stream', dataStreamVisualisation));

  // Add allOf block for custom tiles
  allOfs.push(createCustomBlock(visualisations, datasources));

  // Build the overall schema
  const schema = {
    '$schema': 'http://json-schema.org/draft-07/schema#',
    'definitions': generated.definitions,
    'type': 'object',
    'required': ['_type'],
    'properties': {
      '_type': {
        'enum': tileRepo.list().map(t => `tile/${t}`), // list of all possible tiles
        'type': 'string'
      },
      'title': {
        'type': 'string'
      },
      'description': {
        'type': 'string'
      }
    },
    'allOf': allOfs
  };

  return schema;

};

/**
 * For each tile, get the name of the config type for both the
 * visualization and the datasource
 * e.g. TableConfig and AwsCostConfig
 */
const getTileConfigs = async () => {
  //Get all tiles and their configs
  const tiles = await Promise.all(tileRepo.list().map(async t => {
    const tile = tileRepo.get(t).tile;
    const { default: config } = await import(`../tiles/${tile}.ts`);
    return [t, config];
  }));

  // Reduce over the tiles, and select the visualisation and datasource configs
  return tiles.reduce((acc, [name, config]) => {
    acc[name] = [config.visualisation?.type?.config, config.datasource?.type?.config];
    return acc;
  }, {});
};

/**
 * Get all items and their configs as tuples from the given repository
 * @param {Repository} repo Target
 */
const getFromRepo = async (repo) => {
  const all = await Promise.all(repo.list().map(async k => {
    const item = repo.get(k);
    return [k, item.config];
  }));

  return all.filter(([, v]) => Boolean(v));
};

/**
 * Construct an allOf block for the given tile and datasource
 * @param {string} name Tile name
 * @param {string} visConfig Visualisation config type name
 */
const createAllOf = (ifName, ifValue, visConfig, dsConfig) => ({
  'if': {
    'properties': {
      [ifName]: {
        'const': ifValue
      }
    }
  },
  'then': {
    'properties': {
      ...(visConfig && ({
        'visualisation': {
          'type': 'object',
          'properties': {
            'config': {
              '$ref': `#/definitions/${visConfig}`
            }
          }
        }
      })),
      ...(dsConfig && ({
        'datasource': {
          'type': 'object',
          'properties': {
            'config': {
              '$ref': `#/definitions/${dsConfig}`
            }
          }
        }
      }))
    }
  }
});

/**
 * Construct a condition block using the given type and definition name
 * @param {string} type Value of type property
 * @param {string} definition Definition name
 */
const createConditionBlock = (type, definition) => {
  return {
    'if': {
      'properties': {
        'type': {
          'const': type
        }
      }
    },
    'then': {
      'properties': {
        'config': {
          '$ref': `#/definitions/${definition}`
        }
      }
    }
  };
};

/**
 * Create an allOf block to support custom tiles, varying the condition based on 
 * names of visualisation and datasource chosen.
 * @param {Record<string,string>} visualisations Visualisation names -> config names
 * @param {Record<string,string>} datasources Datasource names -> config names
 */
const createCustomBlock = (visualisations, datasources) => {
  const vis = visualisations.map(([name, config]) => createConditionBlock(name, config));
  const ds = datasources.map(([name, config]) => createConditionBlock(name, config));

  return {
    'if': {
      'properties': {
        '_type': {
          'const': 'tile/custom'
        }
      }
    },
    'then': {
      'required': ['visualisation', 'datasource'],
      'properties': {
        'visualisation': {
          'type': 'object',
          'required': ['type'],
          'properties': {
            'config': {
              'type': 'object'
            },
            'type': {
              'type': 'string'
            }
          },
          'allOf': vis
        },
        'datasource': {
          'type': 'object',
          'required': ['type'],
          'properties': {
            'config': {
              'type': 'object'
            },
            'type': {
              'type': 'string'
            }
          },
          'allOf': ds
        }
      }
    }
  };
};


/**
 * Create an allOf block to support multiple visualisations
 * @param {Record<string,string>} visualisations Visualisation names -> config names
 */
const createCustomVisualisationBlock = (tileName, visualisations) => {
  const vis = visualisations.map(([name, config]) => createConditionBlock(name, config));

  return {
    'if': {
      'properties': {
        '_type': {
          'const': tileName
        }
      }
    },
    'then': {
      'properties': {
        'visualisation': {
          'type': 'object',
          'properties': {
            'config': {
              'type': 'object'
            },
            'type': {
              'type': 'string'
            }
          },
          'allOf': vis
        }
      }
    }
  };
};