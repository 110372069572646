import { TooltipButton } from '@/components/Button';
import { faLocationPin, faLocationPinSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetPinnedNodeIds } from 'components/map/context/NetworkMapStoreContext';
import { NodeAction, useHandleNodeAction } from 'components/map/hooks/useHandleNodeAction';

interface PinButtonProps {
    id: string;
    isPinned?: boolean;
    className?: string;
}

export const PinButton: React.FC<PinButtonProps> = ({ id, isPinned, className }) => {
    const handleNodeAction = useHandleNodeAction();
    const getPinnedNodesIds = useGetPinnedNodeIds();

    return (
        <TooltipButton
            variant='tertiary'
            onClick={(e) => {
                e.stopPropagation();
                handleNodeAction(NodeAction.pin, { nodeIds: [id] });
            }}
            disabled={getPinnedNodesIds().length === 1 && isPinned}
            title={isPinned ? 'Unpin' : 'Pin'}
            className={className}
        >
            <FontAwesomeIcon icon={isPinned ? faLocationPin : faLocationPinSlash} fixedWidth />
        </TooltipButton>
    );
};
