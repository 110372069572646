import { ClientDataStreamRequest } from '@squaredup/data-streams';
import { Serialised } from '@squaredup/ids';
import { defaultTimeframeEnum, getTimeframe } from '@squaredup/timeframes';
import {
    AutoCompleteField,
    FieldDataSourceConfig,
    FieldReference,
    FieldReferenceSchema,
    matchSpec,
    Result,
    UIConfig
} from '@squaredup/utilities';
import type { ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import stringify from 'fast-json-stable-stringify';
import { get, has, mapValues, orderBy } from 'lodash';
import { __, match } from 'ts-pattern';
import {
    AutocompleteOption,
    AutocompleteOptions,
    ensureLabel,
    getAutoCompleteLabelText,
    isAutocomplete,
    isAutocompleteOption,
    OptionsLoader,
    valuesToOptions
} from './autocompleteOptions';
import { nullOptionsLoader, readStreamAsAutocompleteOptions } from './streamDataAutocompleteOptions';

type ResolvedOptions = {
    /**
     * The options or options loader function.
     */
    options: AutocompleteOptions;
    defaultValue: AutocompleteOption[];
    /**
     * A key based on the dependencies used to load the options.
     */
    optionsKey: string;
    /**
     * The message to display in the dropdown when no options are available.
     * (Usually a prompt to search)
     */
    emptyMessage?: string;
};

/**
 * An autocomplete field which has options that can be passed to an autocomplete component
 * instead of the `data` config provided by the UI config.
 */
type ResolvedAutocompleteField = Omit<AutoCompleteField, 'data' | 'defaultValue'> & {
    options: AutocompleteOptions;
    defaultValue?: AutocompleteOption | AutocompleteOption[];
    noOptionsMessage?: () => string;
};

/**
 * UI config where the autocomplete fields have options that can be passed to an autocomplete
 * component instead of the `data` config provided by the UI config.
 */
export type ResolvedUIConfig = Exclude<UIConfig, AutoCompleteField> | ResolvedAutocompleteField;

const resolveFieldReferenceValue = (referencedValue: unknown, reference: FieldReference): Result<unknown> => {
    if (Array.isArray(referencedValue)) {
        // Empty multiselect autocompletes are empty arrays, so they count as 'not set'
        if (referencedValue.length === 0) {
            return Result.fail({ reason: 'Field is not set', reference, referencedValue });
        }

        return Result.flatten(referencedValue.map((v) => resolveFieldReferenceValue(v, reference)));
    }

    if (reference.select) {
        if (!has(referencedValue, reference.select)) {
            return Result.fail({ reason: 'Specified property does not exist', reference, referencedValue });
        }
        return get(referencedValue, reference.select);
    }

    // Extract value strings from referenced autocomplete fields automatically
    if (isAutocompleteOption(referencedValue)) {
        return Result.success(referencedValue.value);
    }

    if (referencedValue == null) {
        return Result.fail({ reason: 'Field is not set', reference, referencedValue });
    }

    return Result.success(referencedValue);
};

const resolveFieldReferences = (fieldValues: Record<string, unknown>, dataSourceConfig: FieldDataSourceConfig) => {
    if (dataSourceConfig == null) {
        return { resolvedValues: {}, isValid: true };
    }

    let isValid = true;

    const resolvedPairs = Object.entries(dataSourceConfig).map(([k, v]) => {
        const parseResult = FieldReferenceSchema.safeParse(v);

        if (!parseResult.success) {
            return [k, v];
        }

        const reference = parseResult.data;
        const referencedValue = fieldValues[reference.fieldName];

        const resolveResult = resolveFieldReferenceValue(referencedValue, reference);

        if (resolveResult.failed) {
            if (reference.required) {
                isValid = false;
            }

            return [k, undefined];
        }

        return [k, resolveResult.value];
    });

    return { resolvedValues: Object.fromEntries(resolvedPairs), isValid };
};

/**
 * Create a function that can be used with resolveAutocompleteOptions which
 * builds the required request object by finding the data stream in the provided
 * list.
 * @param dataStreams The complete list of available data streams
 * @param config The scope and/or plugin config Id to include in the request
 * @param pluginId The Id of the plugin containing the data stream to load autocomplete options for
 */
export const buildRequestDefaults =
    (
        dataStreams: Serialised<ProjectedDataStreamDefinitionEntity>[],
        config: Pick<ClientDataStreamRequest, 'scope' | 'pluginConfigId' | 'context'>,
        pluginId: string
    ) =>
    (dataStreamName: string) => {
        const dataStream = dataStreams.find(
            // Because we match on name, we only allow querying of data streams
            // in the same plugin as the template we need the autocomplete options for
            (ds) => (ds.parentPluginId as unknown as string) === pluginId && ds.definition.name === dataStreamName
        )?.id;

        if (!dataStream) {
            throw new Error('Datastream not found');
        }

        return {
            ...config,
            dataStreamId: dataStream
        };
    };

/**
 * Resolve the `data` config on an autocomplete field so it can be
 * rendered by DisplayJsonUi.
 * @param getRequestDefaults A function which returns the scope (for data streams that require one)
 * or pluginConfigId (for streams that do not require a scope),
 * appropriate for the context that the autocomplete will be rendered in
 * @param fieldDataConfig The data config of the autocomplete field
 * @param selectedValues Any values that should be selected when the field is initially displayed
 * @param fieldValues Current values of other fields that can be referenced by the fields being resolved
 */
export const resolveAutocompleteOptions = (
    getRequestDefaults: (
        dataStreamName: string
    ) => Pick<ClientDataStreamRequest, 'scope' | 'pluginConfigId' | 'dataStreamId' | 'context'>,
    fieldDataConfig: AutoCompleteField['data'],
    selectedValues: unknown | AutocompleteOption | AutocompleteOption[] | string | string[],
    fieldValues: Record<string, unknown>,
    defaultValues?: unknown | AutocompleteOption | AutocompleteOption[] | string | string[]
): ResolvedOptions => {
    if (fieldDataConfig == null && selectedValues == null && defaultValues == null) {
        return { options: [], defaultValue: [], optionsKey: 'noOptions' };
    }

    /**
     * Options intially selected when the autocomplete loads
     */
    const defaultOptions: AutocompleteOption[] = valuesToOptions(selectedValues || defaultValues)
        .map(ensureLabel)
        .filter((value) => value.label !== '' && value.value !== '');

    const configuredOptionsAndGroups = match(fieldDataConfig)
        .with(__.nullish, () => [])
        .with({ source: 'none' }, () => [])
        .with({ source: 'fixed' }, ({ values }) =>
            values.map((v) => {
                if ('options' in v) {
                    return { ...v, options: v.options.map(ensureLabel) };
                }

                return ensureLabel(v);
            })
        )
        .with(
            { source: 'dataStream' },
            ({
                dataStreamName,
                dataSourceConfig,
                search = { type: 'client' as const }
            }): { loader: OptionsLoader; optionsKey: string; emptyMessage?: string } => {
                const { resolvedValues, isValid: referencesAreValid } = resolveFieldReferences(
                    fieldValues,
                    dataSourceConfig
                );

                if (!referencesAreValid) {
                    return {
                        loader: nullOptionsLoader,
                        optionsKey: stringify(resolvedValues)
                    };
                }

                const requestDefaults = getRequestDefaults(dataStreamName);
                // Prompt the user to search if we aren't showing any options by default
                const emptyMessage =
                    search?.type === 'server' ? search.noOptionsMessage ?? 'Type to search' : undefined;

                return {
                    loader: readStreamAsAutocompleteOptions(
                        {
                            ...requestDefaults,
                            dataSourceConfig: resolvedValues,
                            timeframe: getTimeframe(defaultTimeframeEnum),
                            options: {}
                        },
                        defaultOptions,
                        search
                    ),
                    // when set on the autocomplete field, causes options to reload when other fields are changed
                    optionsKey: stringify({ requestDefaults, resolvedValues }),
                    emptyMessage
                };
            }
        )
        .exhaustive();

    if (!Array.isArray(configuredOptionsAndGroups)) {
        return {
            options: configuredOptionsAndGroups.loader,
            optionsKey: configuredOptionsAndGroups.optionsKey,
            defaultValue: defaultOptions,
            emptyMessage: configuredOptionsAndGroups.emptyMessage
        };
    }

    const extraOptions = defaultOptions.filter(
        ({ value: v }) =>
            !configuredOptionsAndGroups.some((optionOrGroup) =>
                'options' in optionOrGroup
                    ? optionOrGroup.options.some((o) => v === o.value)
                    : optionOrGroup.value === v
            )
    );

    const allOptionsAndGroups = configuredOptionsAndGroups
        .map((v) => {
            if ('options' in v) {
                return { ...v, options: v.options.map(ensureLabel) };
            }

            return ensureLabel(v);
        })
        .concat(extraOptions);

    return {
        options: orderBy(allOptionsAndGroups, (o) => getAutoCompleteLabelText(o)?.toLowerCase()),
        defaultValue: defaultOptions,
        optionsKey: 'fixedOptions'
    };
};

export const resolveFormField = (
    formField: UIConfig,
    formData: Record<string, any>,
    streamDefinitions: Serialised<ProjectedDataStreamDefinitionEntity>[],
    config: Pick<ClientDataStreamRequest, 'scope' | 'pluginConfigId' | 'context'>,
    pluginId: string
): ResolvedUIConfig => {
    const formDataWithValues = mapValues(formData, (v) =>
        Array.isArray(v)
            ? v.map((v2) => (isAutocompleteOption(v2) ? v2.value : v2))
            : isAutocompleteOption(v)
            ? v.value
            : v
    );
    const resolvedEnabledState =
        !('disabled' in formField) || formField.disabled == null || typeof formField.disabled === 'boolean'
            ? {}
            : {
                  disabled: matchSpec(formField.disabled, formDataWithValues).succeeded
              };

    if (!isAutocomplete(formField)) {
        return { ...formField, ...resolvedEnabledState } as ResolvedUIConfig;
    }

    const resolvedOptions = resolveAutocompleteOptions(
        buildRequestDefaults(streamDefinitions, config, pluginId),
        formField.data,
        formData[formField.name],
        formData,
        formField.defaultValue
    );

    return {
        ...formField,
        ...resolvedOptions,
        ...resolvedEnabledState,
        defaultValue:
            // Non-multi form fields don't have their values in an array
            formField.isMulti !== false && Array.isArray(resolvedOptions.defaultValue)
                ? resolvedOptions.defaultValue[0]
                : resolvedOptions.defaultValue
    };
};

export const resolveFormFields = (
    formFields: Serialised<UIConfig[]> | undefined,
    formData: Record<string, any>,
    streamDefinitions: Serialised<ProjectedDataStreamDefinitionEntity>[],
    config: Pick<ClientDataStreamRequest, 'scope' | 'pluginConfigId' | 'context'>,
    pluginId: string
): ResolvedUIConfig[] | undefined =>
    formFields?.map((field) => {
        if (field.type === 'fieldGroup') {
            return {
                ...field,
                fields: resolveFormFields(field.fields, formData, streamDefinitions, config, pluginId)
            };
        }
        return resolveFormField(field, formData, streamDefinitions, config, pluginId);
    }) as ResolvedUIConfig[] | undefined;
