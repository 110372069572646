import { MONITOR_NODE, MONITOR_NODE_SIZE } from '../../components/nodes/MonitorNode';

export const monitorToNode = (monitor: any) => {
    return {
        id: monitor.id,
        type: MONITOR_NODE,
        position: {
            x: 0,
            y: 0
        },
        width: MONITOR_NODE_SIZE,
        height: MONITOR_NODE_SIZE,
        data: {
            label: monitor.tileName?.[0] ?? monitor.name?.[0],
            dashId: monitor.dashId?.[0],
            tileId: monitor.sourceId?.[0],
            type: monitor.type?.[0],
            sourceType: monitor.sourceType?.[0],
            initialState: monitor.state?.[0]
        }
    };
};
