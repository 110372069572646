import * as Constants from '@squaredup/constants';
import Modal, { CloseHandler, ifNotOutside } from 'components/Modal';
import Field from 'components/forms/field/Field';
import Form from 'components/forms/form/Form';
import { useState } from 'react';

interface ApiKeyModalProps {
    subType: Constants.ApiKeySubType;
    apiKeys: (string | undefined)[];
    close: CloseHandler;
}

interface FormData {
    displayName?: string;
    description?: string;
}

function ApiKeyModal({ close, subType, apiKeys }: ApiKeyModalProps) {
    const [apiKeyErrors, setApiKeyErrors] = useState(false);
    return (
        <Modal title='Add API key' close={ifNotOutside(close)} fullWidth maxWidth='max-w-3xl'>
            <div className='pr-1 tile-scroll-overflow'>
                <Form className='px-8'>
                    {(_isValid, _isSubmitting, data: FormData) => (
                        <>
                            <div className='mb-16 mr-4'>
                                <Field.Input
                                    name='displayName'
                                    label='Name'
                                    title='Name'
                                    placeholder='Enter a name...'
                                    validation={{
                                        required: true,
                                        maxLength: 128,
                                        minLength: 5,
                                        validate: (value) => {
                                            return (
                                                !apiKeys?.includes(value.toLowerCase()) ||
                                                'An API key with that name already exists'
                                            );
                                        }
                                    }}
                                />
                                <Field.Input
                                    name='description'
                                    label='Description'
                                    title='Description'
                                    placeholder='Enter a description...'
                                    validation={{ maxLength: 128, minLength: 5 }}
                                />
                                <Field.Input
                                    type='apiKey'
                                    name='ApiKey'
                                    title='API key'
                                    label='API key'
                                    subType={subType}
                                    disabled={
                                        !(
                                            (
                                                data.displayName !== undefined &&
                                                data.displayName.length >= 5 &&
                                                data.displayName.length <= 128 &&
                                                !apiKeyErrors &&
                                                _isValid
                                            ) //Disable button if there are any errors
                                        )
                                    }
                                    keyDisplayName={data.displayName}
                                    keyDescription={data.description}
                                    setErrorField='displayName'
                                    setApiKeyErrors={(errorsExist: boolean) => setApiKeyErrors(errorsExist)}
                                />
                            </div>
                        </>
                    )}
                </Form>
            </div>
        </Modal>
    );
}

export default ApiKeyModal;
