export enum EditorSteps {
    dataStream = 'Data Stream',
    objects = 'Objects',
    query = 'Parameters',
    timeframe = 'Timeframe',
    shaping = 'Shaping',
    metadata = 'Columns'
}

export const MATCHES_NONE = ['none'];
export const MATCHES_ALL = ['all'];
export const MATCHES_ADVANCED = ['advanced'];

export const propertyExclusionList = [
    'name',
    'sourceId',
    'type',
    'sourceName',
    '__search',
    '__name',
    '__partitionKey',
    '__tenantId',
    '__configId',
    '__timestamp'
];
