import type { DashboardTileContent, DashboardVariable } from '@squaredup/dashboards';
import { TimeframeEnumValue, defaultTimeframeEnum, supportedTimeframes } from '@squaredup/timeframes';
import clsx from 'clsx';
import { AppContext } from 'contexts/AppContext';
import DashboardContext from 'contexts/DashboardContext';
import RenderTile from 'dashboard-engine/render/RenderTile';
import { usePageTitle } from 'lib/usePageTitle';
import { useInitialVariableSelection } from 'pages/dashboard/components/utils/useInitialVariableSelection';
import { useDashboard } from 'queries/hooks/useDashboard';
import { useDashboardVariables } from 'queries/hooks/useDashboardVariables';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useDashboardId } from 'ui/hooks/useDashboardId';
import { TileError } from 'ui/tile/TileError';
import { OpenAccessLoadingSpinner } from './OpenAccessLoadingSpinner';
import { useOpenAccessBackground } from './useOpenAccessBackground';

/**
 * Renders a specific tile using a shared/transient link
 * Supports specifying timeframe by URL parameter
 */
export const OpenAccessTile: React.FC = () => {
    const dashboardId = useDashboardId();
    const [params] = useSearchParams();
    const { tileId } = useParams();

    // Support specifying a timeframe via URL parameter, e.g. ?timeframe=last7days
    const paramTimeframe = params.get('timeframe');
    const initialTimeframe: TimeframeEnumValue = (
        paramTimeframe && (supportedTimeframes as string[]).includes(paramTimeframe)
            ? paramTimeframe
            : defaultTimeframeEnum
    ) as TimeframeEnumValue;

    const backgroundClass = useOpenAccessBackground('bg-tileBackground');

    const [timeframe, setTimeframe] = useState(initialTimeframe);
    const [variables, setVariables] = useState<DashboardVariable[]>([]);

    const { setCurrentWorkspaceID } = useContext(AppContext);

    const { data: dashboard, isLoading: isLoadingDashboard } = useDashboard(dashboardId);
    const initialNodes = useInitialVariableSelection(dashboardId);
    const { data: dashboardVariables, isLoading: isLoadingVariables } = useDashboardVariables(
        dashboardId,
        initialNodes
    );

    const isLoading = isLoadingDashboard || isLoadingVariables;

    const tile = dashboard?.content?.contents?.find((t: DashboardTileContent) => t.i === tileId);

    useEffect(() => {
        setCurrentWorkspaceID({
            id: dashboard?.workspaceId,
            force: true
        });
    }, [dashboard?.workspaceId, setCurrentWorkspaceID]);

    useEffect(() => {
        setVariables(dashboardVariables || []);
    }, [dashboardVariables]);

    // Ensure timeframe reflects dashboard timeframe (if set)
    useEffect(() => {
        if (!paramTimeframe) {
            // Only if not being set by URL parameter
            setTimeframe(dashboard?.timeframe || defaultTimeframeEnum);
        }
    }, [dashboard?.timeframe, paramTimeframe]);

    usePageTitle([tile?.config.title, dashboard?.displayName].filter(Boolean).join(' | '));

    if (isLoading) {
        return <OpenAccessLoadingSpinner />;
    }

    if (!tile) {
        return (
            <TileError
                heading='Invalid tile'
                detail='This specified tile/dashboard was not found, please check the dashboard and try again.'
                showDetail={true}
            />
        );
    }

    return (
        <div className={clsx('flex flex-col w-full h-full min-h-0', backgroundClass)}>
            <div className='w-full h-full'>
                <div className='w-full h-full'>
                    <DashboardContext.Provider
                        value={{
                            editing: false,
                            showDetailedErrors: false,
                            dashboard: dashboard!,
                            timeframe,
                            variables,
                            setVariables
                        }}
                    >
                        <RenderTile
                            tileId={tileId!}
                            config={{
                                ...tile?.config,
                                noBackground: true,
                                noHeader: true
                            }}
                            key={tile?.i}
                            preview={false}
                        />
                    </DashboardContext.Provider>
                </div>
            </div>
        </div>
    );
};
