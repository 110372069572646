import PropTypes from 'prop-types';

import { RegisterOptions } from 'react-hook-form';
import Input from '../../input/Input';
import Field, { FormFieldProps } from '../Field';
import React from 'react';

type FieldRadioProps = FormFieldProps & {
    name: string;
    validation: RegisterOptions;
    displayInline?: boolean;
    disabled?: boolean;
    options: {
        label: string;
        value: string;
        checked?: boolean;
        description?: string;
        disabled?: boolean;
        labelNode?: React.ReactNode;
    }[];
};

/**
 * Field.Radio
 * Helper for creating a radio field. radio are a little different as they have two labels and we should
 * show the required marker against the radio, rather than the label.
 *
 * @example
 * `<Field.Radio label='Options' inputLabel='Enable Access' name='test' validation={{ required: true }}/>`
 */
function FieldRadio({
    name,
    label,
    options,
    description,
    help,
    validation,
    indent,
    displayInline,
    helpAlign,
    descriptionAbove,
    disabled,
    ...rest
}: FieldRadioProps) {
    const optionElements = options.map((o, i) => (
        <Input
            type='radio'
            name={name}
            value={o.value}
            id={o.value}
            key={i}
            label={o.label}
            description={o.description}
            validation={validation}
            className='mb-2'
            disabled={disabled || o.disabled}
            labelNode={o.labelNode}
            {...rest}
        />
    ));

    return (
        <Field
            label={label}
            description={description}
            help={help}
            helpAlign={helpAlign}
            descriptionAbove={descriptionAbove}
            htmlFor={name}
        >
            {displayInline ? <div className='flex flex-wrap space-x-6'>{optionElements}</div> : optionElements}
        </Field>
    );
}

FieldRadio.propTypes = {
    /**
     * Name of the input, used in output data.
     */
    name: PropTypes.string.isRequired,
    /**
     * Text to be displayed as field label
     */
    label: PropTypes.string.isRequired,
    /**
     * Array of options (value/label)
     */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
            checked: PropTypes.bool
        })
    ),
    /**
     * Text to be displayed underneath input
     */
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /**
     * Text to display as the tooltip
     */
    title: PropTypes.string,
    /**
     * Text to displayed as help icon tooltip
     */
    help: PropTypes.string,
    /**
     * Validation settings - see React Hook Form
     */
    validation: PropTypes.object
};

export default FieldRadio;
