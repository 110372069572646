import { ApplicationSubdomain } from '@squaredup/tenants';
import { axiosNoAuth } from './API';
import { handleError, handleResponse } from './util';
import { Serialised } from '@squaredup/ids';

export const GetApplicationSubdomain = async (subdomain: string): Promise<Serialised<ApplicationSubdomain>> =>
    // The endpoint is unauthenticated because it needs to be called before sign-in.
    axiosNoAuth
        .get<Serialised<ApplicationSubdomain>>(`/subdomains/${subdomain}`)
        .then(handleResponse)
        .catch((err) => handleError(err, true));
