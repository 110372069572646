import objectHash from 'object-hash';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ReactFlow, { Controls, Node, useEdgesState, useNodesState, type Edge } from 'reactflow';
import 'reactflow/dist/style.css';
import NetworkNode from './NetworkNode';
import WorkspaceNode from './WorkspaceNode';
import './network.css';
import { getAllIncomers } from './utils/getAllIncomers';
import { getEdgeWithState } from './utils/getEdgeWithState';
import { getNodeWithState } from './utils/getNodeWithState';
import WorkspaceKPINode from './workspaceNetworkComponents/WorkspaceKPINode';

export const GRAPH_DATA = 'GRAPH_DATA';



const nodeTypes = {
    circle: NetworkNode,
    kpi: WorkspaceKPINode,
    workspace: WorkspaceNode
};

const getNodeLink = (data: any) => {
    // If the node has a link just use that
    if (data.link) {
        return data.link;
    }

    // We're in the graph (network viz) so stay in the graph
    return `/drilldown/node/${data.id}`;
};

export const NetworkFlow: React.FC<{
    nodes: Node[];
    edges: Edge[];
    nodeType?: string;
    extraControls?: ReactNode;
    onClick?: () => void;
}> = ({ nodes: initialNodes, edges: initialEdges, extraControls, nodeType = '', onClick }) => {
    const navigate = useNavigate();

    const [hoveredNode, setHoveredNode] = useState<string>();
    const [hoveredUpStreamNodes, setHoveredUpStreamNodes] = useState<string[]>();

    const handleClick = useCallback(
        (_, { data }: Pick<Node, 'data'>) => {
            const link = getNodeLink(data);

            // Fire on click and then navigate away
            onClick?.();
            navigate(link);
        },
        [navigate, onClick]
    );

    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes.map((node) => getNodeWithState(
        node,
        nodeType,
        hoveredNode,
        hoveredUpStreamNodes
    )));

    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges.map((edge) => getEdgeWithState(
        edge,
        nodeType,
        hoveredUpStreamNodes
    )));

    const handleMouseEnter = (_: any, { id: nodeId, data }: Pick<Node, 'id' | 'data'>) => {
        setHoveredNode(nodeId);

        // Limit hover effects to graphs with less than 500 nodes (It can lead to very poor performance!)
        if (nodes.length <= 500) {
            setHoveredUpStreamNodes([nodeId, ...getAllIncomers(data, nodes, edges).map(({ id }) => id)]);
        }
    };

    const handleMouseLeave = () => {
        setHoveredNode(undefined);
        setHoveredUpStreamNodes(undefined);
    };

    useEffect(() => {
        setNodes(initialNodes.map(node => getNodeWithState(
            node,
            nodeType,
            hoveredNode,
            hoveredUpStreamNodes
        )));

        setEdges(initialEdges.map(edge => getEdgeWithState(
            edge,
            nodeType,
            hoveredUpStreamNodes
        )));
    }, [hoveredNode, hoveredUpStreamNodes, initialEdges, initialNodes, nodeType, setEdges, setNodes]);

    return (
        <div className='w-full h-full' data-testid='networkGraph'>
            {nodes.length > 0 && (
                <ReactFlow
                    key={objectHash({ ...(nodes?.map(({ id, hidden, data }) => [id, hidden, data.tiles]) || []) })}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    nodeTypes={nodeTypes as any}
                    nodesDraggable={false}
                    proOptions={{ hideAttribution: true }}
                    onNodeClick={nodeType !== 'kpi' ? handleClick : undefined}
                    onNodeMouseEnter={handleMouseEnter}
                    onNodeMouseLeave={handleMouseLeave}
                    minZoom={0.2}
                    maxZoom={1}
                    fitView
                >
                    <Controls showInteractive={false}>{extraControls}</Controls>
                </ReactFlow>
            )}
        </div>
    );
};
