import { ConfigId } from '@squaredup/ids';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { useMutation, useQueryClient } from 'react-query';
import { DATA_STREAM_DEFINITIONS_FOR_WORKSPACE } from 'services/DataStreamDefinitionService';
import { AddPluginLinksToWorkspace } from 'services/WorkspaceService';

interface useAddDataSourceProps {
    workspaceId: string;
    onSuccess?: () => void;
}

const useAddDataSources = ({ workspaceId, onSuccess }: useAddDataSourceProps) => {
    const queryClient = useQueryClient();

    const { mutateAsync: addPlugins } = useMutation(
        async (pluginIds: ConfigId['value'][]) => {
            await AddPluginLinksToWorkspace(workspaceId, pluginIds);
            await queryClient.invalidateQueries([DATA_STREAM_DEFINITIONS_FOR_WORKSPACE]);
            await queryClient.invalidateQueries(workspaceQueryKeys.list);
        },
        {
            onSuccess
        }
    );

    const addDataSources = async (pluginIds: ConfigId['value'][]) => {
        if (!workspaceId) {
            return;
        }
        return addPlugins(pluginIds);
    };

    return { addDataSources };
};

export default useAddDataSources;
