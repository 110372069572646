import Text from '@/components/Text';
import { faChevronLeft, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TruncatedText } from 'components/TruncatedText';
import { NavTitle } from 'ui/nav/ui/NavLinks';

interface ScopeModalHeaderProps {
    isEditing?: boolean;
    isViewing?: boolean;
    existingScopeName?: string;
    onBack?: () => void;
    onClose: () => void;
}

export const ScopeModalHeader: React.FC<ScopeModalHeaderProps> = ({
    isEditing,
    isViewing,
    existingScopeName,
    onBack,
    onClose
}) => (
    <div className='py-5 border-b px-9 border-dividerTertiary'>
        {onBack && (
            <NavTitle
                title='Back'
                onClick={onBack}
                icon={faChevronLeft}
                className='mb-2 text-sm text-textSecondary hover:text-textPrimary'
            />
        )}
        <div className='flex flex-row items-center flex-shrink-0 overflow-hidden'>
            <span className='flex flex-col items-start flex-1 overflow-hidden'>
                <Text.H2 className='w-full truncate text-textPrimary' data-testid='modalHeader'>
                    {(isEditing || isViewing) && existingScopeName ? (
                        <TruncatedText title={existingScopeName} className='max-w-full truncate'>
                            {isViewing ? 'Collection:' : 'Editing collection:'} {existingScopeName}
                        </TruncatedText>
                    ) : (
                        'Add collection'
                    )}
                </Text.H2>
            </span>

            <FontAwesomeIcon
                icon={faXmark}
                className='ml-4 text-xl cursor-pointer text-tertiaryButton hover:text-tertiaryButtonHover'
                onClick={() => onClose()}
                data-testid='closeModal'
            />
        </div>
    </div>
);
