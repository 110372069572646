import { isPresetOf } from '@squaredup/data-streams';
import { Serialised } from '@squaredup/ids';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import type { DataStreamDefinitionEntity } from 'dynamo-wrapper';
import { flatMap, map, sortBy, uniq } from 'lodash';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
    DATA_STREAM_DEFINITIONS_FOR_WORKSPACE,
    INCLUDE_TEMPLATES,
    isMatchable,
    ListForAllWorkspaces,
    ListForWorkspace
} from 'services/DataStreamDefinitionService';
import { matchDataStreams } from 'services/DataStreamService';
import { NO_ACTIVE_WORKSPACE } from 'services/WorkspaceUtil';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { getMatchTypes } from '../../utilities/getMatchTypes';
import { MATCHES_ADVANCED } from '../constants';
import { getSavedScopeId } from '../utilities/getSavedScopeId';
import { useDataSources } from './useDataSources';
import { useScopes } from './useScopes';
import { useSortedDataStreams } from './useSortedDataStreams';
import { useSourceTypesToTypesMap } from './useSourceTypesToTypesMap';

export type DataStreamFilterOption = Serialised<DataStreamDefinitionEntity> & {
    pluginName?: string;
    /**
     * The configurable data stream that this data stream
     * is a preset of.
     */
    presetParentDataStream?: Omit<DataStreamFilterOption, 'displayNameFull'>;
    /**
     * The display name of this data stream, prepended with
     * the name of the data stream this stream is a preset of (if any).
     */
    displayNameFull: string;
    definition: {
        matchesTypes?: string[];
    };
};

/**
 * @param dataStreamId string
 * Gets the data sources for the workspace and generates filter criteria for selecting a
 * data stream (data source and/or type), manges the filter state and returns the list of
 * data streams for selection
 * @returns filter criteria, loading state and filter state getters/setters
 */
export const useDataStreamFilters = () => {
    const { workspace: workspaceFromContext } = useDataStreamWorkspaceContext();
    const { config } = useDatasetContext();

    const isGlobal = workspaceFromContext === NO_ACTIVE_WORKSPACE;
    const workspace = workspaceFromContext && !isGlobal ? workspaceFromContext : null;

    // Store id and pluginConfigId on mount so we can show the previously selected data stream at the top of the list
    const [{ id: dataStreamId }] = useState(config.dataStream || {});

    const [selectedDataSourceId, setSelectedDataSourceId] = useState<string>();
    const [selectedObjectScope, setSelectedObjectScope] = useState<string>();
    const [selectedScopeId, setSelectedScopeId] = useState<string | undefined>(
        getSavedScopeId(config.scope, workspace!) || ''
    );
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const { data: sources, isLoading: isLoadingSources } = useDataSources();
    const { data: sourceTypesToTypes, isLoading: isLoadingTypesMap } = useSourceTypesToTypesMap(sources);

    const { data: dataStreams, isLoading: isLoadingDataStreams } = useQuery(
        [DATA_STREAM_DEFINITIONS_FOR_WORKSPACE, workspace, INCLUDE_TEMPLATES],
        async () => {
            const allDatastreams = await (isGlobal ? ListForAllWorkspaces(true) : ListForWorkspace(workspace, true));
            return Object.values(allDatastreams).flat();
        },
        {
            enabled: Boolean(sources && sourceTypesToTypes),
            select: (data): DataStreamFilterOption[] => {
                const allStreamsWithMatchCriteria = data
                    .filter((stream) => {
                        return (
                            stream.definition?.provides !== 'templateData' &&
                            sources?.some((s) => s.plugin?.pluginId === stream.pluginId) && // If the plugin source config exists
                            ((isMatchable(stream) &&
                                getMatchTypes(stream.definition?.matches, sourceTypesToTypes)?.length) ||
                                stream.id === dataStreamId)
                        ); // If it's matchable and has objects that are in the graph OR current ID is a non matchable stream
                    })
                    .map((dataStream) => {
                        const matches = dataStream.definition?.matches;
                        const matchesTypes = matches ? getMatchTypes(matches, sourceTypesToTypes) : MATCHES_ADVANCED;

                        return {
                            ...dataStream,
                            presetParentDataStream: dataStream.definition.presetOf
                                ? data.find((parent) => isPresetOf(parent, dataStream))
                                : undefined,
                            definition: {
                                ...dataStream.definition,
                                matchesTypes: matchesTypes
                            }
                        };
                    })
                    .map((dataStream) => {
                        return {
                            ...dataStream,
                            displayNameFull: dataStream.presetParentDataStream?.displayName
                                ? `${dataStream.presetParentDataStream.displayName} / ${dataStream.displayName}`
                                : dataStream.displayName,
                            pluginName: sources?.find((s) => s.plugin?.pluginId === dataStream.pluginId)?.plugin?.name
                        };
                    });

                return allStreamsWithMatchCriteria;
            }
        }
    );

    const { data: scopes, isLoading: isLoadingScopes } = useScopes();

    const { data: currentScopeDataStreams, isLoading: isLoadingScopeDataStreamMatches } = useQuery(
        ['streamMatches', selectedScopeId],
        () =>
            matchDataStreams({
                scope: selectedScopeId,
                workspace: workspaceFromContext,
                query: ''
            }),
        {
            staleTime: Number.POSITIVE_INFINITY,
            cacheTime: Number.POSITIVE_INFINITY,
            enabled: Boolean(selectedScopeId),
            select(data) {
                return Object.values(data).flat();
            }
        }
    );

    /**
     * The visible Data Source, Data Stream type, and Object Scope filters are affected by one another
     *
     * When a selectedDataSourceId is picked, any Object Scopes that don't appear in the
     * resulting dataStreams list will get filtered out, and vice-versa with selectedObjectScope
     * and Data Sources
     *
     * We need to filter the dataStreams list several different ways to get each variation to
     * compare against
     */
    const dataStreamsForScope = useMemo(() => {
        if (!selectedScopeId) {
            return dataStreams || [];
        }

        if (currentScopeDataStreams && currentScopeDataStreams.length > 0) {
            const scopeDataStreams = currentScopeDataStreams.map((scopeStream) => scopeStream.id);
            return (dataStreams || []).filter(
                // If matches the scope, or it's a non matchable stream that's currently selected
                (s) => scopeDataStreams.includes(s.id) || (!isMatchable(s) && dataStreamId === s.id)
            );
        }

        return dataStreams || [];
    }, [dataStreams, currentScopeDataStreams, selectedScopeId, dataStreamId]);

    const dataStreamsForPlugin =
        dataStreams?.filter(
            (stream) => stream.pluginId && (selectedDataSourceId ? selectedDataSourceId === stream.pluginId : true)
        ) || [];

    const dataStreamsForObjectScope =
        dataStreams?.filter((stream) =>
            selectedObjectScope
                ? stream.definition.matchesTypes?.some((type) => selectedObjectScope === type || type === 'all')
                : true
        ) || [];

    const dataStreamsForTags =
        dataStreams?.filter((stream) =>
            selectedTags.length > 0 ? selectedTags.every((tag) => stream.definition.tags?.includes(tag)) : true
        ) || [];

    const dataStreamsToShowForTags = dataStreamsForScope.filter(
        (stream) =>
            dataStreamsForPlugin.some(({ pluginId }) => stream.pluginId === pluginId) &&
            dataStreamsForObjectScope.some(({ id }) => stream.id === id)
    );
    /**
     * Data streams with all filters applied - scope, source, object scope,
     * matches none/scoped - these get displayed to the user
     */
    const dataStreamsToShow = dataStreamsToShowForTags.filter((stream) =>
        dataStreamsForTags.some(({ id }) => stream.id === id)
    );

    /**
     * We need to generate the list of filter options to show based on the currently selected
     * criteria and the filtered set of data streams
     */
    const pluginOptions = [
        ...new Set(
            map(
                dataStreamsForScope.filter(
                    (stream) =>
                        dataStreamsForObjectScope.some(({ id }) => stream.id === id) &&
                        dataStreamsForTags.some(({ id }) => stream.id === id)
                ),
                'pluginId'
            )
        )
    ];

    const pluginOptionsToShow = sortBy(
        pluginOptions
            .map((pluginId) => ({
                pluginId,
                pluginName: sources?.find((s) => s.plugin?.pluginId === pluginId)?.plugin?.name,
                onPrem: sources?.find((s) => s.plugin?.pluginId === pluginId)?.plugin?.onPrem
            }))
            .filter((o): o is typeof o & { pluginName: string } => o.pluginName != null),
        'pluginName'
    );

    // Get a list of object scopes to show based on the available data streams filtered by data source
    const objectScopeOptions = [
        ...new Set([
            ...flatMap(
                dataStreamsForScope.filter((stream) => dataStreamsForPlugin.some(({ id }) => stream.id === id)),
                'definition.matchesTypes'
            ),
            selectedObjectScope
        ])
    ];
    const objectScopesToShow = objectScopeOptions
        .filter((objectScope) => objectScope && !['all', 'none', 'advanced'].includes(objectScope))
        .sort();

    if (objectScopeOptions.includes('all')) {
        objectScopesToShow.unshift('all');
    }
    // Ensure advanced and all are at the top of the list
    if (objectScopeOptions.includes('advanced')) {
        objectScopesToShow.push('advanced');
    }

    const isLoading = isLoadingSources || isLoadingTypesMap || isLoadingDataStreams || isLoadingScopes;

    // Tags needs to be shown in the default data stream sort order, so get a list of sorted data streams
    const { sortedStreams: sortedDataStreamsToShowForTags } = useSortedDataStreams(dataStreamsToShowForTags, true);

    const tags = uniq([
        ...(sortedDataStreamsToShowForTags ?? []).flatMap((ds) => ds.definition.tags ?? []),
        // Currently selected tags may not be in any of the currently shown data streams,
        // add them in so they are still visible
        ...selectedTags
    ]);

    return {
        dataStreams: dataStreamsToShow,
        plugins: pluginOptionsToShow,
        objectScopes: objectScopesToShow,
        scopes,
        tags,
        isLoading,
        isLoadingScopeDataStreamMatches,
        selectedDataSourceId,
        selectedObjectScope,
        selectedScopeId,
        selectedTags,
        setSelectedDataSourceId,
        setSelectedObjectScope,
        setSelectedScopeId,
        setSelectedTags
    };
};
