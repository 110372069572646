import {
    faAddressBook,
    faAnglesRight,
    faBarsFilter,
    faBringFront,
    faBrowser,
    faBuilding,
    faBullseye,
    faChartLine,
    faCloud,
    faCodeBranch,
    faCog,
    faCube,
    faCubes,
    faDatabase,
    faFileInvoice,
    faGaugeHigh,
    faGlobe,
    faLambda,
    faLayerGroup,
    faMap,
    faMonitorWaveform,
    faNetworkWired,
    faPlug,
    faProjectDiagram,
    faRocket,
    faScaleBalanced,
    faScrewdriver,
    faServer,
    faSignsPost,
    faSuitcase,
    faTable,
    faTag,
    faUser,
    faUsers,
    type IconDefinition
} from '@fortawesome/pro-solid-svg-icons';
import type { Node } from '@squaredup/graph';
import { mapValues } from 'lodash';
import { ClientSideCustomType } from 'services/CustomTypesService';

type Mode = 'plural' | 'singular';

interface BuiltInTypeDefinition {
    icon: IconDefinition;
    singular: string;
    plural: string;
}

type CustomTypeDefinition = Pick<ClientSideCustomType, 'config'>;

/**
 * Built in core types
 */
export const BuiltInTypes: Record<Lowercase<string>, BuiltInTypeDefinition> = {
    app: {
        icon: faBrowser,
        singular: 'App',
        plural: 'Apps'
    },
    api: {
        icon: faNetworkWired,
        singular: 'API',
        plural: 'APIs'
    },
    apidomain: {
        icon: faGlobe,
        singular: 'API Domain',
        plural: 'API Domains'
    },
    apigateway: {
        icon: faNetworkWired,
        singular: 'API Gateway',
        plural: 'API Gateways'
    },
    dnszone: {
        icon: faMap,
        singular: 'DNS Zone',
        plural: 'DNS Zones'
    },
    dnsrecord: {
        icon: faSignsPost,
        singular: 'DNS Record',
        plural: 'DNS Records'
    },
    db: {
        icon: faDatabase,
        singular: 'Database',
        plural: 'Databases'
    },
    host: {
        icon: faServer,
        singular: 'Host',
        plural: 'Hosts'
    },
    monitor: {
        icon: faMonitorWaveform,
        singular: 'Monitor',
        plural: 'Monitors'
    },
    kpi: {
        icon: faGaugeHigh,
        singular: 'KPI',
        plural: 'KPIs'
    },
    function: {
        icon: faLambda,
        singular: 'Function',
        plural: 'Functions'
    },
    table: {
        icon: faTable,
        singular: 'Table',
        plural: 'Tables'
    },
    storage: {
        icon: faCloud,
        singular: 'Storage',
        plural: 'Storage'
    },
    cdn: {
        icon: faCloud,
        singular: 'CDN',
        plural: 'CDNs'
    },
    directory: {
        icon: faAddressBook,
        singular: 'Directory',
        plural: 'Directories'
    },
    relay: {
        icon: faBringFront,
        singular: 'Relay',
        plural: 'Relays'
    },
    tag: {
        icon: faTag,
        singular: 'Tag',
        plural: 'Tags'
    },
    space: {
        icon: faLayerGroup,
        singular: 'Workspace',
        plural: 'Workspaces'
    },
    scope: {
        icon: faBullseye,
        singular: 'Object Collection',
        plural: 'Object Collections'
    },
    dash: {
        icon: faBrowser,
        singular: 'Dashboard',
        plural: 'Dashboards'
    },
    tile: {
        icon: faChartLine,
        singular: 'Tile',
        plural: 'Tiles'
    },
    cluster: {
        icon: faCubes,
        singular: 'Cluster',
        plural: 'Clusters'
    },
    service: {
        icon: faCog,
        singular: 'Service',
        plural: 'Services'
    },
    loadbalancer: {
        icon: faScaleBalanced,
        singular: 'Load Balancer',
        plural: 'Load Balancers'
    },
    container: {
        icon: faSuitcase,
        singular: 'Container',
        plural: 'Containers'
    },
    workflow: {
        icon: faAnglesRight,
        singular: 'Workflow',
        plural: 'Workflows'
    },
    pipeline: {
        icon: faScrewdriver,
        singular: 'Pipeline',
        plural: 'Pipelines'
    },
    organization: {
        icon: faBuilding,
        singular: 'Organization',
        plural: 'Organizations'
    },
    project: {
        icon: faProjectDiagram,
        singular: 'Project',
        plural: 'Projects'
    },
    repository: {
        icon: faCodeBranch,
        singular: 'Repository',
        plural: 'Repositories'
    },
    environment: {
        icon: faCloud,
        singular: 'Environment',
        plural: 'Environments'
    },
    release: {
        icon: faRocket,
        singular: 'Release',
        plural: 'Releases'
    },
    account: {
        icon: faFileInvoice,
        singular: 'Account',
        plural: 'Accounts'
    },
    user: {
        icon: faUser,
        singular: 'User',
        plural: 'Users'
    },
    group: {
        icon: faUsers,
        singular: 'Group',
        plural: 'Groups'
    },
    'data-source': {
        icon: faPlug,
        singular: 'Data Source',
        plural: 'Data Sources'
    },
    var: {
        icon: faBarsFilter,
        singular: 'Variable',
        plural: 'Variables'
    },
    unknown: {
        icon: faCube,
        singular: 'Other',
        plural: 'Others'
    }
};

//TODO: We need proper state management
/**
 * Singleton for looking up a type
 * Looks for a built in type first and then a custom type
 */
class Types {
    _customTypes: Record<Lowercase<string>, CustomTypeDefinition> = {};

    get(type: string): BuiltInTypeDefinition | ClientSideCustomType['config'] {
        const lowerCasedType = type?.toLowerCase() as Lowercase<string>; // Lowercase type to match lowercased values from graph
        return BuiltInTypes[lowerCasedType] || this._customTypes[lowerCasedType]?.config;
    }

    list() {
        return { ...BuiltInTypes, ...mapValues(this._customTypes, (t) => t.config) };
    }

    setCustom(types: CustomTypeDefinition[]) {
        const map = types.reduce(
            (acc, t) => {
                acc[t.config.type?.toLowerCase()] = t; // Lookups always use lowercased type (see get above)
                return acc;
            },
            {} as Record<string, CustomTypeDefinition>
        );

        this._customTypes = map;
    }

    unknown = BuiltInTypes.unknown;
}

export const TypesStore = new Types();

/**
 * Determines a group/type name for a given node
 * Examines type, sourceType, sourceName
 * @param n Graph node
 * @param mode 'plural' or 'singular' mode
 */
export function getTypeNameForNode(n: Node, mode: Mode = 'plural', preferSourceType = false) {
    const groupName = n?.groupName?.[0];
    const type = n?.type?.reverse()[0];
    const sourceType = n?.sourceType?.[0];

    // either prefer the type or sourceType depending on caller preference
    const primaryType = preferSourceType ? sourceType : type;
    const secondaryType = !preferSourceType ? sourceType : type;

    // If we have a specific group set, use that
    // If we have a primaryType, get it from the store - if not available, just use as is
    // If neither available, use the secondaryType (as above), otherwise just show unknown
    return (
        groupName ||
        (primaryType ? TypesStore.get(primaryType)?.[mode] || primaryType : undefined) ||
        (secondaryType ? TypesStore.get(secondaryType)?.[mode] || secondaryType : undefined) ||
        TypesStore.unknown[mode]
    );
}

/**
 * Determines name for a given type
 * @param type Graph node type or sourceType
 * @param mode 'plural' or 'singular' mode
 * @param fallback fallback if there is no value in the Typestore, defaults to the passed `type` string
 */
export function getNameForType(type: string, mode: Mode, fallback = type) {
    return TypesStore.get(type)?.[mode] || fallback;
}

/**
 * Determines icon for a given type
 * @param type Graph node type or sourceType
 */
export function getIconForType(type: string) {
    return TypesStore.get(type)?.icon || TypesStore.unknown.icon; // fall back to unknown
}

/**
 * Determines an icon for a given node
 * Examines type, sourceType, sourceName
 * @param n Graph node
 * @param mode 'plural' or 'singular' mode
 */
export function getIconForNode(n: Pick<Node, 'type' | 'sourceType'>) {
    const type = n?.type?.[0];
    const sourceType = n?.sourceType?.[0];

    return (
        (type && TypesStore.get(type)?.icon) || // First check type
        (sourceType && TypesStore.get(sourceType)?.icon) || // Then sourceType
        TypesStore.unknown.icon
    ); // Otherwise fall back to unknown
}
