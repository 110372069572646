import { isFeatureEnabled, validateTier } from '@squaredup/tenants';
import { TypesStore } from 'lib/types';
import { customTypesQueryKeys } from 'queries/queryKeys/customTypesKeys';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { ListIncludingPluginCustomTypes } from 'services/CustomTypesService';
import { TenantResponse } from 'services/TenantService';

export const useCustomTypes = (tenant: TenantResponse | undefined) => {
    const queryClient = useQueryClient();
    const [isFeatureAvailable, setIsFeatureAvailable] = useState<{ prev?: boolean; current?: boolean }>({});

    // Observe changes to tenant in case Tier changes
    useEffect(() => {
        // Skip if tenant isn't ready, as validateTier will return false
        if (!tenant) {
            return;
        }

        const tier = validateTier(tenant?.tier);
        const isCustomTypesAvailable = tier.success && isFeatureEnabled(tier.data, 'customTypes');

        // Only consider it a state change if we previously had a value
        setIsFeatureAvailable(({ prev, current }) => ({
            current: isCustomTypesAvailable,
            prev: prev === undefined ? isCustomTypesAvailable : current
        }));
    }, [tenant]);

    // Invalidate queries if the feature state changes
    useEffect(() => {
        if (isFeatureAvailable.prev !== isFeatureAvailable.current) {
            queryClient.invalidateQueries(customTypesQueryKeys.list);
        }
    }, [isFeatureAvailable, queryClient]);

    return useQuery(customTypesQueryKeys.list, ListIncludingPluginCustomTypes, {
        enabled: tenant !== undefined,
        // Pretty horrible hack, but we've chosen to manage type state this way..
        select: (data) => {
            TypesStore.setCustom(data);
            return data;
        },
        /* Type data hardly ever changes, aside from a few places that might invalidate the cache directly */
        staleTime: Number.POSITIVE_INFINITY,
        cacheTime: Number.POSITIVE_INFINITY
    });
};
