import {
    boolean,
    date,
    FormattedStreamValue,
    guid,
    isNone,
    json,
    noneString,
    ShapeName,
    state,
    url
} from '@squaredup/data-streams';
import { CellContext } from '@tanstack/react-table';
import Tooltip from 'components/tooltip/Tooltip';
import { FC } from 'react';
import { BooleanCell } from './cells/BooleanCell';
import { CellProps, CellStyle } from './cells/CellProps';
import { DateCell } from './cells/DateCell';
import { GUIDCell } from './cells/GUIDCell';
import { JSONCell } from './cells/JSONCell';
import { RawCell } from './cells/RawCell';
import { StateCell } from './cells/StateCell';
import { URLCell } from './cells/URLCell';
import { ValueCell } from './cells/ValueCell';

const CELL_RENDERERS: Record<ShapeName, FC<CellProps>> = {
    [url.name]: URLCell,
    [date.name]: DateCell,
    [guid.name]: GUIDCell,
    [json.name]: JSONCell,
    [state.name]: StateCell,
    [boolean.name]: BooleanCell,
    // eslint-disable-next-line camelcase
    shape_raw: RawCell
};

const defaultCellStyle: CellStyle = { align: 'center' };

/**
 * Render a stream data value as a particular shape as if it was appearing in
 * the table visualisation
 */
export const renderCellValue = (columnShape: ShapeName, value: FormattedStreamValue, style: CellStyle) => {
    if (isNone(value.value) || value?.value === '') {
        return (
            <Tooltip title='No value'>
                <span className='opacity-30'>{noneString}</span>
            </Tooltip>
        );
    }

    const Component = CELL_RENDERERS[columnShape] ?? ValueCell;

    return <Component {...value} style={style} />;
};

export const renderCell =
    (columnShape: ShapeName, style?: CellStyle) =>
    ({ cell }: CellContext<FormattedStreamValue[], any>) => {
        const value = cell.getValue();

        return renderCellValue(columnShape, value, style ?? defaultCellStyle);
    };
