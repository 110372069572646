/* eslint-disable no-console */
import { StreamDataColumn, url } from '@squaredup/data-streams';
import Field from 'components/forms/field/Field';
import Input from 'components/forms/input/Input';
import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import { VisualisationOptionAction } from 'dashboard-engine/types/Visualisation';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTileEditorContext } from 'ui/editor/dataStream/contexts/TileEditorContext';

interface ColumnListParams {
    columns: StreamDataColumn[];
    onChange: (action: VisualisationOptionAction) => void;
    columnListData: Record<string, any>;
}

const RowLink = ({ columns, onChange, columnListData }: ColumnListParams) => {
    const formProps = useForm({ mode: 'onChange' });
    const { savedTileConfig } = useTileEditorContext();
    const isSaved = savedTileConfig?._type;

    // Memoize and store filtered columns that are role=link/shapeName=shape_url, pass as options in form
    const columnRowLinkOptions = useMemo(() => {
        let formattedColumns: AutocompleteOption[] = [{ label: 'None', value: 'None' }];
        let filteredCols = columns
            .filter((c) => c.role === 'link' || c.shapeName === url.name)
            .map((c) => ({
                column: c,
                value: c.name,
                label: c.displayName
            }));

        const orderedCols = orderBy(filteredCols, (col) => col.column.role, ['asc']);

        formattedColumns.push(...new Set(orderedCols));

        return formattedColumns;
    }, [columns]);

    const doesRowLinkExist = columnRowLinkOptions.some(
        (x) =>
            x.label === columnListData?.rowLinkColumnName?.label && x.value === columnListData?.rowLinkColumnName?.value
    );

    const selectedRow = doesRowLinkExist && columnListData?.rowLinkColumnName;
    const firstLinkOption = columnRowLinkOptions[1];

    if (!selectedRow && !isSaved && columnRowLinkOptions.length > 1) {
        onChange({ action: 'changeRowLink', data: firstLinkOption });
    } else if (columnRowLinkOptions.length <= 1) {
        onChange({ action: 'changeRowLink', data: 'None' });
    }

    return (
        <FormProvider {...formProps}>
            <Field htmlFor='column' className='mt-0'>
                <Input
                    type='autocomplete'
                    name='column'
                    isDisabled={columnRowLinkOptions.length <= 1 ? true : false}
                    placeholder='None'
                    defaultValue={selectedRow?.label || (!isSaved && firstLinkOption?.label) || 'None'}
                    isMulti={false}
                    options={columnRowLinkOptions}
                    onSelect={(data: string) => {
                        data
                            ? onChange({ action: 'changeRowLink', data })
                            : onChange({ action: 'changeRowLink', data: 'None' });
                    }}
                />
            </Field>
        </FormProvider>
    );
};

export default RowLink;
