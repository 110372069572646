import { stateStrings } from '@squaredup/monitoring';
import { FilterCriteria } from 'pages/monitoring/common';
import { WorkspaceNetworkNode, WorkspacesDependenciesMap } from './generateWorkspaceNetwork';
import { isUnhealthy } from 'constants/state';

/**
 * Utility function to traverse the tree and filter nodes that match the current
 * filter criteria. e.g. 'Show only issues'
 * @param filteredNodes Variable to assign resultant nodes
 * @param graphNodes The nodes in the tree
 * @param currentGraphNodes Current nodes in the tree traversal
 * @param filterCriteria Filter criteria to apply
 * @param workspacesWithDependenciesByNodeId Map node ID -> workspace data
 */
export const getFilteredNodes = (
    filteredNodes: WorkspaceNetworkNode[],
    nodes: WorkspaceNetworkNode[],
    currentNodes: WorkspaceNetworkNode[],
    filterCriteria: FilterCriteria,
    workspacesWithDependenciesByNodeId: WorkspacesDependenciesMap
) => {
    const { showIssuesOnly } = filterCriteria;

    currentNodes.forEach(({ data: { upstream = [], dependencies = [] } }) => {
        const filteredConnectedNodeIds = [...upstream, ...dependencies].filter((nodeId) => {
            const healthState = workspacesWithDependenciesByNodeId.get(nodeId)?.state;

            return (
                !filteredNodes.some(({ data: { id } }) => id === nodeId) &&
                (!showIssuesOnly || healthState && isUnhealthy(healthState))
            );
        });

        const dependencyNodes = nodes.filter(({ data: { id } }) => filteredConnectedNodeIds.includes(id));

        filteredNodes.push(...dependencyNodes);

        // Recursively lookup connected nodes
        getFilteredNodes(filteredNodes, nodes, dependencyNodes, filterCriteria, workspacesWithDependenciesByNodeId);
    });
};
