export type Margins = { top: number; left: number; right: number; bottom: number };
export type MarginFunction = (scale: number, maxLabel?: number) => Margins;

/**
 * Margins for when using an inline legend
 */
export const marginsInlineLegend: MarginFunction = (scale, maxLabel = 0) => ({
    top: scale * 40,
    right: scale * 50 + maxLabel,
    bottom: scale * 40,
    left: scale * 50 + maxLabel
});

/**
 * Margins for when using a table legend
 */
export const marginsTableLegend: MarginFunction = (scale) => ({
    top: scale * 20,
    left: 0,
    right: 0,
    bottom: scale * 20
});

/**
 * Margins for when no legend is shown, e.g. small tiles
 */
export const marginsNoLegend: MarginFunction = (scale) => ({
    top: scale * 20,
    left: scale * 30,
    right: scale * 30,
    bottom: scale * 30
});
