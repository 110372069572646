import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { List, SCRIPTS } from 'services/ScriptService';
import { SettingsTemplate } from '../SettingsTemplate';
import { ScriptsDeleteModal } from './ScriptsDeleteModal';
import ScriptsTable from './ScriptsTable';
import { ScriptsAddEditModal } from './ScriptsAddEditModal';
import * as Constants from '@squaredup/constants';
import { monitorConditionJsTemplate, tileDataJsTemplate } from './ScriptTemplates';

export type ProjectedScript = {
    id: string;
    displayName: string;
    scriptType: ScriptTypesKeys;
    scriptTypeName: string;
    subType: string;
}

type Condition = {
    value: string;
    label: string;
    func: string;
    template?: string;
};

export type ScriptTypes = {
    tileDataJS: Condition;
    monitorConditionJS: Condition;
};

export type ScriptTypesKeys = keyof typeof Constants.scriptTypes;

export const scriptTypes: ScriptTypes = Constants.scriptTypes;
scriptTypes.tileDataJS.template = tileDataJsTemplate;
scriptTypes.monitorConditionJS.template = monitorConditionJsTemplate;

/**
 * Renders the scripts page
 */
const Scripts = () => {
    const [scriptBeingDeleted, setScriptBeingDeleted] = useState<ProjectedScript>();
    const [scriptBeingEdited, setScriptBeingEdited] = useState<ProjectedScript>();    
    const [scriptAddEditOpen, setScriptAddEditOpen] = useState(false);

    const { data: scripts, isLoading } = useQuery(
        [SCRIPTS],
        List,
        {
            select: (data: ProjectedScript[]) => data.sort((a: any, b: any) => {
            if (a.displayName === undefined) {
               a.displayName = '';
            }
            if (b.displayName === undefined) {
               b.displayName = '';
            }
            return a.displayName.localeCompare(b.displayName) ;
         }).map((s) => {
            const scriptType = Constants.getScriptTypeForSubType(s.subType);
            return {
                id: s.id,
                subType: s.subType,
                displayName: s.displayName,
                scriptType: (scriptType === scriptTypes.tileDataJS.value ? 
                scriptTypes.tileDataJS.value :
                scriptTypes.monitorConditionJS.value) as ScriptTypesKeys,                  
                scriptTypeName: scriptType === scriptTypes.tileDataJS.value ? 
                scriptTypes.tileDataJS.label :
                scriptTypes.monitorConditionJS.label      
            };
         })         
        }
    );

    return (
        <SettingsTemplate
            title='Scripts'
            description='Scripts are used to retrieve data programmatically or to write custom monitoring conditions.'
            learnMoreLink='https://squaredup.com/cloud/advanced-features/scripts'
        >
            {isLoading && (
                <span className='flex justify-center'>
                    <LoadingSpinner />
                </span>
            )}
            {!isLoading && (
                <div className='flex flex-col flex-1 min-h-0'>
                    <div>
                        <Button onClick={() => setScriptAddEditOpen(true)} data-testid='addScriptButton'>
                            Add script
                        </Button>
                    </div>

                    <div className='flex flex-col min-h-0 mt-4 mb-8'>
                        <ScriptsTable
                            scripts={ scripts || [] }
                            onEdit={(script) => {
                                setScriptBeingEdited(script);
                                setScriptAddEditOpen(true);
                            }}
                            onDelete={(script) => setScriptBeingDeleted(script)}
                        />
                    </div>                    
                </div>
            )}

            {scriptBeingDeleted && (
                <ScriptsDeleteModal
                    script={scriptBeingDeleted}
                    onClose={() => setScriptBeingDeleted(undefined)}
                    key='scripts-delete'
                />
            )}

            {scriptAddEditOpen && (
                <ScriptsAddEditModal
                    onSave={() => setScriptBeingEdited(undefined)}
                    onClose={() => {
                        setScriptBeingEdited(undefined);
                        setScriptAddEditOpen(false);
                    }}
                    script={scriptBeingEdited}
                    key='script-add-edit'
                />
            )}            
        </SettingsTemplate>
    );
};

export default Scripts;