import { findValueColumn } from '@squaredup/data-streams';
import Field from 'components/forms/field/Field';
import { dataMatchCriteria } from 'dashboard-engine/dataStreams/dataMatchCriteria';
import { VisualisationOption } from 'dashboard-engine/types/Visualisation';
import { OptionDropdown } from '../sharedComponents/OptionDropdown';
import { DataStreamGaugeConfig } from './Config';
import DataStreamGaugeChartOptionsForm, { getValidColumns } from './DataStreamGaugeChartOptionsForm';

export const DataStreamGaugeChartOptions: VisualisationOption<DataStreamGaugeConfig> = {
    initialPanels: (config?: DataStreamGaugeConfig) => {
        const panels = [{ name: 'mapping', isOpen: true }];

        if (
            config?.minimum || 
            (config?.maximum && config?.maximum !== 100) ||
            config?.label
        ) {
            panels.push({ name: 'options', isOpen: true });
        }

        return panels;
    },
    dataMappingComponent: ({ columns, config, onChange, tileData }) => {
        const columnOptions = getValidColumns(columns);
        const autoColumn = findValueColumn(columnOptions, tileData.rows.length ?? 0);
        const autoName = autoColumn.column ? `Auto - ${autoColumn.displayName}` : 'Auto - Count (rows)';
        const builtInOptions = [
            {
                label: autoName,
                value: 'none'
            }
        ];

        if (autoColumn.column) {
            builtInOptions.push({
                label: 'Count (rows)',
                value: 'count'
            });
        }

        const optionGroups = [
            {
                label: 'Built-in',
                options: builtInOptions
            },
            {
                label: 'Columns',
                options: columns.map((column) => ({
                    label: column.displayName,
                    value: column.name
                }))
            }
        ];

        const defaultValue = optionGroups
            .flatMap((group) => group.options)
            .find((option) => option.value === (config.value?.type || config.value)) ?? {
            label: autoName,
            value: 'none'
        };

        return (
            <Field label='Value' htmlFor='column' help='Select the column to use for the value shown in the tile'>
                <OptionDropdown name='column' options={optionGroups} defaultValue={defaultValue} onChange={onChange} />
            </Field>
        );
    },
    configurationComponent: ({ config, accordionControls, onChange }) => {
        return (
            <DataStreamGaugeChartOptionsForm
                config={config}
                accordionControls={accordionControls}
                onChange={onChange}
            />
        );
    },
    handlers: {
        'form-change': (config, data) => {
            return { ...config, ...data };
        },
        'column-select': (config, data) => {
            if (data && data !== 'none') {
                if (data === 'count') {
                    return { ...config, value: { type: 'count' } };
                }
                return { ...config, value: data };
            } else {
                const { value: _, ...rest } = config;
                return rest;
            }
        },
        'label-text': (config, data) => {
            return { ...config, label: data };
        }
    },
    validate: (_, config) => config,
    matchesData: () => dataMatchCriteria()
};
