import { useDatasource } from 'queries/hooks/useDatasource';
import { extractPluginLink } from 'queries/utils/extractPluginLink';

export const useDatasourceDocumentationLink = (datasourceId?: string) => {  
    return useDatasource(
        datasourceId, 
        { 
            enabled: Boolean(datasourceId),
            select: (data) => {
                const documentationLink = extractPluginLink(data.externalLinks ?? [], 'documentation', true);
                if (documentationLink?.includes('squaredup.com')) {
                    return documentationLink;
                }
            }
        }
    );
};