import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/tooltip/Tooltip';
import { useAppContext } from 'contexts/AppContext';
import { Link } from 'react-router-dom';
import { useWorkspaceCanWrite } from 'services/AccessControlService';

export const AddDatasourceButton: React.FC = () => {
    const { currentWorkspaceID } = useAppContext();

    const { data: canWrite, isLoading } = useWorkspaceCanWrite(currentWorkspaceID ?? '');

    if (isLoading || !canWrite) {
        return null;
    }

    return (
        <Tooltip title='Add data source'>
            <Link to={`/datasources/${currentWorkspaceID}`}>
                <FontAwesomeIcon icon={faPlus} className='mr-px w-8 hover:text-textPrimary' />
            </Link>
        </Tooltip>
    );
};
