import { useQuery } from 'react-query';
import { CHANNELS, CHANNEL_TYPES, List, ListTypes } from 'services/NotificationChannelService';

export const useNotificationChannels = (enabled: boolean = true) => {
    const {
        data: channels,
        isLoading: isLoadingChannels,
        isFetching: isFetchingChannels
    } = useQuery(CHANNELS, List, { enabled: enabled });

    return {
        channels,
        isLoadingChannels,
        isFetchingChannels
    };
};

export const useNotificationChannelTypes = (enabled: boolean = true) => {
    const {
        data: channelTypes,
        isLoading: isLoadingChannelTypes,
        isFetching: isFetchingChannelTypes
    } = useQuery(CHANNEL_TYPES, ListTypes, {
        enabled: enabled,
        // Channel types almost never change
        staleTime: Number.POSITIVE_INFINITY,
        cacheTime: Number.POSITIVE_INFINITY
    });

    return {
        channelTypes,
        isFetchingChannelTypes,
        isLoadingChannelTypes
    };
};
