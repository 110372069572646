import { useQuery } from 'react-query';
import { Query } from './GraphService';
import { useWorkspaces } from 'queries/hooks/useWorkspaces';

export const TAGS = 'TAGS';

/**
 * Get all graph tags
 */
export const ListGraphTags = () =>
    Query({
        gremlinQuery: 'g.V().has("type", "tag").values("name")',
        workspaceId: null
    }).then((res) => res.gremlinQueryResults);

/**
 * Get all tags via react-query
 */
export const useTags = (options?: { enabled?: boolean }) => {
    const { data: workspaces } = useWorkspaces();

    return useQuery(
        [TAGS],
        () =>
            Promise.all([
                workspaces?.flatMap((workspace) => workspace?.data?.properties?.tags)
                        .filter((tag) => Boolean(tag)),
                ListGraphTags()
            ]).then((data) => [...new Set(data.flat() as string[])].sort(Intl.Collator().compare)),
        options
    );
};