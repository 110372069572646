import { getObjectFilters } from 'ui/editor/dataStream/TileEditor/utilities/getObjectFilters';

type Tag = {
    key: string;
    value: string;
};

export const migrateTagsToPropertyFilters = (
    scope?: {
        id: string;
        workspace: string;
        displayName: string;
        isDependency?: boolean;
        query: string;
        queryDetail: any;
        bindings: any;
    },
    tags?: Tag[]
) => {
    if (!scope || !tags) {
        return;
    }

    const formattedTags = tags.reduce(
        (acc, cur) => {
            if (!acc[cur.key]) {
                acc[cur.key] = [cur.value];
            } else {
                acc[cur.key].push(cur.value);
            }
            return acc;
        },
        {} as Record<string, string[]>
    );

    scope.queryDetail = {
        ...scope.queryDetail,
        ...getObjectFilters([], [], formattedTags, '')
    };

    delete scope.queryDetail.tags;
};
