import { InfoTip } from '@/components/InfoTip';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { AnimatePresence } from 'framer-motion';
import { trackEventDebounced } from 'lib/analytics';
import { debounce } from 'lodash';
import { ScopeObjectCountLimitMessage } from 'pages/scope/scopeModal/ScopeObjectCountLimitMessage';
import { useCallback, useMemo, useState } from 'react';
import { useTileEditorObjectsFilterContext } from '../../contexts/TileEditorObjectsFilterContext';
import { useTileEditorStepsContext } from '../../contexts/TileEditorStepsContext';
import { SearchBar } from '../SearchBar';
import { useConvertOobScopeToNodes } from '../hooks/useConvertOobScopeToNodes';
import { useDataStreamFilters } from '../hooks/useDataStreamFilters';
import { FilterType } from '../hooks/useDataStreamObjectFilters';
import { DataStreamLimitMessage } from '../objects/DataStreamLimitMessage';
import { ObjectDynamicToggle } from '../objects/ObjectDynamicToggle';
import { ObjectFilters } from '../objects/ObjectFilters';
import { ObjectTable } from '../objects/ObjectTable';
import { OobScopeLimitMessage } from '../objects/OobScopeLimitMessage';
import { ObjectsFilterContextWrapper } from './ObjectsFilterContextWrapper';
import { SelectedObjectsCountButton } from './SelectedObjectsCountButton';
import { SelectedObjectsPanel } from './SelectedObjectsPanel';

export const ObjectsStepInner: React.FC = () => {
    const { canMoveToNextStep, nextStep } = useTileEditorStepsContext();

    const objectFilterState = useTileEditorObjectsFilterContext();

    const {
        isDynamic,
        count,
        filterQuery,
        isConfigured,
        selectedObjects,
        dynamicObjectsCount,
        selectedObjectsCount,
        isLoadingFilters,
        interactedObjects,
        isFetchingObjects,
        hasVariables,
        setFilterQuery,
        setInteractedObjects,
        handleSetFilterState,
        handleFixedScope
    } = objectFilterState;

    const [toggleObjectsPanel, setToggleObjectsPanel] = useState(selectedObjectsCount ? true : false);

    const onConverted = useCallback(() => {
        setToggleObjectsPanel(true);
    }, [setToggleObjectsPanel]);
    useConvertOobScopeToNodes(onConverted);

    // We only want to update the search query every 500ms to avoid sending multiple
    // requests too quickly
    const debouncedSearch = useMemo(
        () =>
            debounce((searchTerm: string) => {
                trackEventDebounced('Objects Searched For', { query: searchTerm });
                return handleSetFilterState(FilterType.query, searchTerm, setFilterQuery);
            }, 500),
        [handleSetFilterState, setFilterQuery]
    );

    if (isLoadingFilters) {
        return (
            <div className='flex items-center justify-center w-full h-full'>
                <LoadingSpinner className='m-auto' />
            </div>
        );
    }

    const handleObjectsPanelToggle = () => {
        if (!isDynamic) {
            setToggleObjectsPanel(!toggleObjectsPanel);
        }
    };

    return (
        <div className='flex h-full'>
            <div className='flex flex-col flex-1 min-w-0 pl-6 pr-5 py-7'>
                <div className='flex flex-1 min-h-0 space-x-6'>
                    <ObjectFilters allowCreateScope={true} allowEditScope={true} />

                    <div className='flex flex-col flex-1 h-full min-w-0 min-h-0'>
                        <div className='flex mb-4 mr-4'>
                            <SearchBar
                                initialValue={filterQuery}
                                placeholder='Search objects...'
                                onChange={debouncedSearch}
                                className='flex-1 min-w-0 mr-8'
                                showHelp={true}
                            />

                            <ObjectDynamicToggle />
                        </div>

                        <ObjectTable />

                        <OobScopeLimitMessage />

                        {hasVariables && (
                            <InfoTip icon={faBarsFilter} iconClassName='text-textPrimary'>
                                This tile uses a dashboard variable, users can set the variable when viewing the
                                dashboard.
                            </InfoTip>
                        )}

                        <div className='flex flex-col mt-4'>
                            <ScopeObjectCountLimitMessage />

                            <div className='flex items-center flex-shrink-0 pr-4'>
                                <div>
                                    <DataStreamLimitMessage />
                                </div>
                                <div className='flex justify-end items-center flex-1 text-textSecondary'>
                                    {!hasVariables && (
                                        <SelectedObjectsCountButton
                                            totalCount={count || 0}
                                            interactedObjectsCount={interactedObjects.length}
                                            selectedObjectsCount={selectedObjectsCount}
                                            dynamicObjectsCount={dynamicObjectsCount}
                                            isDynamic={isDynamic}
                                            onClick={() => {
                                                handleObjectsPanelToggle();
                                                setInteractedObjects(selectedObjects);
                                            }}
                                        />
                                    )}

                                    {isFetchingObjects && <LoadingSpinner size={20} className='mr-4' />}
                                </div>

                                {!isDynamic && (
                                    <Button
                                        disabled={selectedObjectsCount === 0}
                                        variant='tertiary'
                                        className='mr-2 px-md'
                                        onClick={() => handleFixedScope([])}
                                    >
                                        Clear
                                    </Button>
                                )}

                                <Button disabled={!canMoveToNextStep || !isConfigured} onClick={() => nextStep()}>
                                    Next
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AnimatePresence>
                {toggleObjectsPanel && interactedObjects.length !== 0 && !isDynamic && (
                    <div className='h-full overflow-hidden bg-tagBackground'>
                        <SelectedObjectsPanel handleObjectsPanelToggle={handleObjectsPanelToggle} />
                    </div>
                )}
            </AnimatePresence>
        </div>
    );
};

export const ObjectsStep = ({ dataStreamFilters }: { dataStreamFilters: ReturnType<typeof useDataStreamFilters> }) => {
    const { selectedObjectScope } = dataStreamFilters;
    return (
        <ObjectsFilterContextWrapper
            fallbackDefaults={{
                type: selectedObjectScope
            }}
        >
            <ObjectsStepInner />
        </ObjectsFilterContextWrapper>
    );
};
