import LoadingSpinner from 'components/LoadingSpinner';
import { datasourceConfigQueryKeys } from 'queries/queryKeys/datasourceConfigKeys';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { OAuth2CodeResponse } from 'services/PluginService';
import { useHash } from './components/useHash';

/**
 * This component satisfies the route /settings/pluginsoauth2 which is the return URL we use for OAuth2
 * authentication of a plugin. The query args contain the OAuth2 auth code. This information is passed
 * to the back end which returns the URL of the plugin editing session to which the OAuth2 authentication
 * process belongs.
 * 
 * This component is only displayed transiently - it simply shows a loading spinner.
 */
function PluginsOAuth2() {
    const queryClient = useQueryClient();
    const [params] = useSearchParams();
    const [hash] = useHash();

    // Check if we have query params from an OAuth2 authentication in progress...
    useEffect(() => {
        async function checkOAuth2() {
            const otherArgs: any = {};
            let state = params.get('state');

            if (state) {
                // Remove any redirectUrl suffix
                state = state.replace(/\|redirectURI=.*$/u, '');

                // Get all other query args
                for (const [key, value] of params.entries()) {
                    if (key !== 'state') {
                        otherArgs[key] = value;
                    }
                }
            } else {
                // Get args from hash
                const argString = decodeURIComponent(hash ?? '#').substring(1);
                argString.split('&').forEach(keyValuePair => {
                    // Only split first = (there might be more if the value part is Base64 encoded)
                    const i = keyValuePair.indexOf('=');
                    const [key, value] = [keyValuePair.substring(0, i), keyValuePair.substring(i + 1)];

                    if (key === 'state') {
                        // Remove any redirectUrl suffix
                        state = value.replace(/\|redirectURI=.*$/u, '');
                    } else {
                        otherArgs[key] = value;
                    }
                });
            }

            if (state) {
                // Let the plugin process the args received from the auth server (hopefully to redeem its
                // auth code for real tokens
                const nextUrl = await OAuth2CodeResponse(state, otherArgs);

                // Plugin configs will have been updated, so zap the cached versions
                queryClient.invalidateQueries(datasourceConfigQueryKeys.all);

                // Redirect as requested
                (window as Window).location = nextUrl;
            } else {
                throw new Error('No state arg found in URL');
            }
        }
        checkOAuth2();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Run this one once on first render

    return (
        <>
            <LoadingSpinner size={18} />
        </>
    );
}

export { PluginsOAuth2 as default };
