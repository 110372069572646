import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { CodeEditor } from 'ui/editor/components/CodeEditor';

type JsonProps = Omit<React.ComponentProps<typeof CodeEditor>, 'content' | 'onValidUpdatedContent'> & {
    name: string;
    defaultValue?: unknown;
    schema?: Record<string, unknown>;
    validation?: RegisterOptions;
};

/**
 * Json
 * Renders a code editor with validation / intellisense
 */
function Json({ name, validation, schema, defaultValue, content, ...props }: JsonProps) {
    const [jsonError, setJsonError] = useState(true);
    const { trigger } = useFormContext();

    useEffect(() => {
        trigger(name);
    }, [name, trigger, jsonError]);

    return (
        <div className='flex flex-col w-full'>
            <Controller
                render={({ field: { value, onChange } }) => (
                    <CodeEditor
                        content={value || {}}
                        schema={schema}
                        onValidUpdatedContent={(v) => onChange(v)}
                        handleError={setJsonError}
                        {...props}
                    />
                )}
                name={name}
                rules={{
                    ...validation,
                    ...(schema && { validate: () => !jsonError })
                }}
                defaultValue={defaultValue}
            />
        </div>
    );
}

Json.propTypes = {
    /**
     * Name of the input, used in output data
     */
    name: PropTypes.string.isRequired,

    /**
     * Validation rules (see React Hook Form)
     */
    validation: PropTypes.object,

    /**
     * Handler for creating new options
     */
    onCreate: PropTypes.func,

    /**
     * Default/initial value
     */
    defaultValue: PropTypes.any
};

export default Json;
