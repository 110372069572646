import { Skeleton } from '@/components/Skeleton';
import { cn } from '@/lib/cn';
import useSize from '@react-hook/size';
import { useRef } from 'react';

const ROW_HEIGHT = 30;
const ROW_GAP = 20;
const COLUMN_GAP = 12;

/**
 * Table skeleton
 * Works by creating 3 masks, bottom mask creates the rows, middle creates the columns.
 * and top highlights the first row/column as the header.
 */
const TableSkeleton = ({ className, transpose }: { className?: string; transpose?: boolean }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [width, height] = useSize(containerRef);
    const headerColumnWidth = width < 700 ? 36 : 16.9;
    // calculate maximum number of rows that can fit in the container
    const rowCount = Math.floor((height + ROW_GAP) / (ROW_HEIGHT + ROW_GAP));
    // calculate the gap percentage of the height
    const gapPercent = (ROW_GAP / height) * 100;
    // calculate the row height percentage, adding the gap percentage so the final gap is hidden
    const rowHeightPercent = 100 / rowCount + gapPercent / rowCount;

    // on smaller tiles we only show 2 columns
    const columnMaskImage =
        width < 700
            ? `linear-gradient(to right,
                    black ${headerColumnWidth}%, rgba(0,0,0,0) ${headerColumnWidth}%,
                    rgba(0,0,0,0) calc(${headerColumnWidth}% + ${COLUMN_GAP}px), black calc(${headerColumnWidth}% + ${COLUMN_GAP}px)
                    )`
            : `linear-gradient(to right, 
                    black ${headerColumnWidth}%, rgba(0,0,0,0) ${headerColumnWidth}%,
                    rgba(0,0,0,0) calc(${headerColumnWidth}% + ${COLUMN_GAP}px), black calc(${headerColumnWidth}% + ${COLUMN_GAP}px),
                    black 50%, rgba(0,0,0,0) 50%,
                    rgba(0,0,0,0) calc(50% + ${COLUMN_GAP}px), black calc(50% + ${COLUMN_GAP}px)
                    )`;

    return (
        <div
            className={cn('size-full pb-2', className)}
            style={{
                maskImage: transpose
                    ? `linear-gradient(to right, rgba(0,0,0,1) ${headerColumnWidth}%, rgba(0,0,0,0.6) ${headerColumnWidth}%)`
                    : `linear-gradient(to bottom, rgba(0, 0, 0, 1) ${
                          rowHeightPercent - gapPercent
                      }%, rgba(0, 0, 0, 0.6) ${rowHeightPercent - gapPercent}%)`,
                maskSize: '100%'
            }}
        >
            <div
                ref={containerRef}
                className='relative size-full'
                style={{
                    maskImage: columnMaskImage,
                    maskSize: '100%',
                    maskRepeat: 'repeat-x'
                }}
            >
                <Skeleton
                    className='rounded-none size-full'
                    style={{
                        maskImage: `linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) calc(100% - ${ROW_GAP}px), rgba(0, 0, 0, 0) calc(100% - ${ROW_GAP}px))`,
                        maskSize: `100% ${rowHeightPercent}%`,
                        maskPosition: '0 0',
                        maskRepeat: 'repeat-y'
                    }}
                />
            </div>
        </div>
    );
};

export default TableSkeleton;
