import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import useAwait from 'lib/useAwait';
import LoginWrapper from 'pages/login/LoginWrapper';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import Auth from 'services/Auth';
import * as TenantService from 'services/TenantService';
import { CURRENT_WORKSPACE } from 'services/WorkspaceService';
import { STATUS_CONFIG } from '../status/utils/useStatusConfig';

/**
 * SelectTenant
 * Gives the user a list of tenants to select
 *
 * @example
 * `<SelectTenant />`
 */
function SelectTenant({ signIn = false }) {
    const queryClient = useQueryClient();

    const [tenantLoading, setTenantLoading] = useState(undefined);
    const loginTenant = (tenant) => async () => {
        setTenantLoading(tenant.displayName);
        window.localStorage.removeItem(CURRENT_WORKSPACE); // clear the workspace
        window.localStorage.setItem('preferredTenant', tenant.id);

        queryClient.invalidateQueries([STATUS_CONFIG]);

        if (signIn && !window.location.pathname.endsWith('/organization')) {
            // User is signing-in and may be targeting a specific destination
            // (e.g. a dashboard).  So just reload to get them there without losing context.
            window.location.reload();
        } else {
            // User explicitly chose to 'change org', so go to the root of the app.
            window.location.href = '/';
        }
    };

    let [, tenants] = useAwait(async () => {
        const tenantInfo = (await TenantService.Tenants()) || [];
        return tenantInfo
            .filter((tenant) => Boolean(Auth.user.tenants?.includes(tenant.id)))
            .sort((a, b) => Intl.Collator().compare(a.displayName, b.displayName))
            .map((tenant) => (
                <Button
                    key={tenant.id}
                    variant='secondary'
                    onClick={loginTenant(tenant)}
                    data-testid='tenantButton'
                    className='w-full'
                >
                    <span className='block w-full text-left truncate'>{tenant.displayName}</span>
                </Button>
            ));
    }, []);

    return (
        <LoginWrapper>
            <div className='flex flex-col flex-1 min-h-0' data-testid='selectTenantPage'>
                <h1 className='mt-10 text-3xl font-bold'>Select organization</h1>
                <h2 className='mt-4'>
                    You are part of multiple organizations, <br />
                    please select which organization to access.
                </h2>

                <div className='justify-center overflow-x-auto flex-1 min-h-0 pr-4 mt-8 space-y-2 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                    {tenants.length > 0 && !tenantLoading ? (
                        tenants
                    ) : !tenantLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <span>
                            <span className='pr-2'>
                                <LoadingSpinner />
                            </span>
                            Loading {tenantLoading}
                        </span>
                    )}
                </div>
            </div>
        </LoginWrapper>
    );
}

SelectTenant.propTypes = {};

export default SelectTenant;
