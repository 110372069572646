import { Edge, Node } from '@squaredup/graph';
import { useCallback } from 'react';
import { useSetNetworkNodesAndEdges } from '../context/NetworkMapStoreContext';
import { setNodesAndEdgeVisibility } from '../data/utils/setNodesAndEdgeVisibility';

export const useRevealExpandedNodes = () => {
    const setNodesAndEdges = useSetNetworkNodesAndEdges();

    return useCallback((
        expandedNodeids: string[],
        updatedGraphNodes: Node[],
        updatedGraphEdges: Edge[],
        updatedExpandedNodeIds: string[],
        updatedPinnedNodeIds: string[],
        updatedPinnedGroupMemberNodeIds: Map<string, string[]>,
        updatedUngroupedNodeIds: string[]
    ) => {
        const {
            nodes: updatedNodes,
            edges: updatedEdges
        } = setNodesAndEdgeVisibility(
            updatedGraphNodes, 
            updatedGraphEdges, 
            updatedExpandedNodeIds ?? [],
            updatedPinnedNodeIds ?? [],
            updatedPinnedGroupMemberNodeIds ?? new Map<string, string[]>(),
            updatedUngroupedNodeIds ?? [] 
        );
    
        setNodesAndEdges(
            previousNodes => {
                const hiddenNodeIds = updatedNodes
                    .filter((updatedNode) => updatedNode.hidden)
                    .map((updatedNode) => updatedNode.id);
    
                return updatedNodes.map(node => {
                    const hiddenConnections = node.data.connectedNodeIds
                        ?.filter((connectedNodeId: string) => hiddenNodeIds.includes(connectedNodeId))?.length;
    
                    const previousNodeState = previousNodes.find(
                        previousNode => previousNode.id === node.id
                    );
    
                    // We don't want to lose any old position data so prioritise that if it exists
                    let position = previousNodeState?.position ?? node.position;
    
                    // If the node was previously hidden, was in a group, is or now in a group, or didn't exist, 
                    // originate position in appropriate spot
                    if (!node.hidden && (previousNodeState?.hidden || !previousNodeState)) {
                        // Previously hidden, get position of expanded node
                        const expandedNode = previousNodes.find(
                            previousNode => !previousNode.hidden && expandedNodeids.includes(previousNode.id)
                        );
    
                        // Was in group
                        const groupedNodePosition = previousNodes.find((previousNode) => 
                            !previousNode.hidden &&
                            previousNode.data.sourceNodeIds?.includes(node.id)
                        )?.position;
    
                        // Is now in group
                        const memberNodePosition = previousNodes.find((previousNode) => 
                            !previousNode.hidden &&
                            node.data.sourceNodeIds?.some(sourceNodeId => sourceNodeId === previousNode.id)
                        )?.position;
    
                        
                        position = groupedNodePosition ?? memberNodePosition ?? expandedNode?.position ?? position;
                    }
    
                    return {
                        ...previousNodeState,
                        ...node,
                        position,
                        data: {
                            ...node.data,
                            ...node.hidden && { fixedPosition: undefined },
                            fixedPosition: previousNodeState?.data.fixedPosition,
                            healthState: previousNodeState?.data.healthState,
                            hiddenConnections
                        }
                    };
                });
            },
            updatedEdges
        );
    }, [setNodesAndEdges]);
};
