import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/button/Button';
import { FC } from 'react';
import type { TileTypeSelectorComponentProps } from './TileTypeSelector';

export const TileTypeButtons: FC<TileTypeSelectorComponentProps> = ({ tileTypes, onTileTypeSelected }) => {
    return (
        <div className='flex items-center justify-center space-x-4'>
            {tileTypes.map(({ icon, displayName, name }) => (
                <div className='flex flex-col items-center justify-center' key={name}>
                    <Button
                        variant='secondary'
                        className='flex flex-col justify-center aspect-square'
                        aria-label={displayName}
                        onClick={() => {
                            onTileTypeSelected(name);
                        }}
                    >
                        <FontAwesomeIcon icon={icon} size='2x' fixedWidth />
                    </Button>
                    <span className='mt-2 text-lg'>{displayName}</span>
                </div>
            ))}
        </div>
    );
};
