export default class GenericRepo<T> {
    repository: Record<string, T>;

    constructor(repo: Record<string, T>) {
        this.repository = repo;
    }

    get(lookup: string): T {
        const item = this.repository[lookup];

        // Do some stuff if not in repo ...

        return item;
    }

    list(): string[] {
        return Object.keys(this.repository);
    }
}
