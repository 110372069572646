import { HealthState } from '@squaredup/monitoring';
import { healthStateTextColors } from 'constants/state';

const nonWorkspaceNodeColours = {
    success: 'border-statusHealthyPrimary bg-statusHealthySecondary',
    error: 'border-statusErrorPrimary bg-statusErrorSecondary',
    warning: 'border-statusWarningPrimary bg-statusWarningSecondary',
    unknown: 'border-statusUnknownPrimary bg-statusUnknownSecondary',
    unmonitored: 'border-statusUnmonitoredPrimary bg-statusUnmonitoredSecondary'
};

export const getStylesForNode = (state = 'unknown' as HealthState, isWorkspaceNode: boolean) => {
    return isWorkspaceNode
        ? healthStateTextColors[state]
        : `rounded-full border-4 p-2 ${nonWorkspaceNodeColours[state]}`;
};
