import { LineSeries } from './lineGraphTypes';

/**
 * Generate a function to calculate opacity based on the given values
 * The generated function takes the line ID and the hovered line ID
 * @param activeOpacity Opacity when nothing is hovered, or the current line is hovered
 * @param inactiveOpacity Opacity when something else is hovered
 * @returns
 */
export const getOpacityFn = (activeOpacity: number, inactiveOpacity: number) => {
    return (lineId: string | number, hoverId: string | number | undefined) => {
        if (!hoverId || hoverId === lineId) {
            return activeOpacity;
        } else {
            return inactiveOpacity;
        }
    };
};

/**
 * Determine the area opacity based on the given series
 * @returns Opacity for area of chart
 */
export const getAreaOpacity = (series: LineSeries[]) => {
    if (series.length === 1) {
        return 0.4;
    }
    if (series.length >= 5) {
        return 0.15;
    }
    return 0.3;
};
