import { cn } from '@/lib/cn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defaultTimeframeEnum } from '@squaredup/timeframes';
import Tooltip from 'components/tooltip/Tooltip';
import useDataStream from 'dashboard-engine/hooks/useDataStream';
import { getHealthState } from 'dashboard-engine/util/getHealthStateFromDatastream';
import { getIconForNode } from 'lib/types';
import { upperFirst } from 'lodash';
import { FC } from 'react';
import { NodeWithCanonical } from './common';

const iconBorder = {
    success: 'border-statusHealthyPrimary',
    error: 'border-statusErrorPrimary',
    warning: 'border-statusWarningPrimary',
    unknown: 'border-statusUnknownPrimary',
    unmonitored: 'border-statusUnmonitoredPrimary'
};

export interface GraphNodeDrilldownIconProps {
    node: NodeWithCanonical;
}

export const GraphNodeDrilldownIcon: FC<GraphNodeDrilldownIconProps> = ({ node }) => {
    const { data: healthData } = useDataStream(
        node !== undefined
            ? {
                  dataStreamId: 'datastream-health',
                  timeframe: defaultTimeframeEnum,
                  scope: [`${node?.id}`],
                  options: {
                      // Node could be from any workspace we have access to
                      accessControlType: 'directOrAnyWorkspaceLinks'
                  }
              }
            : undefined
    );

    const nodeState = getHealthState(healthData, node?.id, node?.isCanonical);

    return (
        <Tooltip title={upperFirst(nodeState)} disabled={!nodeState}>
            <div
                className={cn(
                    'flex items-center justify-center p-2 overflow-hidden w-11 h-11 border-2 rounded-full border-transparent text-textPrimary bg-tileBackground',
                    nodeState && `${iconBorder[nodeState]}`
                )}
                data-testid='objectIcon'
                data-teststate={nodeState}
            >
                <FontAwesomeIcon
                    fixedWidth
                    icon={getIconForNode(node ?? {})}
                    className='w-6 h-6 text-textPrimary'
                />
            </div>
        </Tooltip>
    );
};
