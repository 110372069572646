/**
 * Build a sensible error message from the validation failure
 * @param {string} type Input type, e.g. checkbox
 * @param {string} errorType Validation error type, e.g. required
 * @param {string} name Input/field name/label
 * @param {object} validation Validation rules - see React Hook Form
 */
const buildErrorMessage = (type, errorType, name, validation) => {

    // Ensure nice error messages
    if(name[0] !== name[0].toUpperCase()) {
        throw new Error('Input label should always start with a capital');
    }

    switch(errorType) {
        case 'required':
            switch(type) {
                case 'checkbox':
                    return `You must select ${name}.`;
                default:
                    return `${name} is required.`;
            }
        case 'maxLength':
            return `${name} cannot exceed ${validation.maxLength.value || validation.maxLength} characters.`;
        case 'minLength':
            return `${name} should be at least ${validation.minLength.value || validation.minLength} characters.`;
        case 'max':
            return `${name} cannot be higher than ${validation.max.value || validation.max}`;
        case 'min':
            return `${name} cannot be lower than ${validation.min.value || validation.min}`;
        case 'pattern':
            return `${name} did not match the required pattern.`;
        default:
            return 'This value is invalid.';
    }
};

export default buildErrorMessage;
