import { useWorkspaceACL } from 'components/workspaceModals/EditWorkspaceForm';
import { Workspace } from 'services/WorkspaceService';
import { useGroups } from './useGroups';

/*
 * fetches all of the users and groups for a workspace and returning the names of those users.
 * @params workspaceId: the id of the workspace
 * @returns An array of string representing the names of all the users in a workspace
 */
export const useUsersNamesInWorkspace = (id: Workspace['id']) => {
    const groups = useGroups({ suspense: true });
    const workspaceACL = useWorkspaceACL({ id, suspense: true });

    const membersInWorkspace = workspaceACL.data?.acl.map(
        (user) => groups.data?.find((group) => group.id === user.subjectId)?.displayName ?? user.subjectId
    );

    return membersInWorkspace ?? [];
};
