import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Serialised } from '@squaredup/ids';
import { AlertingRuleV2, ChannelId, ChannelTypeIds } from '@squaredup/monitoring';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';
import type { ProjectedChannel } from 'dynamo-wrapper';
import { ReactNode, useMemo, useState } from 'react';
import { ChannelCreate, ChannelType } from 'services/NotificationChannelService';
import { DashboardMonitors, NewChannel, NotificationRuleToCreate } from './Monitoring';
import { NotificationRuleAddChannelPanel } from './modal-panels/NotificationRuleAddChannelPanel';
import { NotificationRuleDestinations } from './modal-panels/NotificationRuleDestinations';
import { NotificationRuleSummary } from './modal-panels/NotificationRuleSummary';
import { NotificationRuleTrigger } from './modal-panels/NotificationRuleTrigger';
import { Tier } from '@squaredup/tenants';

export interface AddEditNotificationRuleProps {
    rule: AlertingRuleV2;
    isEditing: boolean;
    onSave: (data: NotificationRuleToCreate) => Promise<void>;
    onClose: () => void;
    channels: Serialised<ProjectedChannel>[];
    channelTypes: Serialised<ChannelType>[];
    dashboards: DashboardMonitors[];
    tier: Tier | undefined;
}

type PanelName = 'summary' | 'triggers' | 'destination' | 'addEditForm';

export interface AddEditNotificationRulePanelProps {
    channels: Serialised<ProjectedChannel>[];
    rule: AlertingRuleV2;
    setActivePanel: (panel: PanelName) => void;
    setRule: (rule: AlertingRuleV2) => void;
}

interface panelStrings {
    title: string;
    backText?: {
        text: string;
        panel: PanelName;
    };
    description?: string | ReactNode;
}

const createTempChannel = (config: ChannelCreate): Serialised<ProjectedChannel> => ({
    enabled: true,
    displayName: config.displayName,
    channelTypeId: config.channelTypeId,
    id: new ChannelId().value,
    name: '',
    tenant: 'ten-',
    type: 'channel',
    status: {
        failed: false,
        failureDate: 0,
        failureReason: ''
    }
});

const getChannelTypeHelpArticle = (channelType?: Serialised<ChannelType>): ReactNode | undefined => {
    const url = channelType?.externalLinks.find((link) => link.isKbArticle)?.url;
    return url ? (
        <div className='mb-4 -mt-2 text-textSecondary' data-testid='destination-kb-link'>
            For help adding this destination, visit our{' '}
            <Button type='button' variant='link' href={url}>
                help article
            </Button>
            .
        </div>
    ) : undefined;
};

export const AddEditNotificationRuleModal: React.FC<AddEditNotificationRuleProps> = ({
    rule,
    isEditing,
    onSave,
    onClose,
    channels,
    channelTypes,
    dashboards,
    tier
}) => {
    const [workingRule, setWorkingRule] = useState(rule);
    // Control which panel is currently shown in the modal, always default to summary
    const [activePanel, setActivePanel] = useState<PanelName>('summary');
    const [selectedChannelType, setSelectedChannelType] = useState<Serialised<ChannelType>>();
    const [addedChannel, setAddedChannel] = useState<Serialised<ProjectedChannel>>();
    const [newChannelConfig, setNewChannelConfig] = useState<NewChannel>();
    const [isModalValid, setIsModalValid] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const availableChannels = useMemo(
        () => (addedChannel ? channels.concat(addedChannel) : channels),
        [addedChannel, channels]
    );

    // Allow us to mutate the title as we move through the modal
    const panelStrings = new Map<PanelName, panelStrings>([
        [
            'summary',
            {
                title: `${isEditing ? 'Edit' : 'Add'} notification rule`
            }
        ],
        [
            'triggers',
            {
                title: 'Trigger',
                backText: {
                    panel: 'summary',
                    text: 'Cancel'
                }
            }
        ],
        [
            'destination',
            {
                title: selectedChannelType?.displayName ?? 'Destination',
                backText: {
                    panel: 'summary',
                    text: 'Back'
                }
            }
        ],
        [
            'addEditForm',
            {
                title: `Add ${selectedChannelType?.displayName} destination`,
                backText: {
                    panel: 'destination',
                    text: 'Cancel'
                },
                description: getChannelTypeHelpArticle(selectedChannelType)
            }
        ]
    ]);

    const { title: modalTitle, description: modalDescription, backText } = panelStrings.get(activePanel) ?? {};

    // Set the preheader above the modal title
    const modalPreHeader = backText ? (
        <div className='mb-2 -ml-1 text-textSecondary' data-testid='header-back-link'>
            <Button type='button' variant='tertiary' onClick={() => setActivePanel(backText?.panel ?? 'summary')}>
                <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
                <span className='ml-1 font-normal'>{backText?.text}</span>
            </Button>
        </div>
    ) : undefined;

    const commonProps = {
        rule: workingRule,
        channels: availableChannels,
        setActivePanel: setActivePanel,
        setRule: setWorkingRule
    };

    return (
        <Modal
            title={modalTitle}
            close={onClose}
            disableClickOutside={true}
            preHeader={modalPreHeader} // Add back link
            headerContent={<></>} // remove close X
            fullWidth
            maxWidth='max-w-[50rem]'
            description={modalDescription}
        >
            <hr className='border-dividerPrimary' />
            <div className='flex flex-col pt-5 overflow-hidden'>
                {/* Summary, which shows trigger and destination, with save/cancel buttons */}
                {activePanel === 'summary' && (
                    <NotificationRuleSummary
                        {...commonProps}
                        dashboards={dashboards}
                        channelTypes={channelTypes}
                        setChannelType={setSelectedChannelType}
                        setIsValid={setIsModalValid}
                        tier={tier}
                    >
                        <ModalButtons>
                            <Button type='button' variant='tertiary' onClick={onClose} data-testid='cancel-button'>
                                Cancel
                            </Button>
                            <Button
                                data-testid='save-button'
                                type='button'
                                disabled={!isModalValid || isSaving}
                                variant='primary'
                                onClick={async () => {
                                    setIsSaving(true);
                                    await onSave({
                                        rule: workingRule,
                                        destinationConfigs: newChannelConfig ? [newChannelConfig] : []
                                    });
                                    onClose();
                                }}
                            >
                                {isSaving ? <LoadingSpinner size={18} /> : 'Save'}
                            </Button>
                        </ModalButtons>
                    </NotificationRuleSummary>
                )}
                {/* Trigger settings, which shows next button and <Cancel in the header */}
                {activePanel === 'triggers' && (
                    <NotificationRuleTrigger {...commonProps} dashboards={dashboards}>
                        <ModalButtons>
                            <Button type='submit'>Save</Button>
                        </ModalButtons>
                    </NotificationRuleTrigger>
                )}
                {/* DestinationPicker, which allows adding or choosing an existing destination. */}
                {activePanel === 'destination' && (
                    <NotificationRuleDestinations {...commonProps} selectedChannelType={selectedChannelType} />
                )}
                {/* Form for adding a new destination of chosen type. Next button with <Cancel in header. */}
                {activePanel === 'addEditForm' && (
                    <NotificationRuleAddChannelPanel
                        {...commonProps}
                        existingChannelNames={availableChannels.map((c) => c.displayName ?? '')}
                        setIsValid={setIsModalValid}
                        channelType={selectedChannelType}
                        saveNewChannel={(config) => {
                            const newChannel = createTempChannel(config);
                            setAddedChannel(newChannel);
                            setNewChannelConfig({ channel: config, tempId: newChannel.id });
                            setWorkingRule({ ...workingRule, channels: [{ id: newChannel.id }] });
                            setActivePanel('summary');
                        }}
                    >
                        {selectedChannelType?.id !== ChannelTypeIds.Zapier && (
                            <ModalButtons hideTopMargin>
                                <Button type='submit' data-testid='submit-destination' disabled={!isModalValid}>
                                    Next
                                </Button>
                            </ModalButtons>
                        )}
                    </NotificationRuleAddChannelPanel>
                )}
            </div>
        </Modal>
    );
};
