import { Serialised } from '@squaredup/ids';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AGENT_GROUPS, AgentGroupResponse, Delete } from 'services/AgentGroupService';
import { AGENTS } from 'services/AgentService';

type AgentGroupDeleteModalProps = {
    group: Serialised<AgentGroupResponse>;
    onClose: () => void;
};

function AgentGroupDeleteModal({ group, onClose }: AgentGroupDeleteModalProps) {
    const [isDeleting, setDeleting] = useState(false);

    const queryClient = useQueryClient();
    const { mutate: handleDelete } = useMutation(
        () => {
            setDeleting(true);
            return Delete(group.id);
        },
        {
            onSettled: async () => {
                await Promise.allSettled([
                    queryClient.invalidateQueries([AGENT_GROUPS]),
                    queryClient.invalidateQueries([AGENTS])
                ]);
                onClose();
            }
        }
    );

    const description = 'Are you sure you want to permanently delete this agent group?';

    return (
        <Modal
            title={`Delete Agent Group: ${group.displayName}`}
            description={description}
            close={onClose}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <ModalButtons>
                <Button type='button' variant='tertiary' onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    type='submit'
                    variant='destructive'
                    onClick={handleDelete}
                    disabled={isDeleting}
                    data-testid='deleteConfirm'
                >
                    {isDeleting ? <LoadingSpinner size={18} /> : 'Delete'}
                </Button>
            </ModalButtons>
        </Modal>
    );
}

export default AgentGroupDeleteModal;
