import { sortBy } from 'lodash';
import { useState } from 'react';
import Auth from 'services/Auth';

const DATASTREAM_STORE_KEY = 'SQUP_RECENT_DATASTREAMS';

const STORE_LIMIT = 20;

const loadRecentDataStreams = (key: string) => {
    const itemString = window.localStorage.getItem(key) ?? '[]';
    let items: string[];
    try {
        items = JSON.parse(itemString);
    } catch (_e) {
        items = [];
    }
    return items.slice(0, STORE_LIMIT);
};

const storeRecentDataStreams = (key: string, streams: string[]) => {
    window.localStorage.setItem(key, JSON.stringify(streams));
};

export const useRecentDataStreamStore = () => {
    const userId = Auth.user?.id ?? '';
    const key = `${DATASTREAM_STORE_KEY}-${userId}`;
    const [recentDataStreams, setRecentDataStreams] = useState(() => loadRecentDataStreams(key));

    const saveRecentDataStream = (recentDataStreamId: string) => {
        if (recentDataStreams.includes(recentDataStreamId)) {
            const newItems = sortBy(recentDataStreams, [(item) => !(item === recentDataStreamId)]);
            setRecentDataStreams(newItems);
            storeRecentDataStreams(key, newItems);
            return;
        }
        const newItems = [recentDataStreamId, ...recentDataStreams.slice(0, STORE_LIMIT - 1)];
        setRecentDataStreams(newItems);
        storeRecentDataStreams(key, newItems);
    };

    const clearCache = () => {
        window.localStorage.removeItem(key);
    };

    return {
        recentDataStreams,
        saveRecentDataStream,
        clearCache
    };
};
