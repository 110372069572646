import { TooltipButton } from '@/components/Button';
import { faCodeBranch, faCodeCompare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetNetworkNode } from 'components/map/context/NetworkMapStoreContext';
import { NodeAction, useHandleNodeAction } from 'components/map/hooks/useHandleNodeAction';
import { MouseEvent, useCallback } from 'react';

const getNodeAction = (isPinned?: boolean, isExpanded?: boolean, isGroup?: boolean) => {
    // We don't want to amend pinning if we're collapsing the only pinned node
    if (!isPinned && !isExpanded) {
        return isGroup ? NodeAction.pinAndExpandGroup : NodeAction.pinAndExpand;
    }

    return isGroup ? NodeAction.expandGroup : NodeAction.expand;
};

interface ExpandButtonProps {
    id: string;
    isPinned?: boolean;
    isExpanded?: boolean;
    hiddenConnections?: number;
    className?: string;
}

export const ExpandButton: React.FC<ExpandButtonProps> = ({
    id,
    isPinned,
    isExpanded,
    hiddenConnections,
    className
}) => {
    const { sourceNodeIds } = useGetNetworkNode()(id)?.data ?? {};
    const isGroup = Boolean(sourceNodeIds?.length);

    const handleNodeAction = useHandleNodeAction();

    const handleClick = useCallback(
        (e: MouseEvent<Element>) => {
            e.stopPropagation();
            handleNodeAction(getNodeAction(isPinned, isExpanded, isGroup), {
                nodeIds: [id],
                ...(isGroup && { sourceNodeIds })
            });
        },
        [handleNodeAction, id, isExpanded, isPinned, isGroup, sourceNodeIds]
    );

    return (
        <TooltipButton
            variant='tertiary'
            onClick={handleClick}
            title={isExpanded ? 'Collapse' : 'Expand'}
            disabled={!isExpanded && !hiddenConnections}
            className={className}
        >
            <FontAwesomeIcon icon={isExpanded ? faCodeCompare : faCodeBranch} fixedWidth />
            {Boolean(hiddenConnections) && <span className='ml-1'>{hiddenConnections}</span>}
        </TooltipButton>
    );
};
