import Editor, { Monaco } from '@monaco-editor/react';
import Color from 'color';
import LoadingSpinner from 'components/LoadingSpinner';

interface PayloadViewerProps {
    name: string;
    provider?: string;
    testResult?: Record<string, unknown>;
}

/**
 * PayloadViewer - this is the first example of a control that can act as the receiver of interactive plugin test output
 * (i.e. be specified in the testResultField property of a fieldGroup control).
 *
 * @example
 * `<PayloadViewer />`
 */
export default function PayloadViewer(props: PayloadViewerProps) {
    return (
        <div className='w-full p-2 mb-2 overflow-hidden text-left break-all border h-80 border-outlinePrimary bg-componentBackgroundSecondary'>
            <PayloadViewerInner {...props} />
        </div>
    );
}

interface PayloadViewerInnerProps {
    name: string;
    provider?: string;
    testResult?: Record<string, unknown>;
}
function PayloadViewerInner(props: PayloadViewerInnerProps) {

    if (props.testResult?.state === 'pending') {
        return <LoadingSpinner />;
    }

    if (props.testResult?.state !== 'received' ||
        !Array.isArray((props?.testResult?.result as any)?.result?.messages) ||
        ((props.testResult!.result! as any).result.messages as Array<unknown>).length <= 0
    ) {
        return <span>(No results)</span>;
    }

    if ((props.testResult!.result! as any).result.messages[0].status === 'error') {
        return <span className='text-statusErrorPrimary'>{(props!.testResult!.result! as any).result.messages[0].message}</span>;
    }

    const payload = (props!.testResult!.result! as any).result.messages[0].payload;

    const handleEditorWillMount = (monacoEditor: Monaco) => {
        const editorBackground = Color(getComputedStyle(document.body).getPropertyValue('--componentBackgroundSecondary')).hex();

        monacoEditor.editor.defineTheme('squpTheme', {
            base: document.body.dataset.theme === 'dark' ? 'vs-dark' : 'vs',
            inherit: true,
            rules: [{ background: editorBackground } as { background: string; token: string }],
            colors: {
                'editor.background': editorBackground,
                'scrollbar.shadow': '#ffffff00'
            }
        });
    };

    return (
        <Editor
            language='json'
            theme='squpTheme'
            wrapperProps={{
                className: 'min-h-[inherit]'
            }}
            loading={<span>Loading editor...</span>}
            beforeMount={handleEditorWillMount}
            defaultValue={JSON.stringify(payload, null, 2)}
            options={{
                scrollBeyondLastLine: false,
                minimap: { enabled: false },
                renderLineHighlight: 'none',
                padding: { top: 10 },
                automaticLayout: true,
                readOnly: true
            }}
        />
    );
}
