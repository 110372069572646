import { BuiltInGroups, isBuiltInGroup } from '@squaredup/constants';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import Form from 'components/forms/form/Form';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ifNotOutside, ModalButtons } from 'components/Modal';
import type { UserGroupModel } from 'dynamo-wrapper';
import { difference } from 'lodash';
import { MutationFunction, useMutation, useQuery, useQueryClient } from 'react-query';
import { ACL_USER_GROUPS_QUERY_KEY } from 'services/AccessControlService';
import {
    AddUserToGroup,
    CreateGroup,
    GetUsers,
    RemoveUserFromGroup,
    TENANT_USER_GROUPS_QUERY_KEY,
    TENANT_USERS_QUERY_KEY,
    UpdateGroup,
    USER_GROUPS_QUERY_KEY
} from 'services/UserService';
import { ValidationValue } from 'types/ValidationValue';

interface CreateEditUserGroupModalProps {
    userGroup?: UserGroupModel;
    onClose: () => void;
}

function CreateEditUserGroupModal({ userGroup, onClose }: CreateEditUserGroupModalProps) {
    const queryClient = useQueryClient();
    const isEditing = Boolean(userGroup);
    const isAdministratorsGroup = isEditing && userGroup?.name === BuiltInGroups.Administrators.name;

    const { data: users, isLoading: isLoadingUsers } = useQuery(TENANT_USERS_QUERY_KEY, GetUsers);

    const mutationOptions = { onSuccess: () => reloadList() };
    const { mutateAsync: saveUserGroup, isLoading } = useMutation(
        (isEditing ? (data: any) => updateGroup(userGroup!, data) : CreateGroup) as unknown as MutationFunction<
            any,
            string
        >,
        mutationOptions
    );

    function reloadList() {
        queryClient.removeQueries(USER_GROUPS_QUERY_KEY);
        queryClient.invalidateQueries(TENANT_USERS_QUERY_KEY);
        queryClient.invalidateQueries(TENANT_USER_GROUPS_QUERY_KEY);
        queryClient.invalidateQueries(ACL_USER_GROUPS_QUERY_KEY);
    }

    const validateUsers = (value: unknown[]) => {
        if (isAdministratorsGroup) {
            return value?.length > 0 || 'At least one Administrator is required';
        }
        return true;
    };

    const handleSubmit = async (data: any) => {
        const { users: usersData, ...formData } = data;

        const processedData = {
            ...formData,
            ...(usersData && { users: usersData.map((user: any) => user.value) })
        };

        await saveUserGroup(processedData);
        onClose();
    };

    const formDefaults = userGroup
        ? {
              displayName: userGroup.displayName,
              description: userGroup.description,
              users: userGroup.users?.map((user) => ({
                  label: user,
                  value: user
              }))
          }
        : {};

    return (
        <Modal
            title={isEditing ? 'Edit user group' : 'Add user group'}
            close={ifNotOutside(onClose)}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <Form submit={handleSubmit} defaultValues={formDefaults} resetOnSubmit={true}>
                {(isValid, isSubmitting) => (
                    <>
                        <div className='px-8'>
                            {(!userGroup || !isBuiltInGroup(userGroup?.name || '')) && (
                                <>
                                    <Field.Input
                                        name='displayName'
                                        label='Name'
                                        type='text'
                                        placeholder='Enter a name'
                                        validation={{ required: true, maxLength: { value: 256 } as ValidationValue }}
                                    />

                                    <Field.Input
                                        name='description'
                                        label='Description'
                                        type='text'
                                        placeholder='Enter a description'
                                    />
                                </>
                            )}

                            <Field.Input
                                name='users'
                                label='Users'
                                type='autocomplete'
                                placeholder='Select one or more users'
                                options={users?.map(({ name }) => ({ label: name, value: name })) || []}
                                isLoading={isLoadingUsers}
                                validation={{
                                    validate: validateUsers
                                }}
                            />
                        </div>

                        <ModalButtons>
                            <Button type='button' variant='tertiary' onClick={() => onClose()}>
                                Cancel
                            </Button>
                            <Button type='submit' disabled={isSubmitting || !isValid}>
                                {isSubmitting || isLoading ? <LoadingSpinner /> : 'Save'}
                            </Button>
                        </ModalButtons>
                    </>
                )}
            </Form>
        </Modal>
    );
}

async function updateGroup(original: UserGroupModel, updated: UserGroupModel) {
    if (!isBuiltInGroup(original.name!)) {
        await UpdateGroup(original.id!, {
            displayName: updated.displayName!,
            description: updated.description
        });
    }

    const usersToAdd = difference(updated.users, original.users!);
    const usersToRemove = difference(original.users, updated.users!);

    const userUpdates: Promise<void>[] = [];
    usersToAdd?.forEach((userToAdd) => userUpdates.push(AddUserToGroup(original.id!, userToAdd)));
    usersToRemove?.forEach((userToRemove) => userUpdates.push(RemoveUserFromGroup(original.id!, userToRemove)));
    return Promise.all(userUpdates);
}

export default CreateEditUserGroupModal;
