import useResizeObserver from '@react-hook/resize-observer';
import { useLayoutEffect, useState } from 'react';

export const useSize = <T extends HTMLElement>(
    target: React.RefObject<T> | T | null,
    useClientSize?: boolean
): [number, number] => {
    const widthKey = useClientSize ? 'clientWidth' : 'offsetWidth';
    const heightKey = useClientSize ? 'clientHeight' : 'offsetHeight';

    const [size, setSize] = useState<[number, number]>(() => {
        const targetEl = target && 'current' in target ? target.current : target;

        return targetEl
            ? [targetEl[widthKey], targetEl[heightKey]]
            : [0, 0];
    });
  
    useLayoutEffect(() => {
        const targetEl = target && 'current' in target ? target.current : target;

        if (!targetEl) {
            return;
        }
        setSize([targetEl[widthKey], targetEl[heightKey]]);
    }, [heightKey, target, widthKey]);
  
    // Where the magic happens
    useResizeObserver(target, (entry) => {
        const eventTarget = entry.target as HTMLElement;
        setSize([eventTarget[widthKey], eventTarget[heightKey]]);
    });
  
    return size;
};