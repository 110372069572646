import Button from 'components/button/Button';
import LoadingSpinner from 'components/LoadingSpinner';

interface SubmitButtonProps {
    isSubmitting: boolean;
    testLoading: boolean;
    supportsValidation?: boolean;
    isDirty: boolean;
    testData: any;
    editMode: 'new' | 'newWithDraft' | 'existing';
    isValid: boolean;
    errors: any;
}

export default function SubmitButton({
    isSubmitting,
    testLoading,
    supportsValidation,
    isDirty,
    testData,
    editMode,
    isValid,
    errors
}: SubmitButtonProps) {
    const submitText = editMode === 'existing' ? 'Update' : 'Add';
    const submittingText = editMode === 'existing' ? 'Updating' : 'Adding';

    return (
        <Button
            type='submit'
            disabled={isSubmitting || testLoading || !isValid || Object.keys(errors).length > 0}
            data-testid='addPluginConfigModalButton'
        >
            {isSubmitting && !testLoading && (
                <span className='inline-flex space-x-2'>
                    <span>{submittingText}</span>
                    <LoadingSpinner size={18} />
                </span>
            )}
            {testLoading && (
                <span className='inline-flex space-x-2'>
                    <span>Testing</span>
                    <LoadingSpinner size={18} />
                </span>
            )}
            {supportsValidation && !isSubmitting && !testLoading && (
                <>
                    {testData && !isDirty && (
                        <>
                            {testData?.testState === 'warning' && `${submitText} with warnings`}
                            {testData?.testState === 'error' && `${submitText} with errors`}
                            {testData?.testState === 'success' && submitText}
                        </>
                    )}

                    {(!testData || isDirty) && `Test and ${submitText.toLowerCase()}`}
                </>
            )}

            {!isSubmitting && !supportsValidation && submitText}
        </Button>
    );
}
