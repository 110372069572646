import Text from '@/components/Text';
import { cn } from '@/lib/cn';

type LimitValues = {
    title: string;
    value: number;
    limit: number;
    name: string;
    warningRange?: number;
    description?: string;
};

const ColourLine = ({ value, limit, warningRange, name }: Omit<LimitValues, 'title'>) => {
    const colour =
        value >= limit
            ? 'bg-statusErrorPrimary'
            : value >= (warningRange ?? limit)
              ? 'bg-statusWarningPrimary'
              : 'bg-upgrade';
    return (
        <div className='w-full h-2 mt-1 overflow-hidden rounded-full bg-dividerTertiary'>
            <div
                data-testid={`priority-${name}-bar`}
                className={cn('h-full', colour)}
                style={{ width: `${Math.min((value / limit) * 100, 100)}%` }}
            />
        </div>
    );
};

const NUMBER_FORMATTER = new Intl.NumberFormat('en-us');

export const LimitPriorityDisplay = ({ title, name, value, limit, warningRange, description }: LimitValues) => {
    const valueDisplay = NUMBER_FORMATTER.format(value);
    const limitDisplay = NUMBER_FORMATTER.format(limit);
    return (
        <div className='mb-4' data-testid={`priority-${name}`}>
            <Text.Body>{title}</Text.Body>
            <div className='grid grid-cols-2'>
                <span className='flex'>
                    <Text.H2 className={cn(value >= limit ? 'text-statusErrorPrimary' : '')}>
                        {valueDisplay} / {limitDisplay}
                    </Text.H2>
                    <Text.SmallBody className='content-end pb-2 ml-2'>{description}</Text.SmallBody>
                </span>
                <Text.Label className='content-end block w-full ml-auto text-right'>Limit</Text.Label>
            </div>
            <ColourLine value={value} limit={limit} warningRange={warningRange} name={name} />
            <div className='w-full ml-auto text-right'>
                <Text.Label>{limitDisplay}</Text.Label>
            </div>
        </div>
    );
};
