import * as Constants from '@squaredup/constants';
import { ClientDataStreamRequest } from '@squaredup/data-streams';
import { Edge, Node } from '@squaredup/graph';
import { getConfigIdFromWorkspaceId } from '@squaredup/ids';
import { stateValues } from '@squaredup/monitoring';
import { defaultTimeframeEnum, getTimeframe } from '@squaredup/timeframes';
import { graphDataToNetworkData, isScope } from 'dashboard-engine/util/graphDataToNetworkData';
import { base62Decode } from 'lib/base62';
import { requestData } from '../../../../services/DataStreamService';
import { Query } from '../../../../services/GraphService';
import { CURRENT_WORKSPACE } from '../../../../services/WorkspaceService';
import { DatasourceFunction } from '../../../types/Datasource';
import { GraphData } from '../../../types/data/GraphData';
import { GraphNetworkConfig } from './Config';

const nodeIdPrefix = Constants.GraphTypes.NODE + '-';

function isScopeId(nodeId: string) {
    if (!nodeId || !nodeId.startsWith(nodeIdPrefix)) {
        return false;
    }

    const encodedSourceId = nodeId.split('-')[1];

    if (!encodedSourceId) {
        return false;
    }

    return base62Decode(encodedSourceId).startsWith('scope-');
}

const queryBase = 'g.V()';

const graphNetwork: DatasourceFunction<GraphData, GraphNetworkConfig> = async(
    config, 
    context
) => {
    const workspaceId = sessionStorage.getItem(CURRENT_WORKSPACE) ?? '';
    const id = config.id || context?.id;
    const type = config.type;
    const types = config.types;
    const excludeResolvedTypes = config.excludeResolvedTypes;
    const configIds = config.configIds;
    const customQuery = config.query;
    const sourceTypeContains = config.sourceTypeContains;

    let query = customQuery || queryBase;
    let bindings: Record<string, any> = {};

    if (type) {
        query += '.has("type", type)';
        bindings.type = type;
    }

    if (types && types.length > 0) {
        query += '.has("type", within(types))';
        bindings.types = types;
    }

    if (Array.isArray(configIds) && configIds.length > 0) {
        query += '.has("__configId", within(configIds))';
        bindings.configIds = configIds;
    }

    if (sourceTypeContains) {
        query += '.has("sourceType", containing(sourceTypeContains))';
        bindings.sourceTypeContains = sourceTypeContains;
    }

    if (id) {
        query += '.has("id", within(id))';
        bindings.id = id;
    }

    if (query === queryBase) {
        // No custom query or filters were specified so default to getting the
        // workspace scoped objects of the current workspace (if there is one)
        if (workspaceId) {
            const configId = getConfigIdFromWorkspaceId(workspaceId);
            query += `.has('sourceType', within('squaredup/scope', 'squaredup/space')).has('__configId', '${configId}')`;
        }
    }

    query += `.limit(${config.nodesToGetConnectionsLimit || 100}).aggregate("nodesVisited")
        .bothE().aggregate("edgesVisited")`;

    // If config doesn't set then default to getting neighbors only for
    // known single node networks (i.e. id was provided)
    if (config.getNeighbors === true || (config.getNeighbors === undefined && id)) {
        query +=
        `.bothV()${excludeResolvedTypes ? '.not(has("type", within(excludeResolvedTypes)))' : ''}.aggregate("nodesVisited")
        .optional(
            inE("is").aggregate("edgesVisited").otherV().aggregate("nodesVisited")
            .bothE().aggregate("edgesVisited").otherV().aggregate("nodesVisited")
        )
        .optional(outE("is").aggregate("edgesVisited").otherV().aggregate("nodesVisited"))`;
    }

    // format visited nodes and edges and dedup the final aggregates
    query += `.cap("edgesVisited").unfold().dedup().aggregate("edges").cap("nodesVisited")
              .unfold().dedup().valueMap(true).aggregate("nodes").cap("nodes", "edges")`;

    if (excludeResolvedTypes) {
        bindings.excludeResolvedTypes = excludeResolvedTypes;
    }

    const body = { 'gremlinQuery': query, bindings, workspaceId };

    const gremlinResults = await Query(body, config.accessControlType);

    const nodes = gremlinResults.gremlinQueryResults[0].nodes;
    const edges = gremlinResults.gremlinQueryResults[0].edges;

    let data = { nodes, edges };

    const isScopeNodeId = nodes != null && id != null && isScopeId(id);
    const skipResolveScopes = config.skipResolveScopes === true ||
        (config.skipResolveScopes === undefined && id && !isScopeNodeId);

    if (!skipResolveScopes) {
        data = await resolveScopeNodes(data, workspaceId, excludeResolvedTypes);
    }

    if (!config.skipHealth) {
        // must come after resolving scopes and before collapsing canonicals
        data = await addHealthState(data, workspaceId);
    }

    if (!config.skipCollapseCanonical) {
        data = rewriteSourceToCanonical(data);
    }

    data = graphDataToNetworkData(data);

    return {
        data
    };
};

type NodesAndEdges = {
    nodes: Node[];
    edges: Edge[]
};

/**
 * Finds Scope nodes and resolves their queries
 * Returns an expanded collection of nodes and edges
 */
const resolveScopeNodes = async (
    {nodes, edges}: NodesAndEdges, workspaceId: string, excludeResolvedTypes?: string[]
): Promise<NodesAndEdges> => {
    const scopes = nodes.filter(isScope);
    if (!scopes.length) {
        return { nodes, edges }; //nothing to do
    }

    const newData = await Promise.all(scopes.map(async (s): Promise<NodesAndEdges> => {
        if (!s.query?.[0]) {
            return { nodes: [], edges: []};
        }
        // get query
        const query = (s.query as string[])[0];
        // resolve to nodes, ensuring we dedup for canonicals
        const { gremlinQueryResults: newNodes } = await Query({
            gremlinQuery: `${query}
                ${excludeResolvedTypes ? '.not(has("type", within(excludeResolvedTypes)))' : ''}
                .optional(out("is")).dedup().valueMap(true)`,
            bindings: {
                ...JSON.parse(s.bindings?.[0] || '{}'),
                excludeResolvedTypes
            },
            workspaceId
        });
        // create list of edges
        const newEdges = newNodes.map(n => ({id: `${s.id}-scopes-${n.id}`, inV: n.id, outV: s.id, label: 'scopes', type: 'edge'}));
        return { nodes: newNodes, edges: newEdges };
    }));

    const newNodes = newData.flatMap(a => a.nodes);
    const newEdges = newData.flatMap(a => a.edges);

    return { nodes: [...nodes, ...newNodes], edges: [...edges, ...newEdges] };
};

/**
 * Replaces any instances of 'source nodes' with canonical nodes, and rewrites any edges that
 * point to/from those the source nodes to point to/from the canonical nodes.
 *
 * Source nodes refer to the nodes imported from a given source, e.g. SCOM Host, Azure VM, New Relic host.
 * e.g.
 * OPP-APP01 (New Relic) ---is---> OPP-APP01 (canonical)
 * OPP-APP01 (New Relic) ----hosts---> Order Processing Platform
 * ends up as
 * OPP-APP01 (canonical) ---hosts---> Order Processing Platform
 */
const rewriteSourceToCanonical = ({nodes, edges}: NodesAndEdges): NodesAndEdges => {

    // Find all canonicals nodes
    const canonicalNodeIdStateMap = nodes.reduce((acc, n) => {
        if (Object.prototype.hasOwnProperty.call(n, '__canonicalType')) {
            acc.set(n.id, stateValues.unknown);
        }
        return acc;
    }, new Map());

    // Create a map of IDs for any 'is' edges (Source ID -> Canonical ID), any edges that reference these need rewriting
    const toRewrite = edges.reduce((acc: Record<string, any>, e) => {
        if (e.label ==='is' && canonicalNodeIdStateMap.has(e.inV)) {
            acc[e.outV] = e.inV;
        }
        return acc;
    }, {});

    // Rewrite any edges that start/end with a 'source ID', and make it start/end to the canonical
    const rewrittenEdges = edges.reduce((acc: Edge[], e) => {
        // Rewrite any edges that originate from a source ID
        const newOutV = toRewrite[e.outV];
        if (newOutV) {
            e.outV = newOutV;
        }
        // Rewrite any edges that point to a source ID
        const newInV = toRewrite[e.inV];
        if (newInV) {
            e.inV = newInV;
        }

        if (!(e.label === 'is' && e.inV === e.outV)) { // Don't include any edges that now point to themselves
            acc.push(e);
        }

        return acc;
    }, []);

    let haveCanonicalWithUpdatedState = false;
    const rewrittenNodes = nodes.reduce((acc: Node[], n) => {
        const canonicalIdOfSourceNode = toRewrite[n.id];
        if (canonicalIdOfSourceNode) {
            // Get state of connected source node and apply to canonical if a worse state but do
            // *NOT* call acc.push for this source node as it's been re-written as the canonical
            const stateValue = (stateValues as never)[`${n.state}`];
            if (stateValue > canonicalNodeIdStateMap.get(canonicalIdOfSourceNode)) {
                canonicalNodeIdStateMap.set(canonicalIdOfSourceNode, stateValue);
                haveCanonicalWithUpdatedState = true;
            }
        } else {
            // Not a source node with an 'is' edge to a canonical so we want it
            acc.push(n);
        }
        return acc;
    }, []);

    if (haveCanonicalWithUpdatedState) {
        // Apply final (worse case) canonical state to canonicals if they have a known (not unknown) state
        const stateValueKeys = Object.keys(stateValues);
        for (const n of rewrittenNodes) {
            const canonicalStateValue = canonicalNodeIdStateMap.get(n.id);
            if (canonicalStateValue) {
                n.state = stateValueKeys[canonicalStateValue];
            }
        }
    }

    return {
        nodes: rewrittenNodes,
        edges: rewrittenEdges
    };
};

const addHealthState = async ({nodes, edges}: NodesAndEdges, workspaceId: string): Promise<NodesAndEdges> => {
    try {
        const request: ClientDataStreamRequest = {
            dataStreamId: 'datastream-health',
            scope: nodes.map((node) => node.id),
            timeframe: getTimeframe(defaultTimeframeEnum),
            // We want to get health for as many of the nodes as we can and the nodes read from the graph
            // have already had access control applied to the read so use the broadest accessControlType
            // to attempt to get health state for all of the nodes that will be displayed.
            options: { accessControlType: 'directOrAnyWorkspaceLinks' }
        };

        const healthStreamData = await requestData(request, true, workspaceId);

        const [ idIndex, stateIndex ] = healthStreamData.metadata.columns.reduce((arr, col, index) => {
            if (col.name === 'data.id') {
                arr[0] = index;
            } else if (col.name === 'data.state') {
                arr[1] = index;
            }
            return arr;
        }, new Array(2));

        const nodeIdToStateMap = new Map();
        if (idIndex >= 0 && stateIndex >= 0) {
            healthStreamData.rows.reduce((map, row) => {
                return map.set(row[idIndex].value, row[stateIndex].value);
            }, nodeIdToStateMap);
        }

        if (nodeIdToStateMap.size > 0) {
            for (const node of nodes) {
                const state = nodeIdToStateMap.get(node.id);
                if (state) {
                    node.state = state;
                }
            }
        }
    } catch (e) {
        // Swallow as we can live without health state - we have up until now!
        // eslint-disable-next-line no-console
        console.log('Failed to add health state to graph network', e instanceof Error ? e.message : e);
    }

    return {
        nodes,
        edges
    };
};

graphNetwork.config = 'GraphNetworkConfig';

export default graphNetwork;
