import { deepResolveLabels } from 'dashboard-engine/util/resolveLabel';
import { useMemo } from 'react';

/**
 * Performs variable resolution for config objects, using context and data, if enabled
 * @param {object} config Config object
 * @param {any} context Dashboard context
 * @param {any} data Tile data
 */
export const useResolvedConfig = (config, context, data) => {
    return useMemo(() => {
        if (config) {
            if (config.disableVariableReplacement === true) {
                return config; // just return config if disabled
            }
            
            return deepResolveLabels(config, { ...(context && ({context})), ...(data && ({data})) });
        }
    }, [config, context, data]);
};