import { stateStrings } from '@squaredup/monitoring';
import { useNetworkBackground } from 'components/map/context/NetworkMapStoreContext';
import { healthStateTextColors } from 'constants/state';

interface ArrowHeadProps {
    state: string, 
    className: string
};

const ArrowHead: React.FC<ArrowHeadProps> = ({ state, className }) => {
    const background = useNetworkBackground();
    
    return (
        <marker
            className={className}
            id={`arrow-head-${background}-${state}`}
            markerUnits='strokeWidth'
            markerWidth={18}
            markerHeight={18}
            viewBox='-10 -10 20 20'
            orient='auto'
            refX='0'
            refY='0'
        >
            <polyline
                style={{ fill: 'currentcolor' }}
                strokeWidth={2}
                strokeLinecap='inherit'
                strokeLinejoin='miter'
                stroke={`var(--${background})`}
                strokeDasharray='30 0'
                fill='none'
                points='-5,-4 0,0 -5,4 -5,-4'
            />
        </marker>
    );
};

export const ArrowHeadMarkers = () => (
    <svg className='w-0 h-0 max-h-0'>
        <defs>
            {Object.keys(stateStrings).map((state) =>
                <ArrowHead 
                    key={`arrow-${state}`}
                    state={state} 
                    className={healthStateTextColors[state as keyof typeof healthStateTextColors]} 
                />
            )}
        </defs>
    </svg>
);
