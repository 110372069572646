import { createContext, useContext } from 'react';

interface ZendeskContextValue {
    labels: string[];
}

const ZendeskContext = createContext<ZendeskContextValue>({
    labels: []
});

ZendeskContext.displayName = 'ZendeskContext';

/**
 * Sets labels for matching Zendesk articles
 * @param labels List of zendesk labels
 */
export const useZendeskLabels = (...labels: string[]) => {
    const context = useContext(ZendeskContext);

    context.labels = labels;
};
