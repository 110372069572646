import { getMonthlyBillingPeriod, toUTCDate } from '@squaredup/utilities';
import { differenceInDays, endOfMonth, startOfMonth } from 'date-fns';
import type { GroupCountResult, MetricsResults } from 'dynamo-wrapper';
import API from './API';
import { handleError, handleResponse } from './util';

export const ALL_DATA_SOURCES_OBJECTS_COUNT_KEY = ['ALL_DATA_SOURCES_OBJECTS', 'COUNT'];
export const PER_DATA_SOURCE_OBJECTS_COUNT_KEY = ['PER_DATA_SOURCE_OBJECTS', 'COUNT'];

/**
 * Get the number of users for the current tenants
 */
export const UserCount = async (): Promise<GroupCountResult[]> =>
    API.post<GroupCountResult[]>('/usage/counts/users').then(handleResponse).catch(handleError);

/**
 * Get the number of indexed nodes in the current tenant
 */
export const NodeCount = async (): Promise<GroupCountResult[]> =>
    API.post<GroupCountResult[]>('/usage/counts/nodes').then(handleResponse).catch(handleError);

/**
 * Get the number of indexed nodes in the current tenant by data source.
 * Optionally specify the data source configIds of interest, if ommitted
 * then results are returned for all data source configIds.
 */
export const DataSourceNodeCount = async (configIds?: string[]): Promise<GroupCountResult[]> =>
    API.post<GroupCountResult[]>('/usage/counts/source-nodes', {
        configIds
    }).then(handleResponse).catch(handleError);

export const MonitorCount = async () =>
    API.post<GroupCountResult[]>('/usage/counts/monitors').then(handleResponse).catch(handleError);

/**
 * Get the current number of monitors tenant wide, without access control
 */
export const GetCountOfMonitors = async () => {
    const result = await API.get<{ count: number }>('/usage/current/monitors').then(handleResponse).catch(handleError);
    return result.count;
};

export const WorkspacesCount = async () =>
    API.post<GroupCountResult[]>('/usage/counts/workspaces').then(handleResponse).catch(handleError);

export const DashboardsCount = async () =>
    API.post<GroupCountResult[]>('/usage/counts/dashboards').then(handleResponse).catch(handleError);

export const DetailedRequestsForBillingPeriod = async (relevantMonth: Date): Promise<MetricsResults[]> => {
    const { start, end } = getMonthlyBillingPeriod(relevantMonth);

    return API.post('/usage/counts/detailedrequests', {
        timeframe: {
            start: start.toISOString(),
            end: end.toISOString()
        },
        dataPoints: differenceInDays(end, start) + 1
    })
        .then(handleResponse)
        .catch(handleError);
};

export const RequestsForCurrentBillingPeriod = async (): Promise<MetricsResults> => {
    const now = new Date();
    const monthStart = startOfMonth(now);
    const monthEnd = endOfMonth(now);
    return API.post<MetricsResults>('/usage/metrics', {
        metrics: ['dataRequestCount'],
        timeframe: {
            // The billing period is always the current month as defined by UTC.
            start: toUTCDate(monthStart).toISOString(),
            end: toUTCDate(monthEnd).toISOString()
        },
        dataPoints: differenceInDays(monthEnd, monthStart)
    })
        .then(handleResponse)
        .catch(handleError);
};
