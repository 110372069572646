import { ResponsiveBar } from '@nivo/bar';
import { date } from '@squaredup/data-streams';
import GraphTooltip from 'components/GraphTooltip';
import NoDataPlaceholder from 'components/NoDataPlaceholder';

import { cn } from '@/lib/cn';
import { DataStreamVisualisation } from 'dashboard-engine/types/Visualisation';
import { opacityGradient } from 'dashboard-engine/util/nivoGradient';
import { formatTickAsDate } from 'dashboard-engine/util/tickDateFormatter';
import { formatTickAsColumn } from 'dashboard-engine/util/tickFormatter';
import { useCallback, useRef, useState } from 'react';
import nivoTheme from '../../util/nivoTheme';
import { LegendWrapper } from '../components/LegendWrapper';
import { LegendPosition } from '../components/Legends';
import { getColor } from '../getColor';
import { NUM_Y_TICKS, getGridYValues } from '../getGridYValues';
import { useVisualizationTheme } from '../hooks/useVisualizationTheme';
import { BarChartCustomBottomAxis } from './BarChartCustomBottomAxis';
import { BarChartTotals } from './BarChartTotals';
import {
    DataStreamBarChartConfig,
    LegacyDataStreamBarChartConfig,
    barGraphBottomLegendPadding,
    barGraphBottomMarginPadding,
    barGraphDefaultBottomMargin,
    barGraphDefaultRightMargin
} from './Config';
import DataStreamBarItem, { LABEL_ALIGN_RIGHT_MIN_WIDTH, LABEL_ALIGN_TOP_MIN_HEIGHT } from './DataStreamBarItem';
import { computedTooltipPoints, legendMaxLength } from './dataUtils';
import { performLegacyBarChartColumnMigration } from './performLegacyBarChartColumnMigration';
import { useColumns } from './useColumns';

const calculateMargins = (
    legendPosition: LegendPosition | undefined,
    xAxisLabelVisible: boolean,
    bottomMargin: number,
    leftMargin: number,
    rightMargin: number,
    topMargin: number
) => {
    let margins = {
        top: topMargin,
        right: rightMargin,
        bottom: barGraphBottomMarginPadding + bottomMargin,
        left: leftMargin
    };
    if (legendPosition === 'bottom') {
        margins.bottom -= 20;
        if (!xAxisLabelVisible) {
            margins.bottom -= 20;
        }
    }

    return margins;
};

export const DataStreamBarChart: DataStreamVisualisation<DataStreamBarChartConfig> = ({
    data,
    config: rawConfig,
    shapingConfig,
    onClick
}) => {
    // Perform migration from legacy bar chart configuration
    const config = performLegacyBarChartColumnMigration(rawConfig as LegacyDataStreamBarChartConfig, data);

    const [bottomMargin, setBottomMargin] = useState(barGraphDefaultBottomMargin);
    // store the right margin for the totals label
    const [totalsLabelRightMargin, setTotalsLabelRightMargin] = useState(barGraphDefaultRightMargin);
    const [borderSize, setBorderSize] = useState(0);
    const [hoveredId, setHoveredId] = useState<string | number | undefined>();
    const topMargin = config.showTotals && config.horizontalLayout !== 'horizontal' ? 20 : 6;
    const rightMargin =
        config.showTotals && config.horizontalLayout === 'horizontal'
            ? totalsLabelRightMargin
            : barGraphDefaultRightMargin;

    const displayMode = config?.displayMode ?? 'actual';
    const isHorizontal = config?.horizontalLayout === 'horizontal';

    const graphRef = useRef<HTMLDivElement>(null);

    const { series, dataKeys, valueColumn, axes, hasSeriesColumn } = useColumns({
        data,
        config,
        displayMode,
        isHorizontal,
        hasSorting: Boolean(shapingConfig?.sort)
    });

    const barGraphTheme = useVisualizationTheme(hasSeriesColumn ? 'bar' : 'singleBar');

    let maxValue: 'auto' | number = 'auto';
    let minValue: 'auto' | number = 'auto';

    if (config.displayMode === 'percentage') {
        maxValue = 100;
    } else if (config.range?.type === 'custom') {
        maxValue = config.range?.maximum ?? 'auto';
        minValue = config.range?.minimum ?? 'auto';
    }

    const isHovered = hoveredId != null;

    const isLegendVertical = config.legendPosition === 'top' || config.legendPosition === 'bottom';

    const marginLeft = Math.max(legendMaxLength(series) * 35, 60);
    const leftAxisLabelOffset = -95;

    const xAxisColumnShape = axes?.x?.column?.shapeName;

    const categories = series.map((d) => d.__group__);

    const xAxisNumericTickFormatter = formatTickAsColumn(axes.x.column, { data, valueColumn: valueColumn?.column });

    const isGrouping = config.grouping;

    const bottomAxis = useCallback(
        (props) => (
            <BarChartCustomBottomAxis
                {...props}
                categories={series.map((d) => d.groupValue)}
                isHorizontal={isHorizontal}
                xAxisColumnShape={xAxisColumnShape}
                graphRef={graphRef}
                xAxisNumericTickFormatter={xAxisNumericTickFormatter}
                setBottomMargin={setBottomMargin}
                setBorderSize={setBorderSize}
                isGrouping={isGrouping}
                hasSeriesColumn={hasSeriesColumn}
                marginLeft={marginLeft}
            />
        ),
        [graphRef, categories, isHorizontal, isGrouping, marginLeft, xAxisColumnShape, xAxisNumericTickFormatter]
    );

    // Determine shape of yAxis if orientation has been flipped
    const horizontalColumnShape = isHorizontal ? axes?.y?.column?.shapeName : '';

    // If yAxis is of shape_date pass it through the D3 scaler, else use the pre-existing format
    const yAxisTickFormatter =
        horizontalColumnShape === date.name
            ? formatTickAsDate(categories)
            : formatTickAsColumn(axes.y.column, { data, valueColumn: valueColumn?.column });

    const showTotals = config.showTotals && config.displayMode !== 'percentage' && !isGrouping;

    // get the formatter for the total values, so they match the value formatting.
    const totalsFormatter =
        horizontalColumnShape === date.name
            ? formatTickAsDate(categories)
            : formatTickAsColumn(
                  isHorizontal ? axes.x.column : axes.y.column,
                  {
                      data,
                      valueColumn: valueColumn?.column
                  },
                  // prevent truncating of value like axes have as bar can be wider.
                  { maxLength: 50 }
              );

    const chartTotals = useCallback(
        (props) =>
            showTotals ? (
                <BarChartTotals
                    {...props}
                    format={totalsFormatter}
                    setTotalsLabelRightMargin={setTotalsLabelRightMargin}
                />
            ) : null,
        [showTotals, setTotalsLabelRightMargin, totalsFormatter]
    );

    if (!series || series.length === 0) {
        return <NoDataPlaceholder />;
    }

    if (series.every((s) => s.value === 0)) {
        return <NoDataPlaceholder message='No non-zero values to display.' />;
    }

    const showGrid = config.showGrid ?? true;

    const legend = dataKeys
        .slice()
        .reverse()
        .map((dataKey, index) => ({
            id: dataKey,
            label: dataKey,
            color: getColor(config, barGraphTheme, dataKey, dataKeys.length - 1 - index),
            isFocused: hoveredId !== undefined ? hoveredId === dataKey : true
        }));

    // Whether labels should be able to overflow the bar item to the right
    const overflowLabelsHorizontal =
        config.horizontalLayout === 'horizontal' && (!hasSeriesColumn || isGrouping) && !showTotals;
    // Whether labels should be able to overflow the bar item on top
    const overflowLabelsVertical =
        config.horizontalLayout === 'vertical' && (!hasSeriesColumn || isGrouping) && !showTotals;

    // a value of 0, means the label will be rendered above/to the side of the bar if it's too short
    const labelSkipWidth = overflowLabelsHorizontal ? 0 : LABEL_ALIGN_RIGHT_MIN_WIDTH;
    const labelSkipHeight = overflowLabelsVertical ? 0 : LABEL_ALIGN_TOP_MIN_HEIGHT;

    const barChart = (
        <div
            data-visualization='data-stream-bar-chart'
            className={cn('absolute inset-0', onClick && 'cursor-pointer')}
            ref={graphRef}
            onClick={onClick}
        >
            <ResponsiveBar
                data={series}
                margin={calculateMargins(
                    config.showLegend ? config.legendPosition : undefined,
                    Boolean(axes.x.label),
                    bottomMargin,
                    marginLeft,
                    rightMargin,
                    topMargin
                )}
                colors={(datum) => getColor(config, barGraphTheme, datum.id, datum.data[`${datum.id}-index`] - 1)}
                layers={['grid', 'axes', 'bars', 'markers', 'legends', bottomAxis, chartTotals]}
                axisTop={null}
                axisRight={null}
                barComponent={DataStreamBarItem}
                axisBottom={{
                    legend: axes.x.label,
                    legendOffset: bottomMargin + barGraphBottomLegendPadding,
                    legendPosition: 'middle',
                    format: xAxisNumericTickFormatter,
                    renderTick: () => null
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickValues: NUM_Y_TICKS,
                    legendPosition: 'middle',
                    format: yAxisTickFormatter,
                    legend: axes.y.label,
                    legendOffset: leftAxisLabelOffset
                }}
                theme={nivoTheme}
                maxValue={maxValue}
                minValue={minValue}
                layout={config.horizontalLayout}
                keys={dataKeys}
                groupMode={hasSeriesColumn && isGrouping ? 'grouped' : 'stacked'}
                enableLabel={config?.showValue}
                label={(d: { id?: string; data?: { [key: string]: string } }) => {
                    return d.data
                        ? config?.displayMode === 'percentage'
                            ? `${d.data[`${d.id}-formatted`]} (${d.data[`${d.id}-percentage`]})`
                            : d.data[`${d.id}-formatted`]
                        : '';
                }}
                indexBy='__group__'
                borderWidth={borderSize}
                borderColor='transparent'
                padding={0.2}
                labelTextColor='white'
                labelSkipWidth={labelSkipWidth}
                labelSkipHeight={labelSkipHeight}
                enableGridY={!isHorizontal}
                enableGridX={isHorizontal}
                gridYValues={getGridYValues(showGrid, minValue, 0)}
                gridXValues={[0]} // Ensure there is a grid line at 0
                defs={[opacityGradient('grouped-bar-gradient', 0.2)]}
                fill={[
                    {
                        match: (d: { id?: string; data?: { [key: string]: string } }) =>
                            isHovered && d.data?.id !== hoveredId,
                        id: 'grouped-bar-gradient'
                    }
                ]}
                onMouseEnter={(e) => setHoveredId(e.id)}
                onMouseLeave={() => setHoveredId(undefined)}
                tooltip={(point) => {
                    const { color, id, indexValue } = point;
                    const formattedFromNivoData = point.data[`${id}-formatted`];

                    return (
                        <GraphTooltip
                            points={[
                                computedTooltipPoints({
                                    color,
                                    id,
                                    indexValue,
                                    formattedFromNivoData,
                                    point,
                                    displayMode
                                })
                            ]}
                            graphRef={graphRef}
                        />
                    );
                }}
            />
        </div>
    );

    if (config.showLegend && config.legendPosition) {
        return (
            <LegendWrapper
                legend={legend}
                position={config.legendPosition}
                containerSizePercentage={isLegendVertical ? 0.3 : 0.25}
                legendContainerClassname='p-5'
                vizRef={graphRef}
                onHover={(properties) => setHoveredId(properties.id)}
                onLeave={() => setHoveredId(undefined)}
            >
                {barChart}
            </LegendWrapper>
        );
    }

    return barChart;
};
