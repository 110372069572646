import { Checkbox as CheckboxPrimitive } from '@/components/forms/Checkbox';
import { cn } from '@/lib/cn';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/tooltip/Tooltip';
import PropTypes from 'prop-types';
import { Controller, RegisterOptions } from 'react-hook-form';

type CheckboxProps = React.ComponentPropsWithoutRef<typeof CheckboxPrimitive> & {
    name: string;
    label: string;
    help?: string;
    checkboxLabel?: string;
    description?: string;
    validation?: RegisterOptions;
    classNames?: string;
};

/**
 * Checkbox
 * A simple checkbox component, not typically used directly. See `Field.Checkbox`.
 *
 * @example
 * ```
 * return (<Checkbox label='example'/>)
 * ```
 */
function Checkbox({
    name,
    label,
    help,
    checkboxLabel,
    description,
    validation,
    disabled,
    ...inputProps
}: CheckboxProps) {
    return (
        <Controller
            name={name}
            rules={validation}
            render={({ field: { onChange, value } }) => (
                <label 
                    className={cn('inline-flex items-center align-middle', !disabled && 'cursor-pointer')}
                    htmlFor={name}
                >
                    <div className='relative flex items-center'>
                        <CheckboxPrimitive
                            {...inputProps}
                            name={name}
                            checked={Boolean(value)}
                            onCheckedChange={(isChecked) => onChange(isChecked ? inputProps.value ?? isChecked : false)}
                            disabled={disabled ?? false}
                        />
                    </div>

                    <span className={cn('min-w-0 select-none ml-sm leading-input text-textPrimary', disabled && 'text-textDisabled')}>
                        {checkboxLabel || label} 
                        {description && <span className='block font-light'>{description}</span>}
                    </span>

                    {help && (
                        <span className='flex-shrink-0 px-2 ml-2 text-textSecondary'>
                            <Tooltip title={help}>
                                <FontAwesomeIcon className='text-base' icon={faCircleQuestion} />
                            </Tooltip>
                        </span>
                    )}
                </label>
            )}
        />
    );
}

Checkbox.propTypes = {
    /**
     * Name/ID of the input, e.g. for output data
     */
    name: PropTypes.string,
    /**
     * Text to be displayed as checkbox label
     */
    label: PropTypes.string.isRequired,
    /**
     * Text to be displayed as checkbox description
     */
    description: PropTypes.string,
    /**
     * Register function for forms - see React Hook Form
     */
    register: PropTypes.func,
    /**
     * Validation rules - see React Hook Form
     */
    validation: PropTypes.object
};

export default Checkbox;
