import { cn } from '@/lib/cn';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';

type ExpandableProps = {
    summary: React.ReactNode;
    children: React.ReactNode;
    initiallyOpen?: boolean;
    summaryStyles?: string;
    childrenStyles?: string;
    scrollIntoViewOnExpand?: boolean;
    summaryDataTestId?: string;
    helpIcon?: unknown;
};

export const DataStreamTileEditorMonitoringExpandable = ({
    summary,
    children,
    initiallyOpen = false,
    summaryStyles = '',
    childrenStyles = '',
    scrollIntoViewOnExpand = false,
    summaryDataTestId = 'expandable',
    helpIcon
}: ExpandableProps) => {
    const detailsElement = useRef<HTMLDivElement>(null);
    const [isExpanded, setExpanded] = useState(initiallyOpen);

    return (
        <details
            ref={detailsElement}
            className='mb-2 overflow-hidden list-none group last:mb-0'
            open={initiallyOpen}
            onToggle={() => {
                if (!isExpanded && scrollIntoViewOnExpand) {
                    detailsElement?.current?.scrollIntoView({ block: 'end' });
                }
                setExpanded(!isExpanded);
            }}
        >
            <summary
                className={`flex items-center w-full py-4 cursor-pointer select-none shrink-0 ${summaryStyles}`}
                data-testid={summaryDataTestId}
            >
                {summary}
                {helpIcon}
                <FontAwesomeIcon className='hidden ml-4 shrink-0 group-open:block' icon={faChevronUp} />
                <FontAwesomeIcon className='block ml-4 shrink-0 group-open:hidden' icon={faChevronDown} />
            </summary>
            <div className={cn('h-full pb-4', childrenStyles)}>{children}</div>
        </details>
    );
};
