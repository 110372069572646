import { getWorkspaceIdFromConfigId } from '@squaredup/ids';
import { DASHBOARD_NODE, DASHBOARD_NODE_SIZE } from 'components/map/components/nodes/DashboardNode';

export const dashboardToNode = (dashboard: any) => {
    return {
        id: dashboard.id,
        type: DASHBOARD_NODE,
        position: {
            x: 0,
            y: 0
        },
        width: DASHBOARD_NODE_SIZE,
        height: DASHBOARD_NODE_SIZE,
        data: {
            label: dashboard.name[0],
            sourceId: dashboard.sourceId[0],
            workspaceId: getWorkspaceIdFromConfigId(dashboard.__configId[0])
        }
    };
};
