import { FC } from 'react';
import Text from '@/components/Text';

type HeaderProps = {
    name: string;
    tooltip: JSX.Element;
    count: number;
}

export const DetailHeader: FC<HeaderProps> = ({ name, tooltip, count}) => (
    <div className='flex flex-col px-4 font-semibold'>
        <div className='flex'>
            <Text.H3>{name}</Text.H3>
            {tooltip}
        </div>
        <div className='text-4xl'>{count}</div>
    </div>
);