import * as React from "react";
const SvgIsdown = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M0 0H30V30H0V0Z", fill: "#202030", style: {
  fill: "#202030",
  fill: "color(display-p3 0.1255 0.1255 0.1882)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M24.5831 7H7.3539L15.985 24.2621L24.5831 7Z", fill: "#E26768", style: {
  fill: "#E26768",
  fill: "color(display-p3 0.8863 0.4039 0.4078)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M20.7518 9.48212H6L13.39 24.2621L20.7518 9.48212Z", fill: "#DE0303", style: {
  fill: "#DE0303",
  fill: "color(display-p3 0.8706 0.0118 0.0118)",
  fillOpacity: 1
} }));
export default SvgIsdown;
