import tileRepo from 'dashboard-engine/repositories/tileRepo';

export const validateConfig = (config: any) => {
    if (!config._type || config._type !== 'layout/grid') {
        return "Expecting a _type property with value 'layout/grid'";
    }

    if (!config.contents || typeof config.contents !== 'object') {
        return 'Expecting a contents array property';
    }

    if (!config.columns || typeof config.columns !== 'number') {
        return 'Expecting a columns numerical property';
    }

    const configContainsErrors =
        config.contents &&
        config.contents.some((tile: any) => {
            const tileComponent = tile.config?._type.split('/').pop();
            return !tileComponent || !tileRepo.get(tileComponent);
        });

    if (configContainsErrors) {
        return 'All tiles must have a valid _type';
    }

    return false;
};

export const displayNameIsValid = (v: string | any[]) => {
    return v && v.length > 0;
};

export const getErrorMessage = (error: Record<string, any>) => {
    switch (error.data.failureCode) {
        case 'PluginLinkPermission': {
            if (error.data?.linksFailed?.plugins.length > 1) {
                return `Your account does not have enough rights to add ${error.data.linksFailed.plugins.length} data sources to the destination workspace. Please contact your administrator.`;
            } else {
                return `Your account does not have enough rights to add data source "${
                    error?.pluginDisplayName ?? 'UNKNOWN'
                }" to the destination workspace. Please contact your administrator.`;
            }
        }
        default: {
            return error.data.failureReason;
        }
    }
};
