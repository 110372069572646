import { Button } from '@/components/Button';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'components/LoadingSpinner';
import Autocomplete from 'components/forms/autocomplete/Autocomplete';
import Field from 'components/forms/field/Field';
import { sortBy } from 'lodash';
import { useScopes } from 'queries/hooks/useScopes';
import React from 'react';
import { useController } from 'react-hook-form';
import { useCurrentWorkspaceId } from 'services/WorkspaceUtil';

interface DashboardVariableObjectVariableFieldsProps {
    setIsScopeModalOpen: (isOpen: boolean) => void;
}

export const DashboardVariableObjectVariableFields: React.FC<DashboardVariableObjectVariableFieldsProps> = ({
    setIsScopeModalOpen
}) => {
    const workspaceId = useCurrentWorkspaceId();
    const { data: scopes, isLoading } = useScopes(workspaceId);

    const { field } = useController({
        name: 'scope',
        rules: { required: true }
    });

    const scopeOptions = sortBy(
        (scopes || []).map((s: { displayName: string; id: string }) => ({
            label: s.displayName,
            value: s.id
        })),
        (scope) => scope.label.toLowerCase()
    );

    return (
        <Field label='Objects'>
            <div className='flex gap-2'>
                {isLoading ? (
                    <div className='flex flex-1 items-center'>
                        <LoadingSpinner />
                    </div>
                ) : (
                    <Autocomplete
                        name='scope'
                        placeholder='Select collection'
                        isMulti={false}
                        className='border-none ring-inset ring-1 ring-outlinePrimary rounded-input bg-componentBackgroundPrimary'
                        options={scopeOptions}
                        onChange={(value) => {
                            if (value == null) {
                                field.onChange(null);
                            } else {
                                field.onChange((value as { value: string }).value);
                            }
                        }}
                    />
                )}

                <Button
                    type='button'
                    variant='secondary'
                    onClick={() => setIsScopeModalOpen(true)}
                    icon={<FontAwesomeIcon icon={faPlus} />}
                >
                    Add collection
                </Button>
            </div>
        </Field>
    );
};
