import { Query } from '../../../../services/GraphService';
import { NodeData } from '../../../types/data/GraphData';
import { DatasourceFunction } from '../../../types/Datasource';
import { GraphNodeConfig } from './Config';

const graphNode: DatasourceFunction<NodeData, GraphNodeConfig> = async(config, context) => {
    const id = config.id || context?.id;
    // Query for the node by id and traverse to canonical node if there is one
    const query = 'g.V().has("id", id).optional(out("is")).dedup().valueMap(true)';
    const { gremlinQueryResults } = await Query({'gremlinQuery': query, bindings: {'id': id}});

    return {
        data: gremlinQueryResults[0]
    };
};

graphNode.config = 'GraphNodeConfig';

export default graphNode;
