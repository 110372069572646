import { useEffect } from 'react';
import { FieldValues, UseFormWatch, DeepPartial } from 'react-hook-form';

/**
 * `onChange` is called every time the form fields change
 */
export function useFormChange<T extends FieldValues>(
    watch: UseFormWatch<T>,
    onChange: (values: DeepPartial<T>) => void
) {
    useEffect(() => {
        const subscription = watch(onChange);

        return () => subscription.unsubscribe();
    }, [watch, onChange]);
}
