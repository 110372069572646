import { useCallback } from 'react';
import { useGetNetworkNode, useSetNetworkNodes } from '../context/NetworkMapStoreContext';

export const useHandleFixedNodePosition = () => {
    const setNodes = useSetNetworkNodes();
    const getNode = useGetNetworkNode();
    
    const isFixed = useCallback((id: string) => getNode(id)?.data.fixedPosition, [getNode]);

    const fixPosition = useCallback((id: string) => {
        setNodes(currentNodes => currentNodes.map(currentNode => currentNode.id === id ? {
            ...currentNode,
            data: {
                ...currentNode.data,
                fixedPosition: true
            }
        } : currentNode));
    }, [setNodes]);

    const releasePosition = useCallback((id: string) => {
        setNodes(currentNodes => currentNodes.map(currentNode => currentNode.id === id ? {
            ...currentNode,
            data: {
                ...currentNode.data,
                fixedPosition: false
            }
        } : currentNode));
    }, [setNodes]);

    return {
        isFixed,
        fixPosition,
        releasePosition
    };
};