import { cn } from '@/lib/cn';
import { faSquareCheck, faSquareXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EntityTypes } from '@squaredup/constants';
import { Node } from '@squaredup/graph';
import { ColumnDef } from '@tanstack/react-table';
import Copy from 'components/Copy';
import graphNodeToTableData from 'dashboard-engine/util/graphNodeToTableData';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import TruncateWithTooltip from 'pages/settings/TruncateWithTooltip';
import { FC } from 'react';
import { reduceNodeJSONProperty, reduceNodeProperty } from './common';

interface GraphNodePropertyTableProps {
    node: Node;
    showInternalProperties: boolean;
}

interface ProjectedRow {
    prop: string;
    value: any;
    rawProp: string;
}

const workspacePropsToPromote = [
    { name: 'description' },
    { name: 'openAccessEnabled', as: 'sharingEnabled' },
    { name: 'type', as: 'workspaceType' }
];

const keepMaxNumber = (current: number, value: unknown) =>
    typeof value === 'number' ? Math.max(current, value) : current;

const toLocalStringOrUndefined = (date: number) => (date !== 0 ? new Date(date).toLocaleString() : undefined);

const convertBooleanString = (value: unknown) =>
    typeof value === 'string' && ['true', 'false'].includes(value.toLocaleLowerCase())
        ? value.toLocaleLowerCase() === 'true'
            ? true
            : false
        : value;

const getExpandedNode = (node: Node, showInternalProperties = false) => {
    if (!node) {
        return {};
    }

    if (!showInternalProperties && node.sourceType?.includes(`squaredup/${EntityTypes.WORKSPACE}`)) {
        //promote some properties out of the properties array into the object
        const newProps = reduceNodeJSONProperty(
            node['properties'],
            (newNode: Record<string, string>, value: Record<string, unknown>, index) =>
                Object.keys(value)
                    .filter((key) => workspacePropsToPromote.some((prop) => prop.name === key))
                    .reduce((acc, key) => {
                        const prop = workspacePropsToPromote.find((p) => p.name === key);
                        acc[`${index}.${prop?.as ?? prop?.name ?? key}`] = `${value[key]}`;
                        return acc;
                    }, newNode),
            {}
        );

        // Add alerting rule count
        const alertingRuleCount = reduceNodeJSONProperty(
            node['alertingRules'],
            (total, rules) => total + (Array.isArray(rules) ? rules.length : 0),
            0
        );
        newProps['notificationRules'] = `${alertingRuleCount}`;

        // Add linked datasources and workspaces
        const linkedObjectCount = reduceNodeJSONProperty(
            node['linkedObjects'],
            (count, links: Record<string, unknown>) => {
                if (Array.isArray(links?.plugins)) {
                    count.dataSources = count.dataSources + links.plugins.length;
                }

                if (Array.isArray(links?.workspaces)) {
                    count.workspaces = count.workspaces + links.workspaces.length;
                }

                return count;
            },
            { dataSources: 0, workspaces: 0 }
        );

        newProps['linkedWorkspaces'] = `${linkedObjectCount.workspaces}`;
        newProps['linkedDatasources'] = `${linkedObjectCount.dataSources}`;

        return { ...node, ...newProps };
    }

    if (!showInternalProperties && node.sourceType?.includes(`squaredup/${EntityTypes.MONITOR}`)) {
        const nextEvaluation = reduceNodeProperty(
            node['nextEvaluation'],
            (current, value) =>
                typeof value === 'number' ? (current === 0 ? value : Math.min(current, value)) : current,
            0
        );

        const lastTimes = ['lastChanged', 'lastEvaluated', 'lastHistoryWritten'].reduce(
            (acc: Record<string, string | undefined>, key) => {
                const value = reduceNodeProperty(
                    node[key],
                    (current, next) => (typeof next === 'number' ? keepMaxNumber(current, next) : current),
                    0
                );
                acc[key] = toLocalStringOrUndefined(value);
                return acc;
            },
            {}
        );

        return {
            ...node,
            nextEvaluation: toLocalStringOrUndefined(nextEvaluation),
            ...lastTimes
        };
    }

    return node;
};

const GraphNodePropertyApplicationTable = ApplicationTable<ProjectedRow, string>();

const columns: ColumnDef<ProjectedRow>[] = [
    {
        header: 'Name',
        accessorKey: 'prop',
        size: 200,
        cell: ({ row }) => (
            <div className='relative overflow-hidden group/cell'>
                <TruncateWithTooltip title={row.original.prop}>
                    <div className='relative flex items-center space-x-4 truncate'>
                        <span data-testid='property-name' className='truncate'>
                            {row.original.prop}
                        </span>
                        <Copy
                            className='invisible transition-opacity group-hover/cell:visible'
                            title='Copy internal property name'
                            value={row.original.rawProp}
                        />
                    </div>
                </TruncateWithTooltip>
            </div>
        )
    },
    {
        header: 'Value',
        accessorKey: 'value',
        size: 400,
        cell: ({ row }) => {
            const convertedValue = convertBooleanString(row.original.value);
            return (
                <div className='relative overflow-hidden group/cell'>
                    {typeof convertedValue === 'boolean' ? (
                        <div className='flex items-center'>
                            <FontAwesomeIcon
                                icon={convertedValue ? faSquareCheck : faSquareXmark}
                                className={cn('h-full', !convertedValue && 'opacity-25')}
                                fixedWidth
                            />
                        </div>
                    ) : (
                        <TruncateWithTooltip title={row.original.value}>
                            <div className='relative flex items-center space-x-4 truncate'>
                                <span className='truncate' data-testid='property-value'>
                                    {row.original.value}
                                </span>
                                <Copy
                                    className='invisible transition-opacity group-hover/cell:visible'
                                    title='Copy property value'
                                    value={row.original.value}
                                />
                            </div>
                        </TruncateWithTooltip>
                    )}
                </div>
            );
        }
    }
];

// Properties on system types that are inappropriate to display
const systemObjectHiddenProperties = ['sourceType', 'sourceId', 'sourceName', 'type'];

export const systemObjectHiddenPropertyMap = new Map<string, string[]>([
    [
        `squaredup/${EntityTypes.WORKSPACE}`,
        systemObjectHiddenProperties.concat(['alertingRules', 'linkedObjects', 'properties'])
    ],
    [`squaredup/${EntityTypes.DASHBOARD}`, systemObjectHiddenProperties.concat(['scopeIds'])],
    [
        `squaredup/${EntityTypes.MONITOR}`,
        systemObjectHiddenProperties.concat(['dataStreamIds', 'targetNodeIds', 'tileId'])
    ],
    [`squaredup/${EntityTypes.SCOPE}`, systemObjectHiddenProperties.concat(['bindings', 'contentHash', 'queryDetail'])]
]);

export const GraphNodePropertyTable: FC<GraphNodePropertyTableProps> = ({ node, showInternalProperties }) => {
    // hide system properties that are inappropriate to display
    const internalProperties = ['name', 'id', 'label', 'links', 'isCanonical'].concat(
        node.sourceType?.flatMap((t) => systemObjectHiddenPropertyMap.get(t) ?? []) ?? []
    );

    const propertiesTableConfig = {
        excludeProperties: showInternalProperties ? [] : internalProperties
    };

    const projectedRows: ProjectedRow[] = graphNodeToTableData(
        getExpandedNode(node, showInternalProperties),
        propertiesTableConfig
    );
    return (
        <GraphNodePropertyApplicationTable
            config={{
                noDataMessage: 'There are no matching properties.',
                splitArrayCellsIntoRows: true
            }}
            data={projectedRows}
            columns={columns}
        />
    );
};
