import NoDataPlaceholder from 'components/NoDataPlaceholder';
import TileContext from 'contexts/TileContext';
import { TileTypes } from 'dashboard-engine/constants';
import { useContext } from 'react';
import { TileTypeSelector } from './TileTypeSelector';

interface ConfigurationPromptProps {
    editing: boolean;
    isConfigured: boolean;
    setTileType: (type: TileTypes) => void;
}

export const ConfigurationPrompt: React.FC<ConfigurationPromptProps> = ({ editing, isConfigured, setTileType }) => {
    const { preview } = useContext(TileContext);

    if (isConfigured || preview) {
        return null;
    }

    if (!editing && !isConfigured) {
        return <NoDataPlaceholder message='Tile not configured.' />;
    }

    return <TileTypeSelector setTileType={setTileType} />;
};
