import { tokenise } from './tokenise';
import { uniq } from 'lodash';

/**
 * Filter an array of objects based on a search query and a list of accessors to search.
 * 
 * The query is split into tokens (words) and for an object to match it must contain **all** of
 * the tokens. However the tokens cab be spread across the accessors (properties).
 * 
 * For example a search for `foo bar` would match an object with 'foo' in the display name and 'bar' in the 
 * description (assuming display name and description are in the list of accessors).
 * 
 * The search is case-insensitive and matches on substrings, so a search for `foo` matches 
 * 'Foo', 'foo2', 'FooBar', 'barFOO' etc.
 */ 
export function filterObjects<T extends Record<string, unknown>>(data: T[], search: string, accessors: Accessors<T>[]) {
    const searchTokens = uniq(tokenise(search.toLowerCase()));
    if (searchTokens.length === 0) {
        return [];
    }
    const accessorPartsCache: Record<string, string[]> = {};

    return data.filter((object) => {
        const matchingTokens = new Set<string>();

        for (const accessor of accessors) {
            // We need to split the accessor into parts and then reduce over them to get the value
            accessorPartsCache[accessor] = accessorPartsCache[accessor] ?? accessor.split('.');
            const value = accessorPartsCache[accessor].reduce((acc: any, part) => acc?.[part], object);

            if (typeof value === 'string') {
                const lowerValue = value.toLowerCase();
                searchTokens.filter(token => lowerValue.includes(token)).forEach(t => matchingTokens.add(t));
            } else if (Array.isArray(value)) {
                // We could extend this to search multiple levels deep in the array
                for (const item of value) {
                    if (typeof item === 'string') {
                        const lowerValue = item.toLowerCase();
                        searchTokens.filter(token => lowerValue.includes(token)).forEach(t => matchingTokens.add(t));
                    }
                };
            }
            
            if (matchingTokens.size === searchTokens.length) {
                // Optimisation, no need to continue if we've matched all tokens.
                break;
            }
        }

        return matchingTokens.size === searchTokens.length;
    });
}

// Recursively get all accessors for a given object
export type Accessors<T extends Record<string | number, any> | string | number | Array<unknown>> = T extends object
    ? {
          [K in keyof T & (string | number)]: Exclude<T[K], null | undefined> extends Array<unknown>
              ? `${K}`
              : Exclude<T[K], null | undefined> extends object
                ? `${K}.${Accessors<Exclude<T[K], null | undefined>>}`
                : `${K}`;
      }[keyof T & (string | number)]
    : never;
