import { FormattedStreamValue } from '@squaredup/data-streams';
import { formatDate, standardDatePatterns } from '@squaredup/utilities';
import Tooltip from 'components/tooltip/Tooltip';

export const DateCell: React.FC<FormattedStreamValue> = ({ value, formatted }) => {
    if (!value) {
        return null;
    }

    const withUtcOffset = formatDate(value as string | number, {
        appendUtcOffset: true,
        pattern: standardDatePatterns.long
    });

    return (
        <Tooltip disabled={false} className='truncate' title={withUtcOffset}>
            {formatted}
        </Tooltip>
    );
};
