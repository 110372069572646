import { Divider } from '@/components/Divider';
import { Skeleton } from '@/components/Skeleton';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faExternalLink, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/button';
import Tooltip from 'components/tooltip/Tooltip';
import { forwardRef, useState } from 'react';
import { ThemeOptions, useTheme } from 'ui/hooks/useTheme';

interface PluginDocsProps {
    documentationPath: string;
    pluginDisplayName?: string;
}

export const PluginDocs = forwardRef<
    HTMLIFrameElement,
    PluginDocsProps
>(({ documentationPath, pluginDisplayName }, ref) => {
    const [isIframeLoaded, setIsIframeLoaded] = useState(false);
    const theme = useTheme();
    
    return (
        <div className={cn('border rounded border-helpOutline overflow-hidden min-h-0 flex flex-col flex-1')}>
            <div className='flex items-center min-w-0 py-3 space-x-2 select-none px-sm text-textPrimary shrink-0'>
                <FontAwesomeIcon icon={faQuestionCircle} />
                
                <Text.H3 className='flex-1 text-left'>Need help adding this data source?</Text.H3>

                <Tooltip
                    title='View full documentation'
                >
                    <Button
                        variant='link' 
                        href={`https://docs.squaredup.com${documentationPath}`} 
                        icon={<FontAwesomeIcon icon={faExternalLink} />}
                        rel='noopener noreferrer'
                    />
                </Tooltip>
            </div>

            <div className={cn('min-w-0 overflow-hidden flex-1 min-h-0')}>
                <Divider className='mx-[16px] w-auto' />

                {!isIframeLoaded && 
                    <div className='w-full h-full pt-4 px-sm'>
                        <Skeleton className='w-[58%] h-[24px] mb-4' />
                        <Skeleton className='w-[74%] h-[16px] mb-2' />
                        <Skeleton className='w-[85%] h-[16px] mb-2' />
                        <Skeleton className='w-[82%] h-[16px] mb-9' />
                        <Skeleton className='w-[92%] h-[24px] mb-4' />
                        <Skeleton className='w-[86%] h-[16px] mb-2' />
                        <Skeleton className='w-[99%] h-[16px] mb-2' />
                        <Skeleton className='w-[88%] h-[16px] mb-2' />
                        <Skeleton className='w-[65%] h-[16px] mb-2' />

                    </div>
                }

                <iframe 
                    src={`https://docs.squaredup.com/embed/${documentationPath}${theme !== ThemeOptions.system ? `?theme=${theme}` : ''}`} 
                    title={`Help adding ${pluginDisplayName ?? ''} data sources`}
                    className={cn('w-full h-[calc(100%-1px)] overflow-hidden hidden', isIframeLoaded && 'block')}
                    onLoad={() => setIsIframeLoaded(true)}
                    ref={ref}
                    allow='clipboard-read *; clipboard-write *'
                    allowFullScreen
                />
            </div>
        </div>
    );
});