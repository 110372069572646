import { UseQueryOptions, useQuery } from 'react-query';
import Auth from 'services/Auth';
import { getApplicationSubdomain } from 'services/Auth/getApplicationSubdomain';
import { ApplicationSubdomain } from '@squaredup/tenants';
import { tenantQueryKeys } from 'queries/queryKeys/tenantKeys';

type QueryReturnType = Awaited<ReturnType<typeof getApplicationSubdomain>>;

/**
 * useQuery returning info about the subdomain the app is running on (if any)
 *
 * @param options The react-query options e.g. refreshInterval
 * @returns Info about the subdomain, or {} if running on the standard app URL
 */
export const useApplicationSubdomain = <T = ApplicationSubdomain>(
    options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>
) =>
    useQuery(tenantQueryKeys.applicationSubdomain, () => getApplicationSubdomain(Auth.getAppLoadUrl()), {
        staleTime: Number.POSITIVE_INFINITY,
        cacheTime: Number.POSITIVE_INFINITY,
        ...options
    });
