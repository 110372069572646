import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Node } from '@squaredup/graph';
import Button from 'components/button';
import trackEvent from 'lib/analytics';
import { FC, useMemo } from 'react';

interface Link {
    url: string;
    label: string;
}

const isLink = (obj: unknown): obj is Link => {
    const asLink = obj as Link;
    return typeof asLink?.url === 'string' && typeof asLink?.label === 'string';
};

function getSourceLinks(context: Node): Link[] {
    try {
        // Parse links property. If the node is a canonical it will be a deep-nested JSON object
        // We therefore need to parse the returned values
        const parsedLinks: unknown = Array.isArray(context?.links)
            ? JSON.parse(context.links[0])
            : context.links
            ? JSON.parse(context.links)
            : [];

        if (!Array.isArray(parsedLinks)) {
            return [];
        }

        return (parsedLinks as unknown[])
            .map((link) => (typeof link === 'string' ? JSON.parse(link)[0] : link))
            .filter(isLink);
    } catch {
        return [];
    }
}

const trackLinkClick = (url: string) => {
    trackEvent('External Link Clicked', { url });
    window.open(url, '_blank', 'noopener');
};

export const ExternalLinks: FC<{ node: Node }> = ({ node }) => {
    const sourceLinks = useMemo(() => getSourceLinks(node), [node]);

    if ((sourceLinks?.length ?? 0) < 1) {
        return <></>;
    }

    return (
        <>
            <div className='flex items-center space-x-4'>
                {sourceLinks.map(({ label, url }, index) => (
                    <Button
                        key={`${label}:${url}:${index}`}
                        icon={<FontAwesomeIcon icon={faExternalLink} />}
                        onClick={() => trackLinkClick(url)}
                        variant='secondary'
                        className='whitespace-nowrap'
                        data-testid='externalLinksDropdownItem'
                    >
                        {label}
                    </Button>
                ))}
            </div>
            <div className='border-l border-dividerPrimary h-full py-4 mx-4' />
        </>
    );
};
