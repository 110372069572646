import { faPencil, faShieldExclamation, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BuiltInGroups } from '@squaredup/constants';
import Tooltip from 'components/tooltip/Tooltip';
import { FC } from 'react';
import TruncateWithTooltip from '../TruncateWithTooltip';
import { UserGroup } from './Users';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { ColumnDef } from '@tanstack/react-table';
import { Action } from 'pages/components/ApplicationTable/types';

interface UserGroupsTableProps {
    userGroups: UserGroup[];
    onEdit: (userGroup: UserGroup) => void;
    onDelete: (userGroup: UserGroup) => void;
    canEdit: (userGroup: UserGroup) => boolean;
    canDelete: (userGroup: UserGroup) => boolean;
}

const UserGroupsApplicationTable = ApplicationTable<UserGroup, string>();

const UserGroupsTable: FC<UserGroupsTableProps> = ({ userGroups, onEdit, onDelete, canEdit, canDelete }) => {
    const columns: ColumnDef<UserGroup, string>[] = [
        {
            id: 'name',
            header: 'Name',
            cell: ({ row }) => {
                return (
                    <>
                        {row.original.name === BuiltInGroups.Everyone.name ? (
                            <div className='flex truncate'>
                                <div className='truncate'>{row.original.displayName}</div>
                                <Tooltip
                                    title='All users in your organization are always members of this group.'
                                    className='ml-2'
                                >
                                    <FontAwesomeIcon className='text-textSecondary' icon={faShieldExclamation} />
                                </Tooltip>
                            </div>
                        ) : (
                            <TruncateWithTooltip title={row.original.displayName || ''}>
                                <div className='truncate'>{row.original.displayName}</div>
                            </TruncateWithTooltip>
                        )}
                    </>
                );
            },
            size: 300,
            accessorKey: 'displayName'
        },
        {
            id: 'description',
            header: 'Description',
            cell: ({ getValue }) => {
                return (
                    <TruncateWithTooltip title={getValue()}>
                        <div className='truncate'>{getValue()}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 500,
            accessorKey: 'description'
        },
        {
            id: 'userCount',
            header: 'Users',
            cell: ({ row }) => `${row.original.userCount} user${row.original.userCount !== 1 ? 's' : ''}`,
            size: 150,
            accessorKey: 'userCount'
        }
    ];

    const actions: Action[] = [
        {
            visible: canEdit,
            action: onEdit,
            icon: faPencil,
            dataTestId: 'editUserGroupButton',
            tooltip: 'Edit user group'
        },
        {
            visible: canDelete,
            action: onDelete,
            icon: faTrash,
            dataTestId: 'deleteUserGroupButton',
            tooltip: 'Delete user group'
        }
    ];

    return (
        <UserGroupsApplicationTable
            config={{
                actions,
                noDataMessage: 'There are no user groups configured.'
            }}
            data={userGroups}
            columns={columns}
        />
    );
};

export default UserGroupsTable;
