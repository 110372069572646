import { AgentGroupId, Serialised } from '@squaredup/ids';
import { handleError, handleResponse } from './util';

import type { AgentGroup, ProjectedAgentGroup } from 'dynamo-wrapper';
import API from './API';

export type AgentGroupResponse = ProjectedAgentGroup & {
    listenerCount: number;
};

export type AgentGroupRestrictedPlatforms = {
    id: AgentGroupId,
    restrictedToPlatforms: string[]
};

/**
 * Key for react-query cache
 */
export const AGENT_GROUPS = 'agentGroups';
export const AGENT_GROUP_RESTRICTIONS = 'agentGroupsRestrictedPlatforms';

/**
 * List all agent groups
 */
export const List = async () => {
    return API.get<Serialised<AgentGroupResponse>[]>('/agentgroups')
        .then(handleResponse)
        .then((response) => {
            return response.sort((a, b) => {
                if (!a.displayName) {
                    return 1;
                }
                if (!b.displayName) {
                    return -1;
                }
                return a.displayName.localeCompare(b.displayName);
            });
        })
        .catch(handleError);
};

/**
 * List agent group platform requirements
 */
export const ListRestrictedPlatforms = async () => {
    return API.get<Serialised<AgentGroupRestrictedPlatforms>[]>('/agentgroups/platforms')
        .then(handleResponse)
        .catch(handleError);
};

/**
 * Creates a new agent Group
 * @param name
 * @param description
 * @param rawId
 */
export const Create = async (displayName: string, description: string, rawId?: string) => {
    return API.post<Serialised<AgentGroup>>('agentgroups', {
        displayName,
        description,
        rawId
    })
        .then(handleResponse)
        .catch(handleError);
};

/**
 * Get an Agent Group
 * @param id
 */
export const GetAgent = async (id: string) => {
    return API.get(`/agentgroups/${id}`).then(handleResponse).catch(handleError);
};

/**
 * Updates an agent group
 * @param displayName
 * @param description
 * @param id
 * @param apiKeyId
 */
export const Update = async (id: string, displayName: string, description: string, meta?: object) => {
    return API.put(`/agentgroups/${id}`, {
        displayName,
        description,
        meta
    })
        .then(handleResponse)
        .catch(handleError);
};

/**
 * Deletes an agent group
 * @param id
 */
export const Delete = async (id: string) => {
    return API.delete(`/agentgroups/${id}`).then(handleResponse).catch(handleError);
};
