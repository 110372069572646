import { useCallback, useRef, useState } from 'react';

export const useHover = (hoverTimeout = 0, blurTimeout = 150) => {
    const hoverTimeoutRef = useRef<number>();
    const blurTimeoutRef = useRef<number>();
    const [isHovered, setIsHovered] = useState(false);

    const onMouseEnter = useCallback(() => {
        window.clearTimeout(blurTimeoutRef.current);
        hoverTimeoutRef.current = window.setTimeout(() => setIsHovered(true), hoverTimeout);
    }, [hoverTimeout]);

    const onMouseLeave = useCallback(() => {
        window.clearTimeout(hoverTimeoutRef.current);
        blurTimeoutRef.current = window.setTimeout(() => setIsHovered(false), blurTimeout);
    }, [blurTimeout]);

    return {
        isHovered,
        onMouseEnter,
        onMouseLeave
    };
};
