import { fiveMinutes } from 'queries/constants';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { FetchQueryOptions, QueryClient, UseQueryOptions, useQuery } from 'react-query';
import { GetCountOfMonitors } from 'services/UsageService';

type QueryReturnType = Awaited<ReturnType<typeof GetCountOfMonitors>>;
type QuerySelectReturnType = QueryReturnType;

const queryKey = dashboardQueryKeys.monitorCount;

const querySettings: FetchQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]> = {
    queryKey,
    queryFn: GetCountOfMonitors,
    staleTime: fiveMinutes,
    cacheTime: fiveMinutes
};

export const useMonitorsCount = (
    options?: UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>
) => useQuery({ ...querySettings, ...options });

export const prefetchMonitorCount = (queryClient: QueryClient) => queryClient.prefetchQuery(querySettings);

export const optimisticMonitorCountUpdate = (queryClient: QueryClient, difference: number) => {
    const monitorCount = queryClient.getQueryData<QueryReturnType>(queryKey);
    if (monitorCount !== undefined) {
        queryClient.setQueryData(queryKey, monitorCount + difference);
    }
};